export default Object.freeze({
   /* =====================================
   내일정 추가/수정 코드
  ===================================== */
  fcCustCntc: [
    {id: 'HD01', name: '비대면'},
    {id: 'HD02', name: '친숙방문'},
    {id: 'HD03', name: '컨설팅'},
    {id: 'HD04', name: '상품설명'},
    {id: 'HD05', name: '소개'},
    {id: 'HD06', name: '리크루팅'},
    {id: 'HD07', name: '신계약'},
    {id: 'HD08', name: '보험금청구'},
    {id: 'HD09', name: '기타'}
  ],
  mngrCustCntc: [
    {id: 'SH01', name: '콜로세움'},
    {id: 'SH02', name: 'JOB설명회'},
    {id: 'SH03', name: 'VIP세미나'},
    {id: 'SH04', name: '교육'},
    {id: 'SH05', name: '조회'},
    {id: 'SH06', name: '기타'},
  ],
  // 
  /***************************
  * 신계약접수현황 선택값 리스트 (MSPAP503M)
  ****************************/
   
  NCONT_ACPN_PNSTA_SORT_CD_LIST: [     // 정렬 CODE-LIST 데이터
    {value: '1',   type: 'contrNm',    text: '계약자명 A,ㄱ부터', sort: 'ASC'},
    {value: '2',   type: 'contrNm',    text: '계약자명 ㅎ부터', sort: 'DESC'},
    {value: '3',   type: 'clctCnsltNm',    text: '컨설턴트명 A,ㄱ부터', sort: 'ASC'},
    {value: '4',   type: 'clctCnsltNm',    text: '컨설턴트명 ㅎ부터', sort: 'DESC'},
    {value: '5',   type: 'contrDob',   text: '계약자 나이 많은순', sort: 'ASC'},
    {value: '6',   type: 'contrDob',   text: '계약자 나이 적은순', sort: 'DESC'},
    {value: '7',   type: 'smtotPrm',   text: '보험료 높은순', sort: 'ASC'},
    {value: '8',   type: 'smtotPrm',   text: '보험료 낮은순', sort: 'DESC'},
    {value: '9',   type: 'prdtNm',     text: '계약명 A,ㄱ부터', sort: 'ASC'},
    {value: '10',   type: 'prdtNm',     text: '계약명 ㅎ부터', sort: 'DESC'},
    {value: '11',   type: 'acpnYmd',    text: '접수일자 최근순', sort: 'ASC'},
    {value: '12',  type: 'acpnYmd',    text: '접수일자 오래된순', sort: 'DESC'},
  ]
})
