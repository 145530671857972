/*
 * 업무구분   : 선심사 서류보완
 * 화면 명    : MSPPI826P
 * 화면 설명  : 서류이미지 크게 보기
*/
<template>
  <ur-page-container class="msp btn-back-clear" title="크게보기" :show-title="false" type="popup" ref="page">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap bgcolor-1">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper">
                <!-- <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon> -->
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '크게보기' : '크게보기'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_Close826P">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky> 
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->            
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb82" nowrap>
                <picture class="bdTy11 w100 bgcolor-2">
                  <img :src="img" class="w100"/>
                </picture>
              </ur-box-container>  
            </ur-box-container>
            <!-- Content 영역 end -->

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close826P">확인</mo-button>
              </div>
            </ur-box-container>  
          </template>

        </mo-collapsing-header-view>

      </div>
    </ur-box-container>

  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPPI826P',
    screenId: 'MSPPI826P',
    components: {},
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      params: {}
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      console.log('this.$props.params.img='+this.$props.params.img)
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
      // 화면접속로그 등록
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.img = this.$props.params.img
      console.log('this.$props.params.img='+this.$props.params.img)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        img: '',
        lv_HeaderviewScrollCompID: ''
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Close826P
       * 설명       : 팝업 닫기
       ******************************************************************************/
      fn_Close826P () {
        this.$emit('onPopupClose')
      },
    },
  };
</script>
<style scoped>
</style>