/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM285D
 * 화면설명 : 터치이력 - 계약사항변경
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : fn_CmtdRsltSubTot > 0}">
      <!-- Contents 영역 -->
      <ur-box-container v-if="fn_CmtdRsltSubTot > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb60">
        <mo-list-item v-for="(item,idx) in lv_RsltSubSVO" :key="idx"> 
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expand-only-btn :expanded="idx < 1" class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fs19rem">{{ $bizUtil.dateDashFormat(item.chgYmd) }}</span>
                </div>
                <div class="list-item__contents__info pt10 fs16rem">
                  <!-- 계약사항 변경내용 -->
                  <span>{{ item.chgSc }}</span>
                </div>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20 mbm20">
                <div class="list-item__contents gray-box pa2024">
                  <div class="list-item__contents__info fexTy5">
                    <span class="min80 crTy-bk7">상품명</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.prdtNm }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">계약번호</span>
                    <span class="ml20 txtSkip fs16rem lnkTy1" @click="fn_MoveMSPPM931M(item)">{{ item.contNoMask }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">처리부서</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.trtDeptNm }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">처리자</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.trtNm }}</span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
    </ur-box-container>

  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM285D', 
  // 현재 화면 ID
  screenId: 'MSPCM285D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '터치이력-계약사항변경',
      lv_UserInfo: {}, // 사용자 정보
      lv_RsltSubSVO: [],
      lv_RsltBasSVO: {}
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltSubSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltSubSVO = this.$bizUtil.cloneDeep(this.pCustInfo.lv_RsltSubSVO)
        
        this.lv_RsltSubSVO.forEach(item => {
          item.contNoMask = '*********' + item.contNo.substr(-4) // 계약번호 마스킹 처리
        });

      }
    },
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
  },
  computed: {
    fn_CmtdRsltSubTot() {
      return this.lv_RsltSubSVO ? this.lv_RsltSubSVO.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM285D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM285D')
  },
  methods: {
    /*********************************************************
     * @function fn_MoveMSPPM931M
     * @name     화면전환
     * @notice   보험계약기본사항 화면 전환
    *********************************************************/
    fn_MoveMSPPM931M (item) {
      let contInfoObj = {
        callerId: 'MSPCM285D', // 송신화면 ID
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,       // 담당컨설턴트 사번
        contNo: item.contNo, // 계약번호
      }

      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },
  }
};
</script>
