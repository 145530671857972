/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI701D
 * 화면 설명  : 보장,가입설계 - 고객선택
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">

    <ur-box-container v-if="lv_IsSearched && lv_List && lv_List.length" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 list-item-p2024"> <!-- 퍼블 0522 수정 ns-radio-list2 -->
        <mo-list :list-data="lv_List">
          <template #list-item="{item, index}">
            <mo-list-item :class="{on: index === lv_SelectedIndex}" @click.native="fn_SelectItem(item, index)">
              <mo-radio v-model="lv_SelectedIndexStr" :value="String(index)" @click.stop="fn_SelectItem(item, index)"/> <!-- 퍼블 0522 추가 mo-radio -->
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip-inline">
                    <span v-html="fn_DisplayMatch(item.custNm)" @click.stop="fn_OnClickCust(item)"></span>
                  </span>
                </div>
                <div class="list-item__contents__info">
                  <div class="fexTy5"> <!-- 퍼블 0522 추가 fexTy5  -->
                    <span class="crTy-bk1" v-if="item.dob">{{item.dob}}</span>
                    <span class="crTy-bk1" v-else>****-**-**</span>
                    <span class="crTy-bk1" v-if="item.sxdsCdNm"><em>|</em>{{item.sxdsCdNm}}</span>
                    <span class="crTy-bk1" v-if="item.insrAge"><em>|</em>{{item.insrAge}}세</span>
                  </div>
                </div>
                <div class="list-item__contents__info">
                  <div class="fexTy5"> <!-- 퍼블 0522 추가 fexTy5 -->
                    <span class="crTy-bk1" v-if="item.mndtCnstgCnsntYn">필수컨설팅동의
                      <b class="ml10" :class="item.mndtCnstgCnsntYn === 'Y' ? 'crTy-blue2' : 'crTy-red2'">{{item.mndtCnstgCnsntYn}}</b>
                    </span>
                    <span class="crTy-bk1" v-if="item.covrAnlyYn"><em v-if="item.mndtCnstgCnsntYn">|</em>보장설계
                      <b class="ml10" :class="item.covrAnlyYn === 'Y' ? 'crTy-blue2' : 'crTy-red2'">{{item.covrAnlyYn}}</b>
                    </span>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
    </ur-box-container>

    <!-- no-data -->
    <ur-box-container v-else-if="lv_IsSearched" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
      <mo-list-item ref="expItem">
        <div class="list-item__contents">
          <div class="list-item__contents__info small-space">
            <span class="ns-ftcr-gray">
              <template v-if="lv_SearchedKeyword.length > 1">
                <p class="mt20 mb20"><b class="crTy-bk1">'{{lv_SearchedKeyword}}'</b>에 대한 검색결과가 없습니다.</p>
                <mo-button class="ns-btn-round h28 blackW fs14rem" @click="fn_MoveAgree">신규고객 등록</mo-button>
              </template>
              <template v-else-if="lv_List.length === 0">
                <p class="mt20 mb20">최근 검색결과가 없습니다.</p>
              </template>
            </span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>


    <!-- 퍼블 0522 SKEL_LIST형 로딩 -->
    <div v-if="!lv_IsSearched" class="skelection in-content type01">  <!-- 퍼블 0522 수정 :  type01 -->
      <div class="skelection-bg"></div>
      <div class="skelection-con">
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
        <div class="sk-list">
          <div class="sk-select"></div><div class="sk-box"></div><div class="sk-box row2"></div><div class="sk-box row3"></div>
        </div>
      </div>
    </div>
    <!-- 퍼블 0522 SKEL_LIST형 로딩 -->

    <!-- 업무선택 Bottom Sheet -->
    <MSPPI701P v-bind="lv_SelectedItem" :opened="lv_SelectedIndex !== -1" @closed="fn_UnSelectItem"/>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPPI701P from '@/ui/pi/MSPPI701P'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI701D',
  screenId: 'MSPPI701D',
  components: { MSPPI701P },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_LoadList()
  },
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy() {
    this.getStore('progress').dispatch('FULL')
  },
  props: {
    searchKeyword: {
      type: String,
      default: ''
    },
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], // 더보기 변수
      // stndKeyVal: '', // 더보기 변수
      stndKeyList: [], // 변경된 컨설턴트
      lv_IsSearched: false,
      lv_SearchedKeyword: '',
      lv_List: [],
      lv_SelectedIndex: -1,
      lv_SelectedIndexStr: '',
      lv_SelectedItem: {},
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_SearchKeyword() {
      return this.searchKeyword?.trim() || ''
    },
  },
  watch: {
    // fn_SearchKeyword(val) {
    //   this.lv_List = []
    //   this.lv_IsSearched = false
    //   if (val.length === 1) {
    //     this.$emit('loaded', 0, false)
    //   } else {
    //     this.fn_LoadList()
    //   }
    // },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_InitSearchList() {
      this.lv_List = []
      this.lv_IsSearched = false
      this.lv_SearchedKeyword = ''
      this.$emit('loaded', 0, false)
    },
    /*********************************************************
     * Function명: fn_LoadList
     * 설명: 리스트 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_LoadList() {
      this.fn_UnSelectItem()

      if (this.fn_SearchKeyword.length === 1) {
        this.$addSnackbar('두 글자 이상 입력해주세요.')
        return
      }

      // 길이가 두 글자 이상인 경우 키워드 검색
      const hasKeyword = this.fn_SearchKeyword.length > 1

      if (hasKeyword) {
        this.fn_SearchCust()
      } else {
        this.fn_LoadSearchHist()
      }
    },
    /*********************************************************
     * Function명: fn_LoadSearchHist
     * 설명: 최근고객조회 List 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_LoadSearchHist() {
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }
      const pParams = {}
      const trnstId = 'txTSSBC02S6'
      const auth = 'S'
      this.fn_InitSearchList()
      this.getStore('progress').dispatch('NONE')
      this.post(this, pParams, trnstId, auth)
        .then(response => {
          if ( response?.body?.custSrchList ) {
            this.lv_List = response.body.custSrchList/* .map(item => {
              item.insrAge = '*'
              item.sxdsCdNm = '*'
              item.telno = '*'
              item.mndtCnstgCnsntYn = '*'
              item.mktPrcusCnsntYn = '*'
              item.covrAnlyYn = '*'
              return item
            }) */
            this.lv_List.map(item => this.fn_SelCustDetail(item))
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.getStore('confirm').dispatch('SETTITLE', null)
          window.vue.error(error)
        })
        .finally(()=> {
          this.$emit('loaded', this.lv_List.length, false)
          this.lv_IsSearched = true
        });
    },
    /*********************************************************
     * Function명: fn_SearchCust
     * 설명: 고객검색 List 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SearchCust() {
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }
      const pParams = { custNm: this.fn_SearchKeyword }
      const trnstId = 'txTSSBC02S5'
      const auth = 'S'
      this.fn_InitSearchList()
      this.getStore('progress').dispatch('NONE')
      this.post(this, pParams, trnstId, auth)
        .then(response => {
          this.lv_SearchedKeyword = pParams.custNm
          if ( response?.body?.custSrchList ) {
            this.lv_List = response.body.custSrchList
            // 고객명으로 진입한 경우 결과가 한건이면 자동선택
            if (this.lv_List.length === 1 && this.$route.params.custNm) {
              this.fn_SelectItem(this.lv_List[0], 0)
            }
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.getStore('confirm').dispatch('SETTITLE', null)
          window.vue.error(error)
        })
        .finally(()=> {
          this.$emit('loaded', this.lv_List.length, true)
          this.lv_IsSearched = true
        });
    },
    /*********************************************************
     * Function명: fn_SelCustDetail
     * 설명: 고객별 필컨, 마동 정보 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelCustDetail(item) {
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }
      const pParams = { chnlCustId: item.chnlCustId }
      const trnstId = 'txTSSBC02S7'
      const auth = 'S'
      this.getStore('progress').dispatch('PART')
      return this.post(this, pParams, trnstId, auth)
        .then(response => {
          if ( response?.body ) {
            const data = response.body
            item.dob = data.dob, // 검색고객생년월일
            item.insrAge = data.insrAge
            item.sxdsCdNm = data.sxdsCdNm
            item.telno = data.telno
            item.mndtCnstgCnsntYn = data.mndtCnstgCnsntYn
            item.mktPrcusCnsntYn = data.mktPrcusCnsntYn
            item.covrAnlyYn = data.covrAnlyYn
          }
        })
        .catch(error => {
          window.vue.getStore('confirm').dispatch('SETTITLE', null)
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_DisplayMatch
     * 설명: 검색어 일치 글자 표기
     * Params: val
     * Return: N/A
     *********************************************************/
    fn_DisplayMatch(val) {
      if (this.$bizUtil.isEmpty(this.lv_SearchedKeyword)) return val
      const regex = new RegExp(`(${this.lv_SearchedKeyword})`, 'g')
      return val.replace(regex, '<span class="crTy-blue3">$1</span>')
    },
    /*********************************************************
     * Function명: fn_SelectItem
     * 설명: 리스트 아이템 선택
     * Params: item, index
     * Return: N/A
     *********************************************************/
    fn_SelectItem(item, index) {

      // 2024.01.15 donghun1.yoo 수정 시작
      if (this.$bizUtil.isEmpty(item.dob)) {
        this.$addSnackbar('고객 생년월일 정보가 없습니다.')
        return
      }
      // 2024.01.15 donghun1.yoo 수정 끝

      this.lv_SelectedIndex = index
      this.lv_SelectedIndexStr = String(index)
      this.lv_SelectedItem = item

      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }
      const trnstId = 'txTSSBC02I1'
      const pParams = {
        chnlCustId: item.chnlCustId, // 채널고객ID
        custNm: item.custNm, // 검색고객명
        dob: item.dob, // 검색고객생년월일
        telno: item.telno // 검색고객전화번호
      }
      // 최근고객검색 추가
      this.post(this, pParams, trnstId, 'I', {}, true)
        // .then(response => {})
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_UnSelectItem
     * 설명: 리스트 아이템 선택 해제
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_UnSelectItem() {
      this.lv_SelectedIndex = -1
      this.lv_SelectedIndexStr = ''
      this.lv_SelectedItem = {}
    },
    /**
     * Function명 : fn_OnClickCust
     * 설명       : 고객 선택 시 event function
     */
    fn_OnClickCust(pItem) {
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        return
      }
      const nmUiObject = {
        chnlCustId : pItem.chnlCustId, //고객채널 ID
        parentId   : this.$route.name, //화면ID+
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        custNm : pItem.custNm,
        contNo : ''
      }
      // 고객카드 바텀시트
      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },
    fn_MoveAgree() {
      this.$router.push({
        name: 'MSPCM050M',
        params: {
          custNm: '',
          srnId: this.$options.screenId,
          aprvType: '01',
          infoPrcusCnsntTypCd: '17', // '17' 필컨, '19': 마동, '18': 둘다
        }
      })
    },
  }
};
</script>
<style scoped>
</style>