/*
 * 업무구분: 고객
 * 화 면 명: MSPCM008D
 * 화면설명: 휴대폰 인증
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.09
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container class="msp" :show-title="true" type="subpage" title="휴대폰 인증" @on-header-left-click="fn_Back">
    <ur-box-container class="msp-cm-wrap" direction="column" alignV="start">
      <div class="ns-insideWidthAuto full">
        <mo-validate-watcher ref="vWatcher">
          <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="verify-box">
              <!-- 고객명-->
              <mo-text-field ref="custNmRef" :rules="validateRuleName" v-model="fn_CustNm" underline placeholder="고객명 입력 후 조회를 선택해주세요"
                              maxlength="30" @keyup.enter="fn_CheckCustNm('enter')" @blur="fn_CheckCustNm" :disabled="disabled_custNm"/>

              <mo-button class="ns-btn-round" @click="fn_CheckCustNm('enter')" :disabled="disabled_custNm">조회하기</mo-button>
              
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check">
                <!-- 인증결과-->
                <mo-checkbox v-if="custCardVO.isExistCustYN === 'Y'" class="is-checked" readonly>기존고객입니다.</mo-checkbox>
                <mo-checkbox v-if="custCardVO.isExistCustYN === 'N'" class="is-checked" readonly>신규고객입니다.</mo-checkbox>
                <!-- 외국인 여부-->
                <!-- 고객조회 완료 시, 외국인 선택 못하도록 처리 : 2022-08-12 -->
                <!-- <mo-checkbox v-model="fn_IsForeigner" :disabled="(infoPrcusCnsntTypCd==='19')? true: (disabled_custNm === true)? true: false">외국인</mo-checkbox> -->
                <mo-checkbox v-model="fn_IsForeigner" :disabled="disabled_exist_foreign">외국인</mo-checkbox>
              </ur-box-container>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column">
              <div class="verify-birth">
                <!-- 생년월일-->
                <mo-text-field v-if="!isForeigner" ref="knbFrnoRef1" :rules="validateRuleKnbFrno" v-model="fn_KnbFrno" type="number" mask="######"
                  underline class="birth-input1" placeholder="생년월일" :disabled="disabled_exist"/>
                <!-- 외국인등록번호-->
                <mo-text-field v-else :rules="validateRuleKnbFrno" ref="knbFrnoRef2" v-model="fn_KnbFrno" type="number" mask="######"
                  underline class="birth-input1" placeholder="외국인등록번호" :disabled="disabled_exist"/>
                <span>-</span>
                <!-- 성별 -->
                <mo-text-field ref="knbBknoRef" :rules="validateRuleKnbBkno" v-model="fn_Gender" type="number" mask="#" 
                    underline class="birth-input2" placeholder="0" :disabled="disabled_knbBkno"/>
                <em>******</em>
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
              <!-- // 한줄 적용요청으로 class 수정 2022-08-01 -->
              <div class="verify-call">
                <!-- 통신사-->
                <msp-bottom-select ref="telecomTypRef" :items="telecomTypes" v-model="fn_TelecomType" underline class="ns-dropdown-sheet call1" placeholder="선택" 
                    bottom-title="통신사를 선택해 주세요" closeBtn />
                <!-- 휴대폰 앞자리-->
                <msp-bottom-select :items="phoneItems" v-model="fn_CelnoFrno" underline class="ns-dropdown-sheet call2"/>
                <!-- 휴대폰 뒷자리-->
                <mo-text-field ref="celnoRef" :rules="validateRuleCelno" v-model="fn_CelnoBkno" underline placeholder="00000000" type="number" class="phone-number1 form-input-name"
                  :disabled="disabled_inComp" mask="########"/>
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-check">
              <div class="ns-height10"></div>
              <mo-checkbox v-model="agreeCheckbox" @input="fn_AgreeCnfrmPop2('Open')">본인확인 서비스 이용 동의</mo-checkbox>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="verify-btn">
              
              <mo-button class="ns-btn-round" @click="fn_BtnP1OnClick()" :disabled="$bizUtil.isEmpty(custCardVO.isExistCustYN)">
                <span v-if="isBeforeSend">인증번호 전송</span>
                <span v-else>인증번호 재전송</span>
              </mo-button>
              <mo-button class="ns-btn-round" v-if="isDevelopment" @click="fn_AuthSkip" :disabled="$bizUtil.isEmpty(custCardVO.isExistCustYN)">
                <span>인증번호스킵</span>
              </mo-button>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="verify-input">
              <!-- 인증번호 입력-->
              <mo-text-field v-model="authNumber" type="number" ref="refInAuthNum" underline placeholder="인증번호를 입력해주세요." :disabled="authNumberDisabled"/>
              <span v-if="isAuthCompleted">인증되었습니다.</span>
              <span v-if="isAuthError">{{ authErrorMsg }}</span>

              <span v-if="isCountStart">인증번호 만료까지 {{ minutes | twoDigits }}:{{ seconds | twoDigits }}</span>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="verify-btn">
              <span class="txt gray" style="margin-top: 0 !important;">
                <span class="txt-ment">인증번호 미도착시 아래와 같이 조치 부탁드립니다.</span>
                <span class="txt-ment">휴대폰 스팸번호 분류확인</span>
                <span class="txt-ment">NICE인증 고객센터 문의 <span style="color: #416dff;">&#9742;1600-1522</span></span>
              </span>
            </ur-box-container>

            <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="ur_box_container_008" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="authNumberDisabled" @click="fn_PrcsMoblAthntCnf()">인증완료</mo-button>
              </div>
            </ur-box-container>

            <!-- 이용동의 안내 bottomSheet -->
            <mo-bottom-sheet ref="agreeCnfrmPop" class="ns-bottom-sheet ns-style3">
              <div>
              <p class="cancel-ment" v-html="agreeMsg"></p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AgreeCnfrmPop('Cancel')">취소</mo-button>
                    <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop('Confirm')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 이용동의 안내 bottomSheet -->
            <div class="ns-bottom-sheet">
              <mo-bottom-sheet ref="agreeCnfrmPop2" :close-btn="false" class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
                <template v-slot:title>
                  본인확인서비스 이용동의
                  <div class="ns-btn-close" @click="fn_AgreeCnfrmPop2('CloseBtn')" name="닫기"></div>
                </template>
                <div class="content-area">
                  <ul class="terms-list-area pb36">
                    <li>개인정보 수집/이용 동의</li>
                    <li>고유식별정보 처리 동의</li>
                    <li>통신사 이용약관 동의</li>
                    <li>서비스 이용약관 동의</li>
                    <li>개인정보 제 3자 제공 동의</li>
                  </ul>
                </div>

                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrmPop2('Confirm')">약관 확인</mo-button>
                      <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop2('AllConfirm')">전체 약관 동의</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
            </div>

            <div class="ns-height70"></div>
          </ur-box-container>
        </mo-validate-watcher>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import cmConstants from '@/config/constants/cmConstants'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  
  import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택 
  import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내
  
  export default {
    /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
    // 현재 화면명
    name: 'MSPCM008D',
    // 현재 화면 ID
    screenId: 'MSPCM008D',
    components: {
      moment,
      MspBottomSelect,
      MSPCM005P,
      MSPCM010P
    },
    props: {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKeyList: [],
        lv_UserInfo: {}, // 사용자 정보

        validateRuleSelect: [
          v => v !== '' || '통신사를 선택하세요'
        ],
        validateRuleName: [
          v => !!v || '필수 입력항목입니다',
          v => this.custNmResult || this.custNmErrorMsg
        ],
        validateRuleKnbBkno: [
          v => !!v || '필수 입력항목입니다',
          v => this.fn_CheckCKnbGender() || this.knbErrorMsg
        ],
        validateRuleKnbFrno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 5 || '생년월일을 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.',
          v => this.chkCelnoResult || this.celnoErrorMsg
        ],
        title: '휴대폰 인증', // 화면명
        agreeMsg:'', //이용동의 내용
        agreeMsg2:'', //이용동의 내용
        agreeCheckbox: false,
        sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
        telecomType: '',
        telecomTypes: [
          { value: 'SKT', text: 'SKT'},
          { value: 'KTF', text: 'KT' },
          { value: 'LGT', text: 'LG U+' },
          { value: 'SKTM',text: 'SKT(알뜰폰)' },
          { value: 'KTM', text: 'KT(알뜰폰)'  },
          { value: 'LGTM',text: 'LG U+(알뜰폰)'},
          { value: 'MCHK',text: '알뜰폰 확인하기'}
        ],
        // 주고객 정보 VO
        custCardVO: {
          isExistCustYN:'', //신규 여부
          custNm: '', // 이름
          chnlCustId: '', // 채널고객ID
          chnlCstgrId: '', // 채널그룹ID
          knb: '', // 전체주민번호
          knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
          knbFrno: '', // 주민번호 앞자리
          knbBkno: '', // 주민번호 뒷자리
          knbBkFstNo: '', // 주민번호 뒷자리 첫자리 (성별구분코드)
          knbBknoCipher: '', // 암호화된 주민번호
          knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
          knbScCd: 'ZPER01', // 식별번호구분코드
          celno: '', // 휴대전화 전체
          celnoFrno: {}, // 휴대전화 앞자리
          celnoBkno: '', // 휴대전화 뒷자리
        },
        disabledObj: {
          chkForeignerDisabled: false,
          mcnstDisabled: false,
          mktDisabled: false,
          knbFrnoDisabled: false,
          knbBknoDisabled: false,
          addrTypeDisabled: false,
          zipBtnDisabled: false,
          entryDisabled: false,
          celnoFrnoDisabled: false,
          celnoBknoDisabled: false,
          emailDisabled: false,
          emailBknoDisabled: false,
          jobDisabled: false,
          nextBtnDisabled: false // 다음버튼 비활성화 여부
        },
        chkCelnoResult: true, // 핸드폰번호 유효성
        celnoErrorMsg: '', // 핸드폰번호 에러 메시지
        chkKnbResult: true, // 주민번호 유효성 검증 결과
        knbErrorMsg: '', // 주민번호 유효성 체크 후 에러 메시지
        custNmResult: true, // 이름 유효성 체크 결과
        custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
        phoneItems: [],   //휴대폰 앞자리
        isForeigner: false, // 외국인 체크 여부
        chkRrn: false, // 미확보 체크여부
        disabled_inComp: true, // 휴대폰번호 입력 컴포넌트 - 초기진입 시 : 비활성화
        disabled_exist: true, // 생년월일, 외국인 입력 컴포넌트 - 초기진입 시 : 비활성화
        disabled_knbBkno: true, // 생년월일, 외국인 입력 컴포넌트 - 초기진입 시 : 비활성화
        disabled_exist_foreign: false, // 외국인 입력 컴포넌트 - 초기진입 시 : 활성화
        disabled_custNm: false, // 고객명, 조회 입력 컴포넌트 - 초기진입 시 : 비활성화
        prevTelecomType: '',
        isBeforeSend: true,
        date: 0,
        now: Math.trunc((new Date()).getTime() / 1000),
        interval: {},
        isAuthCompleted: false,
        isAuthError: false,
        isCountStart: false,
        authErrorMsg: '인증번호 오류입니다',
        selfAthntRespUnQVal: '',
        nicePtptInstId: '',
        aprvNoErrCnt: 0,
        authNumber: '',
        lmsDspchRsltId: '',
        authNumberDisabled: true,
        isDevelopment: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage2' || process.env.NODE_ENV === 'stage'),
        agreeTypeMcnst: false, // 필수컨설팅 동의 여부
        mktChkList: [], //마컨 정보(광고성 수신동의)['P', 'S', 'M', 'E']
        mktCnsntAvlPrd : '', //개인(신용)정보 보유이용기간 선택
        infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
        segmentType1: '', //마케팅 (거래, 미거래)
        
        // 팝업 연동
        popup005: {}, // MSPCM005P 고객선택 팝업
        popup010: {}, // MSPCM010P 알뜰폰 안내 팝업
      
        routeCustNm: '',

        ShowBottomBtn: true // 키패드 노출시, 버튼 숨김
      };
    },
    filters: {
      twoDigits: function (value) {
        if (value.toString().length <= 1) {
          return '0' + value.toString()
        }
        return value.toString()
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** computed 함수 정의 영역 */
    computed: {
      fn_CustNm: { // 고객명
        get: function () {
          return this.custCardVO.custNm
        },
        set: function (data) {
          this.custCardVO.custNm = data ? data.trim().toUpperCase() : ''
          
          this.$refs.vWatcher.clearValidation()
        }
      },
      fn_CheckKnb () { // 주민번호
        let lv_KnbResult = ''
        if (this.chkRrn) { // 주민번호 미확보 체크 선택시에는 주민번호 앞자리만 필수 체크
          if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6) {
            lv_KnbResult = this.custCardVO.knbFrno
          }
        } else {
          if (this.custCardVO.knbFrno !== '' && (this.custCardVO.knbBkno !== '' || this.custCardVO.knbBknoCipher !== '') &&
              this.custCardVO.knbFrno.length === 6) { // 주민번호 확보시에는 앞자리, 뒷자리 모두 입력되었는지 체크
            lv_KnbResult = this.custCardVO.knbFrno + this.custCardVO.knbBkno + this.custCardVO.knbBknoCipher
          }
        }
        return lv_KnbResult
      },
      fn_Gender: { // 주민번호 뒷자리 성별정보 입력 시 처리
        get: function () {
          return this.custCardVO.knbBkFstNo
        },
        set: function (data) {
          this.custCardVO.knbBkFstNo = data

          if (data.length === 1) {
            this.fn_NextComp ('telecomTypRef')
          }
        }
      },
      fn_IsForeigner: { // 외국인 체크박스
        get: function () {
          return this.isForeigner
        },
        set: function (data) {
          this.isForeigner = data

          if (data) {
            this.custCardVO.knbScCd = 'ZPER02' // 외국인

            this.$refs.vWatcher.clearValidation()
          } else {
            this.custCardVO.knbScCd = 'ZPER01' // 내국인
          }
        }
      },
      fn_KnbFrno: { // 주민번호 앞자리 변경시 호출 - 전체주민번호변수(custCardVO.knb)에 변경 값 처리
        get: function () {
          return this.custCardVO.knbFrno
        },
        set: function (data) {
          this.custCardVO.knbFrno = data

          if (data.length === 6) {
            this.fn_NextComp ('knbBknoRef')
          } else {
            // 비활성화 on/적합성 체크 문구 클리어
            this.disabled_knbBkno = true
            this.$refs['knbBknoRef'].clearValidation()
          }
        }
      },
      fn_TelecomType: { //통신사
        get: function () {
          return this.telecomType
        },
        set: function (data) {
          this.prevTelecomType = this.telecomType // 현재 선택되어 있는 값 복사
          this.telecomType = data
        }
      },
      fn_CelnoFrno: { // 핸드폰번호 앞자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
        get: function () {
          return this.custCardVO.celnoFrno
        },
        set: function (data) {
          if (this.custCardVO.celnoFrno !== null && this.custCardVO.celnoFrno !== data) {
            this.custCardVO.isCelnoChanged = true
          }

          this.custCardVO.celnoFrno = data
          this.custCardVO.celno = data + this.custCardVO.celnoBkno
          if (this.custCardVO.isCelnoChanged) { // 신규이거나 기존 고객의 핸드폰번호 수정시에만 체크
            this.fn_CheckCelno()
          }
        }
      },
      fn_CelnoBkno: { // 핸드폰번호 뒷자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
        get: function () {
          return this.custCardVO.celnoBkno
        },
        set: function (data) {
          if (this.custCardVO.celnoBkno !== '' && this.custCardVO.celnoBkno.replace('-', '').replace('-', '') !== data) {
            this.custCardVO.isCelnoChanged = true
          }
          this.custCardVO.celnoBkno = data
          this.custCardVO.celno = this.custCardVO.celnoFrno + data

          if (this.custCardVO.isCelnoChanged) { // 신규이거나 기존 고객의 핸드폰번호 수정시에만 체크
            this.fn_CheckCelno()
          }
        }
      },
      seconds () {
        let lv_Seconds = (this.remainDateValue - this.now) % 60
        if (lv_Seconds > 0) {
          return (this.remainDateValue - this.now) % 60
        } else {
          if (this.minutes === 0) {
            window.clearInterval(this.interval) // 타이머 중지
          }
          return 0
        }
      },
      minutes () {
        let lv_Minutes = Math.trunc((this.remainDateValue - this.now) / 60) % 60
        if (lv_Minutes > 0) {
          return Math.trunc((this.remainDateValue - this.now) / 60) % 60
        } else {
          return 0
        }
      },
      remainDateValue () {
        return Math.trunc(this.date / 1000)
      },
      fn_TelecomType: {
        get: function () {
          return this.telecomType
        },
        set: function (data) {
          this.prevTelecomType = Object.assign({}, this.telecomType) // 현재 선택되어 있는 값 복사
          this.telecomType = data
          if (data !== 'MCHK') { // 알뜰폰 확인 팝업 호출되어 제외함
            this.fn_NextComp('celnoRef')
          }
        }
      },
      modal(){
        return this.$refs.modal;
      }

    },//computed end
    /** watch 정의 영역 */
    watch: {
      telecomType (value) {
        if (value === 'MCHK') { // 알뜰폰 확인하기 선택 시 팝업창 호출 (MSPCM010P 팝업)
          this.popup010 = this.$bottomModal.open(MSPCM010P, {
            properties: {
              pCustNm: this.custCardVO.custNm // 고객명 파라미터
            },
            listeners: {
              confirmPopup: (pData) => {
                this.$bottomModal.close(this.popup010)
                
                // 파라미터 세팅
                this.fn_ConfirmMSPCM010P(pData)
              }
            }
          })
        }
      }

    },
    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created() {
      const lv_Vm = this

      this.fn_Init()
      this.agreeMsg = this.$t('cm')['ECMC036'] // "본인확인 서비스 이용에 동의하셔야 인증번호가 전송됩니다. 이용에 동의하시겠습니까?",
      this.agreeMsg2 = this.$t('cm')['ECMC079'] // "본인확인서비스 이용동의",
      
      if(!_.isEmpty(this.$route.params)){
        this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst //필컨 정보 여부
        this.mktChkList = this.$route.params.mktChkList //마컨 정보(광고성 수신동의)
        this.routeCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
        this.custCardVO.custNm = this.routeCustNm
        this.segmentType1 = this.$route.params.segmentType1 
        this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
        this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
      } else {
        let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo

        this.infoPrcusCnsntTypCd = custInfoStore.infoPrcusCnsntTypCd
        this.pCustNm = !this.$bizUtil.isEmpty(custInfoStore.custNm) ? custInfoStore.custNm : ''
      }

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back)

      // ************ 키패드 이벤트 등록 ************ //
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
        // 키패드 노출시 처리
        lv_Vm.ShowBottomBtn = false
      })
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
        // 키패드 숨김시 처리
        lv_Vm.ShowBottomBtn = true
      })

    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM008D')

      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

      // 화면 진입시, 키패드 활성화
      this.$refs['custNmRef'].focus()
    },
    /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
    beforeDestroy () {
      window.clearInterval(this.interval) // 타이머 실행이 아직도 되어 있다면 타이머 중지
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Back)

      // 키패드 이벤트 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_Init
       * 설명: 로딩시 정보항목 초기화 및 설정
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_Init () {
        const lv_Vm = this
        
        // 휴대전화 앞자리 정보 세팅
        // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
        let defaultValu = {value: '0', text: '선택'}
        let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
        this.phoneItems = [defaultValu, ...lv_CelphCD]

        // 휴대폰번호 앞자리 초기값 셋팅
        this.custCardVO.celnoFrno = '010'

        this.$nextTick(() => {
          lv_Vm.$refs['custNmRef'].focus()
        })
      },
      /******************************************************************************
      * Function명 : fn_AgreePopContent
      * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
      ******************************************************************************/
      fn_AgreePopContent () {
        let lv_Url = this.sCertVnoUrl
        if (this.agreeCheckbox) {
          if (this.$commonUtil.isMobile()) { // 모바일에서 작동
            window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
          } else { // PC에서 작동
            window.open(lv_Url, '_blank')
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_OpenVirtualKeypad
      * 설명       : 가상키패드 열기
      ******************************************************************************/
      fn_OpenVirtualKeypad (num) {
        let name = 'vKey' + num
        this.$refs[name].open()
      },
      /******************************************************************************
      * Function명 : fn_CheckCustNm
      * 설명       : 신규일 때 고객명 입력 후 focus out 될 때 호출
      ******************************************************************************/
      fn_CheckCustNm (param) { // 고객명 체크
        this.$refs.vWatcher.clearValidation()
        if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
          this.$refs['custNmRef'].validate()
          return
        }
        let lv_CustNmResult = this.custCardVO.custNm.trim()
        let regexp_eng = '[a-zA-Z]' // 영문(대소)
        
        this.custCardVO.custNm = lv_CustNmResult

        if (this.$bizUtil.isEmpty(lv_CustNmResult)) {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '필수 입력항목입니다'
          this.$refs.vWatcher.validate()
          return
        }

        // 한글 10글자, 영문은 30글자 제한
        if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
          if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
            lv_CustNmResult = lv_CustNmResult.substr(0, 30)
            this.custCardVO.custNm = lv_CustNmResult
          } else {
            lv_CustNmResult = lv_CustNmResult.substr(0, 10)
            this.custCardVO.custNm = lv_CustNmResult
          }
        }

        // 마케팅동의만 한 외국인인 경우 필수컨설팅 안내 문자 추가
        if (lv_CustNmResult.search(regexp_eng) > -1) {
          if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, 'ZPER02')) {
            if (this.infoPrcusCnsntTypCd==='19') {
              this.custNmResult = false // 이름 유효성 체크 결과
              this.custNmErrorMsg = '외국인 고객님은 마케팅동의 단독처리가 불가합니다.'
              this.$refs.vWatcher.validate()
              return
            } else {
              if (!this.isForeigner) {
                this.custNmResult = false // 이름 유효성 체크 결과
                this.custNmErrorMsg = '외국인 체크를 해주세요'
                this.$refs.vWatcher.validate()
                return
              }
            }
          }
        }

        if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, this.custCardVO.knbScCd)) {
          this.custNmResult = true // 이름 유효성 체크 결과
          // 기존고객 조회 서비스
          if (param === 'enter') { // 엔터키 입력시에만 고객조회함
            this.fn_SearchCustomerInfo()
            return
          }
        } else {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
        }

        this.$refs.vWatcher.validate()
        return this.custNmResult
      },
      /******************************************************************************
      * Function명 : fn_SearchCustomerInfo
      * 설명       : 기존고객 조회 (정보활용 동의 정보 조회 서비스 호출)
      ******************************************************************************/
      fn_SearchCustomerInfo () {
        this.isLoading = true
        let lv_Vm = this
        lv_Vm.custCardVO.isExistCustYN = '' // 기존/신규 여부 초기화

        const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
        lv_Vm.lv_FilterSearchCustNm = lv_Vm.custCardVO.custNm
        if (validOnlyEnglish(lv_Vm.lv_FilterSearchCustNm)) {
          lv_Vm.custCardVO.custNm = lv_Vm.lv_FilterSearchCustNm.toUpperCase()
        }

        let pParams =
                    {
                      cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
                      custNm: lv_Vm.custCardVO.custNm.trim() // 고객명
                    }
                    // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
        }
        let trnstId = 'txTSSCM10S4'

        this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
          // 필수 컨설팅 리스트
          if (response.body !== null) {
            if (response.body.cMInfoPrcusCnsntListVO !== null) {
              lv_Vm.items = response.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.items.length === 0) {
                // lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
                // lv_Vm.$refs.vWatcher.clearValidation()
                lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
                lv_Vm.disabled_exist_foreign = false
                lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
                lv_Vm.custCardVO.isExistCustYN = 'N'
                lv_Vm.custCardVO.chnlCustId = ''
                lv_Vm.custCardVO.knbFrno = ''
                lv_Vm.custCardVO.knbBkno = ''
                lv_Vm.custCardVO.knbBkFstNo = ''
                lv_Vm.custCardVO.celno = ''
                lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
                lv_Vm.custCardVO.celnoBkno = ''

                let focusTarget = null
                if (lv_Vm.isForeigner) {
                  // lv_Vm.fn_NextComp('knbFrnoRef2')
                  focusTarget = lv_Vm.$refs['knbFrnoRef2']
                } else {
                  // lv_Vm.fn_NextComp('knbFrnoRef1')
                  focusTarget = lv_Vm.$refs['knbFrnoRef1']
                }

                let alertMsg = '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요'
                lv_Vm.fn_OpenAlert(alertMsg, focusTarget)
              } else if (lv_Vm.items.length > 0) {
                // 고객 조회 팝업창 띄우기
                lv_Vm.popup005 = lv_Vm.$bottomModal.open(MSPCM005P, {
                  properties: {
                    pCustNm: lv_Vm.custCardVO.custNm // 고객명 파라미터
                  },
                  listeners: {
                    confirmPopup: (pData) => {
                      lv_Vm.$bottomModal.close(lv_Vm.popup005)

                      // 파라미터 세팅
                      lv_Vm.$refs.vWatcher.clearValidation()
                      lv_Vm.fn_ConfirmMSPCM005P(pData)
                    }
                  }
                })
              }
            } else {
              // lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
              // lv_Vm.$refs.vWatcher.clearValidation()
              lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
              lv_Vm.disabled_exist_foreign = false
              lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
              lv_Vm.custCardVO.isExistCustYN = 'N'
              lv_Vm.custCardVO.chnlCustId = ''
              lv_Vm.custCardVO.knbFrno = ''
              lv_Vm.custCardVO.knbBkno = ''
              lv_Vm.custCardVO.knbBkFstNo = ''
              lv_Vm.custCardVO.celno = ''
              lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
              lv_Vm.custCardVO.celnoBkno = ''

              // lv_Vm.$refs.knbFrnoRef.focus() // 생년월일 포커스이동
              // if (lv_Vm.isForeigner) {
              //   lv_Vm.fn_NextComp('knbFrnoRef2')
              // } else {
              //   lv_Vm.fn_NextComp('knbFrnoRef1')
              // }

              let focusTarget = null
              if (lv_Vm.isForeigner) {
                // lv_Vm.fn_NextComp('knbFrnoRef2')
                focusTarget = lv_Vm.$refs['knbFrnoRef2']
              } else {
                // lv_Vm.fn_NextComp('knbFrnoRef1')
                focusTarget = lv_Vm.$refs['knbFrnoRef1']
              }

              let alertMsg = '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요'
              lv_Vm.fn_OpenAlert(alertMsg, focusTarget)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /*********************************************************
       * Function명: fn_IsNextBtnVisible
       * 설명: 다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_IsNextBtnVisible (val, refNm) {
        if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
          this.isNextBtnVisible = val
          this.focusRefName = refNm
        }
      },
      /******************************************************************************
      * Function명 : fn_CheckCKnbGender
      * 설명       : 주민번호 뒷자리 성별구분코드에 따른 정합성 체크
      ******************************************************************************/
      fn_CheckCKnbGender () {
        let lv_Msg = '유효하지 않은 생년월일 정보입니다. 정확한 정보를 확인하시기 바랍니다.'

        // 주민번호 뒷자리 처리
        let lv_FrontYear = this.$bizUtil.addBirthFrontYear(this.custCardVO.knbFrno).substring(0, 2) // 19, 20 조회

        if (this.custCardVO.knbBkFstNo === '0' || this.custCardVO.knbBkFstNo === '9') {
          this.chkKnbResult = false
          this.knbErrorMsg = '주민번호 뒷자리를 확인해주세요.'
        } else if (this.custCardVO.knbFrno !== null && this.custCardVO.knbFrno !== '') {
          if (lv_FrontYear === '19') {
            if (this.isForeigner === true) {
              if (this.custCardVO.knbBkFstNo !== '5' && this.custCardVO.knbBkFstNo !== '6') {
                if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 해제하세요'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = lv_Msg
                }
              } else {
                this.chkKnbResult = true
              }
            } else {
              if (this.custCardVO.knbBkFstNo !== '1' && this.custCardVO.knbBkFstNo !== '2') {
                if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 하세요'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = lv_Msg
                }
              } else {
                this.chkKnbResult = true
              }
            }
          } else if (lv_FrontYear === '20') {
            if (this.isForeigner === true) {
              if (this.custCardVO.knbBkFstNo !== '7' && this.custCardVO.knbBkFstNo !== '8') {
                if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '주민번호 뒷자리를 확인해주세요.'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 해제하세요'
                }
              } else {
                this.chkKnbResult = true
              }
            } else {
              if (this.custCardVO.knbBkFstNo !== '3' && this.custCardVO.knbBkFstNo !== '4') {
                if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '주민번호 뒷자리를 확인해주세요.'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크 하세요'
                }
              } else {
                this.chkKnbResult = true
              }
            }
          }
        }
        
        if (this.chkKnbResult) {
          lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

          if (lv_Msg != '') {
            this.chkKnbResult = false
            this.knbErrorMsg = lv_Msg
          }
        }
        console.log(this.chkKnbResult + ' :::: ' + this.knbErrorMsg)
        return this.chkKnbResult
      },
      /******************************************************************************
      * Function명 : fn_CheckAgreePossible
      * 설명       : 만 14세 이상인지 여부 확인 후, 동의 여부 선택 가능한지 체크 - 필컨,마동 체크 선택이나 주민번호 입력 시 체크함.
      ******************************************************************************/
      fn_CheckAgreePossible () {
        let lv_Msg = ''
        if (this.infoPrcusCnsntTypCd) { // 필컨, 마동 둘중 하나라도 선택되어 있으면 14세 이상인지 체크
          if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6 && this.custCardVO.knbBkFstNo !== '') {
            let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.custCardVO.knbFrno, 14)

            if (lv_CheckAge === false) {
              lv_Msg = '해당 고객은 만 14세 미만으로 단독 동의진행이 불가합니다.'
              // this.getStore('confirm').dispatch('ADD', lv_Msg)
            }
          }
        }
        return lv_Msg
      },
      /******************************************************************************
      * Function명 : fn_CheckCelno
      * 설명       : 핸드폰번호 유효성 체크
      ******************************************************************************/
      fn_CheckCelno () {
        this.chkCelnoResult = true
        let lv_Celno = this.custCardVO.celno.replace('-', '').replace('-', '').replace('-', '').trim()

        if (this.custCardVO.celnoFrno === '0') { // 선택일 경우 무조건 에러 처리
          this.chkCelnoResult = false
          this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
        } else if (this.custCardVO.celnoFrno === '010') { // 010 번호인 경우 전체 자리수는 무조건 11자리
          if (lv_Celno.length < 11) {
            this.chkCelnoResult = false
            this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
          } else if (lv_Celno.length === 11) {
            // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
            this.fn_SelCelnoCheck(lv_Celno)
          }
        } else {
          if (lv_Celno.length < 10) { // 010 외에는 뒷자리가 7자리(xxx-xxxx)로 올수도 있음. 전체 자리수를 10자리를 max로 해서 체크
            this.chkCelnoResult = false
            this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
          } else {
            // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
            this.fn_SelCelnoCheck(lv_Celno)
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SelCelnoCheck
      * 설명       : 핸드폰번호 유효성 체크(임시 번호, FC와 동일번호 체크)
      ******************************************************************************/
      fn_SelCelnoCheck (paramCelno) {
        let lv_CustCelno = this.custCardVO.celno
        if (paramCelno !== undefined && paramCelno !== null && paramCelno !== '') { // created 시점의 임시번호 호출
          lv_CustCelno = paramCelno
        }
        if (lv_CustCelno === '01000000000' || lv_CustCelno === '0100000000' || lv_CustCelno === '01010000000' || lv_CustCelno === '01020000000' || lv_CustCelno === '01030000000') {
          this.chkCelnoResult = false
          this.celnoErrorMsg = '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
          return
        }

        if ((paramCelno !== undefined && paramCelno !== null && paramCelno !== '') || (this.custCardVO.isCelnoChanged && this.custCardVO.unAprvCustYn === 'N')) { // 임시번호 호출이나 기존 승인고객의 핸드폰변경이 있는경우 유효성 체크
          this.isLoading = true
          let lv_Vm = this

          // 고객 VO복사
          let lv_CustCardVO = Object.assign({}, lv_Vm.custCardVO)

          if (this.$commonUtil.isMobile()) {
            if (lv_CustCardVO.knbBknoCipher.length < 8) { // 모바일이고, 주민번호 변경 안한 케이스
              if (lv_CustCardVO.knbBknoCipher !== '1000000' && lv_CustCardVO.knbBknoCipher !== '2000000' &&
                lv_CustCardVO.knbBknoCipher !== '3000000' && lv_CustCardVO.knbBknoCipher !== '4000000') { // 주민번호 확보 고객은 knb 제거
                lv_CustCardVO.knb = ''
              }
              lv_CustCardVO.knbBknoCipher = '' // 주민번호 미변경
            }
          }

          lv_CustCardVO.celno = lv_CustCelno

          let pParams = lv_CustCardVO
          let trnstId = 'txTSSCM10S3'

          this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
            if (response.body !== null) {
              // 2023.06.17 사용자가 본인을 고객으로 등록할수 있으므로 '02' 코드 정상 처리
              if (response.body.celnoStatCo === '00' || response.body.celnoStatCo === '02') { // 정상일 경우
                lv_Vm.chkCelnoResult = true
              } else {
                if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호 (*화면에서 1차로 filtering함. 현재는 사용안함.)
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
                }
                // 2023.06.17 사용자가 본인을 고객으로 등록할수 있으므로 삭제(황고은 프로)
                //  else if (response.body.celnoStatCo === '02') { // 02: FC휴대번호와 동일한 경우
                //   lv_Vm.chkCelnoResult = false
                //   lv_Vm.celnoErrorMsg = '컨설턴트 핸드폰번호로 등록된 번호입니다.'
                // }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_BtnP1OnClick
      * 설명       : 핸드폰 인증번호 요청
      ******************************************************************************/
      fn_BtnP1OnClick () {
        if (!this.$refs.vWatcher.isValid()) { // 추가 유효성 검사
          return
        }

        if (this.fn_Validation()) {
          this.isLoading = true
          let lv_Vm = this
          this.lmsDspchRsltId = '' // LMS발송결과ID 초기화
          // 식별번호값 세팅
          let lv_knb = this.custCardVO.knbFrno + this.custCardVO.knbBkFstNo + '000000'

          // 마케팅 동의 이용기간, 권유방식 세팅
          let lv_MktCnvsMthTelYn = ''
          let lv_MktCnvsMthSmsYn = ''
          let lv_MktCnvsMthPstYn = ''
          let lv_MktCnvsMthEmailYn = ''
          let lv_MktCnsntAvlPrd = '' // 기간

          if (lv_Vm.infoPrcusCnsntTypCd === '18' || lv_Vm.infoPrcusCnsntTypCd === '19') { // 마케팅동의가 있는 경우에만 세팅
            let lv_MktChkList = this.mktChkList

            lv_MktCnvsMthTelYn = (lv_MktChkList.find(item => item === 'P')) ? 'Y' : 'N'
            lv_MktCnvsMthSmsYn = (lv_MktChkList.find(item => item === 'S')) ? 'Y' : 'N'
            lv_MktCnvsMthPstYn = (lv_MktChkList.find(item => item === 'M')) ? 'Y' : 'N'
            lv_MktCnvsMthEmailYn = (lv_MktChkList.find(item => item === 'E')) ? 'Y' : 'N'
            lv_MktCnsntAvlPrd = this.mktCnsntAvlPrd // 기간
          }

          let pParams =
                        {
                          custNm: lv_Vm.custCardVO.custNm, // 고객명
                          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
                          chnlCustId: '', // 채널고객ID
                          chnlCstgrId: '',
                          // knbFrno: lv_Vm.custCardVO.knbFrno, // 주민번호 앞자리
                          // knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher, // 주민번호 뒷자리(암호화)
                          knb: lv_knb, // 주민등록번호
                          infoPrcusCnsntTyoCd: lv_Vm.infoPrcusCnsntTypCd, // 정보활용동의유형코드(17:필수컨설팅동의, 19:마케팅동의, 18: 필컨/마동 둘다 선택, 11:전환설계)
                          custCelphCmucoNm: lv_Vm.telecomType, // 통신사 (SKT, KTF, LGT, SKTM, KTM, LGTM)
                          celno: lv_Vm.custCardVO.celno, // 전화번호
                          mktCnsntAvlPrd: lv_MktCnsntAvlPrd, // 마케팅동의 이용기간 (0~5)
                          telReciCnsntYn: lv_MktCnvsMthTelYn, // 마케팅동의권유방식(전화)
                          smsReciCnsntYn: lv_MktCnvsMthSmsYn, // 마케팅동의권유방식(문자)
                          emailReciCnsntYn: lv_MktCnvsMthEmailYn, // 마케팅동의권유방식(이메일)
                          pstReciRjtYn: lv_MktCnvsMthPstYn // 마케팅동의권유방식(우편)
                        }

          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
          //매니저가 컨설턴트를 변경했을경우
          this.stndKeyList = []
          if(this.$cmUtil.fn_GachangeTF()){
            this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
          }

          let trnstId = 'txTSSCM12I0'
          this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
            if (response.body !== null && response.body !== '' && response.body.niceAthntRespCd === '0000') {
              let lv_TmpResult = response.body
              if (lv_TmpResult) {
                lv_Vm.selfAthntRespUnQVal = lv_TmpResult.selfAthntRespUnQVal
                lv_Vm.nicePtptInstId = lv_TmpResult.nicePtptInstId
                lv_Vm.lmsDspchRsltId = lv_TmpResult.lmsDspchRsltId // LMS발송결과ID
                // 타이머 세팅 및 시작
                lv_Vm.isCountStart = true
                lv_Vm.date = moment(new Date()).add(7, 'm').toDate() // 인증 유효 시간을 7분으로 timer 세팅
                lv_Vm.interval = window.setInterval( () => {
                  lv_Vm.now = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0
                }, 1000)

                lv_Vm.authNumberDisabled = false // 인증번호 Text-Input 활성화

                lv_Vm.disabled_inComp = true // 정상처리인 경우 - 휴대폰 입력 컴포넌트 비활성화
                lv_Vm.disabled_exist = true // 생년월일 입력 컴포넌트 비활성화
                lv_Vm.disabled_exist_foreign = true
                lv_Vm.disabled_custNm = true // 고객명 입력 컴포넌트 비활성화
                lv_Vm.isBeforeSend = false // 인증번호 재전송 버튼으로 변경
                lv_Vm.aprvNoErrCnt = 0 // 실패 횟수는 다시 0으로 초기화

                lv_Vm.$nextTick(_ => {
                  lv_Vm.$refs['refInAuthNum'].focus()
                })
              }
              lv_Vm.isLoading = false
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_PrcsMoblAthntCnf
      * 설명       : 핸드폰 인증번호 확인
      ******************************************************************************/
      fn_PrcsMoblAthntCnf () {
        let lv_RetVal = true
        if (_.isEmpty(this.authNumber) || this.authNumber.length !== 6) {
          this.getStore('confirm').dispatch('ADD', '인증번호 정보가 정확하지 않습니다.') // 인증번호 정보가 정확하지 않습니다.
          lv_RetVal = false
        }
        if (!lv_RetVal) {
          return
        }

        this.isLoading = true
        let lv_Vm = this

        lv_Vm.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)
        let pParams =
                      {
                        smsAthntNo: lv_Vm.authNumber,
                        selfAthntRespUnqVal: lv_Vm.selfAthntRespUnQVal,
                        nicePtptInstId: lv_Vm.nicePtptInstId,
                        aprvNoErrCnt: lv_Vm.aprvNoErrCnt,
                        lmsDspchRsltId: lv_Vm.lmsDspchRsltId
                      }

        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        let trnstId = 'txTSSCM12S0'
        this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
          if (response.body !== null && response.body !== '' && response.body.niceAthntRespCd === '0000') {
            let lv_TmpResult = response.body
            if (lv_TmpResult) {
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              lv_Vm.isCountStart = false
              lv_Vm.isAuthError = false
              lv_Vm.isAuthCompleted = true
              
              // 인증 유형, 인증번호 저장을 위한 emit 호출
              lv_Vm.fn_AuthCompleted()
            }
            lv_Vm.isLoading = false
          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

            if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              lv_Vm.isCountStart = false
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증번호 버튼 클릭 시 유효성 체크
      ******************************************************************************/
      fn_Validation () {
        // 동의 체크 여부 확인
        // 휴대번호 체크
        let lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

        if (lv_Msg != '') {
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return false
        }

        let lv_RetVal = true
        if (this.agreeCheckbox === false) {
          // 본인확인 서비스 이용동의 모달창 띄움.
          // this.fn_AgreeCnfrmPop('Open')
          this.$refs.agreeCnfrmPop2.open()
          lv_RetVal = false
          return lv_RetVal
        } else if (this.custCardVO.isExistCustYN === undefined || this.custCardVO.isExistCustYN === '') {
          this.getStore('confirm').dispatch('ADD', '고객 조회 후 진행가능합니다.')
          lv_RetVal = false
          return lv_RetVal
        } else if (this.telecomType === null || this.telecomType.trim() === '') {
          this.getStore('confirm').dispatch('ADD', '통신사 정보를 확인하세요.')
          lv_RetVal = false
          return lv_RetVal
        } else if (this.$bizUtil.isEmpty(this.custCardVO.celnoFrno)) {
          this.getStore('confirm').dispatch('ADD', '휴대폰번호를 입력하세요.')
          lv_RetVal = false
          return lv_RetVal
        }

        if (this.infoPrcusCnsntTypCd === '19') {
          if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
            this.getStore('confirm').dispatch('ADD', '외국인 고객은 필수컨설팅 동의 후 진행가능합니다.')
            lv_RetVal = false
            return lv_RetVal
          }
        }
        /*
        else {
          if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
            if (!this.isForeigner) {
              this.getStore('confirm').dispatch('ADD', '외국인 체크를 확인하시기 바랍니다.')
              lv_RetVal = false
              return lv_RetVal
            }
          } else {
            if (this.isForeigner) {
              this.getStore('confirm').dispatch('ADD', '외국인 체크를 해제하시기 바랍니다.')
              lv_RetVal = false
              return lv_RetVal
            }
          }
        }
        */
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_AgreeConfirmYes
      * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
      ******************************************************************************/
      fn_AgreeConfirmYes () {
          this.agreeCheckbox = true
          this.fn_AgreePopContent()
      },
      /******************************************************************************
       * Function명: fn_OnNegative
       * 설명: Confirm창 취소 버튼 클릭 함수
       ******************************************************************************/
      fn_OnNegative () {
        // '취소' 선택시 vuex 에 confirm 창을 닫았다고 저장한다.
        this.getStore('spStore').dispatch('SMS_CONFIRM', 'CLOSE')
      },
      /******************************************************************************
       * Function명: fn_AuthCompleted
       * 설명: 인증 유형, 인증번호 저장을 위한 emit 호출
       ******************************************************************************/
      fn_AuthCompleted () {
        this.custCardVO.lmsDspchRsltId = this.lmsDspchRsltId
        this.$emit('authCompleted', {infoPrcusCnsntPathCd: '14', aprvNo: this.authNumber, custCardVO: this.custCardVO})
      },
      /******************************************************************************
       * Function명: fn_AuthSkip
       * 설명:       인증 스킵을 위한 서비스 호출(세션정보에 인증완료 저장)
       ******************************************************************************/
      fn_AuthSkip () {
        const lv_Vm = this

        let pParam = {
          cnsltNo: this.lv_UserInfo.userId,
          moblAthntNo: 'skip'

        }

        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParam.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
      
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        const trnstId = 'txTSSCM12I0'
        const auth = 'S'
        this.post(lv_Vm, pParam, trnstId, auth)
          .then(res => {
            if (!_.isEmpty(res.body) && res.body.niceAthntRespCd === '0000') {
              lv_Vm.fn_AuthCompleted()
            }
          })
          .catch(error => {
            window.vue.error(error)
          })

      },
      /******************************************************************************
      * Function명 : fn_ConfirmMSPCM005P
      * 설명       : 고객검색 팝업창에서 확인 버튼 클릭 시 호출.
                    - 팝업창 닫고, 팝업에서 넘어온 파라미터로 화면 셋팅
      ******************************************************************************/
      fn_ConfirmMSPCM005P (param) {
        const lv_Vm = this

        if (param != null) {
          if (param.isExistCustYN === 'Y') {
            this.custCardVO.custNm = param.custNm
            this.custCardVO.isExistCustYN = param.isExistCustYN
            this.custCardVO.chnlCustId = param.chnlCustId
            this.custCardVO.chnlCustScCd = param.chnlCustScCd
            this.custCardVO.chnlCstgrId =  param.chnlCstgrId
            this.custCardVO.knbFrno = param.knb.substring(0, 6)
            this.custCardVO.knbBkFstNo = param.knb.substring(6, 7)

            if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') { // 외국인인 경우
              this.isForeigner = true
              this.custCardVO.knbScCd = 'ZPER02'
            } else if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2' || this.custCardVO.knbBkFstNo === '3' || this.custCardVO.knbBkFstNo === '4') { // 내국인인 경우
              this.isForeigner = false
              this.custCardVO.knbScCd = 'ZPER01'
            }
            /************************************************************************************
            * ----------- 휴대전화 세팅 start -----------
            ************************************************************************************/
            if (param.celno !== undefined && param.celno.trim() !== '') {
              param.celnoOrg = param.celno.split('-')
              let lv_CelSplit = param.celno.split('-')
              let tmpCelnoFrno = lv_CelSplit[0] // 휴대전화 번호 앞자리

              // 조회한 휴대전화번호 앞자리의 값이 있는지 체크함. 없는 경우 tmpCelnoFrnoObj 빈 객체임.
              let tmpCelnoFrnoObj = this.phoneItems.filter(
                (value) => {
                  if (value.key === tmpCelnoFrno) {
                    return value
                  }
                }
              )

              // 휴대전화 번호 앞자리
              if (tmpCelnoFrnoObj.length > 0) {
                this.custCardVO.celnoFrno = tmpCelnoFrnoObj[0]
              } else {
                this.custCardVO.celnoFrno = tmpCelnoFrno
              }

              let lv_CelnoBkno = param.celno.substring(tmpCelnoFrno.length) // 휴대전화 번호 뒷자리
              this.custCardVO.celnoBkno = lv_CelnoBkno.replace(/\-/g, '') //.replace('-', '') // 첫번째 dash 제거

              this.fn_NextComp ('telecomTypRef') // 통신사선택
            }
            /************************************************************************************
             * ----------- 휴대전화 세팅 end -----------
             ************************************************************************************/
            this.disabled_exist = true // 생년월일, 외국인 입력 컴포넌트 비활성화
            this.disabled_exist_foreign = true
            this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
          } else {
            this.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
            this.disabled_exist_foreign = false
            this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
            this.custCardVO.isExistCustYN = param.isExistCustYN
            this.custCardVO.chnlCustId = ''
            this.custCardVO.chnlCstgrId = ''
            this.custCardVO.knbFrno = ''
            this.custCardVO.knbBkno = ''
            this.custCardVO.knbBkFstNo = ''
            this.custCardVO.celno = ''
            this.custCardVO.celnoFrno = this.phoneItems[1].value
            this.custCardVO.celnoBkno = ''

            let focusTarget = 'knbFrnoRef1'
            this.$nextTick(_ => {
              if (lv_Vm.isForeigner) {
                focusTarget = 'knbFrnoRef2'
              } else {
                focusTarget = 'knbFrnoRef1'
              }
              lv_Vm.fn_NextComp (focusTarget) // 주민번호앞자리포커스이동
            })

          }
        }
      },
      /******************************************************************************
      * Function명 : fn_ConfirmMSPCM010P
      * 설명       : 알뜰폰 안내 팝업창의 '확인', '취소' 버튼 클릭 시 호출
      ******************************************************************************/
      fn_ConfirmMSPCM010P (param) {
        if (param !== null && param !== '') {
          this.telecomType = param.val
        } else { // 취소버튼 클릭 시
          this.telecomType = this.prevTelecomType
        }
      },
      /******************************************************************************
       * Function명: fn_Back
       * 설명: 헤더 영역 Back Button 파라미터 넘기기
       *          >>>> 2차 개발중 요건변경으로 사용안함
       * Params: N/A
       * Return: N/A
       ******************************************************************************/
      fn_Back () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
        this.$router.go(-1)
        return
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM004M',
            params: {
              name: 'MSPCM020M',
              agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보
              mktChkList: this.mktChkList, //마컨 정보(광고성 수신동의)
              mktCnsntAvlPrd: this.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
              infoPrcusCnsntTypCd:  this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
              custNm: this.routeCustNm
            }
          }
        )
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      *          >>>> 2차 개발하였으나, 사용안함
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
      fn_OpenAlert (alertMsg, target) {
        const lv_Vm = this

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            single: true
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              if (!_.isEmpty(target)) {
                target.focus()
              }
            }
          }
        },{
          properties: {
            preventTouchClose: false
          }
        })
      },
      fn_NextComp (param) {
        if (param === 'knbFrnoRef1' || param === 'knbFrnoRef2') { // 이름에서 focus out 되었을 때
          if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
            return
          }
          this.$refs[param].focus()
        } else if (param === 'knbBknoRef') { // 주민번호 앞자리에서 focus out 되었을 때
          if (this.custCardVO.knbFrno.length < 6) {
            return
          }
          this.disabled_knbBkno = false
          this.$nextTick(_ => {
            this.$refs[param].focus()
          })
        } else if (param === 'telecomTypRef') { // 주민번호 뒷자리에서 focus out 되었을 때
          if (this.custCardVO.knbBkFstNo.length < 1) {
            return
          }
          this.$refs['knbBknoRef'].blur() // 주민번호 뒷자리는 포커스 아웃처리
          this.$refs[param].show()
        } else if (param === 'celnoRef') { // 통신사 선택 후
          this.$refs[param].focus()
        }

        // this.$refs[param].focus()
      },
      /******************************************************************************
      * Function명 : fn_AgreeCnfrmPop
      * 설명       : 이용동의 안내 
      *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
      ******************************************************************************/
      fn_AgreeCnfrmPop (param) {

        switch (param) {
          case 'Open':
            this.$refs.agreeCnfrmPop.open()
            break
          case 'Cancel':
            this.$refs.agreeCnfrmPop.close()
            this.fn_OnNegative()
            break
          case 'Confirm':
            this.$refs.agreeCnfrmPop.close()
            // this.fn_AgreeConfirmYes()
            this.fn_AgreeCnfrmPop2('Open') // 2023.05.12 링크호출X => 안내 바텀시트 호출
            break
        }
        
      },
      /******************************************************************************
      * Function명 : fn_AgreeCnfrmPop2
      * 설명       : 본인확인서비스 이용동의 안내 
      *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
      ******************************************************************************/
      fn_AgreeCnfrmPop2(param) {
        switch (param) {
          case 'Open':
            if(this.agreeCheckbox){
              this.agreeCheckbox = false
              this.$refs.agreeCnfrmPop2.open()
            }
            break
          case 'CloseBtn':
            this.$refs.agreeCnfrmPop2.close()
            this.fn_OnNegative()
            break
          case 'Confirm': // 약관 확인
            this.$refs.agreeCnfrmPop2.close('S')
            this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
            break
          case 'AllConfirm': // 전체 약관 확인
            this.$refs.agreeCnfrmPop2.close('S')
            this.fn_OnNegative()
            break
        }
      },
      /******************************************************************************
      * Function명 : fn_SheetClose
      * 설명       : 본인확인서비스 이용동의 안내 Close Fn
      *           - 약관동의로 Close / 강제로 Close 구분 목적
      * @param fn: BottomSheet Close Function
      * @param item: BottomSheet Event Name
      * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
      *         - 약관 동의하게되면 item = 'S' 
      ******************************************************************************/
      fn_SheetClose(fn_CloseSheet, item) {
        console.log('Event Name: ', item)
        this.agreeCheckbox = item === 'swipe' ? false : true
        fn_CloseSheet()
      },
    } //methods end
  };
</script>
<style scoped>
</style>