/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC007M
 * 화면 설명  : 유파트너 공지사항 목록
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="공지사항" :action-type="lv_IsMgrUserRol?actionSlot:''" :topButton="true">

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <mo-tab-box :default-idx="lv_TabIdx" class="ns-move-tab-box tween" @tab-change="fn_ShowTab">
        <mo-tab-label :idx="0">알림</mo-tab-label>
        <mo-tab-label :idx="1">팝업</mo-tab-label>
        <mo-tab-label :idx="2">게시</mo-tab-label>
      </mo-tab-box>

      <!-- tab01 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-alarm-menu" v-if="lv_TabIdx === 0">
        <div class="ns-swiper-area" v-if="fn_IsShowAlarmTypeComp">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-alarm-menu">
            <mo-segment-wrapper v-model="lv_SelectedAlarmType" point clearable @input="fn_ClickAlarmType">
              <mo-segment-button v-if="lv_IsShowAlarmTypeComp.cntc === true" value="CNTC">#접촉정보</mo-segment-button>
              <mo-segment-button v-if="lv_IsShowAlarmTypeComp.evt === true" value="EVT">#이벤트고객</mo-segment-button>
              <mo-segment-button v-if="lv_IsShowAlarmTypeComp.notif === true" value="NOTIF">#공지사항</mo-segment-button>
              <mo-segment-button v-if="lv_IsShowAlarmTypeComp.schdl === true" value="SCHDL">#내일정</mo-segment-button>
              <mo-segment-button v-if="lv_IsShowAlarmTypeComp.ts === true" value="TS">#TS신청</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </div>
      </ur-box-container>
      <!-- tab01 end -->

      <!-- tab02 -->
      <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum" v-else-if="lv_TabIdx === 1">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총<span class="count">{{lv_PopupData.totCnt}}</span>건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> -->
      <!-- tab02 end -->

      <!-- tab03 -->
      <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum" v-else-if="lv_TabIdx === 2">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총<span class="count">{{lv_AnuncData.totCnt}}</span>건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> -->
      <!-- tab03 end -->
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">
      <!-- tab01 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area" v-if="lv_TabIdx === 0">
        <ur-box-container v-if="lv_IsLoadingStatus || lv_AlarmList.length" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-img-list">
          <mo-list :list-data="lv_AlarmList">
            <template #list-item= "{item}">

              <!-- 하얀색 배경화면 class="did" 제거 -->
              <mo-list-item class="did">
              <!-- 하얀색 배경화면 class="did" 제거 -->

                <!--
                <img src="/images/icon_alarm1.svg" class="icon-alarm" alt="접촉정보 아이콘">
                <img src="/images/icon_alarm2.svg" class="icon-alarm" alt="내일정 아이콘">
                <img src="/images/icon_alarm3.svg" class="icon-alarm" alt="공지사항 아이콘">
                <img src="/images/icon_alarm4.svg" class="icon-alarm" alt="시스템공지 아이콘">
                <img src="/images/icon_alarm5.svg" class="icon-alarm" alt="이벤트 아이콘">-->
                <img v-bind="fn_GetAlarmIcon(item.iapKndCd)" class="icon-alarm" @click="fn_SelAlarmDetail(item)">
                <div class="list-item__contents" @click="fn_SelAlarmDetail(item)">
                    <div class="list-item__contents__second">
                      <span>{{fn_GetIapPretxt(item)}}</span><span class="txtGray">{{fn_GetIapReciDtm(item.iapReciDtm)}}</span>
                    </div>
                    <div class="list-item__contents__title">
                      <span>{{fn_GetIapCntnt(item)}}</span>
                    </div>
                    <div class="list-item__contents__text">
                      <!-- <span class="txtSkip">{{item.data4}}</span>
                      <span class="add-img">
                        <!- - 첨부 이미지 영역입니다. - ->
                        <img src="/images/icon_finger_complete2.svg" class="icon-alarm" alt="접촉정보 아이콘">
                      </span> -->

                      <!-- 관리자푸쉬발송의 경우 개행문자를 br태그로 치환 -->
                      <span v-if="item.iapKndCd !== '4'" class="txtSkip" v-html="fn_GetDtlCntnt(item.iapKndCd, item.mobslIapDtlCntnt)" ></span>
                      <!-- 시스템공지인 경우 본문은 노출하지 않음 -->
                      <!-- <span v-if="item.iapKndCd === '4'" v-html="fn_GetDtlCntntForSysNotice(item.mobslIapDtlCntnt)" class="txtSkip"></span> -->
                      <span class="add-img" v-if="fn_GetIsShowImg(item)">
                        <!-- 첨부 이미지 영역입니다. -->
                        <!-- <img :src="fn_GetSrcImg(item)" class="icon-alarm" alt=""> -->
                        <img :src="item.thumbImg" class="icon-alarm" alt="">
                      </span>
                    </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <!-- no-data --> 
        <ur-box-container v-else-if="!lv_IsLoadingStatus" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data mt100">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="ns-ftcr-gray"><p class="mt20">등록된 게시물이 없어요.</p></span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
      <!-- tab01 end -->

      <!-- tab02 -->
      <ur-box-container direction="column" alignV="start" class="msp-bc-wrap"  v-else-if="lv_TabIdx === 1">
        <ur-box-container v-if="lv_IsLoadingStatus || lv_PopupData.list.length" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
            <mo-list :list-data="lv_PopupData.list">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents" @click="fn_SelPopupDetail(item)">
                    <div class="list-item__contents__title">
                      <span class="name">{{item.iapCntnt}}</span>
                    </div>
                    <div class="list-item__contents__info mt10">
                      <div class="info_box">
                        <mo-badge class="badge-sample-badge badge-box red sm mr10" text="" shape="status" v-if="item.isNewRecord || item.cnfYn === 'N'">New</mo-badge>
                        <!-- <span>{{item.data2}}</span>
                        <span class="ml6">{{item.data3}}</span> -->
                        <span>{{item.iapReciDtm}}</span>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
        <!-- no-data --> 
        <ur-box-container v-else-if="!lv_IsLoadingStatus" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data mt100">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="ns-ftcr-gray"><p class="mt20">등록된 게시물이 없어요.</p></span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
      <!-- tab02 end -->

      <!-- tab03 -->
      <ur-box-container direction="column" alignV="start" class="msp-bc-wrap"  v-else-if="lv_TabIdx === 2">
        <ur-box-container v-if="lv_IsLoadingStatus || lv_AnuncData.list.length" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
            <mo-list :list-data="lv_AnuncData.list">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents" @click="fn_SelAnuncDetail(item)">
                    <div class="list-item__contents__title">
                      <span class="name" v-html="item.iapCntnt"></span>
                    </div>
                    <div class="list-item__contents__info mt10">
                      <div class="info_box">
                        <mo-badge class="badge-sample-badge badge-box red sm" text="" shape="status" v-if="item.iapCnfYn === 'N'">New</mo-badge>
                        <mo-badge class="badge-sample-badge lightblue sm mr10" text="" shape="status">{{item.iapKndNm}}</mo-badge>
                        <span>{{item.iapReciDtm}}</span>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
        <!-- no-data --> 
        <ur-box-container v-else-if="!lv_IsLoadingStatus" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data mt100">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="ns-ftcr-gray"><p class="mt20">등록된 게시물이 없어요.</p></span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
      <!-- tab03 end -->
    </ur-box-container>

    <!-- 스크롤 List -->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
// import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import MenuConst from '@/ui/sp/MenuConstans.js'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPBC006P from '@/ui/bc/MSPBC006P.vue'
import MSPBC007P from '@/ui/bc/MSPBC007P.vue'
import MSPBC008P from '@/ui/bc/MSPBC008P.vue'
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC007M',
  screenId: 'MSPBC007M',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  async created() {
    // 관리자 권한 조회
    await this.fn_SelUserRol()
    const template = '<div slot="action" class="icon-wrapper icon-wrapper-type">' +
                        '<mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_OpenMSPBC011M">등록하기</mo-button>' +                        
                      '</div>'
    
    this.actionSlot = {
      actionComponent: {
        template: template,
        methods: {          
          fn_OpenMSPBC011M() {            
            this.$router.push({name: 'MSPBC011M', params: {}})
          },
        }
      }
    }
      

    // FrameNavi 영역 선택 처리
    this.$BottomManager.fn_SetBottomNavigationSelectedMenu('notification')

    // 스크롤 컴포넌트ID
    this.scrollCompID = 'scroll_' + this.$options.screenId
    console.log('this.scrollCompID', this.scrollCompID)

    // PUSH 관련 처리 flag 설정 - true인 경우 알림리스트 조회 후 관련 후행 처리를 함
    if ( this.$route.params !== undefined && this.$route.params !== null && this.$route.params !== '' ) {
      this.lv_IsProcForPush = true
    } else {
      this.lv_IsProcForPush = false
    }

    // 오늘일자 조회
    let lv_Vm = this
    this.$commonUtil.getServerTime().then(function (pResponse) {
      lv_Vm.lv_Today = pResponse
      console.log('this.lv_Today', lv_Vm.lv_Today, typeof (lv_Vm.lv_Today))
    })

    this.fn_ShowTab()
  },
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 스크롤 애니메이션 instance 선언
    // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    // console.log('lv_HeaderviewScrollCompID', this.lv_HeaderviewScrollCompID)
    // // this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.scrollCompID), 'y')
    // this.pageScrollAnimateY = createScrollAnimateInstance(document.getElementById(this.scrollCompID), 'y')
    // this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.lv_HeaderviewScrollCompID), 'y')
    
    // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_TabIdx: 0,

      scrollCompID: '', // 스크롤영역 ID
      // scrollAnimateY: null,
      // lv_HeaderviewScrollCompID: '',
      // pageScrollAnimateY: null,
      lv_AlertPop: '', // bottom alert popup
      lv_IsLoadingStatus: true,
      lv_HasNewAlarm: false, // 새알림여부
      lv_AlarmList: [], // 알림리스트
      lv_NextKey: '1',
      lv_IsNextSearchFlag: false,
      lv_PopupData: {
        list: [], // 팝업리스트
        stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: 'start'}, {stndKeyId: 'rowCnt', stndKeyVal: 40}],  // 더보기 key
        totCnt: 0,
        iapKndCd: '7', // 알람종류코드(4:시스템공지, 5:공지사항(전사), 6:공지사항(지점), 7:팝업)
      },
      lv_AnuncData: {
        list: [], // 게시리스트
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}], // 더보기 key
        stndKeyVal: 'start',
        keyVal: 1,
        noData: '',
        totCnt: 0,
        iapKndCd: '5', // 알람종류코드(4:시스템공지, 5:공지사항(전사), 6:공지사항(지점), 7:팝업)
      },
      lv_ReadCount: 200,
      // 조회조건(ALL: 전체, CNTC: 접촉고객, EVT: 이벤트고객, NOTIF:공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약 41: 프리미엄고객사랑서비스 방문결과 미입력), SCHDL: 일정)
      lv_SrchCond: 'ALL',
      lv_IapUkepPeriode: '',
      lv_IsProcForPush: false,
      lv_SelectedAlarmType: '', // 알림구분
      popupBC006P: '', // 알림상세 팝업화면
      popupBC008P: '', // 팝업상세 팝업화면
      popupBC007P: '', // 게시상세 팝업화면
      lv_Today: '', // 오늘일자
      lv_IsShowAlarmTypeComp: {
        cntc: false, // 접촉고객
        evt: false, // 이벤트고객
        schdl: false, // 접촉고객
        notif: false, // 공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력)
        ts: false
      },
      lv_IsMgrUserRol: false,      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_IsShowAlarmTypeComp () {
      let t_RtnFlag = false

      // 두개 이상 true이면 화면노출
      let t_Cnt = 0
      if ( this.lv_IsShowAlarmTypeComp.cntc === true ) t_Cnt++ // 접촉고객
      if ( this.lv_IsShowAlarmTypeComp.evt === true ) t_Cnt++ // 이벤트고객
      if ( this.lv_IsShowAlarmTypeComp.schdl === true ) t_Cnt++ // 접촉고객
      if ( this.lv_IsShowAlarmTypeComp.notif === true ) t_Cnt++ // 공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력)
      if ( this.lv_IsShowAlarmTypeComp.ts === true ) t_Cnt++ // TS신청

      if ( t_Cnt > 1 ) t_RtnFlag = true
      return t_RtnFlag
    }    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * Function명 : fn_ShowTab
     * 설명       : 탭 선택 및 조회
     * @param {idx}
     * @return {none} - 반환없음
     */
    fn_ShowTab (idx = this.lv_TabIdx) {
      this.lv_TabIdx = idx
      if (idx === 0) {
        // 알림리스트 조회
        this.fn_SelAlarmListFirst()
        
        // 새알림건수 조회
        this.fn_SelNewAlarmCnt()

      } else if (idx === 1) {
        // 팝업리스트 조회
        this.fn_SelPopupListFirst()

      } else if (idx === 2) {
        // 게시리스트 조회
        this.fn_SelAnuncListFirst()

      } else {
        console.error('tab index error');
      }
    },
    /**
     * Function명 : fn_ClickAlarmType
     * 설명       : 알림종류 선택 event frunction
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_ClickAlarmType () {
      console.log('fn_ClickAlarmType call', this.lv_SelectedAlarmType)

      if (this.lv_SelectedAlarmType.trim() === '') {
        this.lv_SrchCond = 'ALL'
      } else {
        // 조회조건(ALL: 전체, CNTC: 접촉고객, EVT: 이벤트고객, NOTIF:공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력), SCHDL: 일정)
        this.lv_SrchCond = this.lv_SelectedAlarmType
      }

      // 알림리스트 조회
      this.fn_SelAlarmListFirst()
    },
    /**
     * Function명 : fn_GetIapReciDtm
     * 설명       : 알림수신일시를 월일 형태로 변환
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIapReciDtm (pDate) {
      // 파라미터 check
      if ( pDate === undefined || pDate === null || pDate.length === '' ) return pDate

      let t_RtnDate = ''
      // 당일건은 몇분전, 몇시간전 표기하며 그외는 X월 X일 YYYYMMDDHH24MISS
      if ( moment(pDate).format('YYYYMMDD') === moment(this.lv_Today).format('YYYYMMDD') ) {
        let date1 = new Date(moment(pDate))
        let date2 = new Date(moment(this.lv_Today))

        // 24 * 60 * 60 * 1000
        let t_RtnCalProc = (date2 - date1) / (60 * 1000)
        if ( Number(t_RtnCalProc) < 1 ) {
          // 1분전이면
          t_RtnDate = '방금'
        } else if (  Number(t_RtnCalProc) < 60 ) {
          // 1시간 이내 건이면 몇분전으로 표기
          t_RtnDate = Math.floor(Number(t_RtnCalProc)) + '분 전'
        } else {
          // 1시간 이후 건이면 몇시간전으로 표기
          let t_RtnReCalProc = (date2 - date1) / (60 * 60 * 1000)
          t_RtnDate = Math.floor(Number(t_RtnReCalProc)) + '시간 전'
        }
      } else {
        let t_TransDate = moment(pDate).format('YYYYMMDD')
        t_RtnDate = Number(t_TransDate.substr(4, 2)) + '월 ' + Number(t_TransDate.substr(6, 2)) + '일'
      }
      return t_RtnDate
    },
    /**
     * Function명 : fn_GetIapPretxt
     * 설명       : 알림종류명 getter
     * @param {none}
     * @return {none}
     */
    fn_GetIapPretxt (pItem) {
      let t_RtnIapPretxt = ''
      let t_IapKndCd = pItem.iapKndCd

      // 공지사항(12:관리자Push등록, 13:선지급명세, 41: 프리미엄고객사랑서비스 방문결과 미입력)
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE
        || t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_PRPYM_SPCF ) {
        t_RtnIapPretxt = '공지 및 알림'
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE ) {
        t_RtnIapPretxt = '프리미엄사랑서비스'
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_TS_SAVE_NOTICE ) { // 55
        t_RtnIapPretxt = 'TS신청'
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CMPGN_EVT_NOTICE ) { // 42
        t_RtnIapPretxt = '캠페인이벤트'
      } else {
        t_RtnIapPretxt = pItem.iapPretxt
      }
      return t_RtnIapPretxt
    },
    /**
     * Function명 : fn_GetAlarmIcon
     * 설명       : 알림종류별 아이콘 getter
     * @param {none}
     * @return {none} - /images/icon_alarm1.svg ~ /images/icon_alarm5.svg
     */
    fn_GetAlarmIcon (pIapKndCd) {
      // 접촉고객, EVT: 이벤트고객, NOTIF:공지사항(12:관리자Push등록, 13:선지급명세, 41: 프리미엄고객사랑서비스 방문결과 미입력), SCHDL: 일정, 시스템공지
      let t_RtnIconSrc = '/images/'
      let t_RtnIconImg = ''
      let t_RtnIconAlt = ''

      // 접촉고객
      if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        t_RtnIconImg = 'icon_alarm1.svg'
        t_RtnIconAlt = '접촉정보 아이콘'
      }

      // 이벤트고객
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        t_RtnIconImg = 'icon_alarm5.svg'
        t_RtnIconAlt = '이벤트 아이콘'
      }

      // 공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력, 80:초회보험료, 81:심사완료, 82:보장설계PDF, 83:골든룰, 84:유의대체계약, 95:휴일문의처리, 96:전화받기 재로그인 요청)
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PRPYM_SPCF
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PM_RENT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_FRST_TM_PREM
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_UNDW_CMPLT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PRCS_CNTC
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_GOLDEN_RULE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ATNT_RPLC_CONT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_HOLY_MSG_RESP_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PHONE_RECV_LOGIN_NOTICE ) {
        t_RtnIconImg = 'icon_alarm3.svg'
        t_RtnIconAlt = '공지사항 아이콘'
      }

      // 일정
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE ) {
        t_RtnIconImg = 'icon_alarm2.svg'
        t_RtnIconAlt = '내일정 아이콘'
      }

      // 시스템공지
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) {
        t_RtnIconImg = 'icon_alarm4.svg'
        t_RtnIconAlt = '시스템공지 아이콘'
      }
      return {
        src: t_RtnIconSrc + t_RtnIconImg,
        alt: t_RtnIconAlt
      }
    },
    /**
     * Function명 : fn_GetIapCntnt
     * 설명       : 알림내용 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIapCntnt (pItem) {
      let t_RtnTxt = ''
      if ( pItem === undefined || pItem === null || pItem.length <= 0 ) {
        t_RtnTxt = ''
      } else {
        t_RtnTxt = pItem.iapCntnt

        let t_IapKndCd = pItem.iapKndCd
        // 알림종류코드가 접촉고객(2)인 경우 '[접촉정보]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
          t_RtnTxt = t_RtnTxt.replace('[접촉정보]', '').trim()
        }

        // 알림종류코드가 이벤트고객(9)인 경우 '[활동캘린더]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
          t_RtnTxt = t_RtnTxt.replace('[활동캘린더]', '').trim() // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M
        }

        // 알림종류코드가 이벤트고객(41)인 경우 '[프리미엄고객사랑서비스]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE ) {
          t_RtnTxt = t_RtnTxt.replace('[프리미엄고객사랑서비스]', '').trim()
        }
      }
      return t_RtnTxt
    },
    /**
     * Function명 : fn_GetIsShowImg
     * 설명       : 썸네일 이미지 노출여부 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIsShowImg (pItem) {
      // 썸네일이미지 존재 시 노출함
      let t_RtnIsShowImg = false
      // 12:관리자Push등록
      if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          if ( t_ArrIapInqrKeyVal[0] !== undefined && t_ArrIapInqrKeyVal[0] !== null && t_ArrIapInqrKeyVal[0] !== '' ) {
            t_RtnIsShowImg = true
          }
        }
      }
      return t_RtnIsShowImg
    },
    /**
     * Function명 : fn_GetSrcImg
     * 설명       : 썸네일 이미지 src getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetSrcImg (pItem) {
      // 썸네일이미지 존재 시 노출함
      let t_RtnSrcImg = ''
      // 12:관리자Push등록
      if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          if ( t_ArrIapInqrKeyVal[0] !== undefined && t_ArrIapInqrKeyVal[0] !== null || t_ArrIapInqrKeyVal[0] !== '' ) {
            t_RtnSrcImg = t_ArrIapInqrKeyVal[0]
          }
        }
      }
      return t_RtnSrcImg
    },
    /**
     * Function명 : fn_ScrollTo
     * 설명       : scroll 화면의 scrollTop 위치로 이동
     * @param {Number} scrollTop - 이동하고자 하는 세로 위치(0: 최상단)
     * @return {none} - 반환없음
     */
    // fn_ScrollTo (scrollTop) {
    //   this.pageScrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
    //   this.scrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
    // },
    /**
     * Function명 : fn_SelAlarmListFirst
     * 설명       : 알림리스트 최초 조회
     * @return {none} - 반환없음
     */
    fn_SelAlarmListFirst () {
      this.lv_AlarmList = [] // 알림리스트 초기화
      this.lv_NextKey = '1' // 다음조회키값 초기화
      this.lv_IsNextSearchFlag = false // 다음조회여부 초기화
      
      // 알림리스트 조회
      this.fn_SelAlarmList()
    },
    /**
     * Function명 : fn_SelPopupListFirst
     * 설명       : 팝업리스트 최초 조회
     * @return {none} - 반환없음
     */
    fn_SelPopupListFirst () {
      // this.lv_PopupData.list = [] // 팝업리스트 초기화
      // this.lv_IsLoadingStatus = false
      
      // 팝업리스트 조회
      this.fn_SelPopupList()
    },
    /**
     * Function명 : fn_SelAnuncListFirst
     * 설명       : 게시리스트 최초 조회
     * @return {none} - 반환없음
     */
    fn_SelAnuncListFirst () {
      // this.lv_AnuncData.list = [] // 게시리스트 초기화
      // this.lv_IsLoadingStatus = false

      // 게시리스트 조회
      this.fn_SelAnuncList()
    },
    /*********************************************************
     * Function명: fn_SelAlarmList
     * 설명: 알림리스트 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelAlarmList () {
      let lv_Vm = this
      let pParams = {
        nextKey: this.lv_NextKey,
        readCount: this.lv_ReadCount,
        srchCond: this.lv_SrchCond // 조회조건(ALL: 전체, CNTC: 접촉고객, EVT: 이벤트고객, NOTIF:공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력), SCHDL: 일정)
      }

      const trnstId = 'txTSSBC03SB'
      const auth = 'S'
      this.lv_IsLoadingStatus = true
      this.getStore('progress').dispatch('SKEL_LIST')
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( response && response.body && response.body.returnList ) {

            let tempList = response.body.returnList

            // 알림리스트 목록 중 제외
            if (tempList?.length) {              
              tempList = tempList.filter(item => item.iapKndCd !== MenuConst.PUSH_ALARM_TYPE_PHONE_RECV_LOGIN_NOTICE)
            }

            lv_Vm.lv_AlarmList = lv_Vm.lv_AlarmList.concat(tempList)
            console.log('알림리스트 조회', lv_Vm.lv_AlarmList)

            // 알림유지기간 설정
            if ( response.body.iapUkepPeriode !== undefined && response.body.iapUkepPeriode !== null && response.body.iapUkepPeriode !== '' ) {
              lv_Vm.lv_IapUkepPeriode = response.body.iapUkepPeriode
            }

            // 다음조회키 설정
            if ( lv_Vm.lv_NextKey === response.body.nextKey ) {
              // 다음조회건 없음
              lv_Vm.lv_IsNextSearchFlag = false
            } else {
              // 다음조회건 있음
              lv_Vm.lv_IsNextSearchFlag = true
              lv_Vm.lv_NextKey = response.body.nextKey
            }

            // 전체조회인 경우 알림종류컴포넌트 설정
            if ( lv_Vm.lv_SrchCond === 'ALL' ) lv_Vm.fn_SetAlarmTypeComp()

            // param 설정되어 있을 경우 관련 처리
            if ( lv_Vm.lv_IsProcForPush === true ) lv_Vm.fn_ProcCallBackForPush()
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.lv_IsLoadingStatus = false
        })
      lv_Vm.lv_IsLoadingStatus = false
    },
    /*********************************************************
     * Function명: fn_SelPopupList
     * 설명: 팝업리스트 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelPopupList () {
      const pParams = {
        iapKndCd: this.lv_PopupData.iapKndCd
      }

      const trnstId = 'txTSSBC11S4'
      const auth = 'S'
      const lv_Vm = this

      if (lv_Vm.lv_PopupData.stndKeyList[0].stndKeyVal !== '') {
        this.lv_IsLoadingStatus = true
        this.getStore('progress').dispatch('SKEL_LIST')
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
          // 서버 데이터 가져오기
            if (response.body !== null && response.body !== '') {
              let responseList = response.body.ppReturnList

              if (lv_Vm.lv_PopupData.stndKeyList[0].stndKeyVal !== 'start' ) {
                // responseList.forEach((item) => {
                //   lv_Vm.lv_PopupData.totCnt = item.totalCnt || 0
                //   lv_Vm.lv_PopupData.list.push(item)
                // })
              } else {
                lv_Vm.lv_PopupData.list = responseList || []
                lv_Vm.lv_PopupData.totCnt = response.body.ppTot || 0
                // lv_Vm.lv_PopupData.list[0].cnfYn = 'Y'
                // lv_Vm.fn_openImg(lv_Vm.lv_PopupData.list[0])
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.lv_PopupData.stndKeyList = response.trnstComm.stndKeyList
          })
          .catch(function (error) {
            window.vue.error(error)
          })
          .finally(() => {
            lv_Vm.lv_IsLoadingStatus = false
          })
        lv_Vm.lv_IsLoadingStatus = false
      }
    },
    /*********************************************************
     * Function명: fn_SelAnuncList
     * 설명: 게시리스트 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelAnuncList () {
      if (this.lv_AnuncData.stndKeyVal === 'end') {
        return
      }
      if (this.lv_AnuncData.stndKeyVal === 'start') {
        this.lv_AnuncData.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
      }
      let pParams = {iapKndCd: this.lv_AnuncData.iapKndCd, iapInqrKeyVal: this.lv_AnuncData.keyVal}

      const trnstId = 'txTSSBC11S1'
      const auth = 'S'
      let lv_Vm = this
      // let lv_Message = lv_Vm.$t('bc')['EBCC002']

      this.lv_IsLoadingStatus = true
      this.getStore('progress').dispatch('SKEL_LIST')
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // let cnt = 0
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.returnList

            if (lv_Vm.lv_AnuncData.stndKeyList[0].stndKeyVal !== 'start' ) {
              responseList.forEach((item) => {
                lv_Vm.lv_AnuncData.totCnt = item.totalCnt || 0
                lv_Vm.lv_AnuncData.list.push(item)
              })
              lv_Vm.lv_AnuncData.keyVal++
            } else {
              lv_Vm.lv_AnuncData.list = responseList
            }
            // if (lv_Vm.lv_AnuncData.totCnt === 0) {
            //   lv_Vm.lv_AnuncData.noData = lv_Message.replace('@', response.body.iapUkepPeriode )
            // }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.lv_AnuncData.stndKeyList = response.trnstComm.stndKeyList
          if (lv_Vm.lv_AnuncData.list.length >= lv_Vm.lv_AnuncData.totCnt) {
            lv_Vm.lv_AnuncData.stndKeyVal = 'end'
          }

          // cnt = (lv_Vm.lv_AnuncData.list) ? lv_Vm.lv_AnuncData.list.length : 0
          // total 건수 TSSBC150M에 전달
          // window.vue.getInstance('vue').$emit('postResult_TSSBC150M.tab1', cnt)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.lv_IsLoadingStatus = false
        })
      lv_Vm.lv_IsLoadingStatus = false
    },
    /*********************************************************
     * Function명: fn_SetAlarmTypeComp
     * 설명: 조회된 알림리스트 중 존재하는 알리종류만 화면상단 알림종류 뱃지 노출
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetAlarmTypeComp () {
      this.lv_IsShowAlarmTypeComp.cntc = false // 접촉고객
      this.lv_IsShowAlarmTypeComp.evt = false // 이벤트고객
      this.lv_IsShowAlarmTypeComp.schdl = false // 접촉고객
      this.lv_IsShowAlarmTypeComp.notif = false // 공지사항(12:관리자Push등록, 13:선지급명세, 20:재가입계약, 41: 프리미엄고객사랑서비스 방문결과 미입력)
      this.lv_IsShowAlarmTypeComp.ts = false // TS신청

      // 알림리스트에 존재하는 check
      for ( let i=0; i < this.lv_AlarmList.length; i++ ) {
        let t_IapKndCd = this.lv_AlarmList[i].iapKndCd
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) { // 통합접촉고객
          this.lv_IsShowAlarmTypeComp.cntc = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) { // 이벤트 알림
          this.lv_IsShowAlarmTypeComp.evt = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE ) { // 일정
          this.lv_IsShowAlarmTypeComp.schdl = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE ) { // 프리미엄 고객사랑서비스 방문결과 미등록
          this.lv_IsShowAlarmTypeComp.notif = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) { // 관리자푸시발송
          this.lv_IsShowAlarmTypeComp.notif = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_PRPYM_SPCF ) { // 선지급명세
          this.lv_IsShowAlarmTypeComp.notif = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_PM_RENT ) { // 재가입계약
          this.lv_IsShowAlarmTypeComp.notif = true
          continue
        }else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_TS_SAVE_NOTICE ) { // TS신청
          this.lv_IsShowAlarmTypeComp.ts = true
          continue
        } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CMPGN_EVT_NOTICE ) { // 캠페인이벤트
          this.lv_IsShowAlarmTypeComp.notif = true
          continue
        }
      }
    },
    /*********************************************************
     * Function명: fn_SelAlarmDetail
     * 설명: 알림상세조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelAlarmDetail (pItem) {
      console.log('fn_SelAlarmDetail call', pItem)

      // 알림읽음 처리
      this.fn_UpdIapCnfYn(pItem)

      // 관련 페이지로 이동
      let t_IapKndCd = pItem.iapKndCd
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_TS_SAVE_NOTICE ) { // TS신창
        this.$router.push({name: 'MSPRC350M'})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CMPGN_EVT_NOTICE ) { // 캠페인이벤트
        // 캠페인고객(MSPCM160M)
        this.$router.push({name: 'MSPCM160M'})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) { // 통합접촉고객
        let tmpObj = pItem
        tmpObj.trtYmd = pItem.occuYmd
        tmpObj.detailOpen = true
        if (this.DEBUG) console.log(tmpObj)
        // 통합 접촉고객(MSPCM121M)
        this.$router.push({name: 'MSPCM121M', params: tmpObj})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) { // 이벤트 알림
        // 활동캘린더(MSPAP320M) 
        this.$router.push({name: 'MSPAP320M'}) // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE ) { // 일정
        let tmpObj = pItem
        tmpObj.pSchdlYmd = pItem.custCntcId
        tmpObj.isMSPAP301M = true
        // this.$router.push({name: 'MSPAP301M', params: tmpObj}) // 화면ID변경됨
        this.$router.push({name: 'MSPAP314M', params: tmpObj})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE ) { // 프리미엄 고객사랑서비스 방문결과 미등록
        // 프리미엄 고객사랑 서비스(MSPCM303M)
        this.$router.push({name: 'MSPCM303M'})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) { // 시스템공지
        // 알림상세팝업 호출
        this.fn_OpenMSPBC006P(pItem)
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) { // 관리자푸시발송
        /* 1. PUSH 관리자 발송(사랑온 미니)의 경우
        *    1) 본문 또는 본문 이미지파일 미존재 시
        *     - 이동화면 설정 건 - 관련 화면으로 이동
        *     - 이동화면 미설정 건 - 상세화면으로 이동
        *    2) 본문 또는 본문 이미지파일 존재 시
        *     - 상세화면으로 이동
        */
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal // 썸네일이미지|본문이미지|화면이동구분|화면ID 로 구성됨 값이 없어도 ||| 으로 옴
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          let t_IapDtlCntnt = pItem.mobslIapDtlCntnt // 본문
          let t_IapDtlImg = t_ArrIapInqrKeyVal[1] // 본문이미지
          let t_SrnType = t_ArrIapInqrKeyVal[2] // 화면이동구분
          let t_SrnId = t_ArrIapInqrKeyVal[3] // 화면ID
          if ( (t_IapDtlCntnt !== undefined && t_IapDtlCntnt !== null && t_IapDtlCntnt !== '')
            || (t_IapDtlImg !== undefined && t_IapDtlImg !== null && t_IapDtlImg !== '') ) {
              // 상세화면이동
              this.fn_OpenMSPBC006P(pItem)
          } else {
            if ( t_SrnType !== undefined && t_SrnType !== null && t_SrnType !== ''
            && t_SrnId !== undefined && t_SrnId !== null && t_SrnId !== '' ) {
              // 화면이동
              let t_Params = {
                'tbtMenuPageAttrCd': t_SrnType,
                'srnId': t_SrnId
              }
              this.$MenuManager.fn_RouterPushMenuItem(t_Params, {})
            } else {
              // 상세화면이동
              this.fn_OpenMSPBC006P(pItem)
            }
          }
        } else {
          this.fn_OpenMSPBC006P(pItem)
        }
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_PM_RENT ) { // 재가입계약        
        this.$router.push({name: 'MSPPM850M'})
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_ATNT_RPLC_CONT ) { // 유의대체계약
        if (this.getStore('userInfo').getters.getUserInfo.isBMngr === 'Y') { // 지점장인 경우만 사전결재현황 화면으로 이동
          this.$router.push({name: 'MSPPS620M'})
        } else {
          this.fn_OpenMSPBC006P(pItem)
        }
      } else if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_PRPYM_SPCF ) { // 선지급명세
        this.fn_BottomAlert('선지급명세서 서명을 진행하세요.')
      } else {
        // 알림상세팝업 호출
        this.fn_OpenMSPBC006P(pItem)
      }
    },
    /**
     * Function명 : fn_OpenMSPBC006P
     * 설명       : 알림상세 팝업 호출
     * @param
     * @return
     */
    fn_OpenMSPBC006P (pItem) {
      this.popupBC006P = this.$bottomModal.open(MSPBC006P, {
        properties: {
          pAlarmItem: pItem,
          pIsMgr: this.lv_IsMgrUserRol
        },
        listeners: {
          onPopupClose: () => {
            console.log('onPopupClose', this.popupBC006P)
            if ( this.popupBC006P !== undefined && this.popupBC006P !== null && this.popupBC006P !== '' ) {
              this.$bottomModal.close(this.popupBC006P)
              this.popupBC006P = ''
            }
          },
          onSrcMove: (pData) => {
            // 상세화면 화면 close
            if ( this.popupBC006P !== undefined && this.popupBC006P !== null && this.popupBC006P !== '' ) {
              this.$bottomModal.close(this.popupBC006P)
              this.popupBC006P = ''
            }

            // 화면이동
            if ( pData !== undefined && pData !== null &&  pData.rtnData !== undefined && pData.rtnData !== null ) {
              let t_IapInqrKeyVal = pData.rtnData.iapInqrKeyVal // 썸네일이미지|본문이미지|화면이동구분|화면ID 로 구성됨 값이 없어도 ||| 으로 옴
              let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
              let t_SrnType = t_ArrIapInqrKeyVal[2] // 화면이동구분
              let t_SrnId = t_ArrIapInqrKeyVal[3] // 화면ID
              // 화면이동
              let t_Params = {
                'tbtMenuPageAttrCd': t_SrnType,
                'srnId': t_SrnId
              }
              this.$MenuManager.fn_RouterPushMenuItem(t_Params, {})
            }
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_SelPopupDetail
     * 설명: 팝업상세조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelPopupDetail (pItem) {
      console.log('fn_SelPopupDetail call', pItem)

      if (!this.fn_WifiNetCheck()) return

      if (pItem === undefined) {
        return
      }

      this.popupBC008P = this.$bottomModal.open(MSPBC008P, {
        properties: {
          id: pItem.id,
          iapCntnt: pItem.iapCntnt,
          iapReciDtm: pItem.iapReciDtm,
        },
        listeners: {
          onPopupClose: () => {
            console.log('onPopupClose', this.popupBC008P)
            if ( this.popupBC008P !== undefined && this.popupBC008P !== null && this.popupBC008P !== '' ) {
              this.$bottomModal.close(this.popupBC008P)
              this.popupBC008P = ''
              pItem.cnfYn = 'Y'
            }
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /*********************************************************
     * Function명: fn_SelAnuncDetail
     * 설명: 게시상세조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelAnuncDetail (pItem) {
      console.log('fn_SelAnuncDetail call', pItem)

      if (!this.fn_WifiNetCheck()) return

      this.popupBC007P = this.$bottomModal.open(MSPBC007P, {
        properties: {
          iapInqrKeyVal: pItem.iapInqrKeyVal,
        },
        listeners: {
          onPopupClose: () => {
            console.log('onPopupClose', this.popupBC007P)
            if ( this.popupBC007P !== undefined && this.popupBC007P !== null && this.popupBC007P !== '' ) {
              this.$bottomModal.close(this.popupBC007P)
              this.popupBC007P = ''
              pItem.iapCnfYn = 'Y'
            }
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /*********************************************************
     * Function명: fn_ProcCallBackForPush
     * 설명: param 설정되어 있을 경우 관련 처리(push 알림으로 접근 시)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ProcCallBackForPush () {
      /* param 설정되어 있을 경우 관련 처리
      * => 푸시 시스템 알림, PUSH 관리자 발송(사랑온 미니)의 경우 알림목록으로 화면이동함
      * 1. 푸시 시스템 알림의 경우 상세정보 팝업호출 및 알림목록에서 해당 건을 찾아 스크롤 이동
      * 2. PUSH 관리자 발송(사랑온 미니)의 경우
      *    1) 본문 또는 본문 이미지파일 미존재 시
      *     - 이동화면 설정 건 - 관련 화면으로 이동
      *     - 이동화면 미설정 건 - 상세화면으로 이동
      *    2) 본문 또는 본문 이미지파일 존재 시
      *     - 상세화면으로 이동
      */
      let t_Params = this.$route.params
      console.log('fn_ProcCallBackForPush call', t_Params)

      // 알림종류코드
      let t_IapKndCd = t_Params.alarmType

      // 알림종류코드 check
      if ( t_IapKndCd === undefined || t_IapKndCd === null || t_IapKndCd === '' ) {
        // 관련 Flag 값 초기화 - 최초 1회 실행
        this.lv_IsProcForPush = false
        return
      }

      let t_Key = '' // 조회 key
      // 푸시 시스템 알림
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) {
        t_Key = t_Params.bltnId
      } else {
        t_Key = t_Params.iapMatrSn
      }

      // 조회 key check
      if ( t_Key === undefined || t_Key === null || t_Key === '' ) {
        // 관련 Flag 값 초기화 - 최초 1회 실행
        this.lv_IsProcForPush = false
        return
      }

      // 알림목록 내 해당 건 존재여부 체크
      let t_TargetItem = []
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) {
        t_TargetItem = this.lv_AlarmList.filter(item => item.iapKndCd === t_IapKndCd && item.bltnpId === t_Key )
      } else {
        t_TargetItem = this.lv_AlarmList.filter(item => item.iapKndCd === t_IapKndCd && item.iapMatrSn === t_Key )
      }
      
      // 미존재 시 SKIP
      if ( !t_TargetItem || t_TargetItem.length <= 0 ) {
          // 관련 Flag 값 초기화 - 최초 1회 실행
        this.lv_IsProcForPush = false
        return
      }

      // 알림목록 스크롤 이동
      this.$nextTick(() => {
        let t_RefNm =  t_IapKndCd + '_' + t_Key
        let t_TargetElement = this.$refs[t_RefNm].$el
        if ( t_TargetElement !== undefined && t_TargetElement !== null ) {
          // 스크롤위치 계산
          let t_TargetScrollTop = t_TargetElement.offsetTop + 48 // 48: 헤더영역

          console.log('t_TargetScrollTop', t_TargetScrollTop)

          // 스크롤이동
          // this.fn_ScrollTo(t_TargetScrollTop)
          // this.scrollAnimateY.animate(t_TargetScrollTop, 700, 0, 'easeOut')
        }

        // 화면이동(상세화면 팝업호출 및 관련화면 이동)
        this.fn_SelAlarmDetail(t_TargetItem[0])

        // 처리완료 후 관련 Flag 값 초기화 - 최초 1회 실행
        this.lv_IsProcForPush = false
      })
    },
    /*********************************************************
     * Function명: fn_SelNewAlarmCnt
     * 설명: 새알림 건수 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelNewAlarmCnt () {
      let lv_Vm = this
      let pParams = {
        iapCnfYn: 'N'
      }
      const trnstId = 'txTSSBC03SA'
      const auth = 'S'
      this.getStore('progress').dispatch('SKEL_LIST')
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        if ( Number(response.body.totalCnt) === 0) {
          lv_Vm.lv_HasNewAlarm = false
        } else {
          lv_Vm.lv_HasNewAlarm = true
        }
        console.log('새알림 건수 조회', lv_Vm.lv_HasNewAlarm, response)

        let t_NewAlarmCnt = 0
        if ( response && response.body ) {
          t_NewAlarmCnt = response.body.totalCnt
        }

        // Framefooter 알림영역 신규알림 갱신 처리
        lv_Vm.$BottomManager.fn_SetBottomMenuNotiValue('notification', Number(t_NewAlarmCnt))
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_UpdIapCnfYn
    * 설명        : 알림읽음 처리
    * Params      : item - 알림정보
    * Return      : N/A
    ******************************************************************************/
    fn_UpdIapCnfYn (pItem) {
      // 알림정보 미존재 시 SKIP
      if ( pItem === undefined || pItem === null || pItem.length === 0 ) return

      // 알림정보 읽음여부 N인 경우에 대해서만 수행
      if ( pItem.iapCnfYn === 'N' ) {
        let lv_Vm = this

        // 시스템공지와 그외 별도 처리
        let pParams = {}
        let trnstId = ''
        if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) {
          pParams = {
            bltnpId: pItem.bltnpId,
            bltnTitl: pItem.iapCntnt
          }
          trnstId = 'txTSSBC11S3'
        } else {
          pParams = { iapMatrSn: pItem.iapMatrSn }
          trnstId = 'txTSSBC03U9'
        }
        const auth = 'U'
        this.getStore('progress').dispatch('FULL')
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 해당 건 읽음 상태로 변경
            console.log(pItem.nextKey)
            for ( let i=0; i < lv_Vm.lv_AlarmList.length; i++ ) {
              if ( pItem.nextKey === lv_Vm.lv_AlarmList[i].nextKey ) {
                lv_Vm.lv_AlarmList[i].iapCnfYn = 'Y'
                break
              }
            }

            // 신규알림건수 조회(FrameFooter 신규알림 관련 갱신)
            lv_Vm.fn_SelNewAlarmCnt()
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /**
     * Function명 : fn_BottomLogoutConfirm
     * 설명       : 로그아웃 관련 메시지를 BottomAlert을 confirm창으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomLogoutConfirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_OnPositive()
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_OnNegative()
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /**
     * Function명 : fn_GetDtlCntntForSysNotice
     * 설명       : 공지사항 상세내역에 링크 존재 할 경우 링크 클릭 막음
     * @param {String} pTxt - 시스템공지사항 상세내역
     * @return {String} - <a> tag를 <p> tag로 변환
     */
    fn_GetDtlCntntForSysNotice (pTxt) {
      if ( pTxt === undefined || pTxt === null ) return ''

      let t_RtnTxt = ''
      t_RtnTxt = this.$bizUtil.replaceAll(pTxt, '<a', '<p')
      t_RtnTxt = this.$bizUtil.replaceAll(t_RtnTxt, '</a', '</p')
      return t_RtnTxt
    },
    /**
     * Function명 : fn_GetDtlCntnt
     * 설명       : 관리자 푸쉬발송 상세내역의 개행문자를 <br/>태그로 치환
     * @param {String} pTxt - 시스템공지사항 상세내역
     * @return {String} - <a> tag를 <p> tag로 변환
     */
    fn_GetDtlCntnt(pIapKndCd, pMobslIapDtlCntnt) {
      let t_RtnTxt = pMobslIapDtlCntnt
      if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        t_RtnTxt = pMobslIapDtlCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      }
      return t_RtnTxt
    },
    /******************************************************************************
    * Function명 : fn_WifiNetCheck
    * 설명       : 사내망 체크
    ******************************************************************************/
    fn_WifiNetCheck () {
      if (!this.$MenuManager.fn_GetInternalNetwork()) {
        window.vue.getStore('confirm').dispatch('ADD', '사내Wifi망에서만 접속 가능합니다.')
        return false
      }
      return true
    },
    /*******************************************************************************
     * Function명 fn_SelUserRol
     * 설명     사용자정보조회
     *******************************************************************************/
    async fn_SelUserRol() {
      console.log('fn_SelUserRol')
      this.lv_IsMgrUserRol = false
      const trnstId = 'txTSSAD04S2'
      let pParams = { mobslAuthId : 'AD99' }
      await this.post(this, pParams, trnstId, 'S').then(response => {                
        if(response?.body) {
          if (response.body.authYN === 'Y') {            
            this.lv_IsMgrUserRol = true
          }
        }
      }).catch(function (error) {        
        window.vue.error(error)
      })      
    },
  }
};
</script>
<style scoped>
</style>