/*
 * 업무구분 : 업무공통
 * 화면 명 : MSPBC003M
 * 화면 설명 : 오늘의 활동(FC홈)
 */
<template>
  <ur-page-container title="오늘의 활동" class="msp home_new todayActivityPage" :show-title="true" type="page" :action-type="actionSlot" :customer="fcData.label" :customerID="fcData.key">
    <div class="msp h100">
      <div class="bottom_open_float">
        <!-- 추가 2024.12.20 :chan -->
        <div class="todayActivityMainImgWrap">
          <div>
              <span>{{date}}</span>
              <span class="day">{{day}}</span>
          </div>
          <div class="cheerUp" v-if="holiday != ''">{{holiday}}</div>
          <div class="cheerUp" v-else>오늘 하루도 응원해요!</div>
        </div>
        <div class="activityScrollBtnWrap">
          <mo-button class="activityScrollBtn checked" @click="scrollButtonCheck(0, true)">내 일정</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(1, true)">활동캘린더</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(2, true)">동의만료 고객</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(3, true)">전화활동</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(4, true)">활동골든룰</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(5, true)">이달의 업적</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(6, true)" v-if="!isManager">최신 안내자료</mo-button>
        </div>
        <!-- 추가 끝 2024.12.20 :chan -->
        <div class="overflow-scroll-y overflow-hidden-x mt20 pl24 fexTy1">
          <div class="myWork_container">
            <!-- 내 일정 --><!-- 수정 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="schedule-item mt20 scrollTo">
              <div class="info--tit flex" @click="$router.push({name: 'MSPAP314M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <span class="fs18rem fwb myActivitySubject">내 일정</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>
              <div class="myActTip mb0" v-if="SchdList.length > 0"  >일정을 확인해보세요</div>
              <div class="myActTip mb0" v-if="SchdList.length === 0">일정을 등록해보세요</div>
              <mo-list :list-data="SchdList" v-if="SchdList.length > 0">
                <template #list-item="{item, index}">
                  <div class="mt10 myScheduleWrap" v-if="index < scheduleAmount" @click="$router.push({name: 'MSPAP314M', params: {pScrId:'MSPBC003M', pData: fcData, selectSchd: true}})">
                    <div class="myScheduleTimeWrap">
                      <div class="myScheduleAmpm">{{item.afternoonYn}}</div>
                      <div class="myScheduleTime actTimeColor1">{{item.startTime}}</div>
                    </div>
                    <div class="myScheduleBox" :class="{'actBoxColor1':item.fcSchdlScCd==='2', 'actBoxColor2':item.fcSchdlScCd==='1', 'actBoxColor3':item.fcSchdlScCd==='3'}">
                      <div class="myScheduleIconWrap">{{item.custNm}}</div><!-- icon 삭제 2024.12.20 :chan -->
                      <div class="badge-type location fs12 fwn pa10 mt10 myScheduleIention" v-if="item.CntcNm">{{item.CntcNm}}</div>
                      <div class="myScheduleIconAddress">{{item.fcSchdlPrmsPlcNm}}</div>
                      <div v-show="item.SchdlNm" class="myScheduleMemo innerBox">{{item.SchdlNm}}</div>
                    </div>
                  </div>
                  <!-- 더보기 추가 2024.12.20 :chan -->
                  <div v-if="SchdList.length > scheduleList && index===scheduleAmount-1" class="showMoreScheduleWrap" @click="showAllSchedule()">
                    <mo-icon class="showMoreScheduleIcon" :class="{'up':showMore==='줄이기'}">msp-arrow-down</mo-icon>{{showMore}}
                  </div>
                </template>
              </mo-list>
              <!-- schedule이 없을 경우 표시 -->
              <div class="myScheduleNoneWrap" v-if="SchdList.length===0">
                <div class="myScheduleNone"><img src="@/assets/img/icon/icon_calendar.png"></div>
                <mo-button class="btnAddMySchedule" v-if="mngSearch" @click="fn_schlAdd()">일정추가</mo-button>
              </div>
            </ur-box-container>
            <!-- 내 일정 끝 -->

            <!-- 활동 캘린더 --><!-- 부분 수정 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP320M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">활동캘린더</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip" v-if="evtCustList.length > 0">특별한날을 맞이한 고객에게 연락해보세요</div>
                <div class="myActTip" v-if="evtCustList.length===0">특별한날을 맞이한 고객이 없어요</div>
                <mo-list :list-data="evtCustList" v-if="evtCustList.length > 0" class="myActListBox">
                  <template #list-item="{item}">
                    <div v-if="item.cnt > 0" class="whatslist" @click="$router.push({name: 'MSPAP320M', params: {pScrId:'MSPBC003M', pData: fcData, evtTypNm: item.evtTypNm}})">
                      <div class="whatsdate">
                        <div class="myActIcon" :class="[{'iconBirthday':item.evtTypNm==='생일'},{'iconCake':item.evtTypNm==='계약1주년'}, {'iconCalendar':item.evtTypNm==='상령일'}, {'iconHeart':item.evtTypNm==='만기일'}]"></div>
                        <span>{{item.evtTypNm}}</span><span class="actionNum">{{item.cnt}}</span>
                      </div>
                      <div class="actionMembers">
                        <!-- <span v-for="key in item.custNm" :style="getStyle(key.length)">{{key.name}}</span> -->
                        <span v-for="(data,i) in item.custNm" :key="i" :style="getStyle(data.length)">{{data.name}}</span>
                      </div>
                    </div>
                  </template>
                </mo-list>
                <div class="myActEmptyData" v-if="evtCustList.length===0">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 활동 캘린더 끝 -->

            <!-- 동의만료 고객 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPCM217M', params: {pScrId:'MSPBC003M', pData: fcData}, query: {t: new Date().getTime()}})">
                  <span class="fs18rem fwb myActivitySubject">동의만료 고객</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip" v-if="delParngCust.length > 0">동의가 만료되는 고객을 터치해보세요</div>
                <div class="myActTip" v-if="delParngCust.length ===0">오늘 동의가 만료되는 고객이 없어요</div>
                <mo-list :list-data="delParngCust" v-if="delParngCust.length > 0" class="myActListBox">
                  <template #list-item="{item}">
                    <div class="whatslist" @click="$router.push({name: 'MSPCM217M', params: {pScrId:'MSPBC003M', pData: fcData}, query: {t: new Date().getTime()}})">
                      <div class="whatsdate">
                        <div class="myActIcon iconDocument"></div>
                        <span>필컨만료</span><span class="actionNum">{{item.cnt}}</span>
                      </div>
                      <!-- <div class="actionMembers">{{item.custNm}}</div> -->
                      <div class="actionMembers">
                        <!-- <span v-for="key in item.custNm" :style="getStyle(key.length)">{{key.name}}</span> -->
                        <span v-for="(data,i) in item.custNm" :key="i" :style="getStyle(data.length)">{{data.name}}</span>
                      </div>
                    </div>
                  </template>
                </mo-list>
                <div class="myActEmptyData" v-if="delParngCust.length === 0">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 동의만료 고객 끝 -->

            <!-- 전화활동 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt20 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP310M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">전화활동</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip" v-if="callList.length > 0">오늘 {{callList.length}}건의 전화활동을 했어요 (총 {{callCnt.length}}명)</div>
                <div class="myActTip" v-if="callList.length===0">고객과의 통화로 하루를 시작해보세요</div>
                <div class="myActCallListBox" v-if="callList.length > 0">
                  <div v-for="(item, index) in callList" :key="index">
                    <div class="myActCallListItem" :class="{'mini':callList.length <= callList && index === callList.length-1}" v-if="index < callListAmount" @click="$router.push({name: 'MSPAP310M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                      <div class="myActCallListIcon" v-if="item.mobslCustCntcId==='HC08' || item.mobslCustCntcId==='HC10' ||item.mobslCustCntcId==='SEND'">
                        <mo-icon>iconCall</mo-icon>
                        <span>발신</span>
                      </div>
                      <div class="myActCallListIcon" v-if="item.mobslCustCntcId==='HC11' || item.mobslCustCntcId==='RECI'">
                        <mo-icon>iconReceive</mo-icon>
                        <span class="blue">수신</span>
                      </div>
                      <div>
                        <div class="name">{{item.custNm}}</div>
                        <div class="time">{{item.mobslCntcCntnt}}</div>
                        <div class="memo" v-html="item.phclMemoCntnt"></div>
                      </div>
                    </div>
                    <div v-if="callList.length > todayCallList && index===callListAmount-1" class="showMoreScheduleWrap" @click="showAllCallList()">
                      <mo-icon class="showMoreScheduleIcon" :class="{'up':showAllList==='줄이기'}">msp-arrow-down</mo-icon>{{showAllList}}
                    </div>
                  </div>
                </div>
                <div class="myActEmptyData" v-if="callList.length===0">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 전화활동 끝 -->

            <!-- 활동 골든룰 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">활동골든룰</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip icon" v-if="GldnrChk==='달성'">  {{GldnrWekl}} 달성 축하드려요!</div>
                <div class="myActTip"      v-if="GldnrChk==='진행중'">{{GldnrWekl}} 달성에 도전중이에요</div>
                <div class="myActTip"      v-if="GldnrChk==='미진행'">{{GldnrWekl}} 활동골든룰 미진행</div>

                <div class="myActGoldenRuleWrap" :class="[{'case1':iCGldnrActWeklySmupDtlVO.length===1}, {'case3':iCGldnrActWeklySmupDtlVO.length%3===0}]">
                  <div v-for="(item, index) in iCGldnrActWeklySmupDtlVO" :key="index">
                    <div class="myActGoldenRuleBox" :class="[{'case3':iCGldnrActWeklySmupDtlVO.length%3===0}, {'incomplete':item.actCnt < item.gldnrActTypGoalVal01}]" @click="$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M', pData: fcData}})">
                      <div class="subject">{{item.gldnrActTypNm}}</div>
                      <div class="dataBox"><span class="data1">{{item.actCnt}}</span>&nbsp;<span class="data2">{{item.gldnrActTypGoalVal01}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </ur-box-container>
            <!-- 활동 골든룰 끝 -->

            <!-- 이달의 업적 --><!-- 수정 2024.12.20 :chan -->
            <ur-box-container id="myAchievWrap" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list mt30 scrollTo">
              <template>
                <component :is="'style'">
                  .moveFlagWrapAdd {animation-name:transCharacter}
                  .percentBar {animation-name:percentBarAni}
                  .percentNumberWrapAdd {animation-name:percentNumberWrapAni}
                  .moveFlagWrapAdd, .percentBar, .percentNumberWrapAdd
                  {animation-duration:1s; animation-iteration-count:1; animation-fill-mode:forwards; animation-delay:0.5s}
                  @keyframes transCharacter {
                    0% {width:0px} 100% {width:{{flagMoveValue}}}
                  }
                  @keyframes percentBarAni {
                    0% {width:0px} 100% {width:{{zero100AchievValue}}%}
                  }
                  @keyframes percentNumberWrapAni {
                    0% {width:20px} 100% {width:{{percentNumberMoveValue}}}
                  }
                </component>
              </template>
              <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP100M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <span class="fs18rem fwb myActivitySubject">이달의 업적</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>
              <div class="myActTip icon" v-if="achievValue === 100">정예화 달성 축하드려요!</div>
              <div class="myActTip" v-else>{{ fn_GetRateMsg.txt1 }}&nbsp;{{ fn_GetRateMsg.txt2 }}</div>
              <div class="activityAchievBg" @click="$router.push({name: 'MSPAP100M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <div class="activityAchievBarWrap">
                  <div class="moveFlagWrap" :class="[{'moveFlagWrapAdd':runFlagSwitch}]">
                    <img src="@/assets/img/icon/icon_flag.png" alt="flag" class="activityAchievFlagImg">
                  </div>
                  <div class="activityAchievBarTrack"></div>
                  <div class="activityAchievBar" :class="[{'percentBar':runFlagSwitch}]"></div>
                  <div class="percentNumberWrap" :class="[{'percentNumberWrapAdd':runFlagSwitch}]">
                    <div class="percentNumberBox"><span class="percentNumber">{{this.achievValueCounter}}</span><span class="percentChar">%</span></div>
                  </div>
                </div>
                <div class="activityAchievImage">
                  <div class="myAchievClubBadge">{{$bizUtil.replaceAll(lv_ChnlQulfNm, '만클럽', '만 Club')}}</div>
                </div>
              </div>
            </ur-box-container>
            <!-- 이달의 업적 끝 -->
            <!-- 최신 안내자료 -->
            <div class="home_info_area noEffect mt40 scrollTo" v-if="!isManager">
              <div class="info--tit flex" @click="$router.push({name: 'MSPSA201M'})">
                <span class="fs18rem fwb">최신 안내자료</span>
                <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>

              <mo-list :list-data="lv_RntCntntItems" class="info_list mt20">
                <template #list-item="{item}">
                  <mo-list-item class="info_view">
                    <div class="" @click="fn_OnSelectedSingleItem(item)">
                      <div>
                        <img :src="item.blobImgUrl">
                        <mo-badge class="lightblue sm mt10" shape="status">{{item.untySlmtClsfNm01}}</mo-badge>
                        <span class="mt4 fs16rem txtSkip3">{{item.untySlmtNm}}</span>
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>
            </div>
            <!-- 최신 안내자료 끝 -->
            <div class="mt80"></div>
          </div>
        </div>
      </div>
    </div>
    <mo-bottom-sheet ref="bottomSheet" :close-btn="false"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        고객현황
        <div class="ns-btn-close" @click="close" name="닫기"></div>
      </template>
      <div class="fex-rw content-area mhAuto txt-center customer-stats-wrap">
        <span>
          <h6 class="crTy-bk7 fs16rem">고객 수</h6>
          <p class="mt0 mb0 crTy-blue3"><b>9999</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">본인</h6>
          <p class="mt0 mb0 crTy-blue3"><b>9999</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">관심</h6>
          <p class="mt0 mb0 crTy-blue3"><b>9999</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">가망(백지)</h6>
          <p class="mt0 mb0 crTy-blue3"><b>9999</b>명</p>
        </span>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="close" class="ns-btn-round white">확인</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="close" class="ns-btn-round blue">전체고객</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역                                                     *
 ***********************************************************************************/
import MenuConst from '@/ui/sp/MenuConstans.js'
import SpConstants from '@/config/constants/spConstants'
import apConstants from '@/config/constants/apConstants'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'
import MSPSA203P from '@/ui/sa/MSPSA203P.vue'
import MSPSA210P from '@/ui/sa/MSPSA210P.vue'
import MSPSA222P from '@/ui/sa/MSPSA222P.vue'
import MSPSA063P from '@/ui/sa/MSPSA063P.vue'
import MSPCM128P from '@/ui/cm/MSPCM128P.vue'
import MSPCM152P from '@/ui/cm/MSPCM152P.vue'
import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
import MSPAP106P from '@/ui/ap/MSPAP106P'
import MSPAP302D from '@/ui/ap/MSPAP302D' // FC 일정추가
import MSPAP303P from '@/ui/ap/MSPAP303P' // 관리자 일정추가
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역                                                           *
   ***********************************************************************************/
  name: 'MSPBC003M',
  screenId: 'MSPBC003M',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역                                                         *
   ***********************************************************************************/
  created() {
    let lv_vm = this
    lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
    // 조회권한 확인
    let template =''
    if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
      template = "<mo-icon @click='clickSearch'>search</mo-icon>"
    } else {
      template = "<mo-icon></mo-icon>"
    }

    // 상단 검색, 카테고리 버튼 생성
    this.actionSlot = {
      actionComponent: {
        template: template,
        methods: {
          clickSearch() {
            lv_vm.fn_ManagerSearch()
          }
        }
      }
    }
  },
  mounted() {
    document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
    document.querySelector('.activityAchievBar').addEventListener('animationend', this.achievValueAniEnd, true)
    window.addEventListener('resize', this.catchScrollEvent, true)
    // 추가 2025.1.10 :chan
    document.querySelector('.activityScrollBtnWrap').addEventListener('touchstart', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').addEventListener('touchend', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').addEventListener('scrollend', this.catchTouchEvent, true)
    // 추가 끝 2025.1.10 :chan
  },
  beforeUnmount() {
    document.querySelector('.todayActivityPage').removeEventListener('scroll', this.catchScrollEvent, true)
    document.querySelector('.activityAchievBar').removeEventListener('animationend', this.achievValueAniEnd, true)
    window.removeEventListener('resize', this.catchScrollEvent, true)
    // 추가 2025.1.10 :chan
    document.querySelector('.activityScrollBtnWrap').removeEventListener('touchstart', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').removeEventListener('touchend', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').removeEventListener('scrollend', this.catchTouchEvent, true)
    // 추가 끝 2025.1.10 :chan
  },
  activated() {  
    // 휴일목록 조회
    this.fn_SelectHolidayList()

    // 변수 초기화
    this.fn_Init()
    
    // 지점장 여부 확인
    if (this.getStore('userInfo').getters.getUserInfo.userRoleList.indexOf('F110') > -1) {
      this.isManager = true

      let cnslt = this.getStore('bcStore').getters.getState.fcData
      if(Object.keys(cnslt).length !== 0) {
        this.fcData  = cnslt
        this.cnsltNo = cnslt.key

        this.getStore('bcStore').dispatch('FC_DATA_DEL') 

        this.fn_searhData()
      } else {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: '컨설턴트를 먼저 선택해 주세요.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log('onPopupConfirm call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            },
            onPopupCancel: () => {
              console.log('onPopupCancel call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            },
            onPopupClose: () => {
              console.log('onPopupClose call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            }
          }
        })
      }
    } else {
      this.isManager = false

      this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

      this.fn_searhData()
    }

    // 오늘 날짜 설정
    let ymd = new Date()
    this.date = moment(new Date()).format('M월D일')

    switch (ymd.getDay()) {
      case 0:
        this.day = '일';
        break;
      case 1:
        this.day = '월';
        break;
      case 2:
        this.day = '화';
        break;
      case 3:
        this.day = '수';
        break;
      case 4:
        this.day =  '목';
        break;
      case 5:
        this.day = '금';
        break; 
      default:
        this.day = '토';
        break;
    }
  },
  deactivated() {
    // scroll 위치 저장
    this.beforeScrollTop = this.$refs.activity.beforeScroll
  },
  /***********************************************************************************
   * 화면변수 선언 영역                                                              *
   ***********************************************************************************/
  data() {
    this.MenuConst = MenuConst
    return {

      //2024-12-30 오늘의활동 개선
      fcData         : {},          // 현재 조회중인 사번
      fcDtlData      : {},          // 현재 조회중인 사번 조직번호
      smryText       : '',          // 활동 요약 text
      scheduleList   : 3,           // '내일정' 보여지는 list 기본 상수
      scheduleAmount : 3,           // '내일정' 보여지는 list 기본 변수
      todayCallList  : 3,           // '전화활동' 보여지는 list 기본 상수
      callListAmount : 3,           // '전화활동' 보여지는 list 기본 변수
      showMore       : '더보기',
      showAllList    : '더보기',
      showAllIcon    : 'plus',
      evtCustList    : [],
      isManager      : false, // 관리자 여부
      cnsltNm        : '',
      cnsltNo        : '',
      callList       : '',
      callCnt        : [],           // 총 전화 인원
      SchdList       : [],
      date           : '',
      day            : '',

      lv_ChnlQulfNm: '',
      lv_IsShowGauge: !['BK','DI','DS','FS','GA','JK'].includes(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd),
      lv_GaugeCount: 0,
      lv_APThmmActActulSVO: {},
      lv_ActualRate: 0,
      cMAllCustCntSVO: {
        cm00: 0,
        cm01: 0,
        cm02: 0,
        cm03: 0,
        cm04: 0,
        cm05: 0,
        cmTot: 0,
      },
      delParngCust: [],  // 동의만료고객
      lv_RntCntntItems: [], // 최신 자료 목록

      // 공용 선택된 아이템 리스트, child의 리스트들과 동기화 됨
      lv_SelectedItemList: [],
      lv_SelectedItemListTmp:[],
      lv_Popup210: {},  // 나의보관함추가popup    
      lv_Popup222: {},  // 화상상담자료저장poppup
      lv_Popup152: {},  // 수신고객목록
      lv_Popup128: {},  // 안내수신고객목록
      lv_Popup154: {},  // 터치온고객문자발송 
      lv_Popup191: {},  // 이메일수신고객목록
      lv_Popup192: {},  // 터치온고객이메일발송 
      isMSPSA063P: {}, //  화상상담자료조회popup
      lv_TargetType: 'c',
      lv_SelectedCustList: [],    // 화상상담자료저장 고객목록 리스트
      lv_SpinValue: 1,            // 인쇄매수    
      lv_IsIncludeCnsltInfo: 'Y', // 컨설턴트 개인정보 이미지 포함 여부
      isNofacCnsl: false,         // 비대명 상상 관련 
      lv_Class: false,            //클래스 함수지정 
      lv_gldnrActrExist: false,   //골든룰 유저 확인

      holiday : '', // 휴일

      iCGldnrActWeklySmupDtlVO:[],
      GldnrChk  : '',
      GldnrWekly: '',

      // 이달의 업적
      achievValue                   : 20, // [이달의 업적] character animation 화면 표시 정수값(0% ~ 100%), 소수일 경우 버림으로 표시됨
      percentNumberMoveValue        : '0',
      activityMessageTransformValue : '0',
      achievValueCounter            : 0,
      achievValueTimeout            : null,
      achievValueIntervalTimer      : null,
      achievValueTimerSwitch        : false,
      runFlagSwitch                 : false,
      startFlagSwitch               : false,
      flagMoveValue                 : '0',
      currentScrollToIndex          : 0,
      scrollBtnLeftStart            : 0, // 2025.1.10
      scrollBtnLeftEnd              : 0, // 2025.1.10
      
      //달성,미달성,진행중,예정
      lv_acvmtYnData: [
        {key: '1', label: '달성'},
        {key: '2', label: '미달성'},
        {key: '3', label: '진행중'},
        {key: '4', label: '예정'},
        {key: '5', label: '미진행'}
      ],
      lv_AcvmtItmData: [
        { key: '01', label: '조회' },
        { key: '02', label: '귀점' },
        { key: '03', label: '귀점후활동' },
        { key: '04', label: '필컨동의' },
        { key: '05', label: '신규필컨' },
        { key: '06', label: '통합보장설계' },
        { key: '07', label: '가입설계' },
        { key: '08', label: '청약(발행)' },
        { key: '09', label: '신계약' },
        { key: '10', label: '컨설팅' },
        { key: '11', label: '전화' },
        { key: '12', label: '문자' },
        { key: '13', label: '이메일' },
        { key: '14', label: '조회&귀점' },
        { key: '15', label: '사업자등록증' }
      ],
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetUserInfo() {
      return this.getStore('userInfo').getters.getUserInfo
    },
    fn_GetRateRange() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? '100'
           : rate >= 80  ? '80'
           : rate >= 60  ? '60'
           : rate >= 40  ? '40'
           : rate >= 20  ? '20'
           : '00'
    },
    fn_GetRateMsg() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? {txt1:'정예화달성', txt2:'축하드려요!!'}
           : rate >= 80  ? {txt1:'정예화달성', txt2:'거의 다 왔어요'}
           : rate >= 60  ? {txt1:'정예화달성', txt2:'잘 하고 있어요'}
           : rate >= 40  ? {txt1:'정예화달성', txt2:'조금만 더 힘내세요~'}
           : rate >= 20  ? {txt1:'정예화달성', txt2:'도전 차근차근 가볼까요?'}
           : {txt1:'정예화달성을 위해', txt2:' 도전 시작!'}
    },
    zero100AchievValue() {
        let returnValue = 0
        if (this.lv_ActualRate < 0) returnValue = 0
        else if (this.lv_ActualRate > 100) returnValue = 100
        else if (this.lv_ActualRate >= 0 && this.lv_ActualRate <= 100) returnValue = Math.floor(this.lv_ActualRate)
        return returnValue
      },
      documentHeight() {
        return document.querySelector('.todayActivityPage').clientHeight
      },
      documentScale() {
        // 수정 2025.1.10 :chan
        return {
          width: document.querySelector('.todayActivityPage').clientWidth,
          height: document.querySelector('.todayActivityPage').clientHeight
        }
      },
      mngSearch () {
        if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
          return false
        } else {
          return true
        }
      }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SelThmmActActul
    * 설명       : 현재 활동성과 조회
    ******************************************************************************/
    fn_SelThmmActActul() {
      const pParams = {
        cnsltNo: this.cnsltNo,
        strYmd: moment(moment().subtract(30, 'day')).format('YYYYMMDD'),
        endYmd: this.$bizUtil.getDateToString(new Date(), '')
      }
      const trnstId = 'txTSSAP00S9'
      this.post(this, pParams, trnstId, 'S').then(response => {
        
        if (response?.body) {
          // 현재 활동성과 조회
          if ( response.body.rsltCd === 'S') {
            this.lv_APThmmActActulSVO = response.body

            // AFC 이면서 무급일 경우 컨설턴트로 표기 ( 정지웅 요청 : 2019.02.25 )
            if ( ['AM','FS'].includes(this.fn_GetUserInfo?.srvcMngScCd) && this.lv_APThmmActActulSVO.chnlQulfNm === '무급') {
              this.lv_ChnlQulfNm = '컨설턴트'
            } else {
              this.lv_ChnlQulfNm = this.lv_APThmmActActulSVO.chnlQulfNm
            }

            // 실적 달성율 = ( 실적 / 목표금액 ) * 100
            this.lv_ActualRate = Math.round(((Number(this.lv_APThmmActActulSVO.avlPrfmAmt1) / Number(this.lv_APThmmActActulSVO.qulfClbyStndAmt)) || 0) * 100) //실적
            this.fn_AnimateCounter()
            
          } else {
            this.$addSnackbar(response.body.rsltMsg)
          }
        } else {
          this.$addSnackbar(response?.msgComm?.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_AnimateCounter() {
      const interval = setInterval(() => {
        if (this.lv_GaugeCount >= this.lv_ActualRate) {
          // clearInterval(interval)
          // return
        }
        this.lv_GaugeCount++
      }, this.fn_GetRateRange === '100' ? 10
       : this.fn_GetRateRange === '80' ? 20
       : this.fn_GetRateRange === '60' ? 30
       : this.fn_GetRateRange === '40' ? 40
       : 50
      )
    },
    /*********************************************************
     * Function명: fn_SetUntySlmtUseAuthCd
     * 설명: 통합영업자료사용권한코드 설정
     *********************************************************/
    fn_SetUntySlmtUseAuthCd() {
      let chnlScCd = this.getStore('userInfo').getters.getUserInfo.srvcMngScCd
      console.log('chnlScCd :::', chnlScCd)

      switch (chnlScCd) {
        case 'FC':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
        case 'AM':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_AM
          break
        case 'SA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_SA
          break
        case 'GP':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GP
          break
        case 'BS':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_BS
          break
        case 'DI':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_DI
          break
        case 'GA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GA
          break
        default:
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
      }
    },
    /*********************************************************
     * Function명: fn_SelRntCntnt
     * 설명: 최신자료 최초 조회
     * Params: N/A
     * Return: 최신자료 컨텐츠 목록
     **********************************************************/
    fn_SelRntCntnt() {
      let lv_vm = this

      let pParams =
      {
        untySlmtUseAuthCd: lv_vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        whlInqrYn: 'Y', // 전체조회여부
        moblInqrYn: 'Y' // 모바일에서 호출여부
      }

      // 트랜잭션ID 설정
      let trnstId = 'txTSSSA01S1'
      const _auth = 'S'

      this.post(lv_vm, pParams, trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            // 서비스 후처리
            if (response.body !== null && response.body !== '' && response.body.iCCntntBasInfoVO !== null && response.body.iCCntntBasInfoVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 응답 데이터 처리
              let reponseBody = response.body
              reponseBody.iCCntntBasInfoVO.forEach(async (item, index) => {

                item.blobImgUrl = ''
                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await lv_vm.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })
              // lv_vm.$data.msgTxt = reponseBody.iCCntntBasInfoVO
              const rntCntntBasInfoVOList = reponseBody.iCCntntBasInfoVO
              // 서비스 통신 조회키
              // lv_vm.lv_rntCntntStndKeyList[0] = response.trnstComm.stndKeyList[0]
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장 , 이때 7개만 보여준다.
              lv_vm.lv_RntCntntItems = rntCntntBasInfoVOList.slice(0, 7)

              console.log('++++++최신자료 목록++++++', lv_vm.lv_RntCntntItems)
    
            } else {
              // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
          }
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          window.vue.error('TSSSP201M_fn_onInitRntCntnt ERROR: ' + error)
        })
    },
    /*********************************************************
    * Function명: fn_OnSelectedSingleItem
    * 설명: 선택된 인기자료/최신자료/내가사용한자료의 제목부분을
            클릭한경우 상세보기 팝업창을 띄우지 않고 바로 해당 pdf뷰
            를 보여준다.
    * Params: target
    * Return: N/A
    *********************************************************/
    fn_OnSelectedSingleItem (pSelectedItem) {
      this.clickedSingleItem = true
      //pSelectedItem.checked = false
      this.lv_SelectedSingleItem = pSelectedItem
      this.lv_SelectedItemList = []
      this.lv_SelectedItemList.push(pSelectedItem)
      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)

      // 단일 선택 후 상세보기 진행 시
      this.lv_IsMadeMultiItem = false
      console.log(this.lv_IsMadeMultiItem)
      if (pSelectedItem.vdoLnkUrl !== null && pSelectedItem.vdoLnkUrl !== undefined && pSelectedItem.vdoLnkUrl !== '') {
        // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
        this.fn_IncrementItemInqrFgrList()
      } else if (pSelectedItem.pdfFileYn === 'Y') {
        this.fn_IncrementItemInqrFgrList()
      } else {
        // 동영상 링크가 아닐 경우는 인쇄 실행
        this.fn_CreateToPDFContnt()
      }

      // FOM 파일 저장
      if (!this.lv_SelectedFomItemList) this.lv_SelectedFomItemList = []
      this.lv_SelectedFomItemList.push(pSelectedItem)
    },
    /******************************************************************************
    * Function명 : fn_IncrementItemInqrFgrList
    * 설명       : 조회 이력 저장
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_IncrementItemInqrFgrList () {
      let lv_vm = this
      const trnstId = 'txTSSSA01P2'
      const auth = 'S'

      let sParams = {}
      // 컨설턴트 번호
      sParams.cnsltNo = lv_vm.cnsltNo
      if (lv_vm.lv_IsMadeMultiItem) {
        sParams.sACntntBasInfoSVO = lv_vm.lv_SelectedItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        sParams.sACntntBasInfoSVO = tempVOList
      }

      this.post(lv_vm, sParams, trnstId, auth)
        .then(function (response) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
          lv_vm.getStore('progress').getters.getState.isShow = true
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {

              if (response.body.sASelCntntDtlSVO != null && response.body.sASelCntntDtlSVO.length > 0) {
                if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {
                  // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                  if (!lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== null && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== undefined && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== '') { // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedSingleItem.vdoLnkUrl}, () => {}, () => {})
                  } else if (lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedItemList.length === 1 && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== null && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== undefined && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== '') {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedItemList[0].vdoLnkUrl}, () => {}, () => {})
                  } else {
                    lv_vm.fn_SaveFOMFile()
                  }
                }
              }
            } else {
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
              lv_vm.getStore('progress').getters.getState.isShow = false
              if (response?.msgComm?.msgCd !== null && response?.msgComm?.msgCd.length > 0) {
                lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CreateToPDFContnt
    * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
    * 인자       : lv_UserInfo 사용자 정보 데이터 (Object)
    * Return     : void
    ******************************************************************************/
    fn_CreateToPDFContnt () {
      let lv_vm = this

      lv_vm.lv_SADCFmlmtSVOList = []
      lv_vm.lv_CompletedPDFFileVOList = []

      let pParams = {}
      pParams.cnsltNo = this.cnsltNo

      if (lv_vm.lv_IsMadeMultiItem) {
        // FOM 파일만 PDF 생성 대상
        pParams.sACntntBasInfoSVO = lv_vm.lv_SelectedFomItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        pParams.sACntntBasInfoSVO = tempVOList
      }
      const trnstId = 'txTSSSA22P3'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.sADCFmlmtSVO != null && response.body.sADCFmlmtSVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
              //lv_vm.getStore('progress').getters.getState.isShow = true
              lv_vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
              lv_vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화
              lv_vm.fn_CheckContentsTransformation(lv_vm.lv_SADCFmlmtSVOList)
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CheckContentsTransformation  ( 2차 개발시 메서드 대체 처리 )
    * 설명       : FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
    * Params     : 변환 요청한 결과 VO 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CheckContentsTransformation (pSADCFmlmtSVO) {
      console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) START ☞ ' + new Date().getTime())

      let lv_vm = this
      let pParams = {
        cnsltNo: this.cnsltNo,
        sADCFmlmtSVO: pSADCFmlmtSVO
      }
      const trnstId = 'txTSSSA22P4'
      const auth = 'S'


      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              // 서비스 호출 결과(body)가 있을 경우
              // 생성여부 확인 시도 회수를 증가
              // 20번까지만 생성확인 여부 시도
              lv_vm.lv_CheckDocsRetryNumberOfTimes++

              // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
              // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
              if (response.body.sADCDoctTrfoBasSVO !== null && response.body.sADCDoctTrfoBasSVO.length > 0) {
                let tempPSADCFmlmtSVO = []
                let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                // 생성요청 VO 목록에서
                lv_vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                  let tempFlag = false
                  let tempVO = {}

                  // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                  resultSADCDoctTrfoBasSVO.forEach(item => {
                    if (dcItem.srcFileNm01 === item.eltrnDoctId) {
                      tempFlag = true
                      tempVO = item
                    }
                  })

                  // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                  if (tempFlag) {
                    if (lv_vm.lv_CompletedPDFFileVOList !== null) {
                      let addFlag = true
                      lv_vm.lv_CompletedPDFFileVOList.forEach(item => {
                        if (tempVO.eltrnDoctId === item.eltrnDoctId) {
                          addFlag = false
                        }
                      })

                      if (addFlag) {
                        lv_vm.lv_CompletedPDFFileVOList.push(tempVO)
                      }
                    }
                  } else {
                    tempPSADCFmlmtSVO.push(dcItem)
                  }
                })

                // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                // 생성여부 확인 작업을 계속 진행
                if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                  // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                  if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                    setTimeout(function () {
                      lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                    }, 1000)
                  } else { // 그렇지 않으면 시도 중지
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                    //v_Vm.getStore('progress').getters.getState.isShow = false
                    // 조회이력 증가 호출
                    //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                    //lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
                  }
                } else { // 그렇지 않으면 조회수 증가 단계로 이동
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  //lv_vm.getStore('progress').getters.getState.isShow = false
                  // 조회이력 증가 호출
                  if (lv_vm.isNofacCnsl) { // [비대면상담]인 경우는 TUTU00012, TUTU00013 테이블에 상담 문서 정보 INSERT
                      for (let i = 0; i < pSADCFmlmtSVO.length; i++) {
                        let tempObj = {}
                        tempObj.nofacCnslDoctNm = pSADCFmlmtSVO[i].prdtNm
                        tempObj.eltrnDoctFilePathNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFilePathNm
                        tempObj.eltrnDoctFileNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFileNm
                        lv_vm.lv_SelectedPdfList.push(tempObj)
                      }
                      lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
                  } else { // 조회이력 증가 호출
                    lv_vm.fn_IncrementItemInqrFgrList()
                  }
                }  
              } else if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                lv_vm.getStore('progress').getters.getState.isShow = false
                // 조회이력 증가 호출
                // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
              }
            } else { // 서비스 호출 결과는 없지만
              // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
              if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
        console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) END ☞ ' + new Date().getTime())

    },
    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    fn_SaveFOMFile () {
      if (process.env.NODE_ENV === 'local') {
        let viewerTargetFileList = ''
        let savePdfTargetItemList = []
        if (this.lv_IsMadeMultiItem) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.eltrnDoctFileNm = 'test.pdf'
            itemObj.localFileNm = 'test.pdf'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

            savePdfTargetItemList.push(itemObj)
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = selectedUntySlmtItem.pdfApndFilePathNm
          itemObj.eltrnDoctFileNm = 'test.pdf'
          itemObj.localFileNm = 'test.pdf'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

          savePdfTargetItemList.push(itemObj)
        }
        savePdfTargetItemList.forEach(item => {
          try {

            viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

        this.fn_CallPdfViewer(viewerTargetFileList)
      } else {
        let successCount = 0
        let tryCount = 0
        let savePdfTargetItemList = []
        let viewerTargetFileList = ''

        if (this.lv_IsMadeMultiItem === true) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            if (selectedUntySlmtItem.pdfFileYn === 'Y') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
              itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

              savePdfTargetItemList.push(itemObj)
            } else {
              let tempDcFmlmtSVO = null
              let tempCompletedPdfSVO = null
              this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                  tempDcFmlmtSVO = dcFmlmtSVO
                }
              })

              if (tempDcFmlmtSVO !== null) {
                this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                  if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                    tempCompletedPdfSVO = completedPDFFileSVO
                  }
                })
              }

              if (tempCompletedPdfSVO !== null) {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

                // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
                let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                } else {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                }

                savePdfTargetItemList.push(itemObj)
              }
            }
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          if (selectedUntySlmtItem.pdfFileYn === 'Y') {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
            itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
            itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

            savePdfTargetItemList.push(itemObj)
          } else {
            let tempDcFmlmtSVO = null
            let tempCompletedPdfSVO = null
            this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
              if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                tempDcFmlmtSVO = dcFmlmtSVO
              }
            })

            if (tempDcFmlmtSVO !== null) {
              this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                  tempCompletedPdfSVO = completedPDFFileSVO
                }
              })
            }

            if (tempCompletedPdfSVO !== null) {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
              if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
              } else {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
              }

              savePdfTargetItemList.push(itemObj)
            }
          }
        }
        savePdfTargetItemList.forEach(item => {
          if (process.env.NODE_ENV === 'development') {
            item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            item.eltrnDoctFileNm = 'test.pdf'
            item.localFileNm = 'test.pdf'
            item.fileTitle = '[TEST]'
          }
          
          try {
            this.fn_FdpSaveFile(item)
              .then(response => {
                successCount++
                tryCount++
                
                viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
                  if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
                    this.fn_CallPdfViewer(viewerTargetFileList)
                  } else if (tryCount === this.lv_SelectedItemList.length) {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    //this.getStore('progress').getters.getState.isShow = false
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }
                } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
                  this.fn_CallPdfViewer(viewerTargetFileList)
                }
              }).catch(error => {
                tryCount++
                // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
                // 다중 선택일 경우
                if (this.lv_IsMadeMultiItem) {
                  // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
                  if (tryCount === this.lv_SelectedItemList.length) {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    this.getStore('progress').getters.getState.isShow = false
                    // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }

                  if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
                } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                  // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                  this.getStore('progress').getters.getState.isShow = false
                  // 오류 팝업 메시지 출력
                  this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
                }
                if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              })
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })
      }
    },
    
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }

        
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N'
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },


    fn_CallPdfViewer (viewerTargetFileList) {
      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
      this.getStore('progress').getters.getState.isShow = false
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || item.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
          libraryItemCount++
        }
      }


      // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
      // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
      let menuAuth = ''
      if (!this.lv_SelcectedClsfSytmNm) this.lv_SelcectedClsfSytmNm = ''
      if (libraryItemCount > 0 || this.lv_SelcectedClsfSytmNm.indexOf('라이브러리') !== -1 || this.lv_SelcectedClsfSytmNm.indexOf('사랑On도움방') !== -1) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
       menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
      } else {
       menuAuth = '1111'
      }

      let lv_vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
        file: viewerTargetFileList // 서버에 올릴때.
        // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
      }, function (result) {
        // 성공콜백
        // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
        if (result.data === 'btn1') {
          //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          lv_vm.fn_SMSBtnClicked()   //문자
        } else if (result.data === 'btn2') {
                lv_vm.fn_OpenPopEmailCustList()  //이메일
        } else if (result.data === 'btn3') {
                lv_vm.fn_print_BottomSheet_open() //인쇄
        } else if (result.data === 'btn4') {
                lv_vm.fn_OpenMSPSA210P()          
        }
      }, function (result) {
        // 실패콜백
        if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
      })
    },

    /*********************************************************
    * Function명: fn_SMSBtnClicked
    * 설명: TSSSP201M.TAB에서 "문자전송" 버튼 클릭시
    * Params:  N/A
    * Return: N/A
    *********************************************************/
    fn_SMSBtnClicked () {
      if (this.lv_SelectedItemList.length > 5) {
         this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
         return
      }

      let lv_vm = this
      // 파라미터 설정
      let pParams = {}

      const trnstId = 'txTSSSA01S8'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.startDt !== undefined && response.body.startDt !== '') {
                if (new Date() >= new Date(response.body.startDt) && new Date() <= new Date(response.body.endDt)) {
                  //알럿띄워야됨 종료시키고 pk.son
                  lv_vm.$addSnackbar(response.body.msg)
                } else {
                  //lv_vm.fn_OpenPopCustList()
                  lv_vm.fn_OpenPopSmsCustList()  // 2차반영
                }
              }else{
                // 서버 config 키값이 정의 되어 있지 않더라도 SMS 고객선택 팝업 호출
                //lv_vm.fn_OpenPopCustList()
                lv_vm.fn_OpenPopSmsCustList()  // 2차반영
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_OpenPopCustList
     * 설명: 안내자료_고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenPopCustList() {
      let lv_vm = this
      let itemList = []

      if(this.clickedSingleItem === true){
        itemList.push(this.lv_SelectedSingleItem)
      }else{
        itemList = this.lv_SelectedItemList
      }
      this.popData = this.$bottomModal.open(MSPSA203P,{
        properties: {
          paramData: itemList // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA203P: (pData) => {
            if(pData !== ''){
              
              this.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData}]
              this.fn_OpenPopSmsSendList(itemList)
            }else{
              this.$bottomModal.close(lv_vm.popData)
            }
          }
        }
      })
    },

    /*********************************************************
    * Function명: fn_OpenPopCustList
    * 설명: 수신고객목록 팝업오픈
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenPopSmsSendList(itemList) {
      let lv = this
      this.popData1 = this.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: this.custListObj, // param셋팅
          pSelectedUntySlmtItemList: itemList,
          pIsShowTSSSP128P: true
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupClose: (pData) => {
            if (pData === 'confirm') {
              this.$bottomModal.close(lv.popData)
              this.lv_IsShowMSPCM203P = false
            }
            this.$bottomModal.close(lv.popData1)
          }
        }
      })
    },

    /* 2차 오픈시 추가반영   화면 하단 BS 영역부문 관련 프로그램반영   */

    /*********************************************************
     * Function명: fn_SaveToCouncel  (화상상담 자료저장)
     * 설명: 고객목록 팝업에서 고객 선택 후 저장 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록(List<SAGrpInfoSVO>)
     * Return: N/A
     *********************************************************/
    fn_SaveToCouncel() {
     
     console.log('@@@ MSPSA201M >>>  화상상담자료저장 fn_SaveToCouncel START  ')

      this.isNofacCnsl = true

      let parentsViewId = 'MSPSA201M'
      let custList = this.lv_SelectedCustList.slice()
      this.lv_TargetType = 'c'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
      this.custListObj = [
          {targetType: this.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
      ]

      this.fn_AfterCustSearch() // FOM문서 PDF변환 혹은 PDF문서 정보 저장
    },
    /*********************************************************
     * Function명: fn_AfterCustSearch
     * 설명: [비대면화상상담]고객목록 팝업에서 고객 선택 후 다음 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록
     * Return: N/A
     *********************************************************/
    fn_AfterCustSearch() {
      // 다중 선택 후 화상상담 진행시
      this.lv_IsMadeMultiItem = true

      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      this.lv_SelectedPdfList = [] // 기존에 만들어진 PDF파일 담을 목록 초기화
      let lv_vm = this
      // 로딩바 강제 설정
      this.getStore('progress').getters.getState.isShow = true
      console.log('■■■■■ MSPSA201M ■■■■■ 화상상담자료저장 전 선택항목 체크(fn_AfterCustSearch) START ☞ ' + new Date().getTime())

      // 1. FOM 파일만 별도로 추출
      //    FOM 파일은 문서변화 서버를 이용해 PDF로 변환
      // 2. PDF 파일일 경우
      //    'UNTY_SLMT_DIR' 를 명시적으로 지정해 주고
      //    pdfApndFilePathNm에 저장된 'ic/20190219' 와
      //    pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명을 조합하여 파일을 다운로드(.pdf 확장자를 붙이지 않음)
      //    -> 최종경로 : /filedown.do?path=UNTY_SLMT_DIR&filename=ic/20190220/000300336246415221102209102
      console.log('********** MSPSA201M ****** lv_SelectedCnslItemList ☞ ', this.lv_SelectedItemList)

      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn === 'Y') { // PDF 파일
          // 로컬 테스트인 경우
          if (process.env.NODE_ENV === 'local') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              itemObj.eltrnDoctFileNm = item.pdfApndFileId // test용
              lv_vm.lv_SelectedPdfList.push(itemObj)
          } else {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR' // Context.properties에서 PDF 파일이 저장된 위치를 명시한 키값 설정
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              // itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm // PDF 파일일 경우는 확장자 .pdf를 붙이지 않음
              itemObj.pdfFilePathDirNm = item.pdfApndFilePathNm // ic/20190219
              itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm
              lv_vm.lv_SelectedPdfList.push(itemObj)
          }
        } else {
             lv_vm.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      // FOM 파일이 하나 이상 선택되었을 경우는 PDF 변환 진행
      if (lv_vm.lv_SelectedFomItemList.length > 0) {
          lv_vm.fn_CreateToPDFContnt()
      } else { // PDF문서만 선택 되었을 때
          lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
      }
    },

    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담] 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData(docInfo) {

      console.log('***** MSPSA201M *** PDF문서 저장 *** fn_InsNofacCnslData ☞ ', docInfo)
      let lv_vm = this
 
      lv_vm.isNofacCnsl = false // 초기화
      lv_vm.lv_TargetType = 'p' // 초기화
      let pParams = {}
      let ctNofacCnslListVO = []

      for (let i = 0; i < docInfo.length; i++) { // 문서 목록
        for (let j = 0; j < lv_vm.custListObj[0].custList.length; j++) { // 선택 고객 목록
          let pParam = {}
          pParam.nofacChnlCustId = lv_vm.custListObj[0].custList[j].chnlCustId // 비대면채널고객ID
          pParam.nofacCnslBusnScCd = 'SA' // 비대면업무구분코드(안내자료:SA)
          pParam.nofacCnsltNo = lv_vm.cnsltNo // 비대면컨설턴트ID
          pParam.doctDeltYn = ' ' // 문서삭제여부
          pParam.nofacCnslDoctNm = docInfo[i].nofacCnslDoctNm // 비대면상담문서명
          pParam.nofacCnslDoctId = docInfo[i].eltrnDoctFileNm // 비대면상담문서ID
          pParam.nofacCnslDoctPathNm = docInfo[i].eltrnDoctFilePathNm // 비대면상담문서경로명
          pParam.pdfFilePathDirNm = docInfo[i].pdfFilePathDirNm // 기존PDF 파일 저장 경로명 예)ic/20190219
          ctNofacCnslListVO.push(pParam)
        }
      }

      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      console.log('### MSPSA201M [비대면화상상담] 자료 저장 start>>' + pParams.ctNofacCnslListVO)

      const trnstId = 'txTSSCT80S1'
      const auth = 'S'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                if (lv_vm.custListObj[0].custList.length === 1) {    // 화상상담 고객이 2명 이상이면 화상상담 자료목록 팝업 호출 
                  lv_vm.lv_selCust = {}
                  lv_vm.lv_selCust.custNm = lv_vm.custListObj[0].custList[0].custNm
                  lv_vm.lv_selCust.chnlCustId = lv_vm.custListObj[0].custList[0].chnlCustId
                  let age = 0
                  let birthDate = lv_vm.custListObj[0].custList[0].dob
                  let contractDate = moment(new Date().toISOString()).format('YYYYMMDD')
                  let fullMonths = 0
                  if (parseInt(contractDate.substring(0, 4)) < 1988) {
                    fullMonths = DateUtil.getMonthPeriodBySingleCalc(birthDate, contractDate)
                  } else {
                    fullMonths = DateUtil.getPassedMonthCount(birthDate, contractDate)
                  } // end else if
                  let years = parseInt(fullMonths / 12)
                  let months = parseInt(fullMonths % 12)
                  if (months > 5) {
                    age = years + 1
                  } else {
                    age = years
                  } // end else if
                  lv_vm.lv_selCust.age = age + '세' // 보험나이
                  
                  lv_vm.fn_nofacCnslBtnClick() // 고객별 화상상담 목록 팝업 열기   
                } else {
                        console.log('### MSPSA201M [비대면화상상담] 자료 저장 end >>' )
                        // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                        // lv_vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                        lv_vm.fn_nofaeCnsl_tostMesg()                              // 비대면상담 자료 저장을 완료했습니다                      
                }
              }
            } else {
              //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },           
 
    /*********************************************************
     * Function명: fn_OpenPopSmsCustList
     * 설명:  고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A 
     *********************************************************/
    fn_OpenPopSmsCustList() {

      let lv_vm = this
      this.custListObj = []

     lv_vm.lv_Popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
     properties: {
       pChnlCustIds: [
         {targetType: 's',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
     },
     listeners: {
        onPopupClose: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
      },
      onNextService: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
        lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData.selectList}] 
        lv_vm.fn_OpenMSPCM128P()
      },
    }
  })          

  },
    /*********************************************************
    * Function명: fn_OpenMSPCM128P
    * 설명: 수신고객목록 팝업오픈 (문자발송)
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function () {
      let lv_vm = this
      let guidList = []
      // if(this.lv_SelectedItemList.length > 1){
      //   guidList = this.lv_SelectedItemList
      // }else{
      //   guidList.push(this.lv_SelectedItemList)   
      // }  
      
      if(this.clickedSingleItem === true){
        guidList.push(this.lv_SelectedSingleItem)
      }else{
        guidList = this.lv_SelectedItemList
      }


        console.log('lv_vm.custListObj >> ', lv_vm.custListObj)
        console.log('lv_vm.guidList >> ',  guidList)
        lv_vm.lv_Popup128 = lv_vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true
        },
        listeners: {
            // 팝업 닫히면서 실행되는 함수
           onPopupClose: (pData) => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },
             // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },         
      // 터치온 발송 프로세스 async
           onNextService: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
              lv_vm.custListObj = []
              lv_vm.custListObj = pData.rtnData
             // 터치온 팝업 오픈(MSPCM154P)
              lv_vm.fn_OpenMSPCM154P()
           }
        }
      })    
    }, 300),    

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_OpenMSPCM154P: _.debounce(function () {

  let lv_vm = this

  lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
    properties: {
      pPage: lv_vm.custListObj.parentsViewId,                   // 모화면 페이지명
      pSmsTargetCustList: lv_vm.custListObj.smsTargetCustList,  // 수신고객리스트
      pMsg: lv_vm.custListObj.msg,                              // 선택된 상용구
      pIsNotEditable: lv_vm.custListObj.isNotEditable           // 메시지 수정가능여부
    },
    listeners: {
      onPopupClose: (pData) => { 
        if (pData.str === 'confirm') {
          lv_vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
        }
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
      }
    }
  })
  } , 300),      


  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  고객선택 수신 팝업오픈-이메일
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {

      if (this.lv_SelectedItemList.length > 5) {
           this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
           return
      } 

      let lv_vm = this
      this.custListObj =  [
         {targetType: 'e',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
      let selectPDFfile = []

      lv_vm.lv_popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
      properties: {
      //   pChnlCustIds: [
      //    {targetType: 'e',
      //     parentsViewId: 'MSPSA201M',
      //     custList: []
      //    }
      //  ],
      pChnlCustIds: lv_vm.custListObj,
        pPDFfile : selectPDFfile
      },
      listeners: {
        onPopupClose: (pData) => {
          // 모달 닫기
          lv_vm.$bottomModal.close(lv_vm.lv_popup152)
        },
        onNextService: async (pData, selectPDFfile) => {
         // 중복제거
          let custList = []    // 문자수신 고객 list
          let cnt = 0
  
          if (pData.selectList.length > 0) {
            for (let i = 0; i < pData.selectList.length; i++) { 
              cnt = 0
              if (i === 0) {
                custList.push(pData.selectList[i])
              } else {
                for (let j = 0; j < custList.length; j++) {
                  if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                    cnt++
                  }
                }
                if (cnt === 0) {
                  custList.push(pData.selectList[i])
                }
              }
            }
          }

          lv_vm.custListObj = [{targetType: 'e', parentsViewId: 'MSPSA201M', custList: custList}]  
          selectPDFfile = lv_vm.lv_SelectedItemList
          let lv_pTitle = '삼성생명' 
          let lv_pText = '고객님 안녕하세요.\n' +
                          '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                          '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                          '오늘도 즐거운 하루 되세요!'  
          let lv_pDataModify = true                
          if (lv_vm.custListObj[0].custList.length > 0) {
            // 모달 닫기
            await lv_vm.$bottomModal.close(lv_vm.lv_popup152)
            // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
            await lv_vm.$cmUtil.fn_OpenMSPCM191P(lv_vm, lv_vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
          }
        },
      }
     })
    },
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked
     * 설명: 인쇄
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (this.lv_SelectedItemList.length === 0) {  
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
      } else  { 
              this.pIsSelectedMultiItem = false
              this.selectItems = [] // 초기화
              this.selectItems = this.lv_SelectedItemList
              this.lv_SelectedItemListTmp = [] // 초기화
              // this.selectItems.forEach(item => {
              //     // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
              //     // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
              //    item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              // })
              this.lv_SelectedItemListTmp = this.selectItems.slice(0)              
              if(this.lv_SelectedItemListTmp.length > 1) {
                 this.pIsSelectedMultiItem = true
               }
               this.fn_OzPrintCntntList()
      }
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked()
     * 설명: 선택 자료 목록 인쇄 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzPrintCntntList() {
      console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList START...☞ ' + new Date().getTime())
      let lv_vm = this

      let tempUntySlmtItemList = []
      // 다중 선택일 경우 선택 자료 목록을 파라미터로 설정
      // if (lv_vm.pIsSelectedMultiItem) {
      //   tempUntySlmtItemList = lv_vm.lv_SelectedItemList
      // } else { // 단일 선택일 경우 선택 자료를 파라미터로 설정
      //   tempUntySlmtItemList.push(lv_vm.lv_SelectedItemList)
      // }
      tempUntySlmtItemList = lv_vm.lv_SelectedItemListTmp

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        tempUntySlmtItemList.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.cnsltNo,
        pblNos: lv_vm.lv_SpinValue,                 // 기본 1 매 설정 
        sACntntBasInfoSVO: tempUntySlmtItemList,
        cnsltInfoIcluYN: this.lv_IsIncludeCnsltInfo  // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      // const trnstId = 'txTSSSA11P1'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( lv_vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                  console.log('@@@ MSPSA201M   인쇄  fn_OzPrintCntntList this.post START ☞ ' + new Date().getTime())
              if (response.body.sACntntSVO.length === lv_vm.selectItems.length) {
              // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                  lv_vm.fn_printtostMesg()                              // 인쇄처리 완료 메세지  
                //lv_vm.$emit('evtCloseTSSSA011P', true)
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList END ☞ ' + new Date().getTime())
        })
        .catch(function (error) {
          window.vue.error(error)
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList Error ☞ ' + new Date().getTime())
        })
    },            
   /*********************************************************
     * Function명: fn_OpenMSPSA210P
     * 설명:  스크랩팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA210P() {
            console.log("MSPSA201M-  fn_OpenMSPSA210M  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup210 = this.$bottomModal.open(MSPSA210P,{
        properties: {
          pPage: 'MSPSA201M',
          pSelectedUntySlmtItems: this.selectItems.slice() // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA210P: (pData) => {
                if(pData !== ''){              
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }else{
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }
            }
         }
        })
      } 
    }, 
   /*********************************************************
     * Function명: fn_OpenMSPSA222M
     * 설명:  화상상담팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA222P() {
            console.log("MSPSA201M-  fn_OpenMSPSA222P  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList.slice()
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup222 = this.$bottomModal.open(MSPSA222P,{
        properties: {
          pPage: 'MSPSA201M',
          pTargetType:  lv_vm.lv_TargetType,
          pSelectedCustList: [],
          pSearchCustNm: ''
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            onPopupConfirm222P: (pData) => {
                if(pData !== ''){      
                  console.log("MSPSA201M-  onPopupConfirm222P  return1 " , pData)
                   lv_vm.lv_SelectedCustList = pData
                   lv_vm.fn_SaveToCouncel()
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)                           
                }else{
                  console.log("MSPSA201M-  onPopupConfirm222P  return2 ")
                   lv_vm.lv_SelectedCustList = []
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)
                }
            },
            onPopupClose222P: () => {
                   this.$bottomModal.close(this.lv_Popup222);
            }
         }
        })
      } 

    }, 
        

    /******************************************************************************
     * Function명 : fn_nofacCnslBtnClick
     * 설명       : 화상상담자료조회popup호출 - MSPSA063P
     ******************************************************************************/
    fn_nofacCnslBtnClick() {
              // 최대 30명까지 선택하도록 제한한다.
      const LIMITED_SMS_SELECTED = 30
      var lv_vm = this

      if (this.lv_SelectedCustList.length > LIMITED_SMS_SELECTED) {
          window.fdpbridge.exec('ToastPlugin', {message: '최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else if (this.lv_SelectedCustList.length === 0) {
          window.fdpbridge.exec('ToastPlugin', {message: '선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else {
        // 화상상담자료조회popup호출
          this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                properties: {
                  pNofacCnslCust: lv_vm.custListObj[0].custList[0]    //this.lv_SelectedCustList[0]    
                },
                listeners: {
                  onPopupConfirm: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  },
                  onPopupClose: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  }
                }
              },
              {
                properties: {
                  noHeader: true
                }
              })
      }        
    },  
    /*********************************************************
    * Function명: fn_print_BottomSheet_XXXX
    * 설명: 인쇄 처리용 버텀 시트 제어
    * Params: N/A
    * Return: N/A
    *********************************************************/
    open() { 
      // this.$refs.bs10.open(); 
    },
    close() { 
      // this.$refs.bs10.close(); 
    },     
    fn_nofaeCnsl_tostMesg(){
        //this.$refs.stackableSnackbar.show({text:'비대면상담 자료를 저장하였습니다.'});
        this.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_printtostMesg(){
        //this.$refs.stackableSnackbar.show({text:'인쇄되었습니다.'});
        this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_print_BottomSheet_open() { 
      if (this.lv_SelectedItemList.length < 1) { 
          //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', '인쇄할 자료를 선택하여 주십시요.')
      } else {
          this.close()
          this.$refs.print_bottomSheet.open();
      }    
    },
    fn_print_BottomSheet_close() { 
      this.$refs.print_bottomSheet.close();
      this.open()
    }, 

    /*********************************************************
     * Function명: fn_ScrollBefore
     * 설명: scroll 위치 복원
     *********************************************************/
    fn_ScrollBefore() {
      this.beforeScrollTop && this.$refs.activity.scrollTo(this.beforeScrollTop)
    },
    /******************************************************************************
    * Function명 : fn_GoldenRulePageMove
    * 설명       : 활동룰 포함 현황 조회 유무 페이지 이동
    ******************************************************************************/
    fn_GoldenRulePageMove(){
      console.log(" @@@@@@@@@@@  lv_gldnrActrExist >> "+this.lv_gldnrActrExist)
      if(this.lv_gldnrActrExist){
        console.log(" @@@@@@@@@@@  MSPAP308M >> ")
        this.$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M'}})
      }
    },
    /*********************************************************
     * Function명: fn_gldnrActrExist
     * 설명: 골든룰활동 컨설턴트 활동룰 포함 현황 조회
     *********************************************************/
    fn_gldnrActrExist(){
      let onpstOrgKndCd = this.getStore('userInfo').getters.getUserInfo.onpstOrgKndCd
      if(onpstOrgKndCd === '11' || onpstOrgKndCd === '20' || onpstOrgKndCd === '14' || onpstOrgKndCd === '13' || onpstOrgKndCd === '15'){
        this.lv_gldnrActrExist = true
      }
    },
    /******************************************************************************
     * Function명 : fn_SelListMemrdCust
     * 설명       : 이벤트 고객 조회 I/F 호출
    ******************************************************************************/
    fn_SelListMemrdCust() {
      let lv_Vm = this
      let pParams = {}
      let trnstId = 'txTSSAP03S1'
      let date = moment(new Date()).format('YYYYMMDD')
      
      pParams =
                { inqrStrYmd: date, // 시작일자
                  inqrEndYmd: date, // 종료일자
                  evtTypCd  : '',
                  cnsltNo : this.cnsltNo
                }

      lv_Vm.evtCustList = []

      let CustList = [
        {cnt:0, custNm:[], evtTypNm:'생일'},
        {cnt:0, custNm:[], evtTypNm:'상령일'},
        {cnt:0, custNm:[], evtTypNm:'계약1주년'},
        {cnt:0, custNm:[], evtTypNm:'만기일'}
      ]

      // lv_Vm.evtCustList = [
      //   {cnt:0, custNm:[{length:11, name:"AAAA BBBBB,"},{length:11, name:"AAAAA BBBBB,"},{length:29, name:"DSADDDDDa sasDASDASDA,"},{length:20, name:"dDASDASDASsad rewDASDA,"},{length:3, name:"rwer werrd,"}], evtTypNm:'생일'},
      //   {cnt:0, custNm:[], evtTypNm:'상령일'},
      //   {cnt:0, custNm:[], evtTypNm:'계약1주년'},
      //   {cnt:0, custNm:[], evtTypNm:'만기일'}
      // ]

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          console.log('이벤트 고객 조회 >>>>>> ', response)
          if (response.body !== null && response.body.iCMemrdCustVO !== null && response.body.iCMemrdCustVO.length > 0) {
            let lv_MemrdCustList = response.body.iCMemrdCustVO

            lv_MemrdCustList.forEach(item => {
              let obj = {}
              // 생일
              if(item.memrdTypCd === '11' || item.memrdTypCd === '12' ||item.memrdTypCd === '13') {
                CustList[0].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[0].custNm.push(obj)
              }
              // 상령일
              if(item.memrdTypCd === '21' || item.memrdTypCd === '22' ) {
                CustList[1].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[1].custNm.push(obj)
              }
              // 계약기념일
              if(item.memrdTypCd === '33') {
                CustList[2].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[2].custNm.push(obj)
              }
              // 만기일
              if(item.memrdTypCd === '51') {
                CustList[3].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[3].custNm.push(obj)
              }
            })
            CustList.forEach(item => {
              let length = item.custNm.length
              if(length > 0) 
              item.custNm[length-1].name = item.custNm[length-1].name.replace(',','')
            })
            lv_Vm.evtCustList = CustList

            console.log("evtCustList",lv_Vm.evtCustList)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_curLoadData
     * 설명       : 골든룰 데이터 가져오기
    ******************************************************************************/
    async fn_curLoadData(){
      this.lv_Params = {
        cnsltNo : this.cnsltNo, 
        stndYm : this.fn_getDate(0)
      }
      this.iCGldnrActWeklySmupDtlVO = []
      let pParams = this.lv_Params
      var lv_Vm = this
      const trnstId = 'txTSSAP09S3'
      const auth = 'S'
      await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
        if (response.body !== null && response.body.iCGldnrActWeklySmupVO !== null){
          if (response.body.iCGldnrActWeklySmupVO.length > 0) {
            let lv_GldnrActList = response.body.iCGldnrActWeklySmupVO
            for (let i = 0; i < lv_GldnrActList.length; i++) {
              let GldnrActList = {}
              if(lv_GldnrActList[i].curYn === 'Y') {
                lv_Vm.GldnrWekl = moment(lv_GldnrActList[i].gldnrMnStrYmd).format('M') + "월 " + lv_GldnrActList[i].weklyDgr + "주차 "
                lv_Vm.GldnrChk   = lv_Vm.lv_acvmtYnData.find(x => x.key === lv_GldnrActList[i].acvmtYn).label

                if(lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO != null && lv_Vm.GldnrChk != '미진행'){
                  for(let j=0;j < lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO.length; j++){
                    let tmpObj = {}
                    tmpObj.gldnrActTypNm         = lv_Vm.lv_AcvmtItmData.find(x => x.key === lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypCd).label
                    tmpObj.actCnt                = lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].actCnt
                    tmpObj.gldnrActTypGoalVal01  = lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypGoalVal01
                    lv_Vm.iCGldnrActWeklySmupDtlVO.push(tmpObj)
                    console.log("this.iCGldnrActWeklySmupDtlVO >>>",lv_Vm.iCGldnrActWeklySmupDtlVO)
                  }
                }
              }
            }
          }else{
          }
        }else{
        }
      }).catch(function (error) {
        window.vue.error(error)
        lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다.')
      })
    },
    /******************************************************************************
     * Function명 : fn_getDate
     * 설명       : 월 계산
    ******************************************************************************/
    fn_getDate(val){
      let d = new Date()
      if(val !== 0){
        let lastDay = (new Date(d.getFullYear(), d.getMonth(),0)).getDate()
        if(d.getDate() > lastDay){
          d.setDate(lastDay)
        }
        d.setMonth(d.getMonth() -1)
      }
      return d.getFullYear() + ('0'+(d.getMonth()+1)).slice(-2)
    },
    /*********************************************************
      * Function명: fn_SelectListPrdSchdl
      * 설명: 일정을 조회한다.
    *********************************************************/
    fn_SelectListPrdSchdl () {
      let lv_Vm = this
      var trnstId = 'txTSSAP20S5'
      let date = moment(new Date()).format('YYYYMMDD')
      lv_Vm.SchdList = []
      var pParam = {
        userEno: this.cnsltNo,
        fcSchdlStrYmd: date,
        fcSchdlEndYmd: date
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        if (response.msgComm.msgCd === 'EFWS004') {
          // 실패시
          window.fdpbridge.exec('ToastPlugin', {message: response.msgComm.msgDesc}, () => {}, () => {})
        }
        if ( response.body != null && response.body.aPSchdlInfoSVO != null) {
          let lv_SchdList = response.body.aPSchdlInfoSVO
          for (let i = 0; i < lv_SchdList.length; i++) {
            let tmpObj = {}
            if(!lv_Vm.$bizUtil.isEmpty(lv_SchdList[i].fcSchdlStrHm)) {
              let hour24  = parseInt(lv_SchdList[i].fcSchdlStrHm.slice(0,2))
              let minutes = parseInt(lv_SchdList[i].fcSchdlStrHm.slice(2))

              let hour12  = hour24 % 12 || 12

              if(hour12.toString().length === 1) {
                hour12 = "0" + hour12
              }
              if(minutes.toString().length === 1) {
                minutes = "0" + minutes
              }

              tmpObj.startTime = "[" + hour12 + ":" + minutes + "]"

              if(lv_SchdList[i].fcSchdlStrHm.substr(0,2) > 11) {
                tmpObj.afternoonYn = '오후'
              } else {
                tmpObj.afternoonYn = '오전'
              }
            } else {
              tmpObj.startTime = "[종일]"
            }
            tmpObj.custNm           = lv_SchdList[i].fcSchdlNm
            tmpObj.SchdlNm          = lv_SchdList[i].fcSchdlCntnt
            tmpObj.fcSchdlScCd      = lv_SchdList[i].fcSchdlScCd
            tmpObj.fcSchdlPrmsPlcNm = lv_SchdList[i].fcSchdlPrmsPlcNm

            if(lv_SchdList[i].fcSchdlScCd === '3') {
              tmpObj.CntcNm = '공유일정'
            }

            apConstants.fcCustCntc.forEach(v => {
              if (v.id === lv_SchdList[i].custCntcId) {
                tmpObj.CntcNm = v.name
              }
            })
            console.log("tmpObj >>>>", tmpObj)
            lv_Vm.SchdList.push(tmpObj)
            
          }
          console.log("내 일정 데이터 >>>>", lv_Vm.SchdList)
        }
      }).catch(function (err) {
        console.log('MSPAP314M===>err', err)
      })
    },
    /*********************************************************
      * Function명: fn_callHis
      * 설명: 전화이력 조회한다.
    *********************************************************/
    fn_callHis() {
        console.log('오늘 전화이력')
        const lv_Vm   = this 
        const trnstId = 'txTSSAP06S1' 
        const auth    = 'S'

        lv_Vm.callList = []
        lv_Vm.callCnt = []

        let pParams   = {
          mobslCntcOccuYmd: moment(new Date()).format('YYYYMMDD'),
          eno             : lv_Vm.cnsltNo
        }
        
        console.log('param>>!', pParams)
        lv_Vm.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('response.body', response.body)
            if (response.body !== null && response.body.telHstrVO !== null && response.body.telHstrVO !== undefined) {
            let lv_CustList = response.body.telHstrVO

            lv_CustList.forEach((item) => {
              let tmpObj = {}
              if(item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'SEND' || item.mobslCustCntcId === 'RECI') {
                tmpObj.custNm          = item.custNm
                tmpObj.mobslCntcCntnt  = item.mobslCntcCntnt
                tmpObj.mobslCustCntcId = item.mobslCustCntcId

                if(!lv_Vm.$bizUtil.isEmpty(item.phclMemoCntnt)) {
                  tmpObj.phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                } else {
                  tmpObj.phclMemoCntnt = item.phclMemoCntnt
                }

                lv_Vm.callList.push(tmpObj)
                lv_Vm.callCnt.push(item.mobslChnlCustId)
              }
            })
            lv_Vm.callCnt = [...new Set(lv_Vm.callCnt)]
            console.log("lv_Vm.callList", lv_Vm.callList)
          }
        })
      },
      /*********************************************************
       * Function명: fn_SelectHolidayList
       * 설명: 휴일 목록을 조회한다.
       *********************************************************/
      fn_SelectHolidayList () {
        let lv_Vm = this

        let trnstId = 'txTSSAP03S2'
        let pParam = {
          inqrStrYmd: moment(new Date()).format('YYYYMMDD'),
          inqrEndYmd: moment(new Date()).format('YYYYMMDD')
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          let iCYmdMngVO = response.body.iCYmdMngVO
          if ( response.body != null && iCYmdMngVO != null && iCYmdMngVO.length > 0) {
            if(iCYmdMngVO[0].holyCd === '1') {
              lv_Vm.holiday = iCYmdMngVO[0].holyNm
            }
          }
        })
      },
      /******************************************************************************
      * Function명  : fn_SelDeltParngCustCnt ()
      * 설명        : 홈화면 배너용 삭제예정고객 수 조회
      * Params      : N/A
      * Return      :
      ******************************************************************************/
      fn_SelDeltParngCustCnt () {
        let lv_Vm = this
        let pParams = {
          cnsltNo : this.cnsltNo
        }
        const trnstId = 'txTSSBC41S4'
        const auth = 'S'

        lv_Vm.delParngCust = []

        console.log("txTSSBC41S4 >>>>>>>>")
        return this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
          if (response?.body) {
            if ( response.body.iCDeltParngCustVO?.length ) {   
              let date = moment(new Date()).format('YYYYMMDD') 
              let deltList = response.body.iCDeltParngCustVO

              let tmpObj = {
                cnt : 0,
                custNm : []
              }
              
              let exCnt = deltList.filter(e => e.mndtCnstgCnsntEndYmd === date).length

              let idx = 1
              deltList.forEach(item => {
                let nmObj = {}

                if(date === item.mndtCnstgCnsntEndYmd) {
                  tmpObj.cnt++
                  // tmpObj.custNm = tmpObj.custNm + item.custNm + ','

                  nmObj.length = item.custNm.length

                  nmObj.name = item.custNm + (idx<exCnt ? ',' : '')

                  tmpObj.custNm.push(nmObj)
                  idx++
                }
              })
              // tmpObj.custNm = tmpObj.custNm.split(',')
              // tmpObj.custNm.pop()
              // tmpObj.custNm = tmpObj.custNm.join(',')
              if(tmpObj.cnt > 0) {
                lv_Vm.delParngCust.push(tmpObj)
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명  : fn_ManagerSearch
      * 설명        : (지점장) 지점 FC 활동 조회
      ******************************************************************************/
      fn_ManagerSearch() {
        let lv_vm = this
        lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', false)
        console.log('store speaker Y')
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPBC003M',
            pData: this.fcData,
            fcDtlData : this.fcDtlData
          },
          listeners: {
            onPopupSearch: (pItem, pData) => {
              console.log('store speaker N')
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              
              console.log('onPopupSearch 컨설턴트 >> ' , pItem)
              console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
              // 모달 닫기
              lv_vm.$bottomModal.close(this.popupAP106)
              if(pItem != null && pItem != undefined){
                
                lv_vm.cnsltNm   = pItem.label
                lv_vm.cnsltNo   = pItem.key

                lv_vm.fcData = pItem     // router params 데이터                
                lv_vm.fcDtlData = pData

                lv_vm.fn_searhData()
              }
            },
            onPopupClose: () => {
              console.log('store speaker N')
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              console.log('onPopupClose')
              // 모달 닫기
              this.$bottomModal.close(this.popupAP106)
            }
          }
        })
      },
      /******************************************************************************
      * Function명  : fn_searhData
      * 설명        : 데이터 조회
      ******************************************************************************/
      fn_searhData() {

        // 삭제예정고객 수 조회
        this.fn_SelDeltParngCustCnt()

        // 당월 실적조회
        this.lv_IsShowGauge && this.fn_SelThmmActActul()

        // 골든룰현황 조회
        this.fn_gldnrActrExist()

        // 활동캘린더 조회
        this.fn_SelListMemrdCust()
      
        // 골든룰 데이터 조회
        this.fn_curLoadData()
        
        // 일정 조회
        this.fn_SelectListPrdSchdl()
      
        // 전화이력 조회
        this.fn_callHis()
      
        //통합영업자료사용권한코드
        this.fn_SetUntySlmtUseAuthCd()

        // 최신자료 로딩
        this.fn_SelRntCntnt()
      
        // scroll 위치 복원
        this.fn_ScrollBefore()
      },
      /******************************************************************************
      * Function명  : fn_Init
      * 설명        : 변수 초기화
      ******************************************************************************/
      fn_Init() {
        this.fcData                        = {}
        this.SchdList                      = []
        this.evtCustList                   = []
        this.iCGldnrActWeklySmupDtlVO      = []
        this.callList                      = []
        this.smryText                      = ''
        this.delParngCust                  = []
        this.lv_ChnlQulfNm                 = ''
        this.lv_ActualRate                 = 0
        this.achievValue                   = 20
        this.runFlagSwitch                 = false
        this.startFlagSwitch               = false
        this.flagMoveValue                 = '0'
        this.percentNumberMoveValue        = '0'
        this.achievValueCounter            = 0
        this.achievValueTimeout            = null
        this.achievValueIntervalTimer      = null
        this.achievValueTimerSwitch        = false
        this.currentScrollTopIndex         = 0
        this.activityMessageTransformValue = '0'
        
      },
      catchTouchEvent(event) {
        // 함수 추가 2025.1.10 :chan
        // scroll button과 전체 scroll과의 event 충돌 피하기
        if(event.type === 'touchstart'){
          this.scrollBtnLeftStart =  document.querySelector('.activityScrollBtnWrap').scrollLeft
          document.querySelector('.todayActivityPage').removeEventListener('scroll', this.catchScrollEvent, true)
        } else if(event.type === 'touchend'){
          this.scrollBtnLeftEnd = document.querySelector('.activityScrollBtnWrap').scrollLeft
          if(this.scrollBtnLeftStart === this.scrollBtnLeftEnd){
            document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
          }
        } else if(event.type === 'scrollend'){
          document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
        }
      },
      catchScrollEvent(event) {
        // 전체 수정 2025.1.10 :chan
        // scroll 내리면서 '이달의 업적'이 화면에 보일 때 character animation 실행
        let scrollAmount = document.querySelector('.mo-page__contents-wrapper').scrollTop
        let activityAchievWrapWidth = document.querySelector('.activityAchievBg').clientWidth
        let myAchievWrapOffsetTop = document.querySelector('#myAchievWrap').offsetTop
        let scrollGap = Math.abs(scrollAmount - myAchievWrapOffsetTop) - 100

        let activityScrollBtnWrap = document.querySelector('.activityScrollBtnWrap')
        if(activityScrollBtnWrap.offsetTop > 140) activityScrollBtnWrap.classList.add('onScroll') // 그림자 class 생성
        else activityScrollBtnWrap.classList.remove('onScroll')

        if (this.zero100AchievValue > 0 && scrollGap < this.documentScale.height*0.4){
          let availableWidth = Math.ceil((activityAchievWrapWidth * this.zero100AchievValue) / 100)
          let remainWidth = activityAchievWrapWidth - availableWidth
          this.runFlagSwitch = true
          this.startFlagSwitch = true
          this.animateAchievValueBox(this.zero100AchievValue)
          this.flagMoveValue = this.zero100AchievValue + '%'
          if(20 > availableWidth){
            this.percentNumberMoveValue = '20px'
          } else {
            this.percentNumberMoveValue = this.zero100AchievValue + '%'
          }
        }
        // scroll 바닥이면 함수 종료
        let documentScrollTop = document.querySelector('.mo-page__contents-wrapper').scrollTop
        let scrollToAllHeight = document.querySelector('.myWork_container').clientHeight
        if(scrollToAllHeight - documentScrollTop < 480){ return } // 475px

        // 현재 scroll 위치에 따라 scroll button 변경
        let scrollCurrent = scrollAmount + 120 // 여유값
        let scrollElements = document.getElementsByClassName('scrollTo')
        let lastScrollTo = document.getElementsByClassName('scrollTo')[document.getElementsByClassName('scrollTo').length-1].offsetTop
        for(let i = scrollElements.length-1; i >= 0; i--){
          if(scrollCurrent >= scrollElements[i].offsetTop){
            this.currentScrollToIndex = i
            break
          }
        }
        if(scrollCurrent < lastScrollTo) {
          let activityScrollBtn = document.getElementsByClassName('activityScrollBtn')
          for(let i = 0; i < activityScrollBtn.length; i++){
            activityScrollBtn[i].classList.remove('checked')
          }
          activityScrollBtn[this.currentScrollToIndex].classList.add('checked')

          let scrollButtonLeft = 0
          for(let j = 0; j < this.currentScrollToIndex; j++){
            scrollButtonLeft += activityScrollBtn[j].clientWidth + 5 // flex gap
          }
          document.querySelector('.activityScrollBtnWrap').scrollTo({left:scrollButtonLeft - activityScrollBtn[this.currentScrollToIndex].clientWidth, top:0})
        }
      },
      animateAchievValueBox(value) {
        if(this.achievValueTimerSwitch === false){
          this.achievValueTimeout = setTimeout( () => {
            this.achievValueIntervalTimer = setInterval( () => {
            if(this.achievValueCounter < value){
              this.achievValueCounter++
            } else {
              clearInterval(this.achievValueIntervalTimer)
              this.achievValueIntervalTimer = null
            }}, 20) // 숫자 바뀌는 속도 (ms)
          }, 500) // 숫자 변경 시작 지연 시간 (ms)
          this.achievValueTimerSwitch = true
        }
      },
      scrollButtonCheck(index) {
        let activityScrollBtn = document.getElementsByClassName('activityScrollBtn')
        for(let i = 0; i < activityScrollBtn.length; i++){
          activityScrollBtn[i].classList.remove('checked')
        }
        document.getElementsByClassName('activityScrollBtn')[index].classList.add('checked')

        // 해당 contents로 scroll 이동
        let scrollBtnHeight = document.querySelector('.activityScrollBtnWrap').clientHeight
        let scrollAmount = document.getElementsByClassName('scrollTo')[index].offsetTop - scrollBtnHeight - 10
        document.querySelector('.mo-page__contents-wrapper').scrollTo({left:0, top:scrollAmount})
      },
      showAllSchedule() {
        // schedule 길게 펼쳐보기 2024.12.20 :chan
        if(this.scheduleAmount < this.SchdList.length){
          this.scheduleAmount = this.SchdList.length
          this.showMore = '줄이기'
        } else {
          this.scheduleAmount = 3
          this.showMore = '더보기'
        }
      },
      showAllCallList() {
        // Call List 펼쳐보기 2024.12.20 :chan
        if(this.callListAmount < this.callList.length){
          this.callListAmount = this.callList.length
          this.showAllList = '줄이기'
          this.showAllIcon = 'minus'
        } else {
          this.callListAmount = 3
          this.showAllList = '더보기'
          this.showAllIcon = 'plus'
        }
      },

      /******************************************************************************
      * Function명  : fn_TodayText
      * 설명        : 오늘의 활동 읽어주기 문구 설정
      ******************************************************************************/
      fn_TodayText(value) {
        this.smryText = ''

        let schdl = ''
        let memrd = ''
        let gldnr = ''
        let deltParng = ''

        // 인트로 셋팅
        let intro = ''
        if(this.$bizUtil.isEmpty(this.holiday)) {
          intro = this.date + ' ' + this.day + '요일 오늘의 활동을 안내해드릴게요.' 
        } else {
          intro =  this.date + this.day + '요일 ' + this.holiday + ' 오늘의 활동을 안내해드릴게요.'
        }

        // 1: 요약 듣기, 2: 자세히 듣기
        if(value === 2) {
          // 일정
          if(this.SchdList.length > 0) {
            schdl = "오늘 등록된 일정입니다. "
            this.SchdList.forEach(item => {
              let time = item.startTime.replace(/[\[\]]/g,"").split(":")

              // 활동일정
              if(item.fcSchdlScCd === '1') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    if(!this.$bizUtil.isEmpty(item.CntcNm)) {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                    } else {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 '  +  '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                    }
                  } else {
                    if(!this.$bizUtil.isEmpty(item.CntcNm)) {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 있습니다. '
                    } else {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + ' 일정이 있습니다. '
                    }
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 있습니다. '
                  }
                }
              }

              // 개인일정
              if(item.fcSchdlScCd === '2') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 있습니다. '
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '일정이 있습니다. '
                  }
                }
              }

              // 공유일정
              if(item.fcSchdlScCd === '3') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 있습니다. '
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '일정이 있습니다. '
                  }
                }
              }

              schdl = schdl.replace("00분", "")
            })
          }

          // 이벤트
          if(this.evtCustList.length > 0) {
            memrd = '오늘 특별한 날을 맞이한 고객입니다. '
            this.evtCustList.forEach(item => {
              let nmList = ''
              item.custNm.forEach(e => {
                nmList = nmList + e.name
              })

              //0건인 항목 제외 2025-01-17
              if(item.cnt>0){
                memrd = memrd + item.evtTypNm + '고객은 ' + nmList + ' ' + item.cnt + '명 있습니다.' 
              }
            })
          }

          // 동의만료
          if(this.delParngCust.length > 0) {
            let delNmList = ''
            this.delParngCust[0].custNm.forEach(item => {
              delNmList = delNmList + item.name
            })

            deltParng = '오늘 필수컨설팅 동의가 만료되는 고객이 ' + this.delParngCust[0].cnt + '명 있습니다.'
                        // + this.delParngCust[0].custNm + ' 고객님의 동의를 확인해보세요.'
                        + delNmList + ' 고객님의 동의를 확인해보세요.'
          }

          // 골든룰
          if(this.GldnrChk === '진행중' || this.GldnrChk === '달성') {
            if(this.GldnrChk === '진행중') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 현재 미달성 중입니다.'
              this.iCGldnrActWeklySmupDtlVO.forEach(item => {
                if((Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) > 0) {
                  gldnr = gldnr + item.gldnrActTypNm + ' ' + (Number(item.gldnrActTypGoalVal01)-Number(item.actCnt)) + '건 '
                }
              })
              gldnr = gldnr + '이 더 필요합니다.'
            }
            if(this.GldnrChk === '달성') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 이미 달성하셨습니다.'
            }
          }
        } 
        else {
          // 일정
          if(this.SchdList.length > 0) {
            schdl = "오늘 등록된 일정입니다. "
            this.SchdList.forEach(item => {
              let time = item.startTime.replace(/[\[\]]/g,"").split(":")

              // 활동일정
              if(item.fcSchdlScCd === '1') {
                  if(!item.startTime.includes("종일")) {
                    schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객. '
                  } else {
                    schdl = schdl + item.custNm + '고객. '
                  }
              }

              // 개인일정
              if(item.fcSchdlScCd === '2') {
                if(!item.startTime.includes("종일")) {
                  schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' +  item.custNm + '. '
                } else {
                  schdl = schdl + item.custNm + '. '
                }
              }

              // 공유일정
              if(item.fcSchdlScCd === '3') {
                if(!item.startTime.includes("종일")) {
                  schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' +  item.custNm + '. '
                } else {
                  schdl = schdl + item.custNm + '. '
                }
              }

              schdl = schdl.replace("00분", "")
            })
            schdl = schdl + '일정이 있습니다. '
          }

          // 이벤트
          if(this.evtCustList.length > 0) {
            memrd = '오늘 특별한 날을 맞이한 고객입니다. '
            this.evtCustList.forEach(item => {
              if(item.cnt>0){
                memrd = memrd + item.evtTypNm + '인 고객은 ' +  item.cnt + '명 ' 
              }
            })
            memrd = memrd + ' 있습니다. '
          }

          // 동의만료
          if(this.delParngCust.length > 0) {
            deltParng = '오늘 필수컨설팅 동의가 만료되는 고객이 ' + this.delParngCust[0].cnt + '명 있습니다. '
          }

          // 골든룰
          if(this.GldnrChk === '진행중' || this.GldnrChk === '달성') {
            if(this.GldnrChk === '진행중') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 현재 미달성 중입니다.'
              this.iCGldnrActWeklySmupDtlVO.forEach(item => {
                if((Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) > 0) {
                  gldnr = gldnr + item.gldnrActTypNm + ' ' + (Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) + '건 '
                }
              })
              gldnr = gldnr + '이 더 필요합니다. '
            }
            if(this.GldnrChk === '달성') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 이미 달성하셨습니다. '
            }
          }
        }

        let outro = '오늘 하루 활동도 화이팅입니다.'

        this.smryText = intro + schdl + memrd + deltParng + gldnr + outro
        
        if(schdl === '' && memrd === '' &&  deltParng === '' ) {
          this.smryText = intro + '아직 등록된 일정이 없습니다. 고객과 통화 후 전화메모를 남겨보거나 일정을 계획해 보세요.' + gldnr + outro
        }
        console.log("TTS 문장 확인 >>>>> ", this.smryText)
      },
      getStyle(data) {
        return data < 20 ? 'white-space: nowrap' : ''
      },
      /******************************************************************************
      * Function명  : fn_schlAdd
      * 설명        : 일정등록
      ******************************************************************************/
      fn_schlAdd() {
        let lv_vm = this
        lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', false)
        this.fullPopup = this.$bottomModal.open(MSPAP302D, {
            properties: {
            pPage: 'MSPBC003M',
            pIsBackKeyListen: true,
            pFcSchdlSno: 0,
            pMobslChnlCustId: '',
            pCustAlctnCmpgnId: '',
            pChoiceYmd: moment(new Date()).format('YYYY-MM-DD'),
            pSrnId: 'MSPBC003M'
          },
          listeners: {
            closeDivision: () => {
              console.log('closeDivision')
              this.$bottomModal.close(this.fullPopup)
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              this.$bottomModal.close(this.fullPopup)
              this.fn_SelectListPrdSchdl()
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
            },
            fn_OnRefresh: () => {
              this.fn_SelectListPrdSchdl()
              this.$refs.schListDtl.close()
            }
          }
        })
      }
  }
}
</script>
<style scoped>
</style>
