/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI800P
 * 화면설명: 선심사
 * 접근권한: 
 * 작 성 일: 2024.01.08
 * 작 성 자: 
 */
 <template>
  <ur-page-container ref="page" class="msp btn-back-clear" title="선심사" :show-title="false" type="popup" :topButton="(lv_CurrntStep.key === '2' || lv_CurrntStep.key === '3')" :topButtonBottom="150">
   <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack(lv_CurrntStep.key)">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '선심사' : '선심사'}}</div>
              <div slot="action">
                <span><mo-button shape="border" class="ns-btn-round btn-title-style-1 mr5" v-show="lv_CurrntStep.key === '2' && ((!lv_isVisblePrtrPeson && lv_MnisdCustInfo.cnsntYn === 'Y') || (lv_MnisdCustInfo.cnsntYn === 'Y' && lv_isVisblePrtrPeson && lv_IsAllCnsnt))" @click="fn_SearchInsmPymBrkdnInfo('')">지급정보</mo-button></span>
                <span><mo-button shape="border" class="ns-btn-round btn-title-style-1 mr5" v-show="(lv_CurrntStep.key === '2' || lv_CurrntStep.key === '3')" @click="fn_OpenMSPPI803P">이전 질병정보</mo-button></span>
                <span><mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_HistoryBack('close')">close</mo-icon></span>
              </div>
            </mo-top-bar>
          </template>
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>
          <!-- stickey 영역 -->
          <template #sticky>
            <!-- 진행단계 -->
            <ur-box-container v-if="lv_CurrntStep.key !== '4'" alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
              <span class="fs22rem fwb">{{ lv_CurrntStep.title }}</span>
              <div><span class="fs20rem crTy-blue2 fwb mr2">{{ lv_CurrntStep.key }}</span>/<span class="ml2">3</span></div>
            </ur-box-container>
          </template>
          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Contents 영역 START -->
            <!-- 지급정보동의 -->
            <MSPPI801D ref="refMSPPI801D"
                       v-show="lv_CurrntStep.key === '1' && Object.keys(lv_MnisdCustInfo).length > 0"
                       :isVisblePrtrPeson="lv_isVisblePrtrPeson"
                       @openMSPPI804P="fn_ConfirmPrtrPeson"
                       @updateCnsnt="fn_UpdateCnsnt"
                       @sendTalk="fn_PreSendTalk"
                       @selClmInfo="fn_SearchInsmPymBrkdnInfo">
            </MSPPI801D>
            <!-- 사전정보 입력 -->
            <MSPPI802D ref="refMSPPI802D"
                      :mnisdCustInfo="lv_MnisdCustInfo"
                      :formId="lv_FormId"
                      :befrInfoDto="lv_BefrInfoDto"
                      :nundwJdgPrgStatCd="lv_NundwJdgPrgStatCd"
                      :diseYn="diseYn"
                      @openMSPPI830P="fn_OpenMSPPI830P"
                       v-show="lv_CurrntStep.key === '2'">
            </MSPPI802D>
            <!-- 질병상세정보 -->
            <MSPPI810D ref="refMSPPI810D"
                       v-show="lv_CurrntStep.key === '3'"
                       :diseDtlDto="lv_DiseDtlDto"
                       :formId="lv_FormId"
                       :nundwJdgPrgStatCd="lv_NundwJdgPrgStatCd"
                       @openMSPPI830P="fn_OpenMSPPI830P"
                       @saveTemp="fn_SelAplNundwInfo('P1')">
                       <!-- @saveTemp="fn_ServiceData('P1')"> -->
            </MSPPI810D>
            <!-- 선심사정보 입력완료 -->
            <!-- <MSPPI812D ref="refMSPPI812D"
                       v-show="lv_CurrntStep.key === '4'"
                       @aplNundw="fn_PreAplNundw">
            </MSPPI812D> -->
            <!-- Contents 영역 END -->
            <!-- 하단 버튼 영역 START -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button v-if="lv_CurrntStep.key !== '1'" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_MoveStep('P')">이전</mo-button>
                <mo-button v-if="lv_CurrntStep.key !== '3'" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveStep('N')">다음</mo-button>
                <mo-button v-if="lv_CurrntStep.key === '3'" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SelAplNundwInfo()">선심사신청</mo-button>
                <!-- <mo-button v-if="lv_CurrntStep.key === '3'" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_PreAplNundw('1')">선심사신청</mo-button> -->
              </div>
            </ur-box-container>
            <!-- 하단 버튼 영역 END -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- Popup: 지급정보안내 -->
    <mo-bottom-sheet preventTouchClose ref="refPymInfoGudn" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        지급정보 안내
      </template>
      <div class="ns-btn-close" @click="$refs.refPymInfoGudn.close()"></div>
       
      <div class="content-area">
        <ur-box-container class="customer-info-txt pal0">
          <p class="ment">고객님의 당타사 보험금 지급정보를 직접 확인할 수 있도록 고객카드에 등록된 고객님의 휴대폰으로 동의 알림톡을 전송합니다.</p>
          <p class="ment mt10">컨설턴트는 고객 상담과정에서 취득한 정보를 제공받은 목적 외로 이용하거나 제3자에게 누설할 수 없습니다.</p>
          <p class="ment mt10">휴대폰 번호 확인 후 전송바랍니다.</p>
        </ur-box-container>
        <div class="round-box bgcolor-1 mt20">
          <div class="fexTy3" v-for="(item, index) in lv_SendTalkList" :key="index">
            <div :class="index === 0 ? 'fexTy5' : 'fexTy3 mt10'">                
              <span class="mr10">
                <mo-badge class="badge-box h28" :class="{'lightblue': item.contvInsrdTypCd === '21', 'lightblue2': item.contvInsrdTypCd === '23', 'purple': item.contvInsrdTypCd !== '21' && item.contvInsrdTypCd !== '23'}" text="" shape="status">{{ item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23' ? fn_setLabel(item.contvInsrdTypCd) : item.hasOwnProperty('index') ? fn_setLabel(item.index) : fn_setLabel(String(index))}}</mo-badge>
              </span>                
              <span class="w95px txtSkip fwb fs19rem">{{ item.custNm }}</span>
            </div>
            <span class="fs17rem">{{ item.custTelno }}</span>
          </div> 
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_SendCnsntIapTalk" class="ns-btn-round blue">전송</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup: 지급정보안내 -->

    <!-- Popup : 친권자 설정 안내 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refPrtrPesonInfo" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">친권자 설정을 위해서는 세대구성원의<br/>필수컨설팅 동의가 필요합니다.</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPPI804P" name="친권자 설정">친권자 설정</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup : 친권자 설정 안내 알럿 -->
    <!-- // Popup : 이전질병정보 불러오기 안내 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refPreDiseInfo" :close-btn="true" class="ns-bottom-sheet">
      <template v-slot:title>
        이전 질병정보
      </template>
      <!-- content 영역 -->
      <div class="content-area">
        <div class="round-box bgcolor-7">
          <mo-list :list-data="[lv_PreDiseInfo]">
            <template #list-item="{item}">
              <div class="list-item__contents">
                <div class="list-item-detail">
                  <div class="contents-row mb10">
                    <span class="fwb">{{item.prdtNm}}</span>
                  </div>
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min50 mr10">설계명</span><span class="fs16rem">{{item.planNm}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem crTy-bk7 min50 mr10">저장일</span><span class="fs16rem">{{item.hasOwnProperty('avlStrYmd') ? $bizUtil.dateDashFormat(item.avlStrYmd.replace(/-/g, '').substr(0,8)) : ''}}</span>
                    <!-- <span class="dsInline fs14rem crTy-bk7 min50 mr10">저장일</span><span class="fs16rem">{{item.avlStrYmd !== '' ? $bizUtil.dateDashFormat(item.avlStrYmd.replace(/-/g, '').substr(0,8)) : ''}}</span> -->
                  </div>
                </div>
              </div>
            </template>
          </mo-list> 
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.refPreDiseInfo.close()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup : 보험금지급정보 -->
    <mo-bottom-sheet preventTouchClose ref="refClmInfo" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        보험금 지급정보
      </template>
      <div class="ns-btn-close" @click="$refs.refClmInfo.close()"></div>
      <div class="content-area h90">
        <ur-box-container v-if="lv_InsmPymBrkdnInfoList.length > 0" class="ns-check-list list-pa2024 mlr-24">
          <div class="ns-check-sum w100">
            <mo-list-item>
              <div class="list-item__contents__title">
                <span class="sum">총 <strong class="crTy-blue3">{{lv_InsmPymBrkdnInfoList.length}}</strong> 건</span>
              </div>
            </mo-list-item>
          </div>
          <mo-list :list-data="lv_InsmPymBrkdnInfoList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3-1">
                    <span class="name flex-1">{{item.krReprDiseClsfCdNm}}</span> 
                  </div>
                  <div class="list-item__contents__info pt5">  
                    <span class="hauto mr20 fs14rem line-h">대표질병코드</span>
                    <span class="hauto crTy-bk1 line-h">{{item.krReprDiseClsfCd}}</span>   
                  </div>
                  <div class="list-item__contents__info pt1 pb0">  
                    <span class="hauto mr20 fs14rem line-h">사고발생일자</span>
                    <span class="hauto crTy-bk1 line-h">{{item.hasOwnProperty('orgnAcdtOccuDtm') ? item.orgnAcdtOccuDate : ''}}</span>   
                  </div>
                  <div class="list-item__contents__info pt1 pb0">  
                    <span class="hauto mr10 fs14rem line-h">입원여부</span>
                    <span class="hauto crTy-bk1 line-h" :class="{'crTy-blue2 ' : item.hspzYn === 'O', 'crTy-orange2' : item.hspzYn !== 'O'}">{{item.hspzYn}}</span><em>|</em>  
                    <span class="hauto mr10 fs14rem line-h">통원여부</span>
                    <span class="hauto crTy-bk1 line-h" :class="{'crTy-blue2 ' : item.mdhspYn === 'O', 'crTy-orange2' : item.mdhspYn !== 'O'}">{{item.mdhspYn}}</span><em>|</em>
                    <span class="hauto mr10 fs14rem line-h">수술여부</span>
                    <span class="hauto crTy-bk1 line-h" :class="{'crTy-blue2 ' : item.surgYn === 'O', 'crTy-orange2' : item.surgYn !== 'O'}">{{item.surgYn}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
          <ul class="terms-list-area crTy-bk7 fs14rem mt20 pl24 pr24">
            <li>사고발생일자 : 해당질병으로 발생한 지급정보 중 마지막 사고일자</li>
          </ul>
        </ur-box-container>
        <!-- no-data start -->
        <ur-box-container v-else class="ns-list-area">
          <div class="no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info small-space">
                  <span class="ns-ftcr-gray">
                    <p class="mla0">데이터가 없습니다.</p>
                  </span>
                </div>
              </div>
            </mo-list-item>
          </div>
        </ur-box-container>
        <!-- // no-data end --> 
      </div>
    </mo-bottom-sheet>
    <!-- // Popup : 공통 안내 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refCommAlert" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{lv_AlertMsg}}</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.refCommAlert.close()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup : 선심사 조회 오류 시 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refErrAlert" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{lv_ErrAlertMsg}}</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseErrAlert">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPPI801D from '@/ui/pi/MSPPI801D' // 지급정보동의
import MSPPI802D from '@/ui/pi/MSPPI802D' // 사전정보 입력
import MSPPI810D from '@/ui/pi/MSPPI810D' // 질병상세고지
import MSPPI804P from '@/ui/pi/MSPPI804P' // 친권자설정
import MSPPI803P from '@/ui/pi/MSPPI803P' // 이전 질병정보 불러오기
import MSPPI830P from '@/ui/pi/MSPPI830P' // 미결상세조회
import Gaf from '@/ui/pi/common/Gaf'
import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                        *
   ***********************************************************************************/
  name: "MSPPI800P",
  screenId: "MSPPI800P",
  isBackKeyHandle: true,
  components: {
    MSPPI801D,
    MSPPI802D,
    MSPPI810D,
    MSPPI804P,
    MSPPI803P,
    MSPPI830P,
  },
  props: {
    pPage: {
      type: String
    },
    /*
    planId: '', // 설계번호
    custId: '', // 주피보험자고객ID
    chnlCustId: '', // 주피 or 종피채널고객ID
    dsclsId: '', // 고지ID(선심사번호)
    nundwJdgPrgStatCd: '', // 선심사진행상태코드(최초작성: 00, 작성중: 10)
    */
    pParams: {
      type: Object
    },
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      name: 'MSPPI800P',
      lv_HeaderviewScrollCompID: '',
      headerVO: {},
      // 선심사 스텝
      lv_StepItem: [
        {key: '1', viewId: 'MSPPI801D', title: '지급정보동의(선택)', isFirst: true, isVisible: true},
        {key: '2', viewId: 'MSPPI802D', title: '사전정보 입력', isFirst: true, isVisible: true},
        {key: '3', viewId: 'MSPPI810D', title: '질병상세정보', isFirst: true, isVisible: true},
        // {key: '4', viewId: 'MSPPI812D', title: '선심사정보 입력완료', isFirst: true, isVisible: true},
      ],
      // 현재 스텝정보
      lv_CurrntStep: {key: '1', viewId: 'MSPPI801D', title: '지급정보동의(선택)', isFirst: true, isVisible: true},
      lv_isVisblePrtrPeson: false, // 친권자 버튼 visible
      // 이전 화면에서 받아온 데이터
      lv_planId: '', // 설계번호
      lv_custId: '', // 주피보험자고객ID
      lv_chnlCustId: '', // 주피 or 종피채널고객ID
      lv_dsclsId: '', // 고지ID(선심사번호)
      
      // tmodel: {},
      // pmodel: {},
      // abdapolicy: [],
      lv_isGaMng: false, // GA설계매니저여부
      lv_GaCnsltNo: '', // GA컨설턴트번호(GA설계매니저가 선택한 컨설턴트)
      /**
       * 선심사진행상태코드
       * 
       * 10: 작성중, 20: 신청, 30: 심사필요, 40: 심사중, 50: 미결, 51: 미결(임시저장), 60: 거절, 70: 중지, 80: 재설계필요, 90: 심사완료
       */
      lv_NundwJdgPrgStatCd: '', // 선심사진행상태코드

      lv_AlertMsg: '', // 공통 알림메시지
      lv_ErrAlertMsg: '', // 조회 시 오류 메시지
      lv_zNundwAplInfoDto: {}, // 선심사신청정보DTO

      lv_SendTalkList: [], // 알림톡 보낼 고객 데이터(주피/종피/친권자)
      lv_MnisdCustInfo: {}, // 지급정보동의 고객정보 - 주피보험자
      lv_PrtrPesonInfo: [], // 지급정보동의 고객정보 - 친권자
      lv_PymBrkdnCnsnt: [], // 동의정보
      lv_isChkCnsnt: false, // 알림톡 보내기 전 동의 조회

      lv_PreDiseInfoList: [], // 이전질병정보DTO
      lv_PreDiseInfo: {}, // 선택된 이전질병정보
      lv_PreInfoCnt: 0,

      popupMSPPI804P: null, // 친권자설정 팝업
      lv_PrtrVal: '', // 친권자정보(01: 1인친권자, 03: 공동친권자, 02:2인친권자)

      lv_NundwInfoTrtDto: {}, // 선심사정보처리DTO
      lv_ResultNundwInfo: {}, // 선심사조회결과

      lv_FormId: '', // 사전정보입력 formID
      lv_BefrInfoDto: {}, // 조회한 사전정보입력 데이터

      lv_DiseDtlDto: [], // 조회한 질병정보 데이터

      lv_LstNundwInspRslt: [], // 인심사결과 List
      lv_InsmPymBrkdnInfoList: [], // 보험금지급정보 List

      lv_IsAllCnsnt: false,
      lv_isClosed: false
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
    if (this.pPage === 'MSPPI220P') {
      Gaf.initStore('MSPPI220P')
      GafMainFunc.setStore('MSPPI220P')
    } else {
      Gaf.initStore('MSPPI001M')
      GafMainFunc.setStore('MSPPI001M')
    }
    // if (this.pPage !== 'MSPPI003P') {
      // Gaf.initStore('MSPPI001M')
      // GafMainFunc.setStore('MSPPI001M')
    // }
  },
  destroyed () {
    Gaf.deletePanel(this.name)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
  },
  created() {
    let lv_vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.headerVO = this.getStore('userInfo').getters.getUserInfo
    // console.log('headerVO =====> ', this.headerVO)
    // Gaf 세팅
    Gaf.initStore(this.name)
    GafMainFunc.setStore(this.name)
    Gaf.addPanelList(this)
  },
  mounted() {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    window.vue.getStore('progress').dispatch('FULL')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    this.fn_Init()
  },
  computed: {
    // 친권자 당일발송일자 유무
    lv_IsChkCurrSendDate () {
      let chkItem = this.lv_PrtrPesonInfo.filter(item => item.dspchYmd.replace(/-/g, '') === piCommonUtil.getCurrentDate())
      return !_.isEmpty(chkItem) && chkItem.length > 0 ? true : false   
    },
    diseYn () {
      return this.lv_DiseDtlDto !== null ? this.lv_DiseDtlDto.length > 0 ? 'Y' : 'N' : 'N'
    }
  },
  watch: {
    // lv_DiseDtlDto (item) {
    //   console.log('lv_DiseDtlDto  === ', item, ' / ' , this.diseYn)
    // }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                             *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 초기화 함수
     ******************************************************************************/
    fn_Init () {
      // 정보 세팅
      if (Object.keys(this.pParams).length > 0) {
        for (let item in this.pParams) {
          // console.log(`item ====> ${item} : ${this.pParams[item]}`)
          this[`lv_${item}`] = this.pParams[item].trim()
        }
      }

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        this.lv_isGaMng = true
        this.lv_GaCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        console.log('gssEplyInfo ===> ', this.getStore('cmStore').getters.getState.gssEplyInfo)
      } else {
        this.lv_isGaMng = false
        this.lv_GaCnsltNo = ''
      }
      // 선심사신청정보
      // this.fn_SelAplNundwInfo()
      // 선심사 조회
      this.fn_ServiceData('S')
    },
    /******************************************************************************
    * Function명 : fn_setLabel
    * 설명       : 코드 값에 따라 라벨 세팅
    ******************************************************************************/
    fn_setLabel (type) {
      switch (type) {
        case '21':
          return '주피'
        case '23':
          return '종피'
        case '01':
        case '03':
        case '1':
          return '친권자1'
        case '02':
        case '2':
          return '친권자2'
        default:
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack (type) {
      // refPymInfoGudn / 지급정보안내
      // refPrtrPesonInfo / 친권자 설정 안내
      if (!_.isEmpty(type)) {
        switch (type) {
          case 'closePopup':
            console.log('closePopup ==>', this.lv_NundwJdgPrgStatCd)
            if (!_.isEmpty(this.lv_dsclsId)) {
              this.$emit('closePopup', this.lv_NundwJdgPrgStatCd)
            } else {
              this.$emit('closePopup', '')
            }
            break
          case 'close':
            this.fn_CommAlert(this.lv_CurrntStep.key)
            break
          case '1': // 지급정보동의
            this.fn_CommAlert(type)
            break
          case '2': // 사전정보 입력
            this.fn_MoveStep('P')
            break
          case '3': // 질병상세정보
            this.fn_MoveStep('P')
            break
          // case '4': // 선심사정보 입력완료
          //   this.fn_MoveStep('P')
          //   break
          default:
            break
        }
      } else {
        // 휴대폰 backKey 시 바텀시트 닫힘 방지
        // 지급정보안내 바텀시트
        if (this.$refs.refPymInfoGudn.status === 'open') {
          // this.$refs.refPymInfoGudn.close()
          return
        }
        // 친권자 설정 안내 바텀시트
        if (this.$refs.refPrtrPesonInfo.status === 'open') {
          // this.$refs.refPrtrPesonInfo.close()
          return
        }
        // 공통 알럿 메시지
        if (this.$refs.refCommAlert.status === 'open') {
          return
        }
        // 공통 에러 메시지
        if (this.$refs.refErrAlert.status === 'open') {
          return
        }
        if (this.$refs.refClmInfo.status === 'open') {
          return
        }
        // 바텀시트 열린거 없을 때 스텝에 따른 분기 처리
        if (this.lv_CurrntStep.key === '1') {
          this.fn_CommAlert(this.lv_CurrntStep.key)
        } else {
          this.fn_MoveStep('P')
        }
        // this.$emit('closePopup', this.lv_NundwJdgPrgStatCd)
      }
    },
    /******************************************************************************
     * Function명 : fn_CommAlert
     * 설명       : 안내 메시지 처리
     ******************************************************************************/
    fn_CommAlert (type, msg) {
      let alertMsg = ''
      let btnSingle = true
      let preventTouchClose = true
      let title_neg_btn = ''
      let title_pos_btn = ''
      let showCloseBtn = true
      switch (type) {
        case '1':
        case '2':
        case '3':
          if (this.lv_NundwJdgPrgStatCd === '50') { // 미결인 경우
            alertMsg = '입력사항은 저장되지 않습니다. 선심사 결과화면으로 이동하시겠습니까?'
            btnSingle = false
            title_neg_btn = '취소'
            title_pos_btn = '확인'
          } else {
            alertMsg = '선심사 신청을 중단합니다.</br>작성된 내용의 저장이 필요한 경우 임시저장 버튼을 눌러주십시오.'
            btnSingle = false
            title_neg_btn = '선심사 중단'
            title_pos_btn = '임시저장'
            // title_neg_btn = '임시저장'
            // title_pos_btn = '선심사 중단'
          }
          break
        // case '4': // 선심사 정보 입력완료 Step
        //   alertMsg = '선심사 신청이 완료되지 않았습니다.</br>선심사 신청을 종료하시겠습니까?'
        //   btnSingle = false
        //   title_neg_btn = '아니오'
        //   title_pos_btn = '예'
        //   break
        case 'isGaMng': // GA 가설대행(가설매니저)
          // 가입설계 대행 선심사인 경우!!
          // 취소(닫기) / 확인(신청 후 선심사결과 화면 오픈)
          alertMsg = '가입설계 대행 선심사는 요청 컨설턴트와 사전소통 및 동의하에 진행하시기 바랍니다. 선심사를 신청하시겠습니까?'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'complete': // 사전정보+질병상세정보 입력 완료한 상태
          alertMsg = '사전정보입력이 완료되었습니다.</br>입력사항을 저장 후 선심사 신청하시겠습니까?'
          // alertMsg = '사전정보입력이 완료되었습니다.</br>입력사항을 저장하시겠습니까?'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'isPend': // 미결보완
          alertMsg = '사전정보·질병정보 수정 후</br>반드시 \'선심사 신청\'을 진행해 주세요.</br>\'선심사 결과\'에서 미결보완제출을 해야 완료됩니다.'
          btnSingle = true
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        case 'update': // 지급정보동의 업데이트
          alertMsg = '정보를 업데이트 할 경우 저장하지 않은 작성정보는 초기화됩니다. 업데이트 하시겠습니까?'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'moveNext2':
          btnSingle = true
          alertMsg = '사전정보입력이 완료되었습니다.'
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        case 'aplComplete':
          alertMsg = '선심사 신청을 위한 정보입력이 완료되었습니다.</br>선심사를 신청하시겠습니까?'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'sendTalkComp':
          btnSingle = true
          alertMsg = '알림톡이 발송되었습니다.'
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        case 'compTalk':
          btnSingle = true
          alertMsg = '이미 동의 완료한 고객입니다.'
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        default:
          if (!_.isEmpty(msg)) {
            alertMsg = msg
            btnSingle = true
            title_pos_btn = '확인'
            showCloseBtn = false
          } else return
          break
      }
      
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'A',
          // type: 'B',
          single: btnSingle,
          content: alertMsg,
          title_neg_btn: title_neg_btn,
          title_pos_btn: title_pos_btn,
          showCloseBtn: showCloseBtn,
          title: '',
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj).then(() => {
              if (type === '1' || type === '2' || type === '3') {
                // 작성중(10) 상태인 경우 선심사 중단 후 팝업 종료
                if (this.lv_NundwJdgPrgStatCd === '50') { // 미결인 경우
                  this.$emit('resultPopup')
                } else {
                  // 임시저장
                  this.fn_SelAplNundwInfo('P11')
                  // this.fn_ServiceData('P11', '10')
                }
              } else if (type === 'isGaMng'){
                // 가입설계 대행 선심사인 경우 선심사신청 버튼 선택 함수 호출
                this.fn_PreAplNundw('1')
              } else if (type === 'complete') {
                this.lv_CurrntStep = this.lv_StepItem[this.lv_StepItem.length-1]
              } else if (type === 'P11') {
                this.fn_HistoryBack('closePopup', '10')
              } else if (type === 'isPend') {
              } else if (type === 'update') {
                // 지급정보동의 Y로 되었을때 신정원정보에서 내려주는 질병정보가 상이할 수 있어서 동의만 업데이트하지 않고 선심사정보 재조회 수행
                this.fn_ServiceData('S2')
                // this.fn_ServiceData('S')
              } else if (type === 'moveNext2') {
                this.lv_CurrntStep = this.lv_StepItem.find(item => +item.key === 3)
                this.fn_MoveStep('N')
              } else if (type === 'aplComplete') {
                this.fn_PreAplNundw('1')
              } else if (type ==='sendTalkComp' || type ==='compTalk') {
                this.$refs.refPymInfoGudn.close()
                this.fn_ServiceData('S2')
              }
            })
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj).then(() => {
              if (type === '1' || type === '2' || type === '3') {
                if (this.lv_NundwJdgPrgStatCd !== '50') { // 미결아닌 경우
                  // 선심사 중단
                  // 고지ID있는 경우 중단서비스 호출. 고지ID 없는 경우는 팝업 종료
                  if (!_.isEmpty(this.lv_dsclsId)) {
                    this.fn_SelAplNundwInfo('P3')
                    // this.fn_ServiceData('P3')
                  } else {
                    this.fn_HistoryBack('closePopup')
                  }
                }
              }
            })
          },
          onPopupCancel: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      },
      {
        properties: {preventTouchClose : preventTouchClose}
      })
    },
    /******************************************************************************
    * Function명 : fn_OepnConfirm
    * 설명       : 알럿메시지 공통 바텀시트 노출
    * @param : msg
    ******************************************************************************/
    fn_OepnConfirm(msg) {
      if (_.isEmpty(msg)) return
      this.lv_AlertMsg = msg
      this.$refs.refCommAlert.open()
    },
    /******************************************************************************
    * Function명 : fn_CloseErrAlert
    * 설명       : 오류메시지 확인 버튼 선택 시 팝업 종료
    ******************************************************************************/
    fn_CloseErrAlert () {
      this.$refs.refErrAlert.close()
      if (this.lv_isClosed) {
        this.$emit('closePopup')
      }
    },
    /******************************************************************************
    * Function명 : fn_MoveStep
    * 설명       : 이전, 다음 버튼 선택 시 스텝으로 이동
    * @param : type(P:이전, N:다음)
    ******************************************************************************/
    fn_MoveStep (type) {
      let currKey = this.lv_CurrntStep.key
      // 이전 버튼
      if (type === 'P') {
        // 사전정보입력
        // if (currKey === '2') {
        //   let currStep = this.$refs.refMSPPI802D.pageList.find(item => item.state === 'checked')
        //   console.log('사전정보입력 currStep ====> ', currStep)
        //   if (currStep.key > 1) {
        //     this.$refs.refMSPPI802D.fn_moveStep(currStep.key-1)
        //     return
        //   }
        // } else if (currKey === '3') {
        //   this.$refs.refMSPPI802D.fn_moveStep(1)
        //   // scrolltop
        //   this.$bizUtil.ctUtils.fn_PageContainerScrollTop(this.$refs.page)
        // }
        if (currKey === '3') {
          this.lv_DiseDtlDto = this.$bizUtil.cloneDeep(this.$refs.refMSPPI810D.lv_diseDtlDto)
        }
        this.lv_CurrntStep = this.lv_StepItem.find(item => +item.key === +this.lv_CurrntStep.key-1)
      } else if (type === 'N') { // 다음 버튼
        if (currKey === '1') {
          this.lv_CurrntStep = this.lv_StepItem.find(item => +item.key === (+currKey+1))
        } else {
          // 1. 페이지 이동 전 사전 체크 =====================
          if (currKey !== '4') {
            if (currKey === '2') { // 사전입력 화면 유효성 체크 후 다음 스텝
              // valChk: true/false(입력완료여부), diseYn: Y/N(질병정보필수입력여부)
              let isValInfo = this.$refs.refMSPPI802D.fn_ValidationChk()
              if (!isValInfo.valChk) {
                return
              } else {
                this.$refs.refMSPPI810D.diseYn = isValInfo.diseYn
                this.fn_CommAlert('moveNext2')
                return
              }
            }
          }
          // 2. 페이지 이동 후 데이터 세팅 및 안내 메시지 노출 =====================
          // this.lv_CurrntStep = this.lv_StepItem.find(item => +item.key === (+currKey+1))
          if (this.lv_CurrntStep.key === '3') { // 질병상세입력
            // scrolltop
            this.$bizUtil.ctUtils.fn_PageContainerScrollTop(this.$refs.page)
            // 추가
            this.$refs.refMSPPI810D.lv_nundwJdgPrgStatCd = this.lv_NundwJdgPrgStatCd
            if (this.lv_PreInfoCnt === 0 && Object.keys(this.lv_PreDiseInfo).length > 0) {
              // 이전질병정보 불러온 데이터 존재시 안내 메시지 노출
              this.$refs.refPreDiseInfo.open()
              this.lv_PreInfoCnt++
            }
          }
        }
      }
      // 스텝이동 시 스크롤탑 원하면 기능 넣어주기
      // scrolltop
      // this.$bizUtil.ctUtils.fn_PageContainerScrollTop(this.$refs.page)
    },
    /******************************************************************************
    * Function명 : fn_ServiceData
    * 설명       : 선심사신청정보 조회 및 저장 서비스 호출
    * @param     : serviceName('S':조회, 'P1': 임시저장, 'P11': 임시저장 후 팝업종료, 'P2': 저장, 'S1':이전질병불러오기, 'P3':선심사중지)
    * nundwTrtScCd 선심사처리구분코드
    * 10: 선심사조회 / 리턴: 10 or 11(지급정보동의有, 질병정보 변경 시)
    * 20: 선심사등록(저장) / 리턴: 25(등록완료)
    * 30: 이전고지정보조회 / 리턴 : 30 or 31(지급정보동의有, 질병정보 변경 시)
    * 40: New-AEUS 저장 / 리턴: 45(등록완료)
    * 50: 선심사 중지 / 리턴: 55(중지완료)
    * 60: New-AEUS 질병정보 조회
    ******************************************************************************/
    fn_ServiceData (serviceName, type) {
      let lv_vm = this
      window.vue.getStore('progress').dispatch('SHOW')
      let params = {
        nundwInfoTrtDto: {
          planId: this.lv_planId, // 설계ID
          dsclsId: this.lv_dsclsId, // 고지ID
          nundwTrtScCd: '', // 선심사처리구분코드(10:조회, 25:등록, 30:이전고지불러오기, 55:선심사중지)
          // 해당 설계건이 설계매니저가 대행한 건이면 추천설계사사번(GA설계매니저)
          recmFpEno: this.lv_isGaMng? this.headerVO.userEno : '', // 추천설계사사번(GA설계매니저)
          pymBrkdnCnsntInfoTrtDto: [] // 지급내역동의정보처리DTO
        }
      }
      let pymBrkdnCnsntInfoTrtInfo = {}
      // 선심사정보처리DTO
      // nundwInfoTrtDto
      switch (serviceName) {
        case 'S': // 조회
        case 'S2': // 동의 화면에서 업데이트 시
          params.nundwInfoTrtDto.nundwTrtScCd = '10' // 선심사처리구분코드
          pymBrkdnCnsntInfoTrtInfo.custId = this.lv_custId // 고객ID
          pymBrkdnCnsntInfoTrtInfo.chnlCustId = this.lv_chnlCustId // 채널고객ID
          // GA설계매니저(대행)인 경우 선택한 컨설턴트 정보 세팅 필요
          if (this.lv_isGaMng) {
            pymBrkdnCnsntInfoTrtInfo.clctCnsltNo = this.lv_GaCnsltNo
          } else {
            pymBrkdnCnsntInfoTrtInfo.clctCnsltNo = this.headerVO.userEno // 모집컨설턴트번호
          }
          params.nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto.push(pymBrkdnCnsntInfoTrtInfo)
          break
        case 'P1': // 임시저장
        case 'P11': // 임시저장 후 팝업종료
        case 'P2': // 저장
          params.nundwInfoTrtDto.nundwTrtScCd = '20' // 선심사처리구분코드
          let saveData = this.fn_SaveNundwInfo(serviceName.replace('P', ''))
          // recmFpEno -> 설계매니저
          if (Object.keys(saveData).length > 0) {
            // 지급내역동의정보처리DTO
            params.nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto = saveData.pymBrkdnCnsntInfoTrtDto
            // 사전정보
            params.nundwInfoTrtDto.befrInfoDto = saveData.befrInfoDto
            // 질병상세정보
            params.nundwInfoTrtDto.diseDtlDto = saveData.diseDtlDto
          }
          // console.log(`선심사정보처리 params ${serviceName} ===> `, params)
          // return
          break
        case 'S1': // 이전질병불러오기
          params.nundwInfoTrtDto.nundwTrtScCd = '30' // 선심사처리구분코드
          params.nundwInfoTrtDto.planId = lv_vm.lv_planId // 설계ID
          // params.nundwInfoTrtDto.planId = lv_vm.lv_PreDiseInfo.planId // 설계ID
          // TEST ================================
          params.nundwInfoTrtDto.dsclsId = lv_vm.lv_PreDiseInfo.dsclsId // 고지ID
          // TEST ================================
          pymBrkdnCnsntInfoTrtInfo.chnlCustId = lv_vm.lv_MnisdCustInfo.chnlCustId // 채널고객ID
          pymBrkdnCnsntInfoTrtInfo.custId = lv_vm.lv_MnisdCustInfo.custId // 고객ID
          if (this.lv_isGaMng) {
            pymBrkdnCnsntInfoTrtInfo.clctCnsltNo = lv_vm.lv_GaCnsltNo
          } else {
            pymBrkdnCnsntInfoTrtInfo.clctCnsltNo = lv_vm.lv_MnisdCustInfo.clctCnsltNo // 모집컨설턴트번호
          }
          params.nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto.push(pymBrkdnCnsntInfoTrtInfo)
          break
        case 'P3': // 선심사중지
          params.nundwInfoTrtDto.nundwTrtScCd = '50' // 선심사처리구분코드
          break
        default:
          break
      }
      console.log(`선심사정보처리 params ${serviceName} ===> `, params)
      piCommonUtil.invoke2('UF1PQL0482', params, this.fn_ResServiceData, serviceName, this.fn_ResErrorHandler, this, this)
    },
    /******************************************************************************
    * Function명 : fn_ResServiceData
    * 설명       : 선심사신청정보 조회 및 저장 서비스 호출 결과
    ******************************************************************************/
    fn_ResServiceData (resultData, serviceName) {
      let lv_vm = this
      let statCd = ''
      let nundwTrtScCd = ''
      console.log(`UF1PQL0482 ${serviceName} =====>`, resultData)
      if (resultData !== null && resultData !== '') {
        nundwTrtScCd = resultData.nundwInfoTrtDto.nundwTrtScCd // 선심사처리구분코드
        console.log('nundwTrtScCd ====> ', nundwTrtScCd)
        switch (serviceName) {
          case 'S': // 조회
          case 'S2':
            window.vue.getStore('progress').dispatch('HIDE')
            lv_vm.lv_ResultNundwInfo = resultData.nundwInfoTrtDto
            this.lv_dsclsId = resultData.nundwInfoTrtDto.dsclsId
            // 1. 청약서 발행여부 체크하여 팝업 안내메시지 노출 후 팝업 종료
            // 청약서발행여부 체크
            let ofrfmPblYn = resultData.nundwInfoTrtDto.ofrfmPblYn
            if (ofrfmPblYn === 'Y') {
              // TEST 를 위해 임시 주석 처리 ========
              this.lv_ErrAlertMsg = '청약서가 발행되어 선심사 진행이 종료되었습니다.'
              this.lv_isClosed = true
              this.$refs.refErrAlert.open()
              return
              // TEST 를 위해 임시 주석 처리 ========
            }
            console.log('선심사 번호 =====> ', this.lv_dsclsId)
            // 동의정보 관련 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('pymBrkdnCnsntInfoTrtDto')) {
              let pymBrkdnCnsntInfoTrtDto = resultData.nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto
              pymBrkdnCnsntInfoTrtDto.forEach(item => {
                if (!_.isEmpty(item.custTelno.trim())) {
                  item.custTelno = this.$bizUtil.telNoWithHyphen(item.custTelno)
                }
                if (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') {
                  this.lv_custId = item.custId
                }
              })
              lv_vm.lv_MnisdCustInfo = pymBrkdnCnsntInfoTrtDto.find(item => (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23'))
              console.log('lv_MnisdCustInfo ===> ', lv_vm.lv_MnisdCustInfo)
              // 주피 미성년자인 경우 친권자 버튼 visible 처리
              let chklAge = lv_vm.lv_MnisdCustInfo.custAge
              if ( +chklAge <= 18 ) {
                this.lv_isVisblePrtrPeson = true
                lv_vm.lv_PrtrPesonInfo = pymBrkdnCnsntInfoTrtDto.filter(item => {
                  if ((item.contvInsrdTypCd !== '21' && item.contvInsrdTypCd !== '23') && item.prtrTypCd.trim() !== '' ) {
                    if (item.cnsntYn !== 'Y' && item.dspchYmd === piCommonUtil.getCurrentDate() ) return true
                    else {
                      if (item.cnsntYn === 'Y') return true
                      else return false
                    }
                  }
                })
                console.log('lv_PrtrPesonInfo ===> ', lv_vm.lv_PrtrPesonInfo, ' / CurrentDate : ', piCommonUtil.getCurrentDate())
              } else {
                this.lv_isVisblePrtrPeson = false
              }
              // ====================================
              let chkConfirm = true
              let confirmDate = ''
              let parentYn = ''
              let ynCnt = 0
              if (this.lv_isVisblePrtrPeson) {
                if (!_.isEmpty(lv_vm.lv_PrtrPesonInfo) && lv_vm.lv_PrtrPesonInfo.length > 0) {
                  for (let i=0; i < lv_vm.lv_PrtrPesonInfo.length; i++) {
                    if (lv_vm.lv_PrtrPesonInfo[i].cnsntYn !== 'Y') {
                    // if (lv_vm.lv_PrtrPesonInfo[i].cnsntYn !== 'Y' || lv_vm.lv_PrtrPesonInfo[i].dspchYmd === piCommonUtil.getCurrentDate() ) {
                      parentYn = 'N'
                      break
                    } else {
                      if (lv_vm.lv_PrtrPesonInfo[i].cnsntYn === 'Y') {
                        ynCnt++
                      // if (lv_vm.lv_PrtrPesonInfo[i].cnsntYn === 'Y' && lv_vm.lv_PrtrPesonInfo[i].dspchYmd === piCommonUtil.getCurrentDate()) {
                        confirmDate = lv_vm.lv_PrtrPesonInfo[i].cnsntAvlEndYmd
                        parentYn = 'Y'
                      } else {
                        parentYn = 'N'
                      }
                    }
                  }
                  if (ynCnt !== lv_vm.lv_PrtrPesonInfo.length) {
                    lv_vm.lv_PrtrPesonInfo.forEach(setItem => {
                      if (!_.isEmpty(setItem.dspchYmd)) {
                        if (setItem.dspchYmd.replace(/-/g, '').substr(0,8) === piCommonUtil.getCurrentDate() && setItem.cnsntYn === 'Y') {
                          setItem.cnsntYn = 'Y'
                        } else {
                          setItem.cnsntYn = 'N'
                        }
                      }
                      parentYn = 'N'
                      console.log('parentYn : ', parentYn)
                    })
                  }
                  if (+chklAge < 14) { // 14세 미만. 부모 모두 동의 시 동의
                    if (parentYn === 'Y') {
                      lv_vm.lv_MnisdCustInfo.cnsntYn = 'Y'
                      lv_vm.lv_MnisdCustInfo.cnsntAvlEndYmd = confirmDate
                      lv_vm.lv_IsAllCnsnt = true
                    } else {
                      lv_vm.lv_IsAllCnsnt = false
                    }
                  } else {
                    // 14세 이상 19세 미만. 본인,부모 모두 동의시 동의
                    if (lv_vm.lv_MnisdCustInfo.cnsntYn === 'Y' && parentYn === 'Y') {
                      lv_vm.lv_MnisdCustInfo.cnsntYn = 'Y'
                      lv_vm.lv_MnisdCustInfo.cnsntAvlEndYmd = _.isEmpty(lv_vm.lv_MnisdCustInfo.cnsntAvlEndYmd) ? confirmDate : lv_vm.lv_MnisdCustInfo.cnsntAvlEndYmd
                      lv_vm.lv_IsAllCnsnt = true
                    } else {
                      lv_vm.lv_IsAllCnsnt = false
                    }
                  }
                }
              }
              // ====================================
              // 만 16세 이상 여부 세팅
              lv_vm.lv_MnisdCustInfo.zaStxtnDeYn = +chklAge >= 16 ? 'Y' : 'N'
              lv_vm.$refs.refMSPPI801D.fn_GetBindData('1')
            } else {
              // lv_vm.lv_MnisdCustInfo = {}
              // lv_vm.lv_PrtrPesonInfo = []
              // lv_vm.$refs.refMSPPI801D.fn_GetBindData()
            }
            // 사전정보 관련 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('befrInfoDto')) {
              let befrInfoDto = JSON.parse(resultData.nundwInfoTrtDto.befrInfoDto.dsclsInptInfoCntnt)
              lv_vm.lv_FormId = befrInfoDto.pvmnotice.formData[0].formId
              console.log(`formId : ${lv_vm.lv_FormId} / befrInfoDto ====> `, befrInfoDto)
              lv_vm.lv_BefrInfoDto = befrInfoDto
              lv_vm.$refs.refMSPPI802D.fn_GetBindData()
            } else {
              // lv_vm.lv_BefrInfoDto = {}
            }
            // 질병정보 관련 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('diseDtlDto')) {
              let diseDtlDto = []
              diseDtlDto = resultData.nundwInfoTrtDto.diseDtlDto
              lv_vm.lv_DiseDtlDto = diseDtlDto
              lv_vm.$refs.refMSPPI810D.fn_GetBindData()
              console.log('diseDtlDto ====> ', diseDtlDto)
            } else {
              // lv_vm.lv_DiseDtlDto = []
            }
            // 선심사인심사결과 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('lstNundwInspRslt')) {
              let lstNundwInspRslt = resultData.nundwInfoTrtDto.lstNundwInspRslt // 선심사인심사결과
              lv_vm.lv_LstNundwInspRslt = lstNundwInspRslt
              console.log('미결결과 ===> ', lv_vm.lv_LstNundwInspRslt)
            }
            // 진행상태에 따라 스텝이동
            statCd = resultData.nundwInfoTrtDto.nundwJdgPrgStatCd // 선심사진행상태코드
            this.lv_NundwJdgPrgStatCd = statCd
            // 작성중
            // 신정원 정보와 질병정보가 상이할 경우 PQM에서 상태코드 11로 리턴해주기로 함
            // 11번으로 온경우 안내 메시지 노출!
            if (!_.isEmpty(this.lv_dsclsId) && (statCd === '10' || statCd === '50')) {
              if (serviceName !== 'S2') {
                console.log('작성중 상태===================')
                lv_vm.lv_CurrntStep = lv_vm.lv_StepItem.find(item => item.key === '2')
              }
              if (statCd === '50') { // 미결인 경우 안내메시지 노출
                this.fn_CommAlert('isPend')
              }
            }
            if (nundwTrtScCd === '11') {
              if (serviceName !== 'S2') {
                lv_vm.lv_CurrntStep = lv_vm.lv_StepItem.find(item => item.key === '2')
              }
              this.fn_OepnConfirm('신정원 정보와 상이한 질병정보가 존재합니다. 사전정보와 해당 질병의 상세정보를 다시 입력해 주세요.')
            }
            // 지급정보 조회
            if (this.lv_MnisdCustInfo.cnsntYn === 'Y' || (this.lv_isVisblePrtrPeson && this.lv_IsAllCnsnt)) {
              lv_vm.fn_SearchInsmPymBrkdnInfo('S')
            }
            break
          case 'S1': // 이전질병불러오기
            window.vue.getStore('progress').dispatch('HIDE')
            statCd = resultData.nundwInfoTrtDto.nundwJdgPrgStatCd // 선심사진행상태코드
            // nundwTrtScCd = resultData.nundwInfoTrtDto.nundwTrtScCd // 선심사처리구분코드
            // 사전정보 관련 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('befrInfoDto')) {
              let befrInfoDto = JSON.parse(resultData.nundwInfoTrtDto.befrInfoDto.dsclsInptInfoCntnt)
              // lv_vm.lv_BefrInfoDto.isPrev = true
              lv_vm.lv_FormId = befrInfoDto.pvmnotice.formData[0].formId
              // lv_vm.lv_BefrInfoDto.prevPvmnotice = befrInfoDto
              this.$set(lv_vm.lv_BefrInfoDto, 'isPrev', true)
              this.$set(lv_vm.lv_BefrInfoDto, 'prevPvmnotice', befrInfoDto)
              console.log('prev Data befrInfoDto ====> ', lv_vm.lv_BefrInfoDto)
              // lv_vm.lv_BefrInfoDto = befrInfoDto
              lv_vm.$refs.refMSPPI802D.fn_GetBindData()
            } else {
              // lv_vm.lv_BefrInfoDto = {}
            }
            lv_vm.lv_ResultNundwInfo.befrInfoDto = resultData.nundwInfoTrtDto.befrInfoDto
            
            // 질병정보 관련 세팅
            if (resultData.nundwInfoTrtDto.hasOwnProperty('diseDtlDto')) {
              let diseDtlDto = []
              diseDtlDto = resultData.nundwInfoTrtDto.diseDtlDto
              lv_vm.lv_DiseDtlDto = diseDtlDto
              lv_vm.$refs.refMSPPI810D.fn_GetBindData()
              console.log('prev Data diseDtlDto ====> ', diseDtlDto)
            } else {
              // lv_vm.lv_DiseDtlDto = []
            }
            lv_vm.lv_ResultNundwInfo.diseDtlDto = resultData.nundwInfoTrtDto.diseDtlDto
            
            // 질병상세정보 화면인 경우 사전정보입력 화면으로 이동
            if (lv_vm.lv_CurrntStep.key === '3') {
              this.$nextTick(() => {
                lv_vm.fn_MoveStep('P')
              })
            }
            if (nundwTrtScCd === '31') {
              this.fn_OepnConfirm('신정원 정보와 상이한 질병정보가 존재합니다. 사전정보와 해당 질병의 상세정보를 다시 입력해 주세요.')
            }
            break
          case 'P1': // 임시저장
          case 'P11': // 임시저장 후 안내메시지 노출 후 팝업 종료
            window.vue.getStore('progress').dispatch('HIDE')
            // 저장완료
            if (nundwTrtScCd === '25') {
              lv_vm.lv_dsclsId = resultData.nundwInfoTrtDto.dsclsId
              lv_vm.lv_NundwJdgPrgStatCd = resultData.nundwInfoTrtDto.nundwJdgPrgStatCd // 선심사진행상태코드
              console.log('P1 result ===> ', lv_vm.lv_dsclsId)
              let alertMsg = '입력한 내용이 저장되었습니다.'
              if (serviceName === 'P1') {
                lv_vm.fn_OepnConfirm(alertMsg)
              } else {
                lv_vm.fn_CommAlert('P11', alertMsg)
              }
            }
            break
          case 'P2': // 저장
            lv_vm.lv_dsclsId = resultData.nundwInfoTrtDto.dsclsId
            console.log('P2 result ===> ', lv_vm.lv_dsclsId)
            // 선심사신청 함수 호출
            if (nundwTrtScCd === '25') {
              lv_vm.fn_AplNundw()
            }
            break
          case 'P3': // 선심사중지
            window.vue.getStore('progress').dispatch('HIDE')
            if (nundwTrtScCd === '55') {
              this.$emit('closePopup', '70')
            }
            break
          default:
            break
        }
      } else {
        window.vue.getStore('progress').dispatch('HIDE')
        if (serviceName === 'S') {
          this.fn_OepnConfirm('선심사정보 조회에 실패하였습니다.')
          // piCommonUtil.getFDPConfirm('s', '선심사정보 조회에 실패하였습니다.')
          return false
        } else {
          this.fn_OepnConfirm('선심사정보 저장에 실패하였습니다.')
          // piCommonUtil.getFDPConfirm('s', '선심사정보 저장에 실패하였습니다.')
          return false
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ResErrorHandler
    * 설명       : 선심사신청정보 조회 및 저장 서비스 호출 결과 오류 처리 함수
    ******************************************************************************/
    fn_ResErrorHandler (resultData, serviceName) {
      window.vue.getStore('progress').dispatch('HIDE')
      console.log('fn_ResErrorHandler ===> ', resultData)
      if (!_.isEmpty(resultData.msgComm.msgDesc)) {
        let msgArr = resultData.msgComm.msgDesc.split(', ')
        let alertMsg = null
        if (msgArr.length > 0) {
          alertMsg = msgArr.find(item => item.indexOf('userMsg=') > -1)
        }
        if (!_.isEmpty(alertMsg) && Object.keys(alertMsg).length > 0) {
          alertMsg = alertMsg.replace(/userMsg=|\[|\]/g, '')
        } else {
          alertMsg = resultData.msgComm.msgDesc
        }
        let title = ''
        if (alertMsg.indexOf('삭제') > -1 || alertMsg.indexOf('없어') > -1 ) {
          this.lv_isClosed = true
          if (!_.isEmpty(alertMsg)) {
            this.lv_ErrAlertMsg = title + alertMsg
            this.$refs.refErrAlert.open()
          }
        } else {
          this.lv_isClosed = false
        }
        // let title = serviceName === 'S' || serviceName === 'S1' ? '선심사정보 조회 오류 ': ''
        if (serviceName === 'S' || serviceName === 'S1') {
          if (serviceName === 'S1') {
            // 오류 발생 시 이전질병정보 데이터 초기화. 질병상세정보 화면에 안내 메시지 노출 방지
            this.lv_PreDiseInfo = {}
            this.lv_PreInfoCnt = 0
          }
          if (!_.isEmpty(alertMsg)) {
            this.lv_ErrAlertMsg = title + alertMsg
            this.$refs.refErrAlert.open()
          }
        } else {
          if (!_.isEmpty(alertMsg)) {
            this.fn_OepnConfirm(title + alertMsg)
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_UpdateCnsnt
    * 설명       : 지급정보동의 화면에서 업데이트 버튼 선택 시 함수
    ******************************************************************************/
    fn_UpdateCnsnt (item) {
      this.fn_CommAlert('update')
    },
    /******************************************************************************
    * Function명 : fn_ConfirmPrtrPeson
    * 설명       : 친권자 설정 안내 바텀시트 오픈
    ******************************************************************************/
    fn_ConfirmPrtrPeson () {
      this.$refs.refPrtrPesonInfo.open()
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI804P
     * 설명        : 친권자 설정 팝업
     ************************************************************************************************/
    fn_OpenMSPPI804P () {
      let lv_vm = this
      this.$refs.refPrtrPesonInfo.close()
      lv_vm.popupMSPPI804P = this.$bottomModal.open(MSPPI804P, {
        properties: {
          prtrPesonInfoList: lv_vm.lv_PrtrPesonInfo,
          prtrVal: lv_vm.lv_PrtrVal,
          hoshInfoList: lv_vm.lv_ResultNundwInfo.pymBrkdnCnsntInfoTrtDto
        },
        listeners: {
          closePopup: () => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI804P)
          },
          confirmInfo: (infoItem) => {
            if (!_.isEmpty(infoItem)) {
              // 친권자 정보 세팅
              lv_vm.lv_PrtrPesonInfo = infoItem.lv_PrtrPesonInfo
              lv_vm.lv_PrtrVal = infoItem.prtrVal // 친권설정정보
              lv_vm.$refs.refMSPPI801D.fn_GetBindData('1')
              lv_vm.$bottomModal.close(lv_vm.popupMSPPI804P)
            }
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })

    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI803P
     * 설명        : 이전 질병정보 불러오기 팝업 함수
     ************************************************************************************************/
    fn_OpenMSPPI803P () {
      let lv_vm = this
      // 동의정보 체크하여 미동의 상태면 안내 메시지 노출
      if (this.lv_MnisdCustInfo.cnsntYn !== 'Y' || (this.lv_isVisblePrtrPeson && !this.lv_IsAllCnsnt)) {
        this.fn_OepnConfirm('지급내역정보조회에 동의되어야 이전 질병정보 불러오기가 가능합니다.')
        return
      }
      lv_vm.popupMSPPI803P = this.$bottomModal.open(MSPPI803P, {
        properties: {
          diseInfo: {
            custId: lv_vm.lv_MnisdCustInfo.custId, // 고객ID
            formId: lv_vm.lv_FormId, // formID
            clctCnsltNo: lv_vm.lv_MnisdCustInfo.clctCnsltNo, // 모집컨설턴트번호
            planId: lv_vm.lv_planId
          }
        },
        listeners: {
          closePopup: () => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI803P)
          },
          evtConfirm: (item) => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI803P).then(() => {
              if (!_.isEmpty(item) && Object.keys(item).length > 0) {
                console.log('evtConfirm MSPPI803P ===> ', item)
                // let compPlanId = lv_vm.lv_PreDiseInfo.hasOwnProperty('planId') ? lv_vm.lv_PreDiseInfo.planId  : ''
                lv_vm.lv_PreInfoCnt = 0
                lv_vm.lv_PreDiseInfo = item
                lv_vm.fn_ServiceData('S1')
              }
            })
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI830P
     * 설명        : 심사의견조회 팝업 오픈 함수
     ************************************************************************************************/
    fn_OpenMSPPI830P () {
      let lv_vm = this
      // if (_.isEmpty(lv_vm.lv_LstNundwInspRslt)) return
      lv_vm.popupMSPPI830P = this.$bottomModal.open(MSPPI830P, {
        properties: {
          nundwInspRsltList: lv_vm.lv_LstNundwInspRslt
        },
        listeners: {
          closePopup: () => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI830P)
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_PreSendTalk
     * 설명        : 동의 알림톡 전송 시 지급정보안내 바텀시트 오픈
     ************************************************************************************************/
    fn_PreSendTalk () {
      let custAge = this.lv_MnisdCustInfo.custAge
      let sendList = custAge < 14 ? [...this.lv_PrtrPesonInfo] : [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
      // 동의여부 체크
      if ((!this.lv_isVisblePrtrPeson && this.lv_MnisdCustInfo.cnsntYn === 'Y') || (this.lv_isVisblePrtrPeson && this.lv_IsAllCnsnt)) {
        this.fn_OepnConfirm('이미 동의 완료한 고객입니다.')
        return
      }
      // 휴대전화번호 체크. 14세 미만은 휴대전화 체크 미필요로 만14세 이상 부터 체크
      let chkCelno = sendList.filter(item => item.custAge >= 14 && (_.isEmpty(item.custTelno) || item.custTelno.replace(/[^0-9]/g, '').length < 11) )
      if (chkCelno.length > 0) {
        this.fn_OepnConfirm('전송 가능한 휴대전화번호가 없습니다.\n고객카드 등록 후 이용 바랍니다.')
        return
      }
      // 미성년자 친권자 설정 여부 체크
      if (this.lv_MnisdCustInfo.custAge <= 18) {
        if (this.lv_PrtrPesonInfo.length === 0) {
          this.fn_OepnConfirm('친권자 설정 후 동의 알림톡 전송이 가능합니다.')
          return
        }
      }
      // 
      // let currDateChk = this.lv_PrtrPesonInfo.filter(item => item.dspchYmd.replace(/-/g, '') === piCommonUtil.getCurrentDate())
      // if (!_.isEmpty(currDateChk)) {
      //   this.fn_OepnConfirm('당일 발송 이력이 존재합니다.')
      //   return
      // }
      // dspchYmd
      this.lv_SendTalkList = []
      // this.lv_SendTalkList = sendList
      // this.lv_SendTalkList = sendList.filter(item => item.cnsntYn !== 'Y')
      // this.$refs.refPymInfoGudn.open()
      this.fn_SendTalk()
    },
    /************************************************************************************************
     * Function명  : fn_SendTalk
     * 설명        : 동의 알림톡 전송
     ************************************************************************************************/
    fn_SendTalk () {
      this.$refs.refPymInfoGudn.close()
      // 동의 N인건 찾아서 동의정보 조회 후 알림톡 보내기
      let custAge = this.lv_MnisdCustInfo.custAge
      let sendList = custAge < 14 ? [...this.lv_PrtrPesonInfo] : [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
      // let sendList = [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
      // let findN = sendList.find(item => item.cnsntYn !== 'Y')
      this.lv_isChkCnsnt = true
      if (+this.lv_MnisdCustInfo.custAge <= 18) {
        this.fn_GetCnsntInfo(null, 'ALL')
      } else {
        this.fn_GetCnsntInfo()
      }
      // if (Object.keys(findN).length > 0) {
      //   this.lv_isChkCnsnt = true
      //   if (+this.lv_MnisdCustInfo.custAge <= 18) {
      //     this.fn_GetCnsntInfo(null, 'ALL')
      //   } else {
      //     this.fn_GetCnsntInfo()
      //   }
      // } else {
      //   this.lv_isChkCnsnt = false
      //   this.fn_SendCnsntIapTalk()
      // }
    },
    /************************************************************************************************
     * Function명  : fn_GetCnsntInfo
     * 설명        : 보험금지급정보 동의 조회
     * @param     : fnScCd(S1:단건조회,
     *                     SA:다건조회, 
     *                     P1:단건 신규저장, 
     *                     SN:친권자포함 동의정보 조회(miorCnsntMndtYn 데이터 필수 세팅))
     ************************************************************************************************/
    fn_GetCnsntInfo (item, type) {
      // 본인, 미성년 친권자1,2
      window.vue.getStore('progress').dispatch('SHOW')
      let lv_fnScCd = 'S1' // 조회구분코드
      let paramArr = []
      // S1:단건조회 - 주피-성인, 14세미만 1인친권자
      // SA:다건조회 - 주피-미성년
      let cnsltNo = this.lv_isGaMng? this.lv_GaCnsltNo : this.headerVO.userEno
      if (!_.isEmpty(item)) { // 단건 조회
        paramArr = [{
          cnsltNo: cnsltNo,// 모집컨설턴트번호
          insrdChnlCustId: this.lv_MnisdCustInfo.chnlCustId, // 피보험자채널고객ID
          // insrdChnlCustId: item, // 피보험자채널고객ID
          ptcpChnlCustId: item// 관계인채널고객ID
        }]
      } else {
        if (+this.lv_MnisdCustInfo.custAge <= 18) {
          if (type === 'ALL') {
            lv_fnScCd = 'SN'
            paramArr.push({
              cnsltNo: cnsltNo,// 모집컨설턴트번호
              insrdChnlCustId: this.lv_MnisdCustInfo.chnlCustId, // 피보험자채널고객ID
              ptcpChnlCustId: '', // 관계인채널고객ID
              miorCnsntMndtYn: +this.lv_MnisdCustInfo.custAge < 14 ? 'N' : 'Y'
            })
          } else {
            lv_fnScCd = 'SA'
            let sendList = [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
            sendList.forEach(item => {
              if (item.cnsntYn !== 'Y') {
                paramArr.push({
                  cnsltNo: cnsltNo,// 모집컨설턴트번호
                  insrdChnlCustId: this.lv_MnisdCustInfo.chnlCustId, // 피보험자채널고객ID
                  ptcpChnlCustId: item.chnlCustId, // 관계인채널고객ID
                  miorCnsntMndtYn: +this.lv_MnisdCustInfo.custAge < 14 ? 'N' : 'Y'
                })
              }
            })
          }
        } else {
          paramArr = [{
            cnsltNo: cnsltNo,// 모집컨설턴트번호
            insrdChnlCustId: this.lv_MnisdCustInfo.chnlCustId, // 피보험자채널고객ID
            ptcpChnlCustId: this.lv_MnisdCustInfo.chnlCustId // 관계인채널고객ID
          }]
        }
      }
      let param = {
        pymBrkdnCnsntListDto: {
          fnScCd: lv_fnScCd, // S1:단건조회, SA:다건조회, P1:단건 신규저장, SN:친권자포함 동의정보 조회(miorCnsntMndtYn 데이터 필수 세팅)
          // 보험금지급내역동의정보목록
          pymBrkdnCnsntList: paramArr
          // pymBrkdnCnsntList: [{ // 보험금지급내역동의정보목록
          //   cnsltNo: this.headerVO.userEno,// 모집컨설턴트번호
          //   insrdChnlCustId: this.lv_CnsntInfo.chnlCustId // 피보험자채널고객ID
          // }]
        },
      }
      console.log('동의 업데이트 param ====> ', param)
      piCommonUtil.invoke('UF1PQL0471', param, this.fn_ResponseCnsntInfoData, lv_fnScCd, this.fn_ResponseCnsntInfoData, this, this)
    },
    /************************************************************************************************
     * Function명  : fn_ResponseCnsntInfoData
     * 설명        : 보험금지급정보 동의 조회 후 처리
     ************************************************************************************************/
    fn_ResponseCnsntInfoData (returnData, fnScCd) {
      console.log('보험금지급정보 동의 responseData', fnScCd + '===> ', returnData)
      let lv_vm = this
      let cnsntYn = ''
      // 친권자 동의 확인
      let custAge = +this.lv_MnisdCustInfo.custAge
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        // 서비스 결과 비정상인 경우
        if (returnData.pymBrkdnCnsntListDto.returnCode !== '00') {
          this.fn_OepnConfirm(returnData.pymBrkdnCnsntListDto.returnMsg)
          window.vue.getStore('progress').dispatch('HIDE')
          return
        }
        if (returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList !== null && returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList !== '') {
          let pymBrkdnCnsntList = returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList
          lv_vm.lv_PymBrkdnCnsnt = pymBrkdnCnsntList
          pymBrkdnCnsntList.forEach((item) => {
            if (item.ptcpChnlCustId === lv_vm.lv_MnisdCustInfo.chnlCustId) {
              lv_vm.lv_MnisdCustInfo.cnsntYn = item.cnsntYn
              lv_vm.lv_MnisdCustInfo.cnsntAvlStrYmd = item.cnsntAvlStrYmd // 동의유효시작일자
              lv_vm.lv_MnisdCustInfo.cnsntAvlEndYmd = item.cnsntAvlEndYmd !== '' ? moment(item.cnsntAvlEndYmd).format('YYYY-MM-DD') : '' // 동의유효종료일자
              lv_vm.lv_MnisdCustInfo.dspchDtm = item.dspchDtm.trim().length > 8 ? item.dspchDtm.substr(0,8) : '' // 발송일시
              lv_vm.lv_MnisdCustInfo.cnsntDtm = item.cnsntDtm // 수신일시
              lv_vm.lv_MnisdCustInfo.prtrTypCd = item.prtrTypCd
            }
            if (lv_vm.lv_PrtrPesonInfo.length > 0) {
              lv_vm.lv_PrtrPesonInfo.forEach((item1,index) => {
                if (item1.chnlCustId === item.ptcpChnlCustId) {
                  item1.cnsntYn = item.cnsntYn
                  item1.cnsntAvlStrYmd = item.cnsntAvlStrYmd // 동의유효시작일자
                  item1.cnsntAvlEndYmd = item.cnsntAvlEndYmd !== '' ? moment(item.cnsntAvlEndYmd).format('YYYY-MM-DD') : '' // 동의유효종료일자
                  item1.dspchDtm = item.dspchDtm.trim().length > 8 ? item.dspchDtm.substr(0,8) : '' // 발송일시
                  item1.cnsntDtm = item.cnsntDtm // 수신일시
                  item1.prtrTypCd = item.prtrTypCd
                  item1.index = String(index+1)
                }
              })
            }
          })
        } else {
          lv_vm.lv_PymBrkdnCnsnt = []
          if (custAge <= 18) {
            lv_vm.lv_PrtrPesonInfo.forEach((setItem, index) => {
              setItem.index = String(index+1)
            })
          }
        }
        // 알림톡 발송 전 체크
        if (lv_vm.lv_isChkCnsnt) {
          lv_vm.lv_isChkCnsnt = false
          // ======================
          
          let lv_cnsntYn = true
          if (custAge <= 18) {
            let chkItem = null
            if (!_.isEmpty(lv_vm.lv_PrtrPesonInfo) && lv_vm.lv_PrtrPesonInfo.length > 0 ) {
              chkItem = lv_vm.lv_PrtrPesonInfo.filter(findItem => findItem.cnsntYn === 'Y')
            }
            let chldCnsntYn = false
            if (custAge >= 14 && lv_vm.lv_MnisdCustInfo.cnsntYn === 'Y') {
              chldCnsntYn = true
            }
            if (!_.isEmpty(chkItem)) {
              if (!chldCnsntYn || (chkItem.length !== 0 && chkItem.length !== lv_vm.lv_PrtrPesonInfo.length)) {
              // if (chkItem.length !== lv_vm.lv_PrtrPesonInfo.length) {
                lv_cnsntYn = false
                // 14세 이상인 경우 부모가 당일 동의 되지 않았을때는 주피 동의여부도 N으로 세팅
                if (chldCnsntYn && lv_vm.lv_MnisdCustInfo.dspchDtm !== piCommonUtil.getCurrentDate()) {
                // if (custAge >= 14 && lv_vm.lv_MnisdCustInfo.cnsntYn === 'Y' && lv_vm.lv_MnisdCustInfo.dspchDtm !== piCommonUtil.getCurrentDate()) {
                  lv_vm.lv_MnisdCustInfo.cnsntYn = 'N'
                }
                lv_vm.lv_PrtrPesonInfo.forEach(_item => {
                  if (_item.cnsntYn === 'Y' && _item.dspchDtm === piCommonUtil.getCurrentDate()) {
                    _item.cnsntYn = 'Y'
                  } else {
                    _item.cnsntYn = 'N'
                  }
                })
                // 14세 미만. 부모모두동의O
                if (custAge < 14) {
                  let list1 = lv_vm.lv_PrtrPesonInfo.filter(findItem => findItem.cnsntYn === 'Y')
                  if (list1.length === lv_vm.lv_PrtrPesonInfo.length) {
                    lv_cnsntYn = true
                    lv_vm.lv_MnisdCustInfo.cnsntYn = 'Y'
                  }
                }
              }
            } else lv_cnsntYn = false
          } else {
            if (lv_vm.lv_MnisdCustInfo.cnsntYn === 'Y') {
              lv_cnsntYn = true
            } else {
              lv_cnsntYn = false
            }
          }
          // ======================
          if (lv_vm.lv_MnisdCustInfo.cnsntYn === 'Y' && lv_cnsntYn) {
            this.fn_CommAlert('compTalk')
            // lv_vm.fn_ServiceData('S2')
            // 이미 동의 완료한 고객입니다.
          } else {
            let sendList = custAge < 14 ? [...this.lv_PrtrPesonInfo] : [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
            this.lv_SendTalkList = sendList.filter(item => item.cnsntYn !== 'Y')
            this.$refs.refPymInfoGudn.open()
            // lv_vm.fn_SendCnsntIapTalk()
          }
          window.vue.getStore('progress').dispatch('HIDE')
        } else {
          window.vue.getStore('progress').dispatch('HIDE')
          // 동의알림톡 전송상태에 따라 메시지 및 상태값 변경
          // if (lv_vm.lv_CnsntIapTalkState) {
          //   if (cnsntYn !== 'Y' || _.isEmpty(lv_vm.lv_CnsntInfo.cnsntAvlEndYmd)) {
          //     this.fn_OepnConfirm('조회된 동의여부 정보가 없습니다.')
          //   } else {
          //     // 동의정보가 내려온 경우 동의알림톡 전송상태 미전송(false)로 변경
          //     // lv_vm.lv_CnsntIapTalkState = false
          //     this.fn_OepnConfirm('동의여부를 업데이트하였습니다.')
          //   }
          // } else {
          //   this.fn_OepnConfirm('동의여부를 업데이트하였습니다.')
          // }
        }
      } else {
        this.fn_OepnConfirm('조회시 오류가 발생하였습니다.')
        window.vue.getStore('progress').dispatch('HIDE')
      }
    },
    /************************************************************************************************
     * Function명  : fn_SendCnsntIapTalk
     * 설명        : 동의알림톡 전송 함수
     ************************************************************************************************/
    fn_SendCnsntIapTalk () {
      let lv_vm = this
      let paramArr = []
      let custAge = +this.lv_MnisdCustInfo.custAge
      let sendList = custAge < 14 ? [...this.lv_PrtrPesonInfo] : [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
      sendList.forEach(item => {
        if (item.cnsntYn !== 'Y') {
        // if (item.cnsntYn !== 'Y' || item.dspchYmd.replace(/-/g, '').substr(0,8) !== piCommonUtil.getCurrentDate()) {
          if (lv_vm.lv_isVisblePrtrPeson) {
            // 주피보험자-미성년자
            // miorCnsntMndtYn	미성년자동의필수여부
            // prtrTypCd	친권유형코드(00:비대상, 01:1인친권, 03:공동친권, 02:2인친권)
            paramArr.push({
              chnlCustId: this.lv_MnisdCustInfo.chnlCustId, // 채널고객ID
              rlatePrsonCustId: item.chnlCustId, // 관계인채널고객ID
              custRltnCd: '01', // 고객관계코드
              custCnsntTypCd: '10', // 동의유형코드(10:지급내역조회동의-고정값)
              miorCnsntMndtYn: custAge < 14 ? 'N': 'Y', // 미성년자동의필수여부(Y 인경우: 14세이상, N인경우 14세미만)
              prtrTypCd: !_.isEmpty(lv_vm.lv_PrtrVal) ? lv_vm.lv_PrtrVal : item.prtrTypCd, // 친권유형코드(00:비대상, 01:1인친권, 03:공동친권, 02:2인친권)
              // prtrTypCd: lv_vm.lv_PrtrVal !== '03' ? item.prtrTypCd : lv_vm.lv_PrtrVal, // 친권유형코드(00:비대상, 01:1인친권, 03:공동친권, 02:2인친권)
            })
          } else {
            // 주피보험자-성인
            paramArr.push({
              chnlCustId: item.chnlCustId, // 채널고객ID
              rlatePrsonCustId: item.chnlCustId, // 관계인채널고객ID
              custRltnCd: '01', // 고객관계코드
              custCnsntTypCd: '10', // 동의유형코드(10:지급내역조회동의-고정값)
            })
          }
        }
      })
      console.log('알림톡 발송 대상 리스트 ====>', paramArr)
      if (paramArr.length < 1) return
      let param = {
        cnsltNo: this.lv_isGaMng ? this.lv_GaCnsltNo : this.headerVO.userEno, // 컨설턴트번호
        inptPathSc:'03', // 입력경로구분(01:ep, 02:GA포탈, 03:사랑온)
        pIPymInfoProfrCnsntDtlDto: paramArr
        // pIPymInfoProfrCnsntDtlDto: [{
        //   chnlCustId: lv_vm.lv_CnsntInfo.chnlCustId, // 채널고객ID
        //   custRltnCd: '01' // 고객관계코드
        // }]
      }
      console.log('알림톡 보내기!!! ====', param)
      // TEST ========================
      // return
      // TEST ========================
      this.post(lv_vm, param, 'txTSSPI03I1', 'S').then(function (response) {
        if (response.body !== null) {
          if (response.body.wkRslt === '0') { // 정상처리
            lv_vm.fn_CommAlert('sendTalkComp')
          } else {
            if (String(response.body.wkMsg).trim().length > 0) {
              lv_vm.fn_OepnConfirm(response.body.wkMsg)
            } else {
              lv_vm.fn_OepnConfirm('알림톡 발송에 오류가 발생하였습니다.')
              return    
            }
          }
        } else {
          lv_vm.fn_OepnConfirm('알림톡 발송에 오류가 발생하였습니다.')
        }
      })
      .catch(function (error) {
        window.vue.error(error)
        window.vue.getStore('progress').dispatch('HIDE')
      })
    },
    
    /************************************************************************************************
     * Function명  : fn_SaveNundwInfo
     * 설명        : 선심사정보 저장을 위한 데이터 세팅
     * @param      : type(1:임시저장, 2:저장)
     *               임시저장-유효성체크 X, 저장-유효성체크 O
     ************************************************************************************************/
    fn_SaveNundwInfo (type) {
      let nundwInfoTrtDto = {}
      nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto = [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo] // 지급내역동의정보처리DTO
      // 사전정보
      /*
      pvmnotice: {
        formData: [{
            ReportDisplayName: '',
            Input: {},
            formId: '',
            MNISD_LTC: ''
          }
        ]
      }
      */
      let questData = this.$refs.refMSPPI802D.fn_SetOutputData()
      questData.formtype = 'notice'
      let dsclsInptInfoCntnt = _.cloneDeep(this.lv_BefrInfoDto)
      dsclsInptInfoCntnt.pvmnotice.formData[0].Input = questData
      nundwInfoTrtDto.befrInfoDto = {
        dsclsInptInfoCntnt: JSON.stringify(dsclsInptInfoCntnt) // 고지입력정보내용
      }
      // 질병상세정보
      let diseDtlInfo = this.$refs.refMSPPI810D.lv_diseDtlDto
      nundwInfoTrtDto.diseDtlDto = diseDtlInfo
      return nundwInfoTrtDto
    },
    /************************************************************************************************
     * Function명  : fn_PreAplNundw
     * 설명        : 선심사신청 버튼 선택 시 함수
     ************************************************************************************************/
    fn_PreAplNundw (type) {
      // GA 설계매니저인 경우 안내 메시지 노출 후 진행
      if (this.lv_isGaMng && _.isEmpty(type)) {
        this.fn_CommAlert('isGaMng')
      } else {
        // 1. 필수 값 체크
        // let valChk = true // 사전정보 유효성 체크
        let valChk = this.$refs.refMSPPI802D.fn_ValidationChk() // 사전정보 유효성 체크
        let diseChk = this.$refs.refMSPPI810D.fn_ValChkMSPPI810D() // 질병상세정보 유효성 체크
        if (valChk && diseChk) {
          // 저장 서비스 호출(저장 서비스 완료 후 결과 처리 영역에서 선심사 신청 서비스 호출)
          this.fn_ServiceData('P2')
        } else return
        // 2. 입력데이터 저장
        // 3. 선심사 신청
        // 저장 데이터 구성 함수
        // 저장 서비스 호출(저장 서비스 완료 후 결과 처리 영역에서 선심사 신청 서비스 호출)
      }
    },
    /************************************************************************************************
     * Function명  : fn_AplNundw
     * 설명        : 선심사신청 함수
     ************************************************************************************************/
    fn_AplNundw () {
      window.vue.getStore('progress').dispatch('SHOW')
      let params = {
        znundwAplDto: {
        // zNundwAplDto: {
          trtScCd: '10', // 처리구분코드(10:선심사신청, 20:New-AEUS신청, 30:간편 New-AEUS신청)
          // dsclsId: this.lv_dsclsId, // 고지ID
          planId: this.lv_planId, // 설계ID
          pymBrkdnCnsntYn: this.lv_MnisdCustInfo.cnsntYn // 동의여부
        }
      }
      piCommonUtil.invoke2('UF1PQL0484', params, this.fn_ResAplNundw, null, this.fn_ResErrorHandler, this, this)
      // piCommonUtil.invoke2('UF1PQL0484', params, this.fn_ResAplNundw, null, this.fn_ResAplNundw, this, this)
    },
    /******************************************************************************
    * Function명 : fn_ResAplNundw
    * 설명       : 선심사신청 서비스 호출 결과
    ******************************************************************************/
    fn_ResAplNundw (resultData) {
      console.log('선심사 신청 결과 ======> ', resultData)
      if (resultData !== null) {
        // 신청 성공
        if (!_.isEmpty(resultData.znundwAplRsltDto)) {
          let resultItem = resultData.znundwAplRsltDto
          if (!_.isEmpty(resultItem.dsclsId) && !_.isEmpty(resultItem.planId)) {
            // 선심사 신청 완료되면 선심사신청 팝업 종료 후 결과 화면으로 이동!!!!
            this.$emit('resultPopup', {nundwJdgPrgStatCd: '20', nundwAplInfo: {dsclsId: resultItem.lv_dsclsId, planId: resultItem.lv_planId}})
          }
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_SelAplNundwInfo
     * 설명        : 선심사신청정보 서비스 호출
     ************************************************************************************************/
    fn_SelAplNundwInfo (type) {
      window.vue.getStore('progress').dispatch('SHOW')
      let params = {
        zNundwAplInfoReqDto: {
          dsclsId: '', //this.lv_dsclsId, // 고지ID
          planId: this.lv_planId, // 설계ID
        }
      }
      piCommonUtil.invoke2('UF1PQL0483', params, this.fn_ResAplNundwInfo, type, this.fn_ResErrorHandler, this, this)
    },
    /************************************************************************************************
     * Function명  : fn_ResAplNundwInfo
     * 설명        : 선심사신청정보 서비스 호출 결과 처리
     ************************************************************************************************/
    fn_ResAplNundwInfo (returnData, type) {
      console.log('선심사신청정보 서비스 호출 결과 ====> ', returnData)
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        this.lv_zNundwAplInfoDto = returnData.znundwAplInfoDto
        // 청약서발행여부 체크
        let ofrfmPblYn = this.lv_zNundwAplInfoDto.ofrfmPblYn
        console.log('선심사 청약서발행 여부 ====> ', ofrfmPblYn)
        if (ofrfmPblYn === 'Y') {
          this.lv_ErrAlertMsg = '청약서가 발행되어 선심사 진행이 종료되었습니다.'
          this.lv_isClosed = true
          this.$refs.refErrAlert.open()
          window.vue.getStore('progress').dispatch('HIDE')
        } else {
          // 선심사
          if (!_.isEmpty(type)) {
            // 임시저장 시
            if (type === 'P11') {
              this.fn_ServiceData('P11', '10')
            } else {
              this.fn_ServiceData(type)
            }
          } else {
            // GA 설계매니저인 경우 안내 메시지 노출 후 진행
            if (this.lv_isGaMng) {
              this.fn_CommAlert('isGaMng')
            } else {
              // this.fn_PreAplNundw('1')
              this.fn_CommAlert('aplComplete')
            }
            window.vue.getStore('progress').dispatch('HIDE')
          }
        }
      }
    },
    /*********************************************************
     * Function명: fn_SearchInsmPymBrkdnInfo
     * 설명: 보험금지급내역정보 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SearchInsmPymBrkdnInfo (type) {
      window.vue.getStore('progress').dispatch('SHOW')
      let param = {
        pymBrkdnCnsntInfoDto: {
          planId: this.lv_planId, // 설계ID
          custId: this.lv_custId, // 고객ID
          clctCnsltNo: this.lv_isGaMng? this.lv_GaCnsltNo : this.headerVO.userEno, // 모집컨설턴트번호
        }
      }
      piCommonUtil.invoke2('UF1PQL0473', param, this.fn_ResponseInsmPymBrkdnInfoData, type, null, this, this)
    },
    /*********************************************************
     * Function명: fn_ResponseInsmPymBrkdnInfoData
     * 설명: 보험금지급내역정보 조회 후 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ResponseInsmPymBrkdnInfoData (returnData, serviceName) {
      let lv_vm = this
      if (returnData !== null && returnData !== '') {
        let insmPymBrkdnInfo = returnData.pymBrkdnCnsntInfoDto.insmPymBrkdnInfoDto // 보험금지급내역정보DTO
        if (!piCommonUtil.isEmpty2(insmPymBrkdnInfo)) {
          /*
          krReprDiseClsfCd	KR대표질병분류코드
          krReprDiseClsfCdNm	KR대표질병분류코드명
          orgnAcdtOccuDtm	원사고발생일시
          hspzYn	입원여부
          mdhspYn	통원여부
          surgYn	수술여부
          pymAmt	지급금액
          */
          lv_vm.lv_InsmPymBrkdnInfoList = insmPymBrkdnInfo.length > 0 ? insmPymBrkdnInfo : []
          if (lv_vm.lv_InsmPymBrkdnInfoList.length > 0) {
            lv_vm.lv_InsmPymBrkdnInfoList.forEach(chgItem => {
              if (chgItem.hasOwnProperty('orgnAcdtOccuDtm') && !piCommonUtil.isEmpty2(chgItem.orgnAcdtOccuDtm)) {
                chgItem.orgnAcdtOccuDate = moment(chgItem.orgnAcdtOccuDtm).format('YYYY-MM-DD')
              } else {
                chgItem.orgnAcdtOccuDate = ''
              }
            })
          }
        } else {
          lv_vm.lv_InsmPymBrkdnInfoList = []
        }
        if (this.$refs.refMSPPI801D) {
          this.$refs.refMSPPI801D.lv_TotCnt = lv_vm.lv_InsmPymBrkdnInfoList.length
        }
        if (_.isEmpty(serviceName)) {
          this.$refs.refClmInfo.open()
        }
      } else {
        piCommonUtil.getFDPConfirm('s', '조회시 오류가 발생하였습니다.')
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
  }
}
</script>