/*
 * 업무구분: 공통
 * 화면 명: MSPBC500M
 * 화면 설명: 홈(지점장)
 */
<template>
  <ur-page-container title="home" class="msp msp-bc-wrap--bgGray none--tit home_new" :show-title="false" type="page" action-type="none" ref="home">
    <div class="msp_home_top_wrap ty--sticky">
      <div class="top_wrap_info">
        <div class="top_wrap--left" @click="fn_DigitProfile('name')">
          <img v-if="lv_profileImageUrl" :src="lv_profileImageUrl" class="top_wrap--photo" />
          <span class="name">{{fn_GetUserName}}</span><span class="etc">님</span>
        </div>
        <div class="top_wrap--right">
          <div class="time_tooltip" :class="lv_tooltipOpend ? 'on'  : 'off'">
            <mo-icon icon="msp-timer" class="tip-open crTy-white" @click="openToolTip">msp-timer</mo-icon>
            <div class="time_tooltip_inner" ref="tooltipBox">
              <label :class="lv_goodMorning" @click="fn_GetWelcomeType(lv_goodMorning,true)">굿모닝<mo-icon v-if="lv_goodMorning === 'refresh element'" icon="msp-refresh-n" ref="rotateIcon"/></label><!-- class: on/off/refresh -->
              <label :class="lv_success" v-show="lv_welcomeType === 2" @click="fn_GetWelcomeType(lv_success,true)">석세스<mo-icon v-if="lv_success === 'refresh element'" icon="msp-refresh-n" ref="rotateIcon"/></label>
              <mo-icon icon="msp-close-n" class="tip-close" @click="closeToolTip">msp-close</mo-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="msp_home_top_wrap">      
      <div class="full home_sch_area">
        <mo-text-field placeholder="컨설턴트 또는 메뉴 검색" @click="fn_MoveCustSearch"/>
        <mo-icon icon="msp-home-mike" @click="fn_MoveCustSearchVoice">msp-home-mike</mo-icon>
      </div>

      <!-- 슬라이딩 배너 영역 -->
      <ur-box-container v-if="lv_homeBannList && lv_homeBannList.length" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-swiper-main mt30">
        <mo-carousel :number-of-page="lv_homeBannList.length" height="auto" :prevButton="false" :nextButton="false" noRotate :swiper-option="swiperOption">
          <template v-for="(item, idx) in lv_homeBannList" #[idx+1]>
            <div :key="idx" class="swiper-item" :class="fn_GetBannClass(item)" @click="fn_OnClickBann(item)">
              <div class="txt">
                <div class="fexTy2 txt-tit">
                  <span class="result" v-html="item.bannLTitl"/>
                </div>
                <span v-html="item.bannSTitl"/>
              </div>
              <div class="icon-hand" v-html="fn_GetBannImg(item)"/>
            </div>
          </template>
        </mo-carousel>
      </ur-box-container>      
      <!-- 슬라이딩 배너 영역 //-->
    </div>

      <div class="mt60 fexTy2 overflow-scroll-x min120">
        <div v-if="lv_bioInsrdNm" class="my_work blue2 fexTy3 ml20 mt20" @click="fn_DelHomeBioInfo">
          <div>
            <span class="fs18 fwm">전자서명 지문촬영</span>
          </div>
          <div class="fexTy4 mt4">
            <span class="fs18 crTy-blue2 round">{{lv_bioInsrdNm}}</span>
          </div>
        </div>
      <!-- <div v-if="lv_infoPrcusCnsntPnstaCnt" class="my_work_group mt20 pb10 pl10">
        <div class="my_work ty--h60 ml20" @click="$router.push({name: 'MSPCM170M'})">
          <div class="fexTy3 mt4 full">
            <span class="fs18 fwm">정보활용동의 승인</span>
            <span class="fs18 fwb crTy-blue2">{{lv_infoPrcusCnsntPnstaCnt}}</span>
          </div>
        </div>
      </div> -->
      <div v-if="lv_bndlCnsntCnt" class="my_work_group mt20 pb10 pl10">
        <div class="my_work ty--h60 ml10" @click="$router.push({name: 'MSPAP508M'})">
          <div class="fexTy3 mt4 full">
            <span class="fs18 fwm">단체동의 승인</span>
            <span class="fs18 fwb crTy-blue2">{{lv_bndlCnsntCnt}}</span>
          </div>
        </div>
      </div>
      <div v-if="lv_ofrfmPblCnt" class="my_work_group mt20 pb10 pl10">
        <div class="my_work ty--h60 ml10 mr20" @click="$router.push({name: 'MSPPS220M'})">
          <div class="fexTy3 mt4 full">
            <span class="fs18 fwm">청약서 발행</span>
            <span class="fs18 fwb crTy-blue2">{{lv_ofrfmPblCnt}}</span>
          </div>
        </div>
      </div>
    </div>

    <ur-box-container class="">
      <mo-list :list-data="lv_homMenuList" class="home_menu_group ty--2">
        <template #list-item="{item}">
          <mo-list-item class="home_menu" @on-item-click="fn_OnMoveSrn(item)">
            <span v-if="item.menuNm === '고객알람(고객접촉정보)'" style="flex-direction: column;">
              고객알람
              <span style="font-size: 13px;">(고객접촉정보)</span>
            </span>
            <span v-else>
              {{item.menuNm}}
            </span>
            <mo-badge class="new sm" text="새로운" v-if="item.isNew">새로운</mo-badge><!-- 2024.04.23 뱃지 추가 -->
            <img v-if="item.imgSrc" :src="item.imgSrc" :alt="item.menuNm">
          </mo-list-item>
        </template>
      </mo-list>
    </ur-box-container>

    <div class="fexTy1 mt20">
        <mo-button class="btn_homemenu--set" @click="fn_OpenMSPBC003P">홈 메뉴 설정</mo-button>
    </div>

    <div class="bottom_open_float ty--2 mt30">

        <div class="calculate-expand mt30">
          <div class="arrow-wrap-mgr" @click="$router.push({name: 'MSPBC003M'})">
            <span class="tit-pre-icon mr10"><img src="@/assets/img/icon/home_icon/home_menu49.png" alt=""></span>
            <span class="info--tit fs18rem fwb">오늘의 활동(컨설턴트)</span>
            <mo-icon icon="msp-arrow-pre" class="deg--180 f-end">msp-arrow-pre</mo-icon>
          </div>
       </div>
        
        <div class="home_info_area mt30">
          <div class="info--tit flex" @click="$router.push({name: 'MSPSA201M'})">
            <span class="fs18rem fwb">최신 안내자료</span>
            <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
          </div>

          <mo-list :list-data="lv_RntCntntItems" class="info_list mt20">
            <template #list-item="{item}">
              <mo-list-item class="info_view">
                <div class="" @click="fn_OnSelectedSingleItem(item)">
                  <div>
                    <img :src="item.blobImgUrl">
                    <mo-badge class="lightblue sm mt10" shape="status">{{item.untySlmtClsfNm01}}</mo-badge>
                    <span class="mt4 fs16rem txtSkip3">{{item.untySlmtNm}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </div>
    </div>

    
    <div class="mt80"></div>

    <!-- 열린화면 S -->
    <div class="right-floating-menu">
      <mo-button class="btn-preview" @click="fn_OpenScreen"><mo-icon icon="msp-preview" class="fs28">msp-preview</mo-icon></mo-button>
      <div class="chat-bubble chat-bubble-top blue" v-if="!lv_ReadOpenScreenTooltip">
        <span ref="aniTxt" class="ani-txt-show">열린 화면을 볼 수 있어요</span>
        <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseOpenScreenTooltip">msp-close-bold</mo-icon>
      </div>
    </div>
    <!-- 열린화면 E -->

    <div class="ns-main-chat" :class="lv_IsShowChatbotMsg ? '' : 'off'" @click.stop="fn_chatBotContent">
        <div class="chat-txt">
          <span>챗봇</span>
        </div>
    </div>

    <mo-bottom-sheet ref="customerStat" :close-btn="false"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        고객현황
        <div class="ns-btn-close" @click="$refs.customerStat.close()" name="닫기"></div>
      </template>
      <div class="fex-rw content-area mhAuto txt-center customer-stats-wrap">
        <span>
          <h6 class="crTy-bk7 fs16rem">고객 수</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cmTot }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">본인</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm03 }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">관심</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm05 }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">가망(백지)</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm00 }}</b>명</p>
        </span>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="$refs.customerStat.close()" class="ns-btn-round white">확인</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="$router.push({name: 'MSPCM115M'})" class="ns-btn-round blue">전체고객</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <MSPBC200P v-if="isReadyHome"/>

    <div v-if="lv_IsLoading" class="preloader-bubbling">
      <span id="bubbling_1"></span>
      <span id="bubbling_2"></span>
      <span id="bubbling_3"></span>
    </div>

    <!--2024.04.01  조회귀점 토스트팝업 start-->
    <div class="home-snackbar__container" v-show="lv_snackbarOpen"> <!-- //2024.04.01 조회귀점 토스트팝업 추가 (변수명은 편하신대로 바꿔사용해주세요) -->
      <div class="home-snackbar" ref="homeSnackbar"> <!-- //on -->
        <div class="home-snackbar__content">
          <span>{{lv_snacbarText}}</span>
        </div>
      </div>
    </div>
      <!--2024.04.01 조회귀점 토스트팝업 end-->
    <!-- <ur-box-container v-if="lv_IsLoading" direction="row" alignV="start" class="home_loading">
      <div class="home_loading-img">
        <img src="@/assets/img/icon/loading_heri.gif" alt="로딩 이미지">
      </div>
    </ur-box-container> -->

    <mo-bottom-sheet ref="print_bottomSheet"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
      인쇄
        <div class="ns-btn-close" @click="fn_print_BottomSheet_close()" name="닫기"></div>
      </template>

      <ur-box-container direction="column" alignV="start">        
        <ur-box-container componentid="namecard">          
          <ur-box-container componentid="" direction="row" class="fexTy5 pl24 mb30">
            <span class="mr35">매수</span>
                <mo-stepper v-model="lv_SpinValue" :value=1  :min="1" :max="20"/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

        <template v-slot:action>
        <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_BottomSheet_close()">취소</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzConfirmBtnClicked()">인쇄</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MenuConst from '@/ui/sp/MenuConstans.js'
import Msg from '@/systems/webkit/msg/msg'
import MSPBC002P from '@/ui/bc/MSPBC002P.vue'
import MSPBC003P from '@/ui/bc/MSPBC003P.vue'
import MSPBC200P from '@/ui/bc/MSPBC200P.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import bcConstants from '@/config/constants/bcConstants.js'
import SpConstants from '@/config/constants/spConstants'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'
import MSPSA203P from '@/ui/sa/MSPSA203P.vue'
import MSPSA210P from '@/ui/sa/MSPSA210P.vue'
import MSPSA222P from '@/ui/sa/MSPSA222P.vue'
import MSPSA063P from '@/ui/sa/MSPSA063P.vue'
import MSPCM128P from '@/ui/cm/MSPCM128P.vue'
import MSPCM152P from '@/ui/cm/MSPCM152P.vue'
import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import Promise from '~/src/systems/acl/promise'
import OpenScreenBottomSheet from '@/ui/bc/OpenScreenBottomSheet.vue' // 열린화면 바텀시트
import MSPBC661P from '@/ui/bc/MSPBC661P.vue'
import MSPBC662P from '@/ui/bc/MSPBC662P.vue'


export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC500M',
  screenId: 'MSPBC500M',
  components: {
    MSPBC200P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.host = (['stage','stage2'].includes(process.env.NODE_ENV) && location.host.includes('imss')) ? 'https://itss.samsunglife.com:8410' : ''

    // 귀점 체크
    this.fn_GetWelcomeType(this.lv_initTypeValue,this.lv_clicked)

    // 유틸용 프로필 이미지 로딩
    this.$bizUtil.selDigitProfImg()
    this.fn_OpenFidoRegGuidePopup()
  },
  beforeMount () {
    let pushFlag
    pushFlag = this.getStore('spStore').getters.getState.pushFlag
    if (pushFlag === false) {
      this.$NoticeManager.eventBus.$on(MenuConst.PUSH_ALARM_EVENT, this.fn_PushEventHandler)
      this.getStore('spStore').dispatch('PUSH_SET')
    }
  },
  mounted() {
    let tmp = this.$route.params
    if (tmp) {
      if (tmp.intentType === 'push') {
        window.vue.getInstance('vue').$NoticeManager.fn_OnNewPushMsgHandler(MenuConst.PUSH_RECEIVE_TYPE_INTENT, tmp.trx)
      }
    }

    // 열린화면 툴팁
    this.lv_ReadOpenScreenTooltip = !!localStorage.getItem('READ_OPENSCREEN_TOOLTIP_MAIN_BMNGR')
      
    // 서비스 대상 구분
    this.fn_ConfigProcss()
  },
  activated() {
    //조회귀점펼치기
    this.lv_tooltipOpend = true
    this.fn_PocDeviceEnv()
    // scroll 위치 복원
    this.fn_ScrollBefore()

    this.getStore('progress').dispatch('NONE')
    this.getStore('notice').dispatch('SET_READY_AGREE', false)
    this.getStore('notice').dispatch('SET_READY_HOME', false)

    if (!this.fn_GetUserName) {
      return
    }

    // 프로필 이미지 조회
    this.fn_GetProfileImageUrl()
    
    this.lv_IsLoading = true
    Promise.all([
      // 배너 정보 조회
      this.fn_SelListBann(),

      // 전자서명 지문촬영건
      this.fn_SelHomeBioInfo(),

      // 지점장 홈 정보조회
      this.fn_SelBmngrHomInfo(),

      // 홈메뉴
      !this.lv_homMenuList.length && this.fn_SelListHomMenu(),

      // 사랑온 미니 홈배너용 삭제예정고객 조회
      this.fn_SelDeltParngCustCnt(),
      
      // 사랑온 미니 홈배너용 이벤트고객 및 접촉고객 알림목록 조회
      this.fn_SelListHomeBannIapForMSP(),

      
      //통합영업자료사용권한코드
      this.fn_SetUntySlmtUseAuthCd(),
      // 최신자료 로딩
      this.fn_SelRntCntnt(),
    ]).then(response => {
      this.lv_IsLoading = false
      this.fn_SetChatbotMsg()
    })

    // 디바이스 토큰 설정 시 로그 기록 안함 (디바이스 토큰 설정 할 때 로그 기록 함)
    if(!this.lv_recLogInDeviceToken) {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    }

    this.fn_SendTodaysData()
    this.fn_HasNewAlarm()

    // FrameNavi 영역 선택 처리
    this.$BottomManager.fn_SetBottomNavigationSelectedMenu('home')

    //업무단에서 등록된 Back key 이벤트 리스너 모두 제거 처리     
    this.$bcUtil.removeAllBackKeyListener()
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    
    /**
     * 세션정보 유무에 따라서 처음 화면에서 진입 여부 설정함.
     **/       
    let telInfo = this.getStore('bcStore').getters.getState.telInfo
    let telSession = this.getStore('bcStore').getters.getState.telSession
    console.log('MSPBC500M telSession CHECK', telSession)
    if(telSession !== undefined && telSession === false) {
      this.getStore('bcStore').dispatch('TEL_SESSION', true)
      this.$router.push({name: 'MSPCM270M', params: {callerId:"MSPBC002M", viewId: 'MSPCM271D', chnlCustId: telInfo.chnlCustId, cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId}})
    }

    // 글자/화면 크기 설정 후 로그 등록
    this.$bizUtil.insSizeConfigLog({isHome: true})
  },
  deactivated() {
    // 챗봇 메시지 노출여부 갱신 - 홈 재진입 시 노출되지 않게 갱신 - push로 진입시는 제외
    if ( !this.lv_IsPush ) {
      let t_IsCompletedChatbotMsg = this.getStore('bcStore').getters.getState.isCompletedChatbotMsg
      if ( t_IsCompletedChatbotMsg === false ) this.getStore('bcStore').dispatch('IS_COMPLETED')
    }

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    // this.$NoticeManager.eventBus.$off(MenuConst.PUSH_ALARM_EVENT, this.fn_PushEventHandler)

    // scroll 위치 저장
    this.beforeScrollTop = this.$refs.home.beforeScroll
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    this.MenuConst = MenuConst
    return {
      lv_IsLoading: false,
      swiperOption: {
        centeredSlides: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        }
      },
      lv_profileImageUrl: '',
      lv_welcomeType: 0,
      lv_homeBannList: [],
      lv_bioInsrdNm: '',
      lv_bioParams: {},
      lv_infoPrcusCnsntPnstaCnt: 0, // 정보활용동의승인건수
      lv_bndlCnsntCnt: 0, // 단체일괄동의
      lv_ofrfmPblCnt: 0, // 청약서발행건수
      cMAllCustCntSVO: {
        cm00: 0,
        cm01: 0,
        cm02: 0,
        cm03: 0,
        cm04: 0,
        cm05: 0,
        cmTot: 0,
      },
      lv_HomeBannDelParngCust: [], // 홈배너 삭제예정고객 LIST
      lv_HomeBannIapList: [], // 홈배너알림(이벤트고객, 접촉고객) List
      lv_CntcCustCount: 0, // 고객접촉정보 수
      lv_EvtNoticeCount: 0, // 이벤트고객 수
      lv_RntCntntItems: [], // 최신 자료 목록
      
      // 공용 선택된 아이템 리스트, child의 리스트들과 동기화 됨
      lv_SelectedItemList: [],
      lv_SelectedItemListTmp:[],
      lv_Popup210: {},  // 나의보관함추가popup    
      lv_Popup222: {},  // 화상상담자료저장poppup
      lv_Popup152: {},  // 수신고객목록
      lv_Popup128: {},  // 안내수신고객목록
      lv_Popup154: {},  // 터치온고객문자발송 
      lv_Popup191: {},  // 이메일수신고객목록
      lv_Popup192: {},  // 터치온고객이메일발송 
      isMSPSA063P: {}, //  화상상담자료조회popup
      lv_TargetType: 'c',
      lv_SelectedCustList: [], // 화상상담자료저장 고객목록 리스트
      lv_SpinValue: 1,            // 인쇄매수    
      lv_IsIncludeCnsltInfo: 'Y', // 컨설턴트 개인정보 이미지 포함 여부
      isNofacCnsl: false,  // 비대명 상상 관련 
      lv_Class: false,        //클래스 함수지정 
      lv_rslt: false,
      lv_homMenuList: [], // 홈메뉴
      lv_ModalLogoutConfirm: false,
      lv_MsgLogoutConfirm: '사랑On 앱을 종료하시겠습니까?',
      lv_APDaySchdlListSVO: [],
      lv_IsShowChatbotMsg: false, // 챗봇문구 노출여부
      lv_ReadOpenScreenTooltip: false, // 열린화면 툴팁 컨트롤
      lv_initTypeValue: 'refresh element',
      lv_goodMorning: 'off',
      lv_success: 'off',
      lv_loginData: '',
      lv_loginWelcomeYn: false,
      lv_loginWelcomeBackYn: false,
      lv_tooltipOpend: false,
      lv_gpsChkYn: '',
      lv_clicked: false,
      lv_lastDegree: 0,
      lv_afterLogin: false,
      lv_snackbarOpen: false,
      lv_setTimeOutVal: '',
      lv_snacbarText: '',
      lv_phoneSnacbarText: '지점/교육장에서 다시 시도해주세요',
      lv_tabletSnacbarText: '사내망 연결 확인 후 다시 로그인해주세요',
      lv_recLogInDeviceToken: false // 디바이스 토큰 설정 시 로그 설정
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetUserName() {
      let userNm = this.getStore('userInfo').getters.getUserInfo.userNm
      window.fdpbridge.exec('AsyncStorageSetPlugin', {key: 'userNm', data: userNm}, function (result) {}, function (result) {})
      return userNm
    },
    isReadyHome() {
      return this.getStore('notice').getters.isReadyHome
    },
  },
  watch: {
    lv_gpsChkYn(newValue, oldValue){
      console.log("@@@@@@@@@@@@  newValue  >>>>"+newValue+"  /   "+ oldValue)
      let userInfo = this.getStore('userInfo').getters.getUserInfo
      if(newValue != ''){
        this.fn_loginInfo(this, userInfo)
        this.lv_gpsChkYn = ''
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명  : rotateRefresh
     * 설명        : 새로고침 표시 로테이션
    ******************************************************************************/
    rotateRefresh() {
      const refreshIcon = this.$refs.rotateIcon
      let degree = this.lv_lastDegree > 0 ? this.lv_lastDegree : 0

      const interval = setInterval(()=> {
        degree = (degree + 1.5) % 360 
        if (degree >= 360) {degree = 0}
        refreshIcon.style.transform = `rotate(${degree}deg)`
        this.lv_lastDegree = degree
      }, 1000 / 360)

      setTimeout(() => {
        clearInterval(interval)
      }, 1000)
    },
    /******************************************************************************
    * Function명  : openToolTip /closeToolTip
    * 설명        : 조회/귀점 표시 활성화 / 비활성화
    ******************************************************************************/
    openToolTip() {
      this.lv_tooltipOpend = true
    },
    closeToolTip() {
      this.lv_tooltipOpend = false
    },
    /******************************************************************************
     * Function명  : fn_PocDeviceEnv
    * 설명        : 앱 사용 디바이스 환경 Tablet / Phone / Other
    ******************************************************************************/
    async fn_PocDeviceEnv () {
      let rslt = await PSCommUtil.fn_GetAppDeviceEnv()
      console.log('fn_PocDeviceEnv rslt = ['+rslt+']')
      let lv_Vm = this
      if (rslt === 'Phone' || localStorage.getItem('osType') === 'ios') {
        lv_Vm.lv_rslt = true
        lv_Vm.fn_SetSrnSize()
      } else {
        lv_Vm.lv_rslt = false
        lv_Vm.fn_SetSrnSize()
      }
      return rslt
    },   
    /*********************************************************
     * Function명: fn_SetSrnSize
     * 설명: 화면크기를 셋팅
     *********************************************************/
    fn_SetSrnSize () {
      let lv_Vm = this

      console.log('fn_SetSrnSize lv_rslt = ['+lv_Vm.lv_rslt+']')
      console.log('fn_SetSrnSize srn_usr_setting = ['+localStorage.getItem('srn_usr_setting')+']')

      if (lv_Vm.lv_rslt === false) {
        if (localStorage.getItem('srn_usr_setting') === 'Y') {     
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
        } else if (localStorage.getItem('srn_usr_setting') === 'N') {
          document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
        } else {     
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
        }
      } else {
        document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
      }
    },            
    /*********************************************************
     * Function명: fn_GetProfileImage
     * 설명: 프로파일 이미지 url
     *********************************************************/
    fn_GetProfileImageUrl() {
      const userId = this.getStore('userInfo').getters.getUserInfo.userId
      this.lv_profileImageUrl = userId ? (`${this.host}/profimgdown.do?userEno=${userId}&t=${Date.now()}`) : ''
    },
    /*********************************************************
     * Function명: fn_SelListBann
     * 설명: 배너 정보 조회
     *********************************************************/
    fn_SelListBann() {
      const pParams = {}
      const trnstId = 'txTSSBC41S1'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body?.homeBannList) {
          this.lv_homeBannList = response.body.homeBannList
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_GetBannImg(pItem) {
      if (pItem.bannTypeCd === '1') return ''
      const src = (pItem.bannTypeCd === '2' && pItem.bannImgNm) ? `${this.host}/profimgdown.do?bannImg=${pItem.bannImgNm}` : ''
      return src ? `<img src="${src}">` : ''
    },
    fn_GetBannClass(pItem) {
      return pItem.bannTypeCd === '1' ? pItem.bannImgNm === 'A' ? 'home1'
                                      : pItem.bannImgNm === 'B' ? 'home2'
                                      : pItem.bannImgNm === 'C' ? 'home3'
                                      : pItem.bannImgNm === 'D' ? 'home4'
                                      : pItem.bannImgNm === 'E' ? 'home5'
                                      : pItem.bannImgNm === 'F' ? 'home_add'
                                      : pItem.bannImgNm === 'G' ? 'home_open'
                                      : ''
           : pItem.bannTypeCd === '2' ? 'home_self'
           : ''
    },
    /******************************************************************************
    * Function명  : fn_OnClickBann ()
    * 설명        : 관련 화면 이동
    * Params      : pItem
    * Return      : N/A
    ******************************************************************************/
    fn_OnClickBann (pItem) {
      // 관련 페이지로 이동
      if (pItem.bannScCntnt && pItem.bannScCd === '1' ) {
        if (pItem.bannScCntnt.indexOf('tss:') > -1) {
          pItem.srnId = pItem.bannScCntnt
          pItem.tbtMenuPageAttrCd = 'LTS'
          this.$MenuManager.fn_SendIntentAppForMenuItem(pItem)
        } else {
           this.$router.push({name: pItem.bannScCntnt})
        }
      } else {
        pItem.bannScCntnt && pItem.bannScCd === '2' && (
          this.$commonUtil.isMobile()
            ? window.fdpbridge.exec('intentBrowserPlugin', {uri: pItem.bannScCntnt}, () => {}, () => {})
            : window.open(pItem.bannScCntnt, '_blank')
        )
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenFidoRegGuidePopup
    * 설명       : 간편인증 등록 유도 팝업 열기
    ******************************************************************************/
    fn_OpenFidoRegGuidePopup () {
      console.log('##### fn_OpenFidoRegGuidePopup #####')
      // let flag = localStorage.getItem('fidoRegGuideFlag') ? Boolean(localStorage.getItem('userEnoSaveFlag')) : false
      // let flag = localStorage.getItem('fidoRegGuideFlag') ? true : false
      let flag = false
      let monogoYn = 'N'
      if (localStorage.getItem('fidoRegGuideFlag') === 'true' || localStorage.getItem('fidoRegGuideFlag') === true) flag = true
      if (localStorage.getItem('user_mongo_yn') === 'Y') monogoYn = 'Y'

      // 간편인증 미등록
      if (flag && monogoYn === 'Y') {
        let hasResult = false
        this.$bcUtil.openBottomPopUp(this, bcConstants.UNTY_ATHNT_CONFIRM_CASE[4], (isPositive) => {
          !isPositive && !hasResult && this.getStore('notice').dispatch('SET_READY_AGREE', true)
          hasResult = true
        })
        // localStorage.removeItem('fidoRegGuideFlag') // 초기화
      } else {
        // this.$bottomModal.closeAll()
        this.getStore('notice').dispatch('SET_READY_AGREE', true)
      }
    },
    /*********************************************************
     * Function명: fn_DigitProfile
     * 설명: 디지털프로필
     *********************************************************/
    fn_DigitProfile (type) {
      this.$router.push({ name: 'MSPBC100M', params: { type: type } })
    },
    /******************************************************************************
    * Function명  : fn_SelHomeBioInfo
    * 설명        : 전자서명 중단건 지문촬영건 조회
    ******************************************************************************/
    fn_SelHomeBioInfo() {
      const trnstId = 'txTSSPS42S3'
      const auth = 'S'
      const pParams = {}
      return this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body) {
          const itemData = response.body
          if (itemData.bioSvcId){
            this.lv_bioInsrdNm = itemData.vuhdInfo.insrdNm
            this.lv_bioParams = {
              svcTp: itemData.bioSvcTp,
              svcId: itemData.bioSvcId,
            }
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_DelHomeBioInfo
    * 설명        : 전자서명피보험자지문촬영요청 삭제
    ******************************************************************************/
    fn_DelHomeBioInfo() {
      const trnstId = 'txTSSPS42U3'
      const auth = 'S'
      const pParams = {}
      this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body) {
          const isProcOk = response.body.isProcOk
          if (isProcOk === 'Y') { // 삭제 
            console.log('*******삭제상태isProcOk********' + isProcOk)
          } else {
            console.log('*******삭제상태isProcOk********' + isProcOk)
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      }).finally(_ => {
        this.$router.push({name: 'MSPBC315P', params: this.lv_bioParams})
      })
    },
    /*********************************************************
     * Function명: fn_SelBmngrHomInfo
     * 설명: 지점장 홈 정보조회
     *********************************************************/
    fn_SelBmngrHomInfo() {
      const pParams = {}
      const trnstId = 'txTSSBC40S5'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          const body = response.body
          // this.lv_infoPrcusCnsntPnstaCnt = body.infoPrcusCnsntPnstaCnt || 0 //정보활용동의승인건수
          this.lv_bndlCnsntCnt = body.bndlCnsntCnt || 0 // 단체일괄동의승인건수
          this.lv_ofrfmPblCnt = body.ofrfmPblCnt || 0 // 청약서발행건수
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_고객검색
    * 설명       : 고객검색화면이동
    * 인자       : N/A
    * Return     : void
    ******************************************************************************/
    fn_MoveCustSearch () {
      this.fn_OpenMSPBC002P()
    },
    /******************************************************************************
    * Function명 : fn_고객검색 / 음성인식
    * 설명       : 고객검색화면이동
    * 인자       : N/A
    * Return     : void
    ******************************************************************************/
    fn_MoveCustSearchVoice () {
      let voiceText = ''
      let lv_Vm = this

      // 음성인식 호출
      window.fdpbridge.exec('STTPlugin', {}, function (result) {
        // 성공콜백
        voiceText = result.data
        lv_Vm.fn_OpenMSPBC002P(voiceText)
      }, function (result) {
        // 실패콜백
        // 실패시 메시지 표시 없음
        // this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        // this.getStore('confirm').dispatch('ADD', '음성인식에 실패하였습니다.')
      })
    },
    /**
     * Function명 : fn_OpenMSPBC002P
     * 설명       : 통합검색 팝업 호출
     * @param
     * @return
     */
    fn_OpenMSPBC002P (searchText) {
      this.popupBC002P = this.$bottomModal.open(MSPBC002P, {
        properties: {
          searchVoiceWord: searchText
        },
        listeners: {
          onPopupClose: () => {
            console.log('onPopupClose', this.popupBC002P)
            if ( this.popupBC002P ) {
              this.$bottomModal.close(this.popupBC002P)
              this.popupBC002P = ''
            }
          },
          onSrcMove: (pData) => {
            // 통합검색 화면 close
            this.$bottomModal.close(this.popupBC002P)

            // 화면이동
            if ( pData?.rtnData ) {
               // 최근접속화면정보 저장
              this.$bcUtil.setRntConctMenuInfo(this, pData.rtnData)

              // 화면이동
              this.$MenuManager.fn_RouterPushMenuItem(pData.rtnData)
            }
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 백버튼 구현(로그아웃 더블클릭에서 클릭으로 변경)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () {
      if ( this.popupBC002P ) {
        this.$bottomModal.close(this.popupBC002P)
        this.popupBC002P = ''
      } else {
        this.fn_BottomLogoutConfirm()
      }

      /*
      let now = new Date()
      if (now - this.lv_BackBtnClcickedDate <= 1000 ) {
        this.lv_ModalLogoutConfirm = true
        this.lv_BackBtnClcickedDate = now
        
        this.fn_BottomLogoutConfirm()
      } else {
        this.lv_BackBtnClcickedDate = now
      }
      */
    },
    /*********************************************************
     * Function명: fn_OnPositive
     * 설명: APP 종료 확인 시 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnPositive () {
      // fdp-confirm 창을 닫는다. fdp-component.css 에서 수정이 되어야 나타남.
      this.lv_ModalLogoutConfirm = false
      // 처리 루틴 적용 네이티브 앱.
      var lv_Vm = this
      var trnstId = 'txTSSBC15P2'
      var pParam = {
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log('DEBUG====', response)
          if ( response.body ) {
            // finally 에서 처리
            // window.fdpbridge.exec('exitAppPlugin', {}, () => {}, () => {}) // 앱종료
          } else {
            lv_Vm.fn_BottomAlert(response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        }).finally(function () {
          window.fdpbridge.exec('exitAppPlugin', {}, () => {}, () => {}) // 앱종료
        })
    },
    /*********************************************************
     * Function명: fn_OnNegative
     * 설명: APP 종료 거부시 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnNegative () {
      this.lv_ModalLogoutConfirm = false
      this.lv_BackBtnClcickedDate = 0
    },
    /******************************************************************************
    * Function명 : fn_SendTodaysData
    * 설명       : 오늘 데이터를 보냄
    ******************************************************************************/
    fn_SendTodaysData () {
      var lv_Vm = this
      var trnstId = 'txTSSAP20S3'
      var pParam = {
        userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
        fcSchdlStrYmd: moment(new Date()).format('YYYYMMDD'),
        fcSchdlEndYmd: moment(new Date()).format('YYYYMMDD')
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log('DEBUG====', response)

          if ( response.body != null) {
            lv_Vm.lv_APDaySchdlListSVO = response.body.aPSchdlInfoSVO
            lv_Vm.lv_APDaySchdlListSVO.sort((a, b) => {
              if (a.fcSchdlStrYmd < b.fcSchdlStrYmd) return -1
              if (b.fcSchdlStrYmd < a.fcSchdlStrYmd) return 1
              if (a.fcSchdlEndYmd > b.fcSchdlEndYmd) return -1
              if (b.fcSchdlEndYmd > a.fcSchdlEndYmd) return 1
              if (a.fcSchdlStrYmd === b.fcSchdlStrYmd && a.fcSchdlEndYmd === b.fcSchdlEndYmd) {
                if (a.fcSchdlStrHm < b.fcSchdlStrHm) return -1
                if (b.fcSchdlStrHm < a.fcSchdlStrHm) return 1
              }
              if (a.fcSchdlNm < b.fcSchdlNm) return -1
              if (b.fcSchdlNm < a.fcSchdlNm) return 1
            })
            let sendData = lv_Vm.fn_MakeString()
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: sendData }, () => {}, () => {})
          } else {
            lv_Vm.lv_APDaySchdlListSVO = []
            window.fdpbridge.exec('setAppWidgetDataPlugin', { data: 'null' }, () => {}, () => {})
          }
          // console.log(lv_Vm.lv_APDaySchdlListSVO)
        })
    },
    /******************************************************************************
    * Function명 : fn_MakeString
    * 설명       : 펼쳐진 화면에 나타날 데이터 세팅
    ******************************************************************************/
    fn_MakeString () {
      let ret = ''
      let lv_Vm = this
      for (let i = 0; i < lv_Vm.lv_APDaySchdlListSVO.length; i++) {
        let campaign = lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm === undefined || lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm === '' ? 'null' : lv_Vm.lv_APDaySchdlListSVO[i].cntcObjCmpgnNm
        let tmpLoca = lv_Vm.fn_makeSchedulString('where', lv_Vm.lv_APDaySchdlListSVO[i]) === '' ? '장소 미지정' : lv_Vm.fn_makeSchedulString('where', lv_Vm.lv_APDaySchdlListSVO[i])
        ret = ret + lv_Vm.lv_APDaySchdlListSVO[i].fcSchdlScCd + 'tsssmpA' + lv_Vm.lv_APDaySchdlListSVO[i].fcSchdlNm + 'tsssmpA' + lv_Vm.fn_makeSchedulString('when', lv_Vm.lv_APDaySchdlListSVO[i]) + 'tsssmpA' + tmpLoca + 'tsssmpA' + campaign + 'tsssmpB'
      }
      return ret === '' ? 'null' : ret
    },
    /******************************************************************************
    * Function명 : fn_makeSchedulString
    * 설명       : 일정의 시간 및 만날장소에 대한 문자열 Control
    ******************************************************************************/
    fn_makeSchedulString ( code, data) {
      let ret_val = ''
      let lv_Vm = this
      if ( code === 'where' ) {
        if ( data.fcSchdlPrmsPlcNm !== null && data.fcSchdlPrmsPlcNm !== '' ) {
          ret_val = lv_Vm.$bizUtil.omitStr( data.fcSchdlPrmsPlcNm, 26 )
        }
      } else if ( code === 'when' ) {
        if ( data.fcSchdlStrYmd === data.fcSchdlEndYmd ) {
          if ( data.fcSchdlStrHm === null || data.fcSchdlEndHm === null ) {
            ret_val = '종일'
          } else {
            ret_val = lv_Vm.fn_convertTime(data.fcSchdlStrHm) + ' ~ ' + lv_Vm.fn_convertTime(data.fcSchdlEndHm)
          }
        } else {
          ret_val = data.fcSchdlStrYmd + ' ' + lv_Vm.fn_convertTime(data.fcSchdlStrHm) + ' \n~ ' + data.fcSchdlEndYmd + ' ' + lv_Vm.fn_convertTime(data.fcSchdlEndHm)
        }
      }

      return ret_val
    },
    /*********************************************************
     * Function명: fn_convertTime
     * 설명: 일정 정보를 한글화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_convertTime ( data ) {
      var ret_val = ''

      if ( data === null || data.length !== 4 ) {
        ret_val = ''
      } else {
        if ( Number( data.substring(0, 2)) < 12 ) {
          ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
        } else if ( Number( data.substring(0, 2)) > 12 ) {
          ret_val = '오후 ' + ( Number(data.substring(0, 2)) - 12 ) + ':' + data.substring(2, 4)
        } else {
          ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
        }
      }

      return ret_val
    },
    /*********************************************************
     * Function명: fn_HasNewAlarm
     * 설명: 새 알림이 있는지 확인하는 메서드 - 태블릿구현시 따라감
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_HasNewAlarm () {
      let lv_Vm = this
      let pParams = {
        iapCnfYn: 'N'
      }
      const trnstId = 'txTSSBC03SA'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let t_NewAlarmCnt = 0
          if ( response && response.body ) {
            t_NewAlarmCnt = response.body.totalCnt
          }

          // Framefooter 알림영역 신규알림 갱신 처리
          lv_Vm.$BottomManager.fn_SetBottomMenuNotiValue('notification', Number(t_NewAlarmCnt))
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명  : fn_PushEventHandler
    * 설명        : Push Event 처리 핸들러
    * Params      : Push 데이터
    ******************************************************************************/
    fn_PushEventHandler (pParams) {
      this.lv_IsPush = true

      if (navigator.userAgent.indexOf("OIC-Agent iOS") > -1) {
        window.fdpbridge.exec('FDPLog.debug', { 'message':'fn_PushEventHandler', 'data':pParams })
      }
      // let t_LogMsg = ''
      let t_AlarmType = ''
      let t_ReciveType = ''
      let t_ReciveTelNo = ''
      let t_ChnlCustId = ''
      let t_CustNm = ''
      // t_LogMsg = `GNB Push Event 감지됨\n${JSON.stringify(pParams)}`

      t_AlarmType = pParams.alarmType
      t_ReciveType = pParams.reciveType
      t_ReciveTelNo = pParams.telNo
      t_ChnlCustId = pParams.chnlCustId
      t_CustNm = pParams.custNm

      // alert('fn_PushEventHandler========>' + pParams.reciveType + ' tel:' + t_ReciveTelNo)
      try {
        // 화면이동 - 아래의 알림일련번호 체크 후 없는경우는 바로 호출되며 있는 경우 읽음처리 후 호출됨
        let t_CallBackFunc = () => {
          // 화면이동전 팝업닫기
          this.$bottomModal.closeAll()

          let tmpObj
          switch (t_AlarmType) {
            case MenuConst.PUSH_ALARM_TYPE_CNTC_CUST: // 통합접촉고객
              tmpObj = pParams
              tmpObj.trtYmd = pParams.occuYmd
              tmpObj.detailOpen = true
              /*
              tmpObj.TabIndex_TSSSP115 = 1
              this.$router.push({name: 'TSSSP115M', params: tmpObj})
              */
              // 통합 접촉고객(MSPCM121M)
              this.$router.push({name: 'MSPCM121M', params: tmpObj})
              break
            case MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE: // 시스템 알림
              // 유파트너 공지사항(MSPBC007M)
              this.$router.push({name: 'MSPBC007M', params: pParams})
              break
            case MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE: // PUSH 관리자 발송(사랑온 미니)
              // 이동화면 지정 시 해당 화면으로 이동, 그외는 알림목록으로 이동
              let t_TbtMenuPageAttrCd = pParams.tbtMenuPageAttrCd
              let t_SrnId = pParams.srnId
              if ( t_TbtMenuPageAttrCd !== undefined && t_TbtMenuPageAttrCd !== null && t_TbtMenuPageAttrCd !== ''
                && t_SrnId !== undefined && t_SrnId !== null && t_SrnId !== '' ) {
                // 화면이동
                let t_Params = {
                  'tbtMenuPageAttrCd': t_TbtMenuPageAttrCd,
                  'srnId': t_SrnId
                }
                this.$MenuManager.fn_RouterPushMenuItem(t_Params, {})
              } else {
                // 유파트너 공지사항(MSPBC007M)
                this.$router.push({name: 'MSPBC007M', params: pParams})
              }
              break
            case MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE: // 이벤트 알림
              tmpObj = pParams
              // 활동캘린더(MSPAP320M) 
              this.$router.push({name: 'MSPAP320M'}) // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M
              break
            case MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE: // 스케쥴 알림.
              tmpObj = pParams
              tmpObj.pSchdlYmd = pParams.pSchdlYmd
              tmpObj.isMSPAP301M = true
              // this.$router.push({name: 'MSPAP301M', params: tmpObj}) // 화면ID변경됨
              this.$router.push({name: 'MSPAP314M', params: tmpObj})
              break
            case MenuConst.PUSH_ALARM_TYPE_REQ_BICS_NOTICE: // 태블릿으로 부터 전자서명을 위한 고객 생체인증정보(지문) 찰영 요청 PUSH - 2020/05/14 (모바일생체인증전자서명PJT)
              this.$router.push({name: 'MSPBC315P', params: pParams})
              break              
            case MenuConst.PUSH_ALARM_TYPE_REQ_MOVE_RNOTICE: // 태블릿으로 부터 전자서명을 위한 고객 생체인증정보(지문) 찰영 요청 PUSH - 2020/05/14 (모바일청약 중단건 페이지이동)
              this.$router.push({name: 'MSPPS230M', params: pParams})
              break
            case MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE: // 프리미엄 고객사랑서비스 방문결과 미입력 고객수 알림
              // 프리미엄 고객사랑 서비스(MSPCM303M)
              this.$router.push({name: 'MSPCM303M', params: pParams})
              break
            case MenuConst.PUSH_ALARM_TYPE_CUST_CNSNT_APRV_NOTICE: // 고객등록(지류) - 지점장승인시, 담당 컨설턴트에게 푸시 알림
              // 지류동의서승인(MSPCM113M)
              this.$router.push({name: 'MSPCM113M', params: pParams})
              break
            case MenuConst.PUSH_ALARM_TYPE_TS_SAVE_NOTICE: // TS신청 (2023.04.03 신형상 SVN추가건)
              // TS목록 조회
              this.$router.push({name: 'MSPRC350M'})
              break
            case MenuConst.PUSH_ALARM_TYPE_CMPGN_EVT_NOTICE: // 캠페인고객 이벤트 푸시 알림
              // 캠페인고객(MSPCM160M)
              this.$router.push({name: 'MSPCM160M'})
              break
            case MenuConst.PUSH_ALARM_TYPE_CUST_AGREE_COMP_NOTICE: // 비대면 고객동의완료시 담당 컨설턴트에게 푸시 알림
              // 고객카드(MSPCM270M)
              this.$router.push({name: 'MSPCM270M', params: pParams})
              break
            case MenuConst.PUSH_ALARM_TYPE_PM_RENT: //  푸시 재가입
              // 재가입대상계약(MSPPM850M)
              this.$router.push({name: 'MSPPM850M'}).catch(()=>{})
              break
            case MenuConst.PUSH_ALARM_TYPE_ATNT_RPLC_CONT: // 유의대체계약
              this.$router.push({name: 'MSPPS620M'}).catch(()=>{})
              break
          } // switch
        }
        // 화면이동 END

        // type에 따라 처리
        if (t_ReciveType === MenuConst.PUSH_RECEIVE_TYPE_EVENT) { // Event 형태 N 뱃지 갱신 처리 , 테블릿 Call 요청도 들어 온다.
          // this.addSystemNotice()
          switch (t_AlarmType) {
            case MenuConst.PUSH_ALARM_TYPE_REQ_CALL_NOTICE: // 태블릿으로 부터 들어온 전화걸기
              // let lv_UserID = ''
              // lv_UserID = this.getStore('userInfo').getters.getUserInfo.userId // '채널고객ID',
              // alert('fn_PushEventHandler !!' + t_ReciveTelNo)
              this.$cmUtil.onPhoneCall(t_ReciveTelNo, t_ChnlCustId, t_CustNm)
              break
            case MenuConst.PUSH_ALARM_TYPE_REQ_SYSCALL_NOTICE: // 태블릿으로 부터 들어온 전화걸기(시스템 문의 전화) 통화시간저장하지 않음.
              window.fdpbridge.exec('CallPlugin', {number: t_ReciveTelNo}, function (result) {
                // 성공콜백
              }, function (result) {
                // 실패콜백 alert(result.data)
                // window.fdpbridge.exec('ToastPlugin', {message: '통화 시간을 받지 못했습니다..'}, () => {}, () => {})
              })
              break
            default:
              // IOS대응 - IOS는 reciveType을 INTENT가 아닌 EVENT로만 준다고 하여 EVENT Type영역에도 INTENT와 동일한 로직 추가
              if (navigator.userAgent.indexOf("OIC-Agent iOS") > -1) {
                if ( pParams.iapMatrSn !== undefined &&  pParams.iapMatrSn !== null && pParams.iapMatrSn !== '' ) { // 알림일련번호 있을 경우 읽음처리(시스템공지는 알림목록 이동 후 처리)
                  let lv_Vm = this
                  let t_pParams = {iapMatrSn: pParams.iapMatrSn}
                  const trnstId = 'txTSSBC03U9'
                  const auth = 'U'
                  this.post(lv_Vm, t_pParams, trnstId, auth)
                    .then(function (response) {
                      // 신규알림건수 조회(FrameFooter 신규알림 관련 갱신)
                      lv_Vm.fn_HasNewAlarm()

                      // 화면이동관련 후행 처리
                      t_CallBackFunc()
                    }).catch(function (error) {
                      window.vue.error(error)
                      // 혹시 오류발생하더라도 화면이동
                      t_CallBackFunc()
                    })
                } else {
                  t_CallBackFunc()
                }
              }
              break
          } // switch
        } else if (t_ReciveType === MenuConst.PUSH_RECEIVE_TYPE_INTENT) { // Intent 형태 상세 Action 처리
          if ( pParams.iapMatrSn !== undefined &&  pParams.iapMatrSn !== null && pParams.iapMatrSn !== '' ) { // 알림일련번호 있을 경우 읽음처리(시스템공지는 알림목록 이동 후 처리)
            let lv_Vm = this
            let t_pParams = {iapMatrSn: pParams.iapMatrSn}
            const trnstId = 'txTSSBC03U9'
            const auth = 'U'
            this.post(lv_Vm, t_pParams, trnstId, auth)
              .then(function (response) {
                // 신규알림건수 조회(FrameFooter 신규알림 관련 갱신)
                lv_Vm.fn_HasNewAlarm()

                // 화면이동관련 후행 처리
                t_CallBackFunc()
              }).catch(function (error) {
                window.vue.error(error)
                // 혹시 오류발생하더라도 화면이동
                t_CallBackFunc()
              })
          } else {
            // 
            t_CallBackFunc()
          }
        }
      } catch (error) {
        // t_LogMsg = `Push Handler Error\n${error}`
      }

      // if (t_LogMsg !== '') alert(t_LogMsg)
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      const pop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(pop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(pop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(pop)
          }
        }
      })
    },
    /**
     * Function명 : fn_BottomLogoutConfirm
     * 설명       : 로그아웃 관련 메시지를 BottomAlert을 confirm창으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomLogoutConfirm () {
      const pop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'B',
          single: false,
          title: "",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(pop)
            this.fn_OnPositive()
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(pop)
            this.fn_OnNegative()
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(pop)
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_OnMoveSrn
     * 설명: 선택화면으로 이동
     * Params: pItem (화면이동 대상정보)
     * Return: N/A
     *********************************************************/
    fn_OnMoveSrn (pItem) {
      // 최근접속화면정보 저장
      this.$bcUtil.setRntConctMenuInfo(this, pItem)

      // 화면이동
      this.$MenuManager.fn_RouterPushMenuItem(pItem)
    },
    /******************************************************************************
    * Function명 : fn_chatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_chatBotContent () {
      let lv_Url = ''

      // 20220706 [ASR220600878] 사랑On 미니챗봇 스크립트 변경
      // 검증계 https://slimsg.samsunglife.com/messenger/?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC
      // 운영계 https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=사번
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
        lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      // } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
      //   // lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      //   lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
    /*********************************************************
     * Function명: fn_SetChatbotMsg
     * 설명: 챗봇 문구 setter - '챗봇 별리가 XXX님을 도와드릴게요' 라는 문구를 5초간 노출 시킴
     *                         최초 홈화면 집입 시에만 노출된다. 노출 후 해당 flag를 vuex에 저장하여
     *                         홈화면 재집입 시에는 노출시키지 않음
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetChatbotMsg() {
      const t_IsCompletedChatbotMsg = this.getStore('bcStore').getters.getState.isCompletedChatbotMsg
      if ( t_IsCompletedChatbotMsg ) {
        this.lv_IsShowChatbotMsg = false
      } else {
        this.lv_IsShowChatbotMsg = true
        setTimeout(() => {
          this.lv_IsShowChatbotMsg = false
          this.getStore('bcStore').dispatch('IS_COMPLETED')
        }, 5000)
      }
    },
    fn_OpenMSPBC003P() {
      const pop = this.$bottomModal.open(MSPBC003P, {
        properties: {
          savedHomeMenuList: this.lv_homMenuList
        },
        listeners: {
          closed: () => {
            this.$bottomModal.close(pop)
          },
          saved: () => {
            // 홈메뉴
            this.fn_SelListHomMenu(),
            
            this.$bottomModal.close(pop)
          }
        }
      },
      {
        properties: {
          full: true,
          preventTouchClose: true,
          mode: 'right' // 우->좌 open popup (defalut: 하->상)
        }
      })
    },
    /*********************************************************
     * Function명: fn_SelListHomMenu
     * 설명: 홈메뉴
     *********************************************************/
    async fn_SelListHomMenu() {
      let newMenuList = []
      try {
        newMenuList = await this.$commonUtil.selMgrCfg(this, {envId: 'MENU_NEW_LABEL_LIST'}) // 메뉴신규라벨목록
      } catch (e) {
        console.log('MSPBC500M, selMgrCfg - error >', e)
      }
      const trnstId = 'txTSSBC40S6'
      return this.post(this, {}, trnstId, 'S').then(response => {
        if (response?.body) {
          const body = response.body
          if (!body.rsltFlag) {
            this.$addSnackbar('오류가 발생했습니다.')
            return
          }
          if (!body.homMenuList) {
            return
          }
          this.lv_homMenuList = body.homMenuList?.filter(item => !!item.menuId).map(item => {
            const imgNum =
                item.srnId === 'MSPAP505M' ? '34' // 가입설계발행현황
              : item.srnId === 'MSPAP502M' ? '35' // 청약서발행현황
              : item.srnId === 'MSPAP504M' ? '38' // 모바일청약진행건조회
              : item.srnId === 'MSPAP503M' ? '16' // 신계약접수현황
              : item.srnId === 'MSPAP506M' ? '36' // FC활동/업적현황
              : item.srnId === 'MSPAP508M' ? '39' // 단체일괄동의승인
              : item.srnId === 'MSPCM113M' ? '37' // 정보활용동의승인
              : item.srnId === 'MSPBC400M' ? '40' // 컨설턴트기본정보
              : item.srnId === 'MSPCM115M' ? '03' // 전체고객
              : item.srnId === 'MSPCM050M' ? '04' // 고객등록동의
              : item.srnId === 'MSPPI700M' ? '01' // 가입보장설계
              : item.srnId === 'MSPPM902M' ? '02' // 보유계약
              : item.srnId === 'MSPPM870M' ? '17' // 유지체크명세
              : item.srnId === 'MSPAP105M' ? '18' // 활동현황
              : item.srnId === 'MSPSA201M' ? '08' // 안내자료
              : item.srnId === 'MSPAP100M' ? '22' // 이달의업적
              : item.srnId === 'MSPAP110M' ? '41' // 승각격실적
              : item.srnId === 'MSPAP130M' ? '23' // 수수료세부명세
              : item.srnId === 'MSPAP070M' ? '42' // 조직명장수수료소득관리
              : item.srnId === 'MSPAP141M' ? '43' // 연간소득내역
              : item.srnId === 'MSPAP610M' ? '44' // 세무팀장활동확인
              : item.srnId === 'MSPRC100M' ? '45' // 리크루팅동의
              : item.srnId === 'MSPRC200M' ? '26' // 전체후보자
              : item.srnId === 'MSPRC250M' ? '46' // 세미나현황
              : item.srnId === 'MSPRC300M' ? '47' // 배양현황
              : item.srnId === 'MSPRC350M' ? '48' // TS캘린더
              : ''

            if (newMenuList.some(innerItem => item.srnId.indexOf(innerItem.envCntnt) > -1)) {
              item.isNew = true
            }

            imgNum && (item.imgSrc = require(`@/assets/img/icon/home_icon/home_menu${imgNum}.png`))
            return item
          })
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_OpenCustomerStat
    * 설명       : 전체 고객 수 조회
    ******************************************************************************/
    fn_OpenCustomerStat() {
      const trnstId = 'txTSSCM33S8'
      const auth = 'S'
      var lv_Vm = this
      const pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
      }
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        // 서버 데이터 가져오기
        if (response?.body) {
          const responseList = response.body.cMAllCustCntSVO

          if (responseList) {
            const result = {}
            result.cmTot = 0
            for (let i = 0; i < responseList.length; i++) {
              if ( responseList[i].custScCd === '01' ) {
                result.cm01 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm01
              } else if ( responseList[i].custScCd === '02' ) {
                result.cm02 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm02
              } else if ( responseList[i].custScCd === '03' ) {
                result.cm03 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm03
              } else if ( responseList[i].custScCd === '04' ) {
                result.cm04 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm04
              } else if ( responseList[i].custScCd === '05' ) {
                result.cm05 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm05
              } else if ( responseList[i].custScCd === '00' ) {
                result.cm00 = parseInt(responseList[i].smtotCnt.trim())
              }
            }
            lv_Vm.cMAllCustCntSVO = result
          }

          // 고객현황 바텀시트 오픈
          lv_Vm.$refs.customerStat.open()
        } else {
          lv_Vm.$addSnackbar(response.msgComm.msgDesc)
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_SelDeltParngCustCnt ()
    * 설명        : 홈화면 배너용 삭제예정고객 수 조회
    * Params      : N/A
    * Return      :
    ******************************************************************************/
    fn_SelDeltParngCustCnt () {
      let lv_Vm = this
      let pParams = {}
      const trnstId = 'txTSSBC41S4'
      const auth = 'S'
      return this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
        if (response?.body) {
          // let t_DelParngCustCnt = 0
          if ( response.body.iCDeltParngCustVO?.length ) {
            // 삭제예정고객List
            /*
            let t_ICDeltParngCustVO = response.body.iCDeltParngCustVO
            let t_Today = lv_Vm.lv_SystemToday // 시스템 현재일자
              
            // 삭제예정고객 List에서 필수컨설팅동의 종료예정일 또는 마케팅동의 종료예정일이 2주 이내 인 건을 추출
            for ( let i=0; i < t_ICDeltParngCustVO.length; i++ ) {
              let t_MndtCnstgCnsntEndYmd = t_ICDeltParngCustVO[i].mndtCnstgCnsntEndYmd // 필수컨설팅동의 종료예정일
              let t_MktCnsntEndYmd = t_ICDeltParngCustVO[i].mktCnsntEndYmd// 마케팅동의 종료예정일
              
              // 필수컨설팅동의 종료예정일이 2주(14일) 이내 인지 check
              let t_IntervalDate = -1
              if ( t_MndtCnstgCnsntEndYmd !== undefined && t_MndtCnstgCnsntEndYmd !== null &&
                t_MndtCnstgCnsntEndYmd !== '' && t_MndtCnstgCnsntEndYmd !== '00000000' ) {
                t_IntervalDate = lv_Vm.fn_CalcIntervalDate(t_Today, t_MndtCnstgCnsntEndYmd)
                console.log('필수컨설팅동의 확인 ::', t_Today, t_MndtCnstgCnsntEndYmd, t_IntervalDate)

                if ( Number(t_IntervalDate) >= 0 && Number(t_IntervalDate) <= 14 ) {
                  t_DelParngCustCnt++
                  continue
                }
              }

              // 마케팅동의 종료예정일이 2주(14일) 이내 인지 check
              t_IntervalDate = -1
              if ( t_MktCnsntEndYmd !== undefined && t_MktCnsntEndYmd !== null &&
                t_MktCnsntEndYmd !== '' && t_MktCnsntEndYmd !== '00000000' ) {
                t_IntervalDate = lv_Vm.fn_CalcIntervalDate(t_Today, t_MktCnsntEndYmd)
                console.log('마케팅동의 확인 ::', t_Today, t_MktCnsntEndYmd, t_IntervalDate)

                if ( Number(t_IntervalDate) >= 0 && Number(t_IntervalDate) <= 14 ) {
                  t_DelParngCustCnt++
                  continue
                }
              }
            }
            if ( t_DelParngCustCnt > 0 ) {
              lv_Vm.lv_HomeBannDelParngCust = [{
                'delParngCustCnt': t_DelParngCustCnt
              }]
            }
            */
            lv_Vm.lv_HomeBannDelParngCust = [{
              'delParngCustCnt': response.body.iCDeltParngCustVO.length
            }]
          }
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_SelListHomeBannIapForMSP ()
    * 설명        : 사랑온 미니 홈배너용 이벤트고객 및 접촉고객 알림목록 조회(당일 기준 수신 미확인건)
    * Params      : N/A
    * Return      : 이벤트고객 및 접촉고객 당일기준 미확인 알림목록
    ******************************************************************************/
    fn_SelListHomeBannIapForMSP () {
      const pParams = {
        nextKey: '1',
        readCount: 6
      }
      const trnstId = 'txTSSBC03SC'
      const auth = 'S'
      return this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body?.returnList) {
          // 조회된 결과 배너 설정
          this.lv_CntcCustCount = 0
          this.lv_EvtNoticeCount = 0
          this.lv_HomeBannIapList = response.body.returnList.filter(item => {
            const t_IapKndCd = item.iapKndCd
            // 알림종류코드가 접촉고객(2)인 경우: 최대 2개 표시
            if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
              this.lv_CntcCustCount++
              return this.lv_CntcCustCount <= 2
            }

            // 알림종류코드가 이벤트고객(9)인 경우: 최대 1개 표시
            if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
              this.lv_EvtNoticeCount++
              return this.lv_EvtNoticeCount <= 1
            }
          })
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_GetIapCntntOfHomeBannIapList ()
    * 설명        : 홈배너용 알림목록 중 알림내용을 return하는 함수
    * Params      : item
    * Return      : 홈배너용 알림목록 중 알림내용
    ******************************************************************************/
    fn_GetIapCntntOfHomeBannIapList (item) {
      let t_RtnTxt = item.iapCntnt

      const t_IapKndCd = item.iapKndCd
      // 알림종류코드가 접촉고객(2)인 경우 '[접촉정보]' 문구삭제
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        t_RtnTxt = t_RtnTxt.replace('[접촉정보]', '').trim()
      }

      // 알림종류코드가 이벤트고객(9)인 경우 '[활동캘린더]' 문구삭제 및 조정
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        t_RtnTxt = t_RtnTxt.replace('[활동캘린더]', '').trim()

        // t_RtnTxt = t_RtnTxt.replace('금일 ', '').trim()

        // t_RtnTxt = t_RtnTxt.replace('이 있습니다.', '입니다.').trim()
        
        // t_RtnTxt = '<b>' + t_RtnTxt.replace('이 있습니다.', '</b>이 있습니다.').trim()

        // 고객이벤트 알림인 경우 추가메시지 설정
        // t_RtnTxt = t_RtnTxt + ' 고객님께 연락해보는건 어떨까요?'
      }
      return t_RtnTxt
    },
    fn_OnClickBannIap(item) {
      const t_IapKndCd = item.iapKndCd
      // 알림종류코드가 접촉고객(2)인 경우
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        this.$router.push({name: 'MSPCM121M'})  
      }

      // 알림종류코드가 이벤트고객(9)인 경우
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        this.$router.push({name: 'MSPAP320M'}) // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M

      }
    },
    /*********************************************************
     * Function명: fn_SetUntySlmtUseAuthCd
     * 설명: 통합영업자료사용권한코드 설정
     *********************************************************/
    fn_SetUntySlmtUseAuthCd() {
      let chnlScCd = this.getStore('userInfo').getters.getUserInfo.srvcMngScCd
      console.log('chnlScCd :::', chnlScCd)

      switch (chnlScCd) {
        case 'FC':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
        case 'AM':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_AM
          break
        case 'SA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_SA
          break
        case 'GP':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GP
          break
        case 'BS':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_BS
          break
        case 'DI':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_DI
          break
        case 'GA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GA
          break
        default:
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
      }
    },
    /*********************************************************
     * Function명: fn_SelRntCntnt
     * 설명: 최신자료 최초 조회
     * Params: N/A
     * Return: 최신자료 컨텐츠 목록
     **********************************************************/
    fn_SelRntCntnt() {
      let lv_vm = this

      let pParams =
      {
        untySlmtUseAuthCd: lv_vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        whlInqrYn: 'Y', // 전체조회여부
        moblInqrYn: 'Y' // 모바일에서 호출여부
      }

      // 트랜잭션ID 설정
      let trnstId = 'txTSSSA01S1'
      const _auth = 'S'

      this.post(lv_vm, pParams, trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            // 서비스 후처리
            if (response.body !== null && response.body !== '' && response.body.iCCntntBasInfoVO !== null && response.body.iCCntntBasInfoVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 응답 데이터 처리
              let reponseBody = response.body
              reponseBody.iCCntntBasInfoVO.forEach(async (item, index) => {

                item.blobImgUrl = ''
                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await lv_vm.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })
              // lv_vm.$data.msgTxt = reponseBody.iCCntntBasInfoVO
              const rntCntntBasInfoVOList = reponseBody.iCCntntBasInfoVO
              // 서비스 통신 조회키
              // lv_vm.lv_rntCntntStndKeyList[0] = response.trnstComm.stndKeyList[0]
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장 , 이때 7개만 보여준다.
              lv_vm.lv_RntCntntItems = rntCntntBasInfoVOList.slice(0, 7)

              console.log('++++++최신자료 목록++++++', lv_vm.lv_RntCntntItems)
    
            } else {
              // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          window.vue.error('TSSSP201M_fn_onInitRntCntnt ERROR: ' + error)
        })
    },
    /*********************************************************
    * Function명: fn_OnSelectedSingleItem
    * 설명: 선택된 인기자료/최신자료/내가사용한자료의 제목부분을
            클릭한경우 상세보기 팝업창을 띄우지 않고 바로 해당 pdf뷰
            를 보여준다.
    * Params: target
    * Return: N/A
    *********************************************************/
    fn_OnSelectedSingleItem (pSelectedItem) {
      this.clickedSingleItem = true
      //pSelectedItem.checked = false
      this.lv_SelectedSingleItem = pSelectedItem
      this.lv_SelectedItemList = []
      this.lv_SelectedItemList.push(pSelectedItem)
      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)

      // 단일 선택 후 상세보기 진행 시
      this.lv_IsMadeMultiItem = false
      console.log(this.lv_IsMadeMultiItem)
      // 단일 선택 후 상세보기 진행 시
      if (pSelectedItem.vdoLnkUrl !== null && pSelectedItem.vdoLnkUrl !== undefined && pSelectedItem.vdoLnkUrl !== '') {
        // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
        this.fn_IncrementItemInqrFgrList()
      } else if (pSelectedItem.pdfFileYn === 'Y') {
        this.fn_IncrementItemInqrFgrList()
      } else {
        // 동영상 링크가 아닐 경우는 인쇄 실행
        this.fn_CreateToPDFContnt()
      }
//
      // FOM 파일 저장
      if (!this.lv_SelectedFomItemList) this.lv_SelectedFomItemList = []
      this.lv_SelectedFomItemList.push(pSelectedItem)
    },
    /******************************************************************************
    * Function명 : fn_IncrementItemInqrFgrList
    * 설명       : 조회 이력 저장
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_IncrementItemInqrFgrList () {
      let lv_vm = this
      const trnstId = 'txTSSSA01P2'
      const auth = 'S'

      let sParams = {}
      // 컨설턴트 번호
      sParams.cnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId
      if (lv_vm.lv_IsMadeMultiItem) {
        sParams.sACntntBasInfoSVO = lv_vm.lv_SelectedItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        sParams.sACntntBasInfoSVO = tempVOList
      }

      this.post(lv_vm, sParams, trnstId, auth)
        .then(function (response) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
          lv_vm.getStore('progress').getters.getState.isShow = true
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {

              if (response.body.sASelCntntDtlSVO != null && response.body.sASelCntntDtlSVO.length > 0) {
                if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {
                  // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                  if (!lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== null && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== undefined && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== '') { // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedSingleItem.vdoLnkUrl}, () => {}, () => {})
                  } else if (lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedItemList.length === 1 && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== null && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== undefined && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== '') {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedItemList[0].vdoLnkUrl}, () => {}, () => {})
                  } else {
                    lv_vm.fn_SaveFOMFile()
                  }
                }
              }
            } else {
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
              lv_vm.getStore('progress').getters.getState.isShow = false
              if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CreateToPDFContnt
    * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
    * 인자       : lv_UserInfo 사용자 정보 데이터 (Object)
    * Return     : void
    ******************************************************************************/
    fn_CreateToPDFContnt () {
      let lv_vm = this

      lv_vm.lv_SADCFmlmtSVOList = []
      lv_vm.lv_CompletedPDFFileVOList = []

      let pParams = {}
      pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

      if (lv_vm.lv_IsMadeMultiItem) {
        // FOM 파일만 PDF 생성 대상
        pParams.sACntntBasInfoSVO = lv_vm.lv_SelectedFomItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        pParams.sACntntBasInfoSVO = tempVOList
      }
      const trnstId = 'txTSSSA22P3'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.sADCFmlmtSVO != null && response.body.sADCFmlmtSVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
              //lv_vm.getStore('progress').getters.getState.isShow = true
              lv_vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
              lv_vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화
              lv_vm.fn_CheckContentsTransformation(lv_vm.lv_SADCFmlmtSVOList)
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CheckContentsTransformation  ( 2차 개발시 메서드 대체 처리 )
    * 설명       : FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
    * Params     : 변환 요청한 결과 VO 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CheckContentsTransformation (pSADCFmlmtSVO) {
      console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) START ☞ ' + new Date().getTime())

      let lv_vm = this
      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        sADCFmlmtSVO: pSADCFmlmtSVO
      }
      const trnstId = 'txTSSSA22P4'
      const auth = 'S'


      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              // 서비스 호출 결과(body)가 있을 경우
              // 생성여부 확인 시도 회수를 증가
              // 20번까지만 생성확인 여부 시도
              lv_vm.lv_CheckDocsRetryNumberOfTimes++

              // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
              // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
              if (response.body.sADCDoctTrfoBasSVO !== null && response.body.sADCDoctTrfoBasSVO.length > 0) {
                let tempPSADCFmlmtSVO = []
                let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                // 생성요청 VO 목록에서
                lv_vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                  let tempFlag = false
                  let tempVO = {}

                  // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                  resultSADCDoctTrfoBasSVO.forEach(item => {
                    if (dcItem.srcFileNm01 === item.eltrnDoctId) {
                      tempFlag = true
                      tempVO = item
                    }
                  })

                  // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                  if (tempFlag) {
                    if (lv_vm.lv_CompletedPDFFileVOList !== null) {
                      let addFlag = true
                      lv_vm.lv_CompletedPDFFileVOList.forEach(item => {
                        if (tempVO.eltrnDoctId === item.eltrnDoctId) {
                          addFlag = false
                        }
                      })

                      if (addFlag) {
                        lv_vm.lv_CompletedPDFFileVOList.push(tempVO)
                      }
                    }
                  } else {
                    tempPSADCFmlmtSVO.push(dcItem)
                  }
                })

                // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                // 생성여부 확인 작업을 계속 진행
                if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                  // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                  if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                    setTimeout(function () {
                      lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                    }, 1000)
                  } else { // 그렇지 않으면 시도 중지
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                    //v_Vm.getStore('progress').getters.getState.isShow = false
                    // 조회이력 증가 호출
                    //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                    //lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
                  }
                } else { // 그렇지 않으면 조회수 증가 단계로 이동
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  //lv_vm.getStore('progress').getters.getState.isShow = false
                  // 조회이력 증가 호출
                  if (lv_vm.isNofacCnsl) { // [비대면상담]인 경우는 TUTU00012, TUTU00013 테이블에 상담 문서 정보 INSERT
                      for (let i = 0; i < pSADCFmlmtSVO.length; i++) {
                        let tempObj = {}
                        tempObj.nofacCnslDoctNm = pSADCFmlmtSVO[i].prdtNm
                        tempObj.eltrnDoctFilePathNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFilePathNm
                        tempObj.eltrnDoctFileNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFileNm
                        lv_vm.lv_SelectedPdfList.push(tempObj)
                      }
                      lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
                  } else { // 조회이력 증가 호출
                    lv_vm.fn_IncrementItemInqrFgrList()
                  }
                }  
              } else if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                lv_vm.getStore('progress').getters.getState.isShow = false
                // 조회이력 증가 호출
                // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
              }
            } else { // 서비스 호출 결과는 없지만
              // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
              if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
        console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) END ☞ ' + new Date().getTime())

    },
    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    fn_SaveFOMFile () {
      if (process.env.NODE_ENV === 'local') {
        let viewerTargetFileList = ''
        let savePdfTargetItemList = []
        if (this.lv_IsMadeMultiItem) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.eltrnDoctFileNm = 'test.pdf'
            itemObj.localFileNm = 'test.pdf'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

            savePdfTargetItemList.push(itemObj)
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = selectedUntySlmtItem.pdfApndFilePathNm
          itemObj.eltrnDoctFileNm = 'test.pdf'
          itemObj.localFileNm = 'test.pdf'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

          savePdfTargetItemList.push(itemObj)
        }
        savePdfTargetItemList.forEach(item => {
          try {

            viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

        this.fn_CallPdfViewer(viewerTargetFileList)
      } else {
        let successCount = 0
        let tryCount = 0
        let savePdfTargetItemList = []
        let viewerTargetFileList = ''

        if (this.lv_IsMadeMultiItem === true) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            if (selectedUntySlmtItem.pdfFileYn === 'Y') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
              itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

              savePdfTargetItemList.push(itemObj)
            } else {
              let tempDcFmlmtSVO = null
              let tempCompletedPdfSVO = null
              this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                  tempDcFmlmtSVO = dcFmlmtSVO
                }
              })

              if (tempDcFmlmtSVO !== null) {
                this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                  if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                    tempCompletedPdfSVO = completedPDFFileSVO
                  }
                })
              }

              if (tempCompletedPdfSVO !== null) {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

                // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
                let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                } else {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                }

                savePdfTargetItemList.push(itemObj)
              }
            }
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          if (selectedUntySlmtItem.pdfFileYn === 'Y') {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
            itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
            itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

            savePdfTargetItemList.push(itemObj)
          } else {
            let tempDcFmlmtSVO = null
            let tempCompletedPdfSVO = null
            this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
              if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                tempDcFmlmtSVO = dcFmlmtSVO
              }
            })

            if (tempDcFmlmtSVO !== null) {
              this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                  tempCompletedPdfSVO = completedPDFFileSVO
                }
              })
            }

            if (tempCompletedPdfSVO !== null) {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
              if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
              } else {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
              }

              savePdfTargetItemList.push(itemObj)
            }
          }
        }
        savePdfTargetItemList.forEach(item => {
          if (process.env.NODE_ENV === 'development') {
            item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            item.eltrnDoctFileNm = 'test.pdf'
            item.localFileNm = 'test.pdf'
            item.fileTitle = '[TEST]'
          }
          
          try {
            this.fn_FdpSaveFile(item)
              .then(response => {
                successCount++
                tryCount++
                
                viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
                  if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
                    this.fn_CallPdfViewer(viewerTargetFileList)
                  } else if (tryCount === this.lv_SelectedItemList.length) {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    //this.getStore('progress').getters.getState.isShow = false
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }
                } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
                  this.fn_CallPdfViewer(viewerTargetFileList)
                }
              }).catch(error => {
                tryCount++
                // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
                // 다중 선택일 경우
                if (this.lv_IsMadeMultiItem) {
                  // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
                  if (tryCount === this.lv_SelectedItemList.length) {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    this.getStore('progress').getters.getState.isShow = false
                    // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }

                  if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
                } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                  // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                  this.getStore('progress').getters.getState.isShow = false
                  // 오류 팝업 메시지 출력
                  this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
                }
                if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              })
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })
      }
    },
    
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }

        
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N'
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },


    fn_CallPdfViewer (viewerTargetFileList) {
      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
      this.getStore('progress').getters.getState.isShow = false
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || item.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
          libraryItemCount++
        }
      }


      // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
      // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
      let menuAuth = ''
      if (!this.lv_SelcectedClsfSytmNm) this.lv_SelcectedClsfSytmNm = ''
      if (libraryItemCount > 0 || this.lv_SelcectedClsfSytmNm.indexOf('라이브러리') !== -1 || this.lv_SelcectedClsfSytmNm.indexOf('사랑On도움방') !== -1) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
       menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
      } else {
       menuAuth = '1111'
      }

      let lv_vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
        file: viewerTargetFileList // 서버에 올릴때.
        // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
      }, function (result) {
        // 성공콜백
        // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
        if (result.data === 'btn1') {
          //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          lv_vm.fn_SMSBtnClicked()   //문자
        } else if (result.data === 'btn2') {
                lv_vm.fn_OpenPopEmailCustList()  //이메일
        } else if (result.data === 'btn3') {
                lv_vm.fn_print_BottomSheet_open() //인쇄
        } else if (result.data === 'btn4') {
                lv_vm.fn_OpenMSPSA210P()          
        }
      }, function (result) {
        // 실패콜백
        if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
      })
    },

    /*********************************************************
    * Function명: fn_SMSBtnClicked
    * 설명: TSSSP201M.TAB에서 "문자전송" 버튼 클릭시
    * Params:  N/A
    * Return: N/A
    *********************************************************/
    fn_SMSBtnClicked () {
      if (this.lv_SelectedItemList.length > 5) {
         this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
         return
      }

      let lv_vm = this
      // 파라미터 설정
      let pParams = {}

      const trnstId = 'txTSSSA01S8'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.startDt !== undefined && response.body.startDt !== '') {
                if (new Date() >= new Date(response.body.startDt) && new Date() <= new Date(response.body.endDt)) {
                  //알럿띄워야됨 종료시키고 pk.son
                  lv_vm.$addSnackbar(response.body.msg)
                } else {
                  //lv_vm.fn_OpenPopCustList()
                  lv_vm.fn_OpenPopSmsCustList()  // 2차반영
                }
              }else{
                // 서버 config 키값이 정의 되어 있지 않더라도 SMS 고객선택 팝업 호출
                //lv_vm.fn_OpenPopCustList()
                lv_vm.fn_OpenPopSmsCustList()  // 2차반영
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_OpenPopCustList
     * 설명: 안내자료_고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenPopCustList() {
      let lv_vm = this
      let itemList = []

      if(this.clickedSingleItem === true){
        itemList.push(this.lv_SelectedSingleItem)
      }else{
        itemList = this.lv_SelectedItemList
      }
      this.popData = this.$bottomModal.open(MSPSA203P,{
        properties: {
          paramData: itemList // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA203P: (pData) => {
            if(pData !== ''){
              
              this.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData}]
              this.fn_OpenPopSmsSendList(itemList)
            }else{
              this.$bottomModal.close(lv_vm.popData)
            }
          }
        }
      })
    },


    /*********************************************************
    * Function명: fn_OpenPopCustList
    * 설명: 수신고객목록 팝업오픈
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenPopSmsSendList(itemList) {
      let lv = this
      this.popData1 = this.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: this.custListObj, // param셋팅
          pSelectedUntySlmtItemList: itemList,
          pIsShowTSSSP128P: true
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupClose: (pData) => {
            if (pData === 'confirm') {
              this.$bottomModal.close(lv.popData)
              this.lv_IsShowMSPCM203P = false
            }
            this.$bottomModal.close(lv.popData1)
          }
        }
      })
    },
    
    /* 2차 오픈시 추가반영   화면 하단 BS 영역부문 관련 프로그램반영   */

    /*********************************************************
     * Function명: fn_SaveToCouncel  (화상상담 자료저장)
     * 설명: 고객목록 팝업에서 고객 선택 후 저장 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록(List<SAGrpInfoSVO>)
     * Return: N/A
     *********************************************************/
    fn_SaveToCouncel() {
     
     console.log('@@@ MSPSA201M >>>  화상상담자료저장 fn_SaveToCouncel START  ')

      this.isNofacCnsl = true

      let parentsViewId = 'MSPSA201M'
      let custList = this.lv_SelectedCustList.slice()
      this.lv_TargetType = 'c'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
      this.custListObj = [
          {targetType: this.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
      ]

      this.fn_AfterCustSearch() // FOM문서 PDF변환 혹은 PDF문서 정보 저장
    },
    /*********************************************************
     * Function명: fn_AfterCustSearch
     * 설명: [비대면화상상담]고객목록 팝업에서 고객 선택 후 다음 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록
     * Return: N/A
     *********************************************************/
    fn_AfterCustSearch() {
      // 다중 선택 후 화상상담 진행시
      this.lv_IsMadeMultiItem = true

      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      this.lv_SelectedPdfList = [] // 기존에 만들어진 PDF파일 담을 목록 초기화
      let lv_vm = this
      // 로딩바 강제 설정
      this.getStore('progress').getters.getState.isShow = true
      console.log('■■■■■ MSPSA201M ■■■■■ 화상상담자료저장 전 선택항목 체크(fn_AfterCustSearch) START ☞ ' + new Date().getTime())

      // 1. FOM 파일만 별도로 추출
      //    FOM 파일은 문서변화 서버를 이용해 PDF로 변환
      // 2. PDF 파일일 경우
      //    'UNTY_SLMT_DIR' 를 명시적으로 지정해 주고
      //    pdfApndFilePathNm에 저장된 'ic/20190219' 와
      //    pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명을 조합하여 파일을 다운로드(.pdf 확장자를 붙이지 않음)
      //    -> 최종경로 : /filedown.do?path=UNTY_SLMT_DIR&filename=ic/20190220/000300336246415221102209102
      console.log('********** MSPSA201M ****** lv_SelectedCnslItemList ☞ ', this.lv_SelectedItemList)

      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn === 'Y') { // PDF 파일
          // 로컬 테스트인 경우
          if (process.env.NODE_ENV === 'local') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              itemObj.eltrnDoctFileNm = item.pdfApndFileId // test용
              lv_vm.lv_SelectedPdfList.push(itemObj)
          } else {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR' // Context.properties에서 PDF 파일이 저장된 위치를 명시한 키값 설정
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              // itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm // PDF 파일일 경우는 확장자 .pdf를 붙이지 않음
              itemObj.pdfFilePathDirNm = item.pdfApndFilePathNm // ic/20190219
              itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm
              lv_vm.lv_SelectedPdfList.push(itemObj)
          }
        } else {
             lv_vm.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      // FOM 파일이 하나 이상 선택되었을 경우는 PDF 변환 진행
      if (lv_vm.lv_SelectedFomItemList.length > 0) {
          lv_vm.fn_CreateToPDFContnt()
      } else { // PDF문서만 선택 되었을 때
          lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
      }
    },

    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담] 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData(docInfo) {

      console.log('***** MSPSA201M *** PDF문서 저장 *** fn_InsNofacCnslData ☞ ', docInfo)
      let lv_vm = this
 
      lv_vm.isNofacCnsl = false // 초기화
      lv_vm.lv_TargetType = 'p' // 초기화
      let pParams = {}
      let ctNofacCnslListVO = []

      for (let i = 0; i < docInfo.length; i++) { // 문서 목록
        for (let j = 0; j < lv_vm.custListObj[0].custList.length; j++) { // 선택 고객 목록
          let pParam = {}
          pParam.nofacChnlCustId = lv_vm.custListObj[0].custList[j].chnlCustId // 비대면채널고객ID
          pParam.nofacCnslBusnScCd = 'SA' // 비대면업무구분코드(안내자료:SA)
          pParam.nofacCnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId // 비대면컨설턴트ID
          pParam.doctDeltYn = ' ' // 문서삭제여부
          pParam.nofacCnslDoctNm = docInfo[i].nofacCnslDoctNm // 비대면상담문서명
          pParam.nofacCnslDoctId = docInfo[i].eltrnDoctFileNm // 비대면상담문서ID
          pParam.nofacCnslDoctPathNm = docInfo[i].eltrnDoctFilePathNm // 비대면상담문서경로명
          pParam.pdfFilePathDirNm = docInfo[i].pdfFilePathDirNm // 기존PDF 파일 저장 경로명 예)ic/20190219
          ctNofacCnslListVO.push(pParam)
        }
      }

      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      console.log('### MSPSA201M [비대면화상상담] 자료 저장 start>>' + pParams.ctNofacCnslListVO)

      const trnstId = 'txTSSCT80S1'
      const auth = 'S'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                if (lv_vm.custListObj[0].custList.length === 1) {    // 화상상담 고객이 2명 이상이면 화상상담 자료목록 팝업 호출 
                  lv_vm.lv_selCust = {}
                  lv_vm.lv_selCust.custNm = lv_vm.custListObj[0].custList[0].custNm
                  lv_vm.lv_selCust.chnlCustId = lv_vm.custListObj[0].custList[0].chnlCustId
                  let age = 0
                  let birthDate = lv_vm.custListObj[0].custList[0].dob
                  let contractDate = moment(new Date().toISOString()).format('YYYYMMDD')
                  let fullMonths = 0
                  if (parseInt(contractDate.substring(0, 4)) < 1988) {
                    fullMonths = DateUtil.getMonthPeriodBySingleCalc(birthDate, contractDate)
                  } else {
                    fullMonths = DateUtil.getPassedMonthCount(birthDate, contractDate)
                  } // end else if
                  let years = parseInt(fullMonths / 12)
                  let months = parseInt(fullMonths % 12)
                  if (months > 5) {
                    age = years + 1
                  } else {
                    age = years
                  } // end else if
                  lv_vm.lv_selCust.age = age + '세' // 보험나이
                  
                  lv_vm.fn_nofacCnslBtnClick() // 고객별 화상상담 목록 팝업 열기   
                } else {
                        console.log('### MSPSA201M [비대면화상상담] 자료 저장 end >>' )
                        // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                        // lv_vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                        lv_vm.fn_nofaeCnsl_tostMesg()                              // 비대면상담 자료 저장을 완료했습니다                      
                }
              }
            } else {
              //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },           
 
    /*********************************************************
     * Function명: fn_OpenPopSmsCustList
     * 설명:  고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A 
     *********************************************************/
    fn_OpenPopSmsCustList() {

      let lv_vm = this
      this.custListObj = []

     lv_vm.lv_Popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
     properties: {
       pChnlCustIds: [
         {targetType: 's',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
     },
     listeners: {
        onPopupClose: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
      },
      onNextService: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
        lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData.selectList}] 
        lv_vm.fn_OpenMSPCM128P()
      },
    }
  })          

  },
    /*********************************************************
    * Function명: fn_OpenMSPCM128P
    * 설명: 수신고객목록 팝업오픈 (문자발송)
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function () {
      let lv_vm = this
      let guidList = []
      // if(this.lv_SelectedItemList.length > 1){
      //   guidList = this.lv_SelectedItemList
      // }else{
      //   guidList.push(this.lv_SelectedItemList)   
      // }  
      
      if(this.clickedSingleItem === true){
        guidList.push(this.lv_SelectedSingleItem)
      }else{
        guidList = this.lv_SelectedItemList
      }


        console.log('lv_vm.custListObj >> ', lv_vm.custListObj)
        console.log('lv_vm.guidList >> ',  guidList)
        lv_vm.lv_Popup128 = lv_vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true
        },
        listeners: {
            // 팝업 닫히면서 실행되는 함수
           onPopupClose: (pData) => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },
             // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },         
      // 터치온 발송 프로세스 async
           onNextService: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
              lv_vm.custListObj = []
              lv_vm.custListObj = pData.rtnData
             // 터치온 팝업 오픈(MSPCM154P)
              lv_vm.fn_OpenMSPCM154P()
           }
        }
      })    
    }, 300),    

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_OpenMSPCM154P: _.debounce(function () {

  let lv_vm = this

  lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
    properties: {
      pPage: lv_vm.custListObj.parentsViewId,                   // 모화면 페이지명
      pSmsTargetCustList: lv_vm.custListObj.smsTargetCustList,  // 수신고객리스트
      pMsg: lv_vm.custListObj.msg,                              // 선택된 상용구
      pIsNotEditable: lv_vm.custListObj.isNotEditable           // 메시지 수정가능여부
    },
    listeners: {
      onPopupClose: (pData) => { 
        if (pData.str === 'confirm') {
          lv_vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
        }
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
      }
    }
  })
  } , 300),      


  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  고객선택 수신 팝업오픈-이메일
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {

      if (this.lv_SelectedItemList.length > 5) {
           this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
           return
      } 

      let lv_vm = this
      this.custListObj =  [
         {targetType: 'e',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
      let selectPDFfile = []

      lv_vm.lv_popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
      properties: {
      //   pChnlCustIds: [
      //    {targetType: 'e',
      //     parentsViewId: 'MSPSA201M',
      //     custList: []
      //    }
      //  ],
      pChnlCustIds: lv_vm.custListObj,
        pPDFfile : selectPDFfile
      },
      listeners: {
        onPopupClose: (pData) => {
          // 모달 닫기
          lv_vm.$bottomModal.close(lv_vm.lv_popup152)
        },
        onNextService: async (pData, selectPDFfile) => {
         // 중복제거
          let custList = []    // 문자수신 고객 list
          let cnt = 0
  
          if (pData.selectList.length > 0) {
            for (let i = 0; i < pData.selectList.length; i++) { 
              cnt = 0
              if (i === 0) {
                custList.push(pData.selectList[i])
              } else {
                for (let j = 0; j < custList.length; j++) {
                  if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                    cnt++
                  }
                }
                if (cnt === 0) {
                  custList.push(pData.selectList[i])
                }
              }
            }
          }

          lv_vm.custListObj = [{targetType: 'e', parentsViewId: 'MSPSA201M', custList: custList}]  
          selectPDFfile = lv_vm.lv_SelectedItemList
          let lv_pTitle = '삼성생명' 
          let lv_pText = '고객님 안녕하세요.\n' +
                          '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                          '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                          '오늘도 즐거운 하루 되세요!'  
          let lv_pDataModify = true                
          if (lv_vm.custListObj[0].custList.length > 0) {
            // 모달 닫기
            await lv_vm.$bottomModal.close(lv_vm.lv_popup152)
            // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
            await lv_vm.$cmUtil.fn_OpenMSPCM191P(lv_vm, lv_vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
          }
        },
      }
     })
    },
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked
     * 설명: 인쇄
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (this.lv_SelectedItemList.length === 0) {  
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
      } else  { 
              this.pIsSelectedMultiItem = false
              this.selectItems = [] // 초기화
              this.selectItems = this.lv_SelectedItemList
              this.lv_SelectedItemListTmp = [] // 초기화
              // this.selectItems.forEach(item => {
              //     // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
              //     // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
              //    item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              // })
              this.lv_SelectedItemListTmp = this.selectItems.slice(0)              
              if(this.lv_SelectedItemListTmp.length > 1) {
                 this.pIsSelectedMultiItem = true
               }
               this.fn_OzPrintCntntList()
      }
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked()
     * 설명: 선택 자료 목록 인쇄 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzPrintCntntList() {
      console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList START...☞ ' + new Date().getTime())
      let lv_vm = this

      let tempUntySlmtItemList = []
      // 다중 선택일 경우 선택 자료 목록을 파라미터로 설정
      // if (lv_vm.pIsSelectedMultiItem) {
      //   tempUntySlmtItemList = lv_vm.lv_SelectedItemList
      // } else { // 단일 선택일 경우 선택 자료를 파라미터로 설정
      //   tempUntySlmtItemList.push(lv_vm.lv_SelectedItemList)
      // }
      tempUntySlmtItemList = lv_vm.lv_SelectedItemListTmp

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        tempUntySlmtItemList.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        pblNos: lv_vm.lv_SpinValue,                 // 기본 1 매 설정 
        sACntntBasInfoSVO: tempUntySlmtItemList,
        cnsltInfoIcluYN: this.lv_IsIncludeCnsltInfo  // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      // const trnstId = 'txTSSSA11P1'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( lv_vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                  console.log('@@@ MSPSA201M   인쇄  fn_OzPrintCntntList this.post START ☞ ' + new Date().getTime())
              if (response.body.sACntntSVO.length === lv_vm.selectItems.length) {
              // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                  lv_vm.fn_printtostMesg()                              // 인쇄처리 완료 메세지  
                //lv_vm.$emit('evtCloseTSSSA011P', true)
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList END ☞ ' + new Date().getTime())
        })
        .catch(function (error) {
          window.vue.error(error)
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList Error ☞ ' + new Date().getTime())
        })
    },            
   /*********************************************************
     * Function명: fn_OpenMSPSA210P
     * 설명:  스크랩팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA210P() {
            console.log("MSPSA201M-  fn_OpenMSPSA210M  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup210 = this.$bottomModal.open(MSPSA210P,{
        properties: {
          pPage: 'MSPSA201M',
          pSelectedUntySlmtItems: this.selectItems.slice() // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA210P: (pData) => {
                if(pData !== ''){              
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }else{
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }
            }
         }
        })
      } 
    }, 
   /*********************************************************
     * Function명: fn_OpenMSPSA222M
     * 설명:  화상상담팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA222P() {
            console.log("MSPSA201M-  fn_OpenMSPSA222P  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList.slice()
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup222 = this.$bottomModal.open(MSPSA222P,{
        properties: {
          pPage: 'MSPSA201M',
          pTargetType:  lv_vm.lv_TargetType,
          pSelectedCustList: [],
          pSearchCustNm: ''
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            onPopupConfirm222P: (pData) => {
                if(pData !== ''){      
                  console.log("MSPSA201M-  onPopupConfirm222P  return1 " , pData)
                   lv_vm.lv_SelectedCustList = pData
                   lv_vm.fn_SaveToCouncel()
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)                           
                }else{
                  console.log("MSPSA201M-  onPopupConfirm222P  return2 ")
                   lv_vm.lv_SelectedCustList = []
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)
                }
            },
            onPopupClose222P: () => {
                   this.$bottomModal.close(this.lv_Popup222);
            }
         }
        })
      } 

    }, 
        

    /******************************************************************************
     * Function명 : fn_nofacCnslBtnClick
     * 설명       : 화상상담자료조회popup호출 - MSPSA063P
     ******************************************************************************/
    fn_nofacCnslBtnClick() {
              // 최대 30명까지 선택하도록 제한한다.
      const LIMITED_SMS_SELECTED = 30
      var lv_vm = this

      if (this.lv_SelectedCustList.length > LIMITED_SMS_SELECTED) {
          window.fdpbridge.exec('ToastPlugin', {message: '최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else if (this.lv_SelectedCustList.length === 0) {
          window.fdpbridge.exec('ToastPlugin', {message: '선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else {
        // 화상상담자료조회popup호출
          this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                properties: {
                  pNofacCnslCust: lv_vm.custListObj[0].custList[0]    //this.lv_SelectedCustList[0]    
                },
                listeners: {
                  onPopupConfirm: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  },
                  onPopupClose: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  }
                }
              },
              {
                properties: {
                  noHeader: true
                }
              })
      }        
    },  
    /*********************************************************
    * Function명: fn_print_BottomSheet_XXXX
    * 설명: 인쇄 처리용 버텀 시트 제어
    * Params: N/A
    * Return: N/A
    *********************************************************/
    open() { 
      // this.$refs.bs10.open(); 
    },
    close() { 
      // this.$refs.bs10.close(); 
    },     
    fn_nofaeCnsl_tostMesg(){
        //this.$refs.stackableSnackbar.show({text:'비대면상담 자료를 저장하였습니다.'});
        this.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_printtostMesg(){
        //this.$refs.stackableSnackbar.show({text:'인쇄되었습니다.'});
        this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_print_BottomSheet_open() { 
      if (this.lv_SelectedItemList.length < 1) { 
          //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', '인쇄할 자료를 선택하여 주십시요.')
      } else {
          this.close()
          this.$refs.print_bottomSheet.open();
      }    
    },
    fn_print_BottomSheet_close() { 
      this.$refs.print_bottomSheet.close();
      this.open()
    }, 
    /*********************************************************
     * Function명: fn_GetWelcomeType
     * 설명: 귀점 체크
     *********************************************************/
    async fn_GetWelcomeType(typeValue,clicked) {
      if (typeValue !== 'refresh element') return
      
      console.log("@@@@@@@@@@@@@@@@ fn_GetWelcomeType @@@@@@@@@@@")
      this.lv_clicked = clicked
      let lv_Vm = this
      let userInfo = this.getStore('userInfo').getters.getUserInfo
      let currentTime = ''
      /*********************************************************
      * 설명: 서버 시간 추출
      *********************************************************/
      await this.$commonUtil.getServerTime().then(response => {
        if (!response) return
        currentTime = response.substr(11, 5) // hh:mm
        console.log("@@@@@@@@@@  time  >> "+currentTime)
      })
      
      lv_Vm.lv_welcomeType = ('00:00' <= currentTime && currentTime <= '10:30') ? 1
                          : ('16:00' <= currentTime && currentTime <= '23:59') ? 2
                          : 0    
      
      let promiseGps =  await this.fn_GpsInfo(lv_Vm, userInfo)
      console.log("@@@@@@@@@@  promiseGps  >>>>> "+promiseGps);
      let promiseLogin =  await this.fn_loginInfo(lv_Vm, userInfo)
      console.log("@@@@@@@@@@  promiseLogin  >>>>> "+promiseLogin);
      
    },
    fn_GpsInfo(lv_Vm, userInfo){
      return new Promise((resolve, reject) =>{
        if(localStorage.getItem('deviceType') == 'Phone' && (lv_Vm.lv_welcomeType == 1 || lv_Vm.lv_welcomeType == 2)){
          /*********************************************************
          * 설명: gps정보 유무
          *********************************************************/
          window.fdpbridge.exec('getOfficeCheckinCheckoutPlugin', {},(lv_success) => {                          
            let jsonObj = JSON.parse(lv_success.data)
            console.log('   @@@@@@ 조회/귀점 @@@@@')
            console.log('   @@@@@@ [getOfficeCheckinCheckoutPlugin]')
            console.log('   @@@@@@ myLat @@@@', jsonObj.myLat)
            console.log('   @@@@@@ myLng @@@@', jsonObj.myLng)

            if(jsonObj.myLat != '' && jsonObj.myLng != ''){
              console.log('@@@@ gpsData @@@@@', jsonObj.myLat+ "  / "+ jsonObj.myLng)
              /*********************************************************
              * 설명: 지점 간 거리 추출
              *********************************************************/
              let plv_Vm = lv_Vm
              let pTrnstId = 'txTSSBC91S1'
              let pParams = {
                userEno : userInfo.userEno,
                welcomeType : lv_Vm.lv_welcomeType,
                orgNo     : userInfo.onpstOrgKndCd,
                hofOrgNo  : userInfo.onpstHofOrgNo,
                fofOrgNo  : userInfo.onpstFofOrgNo,
                comtPosx  : jsonObj.myLng,
                comtPosy  : jsonObj.myLat
              }
              
              console.log('@@@@ trnstId @@@@@', pTrnstId)        
              console.log('@@@@ pParams @@@@@', pParams)        
              lv_Vm.post(lv_Vm, pParams, pTrnstId).then(response =>  {
                if (response.body !== null && response.body !== '') {
                  plv_Vm.lv_gpsChkYn = 'Y'
                }else{
                  plv_Vm.lv_gpsChkYn = 'N'
                }
                
                console.log("@@@@@@@@@@  gpsChkYn  >> "+plv_Vm.lv_gpsChkYn)
              }).catch(function (error) {
                console.log("@@@@@@@@@@  txTSSBC91S1 error  >>")
                window.vue.error(error)
                reject('txTSSBC91S1 gps_check reject')
              })
            }
          })
        }
        resolve('txTSSBC91S1 gps_check')
      })
    },
    fn_loginInfo(lv_Vm, userInfo){
      return new Promise((resolve, reject) =>{
        /*********************************************************
        * 설명: 로그인 이력 정보 추출
        *********************************************************/
        let trnstId = 'txTSSBC91P1'
        let params = {
          userEno : userInfo.userEno,
        }
        let setTime = 0
        
        if(lv_Vm.lv_clicked){
          if(lv_Vm.lv_goodMorning == 'refresh element' || lv_Vm.lv_success == 'refresh element'){
            lv_Vm.lv_afterLogin = true
            lv_Vm.rotateRefresh()
          }
          setTime = 500
        }

        setTimeout(() => {
          lv_Vm.post(lv_Vm, params, trnstId).then(response =>  {
            if (response.body !== null && response.body !== '') {
              lv_Vm.lv_loginData = response.body
              
              if((lv_Vm.lv_loginData.inDataTp == 'Y' || lv_Vm.lv_loginData.inDataPhone == 'Y')){
                lv_Vm.lv_loginWelcomeYn = true
              }
              if((lv_Vm.lv_loginData.outDataTp == 'Y' || lv_Vm.lv_loginData.outDataPhone == 'Y' || lv_Vm.lv_loginData.cnclnData == 'Y')){
                lv_Vm.lv_loginWelcomeBackYn = true
              }

              if(lv_Vm.lv_welcomeType == 1 || lv_Vm.lv_welcomeType == 0){
                if(lv_Vm.lv_loginWelcomeYn){
                  lv_Vm.lv_goodMorning = 'on'
                }else{
                  if(lv_Vm.lv_welcomeType == 1){
                    lv_Vm.lv_goodMorning = 'refresh element'
                  }else{
                    lv_Vm.lv_goodMorning = 'off'
                  }
                }
              }else if(lv_Vm.lv_welcomeType == 2){
                if(lv_Vm.lv_loginWelcomeYn && !lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'on'
                  lv_Vm.lv_success = 'refresh element'
                }else if(!lv_Vm.lv_loginWelcomeYn && lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'off'
                  lv_Vm.lv_success = 'on'
                }else if(lv_Vm.lv_loginWelcomeYn && lv_Vm.lv_loginWelcomeBackYn){
                  lv_Vm.lv_goodMorning = 'on'
                  lv_Vm.lv_success = 'on'
                }else{
                  lv_Vm.lv_goodMorning = 'off'
                  lv_Vm.lv_success = 'refresh element'
                }
              }
              //로그인 이력이 없을 경우 새로고침 클릭했을 때
              if((lv_Vm.lv_goodMorning === 'refresh element' || lv_Vm.lv_success === 'refresh element') && lv_Vm.lv_afterLogin ){
                lv_Vm.fn_openSnackbar()
              }
            }
          }).catch(function (error) {
            window.vue.error(error)
            reject('txTSSBC91P1 loginInfo reject')
          }) 
        }, setTime)
        resolve('txTSSBC91P1 loginInfo')
      })
    },
    /*********************************************************
     * Function명: fn_openSnackbar
     * 설명: 신호등 새로고침 클릭 시 하단 팝업 생성
     *********************************************************/
    fn_openSnackbar() {
      let ms = 3000
      const element = this.$refs.homeSnackbar
      
      if(element.classList[1] == 'on'){
        clearTimeout(this.lv_setTimeOutVal)
        element.classList.remove('on')
        
        setTimeout(() => {
          this.lv_snackbarOpen = true
          element.classList.add('on')
        }, 100);
      }else{
        this.lv_snackbarOpen = true
        element.classList.add('on')  
      }

      if(localStorage.getItem('deviceType') == 'Tablet'){
        this.lv_snacbarText = this.lv_tabletSnacbarText
      }else if(localStorage.getItem('deviceType') == 'Phone'){
        this.lv_snacbarText = this.lv_phoneSnacbarText  
      }

      this.lv_setTimeOutVal = setTimeout( this.fn_openSnackbarSetTime, ms)
    },
    /*********************************************************
     * Function명: fn_openSnackbarSetTime
     * 설명: 신호등 새로고침 클릭 시 실행되는 timeOut 함수
     *********************************************************/
    fn_openSnackbarSetTime(){
      const element = this.$refs.homeSnackbar
      element.classList.remove('on') 
      this.lv_snackbarOpen = false
    },
    /*********************************************************
     * Function명: fn_ScrollBefore
     * 설명: scroll 위치 복원
     *********************************************************/
    fn_ScrollBefore() {
      this.beforeScrollTop && this.$refs.home.scrollTo(this.beforeScrollTop)
    },
    /*********************************************************
     * Function명: fn_OpenScreen
     * 설명: 열린화면 팝업 열기
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenScreen () {
      this.getStore('progress').dispatch('NONE')
      let lv_Vm = this
      lv_Vm.OpenScreen = lv_Vm.$bottomModal.open(OpenScreenBottomSheet, {
        listeners: {
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.OpenScreen)
          },
          onScreenMove: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.OpenScreen)
            if(pData?.rtnData) {
              if(pData.rtnData.mobslSrnId !== 'MSPSA071M' && pData.rtnData.mobslSrnId !== 'MSPPI001M') {
                lv_Vm.$router.push({name:pData.rtnData.mobslSrnId, params: {}, query: {_isMenuItem: true}})
              }else {
                lv_Vm.$router.push({name:pData.rtnData.mobslSrnId, params: {}})
              }
            }
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_CloseOpenScreenTooltip
     * 설명: 열린화면 아이콘 툴팁 닫기
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CloseOpenScreenTooltip() {
      localStorage.setItem('READ_OPENSCREEN_TOOLTIP_MAIN_BMNGR', true)
      this.lv_ReadOpenScreenTooltip = true
    },
    /*********************************************************
     * Function명: fn_TelSrvcUserChk
     * 설명: 전화받기서비스 사용대상 체크(AOS + PHONE - 예외: 갤럭시 폴드)
     *********************************************************/
    async fn_TelSrvcUserChk(chk) {
      console.log("chk", chk)

      let userChk = ''

      if(chk !== 'Y') {
        if(this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P001') ||
          this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P002')) {
          userChk = await this.$bizUtil.telSrvcUserChk()
        }
      } else {
        userChk = await this.$bizUtil.telSrvcUserChk()
      }
      this.fn_srvcStart(userChk)
    },

    /*********************************************************
     * Function명: fn_srvcStart
     * 설명: 전화받기서비스 start 또는 사용여부 팝업
     *********************************************************/
    fn_srvcStart(userChk) {
      console.log("userChk", userChk)
      if(userChk === true) {      
        let lv_vm = this
        // 디바이스 토큰 설정
        lv_vm.fn_SetDeviceToken()

        let dateChk = moment(new Date()).format('YYYYMMDDHHmm')

        // 전화수신서비스 팝업
        if(dateChk >= '202501220830' && !localStorage.getItem('OPEN_TUTO')) {
        // if(!localStorage.getItem('OPEN_TUTO')) {
          lv_vm.fn_OpenTutoPopUp()
        } else {
          //DB 값 Y면 서비스 시작
          if(localStorage.getItem('TEL_SRVC_YN') === 'Y') {
            window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {            
              console.log("callCustInfoStartPlugin success ", result)
            },(error) => {
              lv_vm.fn_SaveSysInfo('N')
              console.log('callCustInfoStartPlugin error', error)
            })
          }
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_OpenAlert
     * Return     : 바텀얼럿
     ******************************************************************************/
    fn_OpenAlert() {
      let lv_vm = this

      localStorage.setItem('OPENPOPUP_TELSRVC', true)

      const popup661 = this.$bottomModal.open(MSPBC661P, {        
        properties: {
        },
        listeners: {
          onPopupConfirm: () => {
            lv_vm.$bottomModal.close(popup661)

            // 디바이스백키 활성화
            window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})

            window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
              // 성공콜백
              lv_vm.fn_SaveSysInfo('Y')
              console.log("callCustInfoStartPlugin success ", result)
            },(error) => {
              lv_vm.fn_SaveSysInfo('N')
              console.log('callCustInfoStartPlugin error', error)
            })
          },
          onPopupClose: () => {
            this.$bottomModal.close(popup661)

            // 디바이스백키 활성화
            window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
            
            window.fdpbridge.exec('callCustInfoStopPlugin', {}, function (result) {
              // 성공콜백
              console.log("callCustInfoStopPlugin success", result)
            },(error) =>{
              console.log('callCustInfoStopPlugin error', error)
            })
            this.fn_SaveSysInfo('N')
          },
          onPopupExit: () => {
            this.$bottomModal.close(popup661)

            if(localStorage.getItem('TEL_SRVC_YN') === 'Y') {
              window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
                // 성공콜백
                lv_vm.fn_SaveSysInfo('Y')
                console.log("callCustInfoStartPlugin success ", result)
              },(error) => {
                lv_vm.fn_SaveSysInfo('N')
                console.log('callCustInfoStartPlugin error', error)
              })
            }
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenTutoPopUp
     * Return     : 튜토리얼 팝업
     ******************************************************************************/
    fn_OpenTutoPopUp() {
      let lv_vm = this
      let rtn = false
      localStorage.setItem('OPEN_TUTO', true)

      const popup662 = this.$bottomModal.open(MSPBC662P, {        
        properties: {},
        listeners: {
          onPopupConfirm: () => {
            lv_vm.$bottomModal.close(popup662)

            // 디바이스백키 활성화
            window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})

            window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
              // 성공콜백
              lv_vm.fn_SaveSysInfo('Y')
              console.log("callCustInfoStartPlugin success ", result)
            },(error) => {
              lv_vm.fn_SaveSysInfo('N')
              console.log('callCustInfoStartPlugin error', error)
            })
          },
          onPopupClose: () => {
            lv_vm.$bottomModal.close(popup662)

            // 디바이스백키 활성화
            window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
            
            window.fdpbridge.exec('callCustInfoStopPlugin', {}, function (result) {
              // 성공콜백
              console.log("callCustInfoStopPlugin success", result)
            },(error) =>{
              console.log('callCustInfoStopPlugin error', error)
            })
            lv_vm.fn_SaveSysInfo('N', 'popup')
          },
          onPopupExit: () => {
            lv_vm.$bottomModal.close(popup662)

            // 디바이스백키 활성화
            window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
            
            if(localStorage.getItem('TEL_SRVC_YN') === 'Y') {
              window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
                // 성공콜백
                lv_vm.fn_SaveSysInfo('Y')
                console.log("callCustInfoStartPlugin success ", result)
              },(error) => {
                lv_vm.fn_SaveSysInfo('N')
                console.log('callCustInfoStartPlugin error', error)
              })
            }
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_SaveSysInfo
     * Return     : 전화받기서비스 사용여부 저장
     ******************************************************************************/
    fn_SaveSysInfo(value, popup) {

      let lv_Vm = this
      const trnstId = 'txTSSBC20U5'
      const auth = 'U'
      let pParams = {}

      pParams.mobslTelReciSrvcYn = value
      
      this.post(lv_Vm, pParams, trnstId, auth, {}, true)
          .then(function(response) {
            if(value === 'Y') {
              /**
               * 세션정보 유무에 따라서 처음 화면에서 진입 여부 설정함.
               *  */       
              let telInfo = lv_Vm.getStore('bcStore').getters.getState.telInfo
              let telSession = lv_Vm.getStore('bcStore').getters.getState.telSession
              console.log('telSession', telSession)
              if(telSession !== undefined && telSession === false) {
                lv_Vm.getStore('bcStore').dispatch('TEL_SESSION', true)
                lv_Vm.$router.push({name: 'MSPCM270M', params: {callerId:"MSPBC500M", viewId: 'MSPCM271D', chnlCustId: telInfo.chnlCustId, cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId}})
              }
            }
            setTimeout(() => {
                if(popup === 'popup') {
                  lv_Vm.fn_BottomAlert("미사용 설정 되었습니다.<br/><br/>사랑On > 설정 화면에서 <br/>언제든지 다시 설정 가능합니다.")
                }
            }, 500);  
            console.log("전화받기서비스 사용여부 저장 ok", value)
          })
    },
    /*********************************************************
     * Function명: fn_SetDeviceToken
     * 설명: 전화받기용 디바이스 토큰 저장
     *********************************************************/
    async fn_SetDeviceToken() {      
      console.log('fn_SetDeviceToken')
      let locDeviceToken = localStorage.getItem('device_token') ? localStorage.getItem('device_token') : ''
      console.log('locDeviceToken', locDeviceToken)
      // 로그인 시 최초 등록, 디바이스 토큰 값 확인 후 없을 경우 등록
      if(!locDeviceToken) {
        this.lv_recLogInDeviceToken = true
        let deviceToken = await this.$commonUtil.getDeviceIDPlugin(this)
        let curTime = moment(new Date()).format('YYYYMMDDHHmm')
        deviceToken += String(curTime)        
        if (deviceToken) {          
          try {            
            let deviceTokenEnc =  await this.$commonUtil.getEncryptCall(this, [deviceToken])
            if(deviceTokenEnc?.length) {
              console.log('getDeviceIDPlugin deviceTokenEnc :', deviceTokenEnc[0])
              localStorage.setItem('device_token', deviceTokenEnc[0])
              localStorage.setItem('device_token_time', curTime)
              window.fdpbridge.exec('AsyncStorageSetPlugin', {key: 'deviceToken', data: deviceTokenEnc[0]}, function (result) {}, function (result) {})              

              // 최초 세션 정보 등록 시 로그 정보 기록
              this.$bizUtil.insSrnLog(this.$options.screenId)
              this.lv_recLogInDeviceToken = false
            }
          } catch (error) {
            console.error('deviceToken encription - error:', error)
          }          
        }
      }
    },
    /*********************************************************
     * Function명: fn_ConfigProcss
     * 설명: 전화받기서비스 컨피그 확인
     *********************************************************/
    async fn_ConfigProcss () {
      console.log('fn_ConfigProcss')
      const lv_Vm = this
      let pParams = {
        envId: 'PILOT_SRVC_YN'
      }
      const trnstId = 'txTSSAD02S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(response => {
          if (response.body !== null && response.body.returnDtlList.length > 0) {            
            lv_Vm.fn_TelSrvcUserChk(response.body.returnDtlList[0].envCntnt)
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })
    },
    // 코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End(){return}
  }
};
</script>
<style scoped>
.mo-system ::v-deep .mo-bottom-navigation {
  filter: none !important;
  border-top: none !important;
}
</style>