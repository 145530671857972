import { render, staticRenderFns } from "./MSPPI250P.vue?vue&type=template&id=620c8a1e&scoped=true&"
import script from "./MSPPI250P.vue?vue&type=script&lang=js&"
export * from "./MSPPI250P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620c8a1e",
  null
  
)

export default component.exports