/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI803P
 * 화면설명: 이전 질병정보
 * 접근권한: 
 * 작 성 일: 2024.01.08
 * 작 성 자: 
 */
<template>
  <ur-page-container ref="page" class="msp btn-back-clear" title="이전 질병정보" :show-title="false" type="popup" :topButton="true" :topButtonBottom="150"> <!-- type="popup" -->
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '이전 질병정보' : '이전 질병정보'}}</div>
              <div slot="action">
                <span><mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_HistoryBack()">close</mo-icon></span>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <!-- <ur-box-container direction="column" alignV="start" class="msp-pi-wrap"> -->
              <!-- <ur-box-container direction="column" alignV="start" class="pa2024"> 
                <div class="fexTy5">
                  <span class="fs17rem">사실과 다른 경우 향후 보장이 제한될 수 있으므로, 수정사항이 있을 시 확인 후 수정해주세요.</span> 
                </div>
              </ur-box-container> -->
            <!-- </ur-box-container> -->
            <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
              <ur-box-container direction="column" alignV="start" class="pa2024 bgcolor-1"> 
                <div class="fexTy5">
                  <span class="fs14rem crTy-bk7">사실과 다른 경우 향후 보장이 제한될 수 있으므로, 수정사항이 있을 시 확인 후 수정해주세요.</span> 
                </div>
              </ur-box-container>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum bd-T-Ty1">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{lv_PreDiseInfoList.length}}</strong> 건</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <ur-box-container v-if="lv_PreDiseInfoList.length > 0" alignV="start" componentid="" direction="column" class="ns-radio-list2 pb82">
              <mo-list :list-data="lv_PreDiseInfoList">
                <template #list-item="{item}">
                  <mo-list-item :class="{'on' : lv_DsclsId == item.planId}">
                    <mo-radio v-model="lv_DsclsId" :value="item.planId"></mo-radio>
                  <!-- <mo-list-item :class="{'on' : lv_DsclsId == item.dsclsId}">
                    <mo-radio v-model="lv_DsclsId" :value="item.dsclsId"></mo-radio> -->
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="name">{{item.prdtNm}}</span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="full fexTy2 hauto"><span class="fs14rem ml0 info_w72">설계명</span><span class="flex-1 hauto line-h crTy-bk1">{{item.planNm}}</span></span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="full hauto">
                          <span class="fs14rem ml0 info_w72">선심사</span>
                          <span class="dsD hauto">
                            <mo-badge class="badge-sample-badge sm" text="" shape="status" :class="fn_SetClass(item.dsclsJdbPrgStatCd)">{{item.dsclsJdbPrgStatCdNm}}</mo-badge>
                            <div class="dsD crTy-bk1">{{item.dsclsId}}</div>
                          </span>
                        </span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="full"><span class="fs14rem ml0 info_w72">설계번호</span><span class="crTy-bk1">{{item.planId}}</span></span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="full"><span class="fs14rem ml0 info_w72">저장일</span><span class="crTy-bk1">{{item.hasOwnProperty('avlStrYmd') ? $bizUtil.dateDashFormat(item.avlStrYmd.replace(/-/g, '').substr(0,8)) : ''}}</span></span>
                      </div>
                      
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>   
            </ur-box-container>
            <!-- NoData 영역: start  -->
              <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info small-space">
                      <span class="ns-ftcr-gray"><p class="mt10">유효기간(3개월) 내 작성된<br/>이전 질병정보가 없습니다.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <!-- NoData 영역: end  -->
            <!-- Content 영역 end -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_HistoryBack('cancle')">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CommAlert">적용</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Gaf from '@/ui/pi/common/Gaf'
// import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPI803P",
  screenId: "MSPPI803P",
  isBackKeyHandle: true,
  components: {
  },
  props: {
    diseInfo: Object
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_HeaderviewScrollCompID: '',
      lv_DiseInfo: {}, // props로 받은 data
      lv_PreDiseInfoList: [], // 이전 질병정보조회 List
      lv_DsclsId: '', // 선택한 선심사번호
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  destroyed () {
  },
  created() {
    let lv_vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    
  },
  mounted() {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    window.vue.getStore('progress').dispatch('FULL')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    if (Object.keys(this.diseInfo).length > 0) {
      this.lv_DiseInfo = this.diseInfo
    }
    this.fn_Init()
  },
  methods: {
    /******************************************************************************
    * Function명 : fn_SetClass
    * 설명       : 심사결과 클래스 처리 함수
    ******************************************************************************/
    fn_SetClass (statCd, type) {
      // 심사필요	30
      // 심사중	40
      // 미결	50
      // 미결(임시저장)	51
      // 거절	60
      // 중지	70
      // 재설계필요	80
      // 심사완료	90
      switch (statCd) {
        case '90': // 심사완료
          return !_.isEmpty(type) ? '심사완료' : 'deepblue'
        case '10': // 작성중
          return !_.isEmpty(type) ? '작성중' : 'blue'
        case '40': // 심사중
          return !_.isEmpty(type) ? '심사중' : 'green'
        case '30': // 심사필요
          return !_.isEmpty(type) ? '심사필요' : 'orange'
        case '50': // 미결
          return !_.isEmpty(type) ? '미결' : 'red3'
        case '60': // 거절
          return !_.isEmpty(type) ? '거절' : 'darkgray'
        case '80': // 재설계필요
          return !_.isEmpty(type) ? '재설계필요' : 'black'
        default:
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack (type) {
      console.log(type)
      if (_.isEmpty(type) || type === 'close') {
        this.$emit('closePopup')
      } else if (type === 'cancle') {
        this.fn_CommAlert(type)
      }
    },
    /******************************************************************************
     * Function명 : fn_CommAlert
     * 설명       : 안내 메시지 처리
     ******************************************************************************/
    fn_CommAlert (type) {
      let alertMsg = ''
      let btnSingle = true
      let preventTouchClose = true
      let title_neg_btn = ''
      let title_pos_btn = ''
      let showCloseBtn = true
      if (type === 'cancle') {
        alertMsg = '이전 질병정보를 불러오지 않고 진행하시겠습니까?'
        btnSingle = true
        showCloseBtn = false
        title_pos_btn = '확인'
      } else {
        if (_.isEmpty(this.lv_DsclsId)) {
          piCommonUtil.getFDPConfirm('s', '불러올 질병정보를 선택하시기 바랍니다.')
          return
        }
        alertMsg = '질병정보 불러오기 시 기 작성된 사전정보, 질병정보는 모두 초기화 됩니다. 진행하시겠습니까?'
        btnSingle = false
        title_neg_btn = '취소'
        title_pos_btn = '확인'
      }
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          // type: 'B',
          type: 'A',
          single: btnSingle,
          content: alertMsg,
          title_neg_btn: title_neg_btn,
          title_pos_btn: title_pos_btn,
          showCloseBtn: showCloseBtn,
          title: '',
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            if (type === 'cancle') {
              this.fn_HistoryBack('close')
            } else {
              this.fn_Confirm()
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          },
          onPopupCancel: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      },
      {
        properties: {preventTouchClose : preventTouchClose}
      })
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 오픈 시 초기화 함수
     ******************************************************************************/
    fn_Init() {
      // 이전 질병정보 조회 함수 호출
      this.fn_SelPreDiseInfo()
    },
    /******************************************************************************
     * Function명 : fn_SelPreDiseInfo
     * 설명       : 이전 질병정보 조회 함수
     ******************************************************************************/
    fn_SelPreDiseInfo () {
      window.vue.getStore('progress').dispatch('SHOW')
      let param = {
        // 이전질병정보DTO
        pastDiseInfoDto: {
          custId: this.lv_DiseInfo.custId, // 고객ID
          formId: this.lv_DiseInfo.formId, // 폼ID
          clctCnsltNo: this.lv_DiseInfo.clctCnsltNo, // 모집컨설턴트번호
          pastDiseListDto: [{
            planId: this.lv_DiseInfo.planId
          }]
        }
      }
      piCommonUtil.invoke('UF1PQL0479', param, this.fn_ResSelPreDiseInfo, null, this.fn_ResSelPreDiseInfo, this, this)
    },
    /******************************************************************************
     * Function명 : fn_SelPreDiseInfo
     * 설명       : 이전 질병정보 조회 함수
     ******************************************************************************/
    fn_ResSelPreDiseInfo (returnData) {
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        if (returnData.pastDiseInfoDto !== null && returnData.pastDiseInfoDto !== '') {
          if (returnData.pastDiseInfoDto.hasOwnProperty('pastDiseListDto')) {
            this.lv_PreDiseInfoList = returnData.pastDiseInfoDto.pastDiseListDto !== null ? returnData.pastDiseInfoDto.pastDiseListDto : []
            if (this.lv_PreDiseInfoList.length === 0) {
              // piCommonUtil.getFDPConfirm('s', '유효기간(3개월) 내 작성된 이전 질병정보가 없습니다.')
            }
          }
        }
      } else {
        piCommonUtil.getFDPConfirm('s', '조회시 오류가 발생하였습니다.')
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 선택한 질병정보 보내기
     ******************************************************************************/
    fn_Confirm () {
      let outData = {}
      if (!_.isEmpty(this.lv_DsclsId)) {
        outData = this.lv_PreDiseInfoList.find(item => item.planId === this.lv_DsclsId)
        // outData = this.lv_PreDiseInfoList.find(item => item.dsclsId === this.lv_DsclsId)
      }
      this.$emit('evtConfirm', outData)
    },
  },
}
</script>
