<template>

  <ur-page-container class="msp myCallHistory" :show-title="true" title="전화활동" type="page" @on-header-left-click="fn_back" :action-type="actionSlot" :customer="fcData.label" :customerID="fcData.key">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1">
              <!--datepicker-->
              <ur-box-container alignH="center" componentid="" direction="row" class="point-date-wrap myCallHistoryCalenWrap"> 
                <mo-icon icon="msp-arrow-pre" @click="fn_date('pre')">msp-arrow-pre</mo-icon>
                <div class="monthDateOnlyValue">{{getMonthDateValue}}</div><!-- 2024.10.07 :chan -->
                <mo-date-picker class="full monthDateOnlyPicker" init="today" v-model="dateValue" :input-Type="'YYYYMMDD'" :model-type="'YYYYMMDD'"/><!-- 2024.10.07 :chan -->
                <!-- <mo-month-picker class="full" placeholder="YY-MM" v-model="srchDate"/> -->
                <mo-icon icon="arrow-ttl" v-if="dateValue < todayDate" @click="fn_date('next')">arrow-ttl</mo-icon>
              </ur-box-container>
              <!--//datepicker-->
              <div class="myCallHistoryTotalWrap">총<span class="myCallHistoryTotal">{{ todayCnt }}</span>건</div>
              <template v-if="callList.length > 0">
                <mo-list-item v-for="(item,idx) in callList" :key="idx"> 
                  <template>
                    <ur-box-container alignV="start" componentid="" direction="column" class="round-box itemBox" :class="{'bgcolor-1' : item.SR === '발신', 'bgcolor-blue' : item.SR === '수신'}">
                      <div class="myCallHistoryNameWrap">
                        <span class="myCallHistoryName">{{item.custNm}}</span>
                        <div class="myCallHistoryBtnWrap" v-if="mngSearch">
                          <mo-button class="blackWH myCallHistoryBtnModify" @click="fn_memoSave(item)">{{item.memo ? '수정' : '입력' }}</mo-button>
                          <mo-icon icon="msp-close-bold" class="myCallHistoryBtnClose"  @click="fn_BottomConfirm('삭제하시겠습니까?', item.CheckSn, item.chnlCustId )">msp-close-bold</mo-icon>
                        </div>
                      </div>
                      <div class="mt5">
                        <span class="myCallHistorySR" :class="{'myCallHistorySRBlue':item.SR==='수신'}">{{item.SR}}</span>
                        <span class="myCallHistoryTime">{{item.CntcCntnt}}</span>
                      </div>
                      <div class="myCallHistoryMemo" v-if="item.memo" v-html="item.phclMemoCntnt"></div>
                    </ur-box-container>
                  </template>
                </mo-list-item>
              </template>
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray"><p class="mt20">사랑온으로 전화통화를 하면<br>전화활동 이력을 볼 수 있어요</p></span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPCM139P from '@/ui/cm/MSPCM139P' // 전화메모 추가, 수정
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP310M",
    screenId: "MSPAP310M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_back)

      let tmp = this.$route.params

      if(this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y' && Object.keys(tmp).length !== 0) { // MSPBC003M에서 타 사번 조회된 상태로넘어온 경우
        if(!this.$bizUtil.isEmpty(tmp.cnsltNo)) {
          this.cnsltNo = tmp.cnsltNo
        } else if (tmp.pData?.key){
          this.cnsltNo = tmp.pData.key
          this.fcData = tmp.pData
        } else {
          this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
        }
      } else {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
      }

      let template  = ''
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        template = "<mo-icon @click='fn_ManagerSearch'>search</mo-icon>"
      } else {
        template = "<mo-icon></mo-icon>"
      }

      let lv_vm = this
      // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template: template,
          methods: {
            fn_ManagerSearch() {
              this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
                properties: {
                  pPage: 'MSPAP310M',
                  pData: this.fcData
                },
                listeners: {
                  onPopupSearch: (pItem) => {
                    console.log('onPopupSearch 컨설턴트 >> ' , pItem)

                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                    if(pItem != null && pItem != undefined){
                      lv_vm.cnsltNo = pItem.key

                      lv_vm.fcData = pItem
                      lv_vm.dateValue = moment(new Date()).format('YYYYMMDD')
                    }
                  },
                  onPopupClose: () => {
                    console.log('onPopupClose')
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                  }
                }
              })
            }
          }
        }
      }
    },
    mounted() {
      // 오늘 날짜로 설정
      this.dateValue = moment(new Date()).format('YYYYMMDD')
    },
    beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_back)
  },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        callList           : [],                                                         // 전화이력 list
        todayDate          : moment(new Date()).format('YYYYMMDD'),                      // 오늘날짜
        todayCnt           : 0,                                                          // 당일 전화이력 건수
        dateValue          : '',                                                         // 달력
        getMonthDateValue  : '',                                                         // 선택날짜
        cnsltNo            : '',                                                         // 컨설턴트사번
        fcData             : '',                                                         // 조회 FC
      };
    },

    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      // 날짜 변경
      dateValue() {
        let month = this.dateValue.substr(4,2)
        let date = this.dateValue.substr(6)
        this.getMonthDateValue = month + '월' + date + '일'
        this.fn_callHis(this.dateValue)
      },
      reload() {
        if(this.getStore('apStore').getters.getState.reload) {
          this.getStore('apStore').dispatch('SRN_RELOAD', false)
          this.fn_callHis(this.dateValue)
        }
      }
    },
    computed: {
      reload() {
        return this.getStore('apStore').getters.getState.reload
      },
      mngSearch () {
        if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
          return false
        } else {
          return true
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_callHis
       * 설명: 전화이력조회
       *********************************************************/
      fn_callHis(date, reload, flag) {
        const lv_Vm   = this 
        const trnstId = 'txTSSAP06S1' 
        const auth    = 'S'
        
        lv_Vm.todayCnt = 0
        lv_Vm.callList = []

        let pParams   = {
          mobslCntcOccuYmd: date,
          eno             : lv_Vm.cnsltNo,
        }

        console.log('param>>!', pParams)
        lv_Vm.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('response.body', response.body)
            if (response.body !== null && response.body.telHstrVO !== null && response.body.telHstrVO.length > 0) {
            let lv_CustList = response.body.telHstrVO

            // 고객접촉ID 분류
            lv_CustList.forEach(item => {
              if (item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'SEND' || item.mobslCustCntcId === 'RECI') {
                let cntcId = ''
                if(item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'SEND') cntcId = '발신'
                if(item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'RECI') cntcId = '수신'

                let phclMemoCntnt = ''
                if(!lv_Vm.$bizUtil.isEmpty(item.phclMemoCntnt)) {
                  phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                }
                let tmpObj = {
                  'custNm'       : item.custNm,
                  'CntcCntnt'    : item.mobslCntcCntnt,
                  'memo'         : item.phclMemoCntnt,
                  'SR'           : cntcId,
                  'phclMemoCntnt': phclMemoCntnt,
                  'CheckSn'      : item.mobslCntcCheckSn,
                  'chnlCustId'   : item.mobslChnlCustId
                }
                lv_Vm.callList.push(tmpObj)
              }
            });

            lv_Vm.todayCnt = lv_Vm.callList.length

            if (reload) {
              setTimeout(function () {
                let alertProps = {
                    title: '',
                    msg: flag ? '수정되었습니다.' : '저장되었습니다.'
                }
                lv_Vm.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
              },1000)
            }
          }
        })
      },

      /*********************************************************
       * Function명: fn_back
       * 설명: 뒤로가기
       *********************************************************/
      fn_back() {
        // 화면 이동 시, 검색사번 유지
        if(!this.mngSearch) {
          this.getStore('bcStore').dispatch('FC_DATA', this.fcData)
        }
        this.$router.go(-1)
      },

      /*********************************************************
       * Function명: fn_date
       * 설명: 날짜 이동
       *********************************************************/
      fn_date(btn) {
        let lastDay = String(new Date(this.dateValue.substr(0,4), this.dateValue.substr(4,2) , 0).getDate())

        // < 클릭
        if(btn === 'pre') {
          if (this.dateValue.substr(4,2) === '01' && this.dateValue.substr(6) === '01') {
            this.dateValue = String(Number(this.dateValue.substr(0,4)) - 1) + '1231'
          } 
          else if(this.dateValue.substr(6) === '01') {
            this.dateValue = String(Number(this.dateValue.substr(0,6)) - 1) + lastDay
          }
          else {
            this.dateValue = String(Number(this.dateValue) - 1)  
          }
        }

        // > 클릭
        if(btn === 'next') {
          if (this.dateValue.substr(4,2) === '12' && this.dateValue.substr(6) === '31') {
            this.dateValue = String(Number(this.dateValue.substr(0,4)) + 1) + '0101'
          } 
          else if(this.dateValue.substr(6) === lastDay) {
            this.dateValue = String(Number(this.dateValue.substr(0,6)) + 1) + '01'
          }
          else {
            this.dateValue = String(Number(this.dateValue) + 1)   
          }
        }
      },

      /*********************************************************
       * Function명: fn_memoSave
       * 설명: 메모저장/수정
       *********************************************************/
      fn_memoSave(item) {
        let lv_Vm = this

        let popup139 = this.$bottomModal.open(MSPCM139P, {
          properties: {
            pMobslCntcCheckSn: item.CheckSn,
            pChnlCustId      : item.chnlCustId,
            custNm           : item.custNm,
            showTime         : item.CntcCntnt,
            memoCntnt        : item.memo
          },

          listeners: {
            onPopupClose: () => {
              // 모달 닫기
              console.log('MSPCM290D onPopupClose')
              this.$bottomModal.close(popup139)
            },

            onPopupConfirm: (pData) => {
              // 모달 닫기
              this.$bottomModal.close(popup139)
              this.fn_callHis(lv_Vm.dateValue, true, pData)
            },
          }
        },
        {
          properties: {
            preventTouchClose: true,
          }
        })
      },
      
      /******************************************************************************
       * Function명 : fn_BottomConfirm
       * 설명       : Confirm 팝업 호출(확인, 취소)
       ******************************************************************************/
      fn_BottomConfirm (contents, CheckSn, chnlCustId) {
        this.lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title        : '알림',
            content      : contents,
            title_pos_btn: "삭제",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_ConfirmPop);
              this.fn_deleteMemo(CheckSn, chnlCustId)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_ConfirmPop);
            }
          }
        })
      },
      /*****************************************************************************
       * Function명 : fn_deleteMemo
       * 설명       : 전화메모 단건 삭제
       ******************************************************************************/
      fn_deleteMemo(CheckSn, chnlCustId) {
        console.log('삭제!', this.pMobslCntcCheckSn)
        const lv_Vm   = this 
        const trnstId = 'txTSSCM58D3' 
        const auth    = 'S'

        let pParams = {
          mobslCntcCheckSn: CheckSn,
          mobslChnlCustId : chnlCustId,
          mobslCnsltEno   : this.getStore('userInfo').getters.getUserInfo.userId,
        }
        console.log('delete param>>!', pParams)

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response?.body && response.body.rsltYn === '1') {
              console.log('삭제완료')
              lv_Vm.fn_callHis(lv_Vm.dateValue)
            } else {
              let msg = response.msgComm.msgDesc
              lv_Vm.$cmUtil.fn_OpenAlert(true, msg)
            }
          })
      },
    }
  };
</script>