/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI801D
 * 화면설명: 선심사 > 지급정보동의
 * 접근권한: 
 * 작 성 일: 2024.01.08
 * 작 성 자: 
 */
 <template>
  <!-- Content영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-pi-wrap pb50">
    <!-- 미성년자인 경우만 노출 START -->
    <ur-box-container v-if="isVisblePrtrPeson && !lv_IsAllCnsnt" direction="column" alignV="start" class="pa2024 bd-b-Ty1"> 
    <!-- <ur-box-container v-if="isVisblePrtrPeson && lv_MnisdCustInfo.cnsntYn !== 'Y' && !lv_IsChkCurrSendDate" direction="column" alignV="start" class="pa2024 bd-b-Ty1">  -->
      <div class="fexTy5">
        <!-- <span class="fs17rem">만 19세 미만 미성년자의 경우 친권자 모두 지급정보 동의 시 이전 질병정보를 불러올 수 있습니다.</span>  -->
        <span class="fs17rem" v-if="+lv_MnisdCustInfo.custAge < 14">만 14세 미만 미성년자의 경우 지급정보조회를 위해서는 친권자 모두의 동의가 필요합니다.</span> 
        <span class="fs17rem" v-else-if="+lv_MnisdCustInfo.custAge >= 14 && +lv_MnisdCustInfo.custAge < 19">만 14세 이상 미성년자의 경우 지급정보조회를 위해서는 본인과 친권자 모두의 동의가 필요합니다.</span> 
      </div>
      <div class="fexTy4 full">
        <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 minWauto hauto pal0 pt4" @click="fn_OpenMSPPI804P" v-if="!lv_IsChkCurrSendDate">친권자 설정</mo-button> 
      </div>
    </ur-box-container>
    <!-- 미성년자인 경우만 노출 END -->
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa2024">
      <mo-list :list-data="(!isVisblePrtrPeson && lv_MnisdCustInfo.cnsntYn === 'Y') || (isVisblePrtrPeson && lv_IsAllCnsnt) ? [lv_MnisdCustInfo] : [lv_MnisdCustInfo, ...lv_PrtrPesonInfo]">
      <!-- <mo-list :list-data="lv_MnisdCustInfo.cnsntYn === 'Y' ? [lv_MnisdCustInfo] : [lv_MnisdCustInfo, ...lv_PrtrPesonInfo]"> -->
        <template #list-item="{item, index}">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy3">
                <div class="fexTy5">                  
                  <span class="mr10">
                    <mo-badge class="badge-box h28" text="" shape="status" :class="{'lightblue': item.contvInsrdTypCd === '21', 'lightblue2': item.contvInsrdTypCd === '23', 'purple': item.contvInsrdTypCd !== '21' && item.contvInsrdTypCd !== '23' }">{{ item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23' ? fn_setLabel(item.contvInsrdTypCd) : fn_setLabel(String(index))}}</mo-badge> 
                    <!-- <mo-badge class="badge-box h28" text="" shape="status" :class="{'lightblue': item.contvInsrdTypCd === '21', 'lightblue2': item.contvInsrdTypCd === '23', 'purple': item.contvInsrdTypCd !== '21' && item.contvInsrdTypCd !== '23' }">{{ item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23' ? fn_setLabel(item.contvInsrdTypCd) : item.prtrTypCd === '02' ? fn_setLabel(String(index)) : fn_setLabel(item.prtrTypCd) }}</mo-badge>  -->
                  </span>                
                  <span class="ns-tool-tip w95px">    
                    <span id="location0" class="name txtSkip">{{item.custNm}}</span><!-- // id값 각각 매칭 연결 -->
                    <mo-tooltip target-id="location0" class="tool-tip-box">{{item.custNm}}</mo-tooltip>
                  </span>
                  <span class="fwn fs14rem crTy-bk7">{{item.custAge === 'NaN' ? '' : `${item.custAge}세`}}</span>
                </div>
                <template v-if="!isVisblePrtrPeson">
                  <mo-button color="normal" shape="border" size="small" class="black" v-show="item.cnsntYn !== 'Y' && (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23')" @click="fn_UpdateCnsntInfo(item.chnlCustId)">업데이트</mo-button>
                </template>
                <template v-else-if="isVisblePrtrPeson">
                  <mo-button color="normal" shape="border" size="small" class="black" v-show="(item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') && !lv_IsAllCnsnt" @click="fn_UpdateCnsntInfo(item.chnlCustId)">업데이트</mo-button>    
                </template>
                <!-- <mo-button color="normal" shape="border" size="small" class="black" v-show="item.cnsntYn !== 'Y' && (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') " @click="fn_UpdateCnsntInfo(item.chnlCustId)">업데이트</mo-button>     -->
                <!-- <mo-button color="normal" shape="border" size="small" class="black" v-show="item.cnsntYn !== 'Y' && Number(item.custAge) >= 14" @click="fn_UpdateCnsntInfo(item.chnlCustId)">업데이트</mo-button>     -->
              </div>
              <div class="list-item__contents__info pt5" v-if="Number(item.custAge) >= 14">
                <span class="w90px hauto mr10 fs14rem line-h">휴대폰번호</span>
                <span class="hauto crTy-bk1 line-h">{{item.hasOwnProperty('custTelno') ? item.custTelno : ''}}</span>
              </div>
              <div class="list-item__contents__info pt1 pb0">  
                <span class="w90px hauto mr10 fs14rem line-h">동의여부</span>
                <span class="hauto line-h">
                    <span class="fwb" :class="{'crTy-blue2' : item.cnsntYn === 'Y', 'crTy-orange2' : item.cnsntYn !== 'Y'}">{{item.cnsntYn === 'Y' && (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') ? '동의완료': item.cnsntYn === 'Y' && (item.contvInsrdTypCd !== '21' && item.contvInsrdTypCd !== '23') ? '동의완료' : '미동의'}}</span>
                    <template v-if="!isVisblePrtrPeson">
                      <span class="ml10 crTy-bk1" v-if="item.cnsntYn === 'Y' && (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23')">{{` 유효기간 ${item.cnsntAvlEndYmd}`}}</span>
                    </template>
                    <template v-else>
                      <span class="ml10 crTy-bk1" v-if="item.cnsntYn === 'Y' && (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') && lv_IsAllCnsnt">{{` 유효기간 ${item.cnsntAvlEndYmd}`}}</span>
                    </template>
                </span>
              </div>
            </div>
          </mo-list-item>
        </template>
      </mo-list>
      <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mla0 pa2024 bd-T-Ty1">
        <div class="ns-btn-relative-s full">
          <div class="relative-div mb0">
            <template v-if="!isVisblePrtrPeson">
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="lv_MnisdCustInfo.cnsntYn !== 'Y'" @click="fn_SendTalk">동의 알림톡 전송</mo-button>
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="lv_MnisdCustInfo.cnsntYn === 'Y'" @click="fn_OpenClmInfo">{{`보험금 지급정보(${lv_TotCnt}건)`}}</mo-button>
            </template>
            <template v-else>
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="!lv_IsAllCnsnt" @click="fn_SendTalk">동의 알림톡 전송</mo-button>
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="lv_IsAllCnsnt" @click="fn_OpenClmInfo">{{`보험금 지급정보(${lv_TotCnt}건)`}}</mo-button>
            </template>
          </div>
        </div>
        <div class="pt10 fs16rem crTy-bk7">
          ※ 지급동의를 받으면 정확한 심사결과를 확인 하실 수 있습니다.
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-box-container> 
  <!-- Content 영역 end -->
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Gaf from '@/ui/pi/common/Gaf'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPPI801D',
  screenId: 'MSPPI801D',
  components: {
  },
  props: {
    isVisblePrtrPeson: Boolean,
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      lv_MnisdCustInfo: {
        custId: '',
        chnlCustId: '',
        clctCnsltNo: '',
        custRltnCd: '',
        custNm: '',
        custAge: '',
        custTelno: '',
        cnsntYn: '',
        cnsntAvlStrYmd: '',
        cnsntAvlEndYmd: '',
        dspchYmd: '',
        contvInsrdTypCd: '',
        mcnstCnsntYn: '',
        prtrTypCd: '',
      }, // 주피 정보
      lv_PrtrPesonInfo: [], // 친권자 정보
      lv_pymBrkdnCnsntInfoTrtDto: [], // 지급내역동의정보처리Dto
      lv_IsAllCnsnt: false,
      lv_TotCnt: 0,
    }
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_Init()
  },
  mounted() {
    // this.fn_GetBindData()
  },
  computed: {
    // 친권자 당일발송일자 유무
    lv_IsChkCurrSendDate () {
      let chkItem = this.lv_PrtrPesonInfo.filter(item => item.dspchYmd.replace(/-/g, '') === piCommonUtil.getCurrentDate())
      return !_.isEmpty(chkItem) && chkItem.length > 0 ? true : false
    }
  },
  methods: {
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 화면 생성 시 초기화 함수
    ******************************************************************************/
    fn_Init () {
    },
    /******************************************************************************
    * Function명 : fn_setLabel
    * 설명       : 코드 값에 따라 라벨 세팅
    ******************************************************************************/
    fn_setLabel (type) {
      switch (type) {
        case '21':
          return '주피'
        case '23':
          return '종피'
        case '01':
        case '03':
        case '1':
          return '친권자1'
        case '2':
          return '친권자2'
        default:
          break
      }
    },
    fn_SetCnsntYn (item) {
      if (_.isEmpty(item)) return 'N'
      if (item.contvInsrdTypCd === '21' || item.contvInsrdTypCd === '23') {
        if (this.lv_IsAllCnsnt) {
          return `${item.cnsntYn} (유효기간 ${item.cnsntAvlEndYmd})`
        } else {
          return item.cnsntYn !== 'Y' ? 'N' : item.cnsntYn
        }
      } else {
        return item.cnsntYn !== 'Y' ? 'N' : item.cnsntYn
      }
    },
    /******************************************************************************
    * Function명 : fn_GetBindData
    * 설명       : 화면 mounted 시 선심사 화면에서 정보 가져와서 세팅
    ******************************************************************************/
    fn_GetBindData (type) {
      console.log('fn_GetBindData START===================')
      this.lv_MnisdCustInfo = this.$bizUtil.cloneDeep(Gaf.getPanel('MSPPI800P').lv_MnisdCustInfo)
      // console.log('getPanel 가져오나...? ', this.lv_MnisdCustInfo)
      if (!_.isEmpty(type)) {
        // 친권자 정보 세팅
        let prtrPesonInfo = this.$bizUtil.cloneDeep(Gaf.getPanel('MSPPI800P').lv_PrtrPesonInfo)
        if (!piCommonUtil.isEmpty(prtrPesonInfo)) {
          this.lv_PrtrPesonInfo = prtrPesonInfo.sort((a, b) => +a.prtrTypCd - +b.prtrTypCd)
          console.log('친권자정보 ===> ', this.lv_PrtrPesonInfo)
        } else {
          this.lv_PrtrPesonInfo = []
        }
      }
      let dateArr = [this.lv_MnisdCustInfo, ...this.lv_PrtrPesonInfo]
      dateArr.forEach(item => {
        if (!_.isEmpty(item.cnsntAvlEndYmd)) {
          item.cnsntAvlEndYmd = piCommonUtil.dateFormat(item.cnsntAvlEndYmd)
        }
      })
      this.lv_IsAllCnsnt = this.$bizUtil.cloneDeep(Gaf.getPanel('MSPPI800P').lv_IsAllCnsnt)
    },
    /******************************************************************************
    * Function명 : fn_SendTalk
    * 설명       : 동의 알림톡 전송 함수
    ******************************************************************************/
    fn_SendTalk () {
      this.$emit('sendTalk')
    },
    /******************************************************************************
    * Function명 : fn_OpenMSPPI804P
    * 설명       : 친권자 설정 팝업 호출
    ******************************************************************************/
    fn_OpenMSPPI804P () {
      this.$emit('openMSPPI804P')
    },
    /******************************************************************************
    * Function명 : fn_UpdateCnsntInfo
    * 설명       : 업데이트 버튼 선택 시 동의 상태 조회 호출
    ******************************************************************************/
    fn_UpdateCnsntInfo (item) {
      this.$emit('updateCnsnt', item)
    },
    /******************************************************************************
    * Function명 : fn_OpenClmInfo
    * 설명       : 보험금지급정보 노출
    ******************************************************************************/
    fn_OpenClmInfo () {
      this.$emit('selClmInfo')
    }
  }
}
</script>