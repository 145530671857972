/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI310M
 * 화면설명: 가입설계관리 > 최근설계
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="최근설계" :show-title="true" type="subpage" ref="page" :action-type="actionType" :topButton="true"
    @on-scroll-bottom="fn_ScrollBottomHandler" 
    @on-header-left-click="fn_HeaderBackBtnHandler"
    :topButtonBottom="topButtonBottom">

    <template #frame-header-fixed>
      <!-- 검색결과 -->
      <ur-box-container v-if="!!searchKeyword" alignV="start" direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1">
            <span class="txt">{{searchKeyword}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!-- 총 건수, 리스트 정렬 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="checkAll" class="mr10 mb4" size="small" @click="fn_SelectAllEventHandler(checkAll)"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="selectItems.length === 0">총 <strong class="crTy-blue3">{{resultCnt}}</strong> 건</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{selectCnt}}</strong> 건 선택</span>
              <!-- 리스트 정렬 컴포넌트 -->
              <PSListSortOpt ref='sortFieldComp'
                            defaultLabel='정렬선택'
                            popupTitle="최근설계 정렬"
                            itemValue="name" 
                            itemText="label"
                            :sortFieldList='sortFieldList'
                            @sortChange='fn_SortChangeHandler'/>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="msp-pi-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list type-3 pb150">
        <mo-list :list-data="items">
          <template #list-item="{item, index}">
            <mo-list-item expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index">
              <!-- 체크박스 -->
              <mo-checkbox v-model="item.checked" class="mr10" size="medium" @click="fn_SelectCheckEventHandler(item)"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 설계명 -->
                  <span class="name" @click.stop="fn_MoveEntPlan(item)">{{item.planNm}}</span>
                </div>
                <div class="list-item__contents__info fexTy2">
                  <!-- 피보험자 -->
                  <span class="crTy-bk7 fs14rem mr10">피보험자</span><span class="crTy-bk1 flex-1 break-all underline" @click.stop="fn_GrdCustNmClick(item)">{{item.insrdNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <!-- 설계일 -->
                  <span class="crTy-bk7 fs14rem mr10">설계일</span><span class="crTy-bk1">{{item.entplYmdLabel}}</span>
                </div>
                <div class="list-item__contents__info">
                  <!-- 보험료 -->
                  <span class="crTy-bk7 fs14rem mr10">보험료</span><span class="crTy-bk1 crTy-blue3 fwb700">
                    <!-- 2020 외화보험 {{$bizUtil.numberWithCommas(props.item.smtotPrm.amount)}} -->
                    {{$bizUtil.numberWithCommasCurr(item.smtotPrm.amount, item.zaCrncyCd ,'Y','Y', 3)}}</span>
                </div>
              </div>

              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="expand-inner-info-wrap">
                    <div class="contents-row">
                      <!-- 전산심사 -->
                      <span class="dsInline crTy-bk7 fs14rem mr20 min50">전산심사</span>
                      <span class="fwb crTy-bk1">{{ item.cundwRsltCdNm }}</span>
                      <span v-if="item.jdgDtmLabel" class="ml4 crTy-bk1">(심사일 : {{ item.jdgDtmLabel }})</span>
                    </div>
                    <div class="contents-row fexTy2">
                      <!-- 선심사 -->
                      <span class="dsInline crTy-bk7 fs14rem mr20 min50">선심사</span>
                      <span class="fex-clumn">  
                        <span class="crTy-bk1">{{ item.zaDsclsId }}</span>
                        <span class="fexTy5">
                          <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 minWauto hauto pal0"  @click="fn_ZaNundwPrgStatNm(item)">{{ item.zaNundwPrgStatNm }}</mo-button>
                          <span class="ml4 crTy-bk1" v-if="item.zaNundwPrgStatCd!=='10' && item.zaNundwYmdLabel!==null">(심사일 : {{ item.zaNundwYmdLabel }})</span>  
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </mo-list-item>

            <ur-box-container alignH="between" componentid="" direction="row">
              <div class="ns-btn-relative-area pl20 pr20 bd-b-Ty1">
                <div class="ns-btn-relative-s">
                  <div class="relative-div mb20">
                    <!-- AEUS 버튼 -->
                    <template v-if="item.eusPrgStatCd === '0'">
                      <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue mr0" @click="fn_GrdAEUSClick(item)">AEUS입력</mo-button> -->
                      <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_GrdAEUSClick(item)">New AEUS</mo-button>
                    </template>
                    <template v-else>
                      <mo-button :disabled="true" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white mr0">{{item.eusPrgStatNm}}</mo-button>
                    </template>
                    <!-- 설계비교 버튼 -->
                    <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CompareButtonClick(item)">설계비교</mo-button>
                  </div>
                </div>
              </div>
            </ur-box-container>  
          </template>
        </mo-list>   
      </ur-box-container>

      <!--START: 청약설계 업무 더보기 -->
      <mo-bottom-sheet ref="bs_MoreMenu"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>
          청약설계 업무 더보기
          <div class="ns-btn-close" @click="fn_SubMenuClose()" name="닫기"></div>
        </template>
        <div class="content-area list-ty">
          <mo-list :list-data="subMenuList">
            <template #list-item="{item}">
              <div class="list-li" @click="fn_SubMenuClick(item)">
                <span class="txt txtSkip">{{item.label}}</span>
                <button class="link-arrow"></button>
              </div> 
            </template>
          </mo-list>
        </div>
      </mo-bottom-sheet>
      <!--END: 청약설계 업무 더보기 -->

      <!--START: alert-선택한 설계를 삭제하시겠습니까? -->
      <mo-bottom-sheet ref="refDelPlanConfirm" class="ns-bottom-sheet ns-style3" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="cancel-ment">선택한 설계를 삭제하시겠습니까?</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_DelPlanAlert('cancel')">아니오</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DelPlanAlert('ok')">예</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert-선택한 설계를 삭제하시겠습니까? -->

      <!--START: 설계 삭제 -->
      <ur-box-container v-if="selectCnt > 0" alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative bottom60">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DelPlan()">설계삭제</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="detailPlanDisabled" @click="fn_movePlan()">설계보기</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
      <!--END: 설계 삭제 -->

    </ur-box-container>
    <!-- NoData 영역 ur-box-containe -->
    <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSAeusUtil from '@/ui/ps/comm/PSAeusUtil' // AEUS 화면 이동
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import Screen from '~systems/mixin/screen'
import MSPPI311P from '@/ui/pi/MSPPI311P' // 필요양식 발행 팝업
import MSPPI312P from '@/ui/pi/MSPPI312P' // 상세검색 팝업
import MSPPI800P from '@/ui/pi/MSPPI800P' // [선심사 및 영업시스템 고도화 PJT] 선심사
import MSPPI820P from '@/ui/pi/MSPPI820P' // [선심사 및 영업시스템 고도화 PJT] 선심사 완료
import MSPPI840P from '@/ui/pi/MSPPI840P' // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Gaf from '@/ui/pi/common/Gaf'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI310M',
  screenId: 'MSPPI310M',
  mixins: [Screen],
  components: {
    'PSNodataMesgBoxForList'  : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
    'PSListSortOpt'           : PSListSortOpt, // 정렬 컴포넌트
    'PSHeaderSubMenuPopup'    : PSHeaderSubMenuPopup, // 서브메뉴 팝업
    'MSPPI311P'               : MSPPI311P, // 전자서명 필요양식 밠행
    'MSPPI312P'               : MSPPI312P, // 검색 키워드 입력 팝업
    'MSPPI800P'               : MSPPI800P, // [선심사 및 영업시스템 고도화 PJT] 선심사
    'MSPPI820P'               : MSPPI820P, // [선심사 및 영업시스템 고도화 PJT] 선심사 완료
    'MSPPI840P'               : MSPPI840P, // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{},
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  // modalProps: {
  //   mode: 'right' // 우->좌 open popup (defalut: 하->상)
  // },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data() {
    return {
      actionType : null,
      topButtonBottom: 0, // 하단 삭제 버튼 show 되면 150 으로 변경
      searchKeyword:'', // 상세조회 입력 텍스트
      searchRes:'', // 상세조회 구분 - 1:고객명 , 2:설계명
      resultCnt: 0, // 조회된 데이터 총 건수F
      selectCnt: 0, // 선택된 데이터 총 건수
      // 업무 더보기 리스트
      subMenuList: [
        {label: '필요양식 발행', srnId: 'MSPPI311P', param: null, isConfrimMesg: null},
        {label: '가입설계', srnId: 'MSPPI001M', param: null, isConfrimMesg: null}
      ],
      // 소팅 필드 리스트
      sortFieldList: [
        {field: 'entplYmd', label: '설계일', sort: 'DESC'},
        {field: 'insrdNm', label: '피보험자', sort: 'ASC'},
        {field: 'eusPrgStatNm', label: 'AEUS', sort: 'ASC'},
        {field: 'zaDsclsId', label: '선심사', sort: 'DESC'}
      ],
      items: [], // 전체 리스트 (화면에 보여지는 데이터)
      orgItems: [], // 전체 리스트 (조회한 데이터 전체)
      selectItems: [], // 선택 리스트
      checkAll: false, // 전체 체크
      stndKey: '', // 리스트 페이징 Next Key
      isNodata: true, // 데이터 없음 Flag
      pi311p: null, // 필요양식발행 팝업
      pi312p: null, // 검색 팝업
      pageScrollAnimateY: null, // 페이지 Y 축 스크롤 객체
      searchFlag: false, // 데이터 조회 시 true, 조회 완료 시 false
      pChnlCustId: '', // 고객검색 팝업에서 받아온 채널고객아이디
      eusObjYn: '',
      selectItem: null, // 선택된 아이템 단건 (AEUS 입력시 사용)
      detailPlanDisabled: false, // 설계보기 비활성화
      // [선심사 및 영업시스템 고도화 PJT] 추가 ==============
      lv_NundwInfo: {}, // 선심사정보
      popupMSPPI800P: null, // 선심사 팝업
      popupMSPPI820P: null, // 선심사 결과 팝업
      popupMSPPI840P: null, // New AEUS
      lv_NewAeusVisible: true, // New AEUS 버튼 처리
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate() {},
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)

    // 상단 검색, 더보기 버튼 셋팅
    let lv_Vm = this
    lv_Vm.actionType = {
      actionComponent: {
        template: '<div slot="action" class="icon-wrapper"><mo-icon type="picto" @click="openSearch">search</mo-icon><mo-icon icon-size="30px" type="picto" @click="openMore">more</mo-icon></div>',
        methods: {
          openSearch() {
            lv_Vm.fn_HeaderSerchHander()
          },
          openMore() {
            lv_Vm.fn_MoreMenuHandler()
          }
        }
      }
    }
    // 초기 조회
    lv_Vm.fn_ServiceData('S')
  },
  // beforeMount() {},
  mounted() {
    // 하단 GNB 영역 나오게
    this.$BottomManager.fn_SetBottomVisible(true)
    // 페이지 Y축 스크롤 객체
    this.pageScrollAnimateY = createScrollAnimateInstance(document.getElementById(this.$refs.page.getScrollElementId()), 'y')
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  // beforeUpdate() {},
  // updated() {},
  beforeDestroy() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  // destroyed() {},
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    items() {
      // 총 건수 셋팅
      this.resultCnt = this.items.length
    },
    selectItems() {
      // 설계 선택에 따라서 '설계삭제' 버튼이 하단에 나오게 됨으로 'Top' 버튼의 위치를 조정해 주어야한다
      if(this.selectItems.length > 0) {
        this.topButtonBottom = 150
        if(this.selectItems.length > 1){
          this.detailPlanDisabled = true
        }else{
          this.detailPlanDisabled = false
        }
      }else {
        this.topButtonBottom = 0
      }
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_DeviceBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DeviceBackBtnHandler() {
      if(this.selectItems.length > 0) {
        let alertMsg = '선택을 그만두고 이전 화면으로 이동하시겠습니까?'
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: alertMsg,
            title_neg_btn: '아니오',
            title_pos_btn: '예'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              this.$router.push({name:'MSPBC002M'})
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      }else {
        this.$router.push({name:'MSPBC002M'})
      }
    },
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 상단 타이틀 백 아이콘 클릭 핸드러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      if(this.selectItems.length > 0) {
        let alertMsg = '선택을 그만두고 이전 화면으로 이동하시겠습니까?'
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: alertMsg,
            title_neg_btn: '아니오',
            title_pos_btn: '예'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              this.$router.push({name:'MSPBC002M'})
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      }else {
        this.$router.push({name:'MSPBC002M'})
      }
    },
    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset() {
      this.items = [] // 화면에 보이는 리스트
      this.orgItems = [] // 전체 리스트
      this.selectItems = [] // 선택 리스트
      this.checkAll = false // 전체 체크
      this.stndKey = '' // 리스트 페이징 Next Key
      this.isNodata = true // 데이터 없음 Flag
      this.resultCnt = 0 // 조회된 데이터 총 건수
      this.selectCnt = 0 // 선택된 데이터 총 건수
    },
    /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
      let lv_Vm = this
      lv_Vm.pi312p = lv_Vm.$bottomModal.open(MSPPI312P, {
        properties: {
          pParntSrnId: lv_Vm.$options.screenId, // 부모 화면 ID
          pSearchKeyword: lv_Vm.searchKeyword || '', //Default set Keyword Value
          pSearchScVal: lv_Vm.searchRes || '1' // 고객명 기본 셋팅
        },
        listeners: {
          // 팝업에 조회 버튼 이벤트
          onPopupSearch: (rtnData) => {
            lv_Vm.searchRes = rtnData.searchScVal // 검색구분값
            lv_Vm.searchKeyword = rtnData.searchKeyword // 검색키워드
            if(rtnData.searchKeyword.trim() !== '') { // 필터검색
              lv_Vm.pChnlCustId = ''
              if(rtnData.searchScVal === '1') {
                lv_Vm.fn_SelectSearch('1', 'chnlCustId', rtnData.chnlCustId)
                lv_Vm.pChnlCustId = rtnData.chnlCustId
              }else if(rtnData.searchScVal === '2') {
                lv_Vm.fn_SelectSearch('2', 'planNm', rtnData.searchKeyword.trim())
              }else {
              }
            }else {
              // 전체 다시 조회
              lv_Vm.fn_Reset()
              lv_Vm.fn_ServiceData('S')
            }
            lv_Vm.$bottomModal.close(lv_Vm.pi312p) // 모달 닫기
          },
          // 팝업에 뒤로가기 버튼 이벤트
          onPopupBack: () => {
            lv_Vm.$bottomModal.close(lv_Vm.pi312p)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_SelectSearch
    * 설명 : 조회 Data 필터 처리
    ******************************************************************************/
    fn_SelectSearch(typeKey, targetFild, searchKey) {
      this.searchFlag = true
      this.pageScrollAnimateY.animate(0, 700, 0, 'easeOut') // 페이징 재로드 발생 방지용 scroll top
      let tempArray = []
      this.selectItems = [] // 선택 데이터 모두 초기화 처리
      this.checkAll = false // 전체 체크
      this.orgItems.forEach(item => {
        if(typeKey === '1' && searchKey === item[targetFild]) { // 고객명
          tempArray.push(item)
        }
        if(typeKey === '2' && item[targetFild].indexOf(searchKey) > -1) { // 설계명
          tempArray.push(item)
        }
      })
      this.items = []
      this.items = tempArray
      // 정렬 선택 라벨 초기화
      this.$refs.sortFieldComp.fn_Reset()
      // 검색 플래그 설정
      setTimeout(() => this.searchFlag = false, 1000)
    },
    /******************************************************************************
    * Function명  : fn_MoreMenuHandler
    * 설명        : 상단 더보기 메뉴 아이콘 클릭 핸들러
    ******************************************************************************/
    fn_MoreMenuHandler() {
      this.$refs.bs_MoreMenu.open()
    },
    /******************************************************************************
    * Function명  : fn_SubMenuClick
    * 설명        : 더보기 메뉴 아이템 클릭 핸들러
    ******************************************************************************/
    fn_SubMenuClick(item) {
      let lv_Vm = this
      if(item.srnId && item.srnId === 'MSPPI001M') {
        // 가입설계 메인 이동
        let o = {}
        o.name = item.srnId
        o.params = {init: true}
        lv_Vm.$router.push(o)
      }else if(item.srnId && item.srnId === 'MSPPI311P') {
        // 필요양식 발행 팝업 열기
        lv_Vm.pi311p = lv_Vm.$bottomModal.open(MSPPI311P, {
          properties: {
            pParntSrnId: lv_Vm.$options.screenId // 부모 화면 ID
          },
          listeners: {
            // 팝업 닫기
            closePopup: () => {
              lv_Vm.$bottomModal.close(lv_Vm.pi311p)
            }
          }
        })
        // 더보기 메뉴 닫기
        lv_Vm.fn_SubMenuClose()
      }
    },
    /******************************************************************************
    * Function명  : fn_SubMenuClose
    * 설명        : 더보기 메뉴 닫기
    ******************************************************************************/
    fn_SubMenuClose() {
      this.$refs.bs_MoreMenu.close()
    },
    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
      this.fn_ServiceData('N') // 리스트 더보기 조회
    },
    /******************************************************************************
    * Function명  : fn_SortReset
    * 설명        : 정렬 리셋 이벤트 핸들러
    ******************************************************************************/
    fn_SortReset() {
      this.items = []
      this.items = this.orgItems.slice(0)
      // 정렬 라벨 초기화
      this.$refs.sortFieldComp.fn_Reset()
    },
    /******************************************************************************
    * Function명  : fn_SortChangeHandler
    * 설명        : 정렬 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortChangeHandler(pSortOrder) {
      let sortField = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC
      switch(sortField) {
        case 'entplYmd': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          // PSCommUtil.fn_SortListValueDateString(sortOrderby, this.items, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          PSCommUtil.fn_SortListValueString(sortOrderby, this.items, sortField)
          break
        case 'insrdNm':  //String 형 sort
        case 'eusPrgStatNm':  //String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.items, sortField)
          break
        case 'zaDsclsId':
          // PSCommUtil.fn_SortListValueString(sortOrderby, this.items, sortField)
          this.items.sort((a, b) => {
            if(a[sortField] !== ' ' && b[sortField] !== ' ') {  // 선심사번호가 있는 건 기준 최근 설계일자 순으로 정렬
              return String(b.entplYmd).localeCompare(String(a.entplYmd))
            } else if (a[sortField] !== ' ') {
              return -1
            } else if (b[sortField] !== ' ') {
              return 1
            } else {
              return 0
            }
          })
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_GrdCustNmClick
     * 설명 : 테이블에 피보험자 컬럼을 선택했을때 nameUI 실행하는 함수
     ******************************************************************************/
    fn_GrdCustNmClick(item) {
      let nmUiObject = {}
      nmUiObject.parentId = this.$options.screenId
      nmUiObject.chnlCustId = item.chnlCustId
      nmUiObject.cnsltNo = PSServiceManager.getUserInfo('userId')
      nmUiObject.custNm = item.insrdNm

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId)=>{
        // close 처리
        return
      })
    },
    /******************************************************************************
     * Function명 : fn_SelectAllEventHandler
     * 설명 : 전체 선택 이벤트 핸들러
     ******************************************************************************/
    fn_SelectAllEventHandler(checked) {
      this.selectItems = []
      this.items.forEach(listObj => {
        listObj.checked = !checked
        if(listObj.checked) {
          this.selectItems.push(listObj)
        }
      })
      this.selectCnt = this.selectItems.length
    },
    /******************************************************************************
     * Function명 : fn_SelectCheckEventHandler
     * 설명 : 리스트 체크박스 선택 이벤트 핸들러
     ******************************************************************************/
    fn_SelectCheckEventHandler(item) {
      if(!item.checked) {
        this.selectCnt++
        this.selectItems.push(item)
      }else {
        this.checkAll = false
        this.selectCnt--
        this.selectItems.splice(this.selectItems.findIndex((e) => e['entplId'] === item.entplId), 1)
      }
    },
    /******************************************************************************
     * Function명 : fn_DelPlan
     * 설명 : 설계 삭제 선택 이벤트 핸들러
     ******************************************************************************/
    fn_DelPlan() {
      // 삭제대상 목록 중 선심사 심사중(40), 미결(50) 상태 존재 시 삭제 불가 [2024.04.05 선심사 및 영업시스템 고도화 PJT]]
      for (let item of this.selectItems) {
        if(item.zaNundwPrgStatCd === '40' || item.zaNundwPrgStatCd === '50') {
          this.getStore('confirm').dispatch('ADD', '선심사 심사중 또는 미결 건은 삭제할 수 없습니다.')
          return 
        }
      }
      // 선택한 설계를 삭제하시겠습니까?
      this.$refs.refDelPlanConfirm.open()
    },
    /******************************************************************************
     * Function명 : fn_DelPlanAlert
     * 설명 : 설계 삭제 컨펌 알럿 이벤트 핸들러
     ******************************************************************************/
    fn_DelPlanAlert(type) {
      if(type === 'ok') {
        // 선택 설계 삭제
        this.fn_ServiceData('D')
      }else {
        // 알럿 닫음
        this.$refs.refDelPlanConfirm.close()
      }
    },
    fn_movePlan() {
      if(this.selectItems.length === 1){
        this.fn_MoveEntPlan(this.selectItems[0])
      }
    },
    /************************************************************************************************
    * Function명  : fn_MoveEntPlan
    * 설명        : 1차 사랑온 상품설계 연동 이벤트
    *               2차 상품설계 연동 이벤트
    ************************************************************************************************/
    fn_MoveEntPlan(item) {
      let pData = {}
      if(item.entplId && item.entplId !== '') {
        pData.zaPlanId = item.entplId
      }else {
        pData.init = true
      }

      let o = {}
      o.name = 'MSPPI001M'
      o.params = pData
      this.$router.push(o)
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(serviceName) {
      if (this.searchFlag) return
      this.searchFlag = true
      let pParams = {}
      switch (serviceName) {
        case 'S': // 최근설계 목록 조회
        case 'N': // 최근설계 목록 조회 더보기
          this.isLoadingStatus = true
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS21S1'))
          pParams.data = {}
          pParams.data.clctCnsltId = PSServiceManager.getUserInfo('userId') // FC 사번
          pParams.data.chnlCustId = '' // 채널고객아이디
          pParams.data.planNm = '' // 상품명
          pParams.data.planScCd = '00' // 설계구분코드 가입설계 00, 청약서발행 02
          pParams.data.eusObjYn = '' // AEUS대상구분 전체 '', 대상 0, 비대상 1
          pParams.data.eusPrgStatCd = '3' // AEUS입력여부 전체 '3', 입력 1, 미입력 0

          // Legacy PJT 변경. 설계진행현황조회 VO에 페이징 VO 추가. ERP To-Be 수정사항 따라감.
          pParams.data.pIPagingVO = {}
          pParams.data.pIPagingVO.fnctScCd = serviceName
          pParams.data.pIPagingVO.pageRowCnt = 30
          pParams.data.pIPagingVO.stndKey = (serviceName === 'S') ? '' : this.stndKey
          if(serviceName === 'S') {
            window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // or 'FULL'
          }else {          
            window.vue.getStore('progress').dispatch('PART') // or 'SKEL_LIST_CHK'
          }
          
          break
        case 'S0': // AEUS조직여부 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S1'))
          pParams.data = {}
          pParams.data.clctCnsltNo = PSServiceManager.getUserInfo('userEno') // FC 사번
          //ody 펼침 추가          
          this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
              for(let i = 0 ; i < this.items.length; i++){
                this.$refs['expItem_'+i].expand() // 아코디언 펼침 처리
              }
            });
          //ody 펼침 추가
          break
        case 'S1': // AEUS시스템장애 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S2'))
          pParams.data = {}
          pParams.data.conctSystmScCd = '10' // 접속시스템구분코드
          break
        case 'D': // 설계 삭제
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS21D1'))
          pParams.data = {}
          pParams.data.trtrEno = PSServiceManager.getUserInfo('trtrEno') // FC 사번
          let pIPlanSlctInfoVO = []
          this.selectItems.forEach(listObj => {
            pIPlanSlctInfoVO.push({
              entplId: listObj.entplId,
              deltYn: 'Y'
            })
          })
          pParams.data.pIPlanSlctInfoVO = pIPlanSlctInfoVO
          break
        default:
          break
      }
      // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
      if(serviceName === 'N' && this.stndKey === '') {
        this.searchFlag = false
      }else {
        pParams.srnId = this.$options.screenId // 대표 화면 명
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출 결과 함수 정의
    ******************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data       
      switch(serviceName) {
        case 'S': // 최근설계 목록 조회             
        case 'N': // 최근설계 목록 조회 더보기
          this.stndKey = lv_data.pipagingVO.stndKey || ''
          let t_rslt = lv_data.piplanProcessStatusViewListVO || []
          if(t_rslt && t_rslt.length > 0) {
            // 더보기 조회 했을때 전체 선택 컨트롤
            if(serviceName === 'N' && this.checkAll) {
              this.checkAll = false
            }
            t_rslt.forEach(pIteam => {
              // 체크 여부
              pIteam.checked = false
              // AEUS 정렬 하기위해 코드값 입력
              if(pIteam.eusPrgStatNm === '미입력') {
                pIteam.eusPrgStatCd = '0'
              }else if (pIteam.eusPrgStatNm === '입력') {
                pIteam.eusPrgStatCd = '1'
              }else if (pIteam.eusPrgStatNm === '비대상') {
                pIteam.eusPrgStatCd = '2'
              }else { // 빈칸 일때
                pIteam.eusPrgStatCd = '3'
              }
              // 설계명에 삼성생명, 삼성 제거 처리
              pIteam.planNm = pIteam.planNm.replace('삼성생명', '').replace('삼성', '').trim()
              // 날짜 포멧
              pIteam.entplYmdLabel = PSDateUtil.fn_DateFormat(pIteam.entplYmd, 'MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDDHHMMSS)
              pIteam.jdgDtmLabel = PSDateUtil.fn_DateFormat(pIteam.jdgDtm, 'MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDDHHMMSS)
              pIteam.zaNundwYmdLabel = PSDateUtil.fn_DateFormat(pIteam.zaNundwYmd, 'MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDDHHMMSS)
              
              // 목록 데이터 셋팅
              if(this.searchKeyword.trim() !== '' && serviceName === 'N') { // 검색중이라면
                if(this.searchRes === '1' && this.pChnlCustId.trim() === pIteam.chnlCustId) { // 고객명
                  this.items.push(pIteam)
                }else if (this.searchRes === '2' && pIteam.planNm.indexOf(this.searchKeyword.trim()) > -1) { // 상품명
                  this.items.push(pIteam)
                }
              }else {
                this.items.push(pIteam)
              }
              // 조회한 데이터 전체
              this.orgItems.push(pIteam)
            })
            //ody 펼침추가
            this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
              for(let i = 0 ; i < this.orgItems.length; i++){
                this.$refs['expItem_'+i].expand() // 아코디언 펼침 처리
              }
            });
            //ody 펼침추가

            // 데이터 없음 처리
            if(this.items.length > 0) {
              this.isNodata = false
            }else {
              this.isNodata = true
            }
          }else {
            if(serviceName === 'S') {
              this.fn_Reset()
            }
          }
          this.searchFlag = false
          if(serviceName === 'S') {
            this.fn_ServiceData('S0') // FC 정보 조회
          }
          break
        case 'S0': // AEUS조직여부 조회
          this.searchFlag = false
          this.eusObjYn = lv_data.eusObjYn
          break
        case 'S1': // AEUS시스템장애 조회
          window.vue.getStore('progress').dispatch('HIDE')
          this.searchFlag = false
          if (lv_data.systmTrblYn === 'N') {
            if (!this.selectItem) {
              this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC024']) // '선택된 설계정보가 없습니다.'
              return false
            } else {
              // EUS조직체크
              if (this.eusObjYn.trim() === '' || this.eusObjYn === 'N') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC025']) // 'AEUS 대상조직이 아닙니다.'
                return false
              } // end if
              // 전산심사결과 체크
              if (this.selectItem.cundwRsltCdNm.trim() === '') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC026']) // '전산심사 미실행-AEUS입력불가'
                return false
              } else if (this.selectItem.cundwRsltCdNm === '발행불가') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC027']) // '심사결과 발행불가-AEUS입력불가'
                return false
              } // end else if
              // AEUS상품 체크
              if (!this.selectItem.eusObjYn === 'N') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC028']) // 'AEUS 비대상 상품-AEUS입력불가'
              }
              // else if (this.selectItem.zaSelPrdtYn !== 'Y') {
              //   this.getStore('confirm').dispatch('ADD', '판매종료된 상품이거나 해당조건에 맞는 상품이 없습니다.')
              //   return false
              // }
              // TSSAU001P.setObjMsg(this.$options.screenId, this.selectItem.entplId)
              // PSAeusUtil.AEUSOpenService(this.$options.screenId, this.selectItem.entplId, this.fn_AEUSServiceResultHandler)
              
              window.vue.getStore('progress').dispatch('SHOW')
              this.fn_SeachState(this.selectItem) // 선심사 관련 New-AEUS 버튼 체크 로직
              return
              // this.fn_NewAEUSClick(this.selectItem)
            } // end else if
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC029']) // '현재 AEUS서비스가 원활하지 않습니다.'
          } // end if
          break
        case 'D':
          this.searchFlag = false
          this.fn_Reset()
          this.fn_ServiceData('S')
          this.getStore('toast').dispatch('ADD', '삭제되었습니다.')
          this.$refs.refDelPlanConfirm.close()
          break
        default:
          break
      }
            
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /******************************************************************************
    * Function명  : fn_ServiceFaultHandler
    * 설명        : 서비스 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      switch(serviceName) {
        case 'S':
        case 'N':
          this.fn_Reset()
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          this.searchFlag = false
          break
        default:
          break
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /************************************************************************************************
    * Function명  : fn_AEUSServiceResultHandler
    * 설명        : AEUS 팝업 닫기 이벤트 일때 리스트 목록 재조회
    *               단 검색중일때는 재조회 안함
    ************************************************************************************************/
    fn_AEUSServiceResultHandler(event) {
      if(event === '1') { // 입력 완료
        if(this.searchKeyword.trim() === '') {
          // this.fn_SearchEvent('S')
        } // end else if
      }else if (event === '0') { // 미입력
      }else if (event === 'S') { // 새로고침
        if(this.searchKeyword.trim() === '') {
          // this.fn_SearchEvent('S')
        } // end else if
      }else if (event === 'E') { // 에러
      } // end else if
    },
    /******************************************************************************
    * Function명  : fn_GrdAEUSClick
    * 설명        : AEUS 화면 링크
    ******************************************************************************/
    fn_GrdAEUSClick(pData) {
      this.selectItem = pData
      this.fn_ServiceData('S1')
    },
    /************************************************************************************************
    * Function명  : fn_CompareButtonClick
    * 설명        : 설계비교 화면 연동
    ************************************************************************************************/
    fn_CompareButtonClick(pData) {
      let o = {}
      o.name = 'MSPPI180M'
      o.params = {}
      o.params.zaPlanId = pData.entplId
      this.$router.push(o)
    },
    // ============================================================================
    // [선심사 및 영업시스템 고도화 PJT] 추가 함수
    // ============================================================================
    /******************************************************************************
     * Function명 : fn_SeachState
     * 설명 : 선심사 관련 New-AEUS 버튼 체크 로직
     ******************************************************************************/
    fn_SeachState (pData) {
      window.vue.getStore('progress').dispatch('FULL')
      let inputData = {
        entplId: pData.entplId
      }
      piCommonUtil.addPropertyToObject(inputData, 'searchCriteria', 'reqSystm', 'NEXT_LOVE_ON')
      // 역슬래시 제거
      let reqVal = JSON.stringify(inputData)
      let pParams = {
        cmndSrvcNm: 'selButtonVisable',
        sdrvDtVal: reqVal
      }
      piCommonUtil.invoke2('URLPQL00154', pParams, this.fn_ResSeachState, null, null, this, this)
    },
    fn_ResSeachState (resultData) {
      window.vue.getStore('progress').dispatch('HIDE')
      if (resultData !== null && !_.isEmpty(resultData)) {
        let outData = JSON.parse(resultData.sdrvDtVal)
        console.log('URLPQL00154 resultData ===> ', outData)
        // 선심사 필수건
        if (outData.nundwVisibleYn === 'F') {
          this.getStore('confirm').dispatch('ADD', '선심사필수 건은 입력할 수 없습니다.')
          return false
        } else if (outData.nundwPrgStatCd !== '70' && outData.nundwYn === 'Y') { // 선심사 입력건
          this.getStore('confirm').dispatch('ADD', '선심사 대상 건은 입력할 수 없습니다.')
          return false
        } else if (outData.aeusVisibleYn !== 'Y') {
          this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC028']) // 'AEUS 비대상 상품-AEUS입력불가'
          return false
        }
        this.fn_NewAEUSClick(this.selectItem)
        // 선심사 관련 New-AEUS 버튼 체크 로직 수행 필요
      }
    },
    /******************************************************************************
     * Function명 : fn_ZaNundwPrgStatNm
     * 설명 : 선심사진행상태명 선택 시 팝업 노출 실행하는 함수
     ******************************************************************************/
    fn_ZaNundwPrgStatNm(item) {
      // 선심사 진행상태에 따라 신청화면 or 결과화면으로 분기
      // 작성중(10):신청화면, 중지(70):리턴, 그 외:결과화면
      let zaNundwPrgStatCd = item.zaNundwPrgStatCd
      if (zaNundwPrgStatCd === '10') {
        // 신청화면 호출
        this.fn_OpenMSPPI800P(item)
      } else if (zaNundwPrgStatCd === '70'){
        return
      } else {
        // 결과화면 호출
        this.fn_OpenMSPPI820P(item)
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenMSPPI800P
    * 설명       : 선심사 신청 팝업 오픈 함수
    ******************************************************************************/
    fn_OpenMSPPI800P (item) {
      // let tmodelObj = Gaf.getObj('tmodel')
      // let rltnrInfo_21 = tmodelObj.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
      // console.log('rltnrInfo_21 ====> ', rltnrInfo_21)
      this.popupMSPPI800P = this.$bottomModal.open(MSPPI800P, {
        properties: {
          pPage: this.name,
          pParams: {planId: item.entplId,
                    custId: '',
                    chnlCustId: item.chnlCustId,
                    dsclsId: '',
                    nundwJdgPrgStatCd: item.zaNundwPrgStatCd,
                    // pmodel: Gaf.getObj('pmodel'),
                    // tmodel: Gaf.getObj('tmodel'),
                    // abdapolicy: Gaf.getGafObjByRefId('POLICY')
          },
          // pIsBackKeyListen: true,
        },
        listeners: {
          closePopup: (result) => {
            // 중단건(70)인 경우 목록 재조회
            if(result === '70') {
              this.fn_Reset()
              this.fn_ServiceData('S')
            }
            this.$bottomModal.close(this.popupMSPPI800P)
          },
          resultPopup: (result) => {
            this.$bottomModal.close(this.popupMSPPI800P).then(() => {
              // 결과화면 오픈
              this.fn_OpenMSPPI820P(item)
            })
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_OpenMSPPI820P
    * 설명       : 선심사 완료 팝업 오픈 함수
    ******************************************************************************/
    fn_OpenMSPPI820P (item) {
      // zaPlanId
      this.popupMSPPI820P = this.$bottomModal.open(MSPPI820P, {
        properties: {
          pPage: this.name,
          pParams: {zaPlanId: item.entplId},
          // pIsBackKeyListen: true,
        },
        listeners: {
          closePopup: (result) => {
            // result 비교
            if (!_.isEmpty(result)) {
              // 선심사번호 or 선심사결과코드 상이할 경우 목록 재조회
              if ( (item.entplId !== result.planId) || (item.zaNundwPrgStatCd !== result.nundwPrgStatCd) ) {
                this.fn_Reset()
                this.fn_ServiceData('S')
              }
            }
            this.$bottomModal.close(this.popupMSPPI820P)
          },
          resultPopup: (result) => {
            this.$bottomModal.close(this.popupMSPPI820P).then(() => {
              // 신청화면 오픈
              this.fn_OpenMSPPI800P(item)
            })
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /******************************************************************************
     * Function명  : fn_NewAEUSClick
     * 설명        : New AEUS 팝업 호출
     ******************************************************************************/
    fn_NewAEUSClick(item) {
      this.popupMSPPI840P = this.$bottomModal.open(MSPPI840P, {
        properties: {
          pPage: this.name,
          pParams: {
            planId: item.entplId
          }
        },
        listeners: {
          closePopup: () => {
            this.$bottomModal.close(this.popupMSPPI840P)
          },
          resultPopup: () => {
            this.$bottomModal.close(this.popupMSPPI840P).then(() => {
            })
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

    //코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End() {return}
  }
}
</script>