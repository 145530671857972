/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI312P
 * 화면설명: 가입설계관리 > 최근설계 > 상세조회 검색 팝업
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="최근설계 상세조회" :show-title="true" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignH="start" componentid="" direction="row" class="form-style-type-1">
          <msp-bottom-select :items="searchTypCds" :itemValue="'key'" :itemText="'label'" v-model="searchScVal" underline class="mr10" placeholder="고객명" bottom-title="상세조건 선택" closeBtn/>
          <mo-text-field v-model="searchKeyword" class="form-input-name" underline clearable placeholder="입력하세요." @keyup.enter="fn_Search()"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear()">초기화</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search()">조회</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객조회

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI312P',
  screenId: 'MSPPI312P',
  components: {
      'MSPCM129P': MSPCM129P //고객조회 팝업
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    pParntSrnId     : {type: String,  default: () => ''}, // 부모 화면 ID
    pSearchKeyword  : {type: String,  default: () => ''}, // Default set Keyword Value
    pSearchScVal    : {type: String,  default: () => '1'}  //1:고객명 2:상품명 3:영수증번호
  },
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data() {
    return {
      parntSrnId: '', // 부모화면 아이디
      searchScVal: '', // 1:고객명 2:설계명
      searchKeyword: '', // 검색값
      searchTypCds: [ // 검색 목록
        {key: '1', label: '고객명'},
        {key: '2', label: '설계명'}
      ],
      mspcm129p: {} // MSPCM129P 소개자 고객조회 팝업
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate() {},
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    //받은 파라미터 셋팅
    this.parntSrnId     = this.pParntSrnId    ? this.pParntSrnId.trim() : '' // 부모 화면 ID
    this.searchKeyword  = this.pSearchKeyword ? this.pSearchKeyword.trim() : '' // Default set Keyword Value
    this.searchScVal    = this.pSearchScVal   ? this.pSearchScVal : '1' // 1:고객명 2:설계명
  },
  // beforeMount() {},
  mounted() {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  destroyed() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_Search
    * 설명        : 조회 액션
    ******************************************************************************/
    fn_Search() {
      let rtnData = {
        parntSrnId : this.parntSrnId , // 부모 화면 ID
        searchScVal : this.searchScVal , // 1:고객명 2:설계명
        searchKeyword : this.searchKeyword.trim() // 입력된 키워드 값
      }
      // 고객명일때 검색팝업(MSPCM129P) 호출
      if(this.searchScVal === '1' && this.searchKeyword !== '') {
        this.fn_OpenMSPCM129P()
      }else {
        this.$emit('onPopupSearch', rtnData)
      }
    },
    /******************************************************************************
    * Function명  : fn_Clear
    * 설명        : 초기화
    ******************************************************************************/
    fn_Clear() {
      this.searchKeyword = ''
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM129P
    * 설명       : 소개자 조회 검색 팝업창에서 '확인' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_OpenMSPCM129P(param) {
      let lv_Vm = this
      lv_Vm.mspcm129p = lv_Vm.$bottomModal.open(MSPCM129P, {
        properties: {
          pCustNm: lv_Vm.searchKeyword.trim() // 고객명 파라미터
        },
        listeners: {
          onPopupConfirm: (pData) => {
            let rtnData = {
              parntSrnId    : lv_Vm.parntSrnId , // 부모 화면 ID
              searchScVal   : '1', // 1:고객명 2:설계명
              searchKeyword : pData.custNm, // 고객명
              chnlCustId    : pData.chnlCustId // 채널 고객ID
            }
            lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
            lv_Vm.$emit('onPopupSearch', rtnData)
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },

    _fn_End(){return} // 코딩 종료 함수
  }
}
</script>