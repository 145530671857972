/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS234P
 * 화면 설명: 설계청약 계약서류발송
 */
<template>
  <ur-page-container class="msp" title="계약서류" :show-title="true" :topButton="false" type="subpage"> <!-- type="popup" -->

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30">
        <span class="fs17rem fwm mb10">발행방식</span>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="returnData[0].key" class="chip-type-wrap">
            <mo-segment-button v-for="(item, idx) in segmentData" :value="item.key" :key="idx" :class="{'maxW50p': rowPopupData['isuMthScCd'] === '02'}">{{item.label}}</mo-segment-button>    
          </mo-segment-wrapper>

        </ur-box-container>
        <div class="full mt30">
          <span class="fs14rem crTy-bk7">다음과 같은 계약서류를 발송합니다.</span>
          <div class="text-gray-box mt10">
            <ul class="terms-list-area crTy-bk7 fs16rem">
              <li>{{planInfoMsg}}</li>
            </ul>
          </div>
        </div>
      </ur-box-container>

    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_Close" class="ns-btn-round white">취소</mo-button>
          <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_SelectItem" class="ns-btn-round blue">확인</mo-button>
        </div>
      </ur-box-container>
    </template>

    <!-- 오류메시지 START -->
    <mo-bottom-sheet ref="refBottomSheetError" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="refBottomSheetError_close" name="닫기"></div>
      </template>
      
      <ur-box-container direction="column" alignV="start" alignH="center" class="con-area">   
        <mo-icon class="popupGuideIcon">errorIcon</mo-icon>
        <div class="popupGuideMessage">{{refBottomSheetError_errorMessge}}</div>
        <div class="popupGuideMessageDetail" v-html="refBottomSheetError_errorMessgeDetail"></div>
        <div class="popupGuideCodeWrap" v-if="refBottomSheetError_errorCode.length">
          <div class="popupGuideToggleBtnWrap" @click="refBottomSheetError_showErrorCode">오류 코드 보기 <mo-icon icon="msp-arrow-down" class="popupGuideToggleBtn" :class="{'toggleUp': refBottomSheetError_errorCodeExpand}"></mo-icon></div>
          <div class="popupGuideCodeDetail" v-if="refBottomSheetError_errorCodeExpand" style="overflow:scroll; height:60vh;" v-html="refBottomSheetError_errorCode"/><!-- {{refBottomSheetError_errorCode}}</div>  -->
        </div>
        <div class="popupGuideLinks">
          <div><img src="@/assets/img/profile/ico_custmer.png" class="popupGuidePhoneIcon"> 문의 <strong>02-311-4500</strong></div>
          <!-- <div @click="fn_chatBotContent"><mo-icon>speechBalloon</mo-icon> 챗봇문의</div> -->
        </div>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <!-- <div class="relative-div" v-if="refBottomSheetError_errorCode.length === 0">
            <mo-button componentid="mo_button_004" color="primary" shape="solid" size="medium" @click="close" class="ns-btn-round white">인쇄</mo-button>
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" class="ns-btn-round blue">미리보기</mo-button>
          </div>
          <div class="relative-div" v-if="refBottomSheetError_errorCode.length">
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" class="ns-btn-round blue">확인</mo-button>
          </div> -->
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" @click="refBottomSheetError_close" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 오류메시지 END -->

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSSha256 from '@/ui/ps/comm/PSSha256'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS234P', 
  screenId: 'MSPPS234P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pRowPopupData: {type: Object}
  },//props

  
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      isVariable: false, // 변액보험 여부
      //isShowAlert: false,
      //msgDesc: '',
      //completeType: '',
      //negativeButtonLabel: '',
      //positiveButtonLabel: '',
      segmentData: [
        {'key': '01', 'label': '알림톡'},
        {'key': '02', 'label': '이메일'},
        {'key': '03', 'label': '지점인쇄'}
      ],
      returnData: [{'key': '01', 'label': '알림톡'}],
      planInfoMsg: '',
      extInfoAddr: {},
      joinDate: 0,
      visible01 : false,
      visible02 : false,
      visible03 : false,
      refBottomSheetError_errorMessge: '', // '인쇄할 수 없음', //미리보기 또는 인쇄 후, 진행 가능
      refBottomSheetError_errorMessgeDetail: '', // '발행서버PC 재부팅 후, 재실행 필요',//'고객에게 상품설명 후, 진행 가능합니다.<br>(계약자 기준 5일이내  해당상품 청약서를 활용한 정보 필요)'
      refBottomSheetError_errorCode: '', //'error code: 11223047 23098s d282dhjks 11223047 23098s d282dhjks',
      refBottomSheetError_errorCodeExpand: false
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.rowPopupData = (this.pRowPopupData)? this.pRowPopupData : {}
    console.log('==================== rowPopupData =================')
    console.log(JSON.stringify(this.rowPopupData))
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_init()

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {},//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/
    fn_init: function () {
      // 20181227 박성배선임 요청사항으로 지점인쇄 모두 활성화 처리
     if (this.rowPopupData['isuMthScCd'] === '01') { // E-mail
        this.segmentData = [{'key': '02', 'label': '이메일'}, {'key': '03', 'label': '지점인쇄'}]
        this.returnData = [{'key': '02', 'label': '이메일'}]
      } else if (this.rowPopupData['isuMthScCd'] === '02') { // 서면
        this.segmentData = [{'key': '03', 'label': '지점인쇄'}]
        this.returnData = [{'key': '03', 'label': '지점인쇄'}]
      } else if (this.rowPopupData['isuMthScCd'] === '03') { // 알림톡(e)
        this.segmentData = [{'key': '01', 'label': '알림톡'}, {'key': '02', 'label': '이메일'}, {'key': '03', 'label': '지점인쇄'}]
        this.returnData = [{'key': '01', 'label': '알림톡'}]
      } else if (this.rowPopupData['isuMthScCd'] === '04') { // 알림톡
        this.segmentData = [{'key': '01', 'label': '알림톡'}, {'key': '03', 'label': '지점인쇄'}]
        this.returnData = [{'key': '01', 'label': '알림톡'}]
      } // end else if

      if (this.returnData[0]['key'] === '01' || this.returnData[0]['key'] === '02') { // 알림톡, 이메일
        this.planInfoMsg = this.isVariable ? '변액운용설명서' : '보험약관 / 계약자보관용청약서 등'
      } else if (this.returnData[0]['key'] === '03') { // 지점인쇄
        this.planInfoMsg = '계약자보관용청약서'
      } // end else if
      this.joinDate = PSDateUtil.intervalDate(this.rowPopupData['joinDate'], PSDateUtil.fn_CurrentDate())
      // 2020.03.05 최주연 프로
      // 완료건 조회기간 변경 15일 -> 2년
      // if (this.joinDate >= 15) {
      // this.fn_AlertMsg('close', '청약일로부터 15일이 지난 자료는\n상품설명서(제안용)가 유효하지 않습니다.', '확인')
      if (this.joinDate >= 730) {
        //this.fn_AlertMsg('close', '청약일로부터 2년이 지난 자료는\n상품설명서(제안용)가 유효하지 않습니다.', '확인')
        this.getStore('confirm').dispatch('ADD', '청약일로부터 2년이 지난 자료는</br>상품설명서(제안용)가 유효하지 않습니다.')
        this.fn_Close()
      } else {
        // 채널고객ID 세팅 보완 (ASR240700774 / 240820 PJO)_START
        if( this.rowPopupData['mobslChnlCustId'].trim().length > 0) {
          this.extInfoAddr = {}
          this.extInfoAddr['printDate'] = '-'
          this.extInfoAddr.chnlCustId = this.rowPopupData['mobslChnlCustId'].trim()
          this.fn_ServiceData('S1') // S0 -> S1
        } else {
          this.getStore('confirm').dispatch('ADD', '채널고객ID확인필요')
        }
        // (ASR240700774 / 240820 PJO)_END
      } // else end if
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      switch (serviceName) {
        case 'S0': // 전자문서ID로 채널고객ID 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29S3'))
          pParams.data = {}
          /* FC 사번 */
          pParams.data.vuchNo = this.rowPopupData['vuchId'] // 영수증번호
          pParams.data.custNm = this.rowPopupData['mnContrNm'] // 계약자명
          break
        case 'S1': // 고객 상세 정보
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4'))
          pParams.data = {}
          pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트 번호
          pParams.data.chnlCustId = this.extInfoAddr['chnlCustId'] // 채널 고객 ID
          pParams.data.custNm = this.rowPopupData['mnContrNm'] // 고객명
          break
        case 'S2': // 출력용 기타 정보 조회(I/F)
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29S1'))
          pParams.data = {}
          pParams.data.planId = this.rowPopupData['vuchId'] // 영수증번호
          break
        case 'S3': // 알림톡완전판매 LMS 처리
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29P2'))
          pParams.data = {}
          pParams.data.chnlCustId = this.extInfoAddr['chnlCustId'] // 채널고객ID
          pParams.data.eltrnDoctId = this.rowPopupData['vuchId'] // 전자문서ID
          pParams.data.vuchNo = this.rowPopupData['vuchId'] // 영수증번호
          pParams.data.smsCelno = this.extInfoAddr['celno'] // 휴대폰번호
          let str = String(this.extInfoAddr['celno']).replace(/\-/g, '')
          str = str + 'LMS'
          var input = PSSha256.computeDigest(str)
          pParams.data.smsCelnoHash = input // 휴대폰번호 Hash (SHA-256 암호화 필요)
          if (this.rowPopupData['aftRctmHopYn'] === 'Y') {
            pParams.data.zzaftRctmFg = 'Y' // 후입금여부 Y
          } else {
            pParams.data.zzaftRctmFg = 'resend' // 재전송 처리 (ASR200400666_계약서류발송 "재발송"시 변액운용설명서 첨부로직 수정_2020.04.16 PJO)
          }
          break
        case 'S4': // 가설/계약서류 이메일발송
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29E1'))
          pParams.data = {}
          pParams.data.regntEno = PSServiceManager.getUserInfo('userId') // 등록자사번(모집컨설턴트ID)
          pParams.data.emailAddr = this.extInfoAddr['emailAddr'] // E-mail 주소
          pParams.data.custNm = this.rowPopupData['mnContrNm'] // 수신자명
          pParams.data.eltrnDoctId = this.rowPopupData['vuchId'] // 전자문서ID
          pParams.data.rrno = this.extInfoAddr['rrno'] // 주민등록번호
          break
        case 'S5': // 계약서류 출력
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29T1'))
          pParams.data = {}
          pParams.data.eltrnDoctId = this.rowPopupData['vuchId'] // 전자문서ID
          pParams.data.aplrEno = PSServiceManager.getUserInfo('userId') // 신청자사번
          pParams.data.aplrEnm = PSServiceManager.getUserInfo('userNm') // 신청자명(설계사명)
          pParams.data.prdtNm = this.rowPopupData['mnPrdtNm'] // 상품명
          pParams.data.pdfFileFullNm = this.extInfoAddr['pdfFileFullNm'] // 파일 path가 포함된 PDF파일명
          break
        case 'S6': // 계약서류 출력 후처리
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29P1'))
          pParams.data = {}
          pParams.data.planId = this.rowPopupData['vuchId'] // 문서ID
          break
        case 'printList': // 계약서류 목록 호출
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29S0'))
          pParams.data = {}
          pParams.data.clctCnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트 번호
          pParams.data.custNm = this.rowPopupData['mnContrNm'] // 고객명
          pParams.data.rrno = this.extInfoAddr['rrno'] // 고객주민등록번호
          pParams.data.inqYm = PSDateUtil.fn_DateFormat(this.rowPopupData['joinDate'], 'yyyyMM') // 조회연월
          pParams.data.inqrObjScCd = '04' // 입력구분코드
          break
        default:
          break
      } // end switch
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S0': // 전자문서ID로 채널고객ID 조회
          this.extInfoAddr = {}
          this.extInfoAddr['printDate'] = '-'
          this.extInfoAddr.chnlCustId = lv_data.chnlCustId
          this.fn_ServiceData('S1')
          break
        case 'S1': // 고객 상세 정보
          this.extInfoAddr.knb = lv_data.knb // 주민번호
          this.extInfoAddr.smsReciCnsntYn = lv_data.smsReciCnsntYn // SMS수신동의여부
          this.extInfoAddr.emailReciCnsntYn = lv_data.emailReciCnsntYn // 이메일수신동의여부
          this.extInfoAddr.celno = lv_data.celno // 휴대폰번호
          this.extInfoAddr.emailAddr = lv_data.emailAddr // 이메일주소
          break
        case 'S2': // 출력용 기타 정보 조회
          // this.extInfoAddr.emailAddr = lv_data.fstEmailAddr // 최초이메일주소
          this.extInfoAddr.rrno = lv_data.rrno // 주민등록번호
          this.extInfoAddr.eltrnDoctFileNm = lv_data.eltrnDoctFileNm // 파일이름
          this.extInfoAddr.eltrnDoctFilePathNm = lv_data.eltrnDoctFilePathNm // 파일경로
          this.extInfoAddr.pdfFileFullNm = lv_data.pdfFileFullNm // pdf 풀경로이름
          if (this.extInfoAddr.emailAddr.trim() !== '') {
            this.fn_ServiceData('S4')
          } else {
            //this.getStore('toast').dispatch('ADD', '이메일 주소 등록이 필요합니다.\n[고객카드]로 이동하셔서 이메일을 등록하시기 바랍니다.')
            //고객카드로 이동
            let toMsg =  '이메일 주소 등록이 필요합니다.</br>[고객카드]로 이동하셔서 이메일을 등록하시기 바랍니다.'
            this.fn_BottomConfirm('', toMsg)
          } // end else if
          break
        case 'S3': // 알림톡완전판매 LMS 처리
          if (lv_data.rtnValue === '00') {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC006']) // '계약서류가 알림톡로 발송되었습니다.'
          } else if (lv_data.rtnValue === '95') {
            //this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC033']) // '기존 고객카드에 등록된 휴대폰 번호와 일치하지 않습니다. 수정 후, 재등록 해주시기 바랍니다.')
            //고객카드로 이동
            this.fn_BottomConfirm('', this.$t('ps')['EPSC033'])
          } else {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC034']) // '시스템 오류가 발생했습니다. 관리자에게 문의하여 주세요.')
          } // end else if
          break
        case 'S4': // 가설/계약서류 이메일발송
          if (lv_data.wkRslt === 0) {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC010']) // '계약서류가 이메일로 발송되었습니다.
          } else {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC035']) // '계약서류가 이메일로 발송이 실패하였습니다.')
          } // end else if
          break
        case 'S5': // 계약서류 출력
          if (lv_data.wkRslt === 1) { // 작업결과 (1인경우 성공)
            if (this.extInfoAddr['printDate'].trim() === '' || this.extInfoAddr['printDate'].trim() === '-') {
              // 발행일자가 없을경우 계약서류 출력 후 처리 !!  2012-05-07
              this.fn_ServiceData('S6')
            } else {
              this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC008']) // '계약서류가 인쇄되었습니다.')
            } // end else if
          } else {
            // this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC008']) // '계약서류가 인쇄에 실패하였습니다.')
            // 보험거래문서디지털화 : 실패시에도 성공 메세지 코드 쓰고 있는 오류 수정 -> 실패시에는 e-form 에서 보내준 실패메세지 출력
            this.getStore('toast').dispatch('ADD', lv_data.wkRsltMsg)
          } // end else ifㅊ
          break
        case 'S6': // 계약서류 출력 후처리
          if (lv_data.successYn === 'N') {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC036']) // '계약서류가 인쇄 하였으나\n발행일자를 가져올 수 없습니다.')
          } else {
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC008']) // '계약서류가 인쇄되었습니다.')
          } // end else if
          break
        case 'printList': // 전자서명 발송/출력 목록 조회
          if (lv_data.successYn === 'Y') {
            let dCRTrans02VO = lv_data.dcrtrans02VO
            if (dCRTrans02VO !== null) {
              for (let i = 0; i < dCRTrans02VO.length; i++) {
                const element = dCRTrans02VO[i]
                // 서면이고 같은 영수증 번호 일때
                if (element.fstIsuMthScCd === '02' && element.planId === this.rowPopupData['vuchId']) {
                  this.extInfoAddr['printDate'] = element.prtYmd
                  break
                } // end else if
              } // end for i
            } // end if
          } // end else if
          this.fn_ServiceData('S5')
          break
        default:
          break
      }
     
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      switch (serviceName) {
        case 'S0': // 전자문서ID로 채널고객ID 조회
          break
        case 'S1': // 전자문서ID로 채널고객ID 조회
          // (ASR240700774 / 240820 PJO)
          // IV_AGT_FILE_ID 데이터가 없습니다.  → 청약 후 해당 고객카드를 삭제 혹은 변경시 계약서류(약관/청약서) 재발송이 불가합니다.
          if (event.msgDesc.replace(/[ .]/g, "") === 'IV_AGT_FILE_ID데이터가없습니다') {
            // event.msgDesc = '청약 후 해당 고객카드를 삭제 혹은 변경시 \n계약서류(약관/청약서) 재발송이 불가합니다.'
            this.refBottomSheetError_errorMessge = '재발송 불가' // 타이틀
            this.refBottomSheetError_errorMessgeDetail = '청약 후 해당 고객카드를 삭제 혹은 변경시<\p>계약서류(약관/청약서) 재발송 불가' // 본문
            this.refBottomSheetError_errorCode = '' // 오류코드
            this.refBottomSheetError_errorCodeExpand = false // 오류코드보기 펼침
            this.$refs.refBottomSheetError.open()
          }
          // this.getStore('confirm').dispatch('ADD', event.msgDesc)
          break
        case 'printList': // 전자서명 발송/출력 목록 조회
          this.fn_ServiceData('S5')
          break
        default:
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          break
      }
    },
    /*************************************************************************
     * 함수 : refBottomSheetError_showErrorCode
     * 설명 : 사랑On 개선 - 오류메시지 수정: 오류코드보기 클릭시 처리
     *************************************************************************/
    refBottomSheetError_showErrorCode() {
      if(this.refBottomSheetError_errorCodeExpand === true) this.refBottomSheetError_errorCodeExpand = false
      else if(this.refBottomSheetError_errorCodeExpand === false) this.refBottomSheetError_errorCodeExpand = true
    },
    /*************************************************************************
     * 함수 : refBottomSheetError_close
     * 설명 : 사랑On 개선 - 오류메시지 수정: 알림 메시지 닫기 처리
     *************************************************************************/
    refBottomSheetError_close() {
      this.$refs.refBottomSheetError.close()
    },
    /******************************************************************************
    * Function명 : fn_chatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_chatBotContent () {
      let lv_Url = ''

      // 20220706 [ASR220600878] 사랑On 미니챗봇 스크립트 변경
      // 검증계 https://slimsg.samsunglife.com/messenger/?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC
      // 운영계 https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=사번
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
        lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      // } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
      //   lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_SelectItem: function () {
      if (this.returnData[0]['key'] === '01') { // 알림톡
        // 알림톡로 계약서류를 발송하시겠습니까?
        //this.fn_AlertMsg('01', this.$t('ps')['EPSC005'], '아니오|예')
        this.fn_BottomConfirm('01', this.$t('ps')['EPSC005'])
      } else if (this.returnData[0]['key'] === '02') { // 이메일
        // 이메일로 계약서류를 발송하시겠습니까?
        //this.fn_AlertMsg('02', this.$t('ps')['EPSC009'], '아니오|예')
        this.fn_BottomConfirm('02', this.$t('ps')['EPSC009'])
      } else if (this.returnData[0]['key'] === '03') { // 지점인쇄
        // 계약서류를 인쇄하시겠습니까?
        //this.fn_AlertMsg('03', this.$t('ps')['EPSC007'], '아니오|예')
        this.fn_BottomConfirm('03', this.$t('ps')['EPSC007'])
      } // end else if
    },

    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : Confirm 팝업 호출
     ******************************************************************************/
    fn_BottomConfirm (type, contents) {
      let lv_Vm = this

      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
        onPopupConfirm: () => {
          if (type !== '') {
            this.$bottomModal.close(this.lv_AlertPop);
            setTimeout(function () {
            lv_Vm.onPositive(type)
            }, 500)
          } else {
            this.$bottomModal.close(this.lv_AlertPop);
            let prams = {
              callerId : this.$options.screenId, // MSPPS231D
              viewId : 'MSPCM271D',
              chnlCustId : this.rowPopupData.insrdChnlCustId,
              cnsltNo : PSServiceManager.getUserInfo('userId'),
            }
            this.$MenuManager.fn_RouterPushSrnId('MSPCM270M', prams) //고객카드로 이동 화면인지 팝업인지 확인필요!!!!!!!
            this.fn_Close()
          }
        },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
   },

    /************************************************************************************************
     * Function명  : onPositive
     * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백
     ************************************************************************************************/
    onPositive (type) {
      if (type === '01') { // 알림톡
        this.fn_ServiceData('S3')
      } else if (type === '02') { // 이메일
        this.fn_ServiceData('S2')
      } else if (type === '03') { // 지점인쇄
        // 서면일떄 출력 이력 조회 확인
        if (this.rowPopupData['isuMthScCd'] === '02') {
          this.extInfoAddr['printDate'] = ''
          this.fn_ServiceData('printList')
        } else {
          this.fn_ServiceData('S5')
        } // end if
      } else if (type === 'close') { // 팝업 종료
        this.fn_Close()
      } // end else if
    },

    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      this.$emit('onPopupClose', 'onPopupClose')
    },


    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>