/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM043M
 * 화면설명 : 상세정보 입력
 * 작 성 일 : 2022.11.28
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="상세정보 입력" :show-title="true" type="subpage" @on-header-left-click="fn_BackBtn">
    <ur-box-container class="msp-cm-wrap" direction="column" alignV="start">

      <!-- Content 영역 ::start -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-area mb40">
        
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="gray-box">
          <span> {{ custCardVO.custNm }}({{ custCardVO.knbFrno }})</span><em>|</em><span> {{ custTypeNm }}</span>
        </ur-box-container>

        <!-- 외국인정보 영역 -->
        <template v-if="isForeigner">
          <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-input-box pb10">
            <span class="ns-title must mb0">영문명</span>
            <mo-text-field v-model="lv_CustEngNm" ref="custEngNm" class="form-input-name" underline placeholder="외국인등록증상의 성명을 입력바랍니다" :disabled="!!custCardVO.natyCd" :rules="CheckCustEngNmRule" @input="fn_InputCustEngNm" />
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-input-box pb10">
            <span class="ns-title must mb0">국적정보</span>
            <div class="w100">
              <mo-text-field v-model="custCardVO.natyNm" class="form-input-name" :disabled="!!custCardVO.natyCd" readonly underline placeholder="국적조회 클릭시 국적 자동반영"/>
              <mo-button class="ns-btn-round white sm" style="position: absolute; right: 25px;" :disabled="!!custCardVO.natyCd" @click="fn_SelNatyInfo">국적조회</mo-button>
            </div>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-agreeArea2 pb50">
            <div class="agree-box2">
              <div class="ns-segment ns0000001">
                <mo-segment-wrapper v-model="lv_DmclScCd" solid primary style="margin: 0;">
                  <mo-segment-button value="01">국내거주</mo-segment-button>
                  <mo-segment-button value="02">국외거주</mo-segment-button>
                </mo-segment-wrapper>
              </div>
            </div>
          </ur-box-container>
        </template>

        <!-- 직업 영역 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="info-box pb0 mb0">
          <div class="info-title-wrap mb16">
            <span class="info-title must">직업</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
            <div class="ns-radio-list">
              <mo-radio class="mb22" v-for="item in jobMains" v-model="selcJobMain" :key="item.key" :value="item.key" @input="fn_CheckSelectedItem(item)">
                {{ item.label }}
              </mo-radio>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 다른 직업 찾기-->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="info-box mt0">
          <msp-bottom-select ref="bottomSelect" :items="jobEtcs" v-model="fn_JobEtc" underline class="ns-dropdown-sheet" placeholder="기타" bottom-title="기타" closeBtn/>
          <mo-text-field v-if="isJobSearch" v-model="lv_JobText" searchable underline placeholder="다른 직업 찾기" readyonly
                         @click="fn_OpenMSPCM013P" @click-icon="fn_OpenMSPCM013P"/>
        </ur-box-container>

        <!-- 취득경로 ::start -->
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="info-box">
          <div class="info-title-wrap mb16">
            <span class="info-title must">취득경로</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column">
            <div class="ns-radio-list">
              <mo-radio class="mb22" v-model="entryPath" value="acutnCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">지인</mo-radio>
              <mo-radio class="mb22" v-model="entryPath" value="intdr" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">소개</mo-radio>

              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-box" v-show="entryPath === 'intdr'">
                <div class="ns-date-picker multi">
                  <!-- 소개자 검색 -->
                  <mo-text-field v-model="custCardVO.custNm01" searchable underline placeholder="소개자 검색" :disabled="disabledYN.entryDisabd" 
                    @click-icon="fn_SelIntdr" @keyup.enter="fn_SelIntdr" @focus="fn_IsNextBtnVisible(false)" @blur="fn_BlurIntdr"/><span></span>
                  <mo-date-picker v-model="custCardVO.introYmd" placeholder="소개일" :max-date="nowDate" :disabled="disabledYN.entryDisabd"/>
                </div>
              </ur-box-container>

              <mo-radio class="mb22" v-model="entryPath" value="aptCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">개척</mo-radio>
              <mo-radio class="mb22" v-model="entryPath" value="rspnDstrtCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">기타</mo-radio>
            </div>
          </ur-box-container>
        </ur-box-container>
        <!-- 취득경로 ::end -->

        <!-- 세대원동의진행 영역 -->
        <ur-box-container alignV="center" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">세대원동의진행</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_ConfirmOpen6">세대추가</mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list :list-data="iCHoshList">
              <template #list-item="{item}">
                <!--//START 레이블이 다른 TYPE -->
                <mo-list-item ref="bs01" mode="none" class="closebtn mb10">
                  <ur-box-container alignV="start" direction="column" class="list-item__contents">
                    <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                      <span class="name txtSkip"> {{ item.custNm }}</span>
                      <!-- 세대원 삭제 버튼 -->
                      <div class="ns-btn-close" name="닫기" @click="fn_ConfirmOpen2(item)"></div>
                    </ur-box-container>
                    <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                      <div class="txt_box">
                        <div>
                          <small>관계</small>
                          <span> {{ $cmUtil.getCustRltnNm(item.mnCustRltnCd) }}</span><em>|</em>
                          <small>생년월일</small>
                          <span>{{ item.dob !== '00000000' ? $bizUtil.dateDashFormat((item.dob)) : '0000-00-00' }}</span>
                        </div>
                        <div v-if="item.isForig">
                          <small>국적</small><span> {{ item.bpNatyNm }}</span>
                        </div>
                      </div>
                    </ur-box-container>
                  </ur-box-container>
                </mo-list-item>
                <!--//END 레이블이 다른 TYPE -->
              </template>
            </mo-list>
          </ur-box-container>  
        </ur-box-container>
        <!-- 세대원동의진행 영역 -->

        <!-- 주소 영역 ::start -->
        <ur-box-container alignV="center" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title must">주소</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_OpenMSPCM014P">
              <!-- 자택/직장 주소 모두 있을때 수정버튼 -->
              {{ !$bizUtil.isEmpty(custCardVO.homAddr) && !$bizUtil.isEmpty(custCardVO.jobpAddr) ? '수정' : '입력' }}
            </mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list-item ref="bs01" mode="none" class="closebtn">
              <ur-box-container alignV="start" direction="column"  class="list-item__contents">
                <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                  <span class="name addr">자택</span>
                  <!-- 주소 삭제 버튼 -->
                  <div class="ns-btn-close" name="닫기" v-if="!$bizUtil.isEmpty(custCardVO.homAddr)" 
                        @click="fn_ConfirmOpen3('home')" />
                </ur-box-container>
                <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                  <div class="info_box inflex">
                    <span class="address">
                      {{ custCardVO.homFrnoPstcd }}
                      {{ custCardVO.homAddr }} 
                      {{ custCardVO.bldgNo01 }} 
                      {{ custCardVO.homDtlad }} 
                      {{ custCardVO.adtnAddr01 }} 
                    </span>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </mo-list-item>
            <mo-list-item ref="bs01" mode="none" class="closebtn">
              <ur-box-container alignV="start" direction="column"  class="list-item__contents">
                <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                  <span class="name addr">직장</span>
                  <!-- 주소 삭제 버튼 -->
                  <div class="ns-btn-close" name="닫기" v-if="!$bizUtil.isEmpty(custCardVO.jobpAddr)" 
                        @click="fn_ConfirmOpen3('job')" />
                </ur-box-container>
                <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                  <div class="info_box inflex">
                    <span class="address">
                      {{ custCardVO.jobpFrnoPstcd }} 
                      {{ custCardVO.jobpAddr }} 
                      {{ custCardVO.bldgNo02 }} 
                      {{ custCardVO.jobpDtlad }} 
                      {{ custCardVO.adtnAddr02 }} 
                    </span>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </mo-list-item>

          </ur-box-container>  
        </ur-box-container>
        <!-- 주소 영역 ::end -->
      </ur-box-container>
      <!-- Content 영역 ::end -->

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative" v-if="isNextBtnVisible">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" 
                  @click="fn_OpenMSPCM044P()" >동의서발행</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
                  @click="fn_CallCmplt()" >다음</mo-button>
                  <!-- txTSSCM10I0 인터페이스 호출 -->
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- BottomSheet 영역 ::start -->
    <!--  안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetConfirmCard" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment pt0 left">
          - 정보활용동의 승인처리가 완료되어야 고객카드가 생성됩니다.
          지점장ㆍ주임에게 동의서를 제출하거나,모바일 동의를 진행하시기 바랍니다.<br>
          <br>
          - 5일이내 미승인 시,본 승인요청건은 자동삭제됩니다.
          {{ lv_AbleDate }}까지 유효({{ lv_DeletDate }} 00시 00분 삭제예정)<br>
          <br>
          - '미승인고객조회'메뉴에서 각 승인요청건의 자동삭제 예정일을 조회할 수 있습니다.<br>
          <br>
          이름과 주민등록번호를 정확히 확인하셨습니까?
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BottomSheetHandler('ConfirmCard', 'close')">아니요</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_BottomSheetHandler('ConfirmCard', 'close', true)">예</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 저장 전 동의 안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetSaveBfCnfrm" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment pt0 left">
          1. 계약자가 동의한 경우에만 변경 가능합니다.<br>계약자의 동의를 받으셨습니까?<br>
          2. 지금 입력하신 정보는 삼성생명 온라인에 반영되며 변경 내용이 고객님께 안내(SMS, e-Mail) 됩니다.<br>
          반영하시겠습니까?<br>
          (취소시 이전데이타 복구)<br>
          ※ 동의 없이 변경할 경우 민원발생 소지가 있으니 주의 바랍니다.
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BottomSheetHandler('SaveBfCnfrm', 'close')">아니요</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_BottomSheetHandler('SaveBfCnfrm', 'close', true)">예</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
        
    <!-- 종료 안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetBackCnfrm" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment pt0">
          고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BottomSheetHandler('BackCnfrm', 'close')">취소</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_BottomSheetHandler('BackCnfrm', 'close', true)">나가기</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- BottomSheet ::end -->
  </ur-page-container>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import CmConstants from '@/config/constants/cmConstants'

import MSPCM013P from '@/ui/cm/MSPCM013P' // 보험직업조회
import MSPCM014P from '@/ui/cm/MSPCM014P' // 주소입력 (추가정보입력)
import MSPCM049P from '@/ui/cm/MSPCM049P' // 세대원 입력
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객 조회
import MSPCM044P from '@/ui/cm/MSPCM044P' // 동의서발행 팝업

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM043M',
  // 현재 화면 ID
  screenId: 'MSPCM043M',
 
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '상세정보 입력', // 화면명
      lv_UserInfo: {}, // 사용자 정보

      iCHoshList: [],
      iCHoshInfoList: [],
      isChangeCust: false, // 고객정보 수정 여부
      title: '고객정보 입력 메인 화면', // 화면명
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      custTypeNm: '신규', // 신규 or 기존 구분
      searchJobcdNm: '', // 검색직업명

      agreeTypeMcnstList: {}, // 필수 컨설팅 리스트
      agreeTypeMktList: {}, // 마케팅 동의 리스트
      infoPrcusCnsntTypCd: '', // 정보활용동의 유형 코드
      selcAgreeTypeMcnst: false, // 필수컨설팅동의 선택여부
      selcAgreeTypeMkt: false, // 마케팅동의 선택여부
      intrResult: true, // 소개자 유효성 체크 결과
      isForeigner: false, // 외국인 체크 여부

      niceAuthResultMsg: '', // 나이스인증결과 메시지

      /* 기본값 설정 */
      selcJobMain: '', //선택한 직업메인 (default: 회사 사무직 종사자)
      jobEtc: {value: '0', text: '그 외 직업'}, //직업 기타
      entryPath : '', //경로 (default: 기타)

      //팝업 연동
      popupComp: {},
      lv_JobText: '',

      // 주고객 정보 VO
      custCardVO: {
        homFrnoPstcd: '',  // 자택 주소 (화면 렌더링 용도)
        jobpFrnoPstcd: '', // 회사 주소 (화면 렌더링 용도)
        
      },
      // 고객 조회용 Service IF VO
      custCardRegMdfcVO: {},
      // 주고객 조회용 Service IF VO
      mnCustCardRegMdfcVO: {},
      // 기존 세대원 리스트
      existHoshInfoListInqrVO: [],
      // 신규 세대원 리스트
      newHoshInfoListInqrVO: [],
      // 기존 세대원 저장용 리스트
      saveExistHoshInfoListInqrVO: [],
      // 신규 세대원 저장용 리스트
      saveNewHoshInfoListInqrVO: [],
      disabledYN: {
        entryDisabd: false
      },

      isJobSearch: false, // 다른직업 찾기 영역
      isBackBtn: false, // 스마트폰 back 버튼

      isNextBtnVisible: true, // 다음 버튼 보이게 할지 여부 처리 변수
      searchRsltMsg: '신규고객', // 고객카드조회 결과 메시지

      lv_msgAddHoshOnly: '',
      /* 공통 코드  */
      jobMains: CmConstants.JOB_MAIN_ITEM_CD,
      jobEtcs: CmConstants.JOB_ETCS_ITEM_CD,
      addrType: '',
      addrTypes: [
        {value: '11', text: '자택'},
        {value: '12', text: '직장'}
      ],
      entryPaths: CmConstants.ENTRY_PATH_CD,
      custForigData: [],

      lv_AbleDate : '',  // 승인유효일자
      lv_DeletDate : '',  // 삭제예정일자

      
      natyCdList: [], // 국적 공통코드 목록
      lv_CustEngNm: '', // 영문명
      lv_DmclScCd: '', // 거주구분

      /* Validation */
      isNotEmptyCustEngNm: true,
      isValidCustEngNm: true,
      CheckCustEngNmRule: [
        v => (this.isNotEmptyCustEngNm = !!v, !!v) || '영문명은 필수값 입니다.',
        v => (this.isValidCustEngNm = this.$bizUtil.isCustNameValidation(v, 'ZPER02')) || this.$t('cm')['ECMC055'] // '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)',
      ],
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                              *
  ***********************************************************************************/
  watch: {
    'custCardVO.custNm01' () { // 소개자를 clearable로 한꺼번에 지울경우 computed의 validation 함수를 탈수 있도록 추가 조치함.
      if (this.entryPath === 'intdr' && this.$bizUtil.isEmpty(this.custCardVO.custNm01)) {
        this.intrResult = false // 소개자 조회
        this.custCardVO.intdrChnlCustId = ''
      }
    },
    lv_DmclScCd (dmclScCd) {
      this.custCardVO.dmclScCd = dmclScCd
      this.custCardVO.bpDmclScCd = dmclScCd
    
      this.custCardVO.dmclScCdNm = dmclScCd === '01' ? '국내' : '해외'
      this.custCardVO.bpDmclScCdNm = dmclScCd === '01' ? '국내' : '해외'
    },
    entryPath () {
      if (this.entryPath !== 'intdr') {
        //소개가 아닐 시 초기화
        this.custCardVO.custNm01 = '' // 소개자명
        this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
        this.custCardVO.custNm01Prev = ''
        this.intrResult = false
        this.custCardVO.introYmd = moment(new Date().toISOString()).format('YYYY-MM-DD')
      }
    }
  },
  /***********************************************************************************
   * computed 정의 영역                                                              *
  ***********************************************************************************/
  computed: {
    fn_JobEtc: { // 직업 기타 셀렉트 박스 선택 시
      get: function () {
        return this.jobEtc
      },
      set: function (data) {
        if (this.custCardVO.jobCd !== data) {
          this.isChangeCust = true
        }

        this.jobEtc = data
        if (data === '0') { // 셀렉트박스의 '그 외' 선택 시 고객카드 VO의 값을 모두 초기화 함
          this.custCardVO.jobCd = ''
          this.custCardVO.jobCdNm = ''
          this.lv_JobText = ''
          this.isJobSearch = true //다른직업찾기 영역 활성화
        } else {
          this.custCardVO.jobCd = data

          let jobEtcNm = this.jobEtcs.filter(val => {
              if (val.value === data) {
                return val
              }
            });

          this.custCardVO.jobCd = jobEtcNm[0].value
          this.custCardVO.jobCdNm = jobEtcNm[0].text
          this.isJobSearch = false //다른직업찾기 영역 비활성화
        }
        this.selcJobMain = '' // 직업 메인 버튼 리스트를 초기화 함
      }
    },
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역                                                                    *
  ************************************************************************************************/
  created() {
    const lv_Vm = this

    // 오늘 날짜 조회
    lv_Vm.$commonUtil.getServerTime().then(function (response) {
      lv_Vm.nowDate = moment(response).format('YYYY-MM-DD')
    })

    // 국적조회 공통코드 세팅
    lv_Vm.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD')
    .then(() => {
      lv_Vm.natyCdList = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')
    })

    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    
    let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
    window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtn)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM043M')

    if (_.isEmpty(this.$route.params)) {
      this.$router.push({name: 'MSPBC002M'})
      return
    }
    this.fn_Init()
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtn)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역                                                                         *
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      const lv_Vm = this

      /** Default 셋팅 **/
      this.selcJobMain = '012212' // 직업 (default: 회사 사무직 종사자)
      this.entryPath = 'rspnDstrtCustYn' // 취득경로 (default: 기타)

      if (!_.isEmpty(lv_Vm.$route.params)) {
        lv_Vm.agreeTypeMcnstList = lv_Vm.$route.params.agreeTypeMcnstList // 필수 컨설팅 리스트
        lv_Vm.agreeTypeMktList = lv_Vm.$route.params.agreeTypeMktList // 마케팅 동의 리스트
        lv_Vm.infoPrcusCnsntTypCd = lv_Vm.$route.params.infoPrcusCnsntTypCd // 정보활용동의 유형 코드

        // 선택한 동의유형 셋팅
        if (!this.$bizUtil.isEmpty(this.infoPrcusCnsntTypCd)) {
          if (lv_Vm.infoPrcusCnsntTypCd === '17') {
            lv_Vm.selcAgreeTypeMcnst = true
          } else if (lv_Vm.infoPrcusCnsntTypCd === '19') {
            lv_Vm.selcAgreeTypeMkt = true
          } else {
            lv_Vm.selcAgreeTypeMcnst = true
            lv_Vm.selcAgreeTypeMkt = true
          }
        }

        if (!_.isEmpty(lv_Vm.$route.params.custCardVO)) {
          Object.assign(this.custCardVO, lv_Vm.$route.params.custCardVO)
          lv_Vm.iCHoshInfoListInqrVO = lv_Vm.custCardVO.iCHoshInfoListInqrVO

          // 외국인 여부
          lv_Vm.isForeigner = lv_Vm.custCardVO.knbScCd === 'ZPER02' ? true : false
          // 기존회원이 외국인이고, 국가정보 없으면 입력 권유 (지류 고객등록시 외국인 정보 입력 받지 않음 2023.04.28)
          // if (lv_Vm.isForeigner) {
          //   if (lv_Vm.$bizUtil.isEmpty(this.custCardVO.natyCd)) {
          //     lv_Vm.fn_BottomSheetHandler('CntryInfo')
          //   } else {
          //     // 여권번호, 국가명
          //     this.custForigData.push({bpPsprtNo: this.custCardVO.bpPsprtNo, bpNatyNm: this.custCardVO.bpNatyNm})
          //   }
          // }

          // 직업
          // 신규 고객일 경우 기본값 셋팅
          if (this.custCardVO.isExistCustYN === 'N') {
            this.custCardVO.jobCd = '012212'
            this.custCardVO.jobCdNm = '회사 사무직 종사자'
            this.custCardVO.mnCustRltnCd = '01' // 주고객관계코드 '01(본인)' 으로 세팅

            // 취득경로 기본 세팅 (default: 취득경로: 기타)
            this.custCardVO.coalCustYn = 'N' // 제휴 (취득경로 선택불가하게 처리)
            this.custCardVO.acutnCustYn = 'N' // 지인
            this.custCardVO.aptCustYn = 'N'   // 개척
            this.custCardVO.rspnDstrtCustYn = 'Y' // 기타
          }

          // 취득경로 날짜
          this.custCardVO.introYmd = this.$bizUtil.isEmpty(this.custCardVO.introYmd) ? '' : this.custCardVO.introYmd
          if (this.custCardVO.introYmd.trim() !== '') { // 소개일이 날짜가 아닌 값이 들어온 경우 ''처리함. (2019.01.04 수정)
            let lv_IntroYmd = this.custCardVO.introYmd.trim().replace(/-/g, '') // dash(-) 제거
            if (lv_IntroYmd === '00000000' || lv_IntroYmd === 'Invalid date' || Number.isNaN(Number(lv_IntroYmd))) {
              this.custCardVO.introYmd = ''
            } else {
              this.custCardVO.introYmd = this.$commonUtil.dateDashFormat(lv_IntroYmd) // 소개일
              this.custCardVO.introYmdPrev = this.custCardVO.introYmd // 소개일 원본 보관
            }
          }

          // 외국인등록정보
          if (this.isForeigner) {
            this.lv_DmclScCd = this.custCardVO.dmclScCd ? this.custCardVO.dmclScCd : '01'
            
            // 국적정보 초기화
            this.custCardVO.custEngNm = ''
            this.custCardVO.natyCd = ''
            this.custCardVO.natyNm = ''
            this.custCardVO.bpCustEngNm = ''
            this.custCardVO.bpNatyCd = ''
            this.custCardVO.bpNatyNm = ''
          }
        }

        // 기존고객 여부를 위해 고객조회 선행 후 진행
        // lv_Vm.fn_isExistCustClick('01')

        // 기존고객일경우에만
        if (this.custCardVO.isExistCustYN === 'Y') {
          this.fn_SetCustData()

          // 세대원 정보 조회(I/F: 고객 카드 조회) :: 여권번호/국적
          let forgHoshList = this.iCHoshList.filter(item => {
              if (item.knbScCd === 'ZPER02') {
                  return item
              }
            });

          for (let i = 0; i < forgHoshList.length; i++) {
            this.fn_SelCustCardInfo(forgHoshList[i].chnlCustId)
          }

          // 승인/미승인 셋팅
          this.iCHoshList.forEach(item => {
              for (let i=0; i< lv_Vm.custCardVO.iCHoshInfoListInqrVO.length; i++) {
                if (item.chnlCustId === lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].chnlCustId) {
                  item.knbFrno = !this.$bizUtil.isEmpty(item.knb) ? item.knb.substr(0,6) : ''
                  item.unAprvCustYn = lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].unAprvCustYn

                  // 국적정보 초기화
                  item.custEngNm = ''
                  item.natyCd = ''
                  item.natyNm = ''
                  item.bpCustEngNm = ''
                  item.bpNatyCd = ''
                  item.bpNatyNm = ''
                }
              }
            });

          // 고객 정보에 저장되어있는 세대원 List
          this.existHoshInfoListInqrVO = [...this.iCHoshList]
          console.log('iCHoshList Setting', this.iCHoshList)
        }
      }

      let today = moment(new Date()).format('YYYY-MM-DD') // 오늘 날짜
      let ableDate = this.$cmUtil.dateCal(today, 'day', 5) // 승인유효일자
      let deletDate = this.$cmUtil.dateCal(today, 'day', 6) // 삭제예정일자

      this.lv_AbleDate = moment(ableDate).format('YYYY-MM-DD')
      this.lv_DeletDate = moment(deletDate).format('YYYY-MM-DD')
    },
    /******************************************************************************
     * @function fn_SelCustCardInfo
     * @name     고객_카드_조회
    ******************************************************************************/
    fn_SelCustCardInfo (chnlCustId) {
      const lv_Vm = this

      let pParams = {
        chnlCustId: chnlCustId, // 채널고객ID
        cnsltNo: this.lv_UserInfo.userId // 컨설턴트 번호
      }

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (res.body !== null) {
            let custCardRegMdfcVO = res.body

            lv_Vm.custCardVO.iCHoshInfoListInqrVO.forEach(item => {
              if (item.chnlCustId === custCardRegMdfcVO.chnlCustId) {
                item.knbBknoCipher = custCardRegMdfcVO.knbBknoCipher // 주민등록번호 뒷자리(암호화)
                item.dmclScCd = custCardRegMdfcVO.dmclScCd // 거주구분
              }
            });
          } else { // 조회 결과가 없는 경우
            if (res.msgComm.msgCd === 'EEAC928') {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('co')['ECOC009']) // '조회하고자 하는 데이터가 없습니다'
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function fn_SetCustData
     * @name     고객_정보_셋팅
     * @notice   기존 고객일경우, 고객정보 셋팅
    ******************************************************************************/
    fn_SetCustData () {
      this.custTypeNm = this.custCardVO.isExistCustYN === 'Y' ? '기존' : '신규'
      // 직업
      // 직업 버튼 처리
      if (this.custCardVO.jobCd.trim() !== '') {
        // 직업 메인 버튼 처리 (key/label)
        let tmpJobMain = this.jobMains.filter((item) => {
            if (item.key === this.custCardVO.jobCd) {
              return item
            }
          });

        this.selcJobMain = !_.isEmpty(tmpJobMain) ? tmpJobMain[0].key : ''

        // 직업 기타 셀렉트 박스 처리 (value/text)
        let tmpJobEtc = this.jobEtcs.filter((item) => {
            if (item.value === this.custCardVO.jobCd) {
              return item
            }
          });

        if (tmpJobEtc.length > 0) {
          this.jobEtc = tmpJobEtc[0].value
        } else {
          if (_.isEmpty(tmpJobMain)) {
            this.jobEtc = this.jobEtcs[0].value
            this.lv_JobText = this.custCardVO.jobCdNm // 직업코드명
            this.isJobSearch = true //다른직업찾기 영역 활성화
          }
        }
      } else {
        if (this.custCardVO.jobCd.trim() === '') { // 직업코드가 없으면 초기화 함.
          this.selcJobMain = '012212' // 직업 (default: 회사 사무직 종사자)
          this.custCardVO.jobCd = '012212'
          this.custCardVO.jobCdNm = '회사 사무직 종사자'
        }
      }

      // 취득경로 세팅
      if (this.custCardVO.coalCustYn === 'Y') { // 제휴 고객인 경우에는 취득경로 선택불가하게 처리(2019.01.04 수정 - ASR190100154)
        this.entryPath = 'coalCustYn'
        this.disabledYN.entryDisabd = true
      } else {
        if (this.custCardVO.acutnCustYn === 'Y') { // 지인
          this.entryPath = 'acutnCustYn'
        } else if (this.custCardVO.aptCustYn === 'Y') { // 개척
          this.entryPath = 'aptCustYn'
        } else if (this.custCardVO.rspnDstrtCustYn === 'Y') { // 기타
          this.entryPath = 'rspnDstrtCustYn'
        } else if (this.custCardVO.intdrChnlCustId.trim() !== '') { // 소개 // 소개자 ID가 있는 경우, 별도의 if로 처리했지만, else if 구문 내로 변경(2019.01.04 수정)
          this.entryPath = 'intdr'
        }
      }

      // 주소
      // 자택, 직장 데이터가 동시에 들어올 경우 자택 주소가 우선 순위임
      if (this.custCardVO.homAddr.trim() !== '') { // 집주소가 먼저 있는지 체크
        this.addrType = this.addrTypes[0].value
      } else if (this.custCardVO.jobpAddr.trim() !== '') { // 직장 주소 체크
        this.addrType = this.addrTypes[1].value
      }
      
      /** 주소, 외국인 정보는 셋팅없이 바로 바인딩 **/

      // 세대원 셋팅 (03:자녀, 04:손자, 08:기타가족)
      this.iCHoshList = this.custCardVO.iCHoshInfoListInqrVO.filter(item => {
          if (item.mnCustRltnCd === '03' || item.mnCustRltnCd === '04' || item.mnCustRltnCd === '08') {
            return item
          }
        });

    },
    /******************************************************************************
    * Function명 : fn_InputCustEngNm
    * 설명       : 영문명 Input 이벤트
    ******************************************************************************/
    fn_InputCustEngNm(val) {
      this.lv_CustEngNm = val?.toUpperCase();
    },
    /******************************************************************************
    * Function명 : fn_SelNatyInfo
    * 설명       : 국적조회
    ******************************************************************************/
    fn_SelNatyInfo () {
      this.$refs.custEngNm.validate()
      if (!this.isNotEmptyCustEngNm || !this.isValidCustEngNm) return;

      const pParams = {
        nameLast: this.lv_CustEngNm, // 영문명
        knbFrno: this.custCardVO.knbFrno, // 외국인등록번호 앞번호 
        knbBknoCipher: this.custCardVO.knbBknoCipherBackup ? this.custCardVO.knbBknoCipherBackup : this.custCardVO.knbBknoCipher, // 외국인등록번호 뒷번호(암호화)
        trtrEno: this.getStore('userInfo').getters.getUserInfo.userId, // 처리자사번
        trtSystmPathDtlVal: 'MSPCM043M', // 처리시스템경로상세값 (EP에서 화면ID로 요청)
      }

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.trtrEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      // 매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      this.post(this, pParams, 'txTSSCM10I6', 'S')
        .then(response => {
          const data = response.body
          if (data) { 
            if (data.trtSc === 'G' && data.respCd === '7001') { // G: 정상, 7001: 등록된 외국인
              // 외국인고객 스크래핑 여부
              this.custCardVO.scrapYn = 'Y'

              const natyObj = this.natyCdList.filter(item => item.key === response.body.natio)
              if (!_.isEmpty(natyObj)) {
                this.custCardVO.custEngNm = this.lv_CustEngNm
                this.custCardVO.bpCustEngNm = this.lv_CustEngNm

                this.custCardVO.natyNm = natyObj[0].label
                this.custCardVO.bpNatyNm = natyObj[0].label

                this.custCardVO.natyCd = natyObj[0].key
                this.custCardVO.bpNatyCd = natyObj[0].key

                this.$forceUpdate()
              } else {
                this.getStore("confirm").dispatch("ADD", '해당 국가 정보가 없습니다.')
              }
            } else {
              this.getStore("confirm").dispatch("ADD", `${this.lv_CustEngNm} 고객에 대한 ${data.mesg.trim() ? `${data.mesg}(으)로 인해` : ''} 재확인 후 다시 조회 바랍니다.`)
            }
          } else {
            this.getStore("confirm").dispatch("ADD", response.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
          this.getStore("confirm").dispatch("ADD", error)
        })
    },
    /******************************************************************************
     * @function fn_CheckSelectedItem
     * @notice   직업 row 선택 시 호출. 선택된 row의 radio value를 변경
    ******************************************************************************/
    fn_CheckSelectedItem (item) {
      if (this.custCardVO.jobCd !== item.key) {
        this.isChangeCust = true
      }

      this.selcJobMain = item.key
      this.custCardVO.jobCd = item.key
      this.custCardVO.jobCdNm = item.label

      //직업 기타 초기화
      this.jobEtc = ''
      this.isJobSearch = false
    },
    /******************************************************************************
     * @function fn_ClickEntryPath
     * @notice   취득경로 버튼 클릭 시 호출
     *              버튼 클릭 시 기존 값 제거 및 신규 값 custCardVO 세팅
    ******************************************************************************/
    fn_ClickEntryPath (data) {
      // 기존인 경우 변경 여부 체크
      if (this.custCardVO.isExistCustYN === 'Y') {
        this.custCardVO.isActPath = true
        this.isChangeCust = true
      }
      this.intrResult = true // 소개자 처리 변수 true로 초기화
      // 기존인 경우 변경 여부 체크
      this.custCardVO.coalCustYn = 'N'
      this.custCardVO.acutnCustYn = 'N'
      this.custCardVO.aptCustYn = 'N'
      this.custCardVO.rspnDstrtCustYn = 'N'

      if (data !== 'intdr') { // 소개가 아닌 경우
        this.custCardVO[data] = 'Y'
      } else {
        // 날짜 적합성 체크
        if (this.custCardVO.introYmd.trim() === '' || this.custCardVO.introYmd === '00000000' || this.custCardVO.introYmd === '0000-00-00') { // 소개일의 기본값이 없는 경우
          this.custCardVO.introYmd = this.$commonUtil.dateDashFormat(this.nowDate) // 오늘 날짜로 세팅
        }
      }
    },
    /******************************************************************************
     * @function fn_SelIntdr
     * @notice   소개자 조회 I/F 호출 후 없는 경우 소개자 검색 팝업 창 호출
    ******************************************************************************/
    fn_SelIntdr () {
      const lv_Vm = this

      if (this.$bizUtil.isEmpty(this.custCardVO.custNm01.trim())) {
        let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '소개자') // {0}은(는) 필수 입력값입니다.
        this.getStore('confirm').dispatch('ADD', lv_Msg)
        return
      } 

      // 소개자 조회 호출 - 고객명으로 조회
      let pParams = { 
        custNm: lv_Vm.custCardVO.custNm01.trim() // 고객명
      }

      const trnstId = 'txTSSCM10S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          let toastMsg = ''
          if (!_.isEmpty(res.body)) {
            if (!_.isEmpty(res.body.cMNewCustRegListVO)) {
              //1건만 있을 경우
              if (res.body.cMNewCustRegListVO.length === 1) { 
                let lv_CustVO = res.body.cMNewCustRegListVO[0]
                lv_Vm.custCardVO.intdrChnlCustId = lv_CustVO.chnlCustId // 채널 고객ID
                lv_Vm.custCardVO.custNm01Prev = lv_Vm.custCardVO.custNm01.trim() // 확정된 소개자 명 원본이름을 보관
                lv_Vm.intrResult = true

                // 토스트 팝업 호출
                toastMsg = '소개자 입력이 완료되었습니다.'
                this.$addSnackbar(toastMsg)
              } else {
                //2건 이상 있을 경우
                if (lv_Vm.entryPath === 'intdr') {
                  // 취득경로가 소개로 선택되어 있는 경우에만 호출하도록 처리. (예: 소개=>지인 선택 시, 포커스 아웃될때 호출되는 경우 있음.)
                  // 2건 이상 조회 시, 고객 검색 팝업창 호출
                  // 고객 조회 팝업창 띄우기
                  lv_Vm.fn_OpenMSPCM129P()
                } else {
                  // 토스트 팝업 호출
                  toastMsg = '취득경로가 변경되어 소개자를 초기화 합니다.'
                  this.$addSnackbar(toastMsg)

                  lv_Vm.custCardVO.custNm01 = '' // 고객명
                  lv_Vm.custCardVO.intdrChnlCustId = '' // 채널 고객ID
                  lv_Vm.custCardVO.custNm01Prev = '' //고객명 backup
                }
              }
            } else {
              if (lv_Vm.entryPath === 'intdr') {
                // 취득경로가 소개로 선택되어 있는 경우에만 알림창 띄우도록 처리. (예: 소개=>지인 선택 시, 포커스 아웃될때 호출되는 경우 있음.)
                // 토스트 팝업 호출
                toastMsg = '입력된 소개자는 등록되어 있지 않습니다.'
                this.$addSnackbar(toastMsg)
              }
              lv_Vm.custCardVO.custNm01 = '' // 고객명
              lv_Vm.custCardVO.intdrChnlCustId = '' // 채널 고객ID
              lv_Vm.custCardVO.custNm01Prev = '' //고객명 backup
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*****************************************************************************************
     * @function fn_IsNextBtnVisible
     * @notice   다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
    *****************************************************************************************/
    fn_IsNextBtnVisible (val) {
      if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
        this.isNextBtnVisible = val
      }
    },
    /************************************************************************************
     * @function fn_BlurIntdr
     * @notice   포커스 out 시 호출 - 소개자 명이 공백인 경우 고객명, 채널고객ID 모두 초기화
    ************************************************************************************/
    fn_BlurIntdr () {
      if (this.custCardVO.custNm01.trim() === '') { // 소개자명을 지울 경우 초기화 함
        this.custCardVO.custNm01 = '' // 고객명
        this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
        this.custCardVO.custNm01Prev = ''
        this.intrResult = false // 소개자 조회
      }

      if (this.$commonUtil.isMobile) { // 모바일일 경우만 다음버튼 활성화 되게 처리
        this.fn_IsNextBtnVisible(true)
      }
    },
    /*********************************************************
     * @function fn_HistoryBack
     * @notice   스마트폰 back 버튼 클릭 시 호출
    *********************************************************/
    fn_HistoryBack () { // 스마트폰의 back 버튼 선택 시 처리

      if (isBackBtn) {
        this.isBackBtn = false
        this.fn_BottomSheetHandler('BackCnfrm', 'close')
      } else {
        this.isBackBtn = true
        this.fn_BottomSheetHandler('BackCnfrm')
      }
    },
    /******************************************************************************
     * @function fn_PrcsConfirmMsg
     * @notice 변경 전 계약자 동의 띄우기 / 화면 기준 유효성 체크
    ******************************************************************************/
    fn_PrcsConfirmMsg () {
      let lv_RetVal = this.fn_ValidateBeforeSaveCust()

      if (lv_RetVal) {
        if (this.custCardVO.chnlCustScCd === '03' || this.custCardVO.chnlCustScCd === '04' || this.custCardVO.chnlCustScCd === '05') { 
          // 계약 고객이면서, 정보 변경시 알림창 띄우기
          this.fn_BottomSheetHandler('SaveBfCnfrm')
        } else {
          //고객 연락처와 실제 고객 번호 안내 팝업
          this.fn_ConfirmOpen()
        }
      }
    },
    /******************************************************************************
     * @function fn_ValidateBeforeSaveCust
     * @notice 저장버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_ValidateBeforeSaveCust () {
      let lv_RetVal = true
      let Msg = ''

      //직업
      if ( this.$bizUtil.isEmpty(this.custCardVO.jobCd)) {
         Msg = '직업 선택은 필수입력 항목입니다.'
         lv_RetVal = false
      }//취득경로 (지인, 개척, 기타)
      else if (this.$bizUtil.isEmpty(this.entryPath)) {
        Msg = '취득 경로는 필수입력 항목입니다.'
        lv_RetVal = false
      }//취득경로 (소개)
      else if (this.entryPath === 'intdr' && this.$bizUtil.isEmpty(this.custCardVO.custNm01)) {
        Msg = '취득 경로 소개는 소개자명 필수입력 항목입니다.'
        lv_RetVal = false
      }//취득경로 (소개일)
      else if (this.entryPath === 'intdr' && !this.custCardVO.introYmd) {
        Msg = '취득 경로 소개는 소개일 필수입력 항목입니다.'
        lv_RetVal = false
      }//취득경로 (소개) 소개자 조회 하지 않을 경우
      else if (this.entryPath === 'intdr' && ( !this.$bizUtil.isEmpty(this.custCardVO.custNm01) && !this.intrResult )) {
        Msg = '취득 경로 소개는 소개자명 입력 후, 조회가 필요합니다.'
        lv_RetVal = false
      }
      
      // 기존 세대원 체크 2022-08-25
      let lv_LengthExist = this.existHoshInfoListInqrVO.length
      if (lv_LengthExist > 0) {
        for (let i = lv_LengthExist; i > 0; i--) {
          let lv_Indx = i - 1
          //필컨포함 시, 기존 세대원 임시고객 여부 체크
          if (this.infoPrcusCnsntTypCd !== '19' && this.existHoshInfoListInqrVO[lv_Indx].chnlCustScCd === '02') {
            let lv_KnbBkno = ''
            
            if (this.$commonUtil.isMobile()) {
              lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBknoCipher
            } else {
              lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBkno
            }

            if (this.$bizUtil.isEmpty(lv_KnbBkno)) {
              Msg = '세대원 주민등록번호를 확인해주세요.('+this.existHoshInfoListInqrVO[lv_Indx].custNm+')'
              lv_RetVal = false
            }
          }
        } // for i
      }

      if (Msg !== '') {
        this.getStore('confirm').dispatch('ADD', Msg)
      }

      return lv_RetVal
    },
    /******************************************************************************
     * @function fn_ConfirmSaveBfCnfrmPop
     * @notice 저장 전 동의 후 호출되는 함수
    ******************************************************************************/
    fn_ConfirmSaveBfCnfrmPop () {
      this.fn_SetCustInfoBeforSaving() // 저장 전 주고객 정보 세팅
      this.fn_GoNextProcess()
    },
    /******************************************************************************
     * @function fn_SetHoshInfoBeforSaving
     * @notice 저장 전 VO에 담기 위한 세대원 정보 세팅
    ******************************************************************************/
    fn_SetHoshInfoBeforSaving () {
      const lv_Vm = this

      // 주고객카드VO 세대원 초기화
      lv_Vm.custCardVO.iCHoshInfoListInqrVO = []

      // 기존세대원, 신규세대원 저장용 VO에 복사 - 화면에 보여주는 값과 별개로 세팅하기 위함
      lv_Vm.saveExistHoshInfoListInqrVO = [...lv_Vm.existHoshInfoListInqrVO]
      lv_Vm.saveNewHoshInfoListInqrVO = [...lv_Vm.newHoshInfoListInqrVO]

      // 기존고객 세대원 리스트
      lv_Vm.saveExistHoshInfoListInqrVO.forEach(item => {
          // 기존/신규 공통 세팅
          item.infoPrcusCnsntTypCd = lv_Vm.infoPrcusCnsntTypCd // 정보활용동의유형코드

          item.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
          item.mktCnvsMthTelYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthTelYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthTelYn // 마케팅권유방법전화여부
          item.mktCnvsMthSmsYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn // 마케팅권유방법SMS여부
          item.mktCnvsMthEmailYn =  (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn // 마케팅권유방법메일여부
          item.mktCnvsMthPstYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthPstYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthPstYn // 마케팅권유방법우편여부
          item.mktCnsntAvlPrd =     (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnsntAvlPrd === undefined) ? '' : lv_Vm.agreeTypeMktList.mktCnsntAvlPrd// 마케팅동의유효기간

          let temp_knb = item.knb // 조회된 주민번호 셋팅

          // 주민등록번호 / 채널고객구분코드 세팅
          if (lv_Vm.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시, 조회된 주민등록번호
            item.knbFrno = temp_knb.substring(0, 6)
            item.knbBkno = temp_knb.substring(6)
            item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            item.chnlCustScCd = item.chnlCustScCd
          } else { // 필수컨설팅 동의 포함 선택
            // 임시고객이었는데 필컨 동의하면 가망고객(01)으로 보내야 함. (01,03,04,05 고객은 기존유지)
            // if (item.isKnbChanged === true) { => 모바일에서 안걸림
            if (temp_knb.substring(7) === '000000') { // 기존에 임시고객이어서 주민번호 직접입력O, 입력한 주민번호 셋팅
              if (item.chnlCustScCd === '' || item.chnlCustScCd === '02') {
                item.chnlCustScCd = '01'
              }
              item.knb = item.knb // 사랑온에서 입력한 주민등록번호
              item.knbFrno = item.knbFrno
              item.knbBkno = item.knbBkno
            } else { // 기존-가망이어서 주민번호 직접입력X, 받은그대로 셋팅
              if (item.chnlCustScCd === '' || item.chnlCustScCd === '02') {
                item.chnlCustScCd = '01'
              }
              item.knbFrno = temp_knb.substring(0, 6)
              item.knbBkno = temp_knb.substring(6)
              item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            }
          }

          // F1MDI0372 조회해서 받은값으로 넣어줘야하는 필드 세팅
          item.chnlCustId = item.chnlCustId // 채널고객ID
          item.afbfFlag = item.afbfFlag // 승인전 승인후 구분
          item.custId = item.custId // 고객ID (BP ID)
          item.chnlCstgrId = item.chnlCstgrId // 주고객관계그룹ID
        });

      // 주고객 세대원LIST에 기존세대원LIST 추가
      for (let i = 0; i < this.saveExistHoshInfoListInqrVO.length; i++) {
        this.custCardVO.iCHoshInfoListInqrVO.push(this.$bizUtil.cloneDeep(this.saveExistHoshInfoListInqrVO[i]))
      }

      // 신규고객 세대원 리스트
      lv_Vm.saveNewHoshInfoListInqrVO.forEach(item => {
          // 기존/신규 공통 세팅
          item.infoPrcusCnsntTypCd = lv_Vm.infoPrcusCnsntTypCd // 정보활용동의유형코드

          item.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
          item.mktCnvsMthTelYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthTelYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthTelYn // 마케팅권유방법전화여부
          item.mktCnvsMthSmsYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn // 마케팅권유방법SMS여부
          item.mktCnvsMthEmailYn =  (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn // 마케팅권유방법메일여부
          item.mktCnvsMthPstYn =    (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnvsMthPstYn === undefined) ? 'N' : lv_Vm.agreeTypeMktList.mktCnvsMthPstYn // 마케팅권유방법우편여부
          item.mktCnsntAvlPrd =     (lv_Vm.agreeTypeMktList === undefined || lv_Vm.agreeTypeMktList.mktCnsntAvlPrd === undefined) ? '' : lv_Vm.agreeTypeMktList.mktCnsntAvlPrd// 마케팅동의유효기간

          // 채널고객ID 초기화
          item.chnlCustId = ''
          item.afbfFlag = 'B'
          item.prcsfCd = 'I'
          // 주민등록번호 / 채널고객구분코드 세팅
          if (lv_Vm.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시 임시고객 (주민번호미확보)
            item.knbFrno = item.knbFrno
            item.knbBkno = item.knbBkFstNo + '000000' // 성별코드 + 000000 값
            item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            item.chnlCustScCd = '02'
          } else { // 필수컨설팅 동의 포함 선택 시 가망고객 (주민번호확보)
            item.knbFrno = item.knbFrno
            item.knbBkno = item.knbBkno

            item.chnlCustScCd = '01'
          }

          // 실명확인결과코드
          if ( this.$bizUtil.isEmpty(item.rlnmCnfRsltCd) ) {
            item.rlnmCnfRsltCd = ''
          } else if (item.custNm === '태아' && item.knbFrno === '000000') {
            item.rlnmCnfRsltCd = ''
          }
        }); // forEach 끝

      // 주고객 세대원LIST에 신규세대원LIST 추가
      for (let i = 0; i < this.saveNewHoshInfoListInqrVO.length; i++) {
        this.custCardVO.iCHoshInfoListInqrVO.push(this.$bizUtil.cloneDeep(this.saveNewHoshInfoListInqrVO[i]))
      }
    },
    /******************************************************************************
     * @function fn_SaveNewCustomer
     * @notice 고객 신규 / 수정 저장
    ******************************************************************************/
    fn_SaveNewCustomer () {
      const lv_Vm = this

      lv_Vm.fn_SetNewHoshInfoBeforSaving() // 신규 저장 전 세대원 정보 세팅
      
      let lv_CustCardVO = this.$bizUtil.cloneDeep(this.custCardVO) // 고객카드VO 복사 - 소개일의 날짜 포맷을 yyyymmdd로 변경함.

      // 취득경로가 소개인 경우 YYYYMMDD로 변경. 그 외에는 소개자 항목은 초기화 함.
      if (this.entryPath === 'intdr') { // 제휴고객일 경우 entryPath의 길이가 0임. 0이상 체크 로직 추가 (2019.01.04 수정 - ASR190100154)
        lv_CustCardVO.introYmd = moment(lv_CustCardVO.introYmd).format('YYYYMMDD')
      } else {
        lv_CustCardVO.introYmd = '00000000'
        lv_CustCardVO.custNm01 = ''
        lv_CustCardVO.intdrChnlCustId = ''
      }

      let pParams = lv_CustCardVO

      const trnstId = 'txTSSCM10I0'
      const auth = 'I'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (res.body !== null) {
            // 신규 저장 완료 후 채널 고객ID, 채널그룹ID 세팅
            lv_Vm.custCardVO.chnlCustId = res.body.chnlCustId
            lv_Vm.custCardVO.chnlCstgrId = res.body.chnlCstgrId
            lv_Vm.custCardVO.mnCustNm = res.body.custNm

            if (!_.isEmpty(res.body.iCHoshInfoListInqrVO)) {
              // 신규 세대원의 채널고객ID정보가 서버로부터 전달되어서 옴. - 필컨, 마동을 위해 다시 서버로부터 온 세대원 정보를 세팅함.
              for (let i = 0; i < lv_Vm.newHoshInfoListInqrVO.length; i++) {
                res.body.iCHoshInfoListInqrVO.forEach(value => {
                    if (value.custNm === lv_Vm.newHoshInfoListInqrVO[i].custNm && value.knbFrno === lv_Vm.newHoshInfoListInqrVO[i].knbFrno ) { // 여기...
                      lv_Vm.newHoshInfoListInqrVO[i].chnlCustId = value.chnlCustId
                      lv_Vm.newHoshInfoListInqrVO[i].chnlCstgrId = value.chnlCstgrId
                      lv_Vm.newHoshInfoListInqrVO[i].mnCustRltnCd = value.mnCustRltnCd
                    }
                  });
              } // for i
            }
			      // 미승인 고객 데이터 등록
            lv_Vm.fn_InsertCnsntAprv()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /************************************************
     * @function fn_InsertCnsntAprv
     * @notice   미승인 고객 데이터 등록
    *************************************************/
    fn_InsertCnsntAprv () {
      const lv_Vm = this
      
      if (this.$bizUtil.isEmpty(this.custCardVO)) {
        console.log('■■■■■ 고객 정보가 존재하지 않습니다. ■■■■■', this.custCardVO)
        return
      }

      let pAprvCustList = this.fn_SetCnsntAprvParams()
      let pParams = {
        aprvCustList: pAprvCustList
      }

      const trnstId = 'txTSSCM34I0'
      const auth = 'U'
      this.post(lv_Vm, pParams, trnstId, auth)
      .then(res => {
        if (!_.isEmpty(res.body)) {
          if (res.body.trnsRsltCd === 'S') {
            // lv_Vm.getStore('confirm').dispatch('ADD', '미승인 고객 등록 성공하였습니다.')
            // 완료 페이지로 전환
            lv_Vm.fn_MoveMSPCM047M()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '미승인 고객 등록 실패하였습니다.')
          }
        }
      }).catch(e => {
        console.log(e)
        lv_Vm.getStore('confirm').dispatch('ADD', '미승인 고객 등록 실패하였습니다.')
      });

    },
    /************************************************
     * @function fn_SetCnsntAprvParams
     * @notice   신규/저장 고객 리스트 정보 셋팅
     *           DB Insert 목적(TTSB00010)
    *************************************************/
    fn_SetCnsntAprvParams () {
      let rtnList = []

      let mnCustInfo = {
        chnlCustId: this.custCardVO.chnlCustId,   // 채널고객ID
        chnlCtgrId: this.custCardVO.chnlCstgrId,  // 채널그룹ID
        custNm: this.custCardVO.custNm, // 고객명
        mnCustNm: this.custCardVO.custNm, // 주고객명
        mnCustRltnCd: this.custCardVO.mnCustRltnCd, // 주고객관계코드
      }

      rtnList = [mnCustInfo] // 주고객

      this.newHoshInfoListInqrVO.forEach(item => {
        let hoshInfo = {
          chnlCtgrId: mnCustInfo.chnlCtgrId,  // 채널그룹ID
          chnlCustId: item.chnlCustId,   // 채널고객ID
          custNm: item.custNm, // 고객명
          mnCustNm: mnCustInfo.custNm, // 주고객명
          mnCustRltnCd: item.mnCustRltnCd // 주고객관계코드
        }

        rtnList.push(hoshInfo) // add 세대원
      });

      return rtnList
    },
    /******************************************************************************
     * @function fn_SetNewHoshInfoBeforSaving
     * @notice 신규 저장 전 VO에 담기 위한 세대원 정보 세팅
    ******************************************************************************/
    fn_SetNewHoshInfoBeforSaving () {
      const lv_Vm = this

      // 신규 고객 세대원 리스트
      this.newHoshInfoListInqrVO.forEach(item => {

        // 채널고객구분코드 세팅
        item.chnlCustScCd = '01' // 임시
        item.knb = item.knbFrno + item.knbBkno.replace('******', '000000')
        item.knbBackup = item.knb
      }); // forEach 끝

      // 태아는 제외함.
      let lv_NewHoshList = this.newHoshInfoListInqrVO.filter(value => {
          if (value.custNm !== '태아' && value.knbFrno !== '000000') {
            return value
          }
        });

      this.newHoshInfoListInqrVO = [...lv_NewHoshList] // 세대원 정보를 custCardVO의 세대원리스트 변수에 저장(복사 후 bind함.)
      this.custCardVO.iCHoshInfoListInqrVO = this.newHoshInfoListInqrVO
    },
    /******************************************************************************
     * @function fn_SaveExistCustomer
     * @notice 기존 고객 정보 저장
    ******************************************************************************/
    fn_SaveExistCustomer () {
      const lv_Vm = this

      lv_Vm.custCardVO.mnCustChnlCustId = lv_Vm.custCardVO.chnlCustId

      // 고객 VO복사
      let lv_CustCardVO = lv_Vm.$bizUtil.cloneDeep(lv_Vm.custCardVO)

      // 주민번호 마스킹 제거
      lv_CustCardVO.knb = lv_CustCardVO.knb.replace('******', '000000')
      lv_CustCardVO.knbBackup = lv_CustCardVO.knbBackup.replace('******', '000000')
      lv_CustCardVO.knbBkno = lv_CustCardVO.knbBkno.replace('******', '000000')


      // 세대원 초기화
      lv_CustCardVO.iCHoshInfoListInqrVO = []

      // 취득경로가 소개인 경우 YYYYMMDD로 변경. 그 외에는 소개자 항목은 초기화 함.
      if (lv_Vm.entryPath.length > 0 && lv_Vm.entryPath[0].key === 'intdr') { // 제휴고객일 경우 entryPath의 길이가 0임. 0이상 체크 로직 추가 (2019.01.04 수정 - ASR190100154)
        lv_CustCardVO.introYmd = moment(lv_CustCardVO.introYmd).format('YYYYMMDD')
      } else {
        lv_CustCardVO.introYmd = '00000000'
        lv_CustCardVO.custNm01 = ''
        lv_CustCardVO.intdrChnlCustId = ''
      }

      // 채널고객구분코드 세팅
      if (lv_Vm.$commonUtil.isMobile()) {
        if (lv_CustCardVO.chnlCustScCd === '02') {
          lv_CustCardVO.knb = lv_CustCardVO.knbFrno + lv_CustCardVO.knbBknoCipher
          lv_CustCardVO.knbBknoCipher = ''
        } else {
          lv_CustCardVO.knbBknoCipher = lv_CustCardVO.knbBknoCipherBackup
        }
      }

      // 바뀐 세대원만 list에 담을 것
      // let lv_ExistHoshInfoListInqrVO = lv_Vm.existHoshInfoListInqrVO
      let lv_ExistHoshInfoListInqrVO = lv_Vm.iCHoshList

      if (lv_ExistHoshInfoListInqrVO.length > 0) { // 세대원 중 정보가 변경된 세대원 리스트만 주고객 VO의 iCHoshInfoListInqrVO에 복사
        // lv_CustCardVO.iCHoshInfoListInqrVO = lv_Vm.$bizUtil.cloneDeep(lv_ExistHoshInfoListInqrVO) // 세대원 정보 세팅, Deep copy, Object.assign은 shallow copy여서 array copy가 안됨.
        lv_CustCardVO.iCHoshInfoListInqrVO = []

        lv_CustCardVO.iCHoshInfoListInqrVO.forEach(item => {
          if (lv_Vm.$commonUtil.isMobile()) {
            // 채널고객구분코드 세팅
            if (item.chnlCustScCd === '02') {
              item.knb = item.knbFrno + item.knbBknoCipher
              item.knbBknoCipher = ''
            } else {
              // TSSCM105M의 이전 버튼 선택 시 주민번호 뒷자리는 마스킹 값이 들어와 있음. 따라서 암호화 값을 가지고 있는 knbBknoCipherBackup를 knbBknoCipher에 저장
              item.knbBknoCipher = item.knbBknoCipherBackup
            }
          }
        });

      }

      let pParams = lv_CustCardVO
      
      const trnstId = 'txTSSCM11U0'
      const auth = 'U'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body)) {
            let lv_NewHoshList = lv_Vm.newHoshInfoListInqrVO

            if (lv_NewHoshList.length > 0) { // 신규 세대원이 있는 경우, 세대원 저장 함수 호출
              lv_Vm.fn_SaveNewHoshInfo()
            } else {
              lv_Vm.fn_ShowSucessAlert()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
    * Function명 : fn_MovePage
    * 설명       : 고객카드/진입화면 으로 이동
    ******************************************************************************/
    fn_MovePage () {
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

      if (_.isEmpty(preSrnObj.rtnParam)) {
        let pParmas = {
          chnlCustId: this.custCardVO.chnlCustId, // 채널고객ID
          cnsltNo: this.lv_UserInfo.userId // 컨설턴트 번호
        }
        this.$router.push({ name: 'MSPCM270M', params: pParmas })
      } else {
        this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
      }

    },
    /******************************************************************************
     * @function fn_SaveNewHoshInfo
     * @notice 기고객의 신규 세대원 추가시 호출
    ******************************************************************************/
    fn_SaveNewHoshInfo () {
      const lv_Vm = this
      this.fn_SetNewHoshInfoBeforSaving() // 저장 전 세대원 세팅

      let pParams = {
        custNm: lv_Vm.custCardVO.custNm, // 고객명
        chnlCustId: lv_Vm.custCardVO.chnlCustId, // 채널고객ID
        chnlCstgrId: lv_Vm.custCardVO.chnlCstgrId, // 채널그룹ID
        cnsltNo: lv_Vm.lv_UserInfo.userId, // 컨설턴트 ID
        iCHoshInfoListInqrVO: lv_Vm.newHoshInfoListInqrVO // 신규 세대원 리스트
      }

      const trnstId = 'txTSSCM10I2'
      const auth = 'I'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body)) {
            // 신규 세대원의 채널고객ID정보가 서버로부터 전달되어서 옴. - 필컨, 마동을 위해 다시 서버로부터 온 세대원 정보를 세팅함.
            for (let i = 0; i < lv_Vm.newHoshInfoListInqrVO.length; i++) {
              res.body.iCHoshInfoListInqrVO.forEach(value => {
                if (value.custNm === lv_Vm.newHoshInfoListInqrVO[i].custNm && value.knbFrno === lv_Vm.newHoshInfoListInqrVO[i].knbFrno) {
                  lv_Vm.newHoshInfoListInqrVO[i].chnlCustId = value.chnlCustId
                }
              });

            } // for i
			      // 미승인 고객 데이터 등록
            lv_Vm.fn_InsertCnsntAprv()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function fn_SetCustInfoBeforSaving
     * @notice   저장 전 VO에 담기 위한 주고객 정보 세팅
    ******************************************************************************/
    fn_SetCustInfoBeforSaving () {
      // 기존/신규 공통 세팅
      this.custCardVO.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd // 정보활용동의유형코드
      this.custCardVO.trtSystmPathCd = '22' // 처리시스템경로코드 (사랑온 고정 구분값 : 22)
      this.custCardVO.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220)
      this.custCardVO.trtSystmPathDtlVal = 'MSPCM043M' // 처리시스템경로상세값 (EP에서 화면ID로 요청)

      this.custCardVO.custJobCd = this.custCardVO.jobCd // 직업코드 레이아웃에 맞춰서 세팅
      this.custCardVO.custJobCdNm = this.custCardVO.jobCdNm // 직업명 레이아웃에 맞춰서 세팅

      this.custCardVO.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
      this.custCardVO.mktCnvsMthTelYn =   (this.agreeTypeMktList === undefined || this.agreeTypeMktList.mktCnvsMthTelYn === undefined) ? 'N' : this.agreeTypeMktList.mktCnvsMthTelYn // 마케팅권유방법전화여부
      this.custCardVO.mktCnvsMthSmsYn =   (this.agreeTypeMktList === undefined || this.agreeTypeMktList.mktCnvsMthSmsYn === undefined) ? 'N' : this.agreeTypeMktList.mktCnvsMthSmsYn // 마케팅권유방법SMS여부
      this.custCardVO.mktCnvsMthEmailYn = (this.agreeTypeMktList === undefined || this.agreeTypeMktList.mktCnvsMthEmailYn === undefined) ? 'N' : this.agreeTypeMktList.mktCnvsMthEmailYn // 마케팅권유방법메일여부
      this.custCardVO.mktCnvsMthPstYn =   (this.agreeTypeMktList === undefined || this.agreeTypeMktList.mktCnvsMthPstYn === undefined) ? 'N' : this.agreeTypeMktList.mktCnvsMthPstYn // 마케팅권유방법우편여부
      this.custCardVO.mktCnsntAvlPrd =    (this.agreeTypeMktList === undefined || this.agreeTypeMktList.mktCnsntAvlPrd === undefined) ? ''  : this.agreeTypeMktList.mktCnsntAvlPrd// 마케팅동의유효기간

      // 고객명 / 휴대폰번호 는 사랑온 화면에서 입력한 값으로 보내야함
      this.custCardVO.custNm = this.custCardVO.custNm.trim() // 고객명

      // 기존고객인 경우 - 고객카드조회(txTSSCM13S1, F1MDI0372) 서비스 조회 데이터에서 받은대로 필수 세팅
      if (this.searchRsltMsg === '기존고객') {
        if (this.mnCustCardRegMdfcVO !== null) {
          if (!this.$bizUtil.isEmpty(this.custCardVO.homFrnoPstcd)) {
            this.custCardVO.homPstcd = this.custCardVO.homFrnoPstcd // 집우편번호코드 레이아웃에 맞춰서 세팅
          }
          if (!this.$bizUtil.isEmpty(this.custCardVO.jobpFrnoPstcd)) {
            this.custCardVO.jobpPstcdSno = this.custCardVO.jobpFrnoPstcd // 직장우편번호코드 레이아웃에 맞춰서 세팅
          }

          let temp_knb = this.mnCustCardRegMdfcVO.prextKnb // 조회된 주민번호 세팅

          // 주민등록번호 / 채널고객구분코드 세팅
          if (this.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시, 조회된 주민등록번호
            this.custCardVO.knbFrno = temp_knb.substring(0, 6)
            this.custCardVO.knbBkno = temp_knb.substring(6)
            this.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
          } else { // 필수컨설팅 동의 포함 선택
            // 임시고객이었는데 필컨 동의하면 가망고객(01)으로 보내야 함.
            if (this.custCardVO.chnlCustScCd === '' || this.custCardVO.chnlCustScCd === '02') {
            } else {
              this.custCardVO.knbFrno = temp_knb.substring(0, 6)
              this.custCardVO.knbBkno = temp_knb.substring(6)
              this.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            }
          }

          // F1MDI0372 조회해서 받은값으로 넣어줘야하는 필드 세팅
          this.custCardVO.chnlCustId = this.mnCustCardRegMdfcVO.chnlCustId // 채널고객ID
          this.custCardVO.afbfFlag = this.mnCustCardRegMdfcVO.afbfFlag // 승인전 승인후 구분
          this.custCardVO.custId = this.mnCustCardRegMdfcVO.custId // 고객ID (BP ID)
          this.custCardVO.chnlCstgrId = this.mnCustCardRegMdfcVO.chnlCstgrId // 주고객관계그룹ID
        }
        // 제휴고객여부 세팅
        this.custCardVO.coalCustYn = this.mnCustCardRegMdfcVO.coalCustYn
      } else { // 신규고객일때
        // 승인전 승인후 구분코드 세팅 - 신규고객 디폴트 'B'
        this.custCardVO.afbfFlag = 'B'
        this.custCardVO.homPstcd = this.custCardVO.homFrnoPstcd // 집우편번호코드 레이아웃에 맞춰서 세팅
        this.custCardVO.jobpPstcdSno = this.custCardVO.jobpFrnoPstcd // 직장우편번호코드 레이아웃에 맞춰서 세팅

        // 주민등록번호 / 채널고객구분코드 세팅
        if (this.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시 임시고객 (주민번호미확보)
          this.custCardVO.knbFrno = this.custCardVO.knbFrno
          this.custCardVO.knbBkno = this.custCardVO.knbBknoBackup // 성별코드 + 000000 값
          this.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
        } else { // 필수컨설팅 동의 포함 선택 시 가망고객 (주민번호확보)
          this.custCardVO.knbFrno = this.custCardVO.knbFrno
          this.custCardVO.knbBkno = this.custCardVO.knbBkno
        }

        // 식별번호 구분코드 세팅 ( 외국인: ZPER02 / 내국인: ZPER01 )
        this.isForeigner ? 'ZPER02' : 'ZPER01'

        // 제휴고객여부 세팅 - 사랑온에서는 선택 불가하므로 신규는 무조건 N으로 넘김
        this.custCardVO.coalCustYn = 'N'
      }
      // 세대원 정보 세팅
      this.fn_SetHoshInfoBeforSaving()
    },
    /*********************************************************
     * @function fn_CallCmplt
     * @notice   완료 버튼 클릭 시 실행
    *********************************************************/
    fn_CallCmplt () {
      let valiMsg = this.fn_GetValiMsg()

      if (!this.$bizUtil.isEmpty(valiMsg)) {
        this.alertProps = {single: true, title: "", content: valiMsg, title_pos_btn: "확인"}
        this.fn_ShowBottomAlert()
        return
      }
      // 고객카드 저장전 공통 체크
      // txTSSCM10S0
      this.fn_IsChangeInfo()
      
    },
    /*********************************************************
     * @function fn_GetValiMsg
     * @notice   유효성 체크, Alert 메시지
    *********************************************************/
    fn_GetValiMsg () {
      let Msg = ''

      // 그 외 직업, text validate
      let chkEctJob = this.fn_JobEtc === '0' && this.$bizUtil.isEmpty(this.lv_JobText) // 그 외 직업이고, 입력하지 않았을때
      // 소개 선택시, 소개자 validate
      let chkIntdr = this.entryPath === 'intdr' && this.$bizUtil.isEmpty(this.custCardVO.custNm01)
      // 소개 선택시, 소개일 validate
      let chkIntdrDt = this.entryPath === 'intdr' && !this.custCardVO.introYmd
      // 주소 validate
      let chkAddr = this.$bizUtil.isEmpty(this.custCardVO.homAddr) && this.$bizUtil.isEmpty(this.custCardVO.jobpAddr)

      if (chkEctJob) {
        Msg = '직업 선택은 필수입력 항목입니다.'
      } else if (chkIntdr) {
        Msg = '취득 경로 소개는 소개자명 입력 후, 조회가 필요합니다.'
      } else if (chkIntdrDt) {
        Msg = '취득 경로 소개는 소개일 필수입력 항목입니다.'
      } else if (chkAddr) {
        Msg = '주소는 필수입력 항목입니다.'
      } else if (this.custCardVO.homFrnoPstcd === '00000') {
        Msg = '자택 주소정보를 확인해주세요.'
      } else if (this.custCardVO.jobpFrnoPstcd === '00000') {
        Msg = '직장 주소정보를 확인해주세요.'
      } else if (this.isForeigner && (!this.custCardVO.custEngNm || !this.custCardVO.natyCd)) {
        Msg = '국적조회를 클릭하세요.'
      } else if (this.iCHoshList.length) {  // 세대원 국적정보
        const isCheckNatyInfo = !this.iCHoshList.filter(v => v.knbScCd === 'ZPER02').some(v => !v.natyCd)
        if (!isCheckNatyInfo) {
          Msg = '세대원 국적조회를 클릭하세요.'
        }
      }

      return Msg
    },
    /**********************************************************************************************************
     * @function fn_SelCustCheck
     * @notice 저장 전 공통 로직 체크
     *              기 고객의 승인/미승인 고객의 주민번호 변경, 신규 고객 등록 시 호출
     * (기 고객의 미승인 고객의 주민번호 변경, 신규 고객 등록) 그리고, (승인 고객의 주민번호 변경)  각각 2번 호출됨.
     * 1. 신규 고객 등록시 호출(주민번호 변경X)
     * 2. 미승인 고객의 리스트를 구성 후, I/F호출함. 호출
     * 3. 승인 고객의 리스트 구성 후, I/F 한번 더 호출
    **********************************************************************************************************/
    fn_SelCustCheck (param) {
      const lv_Vm = this
      
      // 공통 체크할 정보를 별도의 리스트에 담아서 저장할 것 - custCheckList
      // 공통체크할 lv_CustCheckList 변수 생성 및 고객/주민번호 저장
      // 만약, custCheckList와 lv_CustCheckList의 체크할 고객의 주민번호와 고객명이 다르거나 수정되었을 경우 I/F를 조회함.
      let lv_CustCheckList = []
      let lv_CustChckVO = {}
      let lv_ApvrfCd = ''
      // 미승인 고객 정보 세팅
      if (param === 'Y') {
        // 주고객 세팅
        // 신규고객 && 일련번호
        if ( this.custCardVO.isExistCustYN === 'N' && !this.$bizUtil.isEmpty(this.custCardVO.cnsltWcnstPblNo) ) {
          lv_CustChckVO.custNm = this.custCardVO.custNm // 고객명
          lv_CustChckVO.knb = this.custCardVO.knb // 주민번호 전체
          lv_CustChckVO.knbFrno = this.custCardVO.knbFrno // 주민번호 앞자리

          // 지류일 경우 발행번호와 일련번호 세팅 - 서버에서 발행번호와 일련번호로 주민번호 세팅함.
          lv_CustChckVO.cnsltWcnstPblNo = this.custCardVO.cnsltWcnstPblNo
          lv_CustChckVO.sno = this.custCardVO.sno
          lv_CustChckVO.knbBknoCipher = ''

          lv_CustChckVO.knbScCd = this.custCardVO.knbScCd // 주민번호 유형
          lv_CustChckVO.chnlCustId = this.custCardVO.chnlCustId // 채널고객ID

          lv_CustCheckList.push(lv_CustChckVO)
        }
        
        // 기존 미승인 세대원 세팅
        this.existHoshInfoListInqrVO.forEach(value => {
          if ((value.unAprvCustYn === 'Y' && !this.$bizUtil.isEmpty(value.cnsltWcnstPblNo))) {
            let lv_HoshChckVO = {}
            lv_HoshChckVO.custNm = value.custNm // 고객명
            lv_HoshChckVO.knb = value.knb // 주민번호 전체
            lv_HoshChckVO.knbFrno = value.knbFrno // 주민번호 앞자리

            // 지류일 경우 발행번호와 일련번호 세팅 - 서버에서 발행번호와 일련번호로 주민번호 세팅함.
            lv_HoshChckVO.cnsltWcnstPblNo = value.cnsltWcnstPblNo
            lv_HoshChckVO.sno = value.sno
            lv_HoshChckVO.knbBknoCipher = ''

            lv_HoshChckVO.knbScCd = value.knbScCd // 주민번호 유형
            lv_HoshChckVO.chnlCustId = value.chnlCustId // 채널고객ID

            lv_CustCheckList.push(lv_HoshChckVO)
          }
        });

        // 신규 세대원 세팅
        this.newHoshInfoListInqrVO.forEach(value => {
            let lv_HoshChckVO = {}
            let lv_Knb = value.knb

            if (!this.$bizUtil.isEmpty(lv_Knb)) {
              lv_Knb = lv_Knb.substring(6.13) // 평문 주민번호 뒷자리 substring
            }

            if ((lv_Knb !== null || lv_Knb !== '') && value.knbFrno !== '000000') {
                lv_HoshChckVO.chnlCustId = value.chnlCustId // 채널고객ID
                lv_HoshChckVO.cnsltWcnstPblNo = value.cnsltWcnstPblNo // 동의서발행ID
                lv_HoshChckVO.custNm = value.custNm // 고객명
                lv_HoshChckVO.knb = value.knb.replace('******', '000000') // 주민번호 전체
                lv_HoshChckVO.knbBknoCipher = ''
                lv_HoshChckVO.sno = value.sno
                lv_HoshChckVO.knbFrno = value.knbFrno // 주민번호 앞자리
                lv_HoshChckVO.knbScCd = value.knbScCd // 주민번호 유형

                lv_CustCheckList.push(lv_HoshChckVO)
            }
          });

        lv_ApvrfCd = 'B' // 승인 전
      } else if (param === 'N') { // 승인 고객인 경우
        if ((this.custCardVO.isExistCustYN === 'Y' && this.$bizUtil.isEmpty(this.custCardVO.cnsltWcnstPblNo))) {
          lv_CustChckVO.custNm = this.custCardVO.custNm // 고객명
          lv_CustChckVO.knb = this.custCardVO.knb.replace('******', '000000') // 주민번호 전체
          lv_CustChckVO.knbFrno = this.custCardVO.knbFrno // 주민번호 앞자리

          // 지류일 경우 발행번호와 일련번호 세팅 - 서버에서 발행번호와 일련번호로 주민번호 세팅함.
          if (!this.$bizUtil.isEmpty(this.custCardVO.cnsltWcnstPblNo)) {
            lv_CustChckVO.cnsltWcnstPblNo = this.custCardVO.cnsltWcnstPblNo
            lv_CustChckVO.sno = this.custCardVO.sno
            lv_CustChckVO.knbBknoCipher = ''
          }

          lv_CustChckVO.knbScCd = this.custCardVO.knbScCd // 주민번호 유형
          lv_CustChckVO.chnlCustId = this.custCardVO.chnlCustId // 채널고객ID

          lv_CustCheckList.push(lv_CustChckVO)
        }

        // 기존 승인 세대원 세팅
        this.existHoshInfoListInqrVO.forEach(value => {
            if ((value.unAprvCustYn === 'N' && value.cnsltWcnstPblNo !== null && value.cnsltWcnstPblNo !== '')) {
              let lv_HoshChckVO = {}
              lv_HoshChckVO.custNm = value.custNm // 고객명
              lv_HoshChckVO.knb = value.knb.replace('******', '000000') // 주민번호 전체
              lv_HoshChckVO.knbFrno = value.knbFrno // 주민번호 앞자리


              // 지류일 경우 발행번호와 일련번호 세팅 - 서버에서 발행번호와 일련번호로 주민번호 세팅함.
              lv_HoshChckVO.cnsltWcnstPblNo = value.cnsltWcnstPblNo
              lv_HoshChckVO.sno = value.sno
              lv_HoshChckVO.knbBknoCipher = ''

              lv_HoshChckVO.knbScCd = value.knbScCd // 주민번호 유형
              lv_HoshChckVO.chnlCustId = value.chnlCustId // 채널고객ID

              lv_CustCheckList.push(lv_HoshChckVO)
            }
          });

        lv_ApvrfCd = 'A' // 승인 후
      }

      let lv_CallServer = false

      if (lv_ApvrfCd === 'B') { // 미승인(승인전) 고객인 경우
        if (lv_CustCheckList.length > 0) { // 체크할 미승인 고객이 있는 경우
          lv_CallServer = true
        } else {
          this.fn_SelCustCheck('N') // 없는 경우 승인 고객 조회를 하기 위한 함수 호출
        }
      } else if (lv_ApvrfCd === 'A') { // 승인(승인 후) 고객인 경우
        if (lv_CustCheckList.length > 0) { // 체크할 승인 고객이 있는 경우
          lv_CallServer = true
        } else { // 체크할 승인 고객이 없는 경우
          this.fn_NextStep() // 다음 step진행
        }
      }

      if (lv_CallServer) {
        const lv_Vm = this

        let pParams = {
          apvrfCd: lv_ApvrfCd, // 등록 플래그 A: 승인 후, B: 승인전
          chkListVO: lv_CustCheckList
        }

        const trnstId = 'txTSSCM10S0'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            if (!_.isEmpty(res.body)) {
              if (param === 'Y') { // 미승인 조회인 경우
                lv_Vm.fn_SelCustCheck('N') // 승인 고객 조회 호출
              } else { // 승인 고객 조회인 경우
                lv_Vm.fn_NextStep() // 미승인 고객을 이미 먼저 체크했기 때문에 다음 단계로 넘어가는 함수 호출
              }
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
          })
          .catch(error => {
            window.vue.error(error)
          });

      }
    },
    /*************************************************
     * @function fn_GoNextProcess
     * @notice 유효성 / 알림 창 완료 이후 호출
    *************************************************/
    fn_GoNextProcess () {
      const lv_Vm = this

      if (this.custCardVO.isExistCustYN === 'N') { // 신규 고객일때
        this.custCardVO.chnlCustScCd = '01'
        // 신규 고객 저장
        this.fn_SaveNewCustomer()
      } else {
        let lv_IsChangeVal = false

        // 주고객값 변경 여부 체크
        if (this.custCardVO.isCelnoChanged || this.custCardVO.isKnbChanged || this.isChangeCust) {
          lv_IsChangeVal = true
        }

        if (lv_IsChangeVal === false) {
          // 기존 세대원 변경 여부 체크
          this.existHoshInfoListInqrVO.forEach(value => {
            if ((value.isCustNmChanged || value.isKnbChanged || value.isCelnoChanged || value.isCustJobCdChanged) && lv_IsChangeVal === false) {
              lv_IsChangeVal = true
            }
          });

          // 신규 세대원 존재시, 변경 여부 true
          if (!_.isEmpty(this.newHoshInfoListInqrVO)) {
            lv_IsChangeVal = true
          }
        }

        if (lv_IsChangeVal) {
          this.fn_SaveExistCustomer()
        } else {
          // 변경 사항은 없지만, 저장되었습니다. 안내 문구 노출후 => 고객카드로 이동
          let alertObj = {
            msg: lv_Vm.$t('cm')['ECMC053'],
          }
          this.$cmUtil.fn_OpenAlert(lv_Vm, alertObj, this.fn_MovePage)
        }
      }
    },
    fn_ShowSucessAlert () {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true, title: "",
          content: lv_Vm.$t('cm')['ECMC053'],
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.fn_MovePage()
            this.$bottomModal.close(lv_Alert)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_Alert)
          },
        }
      });
    },
    /********************************************************************
     * @function fn_NextStep
     * @notice   다음 단계로 넘어가는 함수 호출 (신규/기존 고객 분기처리됨)
    ********************************************************************/
    fn_NextStep () {
      let lv_NiceMsg = ''

      // 신규고객, 미확보 고객 등록시
      if (this.custCardVO.isExistCustYN === 'N' && this.$bizUtil.isEmpty(this.custCardVO.cnsltWcnstPblNo)) {
        lv_NiceMsg = this.custCardVO.custNm
      }

      this.newHoshInfoListInqrVO.forEach(value => { // 신규 세대원 중 태아, 미승인 고객있는지 체크
          if (this.$bizUtil.isEmpty(value.cnsltWcnstPblNo)) {
            if (lv_NiceMsg === '') {
              lv_NiceMsg = value.custNm
            } else {
              lv_NiceMsg = lv_NiceMsg + ', ' + value.custNm
            }
          }
        });


      // 신규 고객
      if (this.custCardVO.isExistCustYN === 'N') {
        // 미확보 등록시
        if (!this.$bizUtil.isEmpty(lv_NiceMsg)) {
          this.fn_ConfirmOpen4(lv_NiceMsg)
        } else { // 일련번호 입력시
          this.fn_BottomSheetHandler('ConfirmCard')
        }
      } else {
        let _chnlCustScCd = this.custCardVO.chnlCustScCd

        if (_chnlCustScCd === '01') { // '가망고객'인 경우
          // this.showConfirm_TSSCM122U_C2 = true
          this.fn_ConfirmOpen()
        } else if (_chnlCustScCd === '02') { // '임시고객'인 경우
          // this.showConfirm_TSSCM122U_C1 = true
          let custNm = this.custCardVO.custNm
          this.fn_ConfirmOpen4(custNm)
        } else { // '계약고객'인 경우
          // this.showConfirm_TSSCM122U_C3 = true
          this.fn_BottomSheetHandler('SaveBfCnfrm')
        }
      }
    },
    /****************************************************************************
     * 안내 팝업(BottomSheet) Open Function 영역 ::START                        *
    ****************************************************************************/
    fn_ConfirmOpen () {
      this.fn_BottomSheetHandler('ConfirmCard', 'close')

      let contentMsg = '고객 연락처와 실제 고객 번호가<br/> 맞는지 확인하셨습니까?<br/> 임의번호 입력 금지<br/>(예, 010-xxxx-0000, 010-xxxx-1111)'

      this.alertProps = {title: "", content: contentMsg}
      this.fn_ShowBottomAlert(this.fn_ConfirmSaveBfCnfrmPop)
    },
    fn_ConfirmOpen2 (item) {
      let contentMsg = '선택한 세대원을 제외할까요?<br>(해당 세대원의 동의만 진행되지않을뿐<br>고객카드는 삭제되지 않습니다)'

      this.alertProps = {title: "", content: contentMsg, title_pos_btn: "삭제", title_neg_btn: "취소"}
      this.fn_ShowBottomAlert(this.fn_DelHosh, item)
    },
    fn_ConfirmOpen3 (addrType) {
      let contentMsg = '선택한 주소를 삭제할까요?'
      
      this.alertProps = {title: "", content: contentMsg, title_pos_btn: "삭제", title_neg_btn: "취소"}
      this.fn_ShowBottomAlert(this.fn_DelAddr, addrType)
    },
    fn_ConfirmOpen4 (custNm) {
      let contentMsg = this.$t('cm')['ECMC069'].replace('{0}', custNm) // {0}님은 NICE 실명확인 대상이 아닙니다.
      let callbackFnc = null

      if (this.custCardVO.isExistCustYN !== 'Y') { // 신규고객 경우
        callbackFnc = this.fn_BottomSheetHandler
      } else {
        callbackFnc = this.fn_ConfirmOpen
      }

      this.alertProps = {title: "", content: contentMsg, title_pos_btn: "예", title_neg_btn: "아니요"}
      this.fn_ShowBottomAlert(callbackFnc, 'ConfirmCard')
    },
    /******************************************************************************
     * @function fn_ConfirmOpen6
     * @name     주고객(법정대리인)_동의_안내
    ******************************************************************************/
    fn_ConfirmOpen6 () {
      let contentMsg = ''
      let callBackFnc = null

      if (this.custCardVO.mnCustRltnCd === '01' || this.custCardVO.mnCustRltnCd === '02') {
        contentMsg = '앞서 주고객(법정대리인)에 동의해주신 항목에 대해, 세대원도 동일하게 적용하시겠습니까?'
        callBackFnc = this.fn_ConfirmOpen7
      } else {
        contentMsg = "세대원 추가는 '본인', '배우자'만 가능합니다.<br>고객카드에서 세대조정 후 진행 부탁드립니다."
      }

      this.alertProps = {title: "", content: contentMsg, title_pos_btn: "예", title_neg_btn: "아니요"}
      this.fn_ShowBottomAlert(callBackFnc)
    },
    /******************************************************************************
     * @function fn_ConfirmOpen7
     * @name     지류동의_세대원_안내(임시)
    ******************************************************************************/
    fn_ConfirmOpen7 () {
      const contentMsg = '세대원 지류동의 시 국적정보는 동의처리 이후 고객카드에서 별도 진행 바랍니다.'
      const callBackFnc = this.fn_OpenMSPCM049P

      this.alertProps = {title: "", content: contentMsg, title_pos_btn: "예", title_neg_btn: "아니요"}
      this.fn_ShowBottomAlert(callBackFnc)
    },
    /****************************************************************************
     * 안내 팝업(BottomSheet) Open Function 영역 ::END                          *
    ****************************************************************************/

    /****************************************************************************
     * 팝업 호출 Function 영역 ::START
     * @param ('popupID', CallbackFunction)
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_OpenMSPCM013P () {
      this.fn_SetPopup('MSPCM013P', this.fn_ConfirmMSPCM013P)
    },
    /******************************************
     * @name 추가정보입력(주소)
    ******************************************/
    fn_OpenMSPCM014P () {
      this.fn_SetPopup('MSPCM014P', this.fn_ConfirmMSPCM014P)
    },
    /******************************************
     * @name 세대원_입력
    ******************************************/
    fn_OpenMSPCM049P () {
      this.fn_SetPopup('MSPCM049P', this.fn_ConfirmMSPCM049P)
    },
    /******************************************
     * @name 소개자_고객조회
    ******************************************/
    fn_OpenMSPCM129P () {
      this.fn_SetPopup('MSPCM129P', this.fn_ConfirmMSPCM129P)
    },
    /******************************************
     * @name 동의서발행
    ******************************************/
    fn_OpenMSPCM044P () {
      this.fn_SetPopup('MSPCM044P')
    },
    /****************************************************************************
     * 팝업 호출 Function 영역 ::END                                            *
    ****************************************************************************/

    /****************************************************************************
     * 팝업 CallBack Function 영역 ::START                                      *
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_ConfirmMSPCM013P (param) {
      if (_.isEmpty(param)) return

      this.custCardVO.jobCd = param.jobCd
      this.custCardVO.jobCdNm = param.jobCdNm

      if (this.custCardVO.jobCdNm !== param.jobCdNm) {
        this.isChangeCust = true
      }

      // 직업 버튼 처리
      if (!this.$bizUtil.isEmpty(param.jobCd)) {
        // 직업 메인 라디오 버튼 처리 (mo-radio: {key:'',label:''})
        let tmpJobMain = this.jobMains.filter(val => {
            if (val.key === param.jobCd) {
              return val
            }
          });

        if (tmpJobMain.length > 0) { // 매핑되는 값이 있는 경우
          this.selcJobMain = tmpJobMain[0].key
          this.jobEtc = ''
          this.isJobSearch = false
        }

        // 직업 기타 셀렉트 박스 처리 (mo-select: {value:'',text:''})
        let tmpJobEtc = this.jobEtcs.filter(val => {
            if (val.value === param.jobCd) {
              return val
            }
          });

        if (tmpJobEtc.length > 0) {
          this.jobEtc = tmpJobEtc[0].value
        } else {
          this.jobEtc = this.jobEtcs[0].value
          this.lv_JobText = param.jobCdNm
        }
      }
    },
    /******************************************
     * @name 추가정보입력(주소)
    ******************************************/
    fn_ConfirmMSPCM014P (item) {
      if (_.isEmpty(item)) return

      // 주소검색팝업에서 (자택, 주소) 선택만 수정에서 둘다 저장 될 수 있도록 수정 2022-07-26
      // 주소 입력값 초기화
      this.custCardVO.homAddrScCd = ''
      this.custCardVO.homAddr = ''
      this.custCardVO.homDtlad = ''
      this.custCardVO.bldgNo01 = ''
      this.custCardVO.adtnAddr01 = ''
      this.custCardVO.homFrnoPstcd = ''
      this.custCardVO.homAddrRefnCd = ''
      this.custCardVO.homAddrStatCd = ''

      this.addrType = item.addrType
      if ( !this.$bizUtil.isEmpty(item.resCustCardVO.homAddr)) {
        this.custCardVO.homAddrScCd = item.resCustCardVO.homAddrScCd
        this.custCardVO.homAddr = item.resCustCardVO.homAddr // '서울 동작구 상도로55길'
        this.custCardVO.homDtlad = item.resCustCardVO.homDtlad // '201동 201호'
        this.custCardVO.bldgNo01 = item.resCustCardVO.bldgNo01 // '47'
        this.custCardVO.adtnAddr01 = item.resCustCardVO.adtnAddr01 // '(상도동,래미안상도2차아파트)'
        this.custCardVO.homFrnoPstcd = item.resCustCardVO.homFrnoPstcd // '06978'
        this.custCardVO.homAddrRefnCd = item.resCustCardVO.homAddrRefnCd // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.custCardVO.homAddrStatCd = item.resCustCardVO.homAddrStatCd // 집주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
      }

      this.custCardVO.jobpAddrScCd = ''
      this.custCardVO.jobpAddr = ''
      this.custCardVO.jobpDtlad = ''
      this.custCardVO.bldgNo02 = ''
      this.custCardVO.adtnAddr02 = ''
      this.custCardVO.jobpFrnoPstcd = ''
      this.custCardVO.jobpAddrRefnCd = ''
      this.custCardVO.jobpAddrStatCd = ''

      if ( !this.$bizUtil.isEmpty(item.resCustCardVO.jobpAddr)) {
        this.custCardVO.jobpAddrScCd = item.resCustCardVO.jobpAddrScCd
        this.custCardVO.jobpAddr = item.resCustCardVO.jobpAddr // '서울 동작구 상도로55길'
        this.custCardVO.jobpDtlad = item.resCustCardVO.jobpDtlad // '201동 201호'
        this.custCardVO.bldgNo02 = item.resCustCardVO.bldgNo02 // '47'
        this.custCardVO.adtnAddr02 = item.resCustCardVO.adtnAddr02 // '(상도동,래미안상도2차아파트)'

        this.custCardVO.jobpFrnoPstcd = item.resCustCardVO.jobpFrnoPstcd // 우편번호 '06978'
        this.custCardVO.jobpAddrRefnCd = item.resCustCardVO.jobpAddrRefnCd // '01' // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.custCardVO.jobpAddrStatCd = item.resCustCardVO.jobpAddrStatCd // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
      }

      // 기존 고객의 변경인 경우 true로 처리
      this.isChangeCust = true
      this.custCardVO.isAddrChanged = item.resCustCardVO.isAddrChanged
    },
    /******************************************
     * @name 세대원_입력
    ******************************************/
    fn_ConfirmMSPCM049P (item) {
      if (_.isEmpty(item)) return

      this.fn_CheckSuitaHosh(item)
    },
    /******************************************
     * @name 소개자_고객조회
    ******************************************/
    fn_ConfirmMSPCM129P (param) {
      if (_.isEmpty(param)) return

      let toastMsg = ''

      if (param !== null && param !== 'removeLastPopup') { // 확인 버튼 선택 시, 파라미터가 넘어옴.
        this.custCardVO.custNm01 = param.custNm // 고객명
        this.custCardVO.intdrChnlCustId = param.chnlCustId // 채널 고객ID
        this.custCardVO.custNm01Prev = this.custCardVO.custNm01 // 확정된 소개자 명 원본이름을 보관
        this.intrResult = true // 소개자 정상 처리
        // blur와 클릭 이벤트가 이중 발생하여 구분하여 처리
        this.isChangeCust = true

        // 토스트 팝업 호출
        toastMsg = '소개자 입력이 완료되었습니다.'
        this.$addSnackbar(toastMsg)
      } else { // 취소 버튼 선택 시, 값이 없음.
        // 기존에 입력되어 있는 소개자가 없는 경우에만 소개자 항목 초기화 함.
        if (this.$bizUtil.isEmpty(this.custCardVO.intdrChnlCustId)) {
          this.custCardVO.custNm01 = '' // 고객명
          this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
          this.custCardVO.custNm01Prev = ''
          this.intrResult = false

          // 토스트 팝업 호출
          toastMsg = '소개자 입력이 되지 않았습니다.'
          this.$addSnackbar(toastMsg)
        }
      }
    },
    /****************************************************************************
     * 팝업 CallBack Function 영역 ::END                                        *
    ****************************************************************************/

    /*********************************************************
     * @function fn_Back
     * @notice   헤더 영역 Back Button 파라미터 넘기기
    *********************************************************/
    fn_BackBtn () {
      this.fn_BottomSheetHandler('BackCnfrm')
    },
    // 세대원 적합 체크
    /*********************************************************
     * @function fn_CheckSuitaHosh
     * @name     세대원적합체크
     * @notice   세대원 추가에 적합 판단후, 세대원 추가
    *********************************************************/
    fn_CheckSuitaHosh(hoshObj) {
      let hoshTot = hoshObj.existHoshInfoListInqrVO.length + hoshObj.newHoshInfoListInqrVO.length
      let alertMsg = ''

      if (hoshTot > 20) {
        alertMsg = this.$t('cm')['ECMC060'] // '세대추가는 최대 20명까지 가능합니다.'
      }

      if (this.$bizUtil.isEmpty(alertMsg)) {
        if (this.custCardVO.isExistCustYN === 'Y' && this.custCardVO.unAprvCustYn === 'Y') {
          alertMsg = this.$t('cm')['ECMC042'] // '미승인 고객은 세대원을 추가할 수 없습니다.'
        } else {
          this.$bizUtil.getAgeFromKnb(this.custCardVO.knb)
          // let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.custCardVO.knbFrno, 14)
          let lv_CheckAge = this.$bizUtil.getAgeFromKnb(this.custCardVO.knb) > 13
          if (!lv_CheckAge) {
            alertMsg = this.$t('cm')['ECMC043'] // '14세미만 고객은 세대원을 추가할 수 없습니다.'
          }
        }
      }

      if (this.$bizUtil.isEmpty(alertMsg)) {
        this.fn_AddHosh(hoshObj)
      } else {
        this.alertProps = {single: true, title: "", content: alertMsg, title_pos_btn: "확인"}
        this.fn_ShowBottomAlert()
      }
    },
    /*********************************************************
     * @function fn_AddHosh
     * @name   세대원추가
    *********************************************************/
    fn_AddHosh (item) {
      // 토스트 팝업 호출
      let toastMsg = '세대원 추가가 완료되었습니다.'
      this.$addSnackbar(toastMsg)

      this.existHoshInfoListInqrVO = this.$bizUtil.cloneDeep(item.existHoshInfoListInqrVO)
      this.newHoshInfoListInqrVO = this.$bizUtil.cloneDeep(item.newHoshInfoListInqrVO)

      this.iCHoshList = [...this.existHoshInfoListInqrVO, ...this.newHoshInfoListInqrVO]
    },
    /*********************************************************
     * @function fn_DelHosh
     * @name   세대원삭제
    *********************************************************/
    fn_DelHosh (item) {
      if (item.isExistCustYN === 'Y') {
        this.existHoshInfoListInqrVO = this.existHoshInfoListInqrVO.filter(existItem => existItem.chnlCustId !== item.chnlCustId)
      } else {
        this.newHoshInfoListInqrVO = this.newHoshInfoListInqrVO.filter(existItem => existItem.chnlCustId !== item.chnlCustId)
      }
      this.iCHoshList = [...this.existHoshInfoListInqrVO, ...this.newHoshInfoListInqrVO]
    },
    /*********************************************************
     * @function fn_DelAddr
     * @name   주소삭제
    *********************************************************/
    fn_DelAddr (pAddrType) {
      if (pAddrType === 'home') {
        this.custCardVO.adtnAddr01 = ''
        this.custCardVO.bldgNo01 = ''
        this.custCardVO.homAddr = ''
        this.custCardVO.homAddrRefnCd = ''
        this.custCardVO.homAddrScCd = ''
        this.custCardVO.homAddrStatCd = ''
        this.custCardVO.homBknoPstcd = ''
        this.custCardVO.homDtlad = ''
        this.custCardVO.homFrnoPstcd = ''
        this.custCardVO.homPstcdSno = ''
      } else if (pAddrType === 'job') {
        this.custCardVO.adtnAddr02 = ''
        this.custCardVO.bldgNo02 = ''
        this.custCardVO.jobpAddr = ''
        this.custCardVO.jobAddrDtal = ''
        this.custCardVO.jobpAddrRefnCd = ''
        this.custCardVO.jobpAddrScCd = ''
        this.custCardVO.jobpAddrStatCd = ''
        this.custCardVO.jobpDtlad = ''
        this.custCardVO.jobpFrnoPstcd = ''
        this.custCardVO.jobpPstcdSno = ''
      }
      
      this.isChangeCust = true
    },
    /*********************************************************
     * @function fn_MoveMSPCM047M
     * @name     화면전환
     * @notice   param: 신규, 추가된 세대원
     *           MSPCM047M: 고객등록 완료 화면
    *********************************************************/
    fn_MoveMSPCM047M () {
      let param = []

      if (this.custCardVO.isExistCustYN === 'N') {
        this.custCardVO.dob = this.custCardVO.ralDob // 본인
      }

      if (this.isChangeCust) {
        param.push(this.custCardVO)
      }

      let newHoshList = this.iCHoshList.filter(item => {
            return item.custType === 'new'
        }); 

      param.push(...newHoshList)

      this.$router.push({ 
          name: 'MSPCM047M',
          params: { pCustCardVO: param }
        });
    },
    /**************************************************************************************
     * @function fn_ShowBottomAlert
     * @name     BottomAlert_Component_호출_함수
     * @param    fn_CallBack(type: function)
     * @notice   Alert Property는 해당 함수 호출전에 셋팅한다.
     *           Alert Confirm Event는 Function을 파라미터로 받는다.
     *                 Confirm/Close: 전역변수로 셋팅된 Property 초기화
    **************************************************************************************/
    fn_ShowBottomAlert (fn_CallBack, params) {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: lv_Vm.alertProps,
        listeners: {
          onPopupConfirm: () => {
            if (typeof(fn_CallBack) === 'function') {
              window.setTimeout(_ => {
                fn_CallBack(params)
              }, 500)
            }
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
          onPopupClose: () => {
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
        }
      });
      
    },
    /***************************************************************
     * @function fn_BottomSheetHandler
     * @name     바텀시트_핸들링_함수
     * @param    sheetId : 바텀시트ID
     * @param    flag : close/open
     * @param    callFlag : CallbackFunction 실행 Flag
    ***************************************************************/
    fn_BottomSheetHandler (sheetId, flag, callFlag) {
      if (flag === 'close') {
        switch (sheetId) { // BottomSheet Close
          case 'ConfirmCard':
            this.$refs.bottomSheetConfirmCard.close()
            if (callFlag) this.fn_ConfirmOpen() // 저장 전 Confirm Alert
            break;
          case 'SaveBfCnfrm':
            this.$refs.bottomSheetSaveBfCnfrm.close()
            if (callFlag) this.fn_ConfirmOpen() // 저장 전 Confirm Alert
            break;
          case 'BackCnfrm':
            this.$refs.bottomSheetBackCnfrm.close()
            if (callFlag) {
              let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
              let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M

              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

              if (_.isEmpty(preSrnObj.rtnParam)) {
                this.$router.go(-(pageCnt))
              } else {
                this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
              }
            }
            break;
        }
        
      } else {
        switch (sheetId) { // BottomSheet Open
          case 'ConfirmCard':
            this.$refs.bottomSheetConfirmCard.open()
            break;
          case 'SaveBfCnfrm':
            this.$refs.bottomSheetSaveBfCnfrm.open()
            break;
          case 'BackCnfrm':
            this.$refs.bottomSheetBackCnfrm.open()
            break;
        }
      }
    },
    /***************************************************************
     * @function fn_SetPopup
     * @name     팝업_Props_셋팅
     * @notice   파라미터, Callback 함수 셋팅
     * @param    sheetId  :바텀시트ID
     * @param    fn_Callback :CallbackFunction
    ***************************************************************/
    fn_SetPopup (popId, fn_Callback) {
      const lv_Vm = this
      let popupObj = {}
      
      switch (popId) {
        case 'MSPCM013P':
          popupObj.comp = MSPCM013P
          popupObj.properties = { pJobCdNm : lv_Vm.lv_JobText } // 직업찾기 입력값
          break;
        case 'MSPCM014P':
          popupObj.comp = MSPCM014P
          popupObj.properties = { pCustCardVO : lv_Vm.custCardVO, pAddrType : lv_Vm.addrType } // 고객카드VO, 주소타입(자택/직장)
          break;
        case 'MSPCM015P':
          popupObj.comp = MSPCM015P
          popupObj.properties = { tempCustCardVo : lv_Vm.custCardVO } // 고객카드VO
          break;
        case 'MSPCM049P':
          popupObj.comp = MSPCM049P
          popupObj.properties = {
                                  pExistHoshList: lv_Vm.existHoshInfoListInqrVO, // 기존 세대원 리스트VO
                                  pNewHoshList: lv_Vm.newHoshInfoListInqrVO // 신규 세대원 리스트VO
                                }
          break;
        case 'MSPCM019P':
          popupObj.comp = MSPCM019P
          popupObj.properties = { tempCustCardVo: lv_Vm.custCardVO } // 고객카드VO
          break;
        case 'MSPCM129P':
          popupObj.comp = MSPCM129P
          popupObj.properties = { pCustNm: lv_Vm.custCardVO.custNm01 } // 소개자
          popupObj.noHeader = true
          break;
        case 'MSPCM044P':
          popupObj.comp = MSPCM044P
          popupObj.properties = { pCustCardVO: lv_Vm.custCardVO } // 고객카드VO
          break;
      }

      this.fn_OpenPopup(popupObj, fn_Callback)
    },
    /***************************************************************
     * @function fn_OpenPopup
     * @name     팝업_호출
     * @notice   Util처럼 선언한 함수 (해당 화면 팝업사용이 많아 모두 등록)
     * @param    fn_Callback :CallbackFunction
    ***************************************************************/
    fn_OpenPopup (popupObj, fn_Callback) {
      let lv_Popup = this.$bottomModal.open(popupObj.comp,{
        properties: popupObj.properties,
        listeners: {
          // 기개발된 팝업 emit event name이 일관되지 않아, 모두 등록하였습니다.
          confirmPopup: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupClose: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupConfirm: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupCancel: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          cancelPopup: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          }
        }
      },
      {
        properties: {noHeader: popupObj.noHeader ? true : false}
      });

    },
    /***************************************************************
     * @function fn_IsChangeInfo
     * @name     팝업_호출
     * @notice   기존고객 정보 변경시, 안내 팝업 호출
    ***************************************************************/
    fn_IsChangeInfo () {
      const lv_Vm = this

      let isChange = false
      if (this.custCardVO.isCelnoChanged || this.custCardVO.isKnbChanged || this.custCardVO.isAddrChanged || this.isChangeCust) isChange = true

      if (this.custCardVO.isExistCustYN === 'N' || (this.custCardVO.isExistCustYN === 'Y' && !isChange)) { // 신규고객 or 기존고객
        lv_Vm.fn_SelCustCheck('Y')
        return
      }

      // 정보변경 안내후, 저장 프로세스 진행
      let alertMsg = '고객님의 정보가 변경되었습니다.<br>동의처리 요청시 고객에게 정보 변경알림톡이 실시간 발송되었으니 주의 부탁드립니다.<br>진행하시겠습니까?'
      this.alertProps = {title: ' ', content: alertMsg}
      this.fn_ShowBottomAlert(this.fn_SelCustCheck, 'Y')
    }
  } // ::Methods End
}
</script>
