/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI830P
 * 화면설명: 심사의견조회
 * 접근권한: 
 * 작 성 일: 2024.03.04
 * 작 성 자: 
 */
<template>
  <ur-page-container ref="page" class="msp" title="심사의견조회" :show-title="false" :topButton="true" type="popup">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '심사의견조회' : '심사의견조회'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_HistoryBack('close')">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container v-if="lv_NundwInspRsltList.length > 0" alignV="start" componentid="" direction="column" class="ns-info-list list-pa2024 info-w100">
              <mo-list :list-data="lv_NundwInspRsltList">
                <template #list-item="{item, index}">
                  <!-- <mo-list-item :isExpand="index === 0" expandable> -->
                  <mo-list-item :ref="`refExp${index}`" expandable prevent-expand >
                    <div class="list-item__contents" @click="$refs[`refExp${index}`].isExpand!=$refs[`refExp${index}`].isExpand">
                      <div class="list-item__contents__title mb0 fexTy5">
                        <mo-badge class="badge-sample-badge sm" 
                          :class="{
                            'blue2' : item.rsltStatCdNm === '완료', 
                            'red' : item.rsltStatCd === '00' || item.rsltStatCd === '30',
                          }"
                          :text="item.rsltStatCd" shape="status">
                            {{item.rsltStatCdNm}}
                        </mo-badge>
                        <!-- <mo-badge class="badge-sample-badge sm" 
                          :class="{
                            'blue2' : item.rsltStatCd === '30' || item.rsltStatCdNm === '완료', 
                            'red' : item.rsltStatCd === '00',
                          }"
                          :text="item.rsltStatCd === '00' ? '미결' : item.rsltStatCd === '30'? '완료' : item.rsltStatCdNm === '완료' ? '심사': ''" shape="status">
                            {{item.rsltStatCd === '00' ? '미결' : item.rsltStatCd === '30' ? '완료' : item.rsltStatCdNm === '완료'? '심사' : ''}}
                        </mo-badge> -->
                        <!-- 심사일자-->
                        <span class="name txtSkip ml10 fwn">{{item.jdgYmd}}</span>
                        <!-- <span class="name txtSkip ml10 fwn">{{item.rsltStatCd === '30'? item.pendCmpltYmd : item.rsltStatCdNm === '완료' ? item.jdgYmd : item.pendIndctYmd}}</span> -->
                      </div>
                    </div>

                    <!-- 아코디언 컨텐츠 -->
                    <template v-slot:expand>
                      <div class="list-item-detail">
                        <template v-if="item.rsltStatCdNm === '완료'">
                          <div class="contents-row fexTy2">
                            <span class="fs14rem crTy-bk7 min100">세부내용</span><span class="crTy-bk1 txt-cont" v-html="item.jdgCntntOpin"></span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="contents-row fexTy2">
                            <span class="fs14rem crTy-bk7 min100">미결코드1</span><span class="crTy-bk1">{{item.jdgCdNm01}}</span>
                          </div>
                          <div class="contents-row fexTy2">
                            <span class="fs14rem crTy-bk7 min100">미결코드2</span><span class="crTy-bk1">{{item.jdgCdNm02}}</span>
                          </div>
                          <div class="contents-row fexTy2">
                            <span class="fs14rem crTy-bk7 min100">세부내용</span><span class="crTy-bk1 txt-cont" v-html="item.jdgCntntOpin"></span>
                          </div>
                          <div class="contents-row fexTy2">
                            <span class="fs14rem crTy-bk7 min100">미결보완여부</span>
                            <span class="crTy-bk1">
                              <b :class="item.pendCpmtYn === 'Y'? 'crTy-blue2 mr10' : 'crTy-orange2 mr10'">{{item.pendCpmtYn}}</b>
                              <span class="crTy-bk1" v-if="item.pendCpmtYn === 'Y'">{{`(보완일자 : ${item.pendCmpltYmd})`}}</span>
                              <!-- <b :class="item.pendCpmtYn === 'Y'? 'crTy-blue2 mr10' : 'crTy-orange2 mr10'">{{item.pendCpmtYn}}</b> -->
                            </span>
                          </div>
                          <div class="contents-row fexTy2" v-if="item.cpmtParngYmd">
                            <span class="fs14rem crTy-bk7 min100">보완기한</span><span class="crTy-bk1">{{item.cpmtParngYmd}}</span>
                          </div>
                          <div class="contents-row fexTy2" v-if="item.pendTrtCntnt">
                            <span class="fs14rem crTy-bk7 min100">보완내용</span><span class="crTy-bk1 txt-cont" v-html="item.pendTrtCntnt"></span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </mo-list-item>
                </template>
              </mo-list>

            </ur-box-container> 
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                     *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                        *
   ***********************************************************************************/
  name: "MSPPI830P",
  screenId: "MSPPI830P",
  isBackKeyHandle: true,
  props: {
    nundwInspRsltList: {
      type: Array,
      default: () => []
    }
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_HeaderviewScrollCompID: '',
      headerVO: {},
      lv_NundwInspRsltList: [],
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  destroyed () {
  },
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.headerVO = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    window.vue.getStore('progress').dispatch('FULL')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    console.log('MSPPI830P mounted')
    this.fn_Init()
  },
  watch: {

  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                             *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 초기화 함수
     ******************************************************************************/
    fn_Init () {
      // TEST ========================
      if (this.nundwInspRsltList?.length > 0) {
        // undwPendScCd	계약심사미결구분코드
        // untyMngNoScCd	통합관리번호구분코드
        // untyMngNo	통합관리번호
        // trtSno	처리일련번호
        // rsltStatCd	결과상태코드
        // rsltStatCdNm	결과상태명
        // jdgYmd	심사일자
        // jdgCd01	심사코드01
        // jdgCd02	심사코드02
        // jdgCdNm01	심사코드명01
        // jdgCdNm02	심사코드명02
        // jdgCntntOpin	심사내용의견
        // pendCpmtYn	미결보완여부
        // pendIndctYmd 미결지시일자
        // cpmtParngYmd 보완예정일자
        // pendCmpltYmd 미결완료일자
        // pendTrtCntnt 미결처리내용
        // piCommonUtil.dateFormat(item.cnsntAvlEndYmd)
        this.nundwInspRsltList.forEach(item => {
          item.jdgYmd = piCommonUtil.dateFormat(item.jdgYmd)
          if (item.hasOwnProperty('pendIndctYmd')) {
            item.pendIndctYmd = piCommonUtil.dateFormat(item.pendIndctYmd)
          } else {
            item.pendIndctYmd = ''
          }
          if (item.hasOwnProperty('cpmtParngYmd')) {
            item.cpmtParngYmd = piCommonUtil.dateFormat(item.cpmtParngYmd)
          } else {
            item.cpmtParngYmd = ''
          }
          if (item.hasOwnProperty('pendCmpltYmd')) {
            item.pendCmpltYmd = piCommonUtil.dateFormat(item.pendCmpltYmd)
          } else {
            item.pendCmpltYmd = ''
          }
          if (!item.hasOwnProperty('pendTrtCntnt')) {
            item.pendTrtCntnt = ''
          } else {
            item.pendTrtCntnt = piCommonUtil.fn_ChangeTxtVal(item.pendTrtCntnt)
          }
          if (!item.hasOwnProperty('jdgCntntOpin')) {
            item.jdgCntntOpin = ''
          } else {
            item.jdgCntntOpin = piCommonUtil.fn_ChangeTxtVal(item.jdgCntntOpin)
          }
          this.lv_NundwInspRsltList.push(item)  
        })
        this.$nextTick(() => {
          this.$refs.refExp0.isExpand = true
        })
        // piCommonUtil.dateFormat(item.cnsntAvlEndYmd)
        console.log('미결리스트 ====> ', this.lv_NundwInspRsltList)
        // this.$refs.refExp0.isExpand = true
      }
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack (type) {
      this.$emit('closePopup')
    },
  }
}
</script>
