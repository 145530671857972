/*
 * 업무구분   : 가입설계
 * 화면 명    : MSPPI703P
 * 화면 설명  : 가입설계 개선 안내
 */
<template>
  <!-- 개발적용(import적용) 소스 -->
  <ur-page-container title="가입설계 개선 안내" :show-title="false" type="popup">
    <ur-box-container direction="column" align-v="start" class="msp">

      <ur-box-container align-v="center" direction="row" ref="_modal__title" class="ns-bs-title-login">
        <div class="title"><ur-text class size="lg">가입설계 개선 안내</ur-text></div>
        <div class="btn-close" @click="fn_close">X</div>
      </ur-box-container>
      
      <ur-box-container align-v="start" direction="column" class="ns-bottom-sheet">
        <div class="content-area">
          <div class="subtit-info-process">
            가입설계를 빠르고 쉽게 할 수 있도록 개선하였습니다.
          </div>
          <div class="wrap-info-process">
            <ul>
              <li>
                <p class="tit">1. 주피/계약자/종피별로 명확하게 보여요!</p>
                <p class="exp">입력항목이 펼쳐서 보이고, 각 항목간에 구분이 헷갈리지 않아요!</p>
                <p class="img"><img src="@/assets/img/content/p1_pop1.png" alt=""></p>
              </li>
              <li>
                <p class="tit">2. 어떤 고객/상품을 설계중인지 잘 보여요!</p>
                <p class="exp">어떤 고객과 상품으로 설계하고 있는지 헷갈리지 않고, 해당 영역을 클릭하면 고객과 상품을 수정가능해요!</p>
                <p class="img"><img src="@/assets/img/content/p1_pop2.png" alt=""></p>
              </li>
              <li>
                <p class="tit">3. 선택한 특약을 한눈에 보면서 설계 가능해요!</p>
                <p class="exp">일괄선택한 특약을 페이지 이동없이 한번에 입력 가능해요.</p>
                <p class="img"><img src="@/assets/img/content/p1_pop3.png" alt=""></p>
              </li>
            </ul>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" direction="column" class="ns-btn-relative-bs noshadow">
        <div class="relative-div">
          <mo-button color="normal" shape="border" size="medium" @click="fn_close" class="ns-btn-round blue">확인</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPPI703P",
    screenId: "MSPPI703P",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted () {
      // 필수 적용 함수
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {     
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {      
      fn_close() {
        this.$emit('onPopupClose')
      }
    }
  };
</script>
<style scoped>
</style>