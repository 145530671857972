/*
 * 업무구분: 선심사 서류보완
 * 화 면 명: MSPPI825P
 * 화면설명: 가입설계 > 전산심사 > 선심사 > 선심사완료 > 선심사 서류보완
 * 접근권한: 
 * 작 성 일: 2024.01.
 * 작 성 자: gyeonjin.cho
 */
<template>
  <ur-page-container ref="page" class="msp btn-back-clear" title="선심사 서류보완" :show-title="false" type="popup" >
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_close(1)">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '선심사 서류보완' : '선심사 서류보완'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_close(1)">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30 pb30">
              <div class="con-area">
                <p class="fs19rem fwb mb4 mt0 mb0">보완서류 촬영</p>
                <span class="fs17rem">최대 100건까지 이미지 등록이 가능합니다.<br> 촬영버튼을 눌러 서류를 촬영해주세요.</span>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area pal0 mt20">
                  <mo-button class="ns-btn-round bd-radius-40 mla0" @click="fn_camera" :disabled="photoList.length===maxCnt">촬영</mo-button>
                </ur-box-container>
              </div>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum bd-T-Ty1 con-area pt12 pb12">
              <div class="list-item__contents fexTy3 full">
                <div class="list-item__contents__title">
                  <span class="sum">총 <strong class="crTy-blue3">{{photoList.length}}</strong> 건</span>
                </div>
                <span class="fs14rem crTy-bk7">이미지 선택 시 선택한 이미지가 삭제됩니다.</span>
              </div>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <ur-box-container v-if="photoList.length > 0" alignV="start" componentid="" direction="row" class="con-area pb20" nowrap>
              <ul class="album-list col2">
                <li class="image-box" v-for="(item, index) in photoList" :key="'image-'+index">
                  <picture class="career_bs_img bgcolor-3"><img :src="item.img" @click="fn_viewImg(item.img)"/></picture>
                  <mo-button shape="borderless" class="img-close" @click="fn_DeleteImage(index)"><mo-icon icon="msp-close" /></mo-button>  
                </li>
              </ul>
              </ur-box-container>
              <ur-box-container v-else alignV="start" componentid="" direction="column" class="ns-contract-list no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info hauto mhAuto mt60">
                      <span>서류가 없습니다.</span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
            <!-- Content 영역 end -->

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close(1)">취소</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisabled" @click="fn_close(2)">서류 보완</mo-button>
              </div>
            </ur-box-container>  
          </template>

        </mo-collapsing-header-view>

      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPPI826P from '@/ui/pi/MSPPI826P.vue'

  export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPPI825P",
  screenId: "MSPPI825P",
  components: {
  },
  props:{
    params: {
      type: Object,
      default: {}
    }
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {    
    console.log('created >>>>>>>>>>>> preDocuID:'+this.$props.params.preDocuID)
    this.preDocuID = this.$props.params.preDocuID
    //  if(this.$props.params.preDocuID === ''){
    //    this.preDocuID = '76845413626120404202'
    //  }
  },
  mounted() {
    // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    
    this.fn_selImgList()
  },
  /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      lv_HeaderviewScrollCompID: '',
      preDocuID: '',
      retryCnt: 0,
      retryMax: 3,
      maxCnt: 100,
      photoList: [],
      oriPhotoList: [],
      testMap:{}
    }
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    isDisabled(){
      if(this.photoList.length === 0 && this.oriPhotoList.length === 0){
        return true
      }else{
        return false
      }
    },
    isRetry(){      
      if(this.retryCnt < this.retryMax){
        return true    
      }else{        
        return false                 
      }
    },
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    fn_viewImg (imgurl) {
        console.log('imgurl=', imgurl)
        let lv_vm = this
        let P_params = {}
        P_params.img = imgurl
        lv_vm.popupPI826P = this.$bottomModal.open(MSPPI826P, {
          properties: {
            params: P_params
          },
          listeners: {
            onPopupClose: () => {
              lv_vm.$bottomModal.close(lv_vm.popupPI826P)
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
    /**
     * @description 부모페이지 callback check
     * @param {Number} type 1: 취소, 2: 보완서류 제출
     */
    fn_close(type) {
      if( type === 1 ){
          this.$emit('confirmPopup', {type: ''})
      } else {
        // '보완서류 제출' 버튼
        console.log('보완서류 제출---photoList.length : ' + this.photoList.length)
        this.fn_SaveImgList()
      }      
    },
    /**
     * 삭제 : photoList에서의 삭제(index)
     */
    fn_DeleteImage(index){
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '',
            content: '선택한 이미지를 삭제하시겠습니까?'
          },
          listeners: {
            onPopupConfirm: () => {
              this.photoList[index] = null
              this.photoList.splice(index, 1)
        console.log('fn_DeleteImage.photoList.length=' + this.photoList.length) 
        console.log('fn_DeleteImage.oriPhotoList.length=' + this.oriPhotoList.length)           
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
    /**
     * 저장된 서류이미지 목록 조회(nas-fix.dat)
     */
    fn_selImgList() {
        let lv_Vm = this
        let pParams = {
          preDocuID: this.preDocuID
        }
        const trnstId = 'txTSSPI80S1'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if ( response.body && response.body.photoList) { // 조회성 & 목록이 있는경우
            let list = response.body.photoList
            list.forEach(item =>{
                  item.img = lv_Vm.fn_getImgUrl()+item.viewInfo
              })
              lv_Vm.photoList = list
              lv_Vm.oriPhotoList = _.cloneDeep(lv_Vm.photoList)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    /**
     * 촬영 : 앱 카메라 호출
     */
    fn_camera() {
      console.log('fn_camera > isMobile='+this.$commonUtil.isMobile())
      let base64img = ''
      if( process.env.NODE_ENV === 'local' ){
        
        this.fn_SaveImageOne(this.fn_getBase64())

      } else if( this.$commonUtil.isMobile() ){
        let lv_Vm = this

        // 안드로이드의 경우 기본 카메라 모듈로 촬영, IOS는 NEXT사랑온용으로 개발된 카메라 모듈로 촬영
        let pluginNm = 'CameraDefaultPlugin'

        if (lv_Vm.$commonUtil.getIsIOS()) {
          pluginNm = 'CameraOnlyPlugin'
        }

        console.log('fn_camera > pluginNm='+pluginNm)

        /*window.fdpbridge.exec('CameraPlugin', {}, function (result) { asis 테블릿 버전*/
        window.fdpbridge.exec(pluginNm, {}, function (result) { //tobe array 처리 용 버전
          // 성공콜백
          if (result.data === 'cancel') {
            lv_Vm.getStore('confirm').dispatch('ADD', '사진등록을 취소하였습니다.')
          } else if ( lv_Vm.$bizUtil.isEmpty(result.data) || result.data === 'error') {
            lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생 했습니다. 다시 실행해 주세요')
          } else { //성공 하면, result.data = [iVBORw0KGgoAAAANSUhEUg..., iVBORw0KGgoAAAANSUhEUg...] 식으로 넘어옴.
            console.log('fdpbridge('+ pluginNm + ').result.data=' + result.data)
            const imageList = result.data.replace(/\[(.*)\]/, '$1').split(', ')
            if( imageList.length > 0 ) {
              //1. nas 저장 > 성공시 photoList.push
                base64img = 'data:image/jpeg;base64,'+imageList[0]
                lv_Vm.fn_SaveImageOne(base64img)
            }
          }
        }, function (result) {
          // 실패콜백
          lv_Vm.getStore('confirm').dispatch('ADD', '사진등록 앱 호출 오류 발생 \n다시 등록해 주세요.')
        })
      }
    }, // end fn_camera
    /**
     * 보완서류 확정 저장 요청 -> 서류이미지목록 저장 확정
     */
    fn_SaveImgList(){
        let lv_Vm = this
        
        console.log('fn_SaveImgList.photoList.length=' + this.photoList.length) 
        console.log('fn_SaveImgList.oriPhotoList.length=' + this.oriPhotoList.length) 

        if(this.photoList.length === 0 && this.oriPhotoList.length > 0){
          this.fn_delAllImgList()
          
        }else{
        // let isSucess = false
        let pParams = {
          preDocuID: this.preDocuID,
          photoList: this.photoList
        }
        const trnstId = 'txTSSPI80U1'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) { 
          console.log('fn_SaveImgList.response=' + response)         
            if ( response.body ) { // 성공시
                lv_Vm.retryCnt = 0
                lv_Vm.fn_bottomAlert('S')
                // isSucess = true
            }else{
                console.log('fn_SaveImgList >>>> isRetry='+ lv_Vm.isRetry + ',retryCnt='+lv_Vm.retryCnt)
                if(lv_Vm.isRetry){
                  lv_Vm.fn_bottomAlert('R')                  
                }else{
                  lv_Vm.fn_bottomAlert('C') 
                }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
          // return isSucess
        }
    },
    fn_delAllImgList(){
        let lv_Vm = this
        let pParams = {
          preDocuID: this.preDocuID
        }
        const trnstId = 'txTSSPI80D1'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) { 
          console.log('fn_delAllImgList.response=' + response)         
            if ( response.body ) { // 성공시
                lv_Vm.photoList = []
                lv_Vm.oriPhotoList = []
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
          // return isSucess
    },
    fn_bottomAlert(type){
      console.log('fn_bottomAlert >>>> type='+ type ,',retryCnt='+this.retryCnt)
      let typeCon = '이미지 등록이 완료되었습니다.보완서류제출 버튼을 선택하여 미결사항 보완을 완료해주세요.'
      let typeBtnNm = '확인'
        if(type === 'R'){
          typeCon = '이미지 등록에 실패하였습니다.재전송 버튼을 눌러 재시도 해주세요.'
          typeBtnNm = '재전송'          
        }else if(type === 'C'){
          typeCon = '이미지 등록에 실패하였습니다. 잠시 후 다시 촬영해주세요.'
          typeBtnNm = '확인'
        }
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: typeCon,
            title_pos_btn: typeBtnNm
          },
          listeners: {
            onPopupConfirm: () => {
              if(type === 'S'){
                this.$bottomModal.close(lv_AlertPop)
                this.$emit('confirmPopup', {type: ''})
              }else if(type === 'C'){
                this.$bottomModal.close(lv_AlertPop)
                this.retryCnt = 0
              }else if(type === 'R'){
                this.$bottomModal.close(lv_AlertPop) 
                  setTimeout(() => {
                    this.fn_SaveImgList()
                    this.retryCnt++
                  }, 500);
              }

            }
          }
        })
      },      
    /**
     * 촬영 > 저장 : 건별 nas 저장요청
     */
    fn_SaveImageOne (base64img) {
      console.log('fn_SaveImageOne-----------------start=' + moment(new Date()).format('YYYYMMDD HHmmss'))
        let lv_Vm = this
        let pParams = {
          preDocuID: this.preDocuID,
          defDate:'',
          base64Img: base64img
        }
        const trnstId = 'txTSSPI80P1'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) { // 성공시 
              lv_Vm.photoList.push({img: lv_Vm.fn_getImgUrl()+response.body.viewInfo, imgNm: response.body.imgNm, preDocuID:lv_Vm.preDocuID})
              // /lv_Vm.getStore('confirm').dispatch('ADD', '등록 성공!')
              lv_Vm.retryCnt = 0
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        console.log('fn_SaveImageOne-----------------end=' + moment(new Date()).format('YYYYMMDD HHmmss'))
      },
      fn_getImgUrl(){
        let imgUrl = '/docuimgdown.do?docuImg='
        if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          let locHost = location.host
          if (locHost.indexOf('imss') > -1) {
            imgUrl = 'https://itss.samsunglife.com:8410' + imgUrl // 임시로 우회처리
          }``
        }else if (process.env.NODE_ENV === 'local') {
            imgUrl = 'http://localhost:1111' + imgUrl
        }
        return imgUrl
      },
  },// end method
};
</script>
<style scoped>
</style>
