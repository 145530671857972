/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM048P
 * 화면설명 : 승인요청 발송
 * 접근권한 : 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일 : 2023.01.11
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="승인요청 발송" :show-title="true" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">

          <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30 pb0">
            <div class="con-title1">구비서류 촬영</div>
            <ul class="bullet-type-3 crTy-bk7 fs14rem mt10">
              <li>촬영 시 서명이 잘 보이도록 찍어주세요.</li>
            </ul>
            <div class="con-title3 mt30">필수컨설팅 동의서</div>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="row" class="info-box mt10 pb100" nowrap>

            <!-- 동의서 앞면 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="flex-1 h100 pr12" nowrap>
              <h6 class="con-title6 crTy-bk7">앞면</h6>
              <picture class="career_bs_img bdTy11 w100 h100 mt4 mh200px bgcolor-3 fexTy1">
                <mo-button v-if="!$bizUtil.isEmpty(frontImgData)" color="normal" shape="borderless" size="small" class="img-close" @click="fn_ImageClear('front')"></mo-button>
                <img v-if="$bizUtil.isEmpty(frontImgData)" src="@/assets/img/profile/img_noneImage.png" alt="" class="noneImage w40px h40px">
                <img v-else :src="`data:image/jpeg;base64,${frontImgData}`" class="w100 h100"/>
              </picture>
              <mo-button color="normal" shape="border" size="xlarge" class="blue w100 mt10 minWauto" @click="fn_Camera('front')" :disabled="!$bizUtil.isEmpty(frontImgData)">촬영</mo-button>
            </ur-box-container>

            <!-- 동의서 뒷면 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="flex-1 h100 pl12" nowrap>
              <h6 class="con-title6 crTy-bk7">뒷면</h6>
              <picture class="career_bs_img bdTy11 w100 h100 mt4 mh200px bgcolor-3 fexTy1">
                <mo-button v-if="!$bizUtil.isEmpty(backImgData)" color="normal" shape="borderless" size="small" class="img-close" @click="fn_ImageClear('back')"></mo-button>
                <img v-if="$bizUtil.isEmpty(backImgData)" src="@/assets/img/profile/img_noneImage.png" alt="" class="noneImage w40px h40px">
                <img v-else :src="`data:image/jpeg;base64,${backImgData}`" class="w100 h100"/>
              </picture>
              <mo-button color="normal" shape="border" size="xlarge" class="blue w100 mt10 minWauto" @click="fn_Camera('back')" :disabled="!$bizUtil.isEmpty(backImgData)">촬영</mo-button>
            </ur-box-container>
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SendAprv" :disabled="$bizUtil.isEmpty(frontImgData) || $bizUtil.isEmpty(backImgData)">승인요청 발송</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>
    </ur-box-container> 
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/
  import lv_ImgData from '@/ui/cm/test' // Local Test용 base64 Data
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
    // 현재 화면명
    name: "MSPCM048P",
    // 현재 화면 ID
    screenId: "MSPCM048P",
    props: {
      pCustCardVOList: {
        type: Array
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
    data () {
      return {
        testImgData: '', // local Test용

        frontImgData: '', // 앞면 이미지 base64
        backImgData: '', // 뒷면 이미지 base64
    
        userInfo: {}, // 사용자 정보
        custCard: {}, // 고객 정보 (본인 고객)
        custCardVOList: [], // 고객 정보 (세대원 포함)
      };
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
    created() {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM048P')

      if (!_.isEmpty(this.pCustCardVOList)) {
        this.fn_Init()
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_Init () {
        const lv_Vm = this

        this.custCardVOList = this.$bizUtil.cloneDeep(this.pCustCardVOList)
        this.custCard = this.custCardVOList[0] // 주고객

        this.custCardVOList.forEach(item => {
          item.chnlCtgrId = lv_Vm.custCard.chnlCstgrId // 채널카테고리ID
          item.mnCustNm = lv_Vm.custCard.custNm // 주고객명

          if (!this.$bizUtil.isEmpty(lv_Vm.custCard.custRltnCd)) {
            item.mnCustRltnCd = lv_Vm.custCard.custRltnCd // 주고객관계코드
          }
        });
      },
      /*****************************************************************************
       * @function fn_Camera
       * @name 카메라_촬영
       * @param imgFlag 앞면: front/ 뒷면: back
       * @param imgType Plugin 파라미터(jpeg) / Default: png 확장자
       * @notice 성공콜백 (result:{code:0, data:'base64'}) 앞부분 잘라서 넘어옴 base64
       *          result.data (취소: 'cancel'/재촬영: 'retake'/에러: 'error')
      *****************************************************************************/ 
      fn_Camera (imgFlag) {
        const lv_Vm = this

        // Local에서 PC 테스트
        if (!this.$commonUtil.isMobile()) {
          if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
            if (imgFlag === 'front') {
              this.frontImgData = lv_ImgData.lv_test
            } else if (imgFlag === 'back') {
              this.backImgData = lv_ImgData.lv_test
            }
          }
          return
        }

        window.fdpbridge.exec('CameraOnlyPlugin', {},
          // 성공콜백
          (result) => {
            if (result.data === 'cancel') {
              lv_Vm.getStore('confirm').dispatch('ADD', '사진등록을 취소하였습니다.')
            } else if (result.data === 'retake') {
              lv_Vm.fn_Camera(imgFlag)
            } else if (result.data === 'error') {
              lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생 했습니다. 다시 실행해 주세요')
            } else {
              const imgData = result.data.replace(/\[(.*)\]/, '$1').split(', ')
              if (imgFlag === 'front') { // 앞면
                lv_Vm.frontImgData = imgData[0]
              } else if (imgFlag === 'back') { // 뒷면
                lv_Vm.backImgData = imgData[0]
              }
            }
          },
          // 실패콜백
          () => {
            lv_Vm.getStore('confirm').dispatch('ADD', '사진등록 앱 호출 오류 발생 \n다시 등록해 주세요.')
          });

      },
      /*****************************************************************
       * @function fn_SendAprv
       * @notice   이미지 제출 주의사항 알럿 팝업
       *           확인 클릭시, NAS 업로드 함수 호출 : fn_ImageUpload()
      ******************************************************************/
      fn_SendAprv () {
        const lv_Vm = this
        let contentMsg = this.$t('cm')['ECMC086'] // '실제 고객의 의사에 준하여 고객이 직접 자필로 서명한 동의서인 경우에만 승인요청 가능합니다.<br><br>지점장이 동의 승인 후에는 고객에게 바로 동의처리 결과가 전송되오니 참고하시기 바랍니다.'

        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: "임의동의 제출 주의사항",
            content: contentMsg
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              lv_Vm.fn_ImageUpload()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        });

      },
      /************************************************
       * @function fn_ImageUpload
       * @notice NAS경로 이미지 업로드 Service Call
      *************************************************/
      fn_ImageUpload () {
        const lv_Vm = this
        
        if (this.$bizUtil.isEmpty(this.custCard)) {
          console.log('■■■■■ 고객 정보가 존재하지 않습니다. ■■■■■', this.custCard)
          return
        }

        let pParams = {
          foreImgData: this.frontImgData, // 앞면 이미지 데이터
          backImgData: this.backImgData,  // 뒷면 이미지 데이터

          // 등록 고객 리스트
          aprvCustList: this.custCardVOList
        }

        const trnstId = 'txTSSCM14U0'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            if (res.body.trnsRsltCd === 'S') {
              // lv_Vm.getStore('confirm').dispatch('ADD', res.body.trnsRsltCntnt)
              
              let toastMsg = '승인요청이 완료되었습니다.'
              this.$addSnackbar(toastMsg)
              // 이미지 업로드 성공!
              lv_Vm.fn_PopupClose()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '이미지 업로드를 실패하였습니다.')
            }
          }
        }).catch(e => {
          console.log(e)
          lv_Vm.getStore('confirm').dispatch('ADD', '이미지 업로드를 실패하였습니다.')
        });

      },
      /************************************************
       * @function fn_ImageClear
       * @notice   촬영 이미지 초기화
      *************************************************/
      fn_ImageClear (imgFlag) {
        if (imgFlag === 'front') {
          this.frontImgData = ''
          this.frontImgSrc = ''
        } else {
          this.backImgData = ''
          this.backImgSrc = ''
        }
      },
      /************************************************
       * @function fn_PopupClose
       * @name     팝업닫기
      *************************************************/
      fn_PopupClose () {
        this.$emit('onPopupCancel')
      },
    } // ::Methods End
  };
</script>
