/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM276D
 * 화면설명 : 보유계약
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : fn_CmptdContTot > 0}">
      <!-- 타이틀 영역 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb0">
        <mo-list-item>
           <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="sum">총 <strong class="crTy-blue3">{{ lv_Summary.length ? fn_SummaryTotalCnt : fn_CmptdContTot }}</strong> 건</span>
                </div>
              </div>
            </template>
            <template #btn>
                <mo-button v-if="fn_ShowHoshInfoSummary" class="link-arrow down"></mo-button>
                <div v-else class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span v-if="lv_Summary.length" class="sum"><strong class="crTy-blue3">{{ fn_ConvertCrncyView(fn_SummaryTotalPrm) }}</strong></span>
                  </div>
                </div>
            </template>
            <template #content>
              <div class="full-list mt20">
                <div class="list-item__contents con-area mt10">
                  <div class="item-box mt10 round-box pa1216 bgcolor-1">
                      <span class="fs14rem">※ 세대별 정보(계약자&고객카드 세대원기준)</span>
                      <div v-for="(item, index) of lv_Summary" :key="index" class="full fexTy3">
                        <span class="fs14rem">{{ item.custNm }} ({{ item.mnCustRltnNm }})</span>
                        <span class="crTy-bk1">{{ item.count }}건 / 월 {{ fn_ConvertCrncyView(item.prm) }}</span>
                      </div>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container>

      <!-- 보유계약 리스트 영역 -->
      <ur-box-container v-if="fn_CmptdContTot > 0" alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="lv_ContList">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents" @click="fn_MoveMSPPM931M(item)">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip">{{ item.prdtNm }}</span>

                  <!-- 계약상태코드명(뱃지) 정상/실효-->
                  <mo-badge class="badge-sample-badge sm" text="" shape="status"
                            :class="{'lightgreen2' : item.contStatCdNm === '정상', 'lightorange2' : item.contStatCdNm !== '정상'}">
                    {{ item.contStatCdNm }}
                  </mo-badge>
                </div>
                
                <div class="list-item__contents__info">
                  <!-- 계약번호 -->
                  <span class="crTy-bk2">{{ !$bizUtil.isEmpty(item.contNo) ? '************' + item.contNo.substr(-5) : '' }}</span><em>|</em>

                  <!-- 납입주기코드명, 화폐코드 -->
                  <span class="fwb crTy-blue3">
                    {{ item.padCylCdNm === '월납' ? '월' : '' }} {{ $bizUtil.numberWithCommasCurr(item.prm.amount, item.crncyCd, 'Y', 'Y', 3) }}
                  </span>
                </div>

                <!-- 계약자 | 피보험자 -->
                <div class="list-item__contents__info" :class="{'dsN' : item.contrNm === ''}" v-if="lv_CustNm !== item.contrNm">
                  <span class="fs14rem mr10">계약자</span>
                  <span class="crTy-bk1">{{ item.contrNm }}</span>
                </div>
                <div class="list-item__contents__info" :class="{'dsN' : item.insrdNm === ''}" v-if="lv_CustNm !== item.insrdNm">
                  <span class="fs14rem mr10">피보험자</span>
                  <span class="crTy-bk1">{{ item.insrdNm }}</span>
                </div>

              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray">
                <p class="mt10">보유계약이 없습니다.</p>
                <mo-button shape="border" class="ns-btn-round sm bdTy4 crTy-bk4" @click="fn_MoveMSPPI700M" :disabled="!btnDisable || lv_RsltBasSVO.coalCustYn === 'Y'">가입설계</mo-button>
              </span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>
  </ur-box-container>
</template>

<script>
import cmConstants from '~/src/config/constants/cmConstants';

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM276D', 
  // 현재 화면 ID
  screenId: 'MSPCM276D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '보유계약',
      lv_UserInfo: {}, // 사용자 정보
      
      lv_RsltBasSVO: {},
      lv_ContList: [], // 보유계약 리스트
      lv_Summary: {}, // 합계 정보
      lv_CustNm: '',   // 고객명
      btnDisable: false
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
        this.lv_CustNm = this.lv_RsltBasSVO.custNm

        this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
        // 본인 고객 여부
        this.btnDisable = this.lv_RsltBasSVO && this.lv_UserInfo ? this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId : false
      }
    },
    'pCustInfo.lv_ContList'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_ContList = this.pCustInfo.lv_ContList
        
        // 합계 데이터 세팅
        const hoshInfoNmList = [this.lv_CustNm, ...this.lv_RsltBasSVO.iCHoshInfoListInqrVO.map(v => v.custNm)] // 세대원 이름 목록
        this.lv_Summary = Object.entries(this.lv_ContList.reduce((acc, cur) => {
          // 세대원이 있는 경우에는 계약자가 본인 OR 세대원일 경우만 포함
          if (this.lv_RsltBasSVO.iCHoshInfoListInqrVO.length && !hoshInfoNmList.includes(cur.contrNm)) return acc

          acc[cur.contrNm] = {
            mnCustRltnCd: cur.mnCustRltnCd,
            mnCustRltnNm: cmConstants.CUST_RLTN_CD.filter(item => item.key === cur.mnCustRltnCd)[0]?.label,
            count: acc[cur.contrNm] ? acc[cur.contrNm].count += 1 : 1,
            prm: {
              ...acc[cur.contrNm]?.prm,
              [cur.crncyCd]: acc[cur.contrNm]?.prm[cur.crncyCd] ? acc[cur.contrNm].prm[cur.crncyCd] += parseInt(cur.prm.amount) : parseInt(cur.prm.amount)
            }
          }
          return acc
        }, {}))
        .map(([key, value]) => ({ custNm: key, ...value }))
        .sort((a, b) => parseInt(a.mnCustRltnCd) - parseInt(b.mnCustRltnCd) || b.amount - a.amount) // 주고객관계, 금액 순서대로 정렬
      }
    }
  },

  computed: {
    fn_CmptdContTot() {
      return this.lv_ContList ? this.lv_ContList.length : 0
    },
    fn_ShowHoshInfoSummary() {
      if (!this.lv_Summary.length) return false

      if (this.lv_RsltBasSVO.iCHoshInfoListInqrVO.length) {
        if (this.lv_Summary.length === 1) {
          return this.lv_Summary[0].custNm !== this.lv_CustNm
        }
        return true
      }
      return false
    },
    fn_SummaryTotalCnt() {
      return this.lv_Summary.reduce((acc, cur) => acc + cur.count, 0)
    },
    fn_SummaryTotalPrm() {
      return this.lv_Summary.reduce((acc, cur) => {
        Object.entries(cur.prm).forEach(([crncyCd, amount]) => {
          acc[crncyCd] = acc[crncyCd] ? acc[crncyCd] += parseInt(amount) : parseInt(amount)
        })
        return acc
      }, {})
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  create() {
    console.log('■■■■■■■ MSPCM276D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM276D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_ConvertCrncyView
     * @name     변환
     * @notice   합계금액 변환
    *********************************************************/
    fn_ConvertCrncyView(prm) {
      return Object.entries(prm)
        .sort(([crncyCd]) => crncyCd === 'KRW' ? -1 : 1)
        .map(([crncyCd, amount]) => this.$bizUtil.numberWithCommasCurr(amount, crncyCd, 'Y', 'Y', 3))
        .join(', ')
    },
    /*********************************************************
     * @function fn_MoveMSPPI001M
     * @name     화면전환
     * @notice   가입설계 화면 전환
     * @param zaAgtFileId 채널고객ID
    *********************************************************/
    fn_MoveMSPPI001M () {
      // 가입설계
      let param = {
        zaAgtFileId: this.lv_RsltBasSVO.chnlCustId // 채널고객ID
      }
      
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push( { name: 'MSPPI001M', params: param } );
    },
    /*********************************************************
     * @function fn_MoveMSPPM931M
     * @name     화면전환
     * @notice   보험계약기본사항 화면 전환
    *********************************************************/
    fn_MoveMSPPM931M (item) {
      let contInfoObj = {
        callerId: 'MSPCM270M', // 송신화면 ID
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,       // 담당컨설턴트 사번
        contNo: item.contNo, // 계약번호
      }

      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },
    /*********************************************************
     * @function fn_MoveMSPPI700M
     * @name     화면전환
     * @notice   보장설계 화면 전환
     *               MSPCT110M > MSPPI700M 변경(23.06.12 이민지 프로 요청사항)
    *********************************************************/
    fn_MoveMSPPI700M () { 
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)

      let pParams = {
        custNm: this.lv_RsltBasSVO.custNm, // 고객명
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
      }
      // this.$router.push( { name: 'MSPCT110M', params: pParams } );  
      this.$router.push( { name: 'MSPPI700M', params: pParams } );  
    },
  } // ::Methods End
};
</script>
