/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI701P
 * 화면 설명  : 보장,가입설계 - 고객선택 BottomConfirm
 */
<template>
  <mo-bottom-sheet ref="confirm" class="ns-bottom-sheet msp-bottomSt-alert" close-btn @closed="$emit('closed')">
    <div v-if="mndtCnstgCnsntYn && mktPrcusCnsntYn && covrAnlyYn" class="customer-info-txt txt-center mb10">
      <template v-if="mndtCnstgCnsntYn === 'Y' && mktPrcusCnsntYn === 'Y' && covrAnlyYn === 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계를
          <br>진행하시겠습니까?
        </p>
      </template>
      <template v-else-if="mndtCnstgCnsntYn === 'Y' && mktPrcusCnsntYn !== 'Y' && covrAnlyYn === 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계를
          <br>진행하시겠습니까?
        </p>
        <ul class="terms-list-area dsInline wauto mt10">
          <li class="crTy-bk7 fs16rem txt-left">알림톡/이메일 발송을 위한
            <span class="crTy-blue4 underline" @click="fn_MoveMktAgree">마케팅 동의<mo-icon icon="msp-arrow-pre" class="crTy-blue4 rotate180 mt4" /></span>
          </li>
        </ul>
      </template>
      <template v-else-if="mndtCnstgCnsntYn === 'Y' && mktPrcusCnsntYn === 'Y' && covrAnlyYn !== 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계를 위해  
          <br>보장설계를 먼저 진행해 주세요.
        </p>
      </template>
      <template v-else-if="mndtCnstgCnsntYn === 'Y' && mktPrcusCnsntYn !== 'Y' && covrAnlyYn !== 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계를 위해  
          <br>보장설계를 먼저 진행해 주세요.
        </p>
        <ul class="terms-list-area dsInline wauto mt10">
          <li class="crTy-bk7 fs16rem txt-left">알림톡/이메일 발송을 위한
            <span class="crTy-blue4 underline" @click="fn_MoveMktAgree">마케팅 동의<mo-icon icon="msp-arrow-pre" class="crTy-blue4 rotate180 mt4" /></span>
          </li>
        </ul>
      </template>
      <!-- 20230915_ASR230800632 / 사랑On 보장설계 안내 팝업 수정 - 문구 및 색상 -->
      <template v-else-if="mndtCnstgCnsntYn !== 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님은 필수 컨설팅동의가 유효하지않아
          <br>가입설계시에는 보험료 계산만 가능하며
        </p>
        <p class="ment mt10 fs16rem">
          동의 이후 심사 진행이 가능합니다.
        </p>
        <ul class="terms-list-area dsInline wauto mt10">
          <li class="crTy-bk7 fs16rem txt-left">심사/계약조회 위한
            <span class="crTy-blue4 underline" @click="fn_MoveMndtAgree">필수컨설팅 동의<mo-icon icon="msp-arrow-pre" class="crTy-blue4 rotate180 mt4" /></span>
          </li>
        </ul>
      </template>
      <!-- <template v-else-if="mndtCnstgCnsntYn !== 'Y' && mktPrcusCnsntYn === 'Y' && covrAnlyYn !== 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계 시에는 
          <br>보험료 계산만 가능합니다.
        </p>
        <p class="ment mt10 fs16rem">
          심사 진행을 위해서는 동의 후
          <br>보장설계를 진행해 주세요.
        </p>
        <ul class="terms-list-area dsInline wauto mt10">
          <li class="crTy-bk7 fs16rem txt-left">심사/계약조회 위한
            <span class="crTy-blue4 underline" @click="fn_MoveMndtAgree">필수컨설팅 동의<mo-icon icon="msp-arrow-pre" class="crTy-blue4 rotate180 mt4" /></span>
          </li>
        </ul>
      </template>
      <template v-else-if="mndtCnstgCnsntYn !== 'Y' && mktPrcusCnsntYn === 'Y' && covrAnlyYn === 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 가입설계 시에는 
          <br>보험료 계산만 가능합니다.
        </p>
        <ul class="terms-list-area dsInline wauto mt10">
          <li class="crTy-bk7 fs16rem txt-left">심사 진행을 위한
            <span class="crTy-blue4 underline" @click="fn_MoveMndtAgree">필수컨설팅 동의<mo-icon icon="msp-arrow-pre" class="crTy-blue4 rotate180 mt4" /></span>
          </li>
        </ul>
      </template>
      <template v-else-if="mndtCnstgCnsntYn !== 'Y' && mktPrcusCnsntYn !== 'Y' && covrAnlyYn !== 'Y'">
        <p class="ment fs16rem">
          {{$bizUtil.omitStr(custNm,4)}} 고객님의 보장/가입설계 위해 
          <br>먼저 동의를 받아주세요.
        </p>
        <p class="ment mt10 fs16rem">
          · 심사 진행시 필수컨설팅 동의 필요
        </p>
      </template> -->
    </div>
    <template v-slot:action v-if="mndtCnstgCnsntYn && mktPrcusCnsntYn && covrAnlyYn">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
        <div class="relative-div">
          <mo-button v-if="mndtCnstgCnsntYn !== 'Y' && mktPrcusCnsntYn !== 'Y' && covrAnlyYn !== 'Y'"
            componentid="" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_MoveReg">가입설계</mo-button>
          <mo-button v-if="mndtCnstgCnsntYn !== 'Y' && mktPrcusCnsntYn !== 'Y' && covrAnlyYn !== 'Y'"
            componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_MoveAgree">고객등록동의</mo-button>
          <mo-button v-else-if="mndtCnstgCnsntYn === 'Y' && covrAnlyYn !== 'Y'"
            componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_MoveCovr">보장설계 후 가입설계</mo-button>
          <template v-else>
            <mo-button componentid="" color="primary" shape="primary" size="border"
              class="ns-btn-round" :class="mndtCnstgCnsntYn !== 'Y' ? 'white' : covrAnlyYn === 'Y' ? 'white' : 'blue'" @click="fn_MoveCovr">보장설계</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="border"
              class="ns-btn-round" :class="mndtCnstgCnsntYn !== 'Y' ? 'blue' : covrAnlyYn === 'Y' ? 'blue' : 'white'" @click="fn_MoveReg">가입설계</mo-button>
            <!-- 2024.04.19 말풍선 추가 start -->
            <div v-if="tooltipShow" class="chat-bubble type-L1 blu ico1">
              <div class="wrap-txt">
                <div>
                  <div class="tit">가입설계가<br>더 편리해졌어요!</div>
                  <div class="exp">특약 선택/입력을<br>한번에~</div>
                </div>
                <div class="btn"><mo-button @click="fn_openMSPPI703P" class="white">자세히 보기</mo-button></div>
              </div>
              <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_closeTooltip" />
            </div>
            <!-- 2024.04.19 말풍선 추가 end -->
          </template>
        </div>
      </ur-box-container>
    </template>
  </mo-bottom-sheet>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPPI703P from "@/ui/pi/MSPPI703P"

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI701P',
  screenId: 'MSPPI701P',
  components: {
    MSPPI703P: MSPPI703P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
  },
  mounted() {
    if(localStorage.getItem('TOOLTIPSHOW') === 'N'){
      this.tooltipShow = false
    }else{
      this.tooltipShow = true
    }
      console.log('tooltipShow >> ', this.tooltipShow)
  },
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    chnlCustId: {
      type: String,
      default: ''
    },
    insrAge: {
      type: Number,
      default: 0
    },
    // 고객명
    custNm: {
      type: String,
      default: ''
    },
    // 성별코드명
    sxdsCdNm: {
      type: String,
      default: ''
    },
    dob: {
      type: String,
      default: ''
    },
    telno: {
      type: String,
      default: ''
    },
    // 필수컨설팅여부
    mndtCnstgCnsntYn: {
      type: String,
      default: ''
    },
    // 마케팅동의여부
    mktPrcusCnsntYn: {
      type: String,
      default: ''
    },
    // 보장분석여부
    covrAnlyYn: {
      type: String,
      default: ''
    },
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  // data() {
  //   return {
  //   };
  // },
  watch: {
    opened(val) {
      if (!val) return
      this.$refs.confirm.open()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
  },
  data() {
    return {
      tooltipShow: false
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_MoveMndtAgree() {
      this.$refs.confirm.close()
      this.$router.push({
        name: 'MSPCM050M',
        params: {
          custNm: this.custNm,
          srnId: this.$options.screenId,
          aprvType: '01',
          infoPrcusCnsntTypCd: '18', // '17' 필컨, '19': 마동, '18': 둘다
        }
      })
    },
    fn_MoveMktAgree() {
      this.$refs.confirm.close()
      this.$router.push({
        name: 'MSPCM050M',
        params: {
          custNm: this.custNm,
          srnId: this.$options.screenId,
          aprvType: '01',
          infoPrcusCnsntTypCd: '19', // '17' 필컨, '19': 마동, '18': 둘다
        }
      })
    },
    fn_MoveAgree() {
      this.$refs.confirm.close()
      this.$router.push({
        name: 'MSPCM050M',
        params: {
          custNm: this.custNm,
          srnId: this.$options.screenId,
          aprvType: '01',
          infoPrcusCnsntTypCd: '17', // '17' 필컨, '19': 마동, '18': 둘다
        }
      })
    },
    fn_MoveCovr() {
      this.$refs.confirm.close()
      this.$router.push({
        name:'MSPCT110M',
        params:{
          age: this.insrAge,
          custNm: this.custNm,
          gender: (this.sxdsCdNm === '남성' ? '1' : '2'),
          chnlCustId: this.chnlCustId,
          showMsgYn: (this.covrAnlyYn !== 'Y' ? 'Y' : ''),
        }
      })
    },
    fn_MoveReg() {
      this.$refs.confirm.close()
      this.$router.push({name:'MSPPI001M', params:{zaAgtFileId: this.chnlCustId}})
    },
    fn_closeTooltip() {
      localStorage.setItem('TOOLTIPSHOW', 'N')
      this.tooltipShow = false
    },
    fn_openMSPPI703P() {
      this.popup703 = this.$bottomModal.open(MSPPI703P, {
        properties: {
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup703)
          }
        }
      })
    }
  }
};
</script>
<style scoped>
</style>