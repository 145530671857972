/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI700M
 * 화면 설명  : 보장,가입설계
 */
<template>
  <ur-page-container ref="page" class="msp" title="가입보장설계" :show-title="false" :topButton="true" 
    @on-scroll-bottom="fn_ScrollBottomHandler"
    :topButtonBottom="topButtonBottom">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '가입보장설계' : '가입보장설계'}}</div>
              <div slot="action"> 
                <mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_MoveAgree">고객등록동의</mo-button> 
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <!-- MSPBC411D 컨설턴트영역 -->
            <MSPBC411D v-if="vGssAuthYN === 'Y'" @searchCallback="fn_SrchCallback" ref="page411D"/>
            <div v-else style="height: 0.1px;">&nbsp;</div>
            <!-- //MSPBC411D -->
          </template>
          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box :defaultIdx="lv_TabIdx" @tab-change="(idx) => lv_TabIdx = idx" class="ns-move-tab-box">
              <mo-tab-content :idx="0" label="고객선택" ></mo-tab-content>
              <mo-tab-content :idx="1" label="최근설계" ></mo-tab-content>      
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid=""  direction="column" class="con-area mt30 mb10"  v-if="lv_TabIdx === 0">
              <mo-text-field  ref="tab0TextField" class="form-input-name full" underline :clearable="lv_Tab0.searchKeyword !==''" searchable placeholder="고객명 입력 후 돋보기 버튼 선택" v-model="lv_Tab0.searchKeyword" @click-icon="fn_Tab0Search" @keyup.enter="fn_Tab0Search" @keyup="() => { lv_Tab0.searchKeyword = lv_Tab0.searchKeyword.toUpperCase() }"/>
            </ur-box-container>

            <ur-box-container alignV="start" componentid=""  direction="column" class="con-area mt30 mb10"  v-if="lv_TabIdx === 1">
              <div class="fexTy5">
                <msp-bottom-select class="ns-dropdown-sheet w40" :items="lv_Tab1.searchTypCds" :itemValue="'key'" :itemText="'label'" v-model="lv_Tab1.searchScVal" underline placeholder="고객명" bottom-title="상세조건 선택" closeBtn @input="fn_Tab1SelectChange"/>
                <mo-text-field ref="tab1TextField" class="form-input-name full ml10" v-model="lv_Tab1.searchKeyword" underline :clearable="lv_Tab1.searchKeyword !==''" searchable :placeholder="lv_Tab1.searchPlaceHolder" @click-icon="fn_Tab1Search()" @keyup.enter="fn_Tab1Search()"/><!-- 설계명 선택시 설계명을 입력해 주세요. -->
              </div>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum" v-if="lv_TabIdx === 0">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb0 align-item-center">
                    <div class="">
                      <span v-if="lv_Tab0.hasKeyword" class="sum mt0">총<span class="count">{{lv_Tab0.count}}</span>명</span>
                      <span v-else-if="lv_Tab0.searchKeyword.length === 0" class="fs16rem fwb">최근 검색한 고객</span>
                    </div>
                    <mo-button class="ns-btn-round blackW h28 fs14rem" @click="fn_MoveMSPPI001M" ref="btn_cust" :disabled="lv_btn_control.btn_cust">가상고객으로 설계</mo-button>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum" v-show="lv_TabIdx === 1">
              <mo-list-item>
                <mo-checkbox v-model="lv_Tab1.checkAll" class="mr10 mb4" size="small" @click="fn_Tab1SelectAllEventHandler(lv_Tab1.checkAll)"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb0 align-item-center">
                    <span class="sum" v-if="lv_Tab1.selectCnt === 0">총 <strong class="crTy-blue3">{{lv_Tab1.resultCnt}}</strong> 건</span>
                    <span class="sum" v-else><strong class="crTy-blue3">{{lv_Tab1.selectCnt}}</strong> 건 선택</span>
                    <!-- 리스트 정렬 컴포넌트 -->
                    <PSListSortOpt ref='sortFieldComp'
                                  defaultLabel='정렬선택'
                                  popupTitle="최근설계 정렬"
                                  itemValue="name" 
                                  itemText="label"
                                  :sortFieldList='lv_Tab1.sortFieldList'
                                  @sortChange='fn_Tab1SortChangeHandler'/>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 고객선택 -->
            <MSPPI701D v-if="lv_TabIdx === 0" ref="MSPPI701D"
              :search-keyword="lv_Tab0.searchKeyword"
              @loaded="(count, hasKeyword) => lv_Tab0 = {...lv_Tab0, count, hasKeyword}"/>

            <!-- 최근설계 -->
            <MSPPI702D v-if="lv_TabIdx === 1" ref="MSPPI702D"
              @countChange="fn_Tab1CountChange"
              @topButtonBottomChange="fn_Tab1TopButtonBottomChange"
              @scrollAnimateY="fn_Tab1ScrollAnimateY"/>
          </template>

        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- Toast -->
    <!-- <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar> -->


    
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import MSPPI701D from '@/ui/pi/MSPPI701D'
import MSPPI702D from '@/ui/pi/MSPPI702D'
import MSPBC411D from '@/ui/bc/MSPBC411D'
import Screen from '~systems/mixin/screen'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI700M',
  screenId: 'MSPPI700M',
  mixins: [Screen],
  components: { MSPPI701D, MSPPI702D, PSListSortOpt, MSPBC411D },
  props: {
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)

    // router로 전달받은 고객명
    if(this.$route.params.custNm !== undefined && this.$route.params.custNm !== null && this.$route.params.custNm !== ''){
      this.lv_Tab0.searchKeyword = this.$route.params.custNm
    }
    
    //ga매니저 권한
    this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN
  },
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)

    if (!this.$route.params.custNm) {
      this.$refs.tab0TextField.focus()
    }
    if (this.$route.params.srnId) {
      this.prevRoutePath = this.$route.params.srnId
    }

    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    // 최근설계에서 사용
    this.pageScrollAnimateY = createScrollAnimateInstance(document.getElementById(this.$refs.page.getScrollElementId()), 'y')

    this.$watch(
      () => this.$refs.page.beforeScroll,
      (val) => this.$refs.tab0TextField?.blur()
    )
  },
  beforeDestroy() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
  },
  activated() {
    //keepAlive : true 일때 GA매니저 가설 대행이면 조회 초기화
    let gssAuthYN = window.vue.getStore('cmStore').getters.getState.gssAuthYN
    if(gssAuthYN === 'Y'){
      this.$refs.page411D.fn_resetCnsltInfo()
      if(this.lv_TabIdx === 1) {
        this.$refs.MSPPI702D.fn_Reset()
        this.$refs.MSPPI702D.$data.searchFlag = false
        this.$refs.MSPPI702D.fn_ServiceData('S')
      }else{
        this.lv_Tab0.searchKeyword = ''
        this.$refs.MSPPI701D.$data.lv_SelectedIndex = -1
        this.$refs.MSPPI701D.fn_UnSelectItem()
        this.$refs.MSPPI701D.fn_LoadSearchHist()
      }
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      vGssAuthYN: '', //ga매니저 권한
      vEplyNo: '', // ga컨설턴트ID
      vGssEplyNm: '', // ga컨설턴트명
      lv_HeaderviewScrollCompID: '',
      lv_TabIdx: 0,
      lv_Tab0: {
        count: 0,
        searchKeyword: '',
        hasKeyword: false,
      },
      lv_Tab1: {
        searchScVal: '1', // 1:고객명 2:설계명
        searchKeyword: '', // 검색값
        searchPlaceHolder: '고객명을 입력해 주세요.',
        searchTypCds: [ // 검색 목록
          {key: '1', label: '고객명'},
          {key: '2', label: '설계명'}
        ],
        checkAll: false, // 전체 체크
        resultCnt: 0, // 조회된 데이터 총 건수
        selectCnt: 0, // 선택된 데이터 총 건수
        // 소팅 필드 리스트
        sortFieldList: [
          {field: 'entplYmd', label: '설계일', sort: 'DESC'},
          {field: 'insrdNm', label: '피보험자', sort: 'ASC'},
          {field: 'eusPrgStatNm', label: 'AEUS', sort: 'ASC'},
          {field: 'zaDsclsId', label: '선심사', sort: 'DESC'}
        ]
      },

      lv_btn_control: {
        btn_cust : false
      },
      topButtonBottom: 0,
      pageScrollAnimateY: null, // 페이지 Y 축 스크롤 객체
      prevRoutePath: null
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
  },
  watch: {
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * Function명 : fn_ClickBtnPrevious
     * 설명       : 이전버튼 click event frunction
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_ClickBtnPrevious (defaultBackAction) {
      if (this.prevRoutePath === 'MSPCM340M') { // New 통합조회 시스템에서 진입한 경우 해당 메뉴로 돌아가기
        this.$router.push({ name: 'MSPCM340M' })
      } else {
        this.$router.push({ name: 'MSPBC002M' })
      }
      // if (!defaultBackAction) {
      //   window.vue.getWkEvtHandler().$emit(Msg.DEVICE.BACK_KEY_NATIVE)
      //   return
      // }
      // // 이전 화면으로 이동
      // if(this.lv_TabIdx === 1) {
      //   if(this.lv_Tab1.selectCnt > 0) {
      //     let alertMsg = '선택을 그만두고 이전 화면으로 이동하시겠습니까?'
      //     let alertObj = this.$bottomModal.open(MSPBottomAlert, {
      //       properties: {
      //         type: 'B',
      //         content: alertMsg,
      //         title_neg_btn: '아니오',
      //         title_pos_btn: '예'
      //       },
      //       listeners: {
      //         onPopupConfirm: () => {
      //           this.$bottomModal.close(alertObj)
      //           defaultBackAction()
      //         },
      //         onPopupClose: () => {
      //           this.$bottomModal.close(alertObj)
      //         }
      //       }
      //     })
      //   }else {
      //     defaultBackAction()
      //   }
      // }else {
      //   defaultBackAction()
      // }
    },
    /******************************************************************************
    * Function명  : fn_Tab0Search
    * 설명        : 고객 조회
    ******************************************************************************/
    fn_Tab0Search() {
      this.$refs.MSPPI701D.fn_LoadList()
    },
    /******************************************************************************
     * Function명 : fn_Tab1SelectChange
     * 설명 : 전체 선택 이벤트 핸들러
     ******************************************************************************/
    fn_Tab1SelectChange(param) {
      if(param === '1') {
        this.lv_Tab1.searchPlaceHolder = '고객명을 입력해 주세요.'
      }else if(param === '2') {
        this.lv_Tab1.searchPlaceHolder = '설계명을 입력해 주세요.'
      }
    },
    /******************************************************************************
     * Function명 : fn_Tab1SelectAllEventHandler
     * 설명 : 전체 선택 이벤트 핸들러
     ******************************************************************************/
    fn_Tab1SelectAllEventHandler(checked) {
      this.$refs.MSPPI702D.fn_SelectAllEventHandler(checked)
    },
    /******************************************************************************
    * Function명  : fn_Tab1SortChangeHandler
    * 설명        : 정렬 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_Tab1SortChangeHandler(pSortOrder) {
      this.$refs.MSPPI702D.fn_SortChangeHandler(pSortOrder)
    },
    /******************************************************************************
    * Function명  : fn_Search
    * 설명        : 조회 액션
    ******************************************************************************/
    fn_Tab1Search() {
      this.$refs.MSPPI702D.fn_Reset()
      let o = {}
      o.searchScVal = this.lv_Tab1.searchScVal
      o.searchKeyword = this.lv_Tab1.searchKeyword.trim() || ''
      this.$refs.MSPPI702D.fn_Search(o)
    },
    /******************************************************************************
    * Function명  : fn_Tab1CountChange
    * 설명        : 선택건수 변경 이벤트
    ******************************************************************************/
    fn_Tab1CountChange(data) {
      let d = data
      this.lv_Tab1.resultCnt = d['resultCnt']
      this.lv_Tab1.selectCnt = d['selectCnt']
      this.lv_Tab1.checkAll = d['checkAll']
    },
    /******************************************************************************
    * Function명  : fn_Tab1TopButtonBottomChange
    * 설명        : TOP 버튼 위치값 변경
    ******************************************************************************/
    fn_Tab1TopButtonBottomChange(data) {
      this.topButtonBottom = data
    },
    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : 스크롤 하단위치시 리스트 추가 조회
    ******************************************************************************/
    fn_ScrollBottomHandler() {
      if(this.lv_TabIdx === 1) {
        this.$refs.MSPPI702D.fn_ScrollBottomHandler()
      }
    },
    /******************************************************************************
    * Function명  : fn_Tab1ScrollAnimateY
    * 설명        : 스크롤 상단 이동
    ******************************************************************************/
    fn_Tab1ScrollAnimateY() {
      // 페이징 재로드 발생 방지용 scroll top
      this.pageScrollAnimateY.animate(0, 700, 0, 'easeOut')
      // 정렬 선택 라벨 초기화
      this.$refs.sortFieldComp.fn_Reset()
    },

    /******************************************************************************
    * Function명  : fn_MoveMSPPI001M
    * 설명        : 가상고객설계 화면 이동
    ******************************************************************************/
    fn_MoveMSPPI001M() {
      this.lv_btn_control.btn_cust = true

      this.$router.push({
        name:'MSPPI001M',
        params:{
          zaAgtFileId:'none',
          planType:'2'
        },
      }).catch(()=>{})

      this.lv_btn_control.btn_cust = false
    },

    fn_MoveAgree() {
      let moveNm = 'MSPCM050M'
      if(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'GA') moveNm = 'MSPCM500M'
      this.$router.push({
        name: moveNm,
        params: {
          // custNm: this.$route.params.custNm || '',
          srnId: this.$options.screenId
          // aprvType: '01',
          // infoPrcusCnsntTypCd: '17', // '17' 필컨, '19': 마동, '18': 둘다
        }
      })
    },
    /*********************************************************
     * @function fn_SrchCallback
     * @name     콜백함수
     * @notice   컨설턴트 콜백함수
    *********************************************************/
    fn_SrchCallback(pData) {
      console.log('콜백 파라미터>>>>', pData)
      this.vEplyNo = pData.eplyNo // 컨설턴트ID
      this.vGssEplyNm = pData.gssEplyNm // 컨설턴트명

      console.log("변경한 컨설턴트 gssEplyInfo :::::::::::" + JSON.stringify(this.getStore('cmStore').getters.getState.gssEplyInfo))

      if(this.lv_TabIdx === 1) {
        this.$refs.MSPPI702D.fn_Reset()
        this.$refs.MSPPI702D.$data.searchFlag = false
        this.$refs.MSPPI702D.fn_ServiceData('S')
      }else{
        this.lv_Tab0.searchKeyword = ''
        this.$refs.MSPPI701D.fn_LoadSearchHist()
      }
    },

    //코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End() {return}
  }
};
</script>
<style scoped>
</style>