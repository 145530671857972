/*
 * 업무구분   : PUSH서비스
 * 화면 명    : MSPBC006P
 * 화면 설명  : 알림상세
 */
<template>
  <ur-page-container title="알림상세" class="msp" :show-title="true" type="popup" :id="scrollCompID">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="alarm-title">
          <!-- 알림종류별 아이콘 -->
          <em><img :src="fn_GetAlarmIcon(lv_AlarmItem.iapKndCd)" class="icon-alarm" alt="알림종류별 아이콘"></em>
          <!--
          <em><img src="/images/icon_alarm1.svg" class="icon-alarm" alt="접촉정보 아이콘"></em>
          <em><img src="/images/icon_alarm2.svg" class="icon-alarm" alt="내일정 아이콘"></em>
          <em><img src="/images/icon_alarm3.svg" class="icon-alarm" alt="공지사항 아이콘"></em>
          <em><img src="/images/icon_alarm4.svg" class="icon-alarm" alt="시스템공지 아이콘"></em>
          <em><img src="/images/icon_alarm5.svg" class="icon-alarm" alt="이벤트 아이콘"></em>
          -->
          <!-- 알림종류별 아이콘 //-->
          <span>{{lv_AlarmItem.iapPretxt}}</span>
          <p>
            {{lv_AlarmItem.iapCntnt}}
          </p>
        </div>

        <!-- 시스템공지 상세내역영역 -->
        <div class="alarm-detail-txt ns-style2" v-if="lv_AlarmItem.iapKndCd === '4'">
          <!--<span class="date">{{fn_GetIapReciDtm(lv_AlarmItem.iapReciDtm)}}</span>-->
          <span class="date" v-if="lv_AlarmItem.mobslBltnStrYmd && lv_AlarmItem.mobslBltnEndYmd">일시: {{fn_GetBltnStrEndYmd(lv_AlarmItem)}}</span>
          <div class="list-box">
            <p class="detail-txt">
              <span v-html="fn_GetDtlCntntForSysNotice(lv_AlarmItem.mobslIapDtlCntnt)"></span>              
            </p>
          </div>
          <!--
          <span class="date mt15" v-if="lv_AlarmItem.mobslBltnStrYmd && lv_AlarmItem.mobslBltnEndYmd">일시: {{fn_GetBltnStrEndYmd(lv_AlarmItem)}}</span>
          -->
        </div>
        <!-- 시스템공지 상세내역영역 //-->

        <!-- 시스템공지외 상세내역영역 -->
        <div class="alarm-detail-txt" v-else>
          <div class="mb100">
            <span class="date">{{lv_AlarmItem.iapReciDtm}}</span>
            <p class="detail-txt">
              <span class="htmlText" v-html="fn_GetDtlCntnt(lv_AlarmItem.iapKndCd, lv_AlarmItem.mobslIapDtlCntnt)"></span>
            </p>
            <img v-if="fn_GetIsShowDtlImg(lv_AlarmItem)" class="detail-img" :src="lv_DtlImg" @click="fn_DetailShow()">
          </div>
        </div>
        <!-- 시스템공지외 상세내역영역 //-->

        <!-- 화면이동 -->
        <!--ur-box-container v-if="fn_GetIsShowBtnMove(lv_AlarmItem)" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-round-btn ns-no-line">
          <mo-button class="ns-btn-round" @click="fn_ClickMoveSrn(lv_AlarmItem)">바로가기</mo-button>
        </ur-box-container -->
        <!-- 화면이동 //-->

        <!-- TOP버튼 -->
        <ur-box-container alignV="start" componentid="ur_box_container_016" direction="column" class="ns-top-scroll-btn bc006p-top-btn-display-none">
          <mo-button class="ns-top-btn" @click="fn_ScrollTo(0)">top</mo-button>
        </ur-box-container>
        <!-- TOP버튼 //-->

        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
          <mo-button v-if="fn_GetIsImgShowBtn()" componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_DetailShow">확대보기</mo-button>
          <mo-button v-if="fn_GetIsShowBtnMove(lv_AlarmItem)" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickMoveSrn(lv_AlarmItem)">바로가기</mo-button>
          <mo-button v-if="lv_IsMgr && lv_AlarmItem.iapKndCd === '4'" componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OpenMSPBC011M">수정하기</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import MenuConst from '@/ui/sp/MenuConstans.js'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPBC006P',
  screenId: 'MSPBC006P',
  // 컴포넌트 선언
  components: {
  },
  props: {
    pAlarmItem: {
      type: Object,
      default: {}
    },
    pIsMgr: {
      type: Boolean,
      default: false
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    // mode: 'right' // 우->좌 open popup (defalut: 하->상)
    full: true
  },
  /*****************************************************************************
  * 화면 전역 변수 선언 영역
  ****************************************************************************/
  data () {
    return {
      scrollCompID: '', // 스크롤영역 ID
      scrollAnimateY: null,
      lv_AlertPop: '', // bottom alert popup
      lv_AlarmItem: {},
      auncImgName: '',
      lv_DtlImg: '',
      lv_IsMgr: false
    }
  },
  /*****************************************************************************
  * Life Cycle 함수 정의 영역
  ****************************************************************************/
  created () {    
    // 알림내용
    this.lv_AlarmItem = this.$props.pAlarmItem
    // 스크롤 컴포넌트ID
    this.scrollCompID = 'scroll_' + this.$options.screenId
    console.log('this.scrollCompID', this.scrollCompID)

    // 알림 상세이미지 조회
    this.fn_SelIapDtlImg()

    // 공지사항 사용자 권한 조회 - 관리자시스템(AD99) 여부
    this.lv_IsMgr = this.$props.pIsMgr
  },
  beforeMount () {
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 스크롤 애니메이션 instance 선언
    let t_ElementByScrollCompID = document.getElementById(this.scrollCompID) // ui-dev쪽에 확인해 볼 것
    this.scrollAnimateY = createScrollAnimateInstance(t_ElementByScrollCompID.getElementsByClassName('mo-page__contents-wrapper')[0], 'y')
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    fn_GetIsImgShowBtn () {
      if (this.lv_DtlImg !== '') {
        return true
      } else {
        return false
      }
    }  
    ,  
    fn_DetailShow () {
      let vm = this
      window.fdpbridge.exec('ZoomViewerPlugin', {
        img: vm.lv_DtlImg
      }, () => {}, () => {})
    },
    /**
     * Function명 : fn_ScrollTo
     * 설명       : scroll 화면의 scrollTop 위치로 이동
     * @param {Number} scrollTop - 이동하고자 하는 세로 위치(0: 최상단)
     * @return {none} - 반환없음
     */
    fn_ScrollTo (scrollTop) {
      this.scrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
    },
    /**
     * Function명 : fn_SelIapDtlImg
     * 설명       : 알림상세이미지 조회
     * @param {none}
     * @return {String}
     */
    fn_SelIapDtlImg () {
      // 관리자 PUSH발송건이면서 본문이미지 존재 시 조회
      if ( !this.fn_GetIsShowDtlImg(this.lv_AlarmItem) ) return

      // 상세이미지명 getter
      let t_DtlImg = this.fn_GetSrcDtlImg(this.lv_AlarmItem)

      // 상세이미지 없을 경우 SKIP
      if ( t_DtlImg === undefined || t_DtlImg === null || t_DtlImg === '' ) return

      let lv_Vm = this
      let pParams = {
        'srchCond': t_DtlImg
      }
      this.lv_DtlImg = ''
      const trnstId = 'txTSSBC03SD'
      const auth = 'S'
      this.lv_IsLoadingStatus = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( response && response.body && response.body.dtlImg ) {
            console.log('알림상세이미지 조회 ::', response.body)
            lv_Vm.lv_DtlImg = response.body.dtlImg
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        }).finally(() => {
          lv_Vm.lv_IsLoadingStatus = false
        })
    },
    /**
     * Function명 : fn_GetIapReciDtm
     * 설명       : 알림수신일시를 월일 형태로 변환
     * @param {none}
     * @return {String}
     */
    fn_GetIapReciDtm (pDate) {
      // 파라미터 check
      if ( pDate === undefined || pDate === null || pDate === '' ) return ''

      let t_RtnDate = pDate.substr(5, 11)
      return t_RtnDate
    },
    /**
     * Function명 : fn_GetBltnStrEndYmd
     * 설명       : 게시일자를 년월일 형태로 변환
     * @param {none}
     * @return {String} XXXX년 XX월 XX일 ~ XXXX년 XX월 XX일
     */
    fn_GetBltnStrEndYmd (pItem) {
      let t_BltnStrYmd = pItem.mobslBltnStrYmd
      let t_BltnEndYmd = pItem.mobslBltnEndYmd
      let t_RtnDate = t_BltnStrYmd.substr(0, 4) + '년 ' + t_BltnStrYmd.substr(4, 2) + '월 ' + t_BltnStrYmd.substr(6, 2) + '일 ~ '
      t_RtnDate += t_BltnEndYmd.substr(0, 4) + '년 ' + t_BltnEndYmd.substr(4, 2) + '월 ' + t_BltnEndYmd.substr(6, 2) + '일'
      return t_RtnDate
    },
    /**
     * Function명 : fn_GetAlarmIcon
     * 설명       : 알림종류별 아이콘 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetAlarmIcon (pIapKndCd) {
      // 접촉고객, EVT: 이벤트고객, NOTIF:공지사항(12:관리자Push등록, 13:선지급명세, 41: 프리미엄고객사랑서비스 방문결과 미입력), SCHDL: 일정, 시스템공지
      let t_RtnIconSrc = '/images/'
      let t_RtnIconImg = ''

      // 접촉고객
      if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        t_RtnIconImg = 'icon_alarm1.svg'
      }

      // 이벤트고객
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        t_RtnIconImg = 'icon_alarm5.svg'
      }

      // 공지사항(12:관리자Push등록, 13:선지급명세, 41: 프리미엄고객사랑서비스 방문결과 미입력, 80:초회보험료, 81:심사완료, 82:보장설계PDF, 83:골든룰, 95:휴일문의메시지응답처리)
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PRPYM_SPCF
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_FRST_TM_PREM
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_UNDW_CMPLT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PRCS_CNTC
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_GOLDEN_RULE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ATNT_RPLC_CONT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_HOLY_MSG_RESP_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PHONE_RECV_LOGIN_NOTICE ) {
        t_RtnIconImg = 'icon_alarm3.svg'
      }

      // 일정
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_SCHDL_NOTICE ) {
         t_RtnIconImg = 'icon_alarm2.svg'
      }

      // 시스템공지
      else if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_SYS_NOTICE ) {
         t_RtnIconImg = 'icon_alarm4.svg'
      }
      return (t_RtnIconSrc + t_RtnIconImg)
    },
    /**
     * Function명 : fn_GetIapCntnt
     * 설명       : 알림내용 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIapCntnt (pItem) {
      let t_RtnTxt = ''
      if ( pItem === undefined || pItem === null || pItem.length <= 0 ) {
        t_RtnTxt = ''
      } else {
        t_RtnTxt = pItem.iapCntnt

        let t_IapKndCd = pItem.iapKndCd
        // 알림종류코드가 접촉고객(2)인 경우 '[접촉정보]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
          t_RtnTxt = t_RtnTxt.replace('[접촉정보]', '').trim()
        }

        // 알림종류코드가 이벤트고객(9)인 경우 '[활동캘린더]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
          t_RtnTxt = t_RtnTxt.replace('[활동캘린더]', '').trim()
        }

        // 알림종류코드가 이벤트고객(41)인 경우 '[프리미엄고객사랑서비스]' 문구삭제
        if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CULVS_DONOT_INPT_NOTICE ) {
          t_RtnTxt = t_RtnTxt.replace('[프리미엄고객사랑서비스]', '').trim()
        }
      }
      return t_RtnTxt
    },
    /**
     * Function명 : fn_GetIsShowDtlImg
     * 설명       : 본문 이미지 노출여부 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIsShowDtlImg (pItem) {
      // 본문이미지 존재 시 노출함
      let t_RtnIsShowImg = false
      // 12:관리자Push등록
      if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          if ( t_ArrIapInqrKeyVal[1] !== undefined && t_ArrIapInqrKeyVal[1] !== null && t_ArrIapInqrKeyVal[1] !== '' ) {
            t_RtnIsShowImg = true
          }
        }
      }
      return t_RtnIsShowImg
    },
    /**
     * Function명 : fn_GetSrcDtlImg
     * 설명       : 본문 이미지 src getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetSrcDtlImg (pItem) {
      // 본문이미지 존재 시 노출함
      let t_RtnSrcImg = ''
      // 12:관리자Push등록
      if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          if ( t_ArrIapInqrKeyVal[1] !== undefined && t_ArrIapInqrKeyVal[1] !== null || t_ArrIapInqrKeyVal[1] !== '' ) {
            t_RtnSrcImg = t_ArrIapInqrKeyVal[1]
          }
        }
      }
      return t_RtnSrcImg
    },
    /**
     * Function명 : fn_GetIsShowBtnMove
     * 설명       : 바로가기 노출여부 getter
     * @param {none}
     * @return {none} - 반환없음
     */
    fn_GetIsShowBtnMove (pItem) {
      // 연결화면정보 존재여부에 따라 노출
      let t_RtnIsShowBtnMove = false
      // 12:관리자Push등록
      if ( pItem.iapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE ) {
        let t_IapInqrKeyVal = pItem.iapInqrKeyVal
        let t_ArrIapInqrKeyVal = t_IapInqrKeyVal.split('|')
        if ( t_ArrIapInqrKeyVal !== undefined && t_ArrIapInqrKeyVal !== null || t_ArrIapInqrKeyVal.length > 0 ) {
          let t_SrnType = t_ArrIapInqrKeyVal[2] // 화면이동구분
          let t_SrnId = t_ArrIapInqrKeyVal[3] // 화면ID
          if ( t_SrnType !== undefined && t_SrnType !== null && t_SrnType !== ''
            && t_SrnId !== undefined && t_SrnId !== null && t_SrnId !== '' ) {
            t_RtnIsShowBtnMove = true
          }
        }
      }
      return t_RtnIsShowBtnMove
    },
    /**
     * Function명 : fn_ClickMoveSrn
     * 설명       : 화면이동
     * @param {Object} pItem - 알림상세내역
     * @return {none} - 반환없음
     */
    fn_ClickMoveSrn (pItem) {
      // 화면이동
      this.$emit('onSrcMove', {'rtnData': pItem})
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /**
     * Function명 : fn_BottomLogoutConfirm
     * 설명       : 로그아웃 관련 메시지를 BottomAlert을 confirm창으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomLogoutConfirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_OnPositive()
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_OnNegative()
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /**
     * Function명 : fn_GetDtlCntntForSysNotice
     * 설명       : 공지사항 상세내역에 링크 존재 할 경우 링크 클릭 막음
     * @param {String} pTxt - 시스템공지사항 상세내역
     * @return {String} - <a> tag를 <p> tag로 변환
     */
    fn_GetDtlCntntForSysNotice (pTxt) {
      if ( pTxt === undefined || pTxt === null ) return ''

      let t_RtnTxt = ''
      t_RtnTxt = this.$bizUtil.replaceAll(pTxt, '<a', '<p')
      t_RtnTxt = this.$bizUtil.replaceAll(t_RtnTxt, '</a', '</p')      
      return t_RtnTxt
    },
    /**
     * Function명 : fn_GetDtlCntnt
     * 설명       : 관리자 푸쉬발송 상세내역의 개행문자를 <br/>태그로 치환
     * @param {String} pTxt - 시스템공지사항 상세내역
     * @return {String} - <a> tag를 <p> tag로 변환
     */
    fn_GetDtlCntnt(pIapKndCd, pMobslIapDtlCntnt) {
      let t_RtnTxt = pMobslIapDtlCntnt
      if ( pIapKndCd === MenuConst.PUSH_ALARM_TYPE_ADMIN_SEND_NOTICE 
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_FRST_TM_PREM
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_UNDW_CMPLT
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PRCS_CNTC
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_GOLDEN_RULE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_HOLY_MSG_RESP_NOTICE
        || pIapKndCd === MenuConst.PUSH_ALARM_TYPE_PHONE_RECV_LOGIN_NOTICE) {
          
        t_RtnTxt = pMobslIapDtlCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      }
      return t_RtnTxt
    },
    /**
     * Function명 : fn_OpenMSPBC011M
     * 설명       : 수정작
     * @param {String} pTxt - 시스템공지사항 상세내역
     * @return {String} - <a> tag를 <p> tag로 변환
     */
    fn_OpenMSPBC011M() {      
      console.log('fn_OpenMSPBC011M >', this.lv_AlarmItem)
      this.$router.push({name: 'MSPBC011M', params: {pAlarmItem: this.lv_AlarmItem, isModify: true}}).catch(()=>{})
    }
  }
}
</script>

<style scoped>
.ui-frame__scroll-position-top .bc006p-top-btn-display-none {
  display: none;
}
</style>