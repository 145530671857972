/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI252P
 * 화면설명: 발행 > 터치On 문자발송 팝업
 * 접근권한: 
 * 작 성 일: 2023.03.20
 * 작 성 자: ---
 */
<template>
  <ur-page-container class="msp" title="터치On 문자발송" :show-title="true" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <div class="pt30 pb20 pl24 pr24 fs16rem w100 bd-b-Ty1">
          <span class=""><b class="crTy-blue2">{{lv_SmsTargetCustLen}}명</b>의 고객에게 문자를 발송합니다.</span>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt30">
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <div class="title fexTy3">
            <span class="fs14rem crTy-bk7">발송메세지</span>
            <span class="fs12rem crTy-blue2">LMS로 발송</span>
            <!-- <span class="fs12rem" v-if="byteLength <= SMS_BYTE">SMS로 발송</span>
            <span class="fs12rem crTy-blue2" v-else>LMS로 발송</span> -->
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-area class="hauto pa12 crTy-bk1" rows="14" placeholder="메시지를 입력하세요" v-model="lv_InputVal" :value="lv_InputVal"/>
            <!-- <mo-text-area class="hauto pa12 crTy-bk1" rows="14" placeholder="메시지를 입력하세요" :maxlength="lv_RalMaxLength" v-model="lv_InputVal" :value="lv_InputVal"/> -->
            <div class="fex-rw fexJsCtTy3 pt10 pb20">
              <div class="crTy-bk7">
                <!-- <span class="pl2 pr2" v-if="byteLength <= SMS_BYTE">{{byteLength}}</span>
                <span class="fwb pr2 crTy-blue2" v-else>{{byteLength}}</span>
                <span class="pl2 pr2">/</span>
                <span class="pl2 pr2" v-if="byteLength <= SMS_BYTE">{{SMS_BYTE}}</span><span class="pl2 pr2" v-else>{{MAX_BYTE}}</span>byte -->
              </div>
            </div>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
            <div class="fexTy3">
              <span class="crTy-bk7">총 <b class="fwb fs16rem pr2 crTy-bk1">{{lv_SmsPoint}}</b>P<em class="pl10 pr10 space-line"></em> 사용가능 <b class="crTy-bk1">{{$bizUtil.numberWithCommas(lv_CurrentEcash)}}</b> P</span>
              <mo-button color="normal" v-if="!isIOS" shape="border" size="small" class="bdTy4 crTy-bk4" @click="fn_mov_TOUCHON_OnClick">충전</mo-button>
            </div>
            <div class="crTy-orange2 pt6">* 이캐시 충전은 태블릿에서만 가능합니다.</div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseMSPPI252P">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="lv_InputVal.trim() === ''" @click="fn_ConfirmMSPPI252P">발송</mo-button>
        </div>
      </ur-box-container>  
    </ur-box-container>
    <!-- Content영역 END-->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI252P',
  screenId: 'MSPPI252P',
  props: {
    pIsShowTSSPI391P: Boolean,
    pSmsTargetCustList: Array,
    pSelectedUntySlmtItemList: Array, // 선택된 자료 목록
    pIsSelectedMultiItem: Boolean,
    pSelectedSingleItem: Object
  },
  components: {},

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_InputVal: '',
      lv_IsShowTSSPI391P: false,
      lv_CurrentEcash: '',
      lv_celNo: '',

      SMS_BYTE: 80,
      MAX_BYTE: 2000,
      
      // 체크 변수
      isLoading: false,
      isNotEditable: false,

      // 화면 변수
      lv_SmsTargetCustLen: 1,   // 수신고객목록 길이
      lv_SmsPoint: 35,           // 메시지에 사용될 포인트
      // lv_SmsPoint: 0,           // 메시지에 사용될 포인트
      lv_InputVal: '',          // 메시지 본문
      lv_showVal: '',           // 메시지 본문(화면 출력용)
      // lv_CurrentEcash: 0,       // 사용가능 이캐시
      lv_chnlCustIds: '',
      lv_inputParamI: {         // 통신 데이터셋
        cnsltNo: '',
        customerIds: '',
        customerIdCnt: '',
        purchase: [],
        sysType: ''
      },
      lv_RalMaxLength: 9999,
    };
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let lv_Vm = this
    this.lv_InputVal = this.defTextArea

    // lv_Vm.isNotEditable = lv_Vm.pIsNotEditable
    // lv_Vm.lv_SmsTargetCustLen = lv_Vm.pSmsTargetCustList.length
    // lv_Vm.lv_SmsPoint = lv_Vm.lv_SmsTargetCustLen * 35
    // 전송 메시지 세팅
    lv_Vm.lv_InputVal = _.cloneDeep(lv_Vm.pMsg)
    if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_InputVal)) {
      lv_Vm.lv_showVal = lv_Vm.lv_InputVal.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    this.lv_celNo = this.pSmsTargetCustList[0].celno
    this.lv_InputVal = this.$commonUtil.convertToMask('NAM', this.pSmsTargetCustList[0].custNm, true) + ' 고객님 안녕하세요,\n' +
                      '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + ' 컨설턴트 입니다.\n' +
                      '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                      '추가적으로 궁금한 사항에 대해 연락주시면,\n' +
                      '언제든지 최선을 다해 상담하여 드리겠습니다.\n' +
                      '오늘도 즐거운 하루 되세요!\n'
    // 사용가능 이캐시 조회
    lv_Vm.fn_selUsePsbEcash()
  },

  mounted() {
    //화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  computed: {
    byteLength () {
      return this.fn_GetStringByte(this.lv_InputVal)
    },
    isIOS () {
      return this.$commonUtil.getIsIOS()
    }
  },

  watch: {
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_CloseMSPPI252P
     * 설명: 팝업 종료
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CloseMSPPI252P () {
      this.$emit('evtCloseMSPPI252P')
    },
    /*********************************************************
     * Function명: fn_ConfirmTSSPI391P
     * 설명: 부모화면에 LMS 발송 이벤트 전달
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ConfirmMSPPI252P () {
      let lv_Vm = this
      if (this.lv_SmsPoint > this.lv_CurrentEcash) {
        this.getStore('confirm').dispatch('ADD', '문자 발송을 위한 이캐쉬가 부족합니다.')
        return
      }
      lv_Vm.$emit('evtConfirmMSPPI252P', lv_Vm.lv_InputVal)
    },
    /******************************************************************************
    * Function명 : fn_selUsePsbEcash
    * 설명       : 사용가능 이캐시 조회
    *
    ******************************************************************************/
    fn_selUsePsbEcash () {
      const trnstId = 'txTSSCM62S1'
      const auth = 'S'
      var lv_Vm = this
      let pParams = {
        userId: this.getStore('userInfo').getters.getUserInfo.userId // 컨설턴트번호
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.userId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      let confirm = this.getStore('confirm')
      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            // 사용가능 이캐시 데이터 바인딩
            lv_Vm.lv_CurrentEcash = responseList.currentEcash
          } else {
            // confirm.dispatch('SETCOLOR', 'blue')
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명  : fn_GetStringByte()
    * 설명        : 스트링 값에 따른 데이터베이스 바이트 계산
    * Params      : N/A
    * Return      : 스트링 값에 따른 데이터베이스 바이트.
    ******************************************************************************/
    fn_GetStringByte (pStr) {
      let int_char_cnt = 0
      if (!this.$bizUtil.isEmpty(pStr)) {
        let contents = pStr
        let ralLength = 0

        for (let i = 0; i < contents.length; i++) {
          let str_char = escape(contents.charAt(i))

          if (str_char.length === 1) {
            int_char_cnt++
          } else if (str_char.indexOf('%u') !== -1) {
            int_char_cnt += 2
          } else if (str_char.indexOf('%') !== -1) {
            int_char_cnt += str_char.length / 3
          }

          if (int_char_cnt <= this.MAX_BYTE) {
            ralLength = i+1
          }
        }
  
        if (int_char_cnt > this.MAX_BYTE) {
          this.lv_RalMaxLength = ralLength
          this.lv_InputVal = this.lv_InputVal.substr(0, ralLength)
        }
  
        return int_char_cnt
      }
      else return 0
    },

    /******************************************************************************
     * Function명: fn_mov_TOUCHON_OnClick
     * 설명: 충전 버튼 클릭 핸들러 (터치온 앱 연동)
     * Params: N/A
     * Return: N/A
     ******************************************************************************/
    fn_mov_TOUCHON_OnClick () {
      let lv_Vm = this
      let t_Param = []
      let t_Type = 'touchon' // sms, touchon(ecard), email

      for (let i = 0; i < lv_Vm.pSmsTargetCustList.length; i++) {
        t_Param.push(lv_Vm.pSmsTargetCustList[i].chnlCustId)
      }

      lv_Vm.$MenuManager.fn_SendIntentTouchOnRiciCust(t_Type, t_Param)
    },

    // /******************************************************************************
    //  * Function명: fn_CheckPoint
    //  * 설명: 사용가능 포인트와 문자 발송금액 비교
    //  * Params: N/A
    //  * Return: N/A
    //  ******************************************************************************/
    // fn_CheckPoint () {
    //   let lv_Vm = this

    //   if (this.lv_SmsPoint > this.lv_CurrentEcash) {
    //     this.getStore('confirm').dispatch('SETCOLOR', 'blue')
    //     this.getStore('confirm').dispatch('ADD', '문자 발송을 위한 이캐쉬가 부족합니다.')
    //   } else {
    //     lv_Vm.fn_DataSetI()   // Input값 셋팅
    //     lv_Vm.fn_smsMessage() // SMS 전송
    //   }
    // },

    // /******************************************************************************
    //   * Function명 : fn_DataSetI
    //   * 설명       : 문자 발송전 데이터 세팅
    // ******************************************************************************/
    // fn_DataSetI () {
    //   var lv_Vm = this

    //   lv_Vm.lv_chnlCustIds = ''
    //   lv_Vm.lv_inputParamI = {
    //     cnsltNo: '',
    //     customerIds: '',
    //     customerIdCnt: '',
    //     purchase: [],
    //     sysType: ''
    //   }

    //   switch (lv_Vm.pPage) {
    //     // 유지체크명세
    //     case 'MSPPM870M' :
    //       lv_Vm.lv_inputParamI.sysType = 'MSPPM870M'
    //       break
    //     // 자동이체미이체
    //     case 'MSPPM910M' :
    //       lv_Vm.lv_inputParamI.sysType = 'MSPPM910M'
    //       break
    //   }

    //   lv_Vm.pSmsTargetCustList.forEach(item => {
    //     if (lv_Vm.lv_chnlCustIds !== '') {
    //       lv_Vm.lv_chnlCustIds = lv_Vm.lv_chnlCustIds + ','
    //     }
    //     lv_Vm.lv_chnlCustIds = lv_Vm.lv_chnlCustIds + item.chnlCustId
    //   })
    //   lv_Vm.lv_inputParamI.cnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId
    //   lv_Vm.lv_inputParamI.customerIds = lv_Vm.lv_chnlCustIds
    //   lv_Vm.lv_inputParamI.customerIdCnt = lv_Vm.pSmsTargetCustList.length

    //   let saveObj = {}
    //   saveObj.unitPrice = 35
    //   saveObj.totalAmount = 35
    //   saveObj.subType = 2
    //   saveObj.goodsOptions = lv_Vm.lv_InputVal // 문자 본문

    //   lv_Vm.lv_inputParamI.purchase.push(saveObj)
    // },

    // /******************************************************************************
    // * Function명 : fn_smsMessage
    // * 설명       : SMS 전송
    // ******************************************************************************/
    // fn_smsMessage () {
    //   const trnstId = 'txTSSPM01S1'
    //   const auth = 'S'
    //   var lv_Vm = this
    //   let pParams = lv_Vm.lv_inputParamI
    //   let confirm = this.getStore('confirm')

    //   this.isLoading = true
    //   this.post(lv_Vm, pParams, trnstId, auth)
    //     .then(function (response) {
    //     // 서버 데이터 가져오기
    //       if (response.body !== null && response.body !== '') {
    //         let responseList = response.body.pMEtchLinkRsltVO

    //         // 사용가능 이캐시 데이터 바인딩
    //         lv_Vm.currentEcash = responseList.currentEcash

    //         // S : 성공
    //         // F : Exception 오류
    //         // M1 : 이캐시 정합성 체크 오류
    //         // M2 : 차감할 수 있는 이캐시 이력이 없음
    //         // C1 : 수신동의여부 'N'으로 문자발송불가
    //         // C2 : 고객전화번호 공란으로 문자발송불가
    //         // C0 : 고객 정보가 없습니다

    //         if (responseList.code !== 'S') {
    //           if (responseList.code === 'M2') {
    //             let tempMsg = responseList.message + '\n 관리자에게 문의하세요.'
    //             confirm.dispatch('SETCOLOR', 'blue')
    //             confirm.dispatch('ADD', tempMsg)
    //           } else {
    //             confirm.dispatch('SETCOLOR', 'blue')
    //             confirm.dispatch('ADD', 'code : ' + responseList.code + ', message : ' + responseList.message)
    //           }
    //         } else {
    //           lv_Vm.fn_Close('confirm')
    //         }
    //       } else {
    //         confirm.dispatch('SETCOLOR', 'blue')
    //         confirm.dispatch('ADD', response.msgComm.msgDesc)
    //       }

    //       lv_Vm.isLoading = false
    //     })
    //     .catch(function (error) {
    //       window.vue.error(error)
    //     })
    // },

    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기 이벤트
     *              str: cancel(취소), confirm(문자 발송 완료)
     ******************************************************************************/
    fn_Close (str) {
      let rtnData = {}
      rtnData = {
        str: str,
        parentsViewId: this.pPage
      }

      this.$emit('onPopupClose', rtnData)
    },
  }
}
</script>