 /*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM271D
 * 화면설명 : 고객정보 - 기본정보
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys box-shadow__line">
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area pb50 pt30">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">고객정보</span>
          <mo-button class="ns-btn-round white sm" @click="fn_MoveMSPCM050M()" :disabled="!fn_CmptdChkCnslt">고객등록동의</mo-button>
        </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="bd-T-Ty1">
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">{{ lv_RsltBasSVO.knbScCd === 'ZPER02' ? '외국인등록번호' : '주민등록번호' }}</span>
          
          <!-- 임시고객 === 미확보 -->
          <span v-if="lv_RsltBasSVO.chnlCustScCd === '02'"
                class="fs16rem fwb ml20">
            {{ lv_RsltBasSVO.knbFrno }}-
            <span class="crTy-red1">미확보</span>
          </span>
          <span v-else class="fs16rem fwb ml20">
            {{ $cmUtil.insertString(lv_RsltBasSVO.knb, 6, '-') }}
          </span>

        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16" v-if="fn_CmptdSameRealBirth">
          <span class="fs16rem min90">실제생일</span>
          <span class="fs16rem fwb ml20">
            {{ $bizUtil.dateDashFormat(lv_RsltBasSVO.ralDob) }} ({{lv_RsltBasSVO.samScCd === '01' ? '양력' : '음력'}})
          </span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">주고객명</span>
          <span class="fs16rem fwb ml20">{{ fn_CmptdMnCustNm }}</span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">주고객관계</span>
          <span class="fs16rem fwb ml20">{{ fn_CmptdCustRltnNm }}</span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">직업</span>
          <span class="fs16rem fwb ml20">{{ lv_RsltBasSVO.jobCdNm }}</span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">직장명</span>
          <span class="fs16rem fwb ml20">{{ lv_RsltBasSVO.jobpNm }}</span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">휴대폰</span>
          <span class="fs16rem fwb ml20 fex-clumn">
            <span>{{ lv_RsltBasSVO.celno }}</span>
            <span class="mt4">
              <mo-badge class="badge-box sm lightgreen2" shape="status"
                        v-if="!$bizUtil.isEmpty(fn_CmptdCelStatNm)" :class="lv_RsltBasSVO.celnoStatCo === '21' ? 'lightgreen2': 'lightorange2'">
                {{ fn_CmptdCelStatNm }}
              </mo-badge>
              <mo-badge class="badge-box sm lightgray2 ml10" shape="status"
                        v-if="!$bizUtil.isEmpty(fn_CmptdCelStatNm)">
                {{ lv_RsltBasSVO.celnoStatCo === '21' ? '수신가능': '수신불가'}}
              </mo-badge>
            </span>
          </span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">이메일</span>
          <span v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.emailAddr)" class="fs16rem fwb ml20 fex-clumn">
            <span>{{ lv_RsltBasSVO.emailAddr }}</span>
            <span class="mt4">
              <!-- 정상: class="lightgreen2", 발송실패/오류/중복: class="lightorange2" -->
              <mo-badge class="badge-box sm lightgreen2" shape="status" v-if="!$bizUtil.isEmpty(fn_CmptdEmailStatNm)"
                        :class="lv_RsltBasSVO.emailStatCd === '31' ? 'lightgreen2': 'lightorange2'">
                {{ fn_CmptdEmailStatNm }}
              </mo-badge>
              <mo-badge v-if="!$bizUtil.isEmpty(fn_CmptdEmailStatNm)" class="badge-box sm lightgray2 ml10" shape="status">
                {{ lv_RsltBasSVO.emailReciCnsntYn === 'Y'? '수신가능' : '수신불가' }}
              </mo-badge>
            </span>
          </span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">자택주소</span>
          <span class="fs16rem fwb ml20 fex-clumn">
            <span class="fexTy2">
              <mo-icon icon="msp-location" class="mt2" v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.homAddr)" @click="fn_ChkMobileType('home')"/>
              <span class="ml4" @click="fn_ChkMobileType('home')">
                {{ lv_RsltBasSVO.homFrnoPstcd }}
                {{ lv_RsltBasSVO.homAddr }}
                {{ lv_RsltBasSVO.bldgNo01 }}
                {{ lv_RsltBasSVO.homDtlad }}
                {{ lv_RsltBasSVO.adtnAddr01 }}
              </span>
            </span>
            <span class="mt4">
              <!-- 정상: class="lightgreen2", 반송/오류: class="lightorange2" -->
              <mo-badge class="badge-box sm" shape="status" v-if="!$bizUtil.isEmpty(fn_CmptdHomAddrStatNm)"
                        :class="lv_RsltBasSVO.homAddrStatCd === '11' ? 'lightgreen2' : 'lightorange2'">
                {{ fn_CmptdHomAddrStatNm }}
              </mo-badge>

              <mo-badge v-if="fn_CmptdMailRcpplScCd === '11'" class="badge-box sm lightgray2 ml10" shape="status">우편수령지</mo-badge>
            </span>
          </span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">자택전화</span>
          <span class="fs16rem fwb ml20">{{ lv_RsltBasSVO.homTelno }}</span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">직장주소</span>
          <span v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.jobpAddr)" class="fs16rem fwb ml20 fex-clumn">
            <span class="fexTy2">
              <mo-icon icon="msp-location" class="mt2" v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.jobpAddr)" @click="fn_ChkMobileType('job')"/>
              <span class="ml4" @click="fn_ChkMobileType('job')">
                {{ lv_RsltBasSVO.jobpFrnoPstcd }}
                {{ lv_RsltBasSVO.jobpAddr }}
                {{ lv_RsltBasSVO.bldgNo02 }}
                {{ lv_RsltBasSVO.jobpDtlad }}
                {{ lv_RsltBasSVO.adtnAddr02 }}
              </span>
            </span>
            <span class="mt4">
              <!-- 정상: class="lightgreen2", 반송/오류: class="lightorange2" -->
              <mo-badge class="badge-box sm" shape="status" v-if="!$bizUtil.isEmpty(fn_CmptdJobAddrStatNm)"
                        :class="lv_RsltBasSVO.jobpAddrStatCd === '11' ? 'lightgreen2' : 'lightorange2'">
                {{ fn_CmptdJobAddrStatNm }}
              </mo-badge>

              <mo-badge v-if="fn_CmptdMailRcpplScCd === '12'" class="badge-box sm lightgray2 ml10" shape="status">우편수령지</mo-badge>
            </span>
          </span>
        </div>
        <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
          <span class="fs16rem min90">직장전화</span>
          <span class="fs16rem fwb ml20">{{ lv_RsltBasSVO.jobpTelno }}</span>
        </div>
      </ur-box-container>

      <!-- 외국인 정보 영역 -->
      <ur-box-container v-if="lv_RsltBasSVO.knbScCd === 'ZPER02' && lv_RsltBasSVO.chnlCustScCd !== '02'" alignV="center" componentid="" direction="column" class="info-box mt50">
        <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
          <b class="info-title fs18rem">외국인정보</b>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="pa1620 bdTy10">
          <div class="full fexTy5">
            <span class="min90 crTy-bk7">영문명</span>
            <span class="ml20 fs16rem">{{ lv_RsltBasSVO.custEngNm }}</span>
          </div>
          <div class="full fexTy5">
            <span class="min90 crTy-bk7">국적</span>
            <span class="ml20 fs16rem">{{ lv_CustNatyNm }}</span>
          </div>
          <div class="full fexTy5">
            <span class="min90 crTy-bk7">거주구분</span>
            <span class="ml20 fs16rem" v-if="$bizUtil.isEmpty(lv_RsltBasSVO.natyCd)"/>
            <span class="ml20 fs16rem" v-else>
              {{ lv_RsltBasSVO.dmclScCd === '01' ? '국내' : '국외' }}
            </span>
          </div> 
          <div class="full fexTy3">
            <div>
              <span class="min90 crTy-bk7" style="display: inline-block;">국적조회여부</span>
              <span class="ml20 fs16rem">{{ lv_RsltBasSVO.natioInQrYn }}</span>
            </div>
            <mo-button class="ns-btn-round white sm" :disabled="lv_RsltBasSVO.natioInQrYn !== 'Y' || lv_RsltBasSVO.mndtCnstgCnsntYn !== 'Y' || !fn_CmptdChkCnslt" @click="$emit('edit')">
              국적조회
            </mo-button>
          </div> 
        </ur-box-container>
        <ur-box-container alignV="start" direction="column" class="info-title-wrap crTy-blue2" style="background-color: unset;">
          <div>* 국적조회여부Y & 필컨Y 고객중 국적이 변경된 경우 조회 하세요</div>
          <div>* 국적조회여부N인 경우 필수컨설팅동의를 진행바랍니다</div>
        </ur-box-container>
      </ur-box-container>

      <!-- 취득경로 영역 -->
      <ur-box-container alignV="center" componentid="" direction="column" class="info-box mt50">
        <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
          <b class="info-title fs18rem">취득경로</b>
        </ur-box-container>
        <ur-box-container v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.custNm01)" alignV="start" componentid="" direction="column" class="pa1620 bdTy10">
          <div class="full fexTy5">
            <span class="min60 crTy-bk7">경로</span>
            <span class="ml20 fs16rem">{{ fn_CmptdIntroPath ? fn_CmptdIntroPath.label : '' }}</span>
          </div>
          <div class="full fexTy5">
            <span class="min60 crTy-bk7">소개자</span>
            <span class="ml20 fs16rem">{{ lv_RsltBasSVO.custNm01 }}</span>
          </div>
          <div class="full fexTy5">
            <span class="min60 crTy-bk7">소개일</span>
            <span class="ml20 fs16rem">{{ $bizUtil.dateDashFormat(lv_RsltBasSVO.introYmd) }}</span>
          </div>
        </ur-box-container>
        <!-- 소개자 Data 없을경우 -->
        <ur-box-container v-else alignV="start" componentid="" direction="column" class="pa1620 bdTy10">
          <div class="full fexTy5">
            <span class="min60 crTy-bk7">경로</span>
            <span class="ml20 fs16rem">{{ fn_CmptdIntroPath ? fn_CmptdIntroPath.label : '' }}</span>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <!-- ios 지도 네비게이션 -->
    <div class="ns-bottom-sheet ns-style3">
      <mo-bottom-sheet ref="bottomSheetMap" @before-close="onBeforeClose">
        <template v-slot:title>연결 프로그램</template>
        <div class="map-box">
          <span :class="slctMap === '1' ? 'on' : ''" @click="fn_ChkIosMap('1')">네이버지도</span>
          <span :class="slctMap === '2' ? 'on' : ''" @click="fn_ChkIosMap('2')">카카오내비</span>
          <span :class="slctMap === '3' ? 'on' : ''" @click="fn_ChkIosMap('3')">T맵</span>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancleMap">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isMapChk" @click="fn_MoveMap(slctMap)">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </div>

  </ur-box-container>
</template>

<script>
import cmConstants from '~/src/config/constants/cmConstants';

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM271D', 
  // 현재 화면 ID
  screenId: 'MSPCM271D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '기본정보',
      lv_UserInfo: {}, // 사용자 정보

      firstMapTouchHms : '', // 처음 지도아이콘 클릭 시각

      isMapChk: true, // 지도 확인버튼 disabled 여부
      addrType: '',   // 선택 주소 타입(home: 자택, job: 직장)
      slctMap: '',    // IOS 지도 선택값

      // 고객상세인적사항 정보SVO
      lv_RsltBasSVO: {},

      natyCdList: [], // 국가코드 리스트
      lv_CustNatyNm: '' // 국가명
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
		this.$emit("affordanceCntrol")
        this.fn_Init()
      }
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
    // 실제생일 같은지 체크
    fn_CmptdSameRealBirth() {
      let rtnBoolean =  false

      let birth = this.lv_RsltBasSVO.knb
      let realBirth = this.lv_RsltBasSVO.ralDob

      if (!this.$bizUtil.isEmpty(birth)) {
        // console.log('fn_CmptdSameRealBirth>>>>', this.$cmUtil.getFullBirthDate(knb).substring(0,8))
        birth = this.$cmUtil.getFullBirthDate(birth).substring(0,8)
      }

      if (birth === realBirth || realBirth === '00000000') {
        rtnBoolean = false
      } else {    
        rtnBoolean = true
      }

      return rtnBoolean
    },
    // 주고객명
    fn_CmptdMnCustNm() {
      let hoshList = this.lv_RsltBasSVO.iCHoshInfoListInqrVO

      if (this.lv_RsltBasSVO.mnCustRltnCd === '01') return this.lv_RsltBasSVO.custNm
      if (_.isEmpty(hoshList)) return ''

      let mnCustOjb = hoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        })[0];

      return mnCustOjb ? mnCustOjb.custNm : ''
    },
    // 주고객관계
    fn_CmptdCustRltnNm() {
      let custRltnObj = cmConstants.CUST_RLTN_CD.filter(item => {
          return item.key === this.lv_RsltBasSVO.mnCustRltnCd
        })[0];
      
      return custRltnObj ? custRltnObj.label : ''
    },
    // 휴대폰번호 상태
    fn_CmptdCelStatNm() {
      let rtnValu = ''

      if (this.lv_RsltBasSVO.chnlCustScCd !== '01' && this.lv_RsltBasSVO.chnlCustScCd !== '02') {
        if (this.lv_RsltBasSVO.celnoStatCo === '21') {
          rtnValu = '정상'
        } else if (this.lv_RsltBasSVO.celnoStatCo === '22') {
          rtnValu = '오류'
        } else if (this.lv_RsltBasSVO.celnoStatCo === '23') {
          rtnValu = '발송실패'
        } else if (this.lv_RsltBasSVO.celnoStatCo === '24') {
          rtnValu = '중복'
        }
      }
      
      return rtnValu
    },
    // 이메일 상태
    fn_CmptdEmailStatNm() {
      let rtnValu = ''

      if (this.lv_RsltBasSVO.chnlCustScCd !== '01' && this.lv_RsltBasSVO.chnlCustScCd !== '02') {
        if (this.lv_RsltBasSVO.emailStatCd === '31') {
          rtnValu = '정상'
        } else if (this.lv_RsltBasSVO.emailStatCd === '32') {
          rtnValu = '오류'
          } else if (this.lv_RsltBasSVO.emailStatCd === '33') {
          rtnValu = '발송실패'
          } else if (this.lv_RsltBasSVO.emailStatCd === '34') {
          rtnValu = '중복'
          }
      }

      return rtnValu
    },
    // 자택주소 상태 (정상/오류/반송)
    fn_CmptdHomAddrStatNm() {
      let rtnValu = ''

      if (this.lv_RsltBasSVO.chnlCustScCd !== '01' && this.lv_RsltBasSVO.chnlCustScCd !== '02') {
        if (this.lv_RsltBasSVO.homAddrStatCd === '11') {
          rtnValu = '정상'
        } else if (this.lv_RsltBasSVO.homAddrStatCd === '12') {
          rtnValu = '오류'
        } else if (this.lv_RsltBasSVO.homAddrStatCd === '13') {
          rtnValu = '반송'
        }
      }
      
      return rtnValu
    },
    // 직장주소 상태(정상/오류/반송)
    fn_CmptdJobAddrStatNm() {
      let rtnValu = ''

      if (this.lv_RsltBasSVO.chnlCustScCd !== '01' && this.lv_RsltBasSVO.chnlCustScCd !== '02') {
        if (this.lv_RsltBasSVO.jobpAddrStatCd === '11') {
          rtnValu = '정상'
        } else if (this.lv_RsltBasSVO.jobpAddrStatCd === '12') {
          rtnValu = '오류'
        } else if (this.lv_RsltBasSVO.jobpAddrStatCd === '13') {
          rtnValu = '반송'
        }
      }
      
      return rtnValu
    },
    // 우편수령지
    fn_CmptdMailRcpplScCd() {
      // lv_RsltBasSVO.mailRcpplScCd - 우편수령지 코드
      // lv_RsltBasSVO.mailRcpplScCdNm - 우편수령지 명

      let mailRcpplObj = cmConstants.ADDR_TYPE_CD.filter(item => {
          return item.key === this.lv_RsltBasSVO.mailRcpplScCd
        })[0];

      return mailRcpplObj ? mailRcpplObj.key : ''
    },
    // 취득경로
    fn_CmptdIntroPath() {
      let _introPathItems = cmConstants.ENTRY_PATH_CD // 취득경로 코드
      let _introPathValue = {}

      if(_.isEmpty(this.lv_RsltBasSVO)) return

      let _introYmd = this.lv_RsltBasSVO.introYmd // 소개일
      let _acutnCustYn = this.lv_RsltBasSVO.acutnCustYn // 취득경로 '지인'여부
      let _aptCustYn = this.lv_RsltBasSVO.aptCustYn // 취득경로 '개척'여부
      let _rspnDstrtCustYn = this.lv_RsltBasSVO.rspnDstrtCustYn // 취득경로 '기타'여부
      let _coalCustYn = this.lv_RsltBasSVO.coalCustYn // '제휴고객' 여부

      if (_acutnCustYn === 'Y') { // 지인
        _introPathValue = _introPathItems.filter(item => {
            return item.key === 'acutnCustYn'
          })[0];
      } else if (_aptCustYn === 'Y') { // 개척
        _introPathValue = _introPathItems.filter(item => {
            return item.key === 'aptCustYn'
          })[0];
      } else if (_rspnDstrtCustYn === 'Y') { // 기타
        _introPathValue = _introPathItems.filter(item => {
            return item.key === 'rspnDstrtCustYn'
          })[0];
      } else if (_coalCustYn === 'Y') { // 제휴
         // 예전 데이터중 존재(현재 없는 취득경로) 고객카드 취득경로 수정 안됨 2023.08.02
        _introPathValue = {key: 'partnership', label: '제휴'}
      } else {
        // ------------------------------------------------------
        // EP 데이터 중 소개일이 더미값('000000')으로 조회되기 때문에 취득경로 유효성 체크 추가함
        // ------------------------------------------------------
        if (!this.$bizUtil.isEmpty(_introYmd) && _introYmd !== '00000000') { // 소개
          _introPathValue = _introPathItems.filter(item => {
              return item.key === 'intdr'
            })[0];
        }
      }

      return _introPathValue
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM271D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM271D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    fn_Init () {
      const lv_Vm = this

      // 국적조회 공통코드 세팅
      this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD')
      .then(() => {
        lv_Vm.natyCdList = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')

        let natyObj = lv_Vm.natyCdList.filter(item => {
            return item.key === lv_Vm.lv_RsltBasSVO.natyCd
          })
        
        if (!_.isEmpty(natyObj)) {
          lv_Vm.lv_RsltBasSVO.natyNm = natyObj[0].label
          lv_Vm.lv_RsltBasSVO.bpNatyNm = natyObj[0].label
          lv_Vm.lv_CustNatyNm = natyObj[0].label
        } else {
          lv_Vm.lv_RsltBasSVO.natyNm = ''
          lv_Vm.lv_CustNatyNm = ''
        }
      })

      this.lv_RsltBasSVO.psprtNoMask = ''
      if (!this.$bizUtil.isEmpty(this.lv_RsltBasSVO.psprtNo)) {
        let psprtNoMask = '*****' + this.lv_RsltBasSVO.psprtNo.substr(-5)
        this.lv_RsltBasSVO.psprtNoMask = psprtNoMask
      }
      
    },
    /*********************************************************
     * @function  fn_MoveMSPCM050M
     * @name      화면전환
     * @notice    고객등록동의 진입(MSPCM050M) 화면 전환
    *********************************************************/
    fn_MoveMSPCM050M () {
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      
      let pParams = {
        srnId: 'MSPCM270M',
        custNm: this.lv_RsltBasSVO.custNm
      }
      this.$router.push({ name: 'MSPCM050M', params: pParams })
    },
    /*********************************************************
     * @function  fn_ChkMobileType
     * @notice    OS 종류에 따른 지도맵 연동 구분
    *********************************************************/
    fn_ChkMobileType (type) {
      // type: 자택(home)/직장(job)
      this.addrType = type
      // Mobile OS 체크
      if (this.$commonUtil.isMobile()) {
        if (this.$commonUtil.getIsIOS()) {
          // IOS
          this.$refs.bottomSheetMap.open()
        } else {
          // Android
          this.fn_MoveMap() // 지도연동
        }
      }
    },
    /*********************************************************
     * @function  fn_MoveMap
     * @notice    지도 연동
    *********************************************************/
    fn_MoveMap () {
      let addr = ''
      let addrScCd = ''

      // 주소(도로명) + 번지
      if (this.addrType === 'home') {
        /*
        addr = this.lv_RsltBasSVO.homAddr
        if (this.lv_RsltBasSVO.homDtlad === ' ') {
          addr = addr + this.lv_RsltBasSVO.bldgNo01 
        } else {
          addr = addr + ' ' + this.lv_RsltBasSVO.homDtlad + ' ' + this.lv_RsltBasSVO.adtnAddr01 
        }
        */
        addrScCd = this.lv_RsltBasSVO.homAddrScCd
        // addr = this.lv_RsltBasSVO.homAddr + ' ' + this.lv_RsltBasSVO.bldgNo01 + ' ' + this.lv_RsltBasSVO.homDtlad + ' ' + this.lv_RsltBasSVO.adtnAddr01

        if (this.lv_RsltBasSVO.homAddrScCd === '2') { // 집 도로명 주소
          addr = this.lv_RsltBasSVO.homAddr + ' ' + this.lv_RsltBasSVO.bldgNo01  //집 도로명 주소
          addr = addr + ' ' + this.lv_RsltBasSVO.homDtlad + ' ' + this.lv_RsltBasSVO.adtnAddr01 //집 도로명 상세주소
        } else { //집 구주소
          addr =  this.lv_RsltBasSVO.homAddr
          addr = addr + ' ' + this.lv_RsltBasSVO.homDtlad + ' ' + this.lv_RsltBasSVO.bldgNo01
        }        
      } else {
        /*
        addr = this.lv_RsltBasSVO.jobpAddr
        if (this.lv_RsltBasSVO.bldgNo02 === ' ') {
          addr = addr + ' ' + this.lv_RsltBasSVO.jobpDtlad
        } else {
          addr = addr + ' ' + this.lv_RsltBasSVO.bldgNo02 + ' ' + this.lv_RsltBasSVO.jobpDtlad
        }
        */
        addrScCd = this.lv_RsltBasSVO.jobpAddrScCd
        // addr = this.lv_RsltBasSVO.jobpAddr + ' ' + this.lv_RsltBasSVO.bldgNo02 + ' ' + this.lv_RsltBasSVO.jobpDtlad
        if (this.lv_RsltBasSVO.jobpAddrScCd === '2') { // 도로명 주소
          addr = this.lv_RsltBasSVO.jobpAddr + ' ' + this.lv_RsltBasSVO.bldgNo02
          addr = addr + ' ' + this.lv_RsltBasSVO.jobpDtlad + ' ' + this.lv_RsltBasSVO.adtnAddr02
        } else {
          addr = this.lv_RsltBasSVO.jobpAddr
          addr = addr + ' ' + this.lv_RsltBasSVO.jobpDtlad + ' ' + this.lv_RsltBasSVO.bldgNo02
        }        
      }

      if (addrScCd === '3') {
        this.getStore('confirm').dispatch('ADD', '지도 안내가 불가능한 주소입니다.')
      } else {
        // 여러번 누르기 방지
        let lv_Vm = this

        let lastMapTouchHms = new Date() // 최종터치시간
        if (lv_Vm.firstMapTouchHms === '') {
          // 최초 선택 시 지도맵 연동
          lv_Vm.firstMapTouchHms = new Date()
          // type: this.slctMap => IOS일경우, 네이티브에서 선택한 어플리케이션으로 연결
          window.fdpbridge.exec('launchMapPlugin', {address: addr, type: this.slctMap}, () => {}, () => {})

          setTimeout(function() {
            lv_Vm.firstMapTouchHms = '' // 초기화
          }, 3000)
        } else {
          // 여러번 누를 시 리턴
          if(lastMapTouchHms - lv_Vm.firstMapTouchHms < 3000){
            return
          }
        }
      }
    },
    /*********************************************************
     * @function  fn_ChkIosMap
     * @notice    IOS 지도 선택
     * @param {String}  네이버지도('1') / 카카오지도('2') / 티맵('3')
    *********************************************************/
    fn_ChkIosMap (map) {
      this.isMapChk = false // 확인버튼 disabled 해제
      this.slctMap = map
    },
    /*********************************************************
     * @function  fn_CancleMap
     * @notice    IOS 지도 선택 취소버튼
    *********************************************************/
    fn_CancleMap () {
      this.slctMap = '' // 초기화
      this.isMapChk = true // 확인버튼 disabled 초기화
      this.$refs.bottomSheetMap.close()
    },
    /*********************************************************
     * @function  onBeforeClose
     * @notice    지도 선택 외부Dimm영역 선택 시 체크값 초기화 후에 바텀시트 닫음
    *********************************************************/
    onBeforeClose (fn) {
      this.slctMap = '' // 초기화
      this.isMapChk = true // 확인버튼 disabled 초기화

      fn() // 닫기 실행
    },
    
  } // ::Methods End
};
</script>
