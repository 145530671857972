/*
* 업무구분: 업무공통
* 화면 명: MSPBC004M
* 화면 설명: App 설정
* 화면 접근권한: 모든 사용자
*/
<template>
  <ur-page-container :show-title="true" class="msp" title="설정" @on-header-left-click="fn_BackBtnClicked">
    <ur-box-container direction="column" alignV="start" class="setting-area">
      <!-- <div class="setting-box">
        <div class="setting-title">
          <span>알림기능</span>
           <mo-switch v-model="lv_SmpIapReciYn" class="ns-switch" /> 
        </div>
        <span class="setting-txt">다음 정보에 대해 휴대폰 Push알림을 받습니다.</span>
        <div class="ns-check" @click="fn_ShowSnackbar">
          <mo-checkbox disabled v-model="lv_SmpCustCntcInfo">고객접촉정보</mo-checkbox>
          <mo-checkbox disabled v-model="lv_SmpEvent">이벤트고객</mo-checkbox>
          <mo-checkbox disabled v-model="lv_SmpMyNotif">공지사항</mo-checkbox>
          <mo-checkbox disabled v-model="lv_SmpMySchdl">내 일정</mo-checkbox>
        </div>
      </div> -->
      <div class="setting-box">
        <div class="setting-title">
          <span>글자 크기</span>
        </div>
        <span class="setting-txt">글자를 원하는 크기로 설정할 수 있습니다. (주요 영역에 한함)<br>
          <span class="setting-txt-red" v-if="lv_SmpMiddle === true">* 화면 내 글자 줄바꿈이 생길 수 있습니다.</span>
          <span class="setting-txt-red" v-if="lv_SmpLarge === true">* 화면 내 배열 깨짐 현상이 생길 수 있습니다.</span>
        </span>
        
        <!-- <div class="setting-font-size" :class="[{'ns-font-small': lv_SmpSmall},
            {'ns-font-middle': lv_SmpMiddle}, {'ns-font-large': lv_SmpLarge}]">
          <strong class="title">타이틀</strong>
          <span class="text">글자가 이렇게 보입니다.</span>
        </div> -->
        <div class="setting-bar">
          <!-- class="on" 처리 -->
          <div class="bar-box">
            <img class="bar-img" src="/images/font_size_bar.svg" alt="bar 이미지">
            <span style="left:0%" :class="{'on' : lv_SmpSmall === true }" @click="fn_FontSet('lv_SmpSmall')">스위치</span>
            <span style="left:45%" :class="{'on' : lv_SmpMiddle === true}" @click="fn_FontSet('lv_SmpMiddle')">스위치</span>
            <span style="left:89%" :class="{'on' : lv_SmpLarge === true}" @click="fn_FontSet('lv_SmpLarge')">스위치</span> 
          </div>
        </div>
        <!-- <div class="setting-txt-red mt10" v-if="lv_SmpLarge === true">※ 최대로 설정 시, 화면 내 배열이 깨져보일 수 있습니다.</div> -->
      </div>
      <div v-show="lv_rslt === false" class="setting-box">
        <div class="setting-title">
          <span>화면 크기</span>
        </div>
        <span class="setting-txt">화면 전체를 원하는 크기로 설정 할 수 있습니다.<br>
          <span class="setting-txt-red" v-if="lv_SrnLarge === true">* 크게 설정시, 가로모드 사용에 제약이 있습니다.</span>
        </span>
        
        <!-- <div class="setting-font-size" :class="[{'ns-font-small': lv_SmpSmall},
            {'ns-font-middle': lv_SmpMiddle}, {'ns-font-large': lv_SmpLarge}]">
          <strong class="title">타이틀</strong>
          <span class="text">글자가 이렇게 보입니다.</span>
        </div> -->
        <div class="setting-bar">
          <!-- class="on" 처리 -->
          <div class="bar-box">
            <img class="bar-img" src="/images/screen_size_bar.svg" alt="bar 이미지">
            <span style="left:0%" :class="{'on' : lv_SrnSmall === true }" @click="fn_SrnSet('1')">스위치</span>
            <span style="left:89%" :class="{'on' : lv_SrnLarge === true}" @click="fn_SrnSet('2')">스위치</span> 
          </div>
        </div>
        <!-- <div class="setting-txt-red mt10" v-if="lv_SmpLarge === true">※ 최대로 설정 시, 화면 내 배열이 깨져보일 수 있습니다.</div> -->
      </div>
      <div class="setting-box">
        <div class="setting-title">
          <span>알림유지기간</span>
        </div>
        <span class="setting-txt">기간이 지난 알림은 알림목록에서 사라집니다.<br>(시스템 공지 제외)</span>
        <div class="ns-dropdown-sheet">
          <msp-bottom-select :items="lv_SmpPeridItems" v-model="lv_SmpPeridValue" underline />
        </div>
      </div>
      <div class="setting-box">
        <div class="setting-title">
          <span>방해금지 시간대 설정</span>
          <mo-switch v-model="lv_SmpSchdlTmIapYn" class="ns-switch" />
        </div>
        <span class="setting-txt">설정한 시간대에 푸시 알림을 수신하지 않습니다.<br>* 야간시간대(오후 6시 ~ 오전8시)에 설정 가능</span>
        <div class="ns-time-picker multi" v-show="lv_SmpSchdlTmIapYn">
          <mo-time-picker v-model="lv_SmpIapIntrsStrTmArr" underline />
          <span>~</span>
          <mo-time-picker v-model="lv_SmpIapIntrsEndTmArr" underline />
        </div>
      </div>
      
      <!-- 2024.10. 전화받기 서비스 -->
      <div class="setting-box" v-if="osType">
        <div class="setting-title">
          <span>전화받기 서비스 설정
            <mo-icon id="location0" icon="msp-tool-tip" @click="fn_OpenTutoPopUp()" v-if="dateChk >= '202501220830'"></mo-icon>
            <!-- <mo-icon id="location0" icon="msp-tool-tip" @click="fn_OpenTutoPopUp()"></mo-icon> -->
          </span>
          <mo-switch v-model="lv_telReciSrvcYn" class="ns-switch" />
        </div>
        <span class="setting-txt">전화수신 시 고객정보를 확인하고<br>고객카드로 이동할 수 있습니다.</span>
      </div>
      <!-- 2023.11.10 ASR231000906 start -->
      <div class="setting-box" v-show="lv_rslt === false" >
        <div class="setting-title">
          <span>사내망 WiFi 비밀번호 초기화</span>
        </div>
        <span class="setting-txt">태블릿을 교체하셨거나, 패스워드를 잊어버렸을 경우 사용하는 기능입니다. 초기화 이후, 사내접속 패스워드는 현재 사랑On패스워드로 변경됩니다.</span>
        <!-- <div class="ns-dropdown-sheet">
          <mo-button componentid="" color="primary" shape="border" size="medium" @click="fn_initPass()" class="ns-btn-round blue">사내망 비밀번호 초기화</mo-button> -->
        <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-info-list2">
          <div class="txt-center w100">
            <mo-button color="primary" shape="border" size="medium"  @click="fn_initPass()" class="ns-btn-round blue wauto pr20 pl20">사내망 비밀번호 초기화</mo-button>
          </div>
        </ur-box-container>
      </div>
      <!-- 2023.11.10 ASR231000906 end -->

      <span class="setting-bottom-txt">
        시스템 정보<br>
        버전{{lv_AppData.versionName}} ( {{lv_AppData.lastUpdateDT}} 현재 최신버전 입니다. )
        <div style="text-decoration: underline">
            <p v-if="getSDK === '12' || getSDK == '13' || getSDK == '14'" @click="fn_DownloadApk()">삼성전자 보안정책 앱 설치링크</p>
        </div>
      </span>

      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
    </ur-box-container>
    <div v-show="lv_rslt === false" >
      <mo-bottom-sheet ref="bottomSheetPass" :close-btn="true"  class="ns-bottom-sheet">
        <template v-slot:title>사내망 비밀번호가 초기화 되었습니다.</template>
        <div class="customer-info-txt txt-center">
          <p class="ment txt-left">안내자료의 ‘사내망 비밀번호 초기화 가이드’를 참고해주시고, 수동접속이 어려울 시 전산담당 또는 IT서비스데스크(02-3114-500)에서 안내 받으시기 바랍니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" @click="fn_setTablet()" class="ns-btn-round white">태블릿 설정 바로가기</mo-button>
              <mo-button componentid="" color="primary" shape="border" size="medium" @click="fn_preViewPDF()" class="ns-btn-round blue">안내자료 열기</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </div>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
  import TrnsJsonCretUtil from '../bu/TrnsJsonCretUtil'
  import MSPBC661P from '@/ui/bc/MSPBC661P.vue'
  import MSPBC662P from '@/ui/bc/MSPBC662P.vue'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC004M",
    screenId: "MSPBC004M",
    components: {
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_ConfigProcss()

      this.fn_LoadingData()

      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

      this[this.$bcUtil.getFontFlag()] = true
      if (localStorage.getItem('srn_usr_setting') === 'Y') {
          this.lv_SrnLarge = true
          this.lv_SrnSmall = false
      } else if (localStorage.getItem('srn_usr_setting') === 'N') {
          this.lv_SrnLarge = false
          this.lv_SrnSmall = true
      }
      this.lv_mobileYn = this.$commonUtil.checkApp()
      if (this.lv_mobileYn) {
        this.fn_PocDeviceEnv()
      } else {
        this.lv_rslt = false
      } 
      console.log('innerWidth = ' + window.innerWidth)    

      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPBC004M')
      this.$BottomManager.fn_SetBottomVisible(false)
      this.fn_GetVersion()
    },
    beforeDestroy () {
      // this.$parent.$parent.$parent.$parent.$refs.drawer.open()
      // this.$emit('menu')
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    destroyed() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        DEBUG: true,
        lv_SysOptInfo: {}, // 시스템 VO
        lv_UserInfo: '', // 사용자정보
        lv_SmpIapReciYn: true, // 알림기능사용
        lv_SmpSchdlTmIapYn: false, // 방해금지시간대 설정여부
        lv_telReciSrvcYn: false, // 전화받기 서비스 설정
        lv_SmpCustCntcInfo: true, // 고객접촉정보
        lv_SmpMySchdl: true, // 내일정
        lv_SmpEvent: true, // 이벤트
        lv_SmpMyNotif: true, // 공지사항
        lv_SmpSmall: false, // 폰트사이즈 default (12)
        lv_SmpMiddle: false, // 폰트사이즈  (16)
        lv_SmpLarge: false, // 폰트사이즈 (20)
        lv_SrnSmall: false, // 화면사이즈
        lv_SrnLarge: true, // 화면사이즈
        lv_rslt: true,
        lv_mobileYn: false,
        positionInitUp: false,  // 하단버튼 바텀시트 오픈여부 2023.11.10
        lv_AppData: {
          lastUpdateDT: '2018-10-01',
          versionName: '1.0.1'
        },
        lv_AlertPop: {},
        lv_SmpIapIntrsStrTmArr: '오후 08:00',
        lv_SmpIapIntrsEndTmArr: '오전 08:00',
        lv_SmpPeridItems: [ // 알림유지시간 Select
            {
              value: '3',
              text: '3일'
            },
            {
              value: '7',
              text: '7일'
            },
            {
              value: '30',
              text: '30일'
            }
          ],
        lv_SmpPeridValue: '',
        toastMsg: 'Push알림을 받으시려면 "알림기능 사용" 설정을 켜주세요',
        
        osType   : true,
        getSDK   : '',
        firstChk : '',

        config   : '',
        dateChk  : moment(new Date()).format('YYYYMMDDHHmm')
      };
    },

    watch: {
      // lv_SmpIapReciYn(status) { // 알림 토글값이 변경될때 마다 상태값을 저장한다.
      //   this.fn_OnAllAlarmSet('SP_ALARM_SET', Boolean(status))
      //   // 알림기능이 false 이면 모든 알림을 않받겠다는것이므로 방해금지시간대로 false 및 disable 시킨다.
      //   if (this.lv_SmpIapReciYn === false) {
      //     this.lv_SmpSchdlTmIapYn = false 
      //     this.lv_SmpCustCntcInfo = false
      //     this.lv_SmpMySchdl = false
      //     this.lv_SmpEvent = false
      //     this.lv_SmpMyNotif = false // 고객접촉정보+내일정+이벤트 모두 unchecked 한다.
      //   } else {
      //     // 알림 toggle1 이 true 일때 아래쪽 check box 3개 모두 true 설정한다.
      //     this.lv_SmpSchdlTmIapYn = true
      //     this.lv_SmpCustCntcInfo = true
      //     this.lv_SmpMySchdl = true
      //     this.lv_SmpEvent = true
      //     this.lv_SmpMyNotif = true // 고객접촉정보+내일정+이벤트 모두 checked 한다.
      //   }
        
      //   // 알림기능 토글 값이 변경될때도 sub 메뉴들의 상태값도 안드로이드 네이티브로 보낸다.
      //   this.fn_OnAllAlarmSet('SP_MYCUSTOM_SET', Boolean(this.lv_SmpCustCntcInfo))
      //   this.fn_OnAllAlarmSet('SP_MYSCADULE_SET', Boolean(this.lv_SmpMySchdl))
      //   this.fn_OnAllAlarmSet('SP_MYEVENT_SET', Boolean(this.lv_SmpEvent))
      //   this.fn_OnAllAlarmSet('SP_MYNOTIF_SET', Boolean(this.lv_SmpMyNotif))
      //   // Parameter변경 시 DB 저장
      //   this.fn_SaveSysInfo()
      // },
      // lv_SmpCustCntcInfo(status) { // 알림기능 사용에서 고객접촉정보 설정
      //   this.fn_OnAllAlarmSet('SP_MYCUSTOM_SET', Boolean(status))
      //   // Parameter변경 시 DB 저장
      //   this.fn_SaveSysInfo()
      // },
      // lv_SmpMySchdl(status) { // 알림기능 사용에서 내일정 설정
      //   this.fn_OnAllAlarmSet('SP_MYSCADULE_SET', Boolean(status))
      //   // Parameter변경 시 DB 저장
      //   this.fn_SaveSysInfo()
      // },
      // lv_SmpEvent(status) { // 알림기능 사용에서 이벤트 설정
      //   this.fn_OnAllAlarmSet('SP_MYEVENT_SET', Boolean(status))
      //   // Parameter변경 시 DB 저장
      //   this.fn_SaveSysInfo()
      // },
      // lv_SmpMyNotif(status) {
      //   this.fn_OnAllAlarmSet('SP_MYNOTIF_SET', Boolean(status))
      //   // Parameter변경 시 DB 저장
      //   this.fn_SaveSysInfo()
      // },
      lv_SmpSchdlTmIapYn() { // 방해금지 토글값이 변경될때 마다 상태값을 저장한다.
        this.fn_SaveSysInfo()
      },
      lv_SmpIapIntrsStrTmArr() {
        this.fn_SaveSysInfo()
      },
      lv_SmpIapIntrsEndTmArr() {
        this.fn_SaveSysInfo()
      },
      lv_SmpPeridValue(newVal, oldVal) {
        if (newVal !== oldVal) {
        // Parameter변경 시 DB 저장
        this.fn_SaveSysInfo()
        }
      },
      lv_telReciSrvcYn() {
        // 2024.10.11 전화받기 서비스 추가
        let lv_vm = this
        if(this.config !== 'Y') {
          if(this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P001') ||
          this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P002')) {
            if(!this.firstChk) {
              if(lv_vm.lv_telReciSrvcYn) {
                lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn = 'Y'
                window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
                  localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
                  // 성공콜백
                  lv_vm.fn_SaveSysInfo()
                  console.log("callCustInfoStartPlugin success ", result)
                },(error) => {
                  localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
                  console.log('callCustInfoStartPlugin error', error)
                  lv_vm.lv_telReciSrvcYn = false
                })
              } else {
                lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn = 'N'
                localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
                window.fdpbridge.exec('callCustInfoStopPlugin', {}, function (result) {
                  // 성공콜백
                  console.log("callCustInfoStopPlugin success", result)
                },(error) =>{
                  console.log('callCustInfoStopPlugin error', error)
                })
                lv_vm.fn_SaveSysInfo()
              }
            } else {
              lv_vm.firstChk = false
            }
          }
        } else {
          if(!this.firstChk) {
            if(lv_vm.lv_telReciSrvcYn) {
              lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn = 'Y'
              window.fdpbridge.exec('callCustInfoStartPlugin', {}, function (result) {
                localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
                // 성공콜백
                lv_vm.fn_SaveSysInfo()
                console.log("callCustInfoStartPlugin success ", result)
              },(error) => {
                localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
                console.log('callCustInfoStartPlugin error', error)
                lv_vm.lv_telReciSrvcYn = false
              })
            } else {
              lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn = 'N'
              localStorage.setItem('TEL_SRVC_YN',lv_vm.lv_SysOptInfo.mobslTelReciSrvcYn)
              window.fdpbridge.exec('callCustInfoStopPlugin', {}, function (result) {
                // 성공콜백
                console.log("callCustInfoStopPlugin success", result)
              },(error) =>{
                console.log('callCustInfoStopPlugin error', error)
              })
              lv_vm.fn_SaveSysInfo()
            }
          } else {
            lv_vm.firstChk = false
          }
        } 
      } 
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /******************************************************************************
     * Function명  : fn_PocDeviceEnv
    * 설명        : 앱 사용 디바이스 환경 Tablet / Phone / Other
    ******************************************************************************/
      async fn_PocDeviceEnv () {
        let rslt = await PSCommUtil.fn_GetAppDeviceEnv()
        console.log('fn_PocDeviceEnv rslt = ['+rslt+']')
        let lv_Vm = this
        if (rslt === 'Phone' || localStorage.getItem('osType') === 'ios') {
          lv_Vm.lv_rslt = true
        } else {
          lv_Vm.lv_rslt = false
        }
        console.log('fn_PocDeviceEnv lv_rslt = ['+lv_Vm.lv_rslt+']')
 
        return rslt
      },   
    /*********************************************************
     * Function명: fn_SetSrnSize
     * 설명: 화면크기를 셋팅
     *********************************************************/
    fn_SetSrnSize () {
      let lv_Vm = this
      
      console.log('fn_SetSrnSize lv_rslt = ['+lv_Vm.lv_rslt+']')
      console.log('fn_SetSrnSize srn_usr_setting = ['+localStorage.getItem('srn_usr_setting')+']')

      if (lv_Vm.lv_rslt === false) {
        if (localStorage.getItem('srn_usr_setting') === 'Y') {     
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
        } else if (localStorage.getItem('srn_usr_setting') === 'N') {
          document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
        } else {     
          document.getElementById('page-viewport').content = 'width=600, user-scalable=yes'
          //document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
        }
      } else {
        document.getElementById('page-viewport').content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
      }
    },         
      /******************************************************************************
       * Function명 : fn_LoadingData
       * 설명       : 초기데이터 조회
       * 인자       : ?
       * Return     : N/A
       ******************************************************************************/
      fn_LoadingData () {
        let lv_Vm = this
        let pParams = {
          userEno: this.getStore('userInfo').getters.getUserInfo.userId
        }
        const trnstId = 'txTSSBC20S1'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function(response) {
            if (response.body.sysInfo) {
              lv_Vm.lv_SysOptInfo = response.body.sysInfo
              lv_Vm.lv_SysOptInfo.smpIapReciYn = response.body.sysInfo.smpIapReciYn.trim().length !== 0 ? response.body.sysInfo.smpIapReciYn : 'Y' // 알림기능 사용여부
              lv_Vm.lv_SysOptInfo.smpIapUkepDds = response.body.sysInfo.smpIapUkepDds !== '0' ? response.body.sysInfo.smpIapUkepDds : '3' // 알림유지기간
              lv_Vm.lv_SysOptInfo.smpSchdlTmIapYn = response.body.sysInfo.smpSchdlTmIapYn.trim().length !== 0 ? response.body.sysInfo.smpSchdlTmIapYn : 'N' // 방해금지시간대 설정
              lv_Vm.lv_SysOptInfo.smpIntrsStrTm = response.body.sysInfo.smpIntrsStrTm.trim().length !== 0 ? response.body.sysInfo.smpIntrsStrTm : '200000' // 방해금지 시작시간
              lv_Vm.lv_SysOptInfo.smpIntrsEndTm = response.body.sysInfo.smpIntrsEndTm.trim().length !== 0 ? response.body.sysInfo.smpIntrsEndTm : '080000' // 방해금지 종료시간
              lv_Vm.lv_SysOptInfo.smpCustCntcIapYn = response.body.sysInfo.smpCustCntcIapYn.trim().length !== 0 ? response.body.sysInfo.smpCustCntcIapYn : 'Y' // 고객접촉정보
              lv_Vm.lv_SysOptInfo.smpEvtIapYn = response.body.sysInfo.smpEvtIapYn.trim().length !== 0 ? response.body.sysInfo.smpEvtIapYn : 'Y' // 이벤트 고객
              lv_Vm.lv_SysOptInfo.coIapYn = response.body.sysInfo.coIapYn.trim().length !== 0 ? response.body.sysInfo.coIapYn : 'Y' // 공지사항
              lv_Vm.lv_SysOptInfo.smpMySchdlIapYn = response.body.sysInfo.smpMySchdlIapYn.trim().length !== 0 ? response.body.sysInfo.smpMySchdlIapYn : 'Y' // 내일정
              lv_Vm.lv_SysOptInfo.mobslTelReciSrvcYn = response.body.sysInfo.mobslTelReciSrvcYn.trim().length !== 0 ? response.body.sysInfo.mobslTelReciSrvcYn : 'N' // 전화수신서비스여부
              console.log('lv_Vm.lv_SysOptInfo ===>>', lv_Vm.lv_SysOptInfo)
            } else {
              lv_Vm.lv_SysOptInfo.smpIapReciYn = 'Y'
              lv_Vm.lv_SysOptInfo.smpIapUkepDds = '3'
              lv_Vm.lv_SysOptInfo.smpSchdlTmIapYn = 'N'
              lv_Vm.lv_SysOptInfo.smpIntrsStrTm = '200000'
              lv_Vm.lv_SysOptInfo.smpIntrsEndTm = '080000'
              lv_Vm.lv_SysOptInfo.smpCustCntcIapYn = 'Y'
              lv_Vm.lv_SysOptInfo.smpEvtIapYn = 'Y'
              lv_Vm.lv_SysOptInfo.coIapYn = 'Y'
              lv_Vm.lv_SysOptInfo.smpMySchdlIapYn = 'Y'
              lv_Vm.lv_SysOptInfo.mobslTelReciSrvcYn = 'N'
            }
            if (lv_Vm.lv_SysOptInfo.smpCustCntcIapYn === 'N') {
              lv_Vm.lv_SmpCustCntcInfo = false
            } else {
              lv_Vm.lv_SmpCustCntcInfo = true
            }
            if (lv_Vm.lv_SysOptInfo.smpEvtIapYn === 'N') {
              lv_Vm.lv_SmpEvent = false
            } else {
              lv_Vm.lv_SmpEvent = true
            }
            if (lv_Vm.lv_SysOptInfo.coIapYn === 'N') { // 공지사항
              lv_Vm.lv_SmpMyNotif = false
            } else {
              lv_Vm.lv_SmpMyNotif = true
            }
            if (lv_Vm.lv_SysOptInfo.smpMySchdlIapYn === 'N') {
              lv_Vm.lv_SmpMySchdl = false
            } else {
              lv_Vm.lv_SmpMySchdl = true
            }

            if (lv_Vm.lv_SysOptInfo.smpIapReciYn === 'N') { // 알림사용여부
              lv_Vm.lv_SmpIapReciYn = false
            } else {
              lv_Vm.lv_SmpIapReciYn = true
            }
            
            lv_Vm.lv_SmpPeridValue = lv_Vm.lv_SysOptInfo.smpIapUkepDds // 알림유지기간

            if (lv_Vm.lv_SysOptInfo.smpSchdlTmIapYn === 'Y') { // 방해금지시간 여부
              lv_Vm.lv_SmpSchdlTmIapYn = true
            } else {
              lv_Vm.lv_SmpSchdlTmIapYn = false
            }

            if (lv_Vm.lv_SysOptInfo.mobslTelReciSrvcYn === 'Y') { // 전화수신서비스 여부 (2024-10-18)
              lv_Vm.lv_telReciSrvcYn = true
              lv_Vm.firstChk = true
            } 
            else {
              lv_Vm.lv_telReciSrvcYn = false
            }
            lv_Vm.lv_SmpIapIntrsStrTmArr = lv_Vm.fn_ScreenCompr(lv_Vm.lv_SysOptInfo.smpIntrsStrTm)
            lv_Vm.lv_SmpIapIntrsEndTmArr = lv_Vm.fn_ScreenCompr(lv_Vm.lv_SysOptInfo.smpIntrsEndTm)
            // lv_Vm.lv_SmpIapIntrsStrTmArr = '오전 08:00'
            // lv_Vm.lv_SmpIapIntrsEndTmArr = '오후 08:00'
            console.log('lv_Vm.lv_SmpIapIntrsStrTmArr', lv_Vm.lv_SmpIapIntrsStrTmArr)
            console.log('lv_Vm.lv_SmpIapIntrsEndTmArr', lv_Vm.lv_SmpIapIntrsEndTmArr)
          }).catch(function(error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
       * Function명 : fn_FontSet를 (key value)값으로 IOS, Android 전송
       * 설명       : 폰트 사이즈 저장
       * 인자       : pItem
       * Return     : N/A
       ******************************************************************************/
      fn_FontSet (pItem) {
        this.lv_SmpSmall = false
        this.lv_SmpMiddle = false
        this.lv_SmpLarge = false
        this[pItem] = true
        this.$bcUtil.fontSet(pItem)


        // 세팅 로그 저장
        const pParam = {
          isHome: false,          
          font: pItem
        }
        this.$bizUtil.insSizeConfigLog(pParam)
      },
      /******************************************************************************
       * Function명 : fn_SrnSet
       * 설명       : 화면크기를 조정한다
       * 인자       : pItem
       * Return     : N/A
       ******************************************************************************/
      fn_SrnSet (pItem) {
        this.lv_SrnSmall = false
        this.lv_SrnLarge = true
        if (pItem === '1') {
          this.lv_SrnSmall = true
          this.lv_SrnLarge = false
          localStorage.setItem('srn_usr_setting', 'N')
          this.fn_SetSrnSize()
        } else {
          this.lv_SrnLarge = true
          this.lv_SrnSmall = false
          localStorage.setItem('srn_usr_setting', 'Y')
          this.fn_SetSrnSize()
        }

        // 세팅 로그 저장
        const pParam = {
          isHome: false,          
          screen: pItem
        }
        this.$bizUtil.insSizeConfigLog(pParam)
      },
      /******************************************************************************
       * Function명 : fn_OnAllAlarmSet
       * 설명       : 알림기능 설정
       * 인자       : SP_ALARM_SET ,SP_MYCUSTOM_SET(고객접촉정보) ,SP_MYSCADULE_SET(내일정)
       *             ,SP_MYEVENT_SET(이벤트) 값을 localStorage 와
       *             안드로이드 공유메모리 Preference 에 저장후 푸시 알림설정을 제어함.
       * Return     : N/A
       ******************************************************************************/
      fn_OnAllAlarmSet (pKey, pValue) {
        localStorage.setItem(pKey, Boolean(pValue)) // 알림기능 설정
        // 안드로이드 preference 에 값을 저장한다. 2:통합접촉고객, 8:일정등록, 9:이벤트정보 12: 관리자발송 공지사항
        let pBizCode
        if (pKey === 'SP_ALARM_SET') pBizCode = String(pValue)
        else if (pKey === 'SP_MYCUSTOM_SET') pBizCode = String(pValue) // 통합접촉고객 '2'
        else if (pKey === 'SP_MYEVENT_SET') pBizCode = String(pValue) // 이벤트정보 '9'
        else if (pKey === 'SP_MYSCADULE_SET') pBizCode = String(pValue) // 일정등록 '8'
        else if (pKey === 'SP_MYNOTIF_SET') pBizCode = String(pValue) // 관리자 발송 공지사항 '12'
        else pBizCode = String(pValue)
        // alert('pKey =' + pKey + ' pValue= ' + pValue)
        window.fdpbridge.exec('AsyncStorageSetPlugin', {
          key: pKey,
          data: pBizCode
        }, function(result) {
          // 성공콜백
          console.log('fn_OnAllAlarmSet', result)
        }, function(result) {
          // 실패콜백
        })
      },
      /******************************************************************************
       * Function명 : fn_PeroidSet
       * 설명       : 알림유지 기간 설정
       * 인자       : SP_PERIOD_03_SET(3일) ,SP_PERIOD_07_SET(7일) ,SP_PERIOD_30_SET(30일)
       *              값을 localStorage 저장 및 안드로이드 공유메모리 Preference 에 저장 후
       *              푸시 알림유지기간 설정을 제어함.
       * Return     : N/A
       ******************************************************************************/
      fn_PeroidSet(item) {
        let tmpPerid_03, tmpPerid_07, tmpPerid_30
        if (item === '3') {
          tmpPerid_03 = true
          tmpPerid_07 = false
          tmpPerid_30 = false
        } else if (item === '7') {
          tmpPerid_03 = false
          tmpPerid_07 = true
          tmpPerid_30 = false
        } else if (item === '30') {
          tmpPerid_03 = false
          tmpPerid_07 = false
          tmpPerid_30 = true
        }
        this.fn_OnAllAlarmSet('SP_PERIOD_03_SET', tmpPerid_03)
        this.fn_OnAllAlarmSet('SP_PERIOD_07_SET', tmpPerid_07)
        this.fn_OnAllAlarmSet('SP_PERIOD_30_SET', tmpPerid_30)
      },
      /******************************************************************************
       * Function명 : fn_ScreenCompr
       * 설명       : 화면에 표시되는 시간 Format
       * 인자       : time
       * Return     : N/A
       ******************************************************************************/
      fn_ScreenCompr (time) {
        console.log('fn_ScreenCompr', time)
        let timeHm = time.substring(0, 2)
        let timeMm = time.substring(2, 4)
        if (Number(timeHm) === 0) {
          timeHm = '12'
          time = timeHm + ':' + timeMm + ' ' + 'AM'
        }
        else if (Number(timeHm) === 12) {
          timeHm = '12'
          time = timeHm + ':' + timeMm + ' ' + 'PM'
        } else {
          time = timeHm + ':' + timeMm
        }
        return this.fn_DonTouchTimeUIFormat( moment(time, 'h:mm A').format('hh:mm A'))
      },
      /******************************************************************************
       * Function명 : fn_DonTouchTimeUIFormat
       * 설명       : 방해금지 시간대 시작 및 종료 시간 UI Format
       * 인자       : time
       * Return     : N/A
       ******************************************************************************/
      fn_DonTouchTimeUIFormat(time) {
        let t_time
        let timeA = time.substring(5, 8)
        let timeHH = time.substring(0, 2)
        let timeMM = time.substring(3, 5)
        if (timeA.trim() === 'AM') {
          timeA = '오전'
        } else {
          timeA = '오후'
        }
        t_time = timeA + ' ' + timeHH + ':' + timeMM
        console.log('fn_DonTouchTimeUIFormat', t_time)
        return t_time
      },
      /******************************************************************************
       * Function명 : fn_DonTouchTimeServerFormat
       * 설명       : 방해금지 시간대 시작 및 종료 시간 Server Format
       * 인자       : time
       * Return     : N/A
       ******************************************************************************/
      fn_DonTouchTimeServerFormat(time) {
        console.log('fn_DonTouchTimeServerFormat ==== >>', time)
        let timeA, timeHH, timeMM
        timeMM = time.substring(6, 8)
        timeA = time.substring(0, 2)
        timeHH = time.substring(3, 5)
        if (timeA === '오전') {
          if (Number(timeHH) === 12) {
            timeHH = '00'
            time = timeHH + timeMM
          } else {
            time = timeHH + timeMM
          }
        } else {
          if (Number(timeHH) === 12) {
            time = timeHH + timeMM
          } else {
          timeHH = Number(timeHH) + 12
          time = timeHH + timeMM
          }
        }
        console.log('send server time => ', time)
        return time
      },
      /******************************************************************************
       * Function명 : fn_OnDontTouchSetTime
       * 설명       : 방해금지 시간대 시작 및 종료 시간 설정
       * 인자       : START_APPM,START_HOUR,START_MINUTE ,END_AP오후,END_HOUR,END_MINUTE값을
       *              localStorage 저장 및 안드로이드 공유메모리 Preference 에 저장 후 푸시
       *              알림유지기간 설정을 제어함.
       * Return     : N/A
       ******************************************************************************/
      fn_OnDontTouchSetTime () {
        let strTimeA, strTimeHH, strTimeMM, endTimeA, endTimeHH, endTimeMM
        strTimeA = this.lv_SmpIapIntrsStrTmArr.substring(0, 2) // 오전 || 오후
        strTimeHH = this.lv_SmpIapIntrsStrTmArr.substring(3, 5) // 시간
        strTimeMM = this.lv_SmpIapIntrsStrTmArr.substring(6, 8) // 분
        endTimeA = this.lv_SmpIapIntrsEndTmArr.substring(0, 2)
        endTimeHH = this.lv_SmpIapIntrsEndTmArr.substring(3, 5) // 시간
        endTimeMM = this.lv_SmpIapIntrsEndTmArr.substring(6, 8) // 분
        localStorage.setItem('START_APPM', strTimeA) // 알림유지 기간 시작 설정값 저장
        localStorage.setItem('START_HOUR', strTimeHH)
        localStorage.setItem('START_MINUTE', strTimeMM)
        localStorage.setItem('END_APPM', endTimeA) // 알림유지 기간 종료 설정값 저장
        localStorage.setItem('END_HOUR', endTimeHH)
        localStorage.setItem('END_MINUTE', endTimeMM)
        let Dontouch_sTime, Dontouch_eTime
        Dontouch_sTime = this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsStrTmArr)
        Dontouch_eTime = this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsEndTmArr)
        // 안드로이드에 공유메모리에 저장 요청한다.

        this.fn_OnAllAlarmSet('DONTTOUCHT_START', Dontouch_sTime)
        this.fn_OnAllAlarmSet('DONTTOUCHT_ETART', Dontouch_eTime)
      },
      /******************************************************************************
       * Function명 : fn_CheckWorkingTime
       * 설명       : 방해금지 시간대 시작 및 종료 시간 확인
       * 인자       : N/A
       * Return     : 업무시간 외 설정 True, 업무시간 포함 false
       ******************************************************************************/
      fn_CheckWorkingTime() {
        let t_StartTime, t_EndTime
        t_StartTime = Number(this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsStrTmArr))
        t_EndTime = Number(this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsEndTmArr))
        // 업무시간(0800~1800) 설정 금지
        // Case1 시작시간이 0000 ~ 0800 이전일 때
        // : 종료시간은 시작시간 ~ 0800 까지
        // Case2 시작시간이 1800 ~ 2359 일 때 
        // : 종료시간은 시작시간 부터 2359 거나 0000~0800 만 가능
        if (t_StartTime >= 0 && t_StartTime <= 800) {
          if (t_EndTime >= t_StartTime && t_EndTime <= 800) {
            return true
          } else {
            return false
          }
        } else if (t_StartTime >= 1800 && t_StartTime <= 2359) {
          if ( (t_EndTime >= t_StartTime && t_EndTime <= 2359)
            || (t_EndTime >= 0 && t_EndTime <= 800)) {
            return true
          } else {
            return false
          }
        } else {
            return false
        }
      },
      /*********************************************************
       * Function명: fn_SaveSysInfo
       * 설명: 시스템 정보 수정
       * Params: BCOptnMngSrchISVO {
       * userEmpNo: 사용자사번
       * lv_SmpIapReciYn: 알림기능 수신여부
       * lv_SmpPeridItems: 알림유지기간
       * lv_SmpCustCntcInfo: 고객접촉정보
       * lv_SmpEvent: 이벤트
       * lv_SmpMyNotif: 공지사항
       * lv_SmpMySchdl: 내일정
       * lv_SmpSchdlTmIapYn: 방해금지시간대 설정
       * lv_SmpIapIntrsStrTmArr: 방해금지 시작시간
       * lv_SmpIapIntrsEndTmArr: 방해금지 종료시간
       * }
       * Return: N/A
       *********************************************************/
      fn_SaveSysInfo () {
        let lv_Vm = this
        // if (lv_Vm.lv_SmpIapReciYn) { // 알림기능 수신여부
        //   lv_Vm.lv_SysOptInfo.smpIapReciYn = 'Y'
        // } else {
        //   lv_Vm.lv_SysOptInfo.smpIapReciYn = 'N'
        // }
        // if (lv_Vm.lv_SmpCustCntcInfo) { // 고객접촉정보
        //   lv_Vm.lv_SysOptInfo.smpCustCntcIapYn = 'Y'
        // } else {
        //   lv_Vm.lv_SysOptInfo.smpCustCntcIapYn = 'N'
        // }
        // if (lv_Vm.lv_SmpEvent) { // 이벤트
        //   lv_Vm.lv_SysOptInfo.smpEvtIapYn = 'Y'
        // } else {
        //   lv_Vm.lv_SysOptInfo.smpEvtIapYn = 'N'
        // }
        // if (lv_Vm.lv_SmpMyNotif) { // 공지사항
        //   lv_Vm.lv_SysOptInfo.coIapYn = 'Y'
        // } else {
        //   lv_Vm.lv_SysOptInfo.coIapYn = 'N'
        // }
        // if (lv_Vm.lv_SmpMySchdl) { // 내일정
        //   lv_Vm.lv_SysOptInfo.smpMySchdlIapYn = 'Y'
        // } else {
        //   lv_Vm.lv_SysOptInfo.smpMySchdlIapYn = 'N'
        // }

        // 2022.08.26 알림설정 기능 중 알림유지기간 및 방해금지 외 주석처리 후 항상 수신허용(차민아 요청)
        this.lv_SysOptInfo.smpIapReciYn = 'Y' // 알림기능 수신여부
        this.lv_SysOptInfo.smpCustCntcIapYn = 'Y' // 고객접촉정보
        this.lv_SysOptInfo.smpEvtIapYn = 'Y' // 이벤트
        this.lv_SysOptInfo.coIapYn = 'Y' // 공지사항
        this.lv_SysOptInfo.smpMySchdlIapYn = 'Y' // 내일정

        if (this.lv_SmpSchdlTmIapYn) { // 방해금지시간대 설정
          this.lv_SysOptInfo.smpSchdlTmIapYn = 'Y'
        } else {
          this.lv_SysOptInfo.smpSchdlTmIapYn = 'N'
          this.lv_SmpIapIntrsStrTmArr = '오후 08:00'
          this.lv_SmpIapIntrsEndTmArr = '오전 08:00'
        }

        this.lv_SysOptInfo.smpIapUkepDds = this.lv_SmpPeridValue // 알림유지기간        
        this.lv_SysOptInfo.smpIntrsStrTm = this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsStrTmArr) // 방해금지시간대 시작시간
        this.lv_SysOptInfo.smpIntrsEndTm = this.fn_DonTouchTimeServerFormat(this.lv_SmpIapIntrsEndTmArr) // 방해금지시간대 종료시간
        let pParams = lv_Vm.lv_SysOptInfo
        
        if (this.fn_CheckWorkingTime()) {
          const trnstId = 'txTSSBC20U3'
          const auth = 'U'
          console.log('pParams :::', pParams)
          this.post(lv_Vm, pParams, trnstId, auth, {}, true)
            .then(function(response) {
              // console.log('response Save Info CallBack :::', response)
              lv_Vm.fn_OnAllAlarmSet('SP_ALARM_SET', Boolean(lv_Vm.lv_SmpIapReciYn))
              lv_Vm.fn_OnAllAlarmSet('SP_MYCUSTOM_SET', Boolean(lv_Vm.lv_SmpCustCntcInfo))
              lv_Vm.fn_OnAllAlarmSet('SP_MYEVENT_SET', Boolean(lv_Vm.lv_SmpEvent))
              lv_Vm.fn_OnAllAlarmSet('SP_MYNOTIF_SET', Boolean(lv_Vm.lv_SmpMyNotif))
              lv_Vm.fn_OnAllAlarmSet('SP_MYSCADULE_SET', Boolean(lv_Vm.lv_SmpMySchdl))
              lv_Vm.fn_PeroidSet(lv_Vm.lv_SmpPeridValue)
              lv_Vm.fn_OnAllAlarmSet('SP_DONTTOUCH_SET', Boolean(lv_Vm.lv_SmpSchdlTmIapYn))
              lv_Vm.fn_OnAllAlarmSet('DONTTOUCHT_START', lv_Vm.lv_SysOptInfo.smpIntrsStrTm)
              lv_Vm.fn_OnAllAlarmSet('DONTTOUCHT_ETART', lv_Vm.lv_SysOptInfo.smpIntrsEndTm)
            })
        } else {
          this.fn_BottomAlert('선택하신 시간대는 설정 기본값으로 전환합니다. (방해금지 시간대는 야간시간대인 오후 6시 ~ 오전 8시에 설정 가능합니다.)')
        }
      },
      /******************************************************************************
       * Function명 : fn_GetVersion
       * 설명       : 안드로이드, IOS 버전 가져오기
       ******************************************************************************/
      fn_GetVersion () {
        let lv_Vm = this
          window.fdpbridge.exec('getVersionPlugin', {}, (result) => {
            let resultData = JSON.parse( result.data )
            if (resultData.update.length !== 0) {
              lv_Vm.lv_AppData.lastUpdateDT = moment( resultData.update ).format('YYYY-MM-DD')
            } else {
              lv_Vm.lv_AppData.lastUpdateDT = ''
            }
            lv_Vm.lv_AppData.versionName = resultData.versionName
          }, () => {})
      },
      /******************************************************************************
       * Function명 : fn_GetSDKVersion
       * 설명       : 안드로이드, IOS 버전 OS 버전 가져오기
       ******************************************************************************/
      fn_GetSDKVersion () {
        let lv_Vm = this
          window.fdpbridge.exec('getSDKPlugin', {}, (result) => {
            let resultData = JSON.parse( result.data )
            if (resultData.update.length !== 0) {
              lv_Vm.lv_AppData.lastUpdateDT = moment( resultData.update ).format('YYYY-MM-DD')
            } else {
              lv_Vm.lv_AppData.lastUpdateDT = ''
            }
            lv_Vm.lv_AppData.versionName = resultData.versionName
          }, () => {})
      },
      /******************************************************************************
       * Function명 : fn_ShowSnackbar
       * 설명       : 확인 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_ShowSnackbar () {
        console.log('click')
        if (this.lv_SmpIapReciYn) {
          return
        } else {
          this.$refs.snackbar.show({ text: this.toastMsg })
        }
      },
      /******************************************************************************
       * Function명 : fn_BottomAlert
       * 설명       : 확인 팝업 호출
       * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
       ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop);
              console.log('onPopupConfirm')
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop);
              console.log('onPopupClose')
            }
          }
        },
        {
          listeners: {
            closed: this.fn_SetTime
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_SetTime
      * 설명       : 방해금지시간값 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_SetTime () {
        this.lv_SysOptInfo.smpIntrsStrTm = '200000'
        this.lv_SysOptInfo.smpIntrsEndTm = '080000'
        this.lv_SmpIapIntrsStrTmArr = this.fn_ScreenCompr(this.lv_SysOptInfo.smpIntrsStrTm)
        this.lv_SmpIapIntrsEndTmArr = this.fn_ScreenCompr(this.lv_SysOptInfo.smpIntrsEndTm)
      },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
          this.$router.go(-1)
          setTimeout(() => {
            this.$BottomManager.fn_SetBottomMenuDrawerOpen()
          }, 500)
          setTimeout(() => {
            this.$BottomManager.fn_SetBottomVisible(true)
          }, 500)
      },
      /******************************************************************************
      * Function명  : fn_initPass
      * 설명        : [사랑On] 사내접속 패스워드 초기화 메뉴/화면 개발 요청, 결과상세코드(0:성공, 1:시스템오류, 2:사용자정보없음)
      ******************************************************************************/
      async fn_initPass() {
        if (this.fn_CheckBlockingTime()) {
          let obj = {
            title : '비밀번호 초기화 요청 횟수 초과',
            msg : '비밀번호 초기화 요청 횟수를 초과했습니다.\n10분 후에 다시 시도해주세요.'
          }
          this.fn_ErrorPop(obj)
          return false
        }
        let lv_Vm = this
        const trnstId = 'txTSSBC23S1'
        const auth = 'S'
        const pParams = {}
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function(response) {
            let trtSucsYn = response.body.trtSucsYn
            let msgCntnt = response.body.msgCntnt // 결과상세코드(0:성공, 1:시스템오류, 2:사용자정보없음)                        
            if (trtSucsYn === 'Y' && msgCntnt === '0') { // 성공
               if (lv_Vm.fn_CheckCtntConn()) { // 5회 초기화 수행 시 alert 팝업, 10분 블로킹 등록
                let obj = {
                  title : '비밀번호 초기화 요청 횟수 초과',
                  msg : '비밀번호 초기화 요청 횟수를 초과했습니다.\n10분 후에 다시 시도해주세요.'
                }
                lv_Vm.fn_ErrorPop(obj)
                return false
              }              
              lv_Vm.$refs.bottomSheetPass.open()
            } else {
              if (msgCntnt ===  '1') {
                let obj = {
                  title : '사내망 비밀번호 초기화에 실패했습니다.',
                  msg : '시스템 오류로 초기화에 실패했습니다.\n설정 화면의 ‘사내망 비밀번호 초기화’ 버튼을 다시 눌러주세요.'
                }
                lv_Vm.fn_ErrorPop(obj)
              } else {
                let obj = {
                  title : '사내망 비밀번호 초기화에 실패했습니다.',
                  msg : '사용자 정보가 없습니다. IT서비스데스크(02-3114-500)에서 안내 받으시기 바랍니다.'
                }
                lv_Vm.fn_ErrorPop(obj)
              }
            }

        }).catch(function (error) {          
          let obj = {
            title : '사내망 비밀번호 초기화에 실패했습니다.',
            msg : '시스템 오류로 초기화에 실패했습니다.\n설정 화면의 ‘사내망 비밀번호 초기화’ 버튼을 다시 눌러주세요.'
          }
          lv_Vm.fn_ErrorPop(obj)
        }).finally(() => {
          lv_Vm.getStore('progress').getters.getState.isShow = false
        })
      },
      /******************************************************************************
      * Function명 : fn_ErrorPop
      * 설명       : 관리자 아이디 팝업
      ******************************************************************************/
      fn_ErrorPop (obj) {
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: obj.title,
            content: obj.msg,
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log('onPopupConfirm call')
              this.$bottomModal.close(lv_AlertPop)
            },
            onPopupCancel: () => {
              console.log('onPopupCancel call')
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        }) 
      },      
      /******************************************************************************
      * Function명  : fn_setTablet
      * 설명        : 테블릿 설정 바로가기
      ******************************************************************************/
      async fn_setTablet() {
        
        this.$refs.bottomSheetPass.close();
        window.fdpbridge.exec('openDeviceSettingPlugin', {action: 'android.settings.WIFI_SETTINGS'}, function (result) {
          console.log('openDeviceSettingPlugin', result)
        }, function (result) {
          // 실패콜백
          // this.getStore('confirm').dispatch('ADD', '음성인식에 실패하였습니다.')
        })
      },
      /******************************************************************************
      * Function명  : fn_openInfoMaterial
      * 설명        : 안내자료열기
      ******************************************************************************/
      async fn_preViewPDF() {
        this.$refs.bottomSheetPass.close()

        this.getStore('progress').getters.getState.isShow = true
        let lv_Vm = this
        let fileNm = '' // *안내자료 파일명으로 수정필요        
        let fileDate = ''
        
        let pEndDate = moment(new Date()).format('YYYYMMDD')

        let pParams =
        {
          untySlmtNm: '사내접속', // 통합영업자료명
          untySlmtClsfCd: '%', // 통합영업자료분류코드
          untySlmtUseAuthCd: '1%', // 통합영업자료사용권한코드
          moblInqrYn: 'Y', // 모바일에서 호출여부
          inqrStrYmd: '20231110',
          inqrEndYmd: pEndDate
        }
        let responseList = []
        let trnstId = 'txTSSSA07S1'
        const auth = 'S'

        console.log('#################################');
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
              // 서비스 후처리
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                // 응답 데이터 처리
                let reponseBody = response.body
                responseList = reponseBody.iCCntntBasInfoVO
                if (responseList !== null && responseList.length > 0) {
                  let tmpD = ''
                  for (let i = 0; i < responseList.length; i++) {
                    if (tmpD === '') {
                      tmpD = responseList[i].untySlmtRegDtm
                      fileDate = responseList[i].pdfApndFilePathNm
                      fileNm = responseList[i].pdfApndFileStoreNm
                      lv_Vm.fn_preViewOpenPDF(fileDate, fileNm)
                    } else if (tmpD < responseList[i].untySlmtRegDtm) {
                      tmpD = responseList[i].untySlmtRegDtm
                      fileDate = responseList[i].pdfApndFilePathNm
                      fileNm = responseList[i].pdfApndFileStoreNm
                      lv_Vm.fn_preViewOpenPDF(fileDate, fileNm)
                    }
                  }
                }
              }
            // 서버 에러 처리
            return Promise.reject(error)
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        
      },
      /******************************************************************************
       * Function명  : fn_openInfoMaterial
       * 설명        : 안내자료열기
      ******************************************************************************/
     async fn_preViewOpenPDF(fileDate, fileNm) {
       let lv_Vm = this
        let param = {
          fileTitle: '사내망 Wifi 비밀번호 초기화 안내자료',
          localFileNm: fileNm,
          eltrnDoctFileNm: fileDate + '/' + fileNm,  // *안내자료 파일명으로 수정필요
          eltrnDoctFilePathNm: 'UNTY_SLMT_DIR' // *안내자료 경로로 수정필요  UNTY_SLMT_DIR
        }
   
        let isMobile = this.getStore('deviceState').getters.getIsMobile
        console.log('$$$$$$$$$$$$$$$$$$$' +  param.eltrnDoctFileNm)
        let viewerTargetFileList = ''
        
        if(isMobile) {
          try {
              lv_Vm.fn_FdpSaveFile(param)
                .then(response => {                  
                  // 파일명과 PDF Viewer에 노출될 제목을 '|'로 결합하여 한 파일에 대한 정보를 구성                  
                  viewerTargetFileList += response + 'tssB' + param.fileTitle + 'tssA'  
                  console.log(viewerTargetFileList)                
                  // 단일 선택일 경우는 바로 PDF Viewer 호출
                  lv_Vm.fn_CallPdfViewer(viewerTargetFileList)
                }).catch(error => {                                    
                  lv_Vm.getStore('progress').getters.getState.isShow = false
                  // 오류 팝업 메시지 출력
                  lv_Vm.getStore('confirm').dispatch('ADD', '안내자료 다운로드에 실패하였습니다')
                  if (this.DEBUG_MODE_CON) window.console.log(new Error(error))
                  window.vue.getStore('progress').dispatch('SUB')
                })
            } catch (error) {
              if (this.DEBUG_MODE_CON) window.console.log(new Error(error))
              window.vue.getStore('progress').dispatch('SUB')
            }          
        } else {
          let downloadUrl = ''
          if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {            
            downloadUrl = 'http://u-channel.t.samsunglife.kr:8080'
          } else if (process.env.NODE_ENV === 'stage') {
            downloadUrl = 'http://u-channel.v.samsunglife.kr:9000'
          }
   
          let tmpPath = '/BRCH_ATTACH/bizupload/'          
          /**
           * PC 화면에서 뷰어 없이 웹브라우저 볼 때,
           * 로컬 or 개발계 : http://u-channel.t.samsunglife.kr:8080/PDF/cm/fmlmt/pdf/20231114/73200080101141113202.pdf?IS_POPUP=true
           * 검증계 : http://u-channel.v.samsunglife.kr:9000/PDF/cm/fmlmt/pdf/20231114/73200080101141113202.pdf?IS_POPUP=true (최초로그인 시 검증계 통합인증 초기화 필요)
           */                    
          let tmpUrl = downloadUrl + tmpPath +  fileDate + '/' + fileNm +'?IS_POPUP=true'
          console.log(tmpUrl)
          window.open(tmpUrl, '_blank')
        }
      },
      /******************************************************************************
      * Function명 : fn_FdpSaveFile
      * 설명        : Native 저장 장소로 실제 PDF 파일을 다운로드
      *              실제 파일 존재 유무는 다음과 같은 URL을 이용 확인 가능
      *              https://vtss.samsunglife.com:8410/filedown.do?path=file.path.fmlmt&filename=/20190122/000300336200252107122109102.pdf&type=pdf
      * Params     : 다운로드할 PDF 정보를 담고있는 개체
      * Return     : N/A
      ******************************************************************************/
      fn_FdpSaveFile (obj) {
        return new Promise((resolve, reject) => {
          window.fdpbridge.exec('downloadPlugin', {
            path: obj.eltrnDoctFilePathNm,
            filename: obj.eltrnDoctFileNm, // src Directory + fileName
            type: 'pdf',
            localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
            localfilename: obj.localFileNm, // local fileName
            rename: 'N' // 파일명 중복에 관계없이 신규 파일 생성 여부
          }, (result) => {
            resolve(result.data)
          }, (result) => {
            reject(result.data)
          })
        })
      },      
      /******************************************************************************
      * Function명 : fn_CallPdfViewer
      * 설명        : PDF Viewer 호출
      * Params     : PDF Viewer를 통해 보여줄 PDF 파일 및 제목 목록
      * Return     : N/A
      ******************************************************************************/
      fn_CallPdfViewer (viewerTargetFileList) {
        // PDF Viewer를 통해 PDF 확인은 가능하나 툴팁 메뉴 사용은 불가
        let lv_Vm = this
        // PDF 뷰어 호출
        window.fdpbridge.exec('callPdfViewerPlugin', {
          path: '/sli/tss/comm/fmlmt',
          file: viewerTargetFileList,
          menu: '0000'
        }, function (result) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_Vm.getStore('progress').getters.getState.isShow = false
          // 성공콜백
        }, function (result) {
          lv_Vm.getStore('progress').getters.getState.isShow = false
          // 실패콜백
        })
      },
      /******************************************************************************
      * Function명 : fn_CheckCtntConn
      * 설명       : 접속 연속 체크 (4회 이상, 5번째 전 시간데이터와 현재 시간 비교 600초(10분) 이상일 때 오류 팝업)
      * Params     : N/A
      * Return     : 연속 아님 - false , 연속 - true
      ******************************************************************************/
      fn_CheckCtntConn() {
        let curTime = moment(new Date()).format('YYYYMMDDHHmmss')
        let connTimes = localStorage.getItem('INIT_WIFI_CONN_DATETIME') // 현재 wifi패스워드초기화 개수
        let seperate = '|'
        let limitSeconds = 600
        let blockingSeconds = 600
        let limitCount = 5
        try {
          if (connTimes) { // 최초 등록 이후에 등록 시에
            connTimes += seperate
            connTimes += curTime
            let connTimeArr = connTimes.split(seperate) // array        
            let checkTime = connTimeArr && connTimeArr.length >= limitCount ? connTimeArr[connTimeArr.length - limitCount] : '' // 5번째 전 시간
            let limitTime =  moment().subtract(limitSeconds, 'seconds').format('YYYYMMDDHHmmss') // 10분 전
            console.log(`Time array : ${connTimeArr}\n5번째 전 시간 : ${checkTime}\n현재시간 : ${curTime}\n10분 전 : ${limitTime}`)          
            if (checkTime && checkTime > limitTime) { // 4회 이상, 4회 이전의 시간 데이터와 현재 시간 비교 600초(10분) 이상일 때,            
              console.log(`현재시간 : ${curTime}, 5번 째 전 시간 :  ${checkTime}, 비교시간 :  ${limitTime}`)  
              let blockingTime = moment().add(blockingSeconds, 'seconds').format('YYYYMMDDHHmmss') // 10분 전
              localStorage.setItem('WIFI_BLOCKING_TIME', blockingTime) // 10분간 blocking 타임 설정
              localStorage.setItem('INIT_WIFI_CONN_DATETIME', '') // 횟수 초기화
              return true
            }
            localStorage.setItem('INIT_WIFI_CONN_DATETIME', connTimes)
          } else {  // 최초 등록 시
            localStorage.setItem('INIT_WIFI_CONN_DATETIME', curTime)
            localStorage.setItem('WIFI_BLOCKING_TIME', '') // blocking time 초기화
          }
        } catch (error) {
          console.log('fn_CheckCtntConn :', error)
        }
        return false
      },
      /******************************************************************************
      * Function명 : fn_CheckBlockingTime
      * 설명       : Blocking 시간 체크 (10분 내 5회이상 초기화 시 10분 동안 블로킹)
      * Params     : N/A
      * Return     : 10분 내 클릭 - true , 10 분 초과 - false
      ******************************************************************************/
      fn_CheckBlockingTime() {
        let curTime = moment(new Date()).format('YYYYMMDDHHmmss')
        let blockingTime = localStorage.getItem('WIFI_BLOCKING_TIME') // 현재 wifi패스워드초기화 개수        
        try {
          if (blockingTime) {
            if (curTime <= blockingTime) {
              return true
            } else {
              localStorage.setItem('WIFI_BLOCKING_TIME', '') // blocking time 초기화
            }
          }
        } catch (error) {
          console.log('fn_CheckBlockingTime :', error)
        }
        return false
      },
      /******************************************************************************
      * Function명 : fn_onlyPhoneAOS
      * Return     : 디바이스 체크(AOS 휴대폰에서만 동작)
      ******************************************************************************/
      async fn_onlyPhoneAOS(chk) {
        console.log("chk", chk)
        let lv_vm = this

        if(chk !== 'Y') {
          if(this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P001') ||
            this.getStore('userInfo').getters.getUserInfo.userRoleList.includes('P002')) {
            lv_vm.osType = await this.$bizUtil.telSrvcUserChk()

            if(lv_vm.osType) {
              window.fdpbridge.exec('getSDKPlugin', {}, function (result) {
                  console.log('getSDKPlugin success', result)
                  if(result.data == '31' || result.data == '32') {
                    lv_vm.getSDK = '12'
                  }
                  if(result.data == '33') {
                    lv_vm.getSDK = '13'
                  }
                  if(result.data == '34') {
                    lv_vm.getSDK = '14'
                  }
              },(error) =>{
                  console.log('getSDKPlugin error', error)
              })
            }
          } else {
            lv_vm.osType = false
          }
        } else {
          lv_vm.osType = await this.$bizUtil.telSrvcUserChk()

          if(lv_vm.osType) {
            window.fdpbridge.exec('getSDKPlugin', {}, function (result) {
                console.log('getSDKPlugin success', result)
                if(result.data == '31' || result.data == '32') {
                  lv_vm.getSDK = '12'
                }
                if(result.data == '33') {
                  lv_vm.getSDK = '13'
                }
                if(result.data == '34') {
                  lv_vm.getSDK = '14'
                }
            },(error) =>{
                console.log('getSDKPlugin error', error)
            })
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_OpenAlert
      * Return     : 바텀얼럿
      ******************************************************************************/
      fn_OpenAlert() {
        const popup661 = this.$bottomModal.open(MSPBC661P, {        
          properties: {
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(popup661)
                // 디바이스백키 활성화
                window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
                this.lv_telReciSrvcYn = true 
            },
            onPopupClose: () => {
              this.$bottomModal.close(popup661)
              // 디바이스백키 활성화
              window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
              this.lv_telReciSrvcYn = false
            },
            onPopupExit: () => {
              this.$bottomModal.close(popup661)
              // 디바이스백키 활성화
              window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
            }
          }
        })
      },      
      /******************************************************************************
       * Function명  : fn_DownloadApk
       * 설명        : APk 다운로드
      ******************************************************************************/
      fn_DownloadApk(){
        let fileNm = ''        
        // this.getSDK = '12'
        if (this.getSDK === '12') {
          fileNm = 'secuPlcy_v12.apk'
        } else if (this.getSDK === '13') {
          fileNm = 'secuPlcy_v13.apk'
        } else if (this.getSDK === '14') {
          fileNm = 'secuPlcy_v14.apk'
        }        
        window.location.href = '/static/secu/' + fileNm
        this.getStore('progress').getters.getState.isShow = true
        setTimeout(() => {
          this.getStore('progress').getters.getState.isShow = false
        }, 5000)
      },
      // /******************************************************************************
      //  * Function명  : fn_DownloadApk
      //  * 설명        : APk 다운로드
      // ******************************************************************************/
      // fn_DownloadApk(){        
      //   let fileNm = ''        
      //   // this.getSDK = '12'
      //   if (this.getSDK === '12') {
      //     fileNm = 'secuPlcy_v12.apk'
      //   } else if (this.getSDK === '13') {
      //     fileNm = 'secuPlcy_v13.apk'
      //   } else if (this.getSDK === '14') {
      //     fileNm = 'secuPlcy_v14.apk'
      //   }

      //   const contentType = 'vnd.android.package-archive'
      //   const path = 'APK_DOWNLOAD_DIR'

      //   let tmpUrl = ''
      //   let downloadUrl = ''
      //   if (process.env.NODE_ENV === 'local') {
      //     tmpUrl = 'http://localhost:1111/apkdown.do?'
      //   } else if(process.env.NODE_ENV === 'development') {                        
      //     tmpUrl = 'https://ap1.t.tss.samsunglife.kr:8410/apkdown.do?'
      //   } else if (process.env.NODE_ENV === 'stage') {
      //     tmpUrl = 'https://vtss.samsunglife.com:8410/apkdown.do?'
      //   }  else if (process.env.NODE_ENV === 'production') {
      //     tmpUrl = 'https://tss.samsunglife.com:8410/apkdown.do?'
      //   }

      //   downloadUrl = tmpUrl + 'path=' + path + '&filename=' + fileNm + '&type=' + contentType
      //   // console.log(downloadUrl)
      //   // window.open(downloadUrl, '_blank')
      //   window.location.href = downloadUrl
      //   this.getStore('progress').getters.getState.isShow = true
      //   setTimeout(() => {
      //     this.getStore('confirm').dispatch('ADD', '보안정책 APK 다운로드 하였습니다. 클릭하여, 설치해주세요.')
      //     this.getStore('progress').getters.getState.isShow = false
      //   }, 5000)

        
      // },
    /*********************************************************
     * Function명: fn_ConfigProcss
     * 설명: 전화받기서비스 컨피그 확인
     *********************************************************/
    fn_ConfigProcss () {
      console.log('fn_ConfigProcss')
      const lv_Vm = this
      let pParams = {
        envId: 'PILOT_SRVC_YN'
      }
      const trnstId = 'txTSSAD02S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(response => {
          if (response.body !== null) {          
            lv_Vm.config = response.body.returnDtlList[0].envCntnt
            lv_Vm.fn_onlyPhoneAOS(response.body.returnDtlList[0].envCntnt)
          }
        })
        .catch( (error) => {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명 : fn_OpenTutoPopUp
      * Return     : 튜토리얼 팝업
      ******************************************************************************/
      fn_OpenTutoPopUp() {
        const popup662 = this.$bottomModal.open(MSPBC662P, {        
          properties: {
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(popup662)
                // 디바이스백키 활성화
                window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
                this.lv_telReciSrvcYn = true 
            },
            onPopupClose: () => {
              this.$bottomModal.close(popup662)
              // 디바이스백키 활성화
              window.fdpbridge.exec('callCustInfoStopPlugin', {value: true}, () => {}, () => {})
              this.lv_telReciSrvcYn = false
            },
            onPopupExit: () => {
              this.$bottomModal.close(popup662)
              // 디바이스백키 활성화
              window.fdpbridge.exec('backkeyCtrlPlugin', {value: true}, () => {}, () => {})
            }
          }
        })
      },  
    }
  };
</script>
<style scoped>
</style>