-/*
 * 업무구분: 이달의업적
 * 화 면 명: MSPAP100M
 * 화면설명: 이달의업적
 * 접근권한: 
 * 작 성 일: 최준석
 * 작 성 자: 2022.12.23
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="이달의 업적" :topButton="true" type="subpage" @on-header-left-click="fn_goMain">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_goMain" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '이달의 업적' : '이달의 업적'}}</div>
              <div class="titleBarCustomer">{{lv_FofCnslt.label}}</div>
              <div class="titleBarCustomerID">{{lv_FofCnslt.key}}</div>
              <div slot="action" @click="fn_OpenMSPAP106P" v-if="isManager"><mo-icon>search</mo-icon></div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt25">
              <div class="chart-area fexTy3">
                <div class="chart-info">
                  <div class="chart-info-li" v-if="isManager && lv_FofCnslt.key != '0' && lv_FofCnslt.key != ''"> <!-- 지점장이상 컨설턴트 -->
                    <span class="name txtSkip fs18rem" @click="fn_OpenCnstCard">{{lv_FofCnslt.label}}</span>
                    <span class="fs14rem">컨설턴트</span>
                  </div>
                  <div class="chart-info-li">
                    <span class="fwb">{{rsltMonth}}월 활동 실적</span>
                  </div>
                  <div class="chart-info-li">
                    <span class="fs14rem" v-if="isOverPerfomance">정예화 달성 후</span>
                    <span class="fs14rem" v-else>정예화 달성까지</span>
                  </div>
                  <div class="chart-info-li">
                    <span class="fs20rem fwb"><b class="fs28rem">{{$bizUtil.numberWithCommas(smultRslt)}}</b>원</span>
                  </div>
                  <div class="chart-info-li">
                    <span class="fs14rem" v-if="isOverPerfomance">초과 달성되었습니다.</span>
                    <span class="fs14rem" v-else>남았습니다.</span>
                  </div>
                </div>
                <div class="chart-box ml20">
                  <div class="chart-img"> <!-- 차트영역 -->
                    <div id="chartPie" style="position: relative; width: 150px; height: 150px; margin: auto; background: #F5F7FA;"></div>
                  </div>
                  <span>{{actualRate}}% {{actualGoal}}</span>
                </div>
              </div>

              <!-- expandable start : 문제점:열린상태에서 스크롤시 동작이상  -->
              <msp-expand class="detail-bar-acodian" btn-area-class="detail-bar-btn" expanded @expanded="$refs.headerview.adjustHeight()">
                <template #content>
                  <div class="detail-con">
                    <div class="detail-con-li fexTy3">
                      <li><span>나의 Club</span></li>
                      <!-- <li><b>{{$bizUtil.replaceAll(aPThmmActActulSVO.chnlQulfNm, '만클럽', '')}}</b><span>만 Club</span></li> -->
                      <li><b>{{aPThmmActActulSVO.chnlQulfNm}}</b></li>
                    </div>
                    <div class="detail-con-li fexTy3">
                      <li><span>계획</span></li>
                      <li><b>{{$bizUtil.numberWithCommas(String($bizUtil.isEmpty(virtualSum) ? '0' : virtualSum))}}</b><span>원</span></li>
                    </div>
                    <div class="detail-con-li fexTy3">
                      <li><span>환산성적</span></li>
                      <li><b>{{$bizUtil.numberWithCommas(aPThmmActActulSVO.cnvlPrfm)}}</b><span>원</span></li>
                    </div>
                    <div class="detail-con-li fexTy3">
                      <li class="fexTy3">
                        <span>적용유지율</span>
                        <!-- <span class="ns-tool-tip ml10">
                          <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>
                          <mo-tooltip target-id="location0" class="tool-tip-box">
                            산출유지율
                            <span class="ml10">{{aPThmmActActulSVO.ukepRat213B}}%</span><br>
                            구제적용유지율
                            <span class="ml10">{{aPThmmActActulSVO.ukepRat213A}}%</span>
                          </mo-tooltip>                  
                        </span>   -->
                      </li>
                      <li><b>{{aPThmmActActulSVO.ukepRat213}}</b><span>%</span></li>
                    </div>
                    <div class="detail-con-li fexTy3">
                      <li><span>유효성적Ⅰ</span></li>
                      <li><b>{{$bizUtil.numberWithCommas(aPThmmActActulSVO.avlPrfmAmt1)}}</b><span>원</span></li>
                    </div>
                    <div class="detail-con-li fexTy3">
                      <li><span>유효성적Ⅱ</span></li>
                      <li><b>{{$bizUtil.numberWithCommas(aPThmmActActulSVO.avlPrfmAmt2)}}</b><span>원</span></li>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <div class="detail-bar-btn">
                    <div class="btn open-btn">
                      <span>자세히</span><mo-button class="link-arrow down"></mo-button>
                    </div>
                    <div class="btn close-btn">
                      <span>접기</span><mo-button class="link-arrow deg--90 mb4"></mo-button>
                    </div>
                  </div>
                </template>
              </msp-expand>
              <!-- expandable end -->      
              
            </ur-box-container>
          </template>
              

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt10 mb10">
              <mo-segment-wrapper solid primary v-model="tabButton">
                <mo-segment-button value="1">이달의 계약</mo-segment-button>
                <mo-segment-button value="2">최근 설계</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area" v-if="tabButton == '1'">
              <template v-if="currentContractList.length > 0">
                <div class="con-area fs14rem crTy-bk7 mt20">
                  <span class="must"></span><span class="ml2">이달 계약건 중 <b>심사완료</b> 건 표시</span>
                </div>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="msp-card-list">
                  <mo-list :list-data="currentContractList">
                    <template #list-item="{item, index}">
                      <mo-list-item :class="{'blue' : !item.isVirtualcontract}">
                        <div class="list-item__contents">
                          <div class="list-item__contents__title fexTy3" tabindex="-1" :ref="'refCurrent'+index">
                            <span class="name txtSkip fs17rem">{{item.prdtNm}}</span>
                            <mo-icon icon="msp-minus-round" @click="remove(item)" v-if="item.isVirtualcontract"></mo-icon>
                          </div>
                          <div class="list-item__contents__info row mt10">
                            <span class="full fs16rem">{{item.custNm}}</span>
                          </div>
                          <div class="list-item__contents__info mt10 status fexTy3">
                            <span class="badge-box sm">{{item.isVirtualcontract ? '계획' : '체결'}}</span>
                            <span class="right">환산TP<b class="count">{{$bizUtil.numberWithCommas(item.cnvlPrfm)}}</b>원</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>
              </template>
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray"><p class="mt20">이달에 체결하신 신계약이 없습니다.</p></span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area" v-else>
              <template v-if="virtualContractList.length > 0">
                <div class="con-area fs14rem crTy-bk7 mt20 fexTy3 ">
                  <span><span class="must"></span><span class="ml2">전일자 기준</span></span>
                  <mo-button class="ns-btn-round h28 blackW mwauto" v-if="mngSearch" @click="fn_MoveMSPPI001M">가입설계 시작</mo-button>
                </div>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="msp-card-list">
                  <mo-list-item class="gray-block">
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="fs14rem indent15 fwn crTy-bk7">※ 이 달에 달성이 예상되는 설계 상품을 추가해서  예상 실적을 확인하세요</span>
                      </div>
                    </div>
                  </mo-list-item>
                  <mo-list :list-data="virtualContractList">
                    <template #list-item="{item, index}">
                      <mo-list-item :class="{'blue' : !item.isVirtualcontract}">
                        <div class="list-item__contents">
                          <div class="list-item__contents__title fexTy3" tabindex="-1" :ref="'refVirtualt'+index">
                            <span class="name txtSkip fs17rem">{{item.prdtNm}}</span>
                            <mo-icon icon="msp-plus-round" @click="fn_MoveEntpl(item)" v-if="item.isVirtualcontract"></mo-icon>
                          </div>
                          <div class="list-item__contents__info row mt10">
                            <span class="full fs16rem">{{item.custNm}}</span>
                          </div>
                          <div class="list-item__contents__info mt10 status fexTy3">
                            <span class="badge-box sm">{{item.isVirtualcontract ? '계획' : '체결'}}</span>
                            <span class="right">환산TP<b class="count">{{$bizUtil.numberWithCommas(item.cnvlPrfm)}}</b>원</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>
              </template>
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray">
                          <p class="mt20">최근에 설계한 상품이 없습니다<br/>새로운 설계를 추가해 보세요</p>
                          <mo-button class="no-data-btn" @click="fn_MoveMSPPI001M">가입설계 시작</mo-button>
                        </span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
            </ur-box-container>
          </template>

        </mo-collapsing-header-view>

      </div>
    </ur-box-container>
  </ur-page-container>
  
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'

  require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
  require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js')
  require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
  require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP100M",
    screenId: "MSPAP100M",
    components: {
      MspBottomSelect,
      MSPAP106P
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        popupAP106: null,
        tabButton:'1',
        cnsltNo: '',
        isManager: false, // 관리자여부
        isSelectUser: false, // 관리자일때 FC 선택여부
        isOverPerfomance: false, // 실적 초과
        isLoadingMng: false, // 초기 LOAD 시 스크롤에서 차트를 중복 생성하는걸 brocking 하기 위한 변수
        isSimulation: false,
        currentContractList: [], // 이달의계약 + 시뮬레이션
        saveCurrentContractList: [], // 이달의 계약 
        virtualContractList: [], // 가입설계
        lv_FofCnslt: {key: '', label: ''}, // 선택컨설턴트
        lv_IsFirst: true,
        // Graph 영역
        actualRate: 0,
        actualGoal: '달성',
        pChartData: [],
        pChartData1: [{'Title': '실적', 'Profit': 0},
          {'Title': '추가', 'Profit': 0},
          {'Title': '부족', 'Profit': 0}],
        pChartData2: [{'Title': '실적', 'Profit': 0},
          {'Title': '초과', 'Profit': 0}],
        firstColor: '',
        secondColor: '',
        thirdColor: '',
        firstAlpha: '',
        secondAlpha: '',
        thirdAlpha: '',
        // Simulation
        aPThmmActActulSVO: {
          chnlQulfCd: '',
          chnlQulfNm: '',
          qulfClbyStndAmt: '',
          cnvlPrfm: '',
          avlPrfmAmt1: '',
          avlPrfmAmt2: '',
          ukepRat213: '',
          ukepRat213A: '',
          ukepRat213B: ''
        },
        smultRslt: '0', // simulation Result ( 차액 )
        smultInitRslt: '0', // 최초 부족금액
        smultCnvlPrfm: '0', // 실적금액
        virtualSum: '0',
        rsltMonth: ''      
      }
    },
    /************************************************************************************************
    * Lyfe Cycle 함수 정의 영역
    ************************************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)

      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }

    },
    mounted () {

      this.$bizUtil.insSrnLog(this.$options.screenId)

      console.log( '로그인 사용자정보 -------------')
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
      console.log( this.getStore('userInfo').getters.getUserInfo.srvcMngScCd )
      console.log( '로그인 사용자정보 -------------')
      
      this.rsltMonth = Number(moment(new Date().toISOString()).format('MM')) // 실적 월 
      this.userChnl = this.getStore('userInfo').getters.getUserInfo.srvcMngScCd

      // FC(FC), SFC(FC), WM(SA), GFC(GP), AFC(AM)가 아닌 경우에는 메시지 처리 후 종료
      // if ( 'FC,GP,SA,AM'.indexOf(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd) === -1  ) {
      if ( 'BK,DI,DS,FS,GA,JK'.indexOf(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd) > -1  ) {   
          this.getStore('confirm').dispatch('ADD', 'GA/신채널, 금융서비스 등에서는 해당 서비스를 이용하실 수 없습니다. [' + this.getStore('userInfo').getters.getUserInfo.srvcMngScCd + ']' )
          this.$router.go(-1)        
      }    

      if ( !this.isManager ) {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
        this.fn_GetInitialInform()
      } else {
        let tmp = this.$route.params
        if(!this.$bizUtil.isEmpty(tmp.pData)) {
          this.lv_FofCnslt = tmp.pData
          this.cnsltNo     = tmp.pData.key
          this.fn_GetInitialInform()
        } else {
          if(this.lv_IsFirst){
            this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
              properties: {
                single: true,
                title: "",
                content: '컨설턴트를 먼저 선택해 주세요.',
                title_pos_btn: "확인"
              },
              listeners: {
                onPopupConfirm: () => {
                  console.log('onPopupConfirm call')
                  this.$bottomModal.close(this.lv_AlertPop)
                  this.fn_OpenMSPAP106P()
                },
                onPopupCancel: () => {
                  console.log('onPopupCancel call')
                  this.$bottomModal.close(this.lv_AlertPop)
                  this.fn_OpenMSPAP106P()
                },
                onPopupClose: () => {
                  console.log('onPopupClose call')
                  this.$bottomModal.close(this.lv_AlertPop)
                  this.fn_OpenMSPAP106P()
                }
              }
            })
          }
        }
      }

    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    computed: {
      mngSearch () {
        if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
          return false
        } else {
          return true
        }
      }
    },
    watch: {
      tabButton(v) {
        let lv_vm = this
        if(v === '1'){
          lv_vm.$nextTick(() => {
            setTimeout(() => {
              if(lv_vm.$refs['refCurrent1']){
                lv_vm.$refs['refCurrent1'].focus()
              }else{
                if(lv_vm.$refs['refCurrent0']) lv_vm.$refs['refCurrent0'].focus()
              }
            }, 300)
          })
        }else{
          lv_vm.$nextTick(() => {
            setTimeout(() => {
              if(lv_vm.$refs['refVirtualt1']){
                lv_vm.$refs['refVirtualt1'].focus()
              }else{
                if(lv_vm.$refs['refVirtualt0']) lv_vm.$refs['refVirtualt0'].focus()
              }
            }, 300)
          })
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_ResetData
       * 설명       : 데이터를 초기화한다.
      ******************************************************************************/        
      fn_InitInfo (param) {

        if ( param === 'FC' ) {
          this.currentContractList = []
          this.saveCurrentContractList = []
          this.virtualContractList = []
          this.aPThmmActActulSVO.chnlQulfCd =''
          this.aPThmmActActulSVO.chnlQulfNm = ''
          this.aPThmmActActulSVO.qulfClbyStndAmt = '0'
          this.aPThmmActActulSVO.qulfClbyStndAmt = '0'
          this.aPThmmActActulSVO.cnvlPrfm = '0'
          this.aPThmmActActulSVO.avlPrfmAmt1 = '0'
          this.aPThmmActActulSVO.avlPrfmAmt2 = '0'
          this.aPThmmActActulSVO.ukepRat213 = '0'
          this.aPThmmActActulSVO.ukepRat213A = '0'
          this.aPThmmActActulSVO.ukepRat213B = '0'
          this.smultRslt = '0'
          this.smultInitRslt = '0'
          this.smultCnvlPrfm = '0'
        } else {
          this.currentContractList = []
          this.currentContractList = this.saveCurrentContractList
        }
      },
      /******************************************************************************
        * Function명 : fn_MoveMSPPI001M
        * 설명       : 가입설계 Main 화면으로 이동한다.
        *
      ******************************************************************************/
      fn_MoveMSPPI001M () {
        this.$router.push({ name: 'MSPPI001M' })
      },
      /******************************************************************************
        * Function명 : fn_MoveEntpl
        * 설명       : 클릭 시 가입설계건을 이달의 계약으로 반영하고 가입설계에서는 제외
      ******************************************************************************/         
      fn_MoveEntpl (item) {
        const list = this.virtualContractList

        if (list.includes(item)) {
          this.currentContractList.unshift(item)
          this.virtualContractList.splice(list.indexOf(item), 1)

          this.fn_Simaulation('CALC')

          this.$addSnackbar('이달의 계약 목록으로 이동하였습니다.')
        }
      }, 
      /******************************************************************************
       * Function명 : fn_search
       * 설명       : 지점관리자가 설계사를 선택하였을때 조회 처리
       *              - 선택한 FC의 이번달 실적 조회
      ******************************************************************************/
      fn_search () {

        if ( this.lv_FofCnslt.key === '' || this.lv_FofCnslt.key === '0') {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        }

        this.isSelectUser = true
        this.cnsltNo = this.lv_FofCnslt.key

        this.fn_GetInitialInform()
      },    
      /******************************************************************************
       * Function명 : fn_GetInitialInform
       * 설명       : FC의 현재 활동성과를 조회한다.
      ******************************************************************************/
      fn_GetInitialInform () {
        console.log('============= 조회 ===============')
        let curDate = this.$bizUtil.getDateToString(new Date(), '')
        let firstDate = this.$bizUtil.getFirstDayOfNowMonth('Number') + ''
        if(curDate == firstDate){
          this.$bizUtil.ctUtils.fn_alert(this, {content: '이달의 업적은 매월 2일부터 조회 가능합니다.', title_pos_btn: '확인'})
        }

        var lv_Vm = this
        var trnstId = 'txTSSAP00S9'

        var pParam = {
          cnsltNo: lv_Vm.cnsltNo,
          strYmd: moment(moment().subtract(30, 'day')).format('YYYYMMDD'),
          endYmd: curDate
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          console.log('==========================================================')
          console.log(response)
          console.log('==========================================================')
          if ( response.body != null) {
            // 현재 활동성과 조회
            if ( response.body.rsltCd === 'S') {
              lv_Vm.aPThmmActActulSVO.chnlQulfCd = response.body.chnlQulfCd

              // AFC 이면서 무급일 경우 컨설턴트로 표기 ( 정지웅 요청 : 2019.02.25 )
              if ( (lv_Vm.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' || lv_Vm.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'FS' ) && response.body.chnlQulfNm === '무급') {
                lv_Vm.aPThmmActActulSVO.chnlQulfNm = '컨설턴트'
              } else {
                lv_Vm.aPThmmActActulSVO.chnlQulfNm = response.body.chnlQulfNm
              }
              
              lv_Vm.aPThmmActActulSVO.qulfClbyStndAmt = response.body.qulfClbyStndAmt
              lv_Vm.aPThmmActActulSVO.cnvlPrfm = response.body.cnvlPrfm 
              lv_Vm.aPThmmActActulSVO.avlPrfmAmt1 = response.body.avlPrfmAmt1
              lv_Vm.aPThmmActActulSVO.avlPrfmAmt2 = response.body.avlPrfmAmt2
              lv_Vm.aPThmmActActulSVO.ukepRat213 = Math.trunc(response.body.ukepRat213) === 999 ? 100 : Math.trunc(response.body.ukepRat213)
              lv_Vm.aPThmmActActulSVO.ukepRat213A = Math.trunc(response.body.ukepRat213A) === 999 ? 100 : Math.trunc(response.body.ukepRat213A)
              lv_Vm.aPThmmActActulSVO.ukepRat213B = Math.trunc(response.body.ukepRat213B) === 999 ? 100 : Math.trunc(response.body.ukepRat213B)

              console.log('== lv_Vm.aPThmmActActulSVO >> ', lv_Vm.aPThmmActActulSVO)

              // 이달의 계약
              if ( response.body.contInfoSVOList != null) {
                for (let j = 0; j < response.body.contInfoSVOList.length; j++) {
                  let tmpObj = {}
                  tmpObj.isVirtualcontract = false
                  tmpObj.prdtNm = response.body.contInfoSVOList[j].prdtNm
                  tmpObj.custNm = response.body.contInfoSVOList[j].custNm
                  tmpObj.cnvlPrfm = response.body.contInfoSVOList[j].cnvlPrfm

                  lv_Vm.currentContractList.push(tmpObj)
                }
                lv_Vm.saveCurrentContractList = lv_Vm.currentContractList
              }

              // 가입설계
              if ( response.body.aPEntplSVOList != null) {
                for (let j = 0; j < response.body.aPEntplSVOList.length; j++) {
                  let tmpObj = {}
                  tmpObj.isVirtualcontract = true
                  tmpObj.prdtNm = response.body.aPEntplSVOList[j].prdtNm
                  tmpObj.custNm = response.body.aPEntplSVOList[j].contrNm
                  tmpObj.cnvlPrfm = response.body.aPEntplSVOList[j].cnvlPrfm
                  tmpObj.planId = response.body.aPEntplSVOList[j].planId

                  lv_Vm.virtualContractList.push(tmpObj)
                }
              }            

              lv_Vm.fn_Simaulation('INIT') // 초기 정보 설정

            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.body.rsltMsg)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명 : fn_Simaulation
       * 설명       : FC의 당월 신계약(심사완료) 건을 조회한다.
      ******************************************************************************/
      fn_Simaulation (param ) {

        this.virtualSum = 0

        if ( param === 'INIT' ) {
          this.smultRslt = Number(this.aPThmmActActulSVO.qulfClbyStndAmt) - Number(this.aPThmmActActulSVO.avlPrfmAmt1)
          this.smultInitRslt = this.smultRslt
          this.smultCnvlPrfm = this.aPThmmActActulSVO.avlPrfmAmt1

        } else {
          for (let j = 0; j < this.currentContractList.length; j++) {
            if ( this.currentContractList[j].isVirtualcontract ) {
              this.virtualSum = Number(this.virtualSum) + Math.trunc(Number(this.currentContractList[j].cnvlPrfm) * Number( this.aPThmmActActulSVO.ukepRat213 ) / 100 ) 
            }
          }
          this.smultRslt = Number(this.smultInitRslt) - Number(this.virtualSum)
          this.smultCnvlPrfm = Number(this.aPThmmActActulSVO.avlPrfmAmt1) + Number(this.virtualSum)
        }

        if ( Number(this.smultRslt) >= 0 ) {
          this.isOverPerfomance = false
        } else {
          this.isOverPerfomance = true
          this.smultRslt = Math.abs(this.smultRslt)
        }

        this.fn_makeChartData()
      },
      fn_makeChartData () {

        let addAmount = Number(this.smultCnvlPrfm) - Number(this.aPThmmActActulSVO.avlPrfmAmt1)

        console.log('달성율      :' + this.smultRslt       )
        console.log('실적        :' + this.aPThmmActActulSVO.avlPrfmAmt1       )
        console.log('차상위목표  :' + this.aPThmmActActulSVO.qulfClbyStndAmt )
        console.log('추가실적    :' + addAmount)

        if ( Number(this.aPThmmActActulSVO.qulfClbyStndAmt) === 0 ) {
          console.log(0)
          this.pChartData1[0].Profit = 100
          this.pChartData1[1].Profit = 0
          this.pChartData1[2].Profit = 0
          this.firstColor = '0x8781FF'
          this.firstAlpha = '0.3'
          this.actualGoal = '달성'
          this.pChartData = this.pChartData1
          this.actualRate = 0
        } else {

          // 그래프의 입력값이 0 이면 그 배열은 무시됨.(컬러 설정 시 주의)
          if ( ! this.isOverPerfomance ) {
              // 실적 달성율 = ( 실적 / 목표금액 ) * 100
              this.pChartData1[0].Profit = Math.round((Number(this.aPThmmActActulSVO.avlPrfmAmt1) / Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) * 100 ) //실적  
              this.pChartData1[1].Profit = Math.round(Number(addAmount) * 100 / Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) // 추가
              this.pChartData1[2].Profit = 100 - Math.round((Number(this.smultCnvlPrfm) / Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) * 100 ) // 부족

              console.log('this.pChartData1[0].Profit : ' + this.pChartData1[0].Profit )
              console.log('this.pChartData1[1].Profit : ' + this.pChartData1[1].Profit )
              console.log('this.pChartData1[2].Profit : ' + this.pChartData1[2].Profit )

              // this.firstColor = '0x8781FF'
              this.firstColor = '0x416DFF'
              this.firstAlpha = '1' 
              this.secondColor = '0x24C2C6'
              
              if ( this.pChartData1[1].Profit === 0 ) {
                console.log(1)
                this.isSimulation = false
                // this.secondColor = '0x8781FF'
                // this.thirdColor = '0x24C2C6'
                // this.secondColor = '0x24C2C6'
                // this.thirdColor = '0x416DFF'
                if ( this.pChartData1[0].Profit === 0 ) {
                  this.firstAlpha = '0.3'
                } else {
                  this.firstAlpha = '1'
                }
                this.secondAlpha = '0.3'
                this.thirdAlpha = '1'
              } else {
                this.isSimulation = true
                if ( this.pChartData1[0].Profit === 0 ) {
                  console.log(2)
                  // this.firstColor = '0x24C2C6'
                  // this.secondColor = '0x8781FF'
                  // this.firstColor = '0x416DFF'
                  // this.secondColor = '0x24C2C6'
                  this.secondAlpha = '0.3'
                } else {
                  console.log(3)
                  // this.secondColor = '0x24C2C6'
                  // this.thirdColor = '0x8781FF'
                  // this.secondColor = '0x416DFF'
                  this.thirdColor = '0x24C2C6'
                  this.secondAlpha = '1'
                  this.thirdAlpha = '0.3'
                }
              }
              this.pChartData = this.pChartData1
              this.actualRate = Number(this.pChartData1[0].Profit) + Number(this.pChartData1[1].Profit)
              this.actualGoal = '달성'
            } else {
              console.log(4)
              // 초과 달성율 = (( 실적 - 목표금액 ) / 목표금액 ) * 100
              this.pChartData2[0].Profit = Math.round((( Number(this.smultCnvlPrfm) - Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) / Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) * 100) // 초과
              this.pChartData2[1].Profit = 100 - Math.round((( Number(this.smultCnvlPrfm) - Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) / Number(this.aPThmmActActulSVO.qulfClbyStndAmt)) * 100) // 실적 
              this.pChartData = this.pChartData2
              // this.firstColor = '0x24C2C6'
              // this.secondColor = '0x8781FF'
              this.firstColor = '0x24C2C6'
              this.secondColor = '0x24C2C6'
              this.firstAlpha = '1'
              this.secondAlpha = '0.3'
              this.actualRate = this.pChartData2[0].Profit
              this.actualGoal = '초과'
            }
        }

        this.fn_makeChartCreate ()
        this.isLoadingMng = true
      },
      fn_makeChartCreate () {
        chartData = this.pChartData

        // labelPosition : inside, none, outside, insideWithCallout
        // alpha ? 컬러를 투명도 조절 ( 1 : 원색 ~ 0.1 단위로 조정 )

        layoutStr =`<rMateChart backgroundColor="#FFFFFF"  borderStyle="none"> 
                      <NumberFormatter id="fmt"/> 
                      <Pie2DChart innerRadius = "0.7" showDataTips="false" explodable="false" fontSize="25" >
                        <series>
                          <Pie2DSeries field="Profit" labelPosition="none" color="#FFFFFF" startAngle="270" >
                            <showDataEffect>
                              <SeriesZoom duration="1000" />
                            </showDataEffect>
                            <fills>
                              <SolidColor color="` + this.firstColor + `" alpha="` + this.firstAlpha + `" />
                              <SolidColor color="` + this.secondColor + `" alpha="` + this.secondAlpha + `" />
                              <SolidColor color="` + this.thirdColor + `" alpha="` + this.thirdAlpha + `" />
                            </fills>
                          </Pie2DSeries>
                        </series>
                      </Pie2DChart>
                    </rMateChart>`

        rMateChartH5.create('TSSAP100M_1', 'chartPie', 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
        rMateChartH5.registerTheme(rMateChartH5.themes)
      },
      remove (item) {
        const list = this.currentContractList
        if (list.includes(item)) {
          this.virtualContractList.unshift(item)
          this.currentContractList.splice(list.indexOf(item), 1)

          this.fn_Simaulation('CALC')

          this.$addSnackbar('최근 설계 목록으로 이동하였습니다.')
        }
      },
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }
        else {
          if(this.$route.params.pScrId === 'MSPBC003M') {
            if(!this.mngSearch) {
              // 화면 이동 시, 검색사번 유지
              this.getStore('bcStore').dispatch('FC_DATA', this.lv_FofCnslt)
            }
          }
          this.$router.go(-1)
        }
      },  
      fn_OpenMSPAP106P(){
        const lp_Vm = this  
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP100M',
            pData: this.lv_FofCnslt
          },
          listeners: {
            onPopupSearch: (pData) => {
              console.log('onPopupSearch >> ' , pData)
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              if(pData != null && pData != undefined){
                lp_Vm.lv_FofCnslt = pData
                if ( lp_Vm.lv_FofCnslt.key !== '0') {   
                  if ( lp_Vm.cnsltNo !== lp_Vm.lv_FofCnslt.key ) {
                    lp_Vm.fn_InitInfo('FC')
                  }
                  lp_Vm.cnsltNo = lp_Vm.lv_FofCnslt.key           
                }
              }

              lp_Vm.fn_search()
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },
      fn_OpenCnstCard() {
        this.popupAP511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: this.lv_FofCnslt.label,
            pCnsltNo: this.lv_FofCnslt.key,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popupAP511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popupAP511)
            }
          }
        })
      }
    }
  }
</script>
<style scoped>
</style>