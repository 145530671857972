-/*
 * 업무구분: 조직명장수수료 소득관리
 * 화 면 명: MSPAP070M
 * 화면설명: 조직명장수수료 소득관리
 * 접근권한:
 * 작 성 일: 2023.04
 * 작 성 자: 정지윤
 */
<template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="조직명장수수료 소득관리" :topButton="true" :action-type="isManager ? 'search' : 'subpage'" @on-scroll-bottom="fn_moreData">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClickBtnPrevious()" />
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '조직명장수수료 소득관리' : '조직명장수수료 소득관리'}}</div>
              <div slot="action" v-if="isManager" @click="fn_OpenMSPAP106P"><mo-icon>search</mo-icon></div> <!-- 지점장이상 진입 시에만 노출 -->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
             <!-- v-if="consultant === 'Y'" 컨설턴트 선택 후, 지점장이상 진입 시에만 노출-->
            <ur-box-container  v-if="isManager" alignV="start" componentid="" direction="column" class="con-area mt15 mb20">
              <div class="sub-text">  
                <div v-if="isBMngr" class="info-li"> 
                  <span class="fs18rem fwb mr6" @click="fn_Open511Pop(lv_FofCnslt.label, lv_FofCnslt.key)">{{lv_FofCnslt.label}}</span> <!-- 지점장만 노출 --> <!-- class에 txtSkip 추가하면 '컨설턴트'가 밑줄로 넘어감-->
                  <span class="fs17rem">컨설턴트</span>
                </div>
                <div class="info-li"> 
                  <span class="mr6 fs16rem">{{lv_DofOrg.label}}</span>  
                  <span class="fs16rem">{{lv_FofOrg.label}}</span>
                </div>
                <div class="info-li"> 
                  <span><span class="mr6 fs16rem">최초위촉차월</span><span class="fs16rem">{{FstLpmfc}}</span></span><em>|</em>
                  <span><span class="mr6 fs16rem">직책</span><span class="fs16rem">{{duty}}</span></span>
                </div>
              </div>
              <div v-if="isManager" class="gray-bar full48 mt20" />
            </ur-box-container>
             <!-- v-if="consultant === 'Y'" 컨설턴트 선택 후 end -->
            
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area row-box mt20 mb20">  <!-- 레이블 과 한줄로 -->
              <span class="label-title w100px">마감년월</span>
              <mo-month-picker class="full" v-model="finYm" :max-date="maxFinYm"/>
            </ur-box-container>

          </template>
          <!-- expandable end -->          

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt10 mb10">
              <mo-segment-wrapper solid primary v-model="tab_index">
                <mo-segment-button value="1">수수료</mo-segment-button>
                <mo-segment-button value="2">신인업적분</mo-segment-button>
                <mo-segment-button value="3">2대계보분</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
            <!-- 총 -->
            <ur-box-container v-if="tab_index === '2' || tab_index === '3'" alignV="start" componentid="" direction="column" class="ns-check-sum mt20" >
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum" v-if="tab_index === '2'">총<span class="count">{{nwmanAchTabData.length > 0 ? nwmanAchTabData.length: '0'}}</span>명</span>
                    <span class="sum" v-if="tab_index === '3'">총<span class="count">{{etcTabData.length}}</span>명</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 총 end -->
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area"> 
              <!-- no-data --> 
              <ur-box-container v-if="(isManager && !isSelectUser)" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data"> <!-- v-if= || (tab_index==='2'&&nwmanAchTabData.length===0) || (tab_index==='3'&&etcTabData.length===0) -->
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info small-space">
                      <span class="ns-ftcr-gray"><p class="mt10">상세조회 조건을 설정해 주세요</p></span>
                    </div>
                  </div>                
                </mo-list-item>
              </ur-box-container>
              <!-- no-data end -->

              <!-- 수수료 Tab start -->
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt20" v-if="tab_index === '1'" >
                <div class="title-block">
                  <div class="fs16rem"><span>조직명장 예상수수료 (환수금액 미반영)</span></div>
                  <div class="fwb"><span v-if="comsTabData.exptComs" class="fs30rem mr6">{{ $commonUtil.numberWithCommas(comsTabData.exptComs) }}</span><span class="fs24rem">원</span></div>
                </div>
                <div class="gray-bar-block full mt20 fs16rem">
                  <div class="gray-bar-li fexTy3"><span>등급</span><span><b class="mr4">{{ comsTabData.grade }}</b></span></div>
                  <div class="gray-bar-li fexTy3"><span>도입분</span><span><b class="mr4">{{ comsTabData.recruiting ? $commonUtil.numberWithCommas(comsTabData.recruiting) : '0' }}</b>원</span></div>
                  <div class="gray-bar-li fexTy3"><span>신인비례분</span><span><b class="mr4">{{ comsTabData.rookie? $commonUtil.numberWithCommas(comsTabData.rookie) : '0' }}</b>원</span></div>
                  <div class="gray-bar-li fexTy3"><span>2대계보분</span><span><b class="mr4">{{ comsTabData.genaration2? $commonUtil.numberWithCommas(comsTabData.genaration2) : '0' }}</b>원</span></div>
                  <div class="gray-bar-li fexTy3"><span>본인분</span><span><b class="mr4">{{ comsTabData.self? $commonUtil.numberWithCommas(comsTabData.self) : '0' }}</b>원</span></div>
                  <div class="gray-bar-li fexTy3"><span>경력육성분</span><span><b class="mr4">{{ comsTabData.carrSucoa? $commonUtil.numberWithCommas(comsTabData.carrSucoa) : '0' }}</b>원</span></div>
                  <div class="gray-bar-li fexTy3"><span>배출축하금</span><span><b class="mr4">{{ comsTabData.celebrate? $commonUtil.numberWithCommas(comsTabData.celebrate) : '0' }}</b>원</span></div>
                </div>
                <div class="mt50"><span class="fwb fs18rem">기초 DATA</span></div>
                <div class="gray-bar-block full mt10 fs16rem">
                  <div class="gray-bar-li fexTy3"><span>도입인원</span><b>{{ comsTabData.pRecruiting ? comsTabData.pRecruiting : '0' }}</b></div>
                  <div class="gray-bar-li fexTy3"><span>마감월 위촉인원</span><b>{{ comsTabData.cmsnAgent ? comsTabData.cmsnAgent : '0' }}</b></div>
                  <div class="gray-bar-li fexTy3"><span>동일채널 잔존인원</span><b>{{ comsTabData.suvlAgent ? comsTabData.suvlAgent : '0' }}</b></div>
                  <div class="gray-bar-li fexTy3"><span>환산성적</span><b>{{ comsTabData.cnvlPrfm? $commonUtil.numberWithCommas(comsTabData.cnvlPrfm) : '0' }}</b></div>
                  <div class="gray-bar-li fexTy3"><span>도입기간 내 신인 중 <br/>환산 30만 이상 인원</span><b>{{ comsTabData.cnvlAmt30up ? comsTabData.cnvlAmt30up : '0' }}</b></div>
                  <div class="gray-bar-li fexTy3"><span>당월포함 3개월 내 <br/>환산 30만 미만 여부</span><b :class="comsTabData.cnvlAmt30down=='N'?'fwb crTy-orange2':'fwb crTy-blue3'">{{ comsTabData.cnvlAmt30down ? comsTabData.cnvlAmt30down : 'N' }}</b></div><!-- N일때 crTy-blue3 -->
                </div>

                <ul class="terms-list-area mt30 crTy-bk7 fs14rem">
                  <li>소득관리를 위한 예측 Data로 실제 지급금액과 다를 수 있습니다. (환수금액 미반영)</li>
                  <li>직전 24개월내 도입신인 중 환산성적 30만 이상, 1명 이상 시 본인분 발생</li>
                  <li>3개월 연속 조직명장 본인 환산성적 30만 미만 시 해당월 조직명장수수료 전체 부지급</li>
                  <li>도입인원은 조직명장 등급에 계상되는 신인재적으로 산출 세부기준은 해당년도 보험영업지침을 참고하세요.</li>
                </ul>
              </ur-box-container>
              <!-- 수수료 Tab end -->

              <!-- 신인업적분 Tab start -->
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024" v-if="tab_index === '2'">
                <mo-list :list-data="nwmanAchTabData">
                  <template #list-item="{item}">
                    <mo-list-item expandable >
                      <div class="list-item__contents">
                        <div class="list-item__contents__title mb0">
                          <span class="name txtSkip fs19rem" @click="fn_Open511Pop(item.name, item.fcCode)">{{item.name}}({{item.fcCode}})</span>
                        </div>
                        <div class="list-item__contents__info mt10">
                          <span class="fwb"><span class="crTy-blue3 mr4">{{item.nwmanAmt ? $commonUtil.numberWithCommas(item.nwmanAmt) : '0'}}</span><span class="crTy-blue3">원</span></span>
                        </div>
                        <div class="list-item__contents__info mt7">
                          <span><span class="fs14rem mr10">최초위촉일</span><span class="crTy-bk1">{{$bizUtil.dateYyMmDdFormat(item.fstCmsnYmd)}}</span></span><em>|</em>
                          <span><span class="fs14rem mr10">최초위촉차월</span><span class="crTy-bk1">{{item.fstLpmfc}}</span></span>
                        </div>
                        <div class="list-item__contents__info">
                          <span><span class="fs14rem mr10">직책</span><span class="crTy-bk1">{{item.duty}}</span></span><em>|</em>
                          <span><span class="fs14rem mr10">해촉간주</span><span :class="item.rcmsnYn=='Y'?'fwb crTy-orange2':'fwb crTy-blue3'">{{item.rcmsnYn}}</span></span> <!-- N일때 crTy-blue3 -->
                        </div>
                      </div>
                      <template v-slot:expand>
                        <div class="list-item__contents">
                          <div class="">
                            <li><span class="min180 dsInline fs14rem">2~13회 유지율</span><span class="crTy-bk1">{{item.ukepRat123}}</span></li>
                            <li><span class="min180 dsInline fs14rem">유치자 동일지역단 여부</span><span class="crTy-bk1">{{item.indcrAgncYn}}</span></li>
                            <li><span class="min180 dsInline fs14rem">환산성적</span><span class="crTy-bk1 mr4">{{item.cnvlPrfmAmt ? $commonUtil.numberWithCommas(item.cnvlPrfmAmt) : '0'}}</span><span class="crTy-bk1">원</span></li>
                            <li><span class="min180 dsInline fs14rem">절대건수</span><span class="crTy-bk1">{{item.absltCnt ? item.absltCnt : '0.00'}}</span></li>
                            <li><span class="min180 dsInline fs14rem">도입분</span><span class="crTy-bk1 mr4">{{item.inducAmt ? $commonUtil.numberWithCommas(item.inducAmt) : '0'}}</span><span class="crTy-bk1">원</span></li>
                            <li><span class="min180 dsInline fs14rem">신인비례분</span><span class="crTy-bk1 mr4">{{item.prprAmt ? $commonUtil.numberWithCommas(item.prprAmt) : '0'}}</span><span class="crTy-bk1">원</span></li>
                            <li><span class="min180 dsInline fs14rem">경력육성분</span><span class="crTy-bk1 mr4">{{item.carrSucoa ? $commonUtil.numberWithCommas(item.carrSucoa) : '0'}}</span><span class="crTy-bk1">원</span></li>
                          </div>
                        </div>
                      </template>

                    </mo-list-item>
                  </template>
                </mo-list>
                <div class="full con-area">
                  <ul class="terms-list-area mt30 crTy-bk7 fs14rem">
                    <li class="break-word">신인비례분 지급률: [FC] Junior 65%, Senior 85%, Royal 115% / [GFC] Junior 60%, Senior 80%, Royal 100% / 경력신인 20%</li>
                    <li>해촉간주가 Y인 경우 조직명장 수수료 대상 제외 (연속 3개월 환산 30만 미만 시)</li>
                    <li>2~13회 유지율 75% 미만인 경우 신인업적비례분 부지급</li>
                    <li>유치자와 동일지역단이 아닌 경우 신인업적비례분 50% 지급</li>
                  </ul>
                </div>
              </ur-box-container>
              <!-- 신인업적분 Tab end -->

              <!-- 2대계보분 Tab start -->
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024" v-if="tab_index === '3'">
                <mo-list :list-data="etcTabData">
                  <template #list-item="{item}">
                    <mo-list-item expandable >
                      <div class="list-item__contents">
                        <div class="list-item__contents__title mb0">
                          <span class="name txtSkip fs19rem" @click="fn_Open511Pop(item.name, item.fcCode)">{{item.name}}({{item.fcCode}})</span>
                        </div>
                        <div class="list-item__contents__info mt10">
                          <span class="fwb"><span class="crTy-blue3 mr4">{{$commonUtil.numberWithCommas(item.y0bqAmt)}}</span><span class="crTy-blue3">원</span></span>
                        </div>
                        <div class="list-item__contents__info mt7">
                          <span><span class="fs14rem mr10">최초위촉일</span><span class="crTy-bk1">{{$bizUtil.dateYyMmDdFormat(item.fstCmsnYmd)}}</span></span><em>|</em>
                          <span><span class="fs14rem mr10">최초위촉차월</span><span class="crTy-bk1">{{item.fstLpmfc}}</span></span>
                        </div>
                        <div class="list-item__contents__info">
                          <span><span class="fs14rem mr10">직책</span><span class="crTy-bk1">{{item.duty}}</span></span><em>|</em>
                          <span><span class="fs14rem mr10">해촉간주</span><span :class="item.y0ia=='Y'?'fwb crTy-orange2':'fwb crTy-blue3'">{{item.y0ia}}</span></span> <!-- N일때 crTy-blue3 -->
                        </div>
                      </div>
                      <template v-slot:expand>
                        <div class="list-item__contents">
                          <div class="">
                            <li><span class="min180 dsInline fs14rem">2~13회 유지율</span><span class="crTy-bk1">{{item.r00e}}</span></li>
                            <li><span class="min180 dsInline fs14rem">유치자코드</span><span class="crTy-bk1">{{item.s0an}}</span></li>
                            <li><span class="min180 dsInline fs14rem">도입당시 유치자차월</span><span class="crTy-bk1">{{item.c0bp}}</span></li>
                            <li><span class="min180 dsInline fs14rem">환산성적</span><span class="crTy-bk1 mr4">{{item.n00k ? $commonUtil.numberWithCommas(item.n00k) : '0'}}</span><span class="crTy-bk1">원</span></li>
                            <li><span class="min180 dsInline fs14rem">절대건수</span><span class="crTy-bk1">{{item.c00b ? item.c00b : '0.00'}}</span></li>
                            <li><span class="min180 dsInline fs14rem">2대계보도입분</span><span class="crTy-bk1 mr4">{{item.y0bqRecru ? $commonUtil.numberWithCommas(item.y0bqRecru) : '0'}}</span><span class="crTy-bk1">원</span></li>                            
                          </div>
                        </div>
                      </template>

                    </mo-list-item>
                  </template>
                </mo-list>
                <div class="full con-area">
                  <ul class="terms-list-area mt30 crTy-bk7 fs14rem">                    
                    <li>1, 7차월 도입분 지급</li>
                    <li>해촉간주가 Y인 경우 조직명장 수수료 대상 제외 (연속 3개월 환산 30만 미만 시)</li>
                  </ul>
                </div>
              </ur-box-container>
              <!-- 2대계보분 Tab end -->

            </ur-box-container>

          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  import Msg from '~/src/systems/webkit/msg/msg'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP070M",
    screenId: "MSPAP070M",
    components: {
      MspBottomSelect,
      MSPAP106P,
      },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // Back Key 이벤트 바인딩
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)

      // console.log('지점장 이상 권한 여부', this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo)
      // console.log('지점장 역할 여부', this.getStore('userInfo').getters.getUserInfo.isBMngr)
      // 지점장 이상 권한인지 구분
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
        // 지점장 역할인 경우
        if(this.getStore('userInfo').getters.getUserInfo.isBMngr === 'Y') {
          this.isBMngr = true
        }
      } else {
        this.isManager = false
      }
    },
    mounted () {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      // 마감년월 기본값 전월로 설정
      if(moment(new Date().toISOString()).format('MM') > 1){
        this.finYm = [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')-1]
      } else { // 현재 1월일 때
        this.finYm = [moment(new Date().toISOString()).format('YYYY')-1, '12']
      }

      // 지점장 이상 권한 진입 시 컨설턴트 검색 팝업 노출
      if ( !this.isManager ) {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      } else {
        if(this.lv_IsFirst){
          this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              single: true,
              title: "",
              content: '컨설턴트를 먼저 선택해 주세요.',
              title_pos_btn: "확인"
            },
            listeners: {
              onPopupConfirm: () => {
                console.log('onPopupConfirm call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              },
              onPopupCancel: () => {
                console.log('onPopupCancel call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              },
              onPopupClose: () => {
                console.log('onPopupClose call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              }
            }
          })
        }
      }
    },

    beforeDestroy() {
      // Back Key 이벤트 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID:'',

        cnsltNo: '', // 조회되는 FC 코드
        isManager: false, // 최초 영업관리자를 위한 조건값 (지점장 이상이면 true)
        isSelectUser: false, // 영업관리자가 설계사를 선택하였는지 여부
        lv_IsFirst: true,
        isBMngr: false, // 지점장역할
        lv_HofOrg: {key: '', label: ''}, // 선택사업부
        lv_DofOrg: {key: '', label: ''}, // 선택지역단
        lv_FofOrg: {key: '', label: ''}, // 선택지점
        lv_FofCnslt: {key: '0', label: ''}, // 선택컨설턴트

        FstLpmfc: '', // 최초위촉차월
        duty: '', // 직책
        finYm: ['',''], // 마감년월
        maxFinYm: moment(new Date().toISOString()).format('YYYY-MM-DD'), // 마감년월 최대일자

        tab_index: '1', // tab contant 선택
        comsTabData: {}, // 수수료 tab Data
        nwmanAchTabData: [], // 신인업적분 tab Data
        etcTabData: [], // 2대계보분 tab Data
        nextKey1: '', // 신인업적분 페이징 여부 next key
        nextKey2: '', // 2대계보분 페이징 여부 next key
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

    },

    watch: {
      finYm() {
        // 마감월 형식 MM으로
        if(this.finYm[1] < 10){
          this.finYm[1] = '0' + this.finYm[1]
        }
        // console.log('마감년월 >> ', this.finYm)
        if(this.cnsltNo !== '') {
          // this.tab_index = '1'
          this.fn_GetOrgComs() // 수수료조회
        }
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_OpenMSPAP106P
      * 설명       : 지점장 이상 권한 진입 시 컨설턴트 선택 Alert 노출
      ******************************************************************************/
      fn_OpenMSPAP106P(){
        const lp_Vm = this  
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP070M',
          },
          listeners: {
            onPopupSearch: (pItem, pData) => {
              // console.log('onPopupSearch 컨설턴트 >> ' , pItem)
              // console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              if(pItem != null && pItem != undefined){
                lp_Vm.lv_FofCnslt = pItem
                lp_Vm.cnsltNo = lp_Vm.lv_FofCnslt.key
                lp_Vm.lv_HofOrg = pData.hofOrg // 선택사업부
                lp_Vm.lv_DofOrg = pData.dofOrg // 선택지역단
                lp_Vm.lv_FofOrg = pData.fofOrg // 선택지점
              }              
              // lp_Vm.duty = lp_Vm.getStore('userInfo').getters.getUserInfo.dutyCd

              lp_Vm.isSelectUser = true

              lp_Vm.tab_index = '1' // 수수료탭으로
              lp_Vm.fn_GetOrgComs() // 수수료조회
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },

      /******************************************************************************
       * Function명 : fn_Open511Pop
       * 설명       : FC문자/전화 팝업 호출
       ******************************************************************************/
      fn_Open511Pop (cnsltNm, cnsltNo) {
        // 영업관리자(지점장 이상) 권한 시에만 가능
        if(this.isManager){
          this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
            properties: {
              pCnsltNm: cnsltNm,
              pCnsltNo: cnsltNo,
              FCTelBtn: "전화하기",
              FCSMSBtn: "문자하기"
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.lv_popup511)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup511)
              }
            }
          })
        }
      },

      /******************************************************************************
       * Function명 : fn_GetOrgComs
       * 설명       : 수수료조회
      ******************************************************************************/
      fn_GetOrgComs () {
        var lv_Vm = this
        var trnstId = 'txTSSAP02S1'
        var pParam = {
          IV_CHNL_ID: lv_Vm.cnsltNo, // FC코드
          IV_ACTUL_YM: lv_Vm.finYm[0] + lv_Vm.finYm[1] // 마감년월
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            console.log('수수료조회!!! ', response.body)
            lv_Vm.FstLpmfc = response.body.za_LPMFC                 // 최초위촉차월
            lv_Vm.duty = response.body.za_DUTY_SC_CD                // 직책
            let tmp = {}
            tmp.exptComs = response.body.team_MANAGER_SUM.trim()    // 예상수수료
            tmp.grade = response.body.grade                         // 등급
            tmp.recruiting = response.body.recruiting.trim()        // 도입분
            tmp.rookie = response.body.rookie.trim()                // 신인비례분
            tmp.genaration2 = response.body.genaration2.trim()      // 2대계보분
            tmp.self = response.body.self.trim()                    // 본인분
            tmp.celebrate = response.body.celebrate.trim()          // 배출축하금
            tmp.pRecruiting = response.body.p_RECRUITING            // 도입인원
            tmp.cmsnAgent = response.body.cmsn_AGENT                // 마감월 위촉인원
            tmp.suvlAgent = response.body.same_CHANNEL_AGENT        // 동일채널 잔존인원
            tmp.cnvlPrfm = response.body.cnvl_PRFM                  // 환산성적
            tmp.cnvlAmt30up = response.body.cnvl_AMT_30UP           // 환산 30만 이상인원
            tmp.cnvlAmt30down = response.body.cnvl_AMT_30DOWN       // 환산 30만 미만 여부
            tmp.carrSucoa = response.body.carr_SUCOA ? response.body.carr_SUCOA.trim() : '' // 경력육성분
            lv_Vm.comsTabData = tmp
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })

        // 신인업적분 조회
        this.nextKey1='' // 조회 next key 초기화
        this.nwmanAchTabData = [] // 이전 조회 데이터 초기화
        this.fn_GetOrgNwnbAch()
      },

      /******************************************************************************
       * Function명 : fn_GetOrgNwnbAch
       * 설명       : 신인업적분조회
      ******************************************************************************/
      fn_GetOrgNwnbAch () {
        var lv_Vm = this
        var trnstId = 'txTSSAP02S2'
        var pParam = {
          CTRTBU_ID: lv_Vm.cnsltNo, // FC코드
          PRD_YM: lv_Vm.finYm[0] + lv_Vm.finYm[1], // 마감년월
          NEXT_KEY: lv_Vm.nextKey1 // 추가 데이터 페이징 여부 (빈 값이면 조회 X)
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            console.log('신인업적분조회!!! ', response.body)
            if (response.body.aPOrgNwnbAchInfoSVO !== null && response.body.aPOrgNwnbAchInfoSVO.length > 0) {
              response.body.aPOrgNwnbAchInfoSVO.forEach(data => {
                let tmp = {}
                tmp.name = data.name                                          // 이름
                tmp.fcCode = data.ctrtbu_ID.slice(-10)                        // FC코드
                tmp.fstCmsnYmd = data.za_FST_CMSN_YMD                         // 최초위촉일
                tmp.fstLpmfc = data.za_FST_LPMFC                              // 최초위촉차월
                tmp.duty = data.za_DUTY_SC_CD                                 // 직책구분
                tmp.rcmsnYn = data.za_RCMSN_YN                                // 해촉간주
                tmp.ukepRat123 = data.za_UKEP_RAT123                          // 2~13회 유지율
                tmp.indcrAgncYn = data.za_INDCR_AGNC_YN                       // 동일지역단 여부
                tmp.cnvlPrfmAmt = data.za_CNVL_PRFM_AMT                       // 환산성적
                tmp.absltCnt = data.za_ABSLT_CNT                              // 절대건수
                tmp.inducAmt = data.za_INDUC_AMT                              // 도입분
                tmp.prprAmt = data.za_PRPR_AMT                                // 신인비례분
                tmp.carrSucoa = data.za_CARR_SUCOA ? data.za_CARR_SUCOA.trim() : '' // 경력육성분
                tmp.nwmanAmt = Number(tmp.inducAmt) + Number(tmp.prprAmt) + Number(tmp.carrSucoa)    // 신인업적분(도입분 + 신인비례분 + 경력육성분)
                lv_Vm.nwmanAchTabData.push(tmp)
              })
              lv_Vm.nextKey1 = response.body.next_KEY
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })

        // 2대계보분 조회
        if(this.nextKey1===''){ // 마감년월 변경으로 최초 조회인 경우만
          this.nextKey2='' // 조회 next key 초기화
          this.etcTabData=[] // 이전 조회 데이터 초기화
          this.fn_GetOrgEtc()
        }
      },

      /******************************************************************************
       * Function명 : fn_GetOrgEtc
       * 설명       : 2대계보분조회
      ******************************************************************************/
      fn_GetOrgEtc () {
        var lv_Vm = this
        var trnstId = 'txTSSAP02S3'
        var pParam = {
          CTRTBU_ID: lv_Vm.cnsltNo, // FC코드
          PRD_YM: lv_Vm.finYm[0] + lv_Vm.finYm[1], // 마감년월       
          NEXT_KEY: lv_Vm.nextKey2 // 추가 데이터 페이징 여부 (빈 값이면 조회 X)
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            console.log('2대계보분조회!!! ', response.body)
            if(response.body.aPOrgEtcInfoSVO !== null && response.body.aPOrgEtcInfoSVO.length > 0){
              response.body.aPOrgEtcInfoSVO.forEach(data => {
                let tmp = {}
                tmp.name = data.name                                                  // 이름
                tmp.fcCode = data.ctrtbu_ID.slice(-10)                                // FC코드
                tmp.fstCmsnYmd = data.za_FST_CMSN_YMD                                 // 최초위촉일
                tmp.fstLpmfc = data.za_LPMFC                                          // 최초위촉차월
                tmp.duty = data.za_DUTY_SC_CD                                         // 직책구분
                tmp.y0ia = data.y0IA                                                  // 해촉간주
                tmp.r00e = data.r00E                                                  // 유지율
                tmp.s0an = data.s0AN.slice(-7)                                        // 유치자코드
                tmp.c0bp = Number(data.c0BP)                                          // 도입당시 유치자차월
                tmp.n00k = data.n00K                                                  // 환산성적
                tmp.c00b = data.c00B                                                  // 절대건수
                tmp.y0bqRecru = pParam.PRD_YM > '202212' ? data.y0BQ_RECRU : '0'      // 2대계보도입분(23년 신설항목)
                tmp.y0bqRate = data.y0BQ_RATE                                         // 2대계보비례분
                tmp.y0bqAmt = Number(tmp.y0bqRecru)                                   // 2대계보분(2대계보도입분)
                lv_Vm.etcTabData.push(tmp)
              })
              lv_Vm.nextKey2 = response.body.next_KEY  // 페이징여부 next key
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_moreData
      * 설명       : 하단 스크롤 시 리스트 추가 조회
      ******************************************************************************/
      fn_moreData(){
        // 신인업적분
        if(this.tab_index === '2' && this.nextKey1!==''){ // 추가 데이터 페이징 여부 ( next Key 빈 값이면 조회 X)
          // console.log('신인업적분 more data')
          this.fn_GetOrgNwnbAch()
        } 
        // 2대계보분
        else if(this.tab_index === '3' && this.nextKey2!==''){ // 추가 데이터 페이징 여부 (next Key 빈 값이면 조회 X)
          // console.log('2대계보분 more data')
          this.fn_GetOrgEtc() 
        }

      },

      /******************************************************************************
      * Function명 : fn_ClickBtnPrevious
      * 설명       : 이전 버튼 click event(홈으로 이동)
      ******************************************************************************/
      fn_ClickBtnPrevious () {
        // if (this.$route.query?._isMenuItem) {
          this.$router.push({ name: 'MSPBC002M' })
        //   return
        // }
        // this.$router.go(-1)
      },

    }
  };
</script>
<style scoped>
</style>