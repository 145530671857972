/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI253P
 * 화면설명: 발행 > 이메일발송 팝업
 * 접근권한: 
 * 작 성 일: 2023.03.20
 * 작 성 자: ---
 */
 <template>
  <ur-page-container class="msp" type="subpage" title="이메일 발송" :show-title="true">
  <!-- <ur-page-container class="msp" type="popup" title="이메일 발송" :show-title="false"> -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pt15">  
        <mo-list-item>
          <div class="list-item__contents pb10" >
            <div class="list-item__contents__info pal0">
              <span class="crTy-bk1"><b class="crTy-blue2">{{ custCnt }}명</b>의 고객에게 이메일을 발송합니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    <!-- <ur-box-container direction="column" alignV="start"> -->
      <ur-box-container alignV="start" componentid="ur_box_container_000" direction="column" class="ns-add-area">
        <ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box">
            <span class="title">첨부파일</span>
            <span>{{lv_AttchNm}}</span>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="add-box">
            <span class="title must">받는사람</span>
            <mo-text-field v-model="lv_MailTo" ref="mailTo" readonly @keyup.delete="fn_DeleteMailTo" :rules="validateRuleName" underline placeholder="이메일 주소를 입력하세요" class="mb10" />
          </ur-box-container>

          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="add-box">
            <span class="title must">메일제목</span>
            <mo-text-field v-model="lv_MailTitle" ref="mailTitle" clearable :rules="validateRuleName" underline placeholder="입력하세요" class="mb10" />
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="add-box">
            <span class="title">본문내용</span>
            <mo-text-area v-model="lv_MailText" placeholder="입력하세요" :rules="validateRuleName"/>
          </ur-box-container>
        </ur-box-container>

        <div class="ns-height40"></div>
        <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close">취소</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Send" :disabled="lv_MailTitle.trim() === ''">발송</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI253P',
  screenId: 'MSPPI253P',
  components: {
  },
  props: {
    param: {}
  }, // parents data
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created () {
    this.setPage()
  },
  mounted () {
  },
  beforeDestroy () {
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      validateRuleName: [
          (v) => !!v || '필수입력항목입니다.'
      ],
      // lv_AttchNm: '',
      // lv_CustInfo: {},
      // lv_MailTo: '',
      // lv_IsTyping: true, // false: 고객 이메일 존재하므로 불러와서 사용. true: 고객 이메일 미존재하므로 입력하여 사용
      // lv_MailText: '',
      // lv_MailTitle: '[광고]',
      // lv_EmailTargetCustList: [], // 이메일발송 대상 고객
      // DEBUG: false // 디버그모드
      //---------------------------------------
      custCnt: 1,
      successFlag: false,
      pAttachedFileList: [],
      lv_IsShowPopup: this.pShowPopup,
      lv_MailTo: '',
      lv_MailTitle: '',
      lv_MailText: '',
      lv_AttchNm: '',
      lv_IsTyping: true, // false: 고객 이메일 존재하므로 불러와서 사용. true: 고객 이메일 미존재하므로 입력하여 사용
      lv_CustInfo: {},
      lv_EmailTargetCustList: [] // 이메일발송 대상 고객
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  watch: {
    pShowPopup (newValue) {
      this.lv_IsShowPopup = newValue
    },
    lv_IsShowPopup (newValue) {
      if (newValue === false) {
        this.fn_CallClose()
      } else {
        this.lv_MailTitle = ''
        this.lv_MailText = ''

        if (!this.$bizUtil.isEmpty(this.pCustInfo.emailAddr)) {
          this.lv_IsTyping = false
          this.lv_CustInfo = this.$bizUtil.cloneDeep(this.pCustInfo)
          this.lv_MailTo = '"' + this.lv_CustInfo.custNm + '" <' + this.lv_CustInfo.emailAddr + '>'
        }
      }
    },
    lv_MailTo (newValue) {
      if (this.lv_IsTyping) {
        // this.lv_CustInfo.emailAddr = this.lv_MailTo
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*******************************************************************************
    * Function명: setPage
    * 설명: 이메일 발송을 위한 화면 구성
    *******************************************************************************/
    setPage () {
      console.log(this.param)
      let oGrdMyList = this.param.oGrdMyList
      this.lv_MailTo = ''
      if (oGrdMyList !== undefined) {
        oGrdMyList.forEach(element => {
          this.lv_MailTo += element.custNm + '<' + element.custEmailAddr + '>' + ';'
          // this.lv_MailTo = 'cssmail1@ets1.v.ets.samsunglife.kr <= 임시로 설정' // 임시 정규경 선임 메일
        })
        this.lv_AttchNm = oGrdMyList[0].pdf_file_name
        if (oGrdMyList[0].custTiSubject !== null) {
          this.lv_MailTitle = oGrdMyList[0].custTiSubject
        }
        if (oGrdMyList[0].custTaBody !== null) {
          this.lv_MailText = oGrdMyList[0].custTaBody
        }
      }
      // lv_CustInfo 채우기
      this.lv_CustInfo.emailAddr = this.param.oGrdMyList[0].custEmailAddr
      this.lv_CustInfo.custNm = this.param.oGrdMyList[0].custNm
      this.lv_CustInfo.chnlCustId = this.param.oGrdMyList[0].chnlCustId

      let item = {
        eltrnDoctId: this.param.oGrdMyList[0].pdf_file_id,
        eltrnDoctFilePathNm: this.param.oGrdMyList[0].pdf_file_path,
        eltrnDoctFileNm: this.param.oGrdMyList[0].pdf_file_name,
        is_original: this.param.oGrdMyList[0].is_original
      }
      this.pAttachedFileList.push(item)
    },
    /*********************************************************
     * Function명: fn_Close
     * 설명: TSSPI251P 화면 닫기
     *********************************************************/
    fn_Close () {
      // this.lv_IsShowPopup = false
      this.fn_CallClose()
    },
    /*********************************************************
     * Function명: fn_CallClose
     * 설명: TSSPI250P 화면에 TSSPI251P 화면 닫혔다고 전달
     *********************************************************/
    fn_CallClose () {
      // console.log('this.successFlag', this.successFlag)
      this.$emit('closePopup', this.successFlag)
    },
    /*********************************************************
     * Function명: fn_Send
     * 설명: 이메일을 발송한다
     *********************************************************/
    fn_Send () {
      // this.$refs.fdpFormWrapper.validateBeforeSubmit()
      this.fn_ValidateBeforeSubmit(true)
    },
    /*********************************************************
     * Function명: fn_DeleteMailTo
     * 설명: 기등록된 고객의 메일주소를 지울 경우, 미등록된 고객의 이메일을 직접 기입하는 것 처럼 처리
     *********************************************************/
    fn_DeleteMailTo () {
      if (!this.lv_IsTyping) {
        this.lv_MailTo = ''
        this.lv_CustInfo.custNm = ''
        this.lv_CustInfo.emailAddr = ''
        this.lv_IsTyping = true
      }
    },
    /*********************************************************
     * Function명: fn_ValidateBeforeSubmit
     * 설명: Validator에 걸린 오류가 없는지 체크
     *********************************************************/
    fn_ValidateBeforeSubmit (result) {
      if (result) { // 오류가 없다면
        let tmpObj = {}
        tmpObj.chnlCustId = this.lv_CustInfo.chnlCustId
        tmpObj.custNm = this.lv_CustInfo.custNm
        tmpObj.mktCnsntYn = 'Y'
        tmpObj.custEmailAddr = this.lv_CustInfo.emailAddr
        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage2') {
          // tmpObj.custEmailAddr = 'cssmail1@ets1.v.ets.samsunglife.kr'
          tmpObj.custEmailAddr = 'vsdjxbb60657@ets1.v.ets.samsunglife.kr' // 유파트너 검증 확인용
        }
        this.lv_EmailTargetCustList.push(tmpObj)
        this.fn_ReadySendMail()
      }
    },
    /*******************************************************************************
    * Function명: fn_SendEmail, fn_ReadySendMail
    * 설명: 이메일 발송
    *******************************************************************************/
    fn_SendEmail (pAttachedFileList) {
      console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) START ☞ ' + new Date().getTime())
      console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) 대상고객수=' + this.lv_EmailTargetCustList.length)
      console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) pAttachedFileList.length=' + pAttachedFileList.length)

      var lv_Vm = this
      const trnstId = 'txTSSSA12P1'
      const auth = 'I'

      let pParams = {
        subject: this.lv_MailTitle,
        body: this.lv_MailText,
        ecard: '고객님께 전해드리는 좋은 정보'
      }

      let toList = []
      this.lv_EmailTargetCustList.forEach(tempVo => {
        let tempTo = {}
        tempTo.cust_id = tempVo.chnlCustId
        tempTo.cust_name = tempVo.custNm
        // tempTo.cust_email = 'cssmail1@ets1.v.ets.samsunglife.kr' // 임시 정규경 선임 메일
        tempTo.cust_email = tempVo.custEmailAddr

        tempTo.pdf_file_id = pAttachedFileList[0].eltrnDoctId
        tempTo.pdf_file_path = pAttachedFileList[0].eltrnDoctFilePathNm
        tempTo.pdf_file_name = pAttachedFileList[0].eltrnDoctFileNm

        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempVo.is_original=' + tempVo.is_original)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempVo.chnlCustId=' + tempVo.chnlCustId)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempVo.custNm=' + tempVo.custNm)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempVo.custEmailAddr=' + tempVo.custEmailAddr)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempTo.pdf_file_id=' + tempTo.pdf_file_id)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempTo.pdf_file_path=' + tempTo.pdf_file_path)
        console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) tempTo.pdf_file_name=' + tempTo.pdf_file_name)
        toList.push(tempTo)
      })
      pParams.to = toList

      this.successFlag = false
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          console.log('■■■■■ MSPPI253P ■■■■■ axios.post 수행 시작...')
          console.log('■■■■■ MSPPI253P ■■■■■ axios.post lv_Vm.$commonUtil.isSuccess(response)=' + lv_Vm.$commonUtil.isSuccess(response))
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if ( lv_Vm.$commonUtil.isSuccess(response) ) {
            if (response.body !== null && response.body !== '') {
              console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) lv_Vm.lv_SendEmailItemCount=' + lv_Vm.lv_SendEmailItemCount)
              lv_Vm.getStore('toast').dispatch('ADD', '메일 발송이 완료되었습니다.')
              // lv_Vm.getStore('confirm').dispatch('ADD', '메일 발송이 완료되었습니다.')
              lv_Vm.successFlag = true
            } else {
              if (response.msgComm.msgDesc != null && response.msgComm.msgDesc.length > 0) {
                // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              }
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          // console.log('axios.post 수행 완료...')
        }).then(() => {
          lv_Vm.fn_Close()
        })
        .catch(function (error) {
          console.log('■■■■■ MSPPI253P ■■■■■ 이메일발송(fn_SendEmail) 4 ☞ ' + new Date().getTime())
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CM')['error_message'])
        })
      // this.fn_Close()
    },
    fn_ReadySendMail () {
      console.log('■■■■■ MSPPI253P ■■■■■ fn_ReadySendMail START ☞ ' + new Date().getTime())
      console.log('■■■■■ MSPPI253P ■■■■■ fn_ReadySendMail this.pAttachedFileList.length=' + this.pAttachedFileList.length)
      // 화면 깜박거림을 방지하기 위해 강제로 출력된 로딩바 해제
      window.vue.getStore('progress').dispatch('HIDE')
      // this.getStore('progress').getters.getState.isShow = false
      let attachedFileList = []
      for (let i = 0; i < this.pAttachedFileList.length; i++) {
        attachedFileList.push(this.pAttachedFileList[i])
      }

      console.log('■■■■■ MSPPI253P ■■■■■ fn_ReadySendMail attachedFileList.length=' + attachedFileList.length)
      if (attachedFileList.length > 0) {
        this.fn_SendEmail(this.pAttachedFileList)
        // this.fn_SendEmail(attachedFileList)
      }
      console.log('■■■■■ MSPPI253P ■■■■■ fn_ReadySendMail END ☞ ' + new Date().getTime())
    },
  }
}
</script>