/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI804P
 * 화면설명: 친권자 설정
 * 접근권한: 
 * 작 성 일: 2024.01.08
 * 작 성 자: 
 */
 <template>
  <ur-page-container ref="page" class="msp btn-back-clear" title="친권자 설정" :show-title="false" type="popup" :topButton="false" :topButtonBottom="150"> <!-- type="popup" -->
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '친권자 설정' : '친권자 설정'}}</div>
              <div slot="action">
                <span><mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_HistoryBack()">close</mo-icon></span>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <ur-box-container direction="column" alignV="start" class="msp-pi-wrap pb50">
              <ur-box-container direction="column" alignV="start" class="pa2024">
                <div class="fexTy5">
                  <span class="fs17rem">미성년자의 질병정보조회를 위해 친권자 설정이 필요합니다.</span>
                  <!-- <span class="fs17rem">만 14세 미만 또는 휴대폰번호가 없는 만 14세 이상의 미성년자의 경우 지급정보조회를 위해서는 친권자 모두의 동의가 필요합니다.</span> -->
                </div>
              </ur-box-container>
              <div class="gray-bar" />
              <ur-box-container alignV="start" componentid="" direction="column">
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
                  <div class="info-title-wrap mb10">
                    <span class="contain-title">친권자 1</span>
                  </div>
                  <msp-bottom-select class="ns-dropdown-sheet"
                                    :items="lv_HoshInfoList"
                                    v-model="lv_PrtrPeson1.chnlCustId"
                                    :itemValue="'chnlCustId'" :itemText="'custNmAge'"
                                    underline
                                    bottom-title="친권자1"
                                    scrolling
                                    placeholder="선택해주세요"
                                    closeBtn
                                    @selectedItem="fn_SetPrtrPesonInfo(lv_PrtrPeson1.chnlCustId, '1')"/>
                  <div class="ns-check flex mt20">
                    <mo-checkbox v-model="lv_ChkPrtr" value="01" :disabled="lv_ChkPrtrDisabled1">1인 친권자</mo-checkbox>
                    <mo-checkbox v-model="lv_ChkPrtr" value="03" :disabled="lv_ChkPrtrDisabled2">공동친권</mo-checkbox>
                  </div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50" >
                  <div class="info-title-wrap mb10">
                    <span class="contain-title">친권자 2</span>
                  </div>
                  <msp-bottom-select class="ns-dropdown-sheet"
                                    :disabled="lv_ChkPrtr !== '02' && lv_ChkPrtr !== ''"
                                    :items="lv_HoshInfoList"
                                    v-model="lv_PrtrPeson2.chnlCustId"
                                    :itemValue="'chnlCustId'" :itemText="'custNmAge'"
                                    underline
                                    bottom-title="친권자2"
                                    scrolling
                                    placeholder="선택해주세요"
                                    closeBtn
                                    @selectedItem="fn_SetPrtrPesonInfo(lv_PrtrPeson2.chnlCustId, '2')"/>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>
            <!-- Content 영역 end -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_HistoryBack('close')">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import Msg from '@/systems/webkit/msg/msg'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPI804P",
  screenId: "MSPPI804P",
  isBackKeyHandle: true,
  components: {
  },
  props: {
    prtrVal: String,
    prtrPesonInfoList: Array,
    hoshInfoList: Array
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      /**
       * pymBrkdnCnsntInfoTrtDto	지급내역동의정보처리DTO
          custId	고객ID
          chnlCustId	채널고객ID
          clctCnsltNo	모집컨설턴트번호
          custRltnCd	고객관계코드
          custNm	고객명
          custAge	고객연령
          custTelno	고객전화번호
          cnsntYn	동의여부
          cnsntAvlStrYmd	동의유효시작일자
          cnsntAvlEndYmd	동의유효종료일자
          dspchYmd	발송일자
          contvInsrdTypCd	계약관점피보험자유형코드(21:주피, 23:종피)
          mcnstCnsntYn	필수컨설팅동의여부(Y/N)
          prtrTypCd	친권유형코드
       */
      lv_HeaderviewScrollCompID: '',
      lv_PrtrPesonInfo: [], // 친권자 List
      lv_HoshInfoList: [], // 세대정보 List
      lv_PrtrPeson1: {}, // 선택한 친권자 1
      lv_PrtrPeson2: {}, // 선택한 친권자 2
      lv_ChkPrtr: '', // 1: 1인친권자, 2: 공동친권
      lv_ChkPrtrDisabled1: false, // 1인친권자 disalbed
      lv_ChkPrtrDisabled2: false, // 공동친권 disalbed
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  destroyed () {
  },
  created() {
    let lv_vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.fn_Init()
    
  },
  mounted() {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
    window.vue.getStore('progress').dispatch('FULL')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },
  watch: {
    lv_ChkPrtr (item) { // 친권자 선택 시
      console.log('watch lv_ChkPrtr ====> ', item)
      if(!_.isEmpty(item)) {
        if (item === '01') {
          this.lv_ChkPrtrDisabled2 = true
          this.lv_PrtrPeson2 = {}
        } else if (item === '03'){
          this.lv_ChkPrtrDisabled1 = true
          this.lv_PrtrPeson2 = {}
        } else {
          this.lv_ChkPrtrDisabled1 = false
          this.lv_ChkPrtrDisabled2 = false
        }
      } else {
        this.lv_ChkPrtrDisabled1 = false
        this.lv_ChkPrtrDisabled2 = false
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                             *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack (type) {
      if (_.isEmpty(type) || type === 'close') {
        this.$emit('closePopup')
      }
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화 함수
     ******************************************************************************/
    fn_Init () {
      let lv_vm = this
      // 세대정보 중 주피보험자 제외한 데이터 세팅
      lv_vm.lv_HoshInfoList = lv_vm.hoshInfoList.filter(item => item.contvInsrdTypCd !== '21')
      this.lv_HoshInfoList.forEach(element => {
        element.custNmAge = `${element.custNm}(${element.custAge}세)`
      })
      if (this.prtrPesonInfoList.length > 0) {
        this.prtrPesonInfoList.forEach((item, idx) => {
          if (idx === 0) {
            this.lv_PrtrPeson1 = item
          } else {
            this.lv_PrtrPeson2 = item
          }
        })
      }
      this.lv_ChkPrtr = this.prtrVal
      // [TO-DO] 친권자 발송일자, 동의여부 체크하여 변경불가 처리
      // if () {
      // }
      console.log('lv_vm.lv_HoshInfoList ====> ', this.lv_HoshInfoList)
    },
    /******************************************************************************
     * Function명 : fn_SetPrtrPesonInfo
     * 설명       : 친권자 선택 시 함수
     * @param : item(chnlCustId)
     ******************************************************************************/
    fn_SetPrtrPesonInfo (item, type) {
      console.log('친권자 선택 시 함수 ======> ', item)
      if (_.isEmpty(item)) return
      let custInfo = this.$bizUtil.cloneDeep(this.lv_HoshInfoList.filter(element => element.chnlCustId === item)[0])
      console.log('filter custInfo ==> ', custInfo)
      // 필수컨설팅 체크
      if (custInfo.mcnstCnsntYn !== 'Y') {
        this[`lv_PrtrPeson${type}`] = {}
        piCommonUtil.getFDPConfirm('s', '필수컨설팅 동의가 없는 고객은 친권자 지정이 불가합니다.')
        return
      }
      // 미성년 체크
      if (custInfo.custAge <= 18) {
        this[`lv_PrtrPeson${type}`] = {}
        piCommonUtil.getFDPConfirm('s', '미성년자는 친권자 지정이 불가합니다.')
        return
      }
      if (type === '1') { // 친권자 1
        if (item === this.lv_PrtrPeson2.chnlCustId) {
          this.lv_PrtrPeson1 = {}
          piCommonUtil.getFDPConfirm('s', '친권자 1과 친권자 2는 동일인 지정이 불가합니다.')
          return
        }
        this.lv_PrtrPeson1 = custInfo
        // 친권유형코드 - 값 임시 지정
        // this.lv_PrtrPeson1.prtrTypCd = '01' // 01:1인친권
      }
      if (type === '2') { // 친권자 2
        if (_.isEmpty(this.lv_PrtrPeson1.chnlCustId)) {
          this.lv_PrtrPeson2 = {}
          piCommonUtil.getFDPConfirm('s', '친권자 1을 먼저 선택해주세요.')
          return
        }
        if (item === this.lv_PrtrPeson1.chnlCustId) {
          this.lv_PrtrPeson2 = {}
          piCommonUtil.getFDPConfirm('s', '친권자 1과 친권자 2는 동일인 지정이 불가합니다.')
          return
        }
        this.lv_PrtrPeson2 = custInfo
        // TEST 친권유형코드 - 값 임시 지정
        // this.lv_PrtrPeson2.prtrTypCd = '02' // 02:2인친권
      }
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인 버튼 선택 시 모화면으로 데이터 전달
     ******************************************************************************/
    fn_Confirm () {
      this.lv_PrtrPesonInfo = []
      let chkPrtr = ''
      if (this.lv_ChkPrtr !== '') { // 1인 친권자, 공동친권
        if (Object.keys(this.lv_PrtrPeson1).length === 0) {
          piCommonUtil.getFDPConfirm('s', '친권자 1 설정해주세요.')
          return
        }
        this.lv_PrtrPesonInfo.push(this.lv_PrtrPeson1)
        chkPrtr = this.lv_ChkPrtr
      } else {
        // 친권자 설정 X 경우
        if (Object.keys(this.lv_PrtrPeson1).length === 0 || Object.keys(this.lv_PrtrPeson2).length === 0) {
          piCommonUtil.getFDPConfirm('s', '친권자 1, 친권자 2 모두 설정해주세요.')
          return
        }
        this.lv_PrtrPesonInfo.push(this.lv_PrtrPeson1)
        this.lv_PrtrPesonInfo.push(this.lv_PrtrPeson2)
        chkPrtr = this.lv_PrtrPesonInfo.length === 2 ? '02' : this.lv_ChkPrtr
      }
      console.log('lv_PrtrPesonInfo ===> ', this.lv_PrtrPesonInfo)
      this.$emit('confirmInfo', {lv_PrtrPesonInfo: this.lv_PrtrPesonInfo, prtrVal: chkPrtr})
    }
  }
}
</script>
