/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI251P
 * 화면설명: 발행 > 수신고객목록
 * 접근권한: 
 * 작 성 일: 2023.03.22
 * 작 성 자: ---
 */
<template>
  <ur-page-container class="msp" title="수신고객목록" :show-title="true" type="subpage" action-type="none" :topButton="true">    
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pt15">  
        <mo-list-item>
          <div class="list-item__contents pb10">
            <div class="list-item__contents__info pal0">
              <span class="crTy-bk1">선택한 {{lv_reciCustList.length}}명의 고객 중 <b class="crTy-blue2">{{modals.list1 ? countEmlReciCustList : countSmslReciCustList}}명</b>의 고객에게 문자를 발송합니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
          <!-- <mo-list :list-data="lv_reciCustList"> -->
          <mo-list :list-data="lv_reciMktCustList">
            <template #list-item="{item, index}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip" @click="fn_OpenCustInfoBs(item)">{{item.custNm}}</span>
                    <template v-if="modals.list2">
                      <mo-badge v-if="(item.mktCnsntYn === 'Y') && item.iCCdCustMktPsbInfoVO.mktCnvsMthSmsYn === 'Y' && !(!item.celno || item.celno.length < 11)" class="badge-sample-badge green sm" text="" shape="status">가능</mo-badge>
                      <mo-badge v-else class="badge-sample-badge red2 sm" text="" shape="status">불가</mo-badge>
                    </template>
                    <template v-if="modals.list1">
                      <mo-badge v-if="(item.mktCnsntYn === 'Y') && lv_CustMktInfo[index].iCCdCustMktPsbInfoVO.mktCnvsMthEmailYn === 'Y' && !(!item.custEmailAddr || item.custEmailAddr.length >= 3)" class="badge-sample-badge green sm" text="" shape="status">가능</mo-badge>
                      <mo-badge v-else class="badge-sample-badge red2 sm" text="" shape="status">불가</mo-badge>
                    </template>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.ralDob)}}</span>
                  </div>
                  <div class="list-item__contents__info" v-if="modals.list2">
                    <span class="crTy-bk1">문자수신 
                      <b class="ml6 crTy-blue2" v-if="item.mktCnsntYn === 'Y' && lv_CustMktInfo[index].iCCdCustMktPsbInfoVO.mktCnvsMthSmsYn === 'Y'">{{item.mktCnsntYn}}</b>
                      <b class="ml6 crTy-orange2" v-else>N</b>
                    </span><em>|</em>
                    <span class="crTy-bk1">휴대폰번호 
                      <b class="ml6 crTy-orange2" v-if="!item.celno || item.celno.length < 11">N</b>
                      <b class="ml6 crTy-blue2" v-else>Y</b>
                    </span>
                  </div>
                  <div class="list-item__contents__info" v-if="modals.list1">
                    <span class="crTy-bk1">이메일수신 
                      <b class="ml6 crTy-blue2" v-if="item.mktCnsntYn === 'Y' && lv_CustMktInfo[index].iCCdCustMktPsbInfoVO.mktCnvsMthEmailYn === 'Y'">{{item.mktCnsntYn}}</b>
                      <b class="ml6 crTy-orange2" v-else>N</b>
                    </span><em>|</em>
                    <span class="crTy-bk1">이메일주소 
                      <b class="ml6 crTy-orange2" v-if="!item.custEmailAddr || item.custEmailAddr.length < 3">N</b>
                      <b class="ml6 crTy-blue2" v-else>Y</b>
                    </span>
                  </div>
                  <!-- <div class="list-item__contents__info">
                    <span class="crTy-bk1">이메일수신 <b class="ml6" :class="{'crTy-blue2' : item.data4 === 'Y', 'crTy-orange2' : item.data4 === 'N'}">{{item.data4}}</b></span><em>|</em>
                    <span class="crTy-bk1">이메일주소 <b class="ml6" :class="{'crTy-blue2' : item.data5 === 'Y', 'crTy-orange2' : item.data5 === 'N'}">{{item.data5}}</b></span>
                  </div> -->
                </div>
              </mo-list-item>
            </template>
          </mo-list> 
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="countSmslReciCustList===0" @click="fn_btnK1Click">터치On</mo-button> -->
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_logCloseClick">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="countSmslReciCustList===0" @click="fn_btnK1Click">다음</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import SaConstants from '@/config/constants/saConstants'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI251P',
  screenId: 'MSPPI251P',
  components: {
  },
  props: {
    pChnlCustIds: Array, // 채널고객id 목록
    pSelectedUntySlmtItemList: Array, // 선택된 자료 목록
    pTargetType: String,
    pIsSelectedMultiItem: Boolean,
    pSelectedSingleItem: Object
  }, // parents data
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************/
  created () {
    // if (SaConstants.DEBUG_MODE_CON) window.console.log('mounted: START...')
    // this.fn_Init() // 정보활용동의 고객 상세 조회
    // if (SaConstants.DEBUG_MODE_CON) window.console.log('mounted: END...')
  },
  mounted () { // Life Cycle Step-4 마운트 된 후 호출
    if (SaConstants.DEBUG_MODE_CON) window.console.log('mounted: START...')
    this.fn_Init() // 정보활용동의 고객 상세 조회
    if (SaConstants.DEBUG_MODE_CON) window.console.log('mounted: END...')
  },
  data () {
    return {
      DEBUG_MODE_CON: !!(SaConstants.DEBUG_MODE_CON), // 디버그 모드설정 (콘솔)
      DEBUG_MODE_BRW: !!(SaConstants.DEBUG_MODE_BRW), // 디버그 모드설정 (브라우져)
      // showModal_TSSCM222P: false,
      modals: {
        list1: true,
        list2: false
      },
      lv_parentVId: String,
      lv_dspchScCd: String, // 발송구분 (고객목록 메인에서 넘겨준 파라미터 설정)
      lv_chnlCustIds: [],
      // lv_reciCustList: mockData[0].cMCustListSVO.iCEtchCntntCustVO,
      lv_reciCustList: [],
      lv_reciMktCustList: [], // 수신고객+마케팅 고객
      lv_CMCustListSVO: [],
      lv_CustMktInfo: [], // 정보제공동의내역 정보
      lv_SearchText: '',
      lv_TotalCnt: Number,
      lv_Headers: [],
      lv_Items: [],
      lv_Params: [],
      lv_IsConfirmSendEmail: false, //
      lv_IsConfirmSendEmailError: false,
      lv_SendEmailItemCount: 0,
      // (SMS/이메일 전송여부) confirm 창
      bDspchConfirm: false,
      // 사용자 정보
      userInfo: {},
      lv_EmailTargetCustList: [], // 이메일발송 대상 고객
      lv_SMSTargetCustList: [], // SMS발송 대상 고객
      cMCustListSVO: {
        cnsltNo: '', // 컨설턴트번호
        reqScCd: '', // 요청구분코드
        iCEtchCntntCustVO: [
          {
            custNm: '이주명', // 고객명
            chnlCustId: '59476541548030506102', // 채널고객ID
            dob: '970430', // 생년월일
            ralDob: '970430', // 실제생년월일
            custId: 'IDXXX001', // 고객ID
            mktCnsntYn: 'N', // 마케팅동의여부
            infoCnsntYn: 'N', // 정보동의여부
            smsReciCnsntYn: 'Y', // SMS수신동의여부
            emailReciCnsntYn: 'Y', // 이메일수신동의여부
            cnsntYn: 'N', // 동의여부
            tuchYn: 'N', // 터치여부
            custEmailAddr: '', // 고객이메일주소
            celno: '010-9999-9999', // 휴대폰번호
            samScCd: '' // 음양구분코드(?)
          }
        ]
      },
      lv_SelectedFomItemList: [], // 상세보기시 FOM 파일과 PDF 파일 동시 선택시 FOM 파일만 PDF로 변환하는 작업을 처리하기 위해 FOM 파일 저장
      lv_CompletedPDFFileVOList: [], // PDF 생성 완료된 파일 정보를 저장하는 Array
      lv_SADCFmlmtSVOList: [] // PDF 파일 생성 VO 목록
    }
  },
  /***********************************************************************************
   * watch 정의 영역
   ***********************************************************************************/
  watch: {
  },

  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    countEmlReciCustList: function () {
      if (SaConstants.DEBUG_MODE_CON) window.console.log(this.lv_dspchScCd)
      if (SaConstants.DEBUG_MODE_CON) window.console.log('this.lv_reciCustList.length=' + this.lv_reciCustList.length)
      let _count = 0 // 초기값 설정
      if (this.pTargetType === 'e') {
      // if (this.lv_dspchScCd === 'e') { // 이메일 발송일 겨우
        if (this.lv_reciMktCustList && this.lv_reciMktCustList.length > 0) {
          for (let i = 0, len = this.lv_reciMktCustList.length; i < len; i++) { // 이메일주소가 유효한 고객만 카운팅
            if (this.lv_reciMktCustList[i].mktCnsntYn === 'Y' && this.lv_reciMktCustList[i].iCCdCustMktPsbInfoVO.mktCnvsMthEmailYn === 'Y' && this.lv_reciMktCustList[i].custEmailAddr && this.lv_reciMktCustList[i].custEmailAddr.length >= 3) {
              if (SaConstants.DEBUG_MODE_BRW) window.console.log(this.lv_reciMktCustList[i].custEmailAddr)
              _count++
            }
          }
        }
        // if (this.lv_reciCustList && this.lv_reciCustList.length > 0) {
        //   for (let i = 0, len = this.lv_reciCustList.length; i < len; i++) { // 이메일주소가 유효한 고객만 카운팅
        //     if (this.lv_reciCustList[i].mktCnsntYn === 'Y' && this.lv_reciCustList[i].custEmailAddr && this.lv_reciCustList[i].custEmailAddr.length >= 3) {
        //       if (SaConstants.DEBUG_MODE_BRW) window.console.log(this.lv_reciCustList[i].custEmailAddr)
        //       _count++
        //     }
        //   }
        // }
      }
      return _count
    },
    countSmslReciCustList: function () {
      // if (SaConstants.DEBUG_MODE_CON) window.console.log(this.lv_dspchScCd)
      let _count = 0 // 초기값 설정
      if (this.lv_dspchScCd === 's') { // SMS 발송인 경우
        if (this.lv_reciMktCustList && this.lv_reciMktCustList.length > 0) {
          for (let i = 0, len = this.lv_reciMktCustList.length; i < len; i++) { // 휴대폰번호가 유효한 고객만 카운팅
            if (SaConstants.DEBUG_MODE_CON) window.console.log('this.lv_reciCustList[i].mktCnsntYn=' + this.lv_reciMktCustList[i].mktCnsntYn + ',this.lv_reciCustList[i].celno=' + this.lv_reciCustList[i].celno)
            if (this.lv_reciMktCustList[i].mktCnsntYn === 'Y' && this.lv_reciMktCustList[i].celno && this.lv_reciMktCustList[i].iCCdCustMktPsbInfoVO.mktCnvsMthSmsYn === 'Y' && this.lv_reciMktCustList[i].celno.length >= 11) {
              if (SaConstants.DEBUG_MODE_BRW) window.console.log(this.lv_reciMktCustList[i].celno)
              _count++
            }
          }
          if (SaConstants.DEBUG_MODE_BRW) window.console.log('_count=' + _count)
        }
        // if (this.lv_reciCustList && this.lv_reciCustList.length > 0) {
        //   for (let i = 0, len = this.lv_reciCustList.length; i < len; i++) { // 휴대폰번호가 유효한 고객만 카운팅
        //     if (SaConstants.DEBUG_MODE_CON) window.console.log('this.lv_reciCustList[i].mktCnsntYn=' + this.lv_reciCustList[i].mktCnsntYn + ',this.lv_reciCustList[i].celno=' + this.lv_reciCustList[i].celno)
        //     if (this.lv_reciCustList[i].mktCnsntYn === 'Y' && this.lv_reciCustList[i].celno && this.lv_reciCustList[i].celno.length >= 11) {
        //       if (SaConstants.DEBUG_MODE_BRW) window.console.log(this.lv_reciCustList[i].celno)
        //       _count++
        //     }
        //   }
        //   if (SaConstants.DEBUG_MODE_BRW) window.console.log('_count=' + _count)
        // }
      }
      return _count
    }
  },
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 최초 로딩 시 필요한 공통 및 업무데이터 처리 및 출력
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Init () {
      if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ 수신고객목록 (fn_Init) START')
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ 수신고객목록 (fn_Init) this.userInfo=' + this.userInfo)

      this.lv_dspchScCd = this.pTargetType
      this.lv_parentVId = this.$props.pChnlCustIds[0].parentsViewId // 호출 화면아이디 (고객목록 메인에서 넘겨준 파라미터 설정)
      this.lv_dspchScCd = this.$props.pChnlCustIds[0].targetType // 발송구분 (고객목록 메인에서 넘겨준 파라미터 설정)
      this.lv_chnlCustIds = this.$props.pChnlCustIds[0].custList // 채널고객아이디 목록 (고객목록 메인에서 넘겨준 파라미터 설정)
      if (this.lv_dspchScCd === 'e') {
        this.modals.list1 = true
        this.modals.list2 = false
      } else {
        this.modals.list1 = false
        this.modals.list2 = true
      }
      // 수신고객목록 조회
      this.fn_selReciCustList()
      
      if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ 수신고객목록 (fn_Init) END')
    },
    /*********************************************************
     * Function명: fn_selReciCustList
     * 설명: 최초 로딩 시 전달 값을 확인하여 SMS/이메일 수신동의여부를 조회한다.
     * Params: cMCustListSVO: {
     *           cnsltNo: '', 컨설턴트 번호 (String)
     *           iCEtchCntntCustVO: {
     *             chnlCustId: '' 채널고객ID
     *           }
     *         } 수신고객목록정보 (Object)
     *         lv_SndScCd: '' 발송채널(SMS/이메일)구분
     *
     * Return: (SMS/이메일) 수신동의고객 목록
     *********************************************************/
    fn_selReciCustList () {
      // txTSSCM34S1
      if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ 수신고객목록 (fn_selReciCustList) START')
      var _lv_Vm = this
      // const _trnstId = 'txTSSCM33S5'
      // const _trnstId = 'txMSPCM33S5'
      const _trnstId = 'txTSSCM47S6'

      const _auth = 'S'
      let _pParams = _lv_Vm.cMCustListSVO
      _pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId // 컨설턴트번호(세션 및 계정정보 처리유틸에서 가져와야 함)
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        _pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      _pParams.reqScCd = 'IC' // 요청구분코드
      _pParams.dspchScCd = this.lv_dspchScCd // 발송구분코드(sms: s / email : e)
      _pParams.iCEtchCntntCustVO = Array.prototype.slice.call(_lv_Vm.lv_chnlCustIds)

      this.post(_lv_Vm, _pParams, _trnstId, _auth)
        .then(function (response) {
          // if (_lv_Vm.DEBUG_MODE_CON) window.console.log('axios.post 수행 시작...')
          if (_lv_Vm.DEBUG_MODE_BRW) window.console.log('axios.post 수행 시작...')
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if ( _lv_Vm.$commonUtil.isSuccess(response) ) {
            if (response.body !== null && response.body.iCEtchCntntCustVO !== null && response.body.iCEtchCntntCustVO.length > 0) {
            // 서버 데이터 가져오기
              _lv_Vm.lv_CMCustListSVO = response.body.iCEtchCntntCustVO
              // ---------------------------------------------------------------------------------
              // 프사서, VIP 등의 경로로 접근한 처리 시, 필수 파라미터(사번) 상이로 null 값 예외처리 적용
              // ---------------------------------------------------------------------------------
              if (Array.isArray(_lv_Vm.lv_CMCustListSVO)) {
              // 검색 후, 전체 데이터를 다시 보여주기 위해 복사본 저장
                _lv_Vm.lv_reciCustList = _lv_Vm.lv_CMCustListSVO.slice()
                
              } else {
                _lv_Vm.lv_reciCustList = _lv_Vm.lv_CMCustListSVO
              }
              //
              // _lv_Vm.fn_selCustCnsntBrkdn()
              // 서버에서 totalCount를 보내줘야 함
              _lv_Vm.lv_TotalCnt = response.body.totalCnt
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            // _lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            _lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          // console.log('axios.post 수행 완료...')
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          // _lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
          _lv_Vm.getStore('confirm').dispatch('ADD', _lv_Vm.$t('cm')['error_message'])
        })
        .finally(()=>{
          if (Array.isArray(_lv_Vm.lv_reciCustList) && _lv_Vm.lv_reciCustList.length > 0) {
            _lv_Vm.fn_selCustCnsntBrkdn()
          }
        })
    },
    /******************************************************************************
     * Function명: fn_selCustCnsntBrkdn
     * 설명: 서비스 호출(고객 정보제공동의내역 정보 조회)
     ******************************************************************************/
    fn_selCustCnsntBrkdn () {
      console.log('======= fn_selCustCnsntBrkdn')
      if (SaConstants.DEBUG_MODE_CON) window.console.log('=== fn_selCustCnsntBrkdn')
      const trnstId = 'txTSSCM34S1'
      const auth = 'S'
      let confirm = this.getStore('confirm')

      var lv_Vm = this
      let cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      let selCnt = 0
      if (lv_Vm.lv_reciCustList.length > 0) {
        let pParams = {cnsltNo: cnsltNo}
        for (let i=0; lv_Vm.lv_reciCustList.length; i++) {
          if (selCnt === lv_Vm.lv_reciCustList.length) {
            window.vue.getStore('progress').dispatch('HIDE')
            break
          }
          let selItem = lv_Vm.lv_reciCustList[i]
          if (!_.isEmpty(selItem) && !_.isEmpty(selItem.chnlCustId)) {
            pParams.chnlCustId = selItem.chnlCustId
            pParams.custId = _.isEmpty(selItem.custId) ? ' ' : selItem.custId
            selCnt++
            console.log('selCnt === ', selCnt)
          
            this.post(lv_Vm, pParams, trnstId, auth)
              .then(function (response) {
                // 서버 데이터 가져오기
                if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
                  let responseList = response.body
                  if (responseList !== null) {
                    // console.log('정보제공동의내역 정보 조회 ==> ', responseList)
                    let inObj = lv_Vm.$bizUtil.cloneDeep(lv_Vm.lv_reciCustList[selCnt-1])
                    inObj.iCCdCustMktPsbInfoVO = responseList.iCCdCustMktPsbInfoVO // 연락가능방법
                    inObj.iCChnlCustMktPrcusCnsntInfoVO = responseList.iCChnlCustMktPrcusCnsntInfoVO // 마케팅활용동의
                    lv_Vm.lv_reciMktCustList.push(inObj) // 마케팅활용동의
                    lv_Vm.lv_CustMktInfo.push(responseList)
                    // console.log('정보제공동의내역 추가 정보 ====> ', lv_Vm.lv_reciMktCustList[selCnt-1])
                  }
                } else {
                  piCommonUtil.getFDPConfirm('s', response.msgComm.msgDesc)
                  window.vue.getStore('progress').dispatch('HIDE')
                }
              })
              .catch(function (error) {
                window.vue.error(error)
                window.vue.getStore('progress').dispatch('HIDE')
              })
              .finally(() => {
                window.vue.getStore('progress').dispatch('HIDE')
              })
          } else {
            let inObj = lv_Vm.$bizUtil.cloneDeep(lv_Vm.lv_reciCustList[selCnt-1])
            inObj.iCCdCustMktPsbInfoVO = null // 연락가능방법
            inObj.iCChnlCustMktPrcusCnsntInfoVO = null // 마케팅활용동의
            lv_Vm.lv_reciMktCustList.push(inObj) // 
          }
        }
      }
    },
    /*********************************************************
     * Function명: fn_btnK1Click
     * 설명: 확인 버튼을 누르면 전달받은 SMS이메일구분에 따라 SMS이나 이메일전송 화면을 띄운다
     * Params: cnsltNo: 컨설턴트 번호 (String)
     *         chnlCustIds: 채널고객ID[다건] (List)
     *
     * Return: N/A
     *********************************************************/
    fn_btnK1Click () {
      if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ 확인(fn_btnK1Click) START ☞ ' + new Date().getTime())

      // let customerIds = ''
      if (this.countEmlReciCustList === -1 && this.countSmslReciCustList === -1) {
      // if (this.countEmlReciCustList === 0 && this.countSmslReciCustList === 0) {

      } else {
        this.lv_EmailTargetCustList = []
        this.lv_SMSTargetCustList = []
        // let t_Type = ''
        if (this.pTargetType === 'e') { // email
          // if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV) {
          // // 테스트용 코드 START
          //   this.lv_reciCustList = []
          //   let tmpObj = {}

          //   tmpObj.chnlCustId = '1000028741'
          //   tmpObj.custNm = '정규경'
          //   tmpObj.custEmailAddr = 'cssmail1@ets1.v.ets.samsunglife.kr'
          //   tmpObj.mktCnsntYn = 'Y'
          //   this.lv_reciCustList.push(tmpObj)
          // }

          for (let i = 0; i < this.lv_reciCustList.length; i++) {
            if (this.lv_reciCustList[i].mktCnsntYn === 'Y' && this.lv_reciCustList[i].custEmailAddr && this.lv_reciCustList[i].custEmailAddr.length >= 3) {
              this.lv_EmailTargetCustList.push(this.lv_reciCustList[i])
            }
          }

          if (this.lv_EmailTargetCustList.length > 0) {
            // 이메일 발송
            this.$emit('evtMSPPI251PNextStep', 'e', this.lv_EmailTargetCustList)
            // this.$emit('evtTSSSA016PNextStep', 'e', this.lv_EmailTargetCustList)
            // this.fn_ReadySendEmail()
            // this.fn_SendEmail()
          }
        } else if (this.pTargetType === 's') {
          for (let i = 0; i < this.lv_reciCustList.length; i++) {
            if (this.lv_reciCustList[i].mktCnsntYn === 'Y' && this.lv_reciCustList[i].celno && this.lv_reciCustList[i].celno.length >= 3) {
              this.lv_SMSTargetCustList.push(this.lv_reciCustList[i])
            }
          }

          if (SaConstants.DEBUG_MODE_CON) window.console.log('■■■■■ MSPPI251P ■■■■■ t_Param.length=' + this.lv_EmailTargetCustList.length)

          // SMS 발송 대상 고객이 존재하면
          if (this.lv_SMSTargetCustList.length > 0) {
            // SMS 발송
            // this.fn_SendSMSList()
            this.$emit('evtMSPPI251PNextStep', 's', this.lv_SMSTargetCustList)
            // this.$emit('evtTSSSA016PNextStep', 's', this.lv_SMSTargetCustList)
          }
        }
      }
    },
    /*********************************************************
     * Function명: fn_logCloseClick
     * 설명: 화면을 닫는다.
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_logCloseClick () {
      if (SaConstants.DEBUG_MODE_BRW) window.console.log('fn_logCloseClick 클릭...')
      // this.bDspchConfirm = true
      this.$emit('confirmPopup', false)
    },
    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      let nmUiObject = {}

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : lv_Vm.$options.screenId, //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },
  }
}
</script>