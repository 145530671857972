/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM042P
 * 화면설명 : 일련번호 선택 팝업
 * 작 성 일 : 2022.12.28
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="일련번호 선택" :show-title="true" type="subpage" action-type="search" :topButton="true" @action-search-click="fn_Cancle">

    <template #frame-header-fixed>
      <!-- 전체 건 표시 영역 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <span class="count">{{ pblNoList.length }}</span>건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2"> 
        <!-- 검색결과 있을 경우 -->
        <mo-list :list-data="pblNoList">
          <template #list-item="{ item }">
            <mo-list-item :class="{'on' : selectRdo === item.sno}">
              <ur-box-container alignV="start" componentid="" direction="row" class="pt6 pb6"> <!-- 퍼블 : 디자인점검 -->
                <!-- 라디오 버튼 영역 -->
                <mo-radio class="mb0" v-model="selectRdo" :value="String(item.sno)" @input="fn_CheckSelectedItem(item)"/>

                <!-- 체크박스 영역 확인 필요.. -->
                <div class="list-item__contents" @click="fn_CheckSelectedItem(item)">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <span class="name txtSkip">{{ item.sno }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <div class="txt_box">
                      <span>{{ item.rrn }}</span>
                    </div>
                  </div>
                </div>
              </ur-box-container>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
    </ur-box-container>

    <!-- NoData 영역: start  -->
    <ur-box-container v-else direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>
    <!-- NoData 영역: end  -->

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancle">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InsWcnstPblNoPrcusYn" :disabled="selectRdo === ''">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>

  </ur-page-container>
</template>

<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
    name: "MSPCM042P",
    screenId: "MSPCM042P",
    props: {
      pCustInfo: { // 고객정보
        type: Object,
        default: {}
      },
      pCnsltWcnstPblNo: { // 일련번호
        type: String,
        default: ''
      }
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
    data () {
      return {
        title: '일련번호 선택', // 화면명

        lv_UserInfo: {}, // 사용자 정보
        pblNoList: [], // 동의서발행번호 리스트
        selectRdo: '', // 선택한 라디오 값
        selectedItem: {}, // 선택한 값 Obj

        isEmptyView: false // 데이터 유무 체크
      };
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM042P')

      this.fn_Init()
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_Init () {
        this.fn_SearchSno()
      },
      /******************************************************************************
       * @function fn_SearchSno
       * @notice   일련번호 조회 서비스 Function
      *******************************************************************************/
      fn_SearchSno () {
        const lv_Vm = this

        let pParams = { cnsltWcnstPblNo: this.pCnsltWcnstPblNo.trim() }// 발행번호

        const trnstId = 'txTSSCM11S3'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            if (!_.isEmpty(res.body) && !_.isEmpty(res.body.cMWcnstPblNoListVO)) {
              lv_Vm.pblNoList = res.body.cMWcnstPblNoListVO

              if (!_.isEmpty(lv_Vm.pblNoList)) {
                lv_Vm.isEmptyView = false
              } else {
                lv_Vm.isEmptyView = true
              }
            } else {
              lv_Vm.pblNoList = []
              lv_Vm.isEmptyView = true
            }
          })
          .catch(error => {
            window.vue.error(error)
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          });

      },
      /******************************************************************************
       * @function fn_CheckSelectedItem
       * @notice   row 선택 시 호출. 선택된 row의 radio value를 변경
      *******************************************************************************/
      fn_CheckSelectedItem (item) {
        this.selectedItem = item
        this.selectRdo = String(item.sno)
      },
      /**************************************************************************************
       * @function fn_InsWcnstPblNoPrcusYn
       * @name     동의서발행번호활용여부등록   
       * @notice   동의서 발행 번호 활용 여부 등록 서비스 호출
      ***************************************************************************************/
      fn_InsWcnstPblNoPrcusYn () {
        const lv_Vm = this
        this.fn_Validate()

        let pParam = {
          custNm: this.pCustInfo ? this.pCustInfo.custNm : '' ,   // 고객명
          chnlCstgrId: this.pCustInfo ? this.pCustInfo.chnlCstgrId : '', // 채널그룹ID
          chnlCustId: this.pCustInfo ? this.pCustInfo.chnlCustId : '', // 채널고객ID
          chnlCustScCd: this.pCustInfo ? this.pCustInfo.chnlCustScCd : '', // 고객구분코드
          knb: this.pCustInfo ? this.pCustInfo.knbFrno + this.pCustInfo.knbBkFstNo : '', // 고객구분코드
          cnsltWcnstPblNo: this.pCnsltWcnstPblNo, // 동의서발행 번호
          sno: this.selectedItem.sno,   // 선택한 동의서발행 sno

          cMWcnstPblNoListVO: [this.selectedItem] // 선택한 동의서발행 type: List
        }

        const trnstId = 'txTSSCM11I1'
        const auth = 'S'
        this.post(lv_Vm, pParam, trnstId, auth)
          .then(res => {
            if (!_.isEmpty(res.body)) {
              if (res.body.respCd === 'C000') {
                  lv_Vm.fn_Confirm()
              }
            } else {
              let alertMsg = res.msgComm.msgDesc
              lv_Vm.fn_OpenBottomAlert(alertMsg)
            }
          })
          .catch(error => {
            window.vue.error(error)
          });

      },
      /**************************************************************************************
       * @function fn_Cancle//fn_Confirm
       * @notice   fn_Cancle: 해당 팝업 닫기 / (뒤로가기, 취소, 돋보기) 버튼
       *           fn_Confirm: 선택한 Item.rrn과 일련번호 전달하며, 해당 팝업 닫기 / 확인 버튼
      ***************************************************************************************/
      fn_Cancle () { this.$emit('onPopupCancel') },
      fn_Confirm () {
        if (this.selectedItem !== null) {
          let pParam = {
            chnlCustId: this.selectedItem.chnlCustId, // 채널고객ID
            custNm: this.selectedItem.userNm, // 고객명
            rrn: this.selectedItem.rrn, // 주민번호
            knbBknoCipher: this.selectedItem.knbBknoCipher, // 주민번호 뒷번호(암호화)
            sno: this.selectedItem.sno, // 일련번호
            cnsltWcnstPblNo: this.pCnsltWcnstPblNo // 동의서발행ID
          }
          this.$emit('onPopupConfirm', pParam)
        }
      },
      /**************************************************************************************
       * @function fn_OpenBottomAlert
       * @name     알럿호출
       * @notice   바텀시트 알럿 노출
      ***************************************************************************************/
      fn_OpenBottomAlert (alertMsg) {
        const lv_Vm = this

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            content: alertMsg
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      },
      /**************************************************************************************
       * @function fn_Validate
       * @name     주민번호체크
       * @notice   성별자리수가 0, 9일경우 선택 불가
      ***************************************************************************************/
      fn_Validate () {
        let rtnValue = false
        let itemRrn = this.selectedItem.rrn
        
        if (!this.$bizUtil.isEmpty(itemRrn)) {
          rtnValue = itemRrn.substr(6,1) === '0' || itemRrn.substr(6,1) === '9'
        }

        if (rtnValue) {
          let alertMsg = '뒷자리가 0,9로 시작하는 유효하지않은 주민번호 값입니다.<br>ARS 재입력 부탁드립니다.'
          this.fn_OpenBottomAlert(alertMsg)
        }

        return rtnValue
      }
    } // ::Methods End
  };
</script>
