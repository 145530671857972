/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="AI 전화받기 서비스 소개" :show-title="false" class="msp" type="popup">

    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <!-- 본문내용 -->
        <mo-bottom-sheet ref="nsbottomsheet" :close-btn="true" class="ns-bottom-sheet" @closed="fn_close">

          <template v-slot:title><div class="campaignTitle crTy-blue3">AI 전화받기 서비스 소개</div></template>
          <div class="content-area" style="margin-top:0px">
            <!-- 슬라이딩 배너 영역 -->
            <div class="profile_guide mt20">
              <mo-carousel number-of-page="4" style="height:400px" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" class="guide_roll">
                <template #1>
                  <div class="guide_group">
                    <div class="txt-block txt-center">
                      <span class="fs30rem fwb">통화 전, 고객정보를<br/>미리 확인할 수 있어요</span>
                    </div>
                    <div class="guide-img mt20">
                      <img src="@/assets/img/content/call_turtorial01.png" />
                    </div>
                  </div>
                </template>
                <template #2>
                  <div class="guide_group">
                    <div class="txt-block txt-center">
                      <span class="fs30rem fwb">고객카드로 바로 <br/>이동할 수 있어요</span>
                    </div>
                    <div class="guide-img mt20">
                      <img src="@/assets/img/content/call_turtorial02.png" />
                    </div>
                  </div>
                </template>
                <template #3>
                  <div class="guide_group">
                    <div class="txt-block txt-center">
                      <span class="fs30rem fwb">통화 후에는 음성으로 <br/>메모를 남길 수 있어요</span>
                    </div>
                    <div class="guide-img mt20">
                        <img src="@/assets/img/content/call_turtorial03.png" />
                    </div>
                  </div>
                </template>
                <template #4>
                  <div class="guide_group">
                    <div class="txt-block txt-center">
                      <span class="fs30rem fwb">설정에서 언제든지<br/>켜고 끌 수 있어요</span>
                    </div>
                    <div class="guide-img mt20">
                        <img src="@/assets/img/content/call_turtorial04.png" />
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
            <!-- 슬라이딩 배너 영역 //-->
          </div>

          <!-- 버튼영역 설정버튼 -->
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div fex-rw">
                <!-- 수정 2025.1.7 :chan -->
                <mo-button componentid="mo_button_004" color="primary" shape="solid" size="medium" class="ns-btn-round white bottomButtonNarrow" id="abc" @click="$emit('onPopupClose')" v-show="showButton">다음에 할게요</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue"  @click="$emit('onPopupConfirm')">바로 시작하기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 본문내용 끝 -->
      </ur-box-container>
    </ur-box-container>


  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // 디바이스백키 비활성화
      window.fdpbridge.exec('backkeyCtrlPlugin', {value: false}, () => {}, () => {})
    },
    mounted () {
      // Alert 노출(임시)
      this.$refs.nsbottomsheet.open()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 전체 추가 2025.1.7 :chan
        showButton:false,
        swiperOption: {
          centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: false,
          allowSlidePrev: true,
          on: {
            slideChange: function() {
              if(this.activeIndex === 4 || this.activeIndex === 0){
                // on last slide
                document.getElementById('abc').style.display = 'block'
              } else {
                document.getElementById('abc').style.display = 'none'
              }
            },
            reachEnd: function() {},
            reachBeginning: function() {}
          },
        }
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      openPop() { this.$refs.nsbottomsheet.open(); },
      closePop() { this.$refs.nsbottomsheet.close(); },
      fn_close() {
        this.$emit('onPopupExit')
      }
    }
  };
</script>
<style scoped>
/* 2024.9.30 :chan */
.campaignTitle {width:100%; padding-right:30px}
</style>