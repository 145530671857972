/*
* 업무구분: 이벤트고객
* 화면 명: MSPAP320M
* 화면 설명: 이벤트고객
* 화면 접근권한: 모든 사용자
*/
<template> 
  <ur-page-container class="msp" :show-title="true" title="활동캘린더" type="subpage" :action-type="actionSlot" :topButton="false" @on-header-left-click="fn_goMain" :customer="fcData.label" :customerID="fcData.key">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="top-info pl9 pr9">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="filter" class="scheduler-chip">
            <mo-segment-button value="1" class="circle yellow">생일</mo-segment-button>
            <mo-segment-button value="2" class="circle light-blue">계약1주년</mo-segment-button>
            <mo-segment-button value="3" class="circle green">상령일</mo-segment-button>
            <mo-segment-button value="4" class="circle purple">만기일</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-schedule msp-schedule">
        <mo-scheduler ref="loginScheduler"  displayPeriodUom="month" :displayPeriodCount="1" :showEmptyDay="true" eventTop="31px" eventContentHeight="20px"
          :events="filteredEvent" :shrink="shrink" :holidays="holidays" :msg-today="lv_TextToday" 
          @period-changed="fn_PeriodChanged" @click-date="fn_SelectDate" > 
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon icon="msp-calendar"></mo-icon>
            </div>
          </template>
        </mo-scheduler>

      </ur-box-container>
    </ur-box-container>

    <!-- Popup001 -->
    <mo-bottom-sheet ref="refDtailSheet" class="ns-bottom-sheet pb0 closebtn">
      <template v-slot:title>
        <span>{{titleDate}}</span>
        <span class="fs14rem fwn ml10">{{titleDay}}</span>
        <span class="badge-type fs14 fwn ml10" :class="dtlClass" >{{dtlTitle}}</span>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
          <mo-list-item>
            <mo-checkbox size="small" v-model="checkAll" @click="fn_CheckAll" />
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총<span class="count">{{dtlData.length}}</span>명</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </template>
      <!-- close 버튼 Sample -->
      <div class="ns-btn-close" @click="fn_close"></div>  

      <!-- content 영역 -->
      <div class="content-area maxh95 pl0 pr0">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa2024">
          <mo-list :list-data="dtlData">
            <template #list-item="{item}">
              <mo-list-item :class="{'on' : item.checked === true}">
                <mo-checkbox v-model="item.checked"/>
                <div class="list-item__contents" v-if="filter == '1' || filter == '2' || filter == '3' || filter == '4'">
                  <div class="list-item__contents__title fexTy3 mb0">
                    <span class="name txtSkip-inline flex-1 fs19rem mr10 on-txt" @click="fn_OpenCustInfoBs(item.chnlCustId, item.name)">{{item.name}}</span>
                    <span class="fs16rem fwn" v-if="filter == '1'">{{String(item.birthday).substring(0,4)}}년생</span>
                    <span class="min60 txt-right" v-if="filter == '1'"><span class="badge-type location fs12 fwn pa10 h20">{{$bizUtil.replaceAll(String(item.relation), '생일', '')}}</span></span>
                  </div>
                  <div class="list-item__contents__info" v-if="filter == '2' || filter == '4'">
                    <span class="fs16rem crTy-bk1 full txtSkip-inline">{{item.product}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </div>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs" v-if="mngSearch">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_EmailClick" class="ns-btn-round white">이메일</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_SmsClick" class="ns-btn-round blue">문자</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container> 
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'

import MSPCM128P from '@/ui/cm/MSPCM128P' // 문자 수신고객 목록
import MSPCM191P from '@/ui/cm/MSPCM191P' // 이메일 수신고객 목록
import MSPAP106P from '@/ui/ap/MSPAP106P' // 지점장권한 fc조회

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPAP320M",
  screenId: "MSPAP320M",
  components: {
    // 'MSPAP302D': MSPAP302D
  },
  props: {
  
    // 밖에서 열리거나 꺼질때 새로 데이터를 매핑하기 위함.
    flag: {
      type: Boolean
    },
    // 변형되지 않을 오늘 Date Obj
    pToday: {
      type: Date,
      default () { return new Date() }
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_TextToday         : moment(new Date().toISOString()).format('DD')+'일', // 오늘 날짜 입력되도록 Data 가져와 주세요
      lv_NowLoadingHoliday : false,
      dtlData              : [],
      inqrStrYmd           : '',
      inqrEndYmd           : '',
      inqrDtlStrYmd        : '',
      inqrDtlEndYmd        : '',
      filter               : '1',
      shrink               : false,
      titleDay             : '',
      titleDate            : '',
      dtlTitle             : '',
      dtlClass             : '',
      filteredEvent        : [],
      holidayList          : [],
      holidays             : [],
      checkAll             : false,
      template             : '',
      cnsltNm              : '',
      cnsltNo              : '',
      pScrId               : '',
      isFirst              : true,
      fcData               : {}
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)

    let tmp = this.$route.params

    this.pScrId = tmp.pScrId

    if(this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y' && Object.keys(tmp).length !== 0) { // MSPBC003M에서 타 사번 조회된 상태로넘어온 경우
      if(!this.$bizUtil.isEmpty(tmp.cnsltNo)) {
        this.cnsltNo = tmp.cnsltNo
      } else if (tmp.pData?.key){
        this.cnsltNo = tmp.pData.key
        this.fcData  = tmp.pData
      } else {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
      }
    } else {
      this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
    }

    if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
      this.template = '<span><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_mov_MSPAP314M">내 일정</mo-button><mo-icon @click="fn_openMSPAP106P">search</mo-icon></span>'
    } else {
      this.template = '<span><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_mov_MSPAP314M">내 일정</mo-button></span>'
    }

    let lv_vm = this

    this.actionSlot = {
        actionComponent: {
          template:this.template,
          methods: {
            fn_mov_MSPAP314M(e) {
              if(this.mngSearch) {
                this.$router.push({name: 'MSPAP314M', params: {cnsltNo: lv_vm.cnsltNo}})
              } else {
                this.$router.push({name: 'MSPAP314M', params: {pData: lv_vm.fcData}})
              }
            },
            fn_openMSPAP106P:() => {
              this.cnsltNm = ''
              this.cnsltNo = ''
              this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
                properties: {
                  pPage: 'MSPAP320M',
                  pData: this.fcData
                },
                listeners: {
                  onPopupSearch: (pItem, pData) => {
                    console.log('onPopupSearch 컨설턴트 >> ' , pItem)
                    console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                    if(pItem != null && pItem != undefined){

                      this.cnsltNm = pItem.label
                      this.cnsltNo = pItem.key

                      this.fcData = pItem
                      this.fn_SelListMemrdCust()
                    }
                  },
                  onPopupClose: () => {
                    console.log('onPopupClose')
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                  }
                }
              })
            }
          }
        }
      }
  },
  mounted() {
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
  },
  beforeDestroy () {
  }, 
  
  /************************************************************************************************
  * watch 함수 정의 영역
  ************************************************************************************************/
  watch: {
    holidayList () {
      this.holidays.splice(0, this.holidays.length)
      for (let i = 0; i < this.holidayList.length; i++) {
        var tempClass = this.$commonUtil.dateDashFormat(this.holidayList[i].slrcYmYmd)
        if (this.holidayList[i].holyCd === '1') { // 현재 서버에서 넘어오는 데이터 중 97코드인 것만 공휴일 표기되어야함. 로직 변경시 이 매서드도 그에 맞춰 수정해 사용하십시오
          // this.holidays.push({date: tempClass, label: this.holidayList[i].holyNm, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color'})
          this.holidays.push({date: tempClass, label: '', holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color'})
        } 
        else if (this.holidayList[i].holyCd === '2') {
          // this.holidays.push({date: tempClass, label: this.holidayList[i].holyNm, holidayClasses: ['lunar-txt-color'], dayNumberClasses: 'cv-day-number'})
          this.holidays.push({date: tempClass, label: '', holidayClasses: ['lunar-txt-color'], dayNumberClasses: 'cv-day-number'})
        }
      }
    },
    filter () { // 이벤트 구분에 따른 데이터 변경
      let lv_Vm = this
      lv_Vm.fn_SelListMemrdCust()
    },
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    mngSearch () {
      if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
        return false
      } else {
        return true
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (chnlCustId, custNm) {
      let lv_Vm = this
      let nmUiObject = {}

      nmUiObject = {
        chnlCustId : chnlCustId, //고객채널 ID
        parentId   : 'MSPAP320M', //화면ID+
        cnsltNo : this.cnsltNo,
        custNm : custNm
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(lv_Vm, nmUiObject,(modalId)=>{
        return
      })
    },
    /******************************************************************************
    * Function명 : fn_SelListYmdInfo
    * 설명       : 법정공휴일 조회 I/F 호출
    ******************************************************************************/
    fn_SelListYmdInfo: function (date) {
      let lv_Vm = this

      if (lv_Vm.lv_NowLoadingHoliday) return
      this.lv_NowLoadingHoliday = true

      let pParams = {}
      let trnstId = 'txTSSAP03S2'
      lv_Vm.holidayList.splice(0, lv_Vm.holidayList.length)
      lv_Vm.inqrStrYmd = lv_Vm.$bizUtil.getDateToString(date.displayFirstDate, '')
      lv_Vm.inqrEndYmd = lv_Vm.$bizUtil.getDateToString(date.displayLastDate, '')
      pParams =
                { inqrStrYmd: lv_Vm.inqrStrYmd, // 시작일자
                  inqrEndYmd: lv_Vm.inqrEndYmd// 종료일자
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          console.log('법정공휴일 조회 >>>>>> ', response)
          if (response.body !== null && response.body.iCYmdMngVO !== null && response.body.iCYmdMngVO.length > 0) {
            lv_Vm.holidayList = response.body.iCYmdMngVO
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.lv_NowLoadingHoliday = false
          lv_Vm.fn_SelListMemrdCust()
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.lv_NowLoadingHoliday = false
        })
    },
    fn_PeriodChanged (date) {
      let lv_Vm = this
      // lv_Vm.radioNo = ''
      // lv_Vm.shrink = false
      lv_Vm.fn_SelListYmdInfo(date)// 휴일
    },
    fn_SelectDate (v) {
      let lv_Vm = this

      lv_Vm.inqrDtlStrYmd = moment(v.toString()).format('YYYYMMDD')
      lv_Vm.inqrDtlEndYmd = moment(v.toString()).format('YYYYMMDD')

      let hasList = []
      lv_Vm.filteredEvent.forEach((item) => {
        if(item.startDate == lv_Vm.$bizUtil.dateDashFormat(lv_Vm.inqrDtlStrYmd)&&  typeof(item.classes) == 'string'){
          hasList.push(item)
        }
      })

      if(hasList.length > 0){
        // 선택 날짜 props로 전달
        lv_Vm.titleDay = lv_Vm.convertDate(v.toString().substring(0, 3))
        lv_Vm.titleDate = (v.getMonth() + 1) + '월 ' + v.getDate() + '일'
        // lv_Vm.shrink = true

        if (lv_Vm.filter === '1') { // 생일:10
          lv_Vm.dtlTitle = '생일'
          lv_Vm.dtlClass = 'birthday'
        } else if (lv_Vm.filter === '2') { // 계약1주년:30
          lv_Vm.dtlTitle = '계약1주년'
          lv_Vm.dtlClass = 'year'
        } else if (lv_Vm.filter === '3') { // 상령일:20
          lv_Vm.dtlTitle = '상령일'
          lv_Vm.dtlClass = 'senior'
        } else if (lv_Vm.filter === '4') { // 만기일:50
          lv_Vm.dtlTitle = '만기일'
          lv_Vm.dtlClass = 'duedate'
        }

        lv_Vm.checkAll = false

        lv_Vm.fn_SelListMemrdCustDtl()
        lv_Vm.$refs.refDtailSheet.open()
      }

    },
    convertDate (v) { // 요일 타이틀 변경
      switch (v) {
        case 'Mon':
          return '월요일'
        case 'Tue':
          return '화요일'
        case 'Wed':
          return '수요일'
        case 'Thu':
          return '목요일'
        case 'Fri':
          return '금요일'
        case 'Sat':
          return '토요일'
        case 'Sun':
          return '일요일'
      }
    },
    /******************************************************************************
    * Function명 : fn_SelListMemrdCust
    * 설명       : 이벤트 고객 조회 I/F 호출
    ******************************************************************************/
    fn_SelListMemrdCust: function () {
      let lv_Vm = this
      let pParams = {}
      let trnstId = 'txTSSAP03S1'
      let evtTypCd = ''
      let classes = ''
      
      if (lv_Vm.filter === '1') { // 생일:10
        evtTypCd = '10'
        classes = 'birthday-event'
      } else if (lv_Vm.filter === '2') { // 계약:30
        evtTypCd = '30'
        classes = 'year-event'
      } else if (lv_Vm.filter === '3') { // 상령일:20
        evtTypCd = '20'
        classes = 'senior-event'
      } else if (lv_Vm.filter === '4') { // 만기도래:50
        evtTypCd = '50'
        classes = 'duedate-event'
      }
      lv_Vm.filteredEvent.splice(0, lv_Vm.filteredEvent.length)
      pParams =
                { inqrStrYmd: lv_Vm.inqrStrYmd, // 시작일자
                  inqrEndYmd: lv_Vm.inqrEndYmd, // 종료일자
                  evtTypCd: evtTypCd,
                  cnsltNo: this.cnsltNo
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          console.log('이벤트 고객 조회 >>>>>> ', response)
          lv_Vm.holidayList.forEach(
            (item) => {
              if(item.holyCd != '2'){
                let lv_Item = {}
                let lv_EvtYmd = lv_Vm.$commonUtil.dateDashFormat(item.slrcYmYmd)
                lv_Item.startDate = lv_EvtYmd
                lv_Item.endDate = lv_EvtYmd
                lv_Item.title = item.holyNm
                lv_Item.classes = ['holiday', 'holiday-event']
                lv_Vm.filteredEvent.push(lv_Item)
              }
            }
          )
          if (response.body !== null && response.body.iCMemrdCustVO !== null && response.body.iCMemrdCustVO.length > 0) {
            let lv_MemrdCustList = response.body.iCMemrdCustVO

            lv_MemrdCustList.forEach(
              (item) => {
                let lv_Item = {}
                let lv_EvtYmd = lv_Vm.$commonUtil.dateDashFormat(item.evtYmd)
                lv_Item.startDate = lv_EvtYmd
                lv_Item.endDate = lv_EvtYmd
                lv_Item.title = item.custNm
                lv_Item.classes = classes
                lv_Vm.filteredEvent.push(lv_Item)
              }
            )

            if(lv_Vm.$route.params.evtTypNm && lv_Vm.isFirst) {
              let date      = new Date()
              lv_Vm.isFirst = false
              if(lv_Vm.$route.params.evtTypNm === '생일') {
                lv_Vm.filter = '1'
                lv_Vm.fn_SelectDate(date)
              }
              if(lv_Vm.$route.params.evtTypNm === '계약1주년') {
                lv_Vm.filter = '2'
                lv_Vm.fn_SelectDate(date)
              }
              if(lv_Vm.$route.params.evtTypNm === '상령일') {
                lv_Vm.filter = '3'
                lv_Vm.fn_SelectDate(date)
              }
              if(lv_Vm.$route.params.evtTypNm === '만기일') {
                lv_Vm.filter = '4'
                lv_Vm.fn_SelectDate(date)
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SelListMemrdCustDtl
    * 설명       : 이벤트 고객상세리스트 조회 I/F 호출
    ******************************************************************************/
    fn_SelListMemrdCustDtl: function () {
      let lv_Vm = this

      let pParams = {}
      let trnstId = 'txTSSAP03S3'
      let evtTypCd = ''
      var evtCd = ''
      
      if (lv_Vm.filter === '1') { // 생일:10
        evtTypCd = '10'
      } else if (lv_Vm.filter === '2') { // 계약:30
        evtTypCd = '30'
      } else if (lv_Vm.filter === '3') { // 상령일:20
        evtTypCd = '20'
      } else if (lv_Vm.filter === '4') { // 만기도래:50
        evtTypCd = '50'
        // evtCd = lv_Vm.radioNo === '2' ? '52' : '51'
        evtCd = '51'
      }

      lv_Vm.dtlData.splice(0, lv_Vm.dtlData.length)
      pParams =
                { inqrStrYmd: lv_Vm.inqrDtlStrYmd, // 시작일자
                  inqrEndYmd: lv_Vm.inqrDtlEndYmd, // 종료일자
                  evtTypCd: evtTypCd, // lv_Vm.evtTypCd
                  evtCd: evtCd,
                  cnsltNo: this.cnsltNo
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null && response.body.iCMemrdCustVO !== null && response.body.iCMemrdCustVO.length > 0) {
            let lv_MemrdCustList = response.body.iCMemrdCustVO

            var jCompr = lv_MemrdCustList.length - 1
            var dataList = []

            lv_MemrdCustList.forEach(
              (item, index) => {
                let lv_Data = {}
                lv_Data.name = item.custNm
                lv_Data.relation = item.evtTypNm
                lv_Data.chnlCustId = item.chnlCustId
                lv_Data.birthday = lv_Vm.$commonUtil.dateDashFormat(item.basYmd)
                lv_Data.product = item.prdtMclsfNm

                if (item.evtYmd === lv_MemrdCustList[0].evtYmd && lv_Vm.inqrDtlStrYmd === item.evtYmd) {
                  dataList.push(lv_Data)
                }

                if (index === jCompr) {
                  dataList.sort((a, b) => {
                    if (a.name > b.name) {
                      return 1
                    } else if (a.name < b.name) {
                      return -1
                    }
                    return 0
                  })
                  lv_Vm.dtlData = dataList
                  dataList = []
                }
                
              }
            )

            console.log('이벤트 고객상세리스트 조회 >>>>>>>>>>>>>', lv_Vm.dtlData)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_close(){
      this.$refs.refDtailSheet.close()
    },
    fn_CheckAll() {
      if(this.dtlData.length > 0){
        if(this.checkAll){
          this.dtlData.forEach((item) => {this.$set(item, 'checked', false)})
        }else{
          this.dtlData.forEach((item) => {this.$set(item, 'checked', true)})
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_SmsClick
     * 설명       : 문자 수신고객목록 팝업 오픈(MSPCM128P)
     ******************************************************************************/
    fn_SmsClick () {
      let list = this.dtlData.filter((item) => {return item.checked})
      if(list.length == 0){
        this.$bizUtil.ctUtils.fn_alert(this, {content:'고객을 선택하세요.'})
        return
      }

      let param = {
        selectList: list,
        openPage: MSPCM128P
      }

      // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
      this.$cmUtil.fn_BtnS2OnClick(this, param, 's', 'MSPAP320M')
    },
    /******************************************************************************
     * Function명 : fn_EmailClick
     * 설명       : 이메일 연동
     ******************************************************************************/
    fn_EmailClick () {
      let list = this.dtlData.filter((item) => {return item.checked})
      if(list.length == 0){
        this.$bizUtil.ctUtils.fn_alert(this, {content:'고객을 선택하세요.'})
        return
      }

      let param = {
        selectList: list,
        openPage: MSPCM191P
      }
      
      // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
      this.$cmUtil.fn_BtnS2OnClick(this, param, 'e', 'MSPCM115M')
    },

    /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if(this.pScrId === 'MSPBC003M' && !this.mngSearch) {
          // 화면 이동 시, 검색사번 유지
          this.getStore('bcStore').dispatch('FC_DATA', this.fcData)
        }
        this.$router.go(-1)
      }
  }

 }
</script>
<style>
</style>