/*
 * 업무구분: 고객
 * 화 면 명: MSPCM217M
 * 화면설명: 삭제예정고객
 * 작 성 일: 2022.07.13
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="필수컨설팅 만료예정고객" :show-title="true" type="subpage" :action-type="isMngr ? 'search' : ''" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P()" @on-scroll-bottom="fn_LoadingData()"
   :topButton="true" :topButtonBottom="topButtonBottom">
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <!-- 퍼블수정 : tab추가 -->
      <mo-tab-box default-idx="1" class="ns-move-tab-box tween">
        <mo-tab-label idx="1" @click="fn_tabIdx('01')">필수컨설팅 동의 1개월전</mo-tab-label>
        <mo-tab-label idx="2" @click="fn_tabIdx('02')">관심고객 만료 3개월전</mo-tab-label>
        <mo-tab-label idx="3" @click="fn_tabIdx('03')">미니보험고객 만료 3개월전</mo-tab-label>
      </mo-tab-box>
      <!-- 퍼블수정 : tab추가 end-->      
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + totCnt + '명' }}</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area" >

        <!-- 조회결과 리스트 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list"> <!-- 퍼블수정 : 개발의 상태값 잠시 빼 놓음 v-if="!isEmptyView" -->
          <mo-list :list-data="cmDeltParngCustSVO">
            <template #list-item="{ index, item }">
              <mo-list-item expandable prevent-expand :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <span class="name txtSkip" @click.stop="fn_OpenCustInfoBs(item)">{{ item.custNm }}</span>
                    <!-- 비대면 동의 전송한경우 뱃지 노출 (※처리계에서 "진행중" 으로 보낼 경우) -->
                    <!-- (비대면동의 알림톡 발송 확인가능한 서비스. sxdsCd에 들어옴) -->
                    <mo-badge v-if="item.sxdsCd !== undefined && item.sxdsCd.trim() !== ''" class="badge-sample-badge" text="" shape="status">{{ item.sxdsCd.trim() ? '동의진행중' : '' }}</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <span>{{ $commonUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                    <!-- 성별 -->
                    <!-- <span>{{ item.sxdsCd === '01' ? '남' : '여' }}</span><em>|</em> -->
                    <span>{{ item.sxdsCdNm === '남성' ? '남' : '여' }}</span><em>|</em>
                    <!-- 고객구분 -->
                    <span>{{ item.chnlCustScNm }}</span>
                  </div>
                  <!-- 종료일 2주 이내인 경우 컬러 표시 - 황고은 프로(2022-08-02) -->
                  <!-- 필수컨설팅동의 종료예정일 -->
                  <div class="list-item__contents__info">
                    <span class="w147">필수컨설팅동의종료</span>
                    <span :class="(item.mndtCnstgCnsntEndYmd !== '00000000' && (Number(item.mndtCnstgCnsntEndYmd) - Number(nowDate)) <= 14) ? 'ns-ftcr-orange' : ''">
                      {{ item.mndtCnstgCnsntEndYmd === '00000000' ? '동의없음' : $commonUtil.dateDashFormat(item.mndtCnstgCnsntEndYmd) }}
                    </span>
                  </div>
                  <!-- 마케팅동의 종료예정일 --><!-- 퍼블수정 : 신규 내용에 없어서 주석처리 -->
                  <!--<div class="list-item__contents__info">
                    <span class="w147">마케팅동의종료</span>
                    <span :class="(item.mktCnsntEndYmd !== '00000000' && (Number(item.mktCnsntEndYmd) - Number(nowDate)) <= 14) ? 'ns-ftcr-orange' : ''">
                      {{ item.mktCnsntEndYmd === '00000000' ? '동의없음' : $commonUtil.dateDashFormat(item.mktCnsntEndYmd) }}
                    </span>
                  </div> -->
                </div>

                <!-- 퍼블수정 : 아코디언 컨텐츠 추가 -->
                <template v-slot:expand>
                  <div class="list-item__contents pl-10">
                    <div class="list-item__contents__info" v-if="$bizUtil.isEmpty(item.telno)">
                      <span class="info">연락처</span><span></span>
                    </div>
                    <div class="list-item__contents__info" v-else>
                      <span class="info">연락처</span><span>{{$bizUtil.telNoWithHyphen(item.telno, 1)}}</span>
                    </div>
                    <div class="list-item__contents__info" v-if="item.covrAnlyYmd === '00000000'">
                      <span class="info">3개월내 보장설계</span><span></span>
                    </div>
                    <div class="list-item__contents__info" v-else>
                      <span class="info">3개월내 보장설계</span><span>{{$commonUtil.dateDashFormat(item.covrAnlyYmd)}}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">3개월내 활동내용</span><span>{{item.custCntcNm}}</span>
                    </div>
                    <div class="list-item__contents__info" v-if="item.occuYmd === '00000000'" >
                      <span class="info">활동일</span><span></span>
                    </div>
                    <div class="list-item__contents__info" v-else>
                      <span class="info">활동일</span><span>{{$commonUtil.dateDashFormat(item.occuYmd)}}</span>
                    </div>
                    <div class="list-item__contents__info ns-type-area">
                      <span class="info">내  용</span><span>{{item.prdtNm}}</span>
                    </div>
                  </div>
                </template>
                <!-- 퍼블수정 : 아코디언 컨텐츠 end -->                
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역  --><!-- 퍼블수정 : 화면확인하기 위해 주석처리 -->
        <!--<ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>-->

        <!-- 고객등록동의/비대면등록동의 이동 -->
        <mo-bottom-sheet ref="bottomSheetAgree" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'">
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white"
                  @click="fn_moveMSPCM001M()">고객등록동의</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white"
                  @click="fn_MoveTSSCM100M(slctMktCnsntEndYmd)" :readonly="lv_cnsntInfo.mktPrcusCnsntYn !== 'Y'">비대면동의전송</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

        <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
        <div v-if="positionUp && cmDeltParngCustSVO.length > 0" class="ns-height120"></div>
        <div v-else-if="cmDeltParngCustSVO.length > 0" class="ns-height40"></div>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 삭제예정고객 목록 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM217M',
  // 현재 화면 ID
  screenId: 'MSPCM217M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM116P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      tab_index: '01',      // 퍼블수정 : tab contant 선택
      pCustPtclrMatrCntnt: String,
      pChnlCustId: String,
      pCnsltNo: String,

      isFirst: true,           // 초회 진입 여부
      isMngr: false,           // 지점장 여부
      toastMsg: '',            // 문자발송 toast 문구
      pOrgData217M: {},        // 최근 조직 조회 데이터

      // 체크 변수
      checkDisabled: false,    // 체크박스 비활성화 여부
      isOneOrMoreCheck: false, // 전체선택
      positionUp: false,       // 고객동의 바텀시트 강제 여부

      // 팝업 연동
      popup116: {}, // MSPCM116P 전체고객 목록 검색
      
      // 조회 변수
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      cmDeltParngCustSVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {},           // 사용자정보 obj
      isLoading: false,
      isEmptyView: false,     // 데이터 없음 화면
      lv_cnsltNo: '',         // 컨설턴트 번호
      lv_inputParam: {        // 서비스호출 Obj
        hofOrgNo: '',         // 사업부조직번호
        dofOrgNo: '',         // 지역단조직번호
        fofOrgNo: '',         // 지점조직번호
        cnsltNo: '',          // 컨설턴트번호
        inqrScCd: '',         // 조회구분코드
        custScCd: ''          // 고객구분코드
      },
      totCnt: 0,              // 누적 총 조회건수
      slctMktCnsntEndYmd: '', // 단건 마케팅동의
      vuchId: '',             // 영수증번호

      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20',   // 페이지 카운트

      isSearched: false,  // 조회이력 저장 변수
      lv_cnsntInfo: {
        mndtCnstgCnsntYn: String,
        mndtCnstgCnsntEndYmd: String,
        mndtCnstgCnsntRmdrDds: Number,
        mktPrcusCnsntYn: String,
        mktPrcusCnsntEndYmd: String,
        mktPrcusCnsntRmdrDds: Number,
        suitaCnsntYn: String,
        suitaCnsntEndYmd: String,
        suitaCnsntRmdrDds: Number
      },
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cmDeltParngCustSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cmDeltParngCustSVO.filter(item => item.checked).length
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    },
    mngSearch() {
      if(this.lv_inputParam.cnsltNo != this.getStore('userInfo').getters.getUserInfo.userId) {
        return false
      } else {
        return true
      }
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cmDeltParngCustSVO.length && this.cmDeltParngCustSVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }
    
    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    let tmp = this.$route.params
    // 컨설턴트 번호
    if(tmp.pData?.key && this.isMngr) {
      this.pOrgData217M.cnslt = tmp.pData
    } else {
      this.lv_cnsltNo = this.userInfo.userId
    }

    this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
    this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
    this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
    this.lv_inputParam.HofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

    // 삭제예정고객 조회
    this.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM217M')
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    fn_tabIdx (str) {
      /*
      if (str === '1') {
        this.tab_index = '1'
      } else if (str === '2') {
        this.tab_index = '2'
      } else if (str === '3') {
        this.tab_index = '3'
      }
      */
      this.tab_index = str
      // 바텀시트 초기화
      if (this.$refs !== undefined) {
        if (this.$refs.bottomSheetAgree !== undefined) {
          this.positionUp = false
          this.$refs.bottomSheetAgree.close()
        }
      }
      this.fn_Submit()
    },
   /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData217M = {} // 조직 조회 데이터
          this.isSearched = false // 검색이력 초기화
          this.isFirst = true // 초회 검색 여부 초기화
          // 바텀시트 초기화
          if (this.$refs !== undefined) {
            if (this.$refs.bottomSheetAgree !== undefined) {
              this.positionUp = false
              this.$refs.bottomSheetAgree.close()
            }
          }
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          if(this.$route.params?.pScrId === 'MSPBC003M') {
            // 화면 이동 시, 검색사번 유지
            this.getStore('bcStore').dispatch('FC_DATA', this.pOrgData217M.cnslt)
          }
          this.$router.go(-1)
        }
      }

    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData217M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData217M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.cmDeltParngCustSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      if (this.isFirst) {
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
        this.isFirst = false
      } else {
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
      }

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      // 지점장 조회시 구분
      if (this.pOrgData217M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData217M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
      // this.lv_inputParam.custScCd = '02' // 대상고객 구분 코드(01:필수컨설팅동의 1개월전, 02:동의종료예정, 03:마케팅동의 1개월전)
      this.lv_inputParam.custScCd = this.tab_index
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(삭제예정고객 목록 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM33S3'// 삭제예정인수고객 목록 조회
      // const trnstId = 'txTSSCM33S9'// 삭제예정인수고객 목록 조회 (비대면동의 알림톡 발송 확인가능한 서비스. sxdsCd에 들어옴)
      const trnstId = 'txTSSCM47S9'// 삭제예정인수고객 목록 조회 (비대면동의 알림톡 발송 확인가능한 서비스. sxdsCd에 들어옴)
      const auth = 'S'
      const lv_Vm = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCDeltParngCustVO

            if (responseList !== null) {
              // 리스트 체크변수 적용
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].checked = false
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmDeltParngCustSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmDeltParngCustSVO = responseList
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmDeltParngCustSVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')

        this.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cmDeltParngCustSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cmDeltParngCustSVO) {
            if (this.isOneOrMoreCheck) {
              this.cmDeltParngCustSVO[n].checked = true
            } else {
              this.cmDeltParngCustSVO[n].checked = false
            }
          }

          // 고객툴바 호출
          this.$cmUtil.setSelectedCustList(this, this.selectItems)
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      // 단건 선택시 고객등록동의/비대면동의전송 팝업 호출
      if (this.checkCount === 1) {
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')

        this.slctMktCnsntEndYmd = custInfo.mktCnsntEndYmd
        // 모달 호출
        // this.positionUp = true
        this.fn_SelInfoPrcusCnsnt(custInfo.chnlCustId)
        // this.$refs.bottomSheetAgree.open()
      } else {
        this.slctMktCnsntEndYmd = ''
        // 모달 닫기
        this.positionUp = false
        this.$refs.bottomSheetAgree.close()
      }

      // 고객툴바 호출
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM217M',
          pOrgData: this.pOrgData217M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.positionUp = false
            this.$refs.bottomSheetAgree.close()
            this.$bottomModal.close(this.popup116)

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData217M = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 삭제예정고객 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData217M.cnslt === undefined || this.pOrgData217M.cnslt === null || this.pOrgData217M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData217M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : lv_Vm.$options.screenId, //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * Function명 : fn_moveMSPCM001M
     * 설명       : (대면고객동의)선택된 고객정보를 가지고 고객등록동의 화면으로 이동
     ******************************************************************************/
    fn_moveMSPCM001M () {
      // 바텀시트 비활성화
      this.positionUp = false
      this.$refs.bottomSheetAgree.close()

      // 대면고객동의 이동
      this.$router.push({
        name: 'MSPCM001M',
        params: {
          chnlCustId: this.selectItems[0].chnlCustId // 채널고객ID
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_MoveTSSCM100M
     * 설명       : (비대면고객동의)선택된 고객정보를 가지고 비대면 문자 발송
     ******************************************************************************/
    fn_MoveTSSCM100M (data) {
      // 마케팅동의 체크
      if (this.lv_cnsntInfo.mktPrcusCnsntYn !== 'Y') {
        this.toastMsg = '마케팅 동의가 없는 고객은 비대면 동의 전송 불가합니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
        return
      }

      // 바텀시트 비활성화
      this.positionUp = false
      this.$refs.bottomSheetAgree.close()

      let pCelno = ''
      if (this.selectItems[0].telno.trim() !== '') {
        pCelno = this.selectItems[0].telno
        if (pCelno.indexOf('[') > -1) {
          pCelno = pCelno.substring(3) // [핸], [자], [직] 제거한 telno
        }
      }

      // 비대면고객동의 이동
      this.$router.push({
        name: 'MSPCM133M',
        params: {
          custNm: this.selectItems[0].custNm, // 이름
          celno: pCelno, // 핸드폰번호
          chnlCustId: this.selectItems[0].chnlCustId // 채널고객ID
        }
      })

    },

    /******************************************************************************
     * Function명 : fn_SelInfoPrcusCnsnt
     * 설명       : 동의정보 조회('txTSSCM31SD' 조회)
     ******************************************************************************/
    fn_SelInfoPrcusCnsnt (chnlCustId) {
      const lv_Vm = this
      const trnstId = 'txTSSCM31SD' // 동의정보 조회
      const auth = 'S'

      // 파라미터 세팅
      let lv_cnslt = {} // Input 객체 초기화
      lv_cnslt.chnlCustId = chnlCustId // 채널고객아이디
      lv_cnslt.cnsltNo = this.lv_cnsltNo // 컨설턴트번호

      let pParams = {}
      pParams = lv_cnslt

      // 동의정보조회
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            // 동의정보 데이터 바인딩
            lv_Vm.lv_cnsntInfo = response.body
            // lv_Vm.lv_knbScCd = lv_Vm.lv_cnsntInfo.knbScCd
          }
          lv_Vm.$forceUpdate()
          lv_Vm.positionUp = true
          lv_Vm.$refs.bottomSheetAgree.open()          
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData217M = {} // 조직 조회 데이터
                this.isSearched = false // 검색이력 초기화
                this.isFirst = true // 초회 검색 여부 초기화
                // 바텀시트 초기화
                if (this.$refs !== undefined) {
                  if (this.$refs.bottomSheetAgree !== undefined) {
                    this.positionUp = false
                    this.$refs.bottomSheetAgree.close()
                  }
                }

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
