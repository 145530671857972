/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS220M
 * 화면 설명: 설계청약 > 청약서발행현황
 */
<template> 
  <!-- AppBar BackArrow 버튼/ 타이틀 / 검색버튼 컨테이너 -->
  <!-- root ur-page-container -->
  <ur-page-container class="msp" title="청약서발행현황" :show-title="true" type="subpage" ref="page" :action-type="actionType" :topButton="true"
    @on-scroll-bottom="fn_ScrollBottomHandler"
    @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- MSPBC411D 컨설턴트영역 -->
    <MSPBC411D ref="page411D" v-if="vGssAuthYN === 'Y'" @searchCallback="fn_SrchCallback" />
    <!-- 조회건수 출력 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="count">{{rsltCnt}}</strong> 건</span>
              <!-- 리스트 정렬 컴포넌트 -->
              <PSListSortOpt ref='sortFieldComp'
                            defaultLabel='정렬선택'
                            popupTitle="정렬선택"
                            itemValue="name" 
                            itemText="label"
                            :sortFieldList='sortFieldList'
                            @sortChange='fn_SortChangeHandler'/>
              <!--/ 리스트 정렬 컴포넌트 -->
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- / 조회건수 출력 -->
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column" class="">      
            <mo-list :list-data="rsltItemList">
              <template #list-item="{item, index}">      
                <mo-list-item class=""> 
                  <msp-expand titleFirst btnAreaFirst  :expanded="true" expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" class="mo-list-expand w100" btn-area-class="fexTy3 firstdiv-full mb0"  > 
                    <template #title>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title pt6">
                          <span class="name txtSkip fs19rem fwb">{{item.planNm}}</span>
                        </div>
                        <div class="mt6 mb10">
                          <span class="fs14rem mr6 crTy-bk7">피보험자</span> <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 ls--1 lnkTy1" @click.stop="fn_GrdCustNmClick(item.chnlCustId)">{{item.insrdNm}}</span>
                          <em class="em_normal">|</em>
                          <span class="fs14rem mr8 crTy-bk7">발행일</span><span class="crTy-bk1 fs16rem ls--1">{{item.ofrfmPblYmdLabel}}</span>
                        </div>
                        <div class="mt6 mb10">
                          <span class="fs14rem mr8 crTy-bk7">보험료</span>
                          <span class="crTy-bk1 fs16rem crTy-blue3 fwb700">{{$bizUtil.numberWithCommasCurr(item.smtotPrm.amount, item.zaCrncyCd, 'Y', 'Y', 3)}}</span>
                        </div>
                      </div>
                    </template>
                    <template #btn>
                      <mo-button class="link-arrow down mb34"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                        <ur-box-container class="con--padding ">
                          <div class="contents-row">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span><span class="fs16rem">{{item.planId}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min60">전산심사</span><span class="fs16rem">{{item.cundwRsltCdNm}}</span>
                          </div>
                          <!-- <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min60">선심사</span><span class="fs16rem">{{item.zaDsclsId}}</span>
                              <div class="contents-row">
                                <span class="dsInline crTy-bk7 fs14rem mr10 min80"></span><span class="ml10 crTy-blue3 crTy-bk1 lnkTy1" @click="fn_ZaNundwPrgStatNm(item)">{{ item.zaNundwPrgStatNm }}</span>
                                <span class="ml10" v-if="item.zaNundwYmdLabel!==null"> (심사일 : {{ item.zaNundwYmdLabel }})</span>
                              </div>
                          </div> -->
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt0">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div mb5">
                        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_MoveEntPlan(item.entplId)">가입설계</mo-button>
                        <template v-if="item.eusPrgStatCd === '0'">
                          <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AEUSButtonClick(item)">AEUS입력</mo-button> -->
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AEUSButtonClick(item)">New AEUS</mo-button>
                        </template>
                        <template v-else>
                          <mo-button :disabled="true" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white">{{item.eusPrgStatNm}}</mo-button>
                        </template>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- NoData 영역 ur-box-containe  -->        
      <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>        
      <!-- / NoData 영역 ur-box-containe -->
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSAeusUtil from '@/ui/ps/comm/PSAeusUtil' // AEUS 화면 이동
import PSConstants from '@/config/constants/psConstants'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import Screen from '~systems/mixin/screen'
import MSPBC411D from '@/ui/bc/MSPBC411D'
import MSPPS201P from '@/ui/ps/MSPPS201P'
import MSPPI800P from '@/ui/pi/MSPPI800P' // [선심사 및 영업시스템 고도화 PJT] 선심사
import MSPPI820P from '@/ui/pi/MSPPI820P' // [선심사 및 영업시스템 고도화 PJT] 선심사 완료
import MSPPI840P from '@/ui/pi/MSPPI840P' // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력
import piCommonUtil from '@/ui/pi/common/piCommonUtil'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS220M', 
  screenId: 'MSPPS220M',
  mixins: [Screen],

  components: {    
    'PSNodataMesgBoxForList'  : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
    'PSListSortOpt'           : PSListSortOpt,
    'PSHeaderSubMenuPopup'    : PSHeaderSubMenuPopup, // 서브메뉴 팝업
    'MSPBC411D'               : MSPBC411D,
    'MSPPS201P'               : MSPPS201P, // 검색 키워드 입력 팝업
    'MSPPI800P'               : MSPPI800P, // [선심사 및 영업시스템 고도화 PJT] 선심사
    'MSPPI820P'               : MSPPI820P, // [선심사 및 영업시스템 고도화 PJT] 선심사 완료
    'MSPPI840P'               : MSPPI840P, // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력
 
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      vGssAuthYN: '', //ga매니저 권한
      vEplyNo: '', // ga컨설턴트ID
      vGssEplyNm: '', // ga컨설턴트명
      actionType : 'none',
      selectBoxValue: '',
      PSConstants : PSConstants,  //PS상수
      rtnSearchPopupData: {searchScVal : '', searchKeyword : ''}, // 검색팝업 리턴값
      eusObjYn: '',
      initSearchFlag: false,
      stndKey: '', // 페이징 Next Key
      isNodata: false, // 데이터 없음 Flag            
      slctItamData : null, // 선택된 item Row Object Data  
      rsltCnt : 0 ,  // 조회결과 누적 데이터 길이
      rsltItemList : [], // 조회결과 리스트 데이터
      sortFieldList : [],
      searchPopup : null, //검색팝업
      orgItems: [], // 전체 리스트 목록 복사본
      pChnlCustId: '', // 고객검색 팝업에서 받아온 채널고객아이디
      searchFlag: false, // 데이터 조회 시 true, 조회 완료 시 false
      lv_CustInfoBottomSheet: null, // 고객카드바텀시트
      // [선심사 및 영업시스템 고도화 PJT] 추가 ==============
      lv_NundwInfo: {}, // 선심사정보
      popupMSPPI800P: null, // 선심사 팝업
      popupMSPPI820P: null, // 선심사 결과 팝업
      popupMSPPI840P: null, // New AEUS
      lv_NewAeusVisible: true, // New AEUS 버튼 처리
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    this.actionType ='none' // 리셋
    this.actionType = PSCommUtil.fn_SetAppBarSearchMoreMenu(this.fn_HeaderSerchHander,this.fn_MoreMenuHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    
    //ga매니저 권한
    this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN
    
    this.fn_Reset()
    
    //초기 고객명의 키워드 공백으로 설정 하여 조회
    this.rtnSearchPopupData.searchScVal = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key
    this.rtnSearchPopupData.searchKeyword = ''
    
    this.fn_ServiceData('S') // 초기 조회
    
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnHandler)

  },//destroyed
  activated() {
    //keepAlive : true 일때 GA매니저 가설 대행이면 조회 초기화
    let gssAuthYN = window.vue.getStore('cmStore').getters.getState.gssAuthYN
    if(gssAuthYN === 'Y'){
      this.$refs.page411D.fn_resetCnsltInfo()
      this.rsltCnt = 0
      this.rsltItemList = []
    }
  },


  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
      //this.$router.go(-1)
    }, //fn_DevicBackBtnHandler


    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      // this.$router.go(-1)
      this.$router.push({ name: 'MSPBC002M' })
    },// fn_HeaderBackBtnHandler


    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset: function () {
      this.stndKey = ''        
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
      this.eusObjYn = ''
    },

  /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
      let lv_Vm = this
     
      this.searchPopup = this.$bottomModal.open(MSPPS201P, {
        properties: {
          pParntSrnId: lv_Vm.$options.screenId, // 부모 화면 ID
          pSearchKeyword: lv_Vm.rtnSearchPopupData.searchKeyword, //Default set Keyword Value
          pSearchScVal: lv_Vm.rtnSearchPopupData.searchScVal
        },
        
        listeners: {
          //대상팝업 onPopupReset $emit 이벤트명 backkey reset or reset
          onPopupReset : () => {
            lv_Vm.rtnSearchPopupData.searchScVal = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key
            lv_Vm.rtnSearchPopupData.searchKeyword = ''
            lv_Vm.fn_Reset()            
            lv_Vm.fn_ServiceData('S')
            lv_Vm.$bottomModal.close(this.searchPopup) // 모달 닫기    
          },
          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            console.log(rtnData)

            lv_Vm.rtnSearchPopupData.searchScVal = rtnData.searchScVal // 검색구분값
            lv_Vm.rtnSearchPopupData.searchKeyword = rtnData.searchKeyword // 검색키워드

            if (rtnData.searchKeyword !== '') {   //필터검색
              lv_Vm.fn_FltSearch(rtnData)
            }
            else {
              lv_Vm.fn_Reset()            
              lv_Vm.fn_ServiceData('S')
            }
            
            lv_Vm.$bottomModal.close(this.searchPopup) // 모달 닫기                        
          }          
        }
      })
    },// fn_HeaderSerchHander

    fn_MoreMenuHandler(){
      console.log('fn_MoreMenuHandler!!!')
      //팝업 열기 
      let subMenuModalId = this.$bottomModal.open(PSHeaderSubMenuPopup, {
        properties: {          
          parentSrnId  : this.$options.screenId, // 부모 화면 ID
          popupTitle   : '청약설계 업무 더보기',                    
          subMenuList  : PSCommUtil.fn_GetOfrPlanMoreMenuList() //청약설계 보조메뉴 리스트          
        },
        listeners: {
          popupEventClose : () => {
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
          },
          popupEventRouterMove: (rtnData) => {//subMenuList에서 선택함 Item 리턴
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
            this.isActivePopup = false
          },      
        }
      },
      {properties: {closeBtn: true}})
    },

    /******************************************************************************
     * Function명 : fn_FltSearch
     * 설명 : 필터검색 - 팝업에서 넘어오는 함수
     ******************************************************************************/
    fn_FltSearch (pdata) {
      let tempArray = []
      this.selectBoxValue = pdata.searchScVal //검색팝업 종류 1,2,3
      this.orgItems.forEach(item => {
        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key) { // 고객명          
          if ( pdata.searchKeyword === item.insrdNm) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key) { // 상품명
          if (item.planNm.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key) { // 영수증번호
          if (item.planId.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }
      })
      this.rsltItemList = []
      this.rsltItemList = tempArray

      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
      this.isNodata = (this.rsltCnt <= 0)? true : false
    },

    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_SearchEvent('N', false)
    },// fn_ScrollBottomHandler


    /******************************************************************************
    * Function명  : fn_AEUSButtonClick
    * 설명        : AEUS 화면 링크
    ******************************************************************************/
    fn_AEUSButtonClick(pData) {
      // event.stopPropagation()
      this.slctItamData = pData
      this.fn_ServiceData('S1')
    },//fn_AEUSButtonClick

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(serviceName) {
      if (this.searchFlag) return
      this.searchFlag = true

      let pParams = {}
     
      switch (serviceName) {
        case 'S': // 검색
          window.vue.getStore('progress').dispatch('SKEL_LIST')
        case 'N': // 더보기
          pParams.appId = 'sli.ipi'
          pParams.trnstId = 'txTSSPS21S2'
          pParams.fnctScCd = 'S'
          // [선심사 및 영업시스템 고도화PJT] I/F 변경으로 인해 VO명 변경
          pParams.serviceId = 'C339_F1PQL0492_S' // C339_F1PQL0022_S  ->  C339_F1PQL0492_S
          pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0492ReqVO'
          pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0492ResVO'
          // pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0022ReqVO'
          // pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0022ResVO'
          // pParams.reqVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0022VO'
          // pParams.resVONm = 'sli.in.ea2.vo.a0025pq.EAF1PQL0022VO'
          pParams.data = {}
          pParams.data.clctCnsltId = this.$MenuManager.fn_GetUserInfoProp('userId') // FC 사번
          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.data.clctCnsltId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
         
          pParams.data.planScCd = '02' // 설계구분코드 가입설계 00, 청약서발행 02
          pParams.data.eusObjYn = '' // AEUS대상구분 전체 "", 대상 0, 비대상 1
          pParams.data.eusPrgStatCd = '3' // AEUS입력여부 전체 "3", 입력 1, 미입력 0

          // Legacy PJT 변경. 설계진행현황조회 VO에 페이징 VO 추가. ERP To-Be 수정사항 따라감.
          pParams.data.zaNundwPrgStatCd = '' // [선심사 및 영업시스템 고도화PJT] I/F 변경으로 인해 VO명 변경
          pParams.data.zaNundwPrgYn = '' // [선심사 및 영업시스템 고도화PJT] I/F 변경으로 인해 VO명 변경
          pParams.data.pIPagingVO = {}
          pParams.data.pIPagingVO.fnctScCd = serviceName
          pParams.data.pIPagingVO.pageRowCnt = 30
          pParams.data.pIPagingVO.stndKey = (serviceName === 'S') ? '' : this.stndKey
          break
        case 'S0': // AEUS조직여부 조회
          window.vue.getStore('progress').dispatch('SKEL_LIST')
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S1'))
          pParams.data = {}
          /* FC 사번 */
          pParams.data.clctCnsltNo = PSServiceManager.getUserInfo('userEno')
          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.data.clctCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
          //ody 펼침 추가          
          this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
            for(let i = 0 ; i < this.orgItems.length; i++){
              this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
            }
          });
          //ody 펼침 추가
          break
        case 'S1': // AEUS시스템장애 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S2'))
          pParams.data = {}
          // 접속시스템구분코드
          pParams.data.conctSystmScCd = '10'
          break
        default:
          break
      } // end switch
      
      // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
      if (serviceName === 'N' && this.stndKey === '') {
        this.searchFlag = false
      } else {
        pParams.srnId = this.$options.screenId // 호출 화면 명
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      }
    },

   /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data
      let stndKeyList = event.trnstComm.stndKeyList
      switch (serviceName) {
        case 'S':
          window.vue.getStore('progress').dispatch('PART')
        case 'N':
          this.stndKey = lv_data.pipagingVO.stndKey
          let t_rslt = lv_data.piplanProcessStatusViewListVO
          let t_rowIndex = this.rsltItemList.length
          if (t_rslt && t_rslt.length > 0) {
            
            t_rslt.forEach(pIteam => {
              pIteam.id = t_rowIndex++
              pIteam.value = false
              // 정렬 하기위해 코드값 입력
              if (pIteam.eusPrgStatNm === '미입력') {
                pIteam.eusPrgStatCd = '0'
              } else if (pIteam.eusPrgStatNm === '입력') {
                pIteam.eusPrgStatCd = '1'
              } else if (pIteam.eusPrgStatNm === '비대상') {
                pIteam.eusPrgStatCd = '2'
              } else { // 빈칸 일때
                pIteam.eusPrgStatCd = '3'
              } // end else if
              pIteam.planNm = pIteam.planNm.replace('삼성생명', '').replace('삼성', '').trim() // 설계명에 삼성생명, 삼성 제거 처리
              pIteam.ofrfmPblYmd = PSDateUtil.fn_DateFormat(pIteam.ofrfmPblYmd, 'yyyyMMddHHmmss', 'yyyy-MM-dd HH:mm:ss')
              pIteam.ofrfmPblYmdLabel = PSDateUtil.fn_DateFormat(pIteam.ofrfmPblYmd, 'MM-dd', 'yyyyMMddHHmmss')
              pIteam.zaNundwYmdLabel = PSDateUtil.fn_DateFormat(pIteam.zaNundwYmd, 'MM-dd', 'yyyyMMddHHmmss')
              this.rsltItemList.push(pIteam)
            })
            this.orgItems = this.rsltItemList
            //ody 펼침추가
            // this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
            //   for(let i = 0 ; i < this.orgItems.length; i++){
            //     this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
            //   }
            // });
            //ody 펼침추가
          } else {
            if (serviceName === 'S') this.fn_TableStat() // 조회결과 없음 처리
          } // end if
          
          this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
          this.isNodata = (this.rsltCnt <= 0)? true : false
          this.searchFlag = false
          this.fn_SetOrderByFields() // 정렬 컴포넌트 필드정의 셋팅

          if (serviceName === 'S') this.fn_ServiceData('S0') // FC 정보 조회

          // if(this.rsltCnt > 0 && serviceName === 'S'){
          //   this.$nextTick(()=>{
          //     PSCommUtil.fn_MoListItemExpandIdx(this.$refs.moList,0,true)
          //   })
          // }
          
          break
        case 'S0': // AEUS조직여부 조회
          window.vue.getStore('progress').dispatch('PART')
          this.searchFlag = false
          this.eusObjYn = lv_data.eusObjYn
          break
        case 'S1': // AEUS시스템장애 조회
          window.vue.getStore('progress').dispatch('HIDE')
          this.searchFlag = false
          if (lv_data.systmTrblYn === 'N') {
            if (!this.slctItamData) {
              this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC024']) // '선택된 설계정보가 없습니다.'
              return false
            } else {
              // EUS조직체크
              if (this.eusObjYn.trim() === '' || this.eusObjYn === 'N') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC025']) // 'AEUS 대상조직이 아닙니다.'
                return false
              } // end if
              // 전산심사결과 체크
              if (this.slctItamData.cundwRsltCdNm.trim() === '') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC026']) // '전산심사 미실행-AEUS입력불가'
                return false
              } else if (this.slctItamData.cundwRsltCdNm === '발행불가') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC027']) // '심사결과 발행불가-AEUS입력불가'
                return false
              } // end else if
              // AEUS상품 체크
              if (!this.slctItamData.eusObjYn === 'N') {
                this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC028']) // 'AEUS 비대상 상품-AEUS입력불가'
              } 
              // else if (this.slctItamData.zaSelPrdtYn !== 'Y') {
              //   this.getStore('confirm').dispatch('ADD', '판매종료된 상품이거나 해당조건에 맞는 상품이 없습니다.')
              //   return false
              // }
              // TSSAU001P.setObjMsg(this.$options.screenId, this.slctItamData.entplId)
              // PSAeusUtil.AEUSOpenService(this.$options.screenId, this.slctItamData.entplId, this.fn_AEUSServiceResultHandler)

              window.vue.getStore('progress').dispatch('SHOW')
              this.fn_SeachState(this.slctItamData) // 선심사 관련 New-AEUS 버튼 체크 로직
              return
              // this.fn_NewAEUSClick(this.slctItamData)
            } // end else if
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC029']) // '현재 AEUS서비스가 원활하지 않습니다.'
          } // end if
          break
        default:
          break
      } // end switch
      
    },

    /************************************************************************************************
     * Function명  : fn_AEUSServiceResultHandler
     * 설명        : AEUS 팝업 닫기 이벤트 일때 리스트 목록 재조회
     *               단 검색중일때는 재조회 안함
     ************************************************************************************************/
    fn_AEUSServiceResultHandler: function (event) {
      //PS AEUSUtil에서 처리
      //PSCommUtil.fn_AppPortrait(null,false) //App강제 세로방향

      if (event === '1') { // 입력 완료
        if (this.rtnSearchPopupData.searchKeyword.trim() === '') {
          this.fn_SearchEvent('S', true)
        } // end else if
      } else if (event === '0') { // 미입력
      } else if (event === 'S') { // 새로고침
        if (this.rtnSearchPopupData.searchKeyword.trim() === '') {
          this.fn_SearchEvent('S', true)
        } // end else if
      } else if (event === 'E') { // 에러
      } // end else if


    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      switch (serviceName) {
        case 'S':
          this.fn_TableStat()
          this.searchFlag = false
          if (event && event.msgCd !== 'EFWS034') {
            this.getStore('confirm').dispatch('ADD', event.msgDesc)
          } else {
            // 다른 시스템 호출 간 오류가 발생하였습니다.
            this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니\n확인후 다시 시도하세요')
          } // end if          
          break
        case 'S1':
          this.getStore('toast').dispatch('ADD', event.msgDesc)  
          break
        default:
          break
      } // end switch
      
      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0 
    },

  /******************************************************************************
    * Function명 : fn_SearchEvent
    * 설명       : 서비스 검색
    ******************************************************************************/
    fn_SearchEvent (key, initFlag) {

      this.initSearchFlag = initFlag // true: 최초검색, flase: 버튼검색
      
      if (key === 'S') { // 리스트에 첫페이지 검색
        if (this.initSearchFlag) { // 최초검색이면
          //정렬조건 초기화
          this.fn_SortReset()

          this.orgItems = []
          this.fn_Reset()
          this.fn_ServiceData(key) // 그냥검색
        } else { // 버튼검색
          let tmpArr = []
          if (this.selectBoxValue === '1') { // 고객명 검색
            if (this.rtnSearchPopupData.searchKeyword.trim() === '') {
              this.orgItems = this.rsltItemList = []
              this.fn_ServiceData(key)
            } else {
              // 고객명 검색 팝업 호출
              this.fn_HeaderSerchHander()
            }
          } else if (this.selectBoxValue === '2') { // 상품명 검색
            if (this.rtnSearchPopupData.searchKeyword.trim() === '') {
              this.orgItems = this.rsltItemList = []
              this.fn_ServiceData(key)
            } else {
              this.orgItems.forEach(item => {
                if (item.planNm.indexOf(this.rtnSearchPopupData.searchKeyword.trim()) > -1) {
                  tmpArr.push(item)
                }
              })
              this.rsltItemList = []
              this.rsltItemList = tmpArr
              
            }
          } else if (this.selectBoxValue.key === '3') { // 영수증번호 검색
            if (this.rtnSearchPopupData.searchKeyword.trim() === '') {
              this.orgItems = this.rsltItemList = []
              this.fn_ServiceData(key)
            } else {
              this.orgItems.forEach(item => {
                if (item.planId.indexOf(this.rtnSearchPopupData.searchKeyword.trim()) > -1) {
                  tmpArr.push(item)
                }
              })
              this.rsltItemList = []
              this.rsltItemList = tmpArr
              
            }
          } else {}
        }
      } else { // 리스트에 다음페이지 검색
        this.fn_ServiceData(key) // 서비스 호출
      }
    },

    /************************************************************************************************
     * Function명  : fn_TableStat
     * 설명        : 데이터 없는 페이지 설정
     * 
     ************************************************************************************************/
    fn_TableStat() {
      this.isNodata = true      
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
      this.eusObjYn = ''
    },

    /******************************************************************************
     * Function명 : fn_GrdCustNmClick
     * 설명 : 테이블에 피보험자 컬럼을 선택했을때 nameUI 실행하는 함수
     ******************************************************************************/
    fn_GrdCustNmClick (itemData) {
      if(this.vGssAuthYN === 'Y') return
      
      this.lv_CustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
          properties: {
            chnlCustId: itemData,
            cnstlNo: PSServiceManager.getUserInfo('userId'),
            //contNo : contNo
            parentId: 'MSPPS220M'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            }
          }
        })
    },

    /************************************************************************************************
     * Function명  : fn_MoveEntPlan
     * 설명        : 설계명 클릭 시 처리 함수( 1차 사랑온 상품설계 연동 이벤트 , 2차 상품설계 연동 이벤트 )
     ************************************************************************************************/
    fn_MoveEntPlan (planId = null) {
      let pData = {}
      if (planId === null) {
        pData = {init: true}
      }else{
        pData.zaPlanId = planId
      }
      
      this.$MenuManager.fn_RouterPushSrnId('MSPPI001M', pData)
    },

    /******************************************************************************
  * Function명  : fn_SetOrderByFields
  * 설명        : 정렬 컴포넌트 필드정의 셋팅
  *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
  * 
  ******************************************************************************/
  fn_SetOrderByFields () {
      this.sortFieldList = [
         {field : 'ofrfmPblYmd'     , label : '발행일'  , sort: 'DESC'}
        ,{field : 'insrdNm'         , label : '피보험자',  sort: 'ASC'}
        ,{field : 'eusPrgStatNm'    , label : 'AEUS'    , sort: 'ASC'}
        ,{field : 'zaDsclsId'        , label: '선심사'  , sort: 'DESC'}
      ]
  },
  /******************************************************************************
  * Function명  : fn_SortChangeHandler
  * 설명        : 정렬 이벤트 핸들러
  *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
  ******************************************************************************/
  fn_SortChangeHandler (pSortOrder){
    console.log('fn_SortChangeHandler !!!')

    let sortField = pSortOrder.field
    let sortOrderby = pSortOrder.sort //ASC or DESC

    console.log(pSortOrder)
    console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)
    
    switch(sortField){
      case 'insrdNm':  //String 형 sort
      case 'eusPrgStatNm':  //String 형 sort
        PSCommUtil.fn_SortListValueString(sortOrderby, this.rsltItemList, sortField)
        break
      case 'ofrfmPblYmd': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
        PSCommUtil.fn_SortListValueDateString(sortOrderby, this.rsltItemList, sortField, 'yyyyMMddHHmmss') //yyyy-MM-dd
        break
      case 'zaDsclsId':
        // PSCommUtil.fn_SortListValueString(sortOrderby, this.items, sortField)
        this.rsltItemList.sort((a, b) => {
          if(a[sortField] !== ' ' && b[sortField] !== ' ') {  // 선심사번호가 있는 건 기준 최근 설계일자 순으로 정렬
            return String(b.entplYmd).localeCompare(String(a.entplYmd))
          } else if (a[sortField] !== ' ') {
            return -1
          } else if (b[sortField] !== ' ') {
            return 1
          } else {
            return 0
          }
        })
        break  
      
    }//switch

  },

  /******************************************************************************
  * Function명  : fn_SortReset
  * 설명        : 정렬 리셋 이벤트 핸들러
  ******************************************************************************/
  fn_SortReset(){
    console.log('fn_SortReset !!!')
    /*
    원본 되돌리기
    this.rsltItemList = []      
    this.orgItems.forEach(item => {
      this.rsltItemList.push(Object.assign({},item))
    });
    */
    //정렬 선택 라벨만 초기화
    this.$refs.sortFieldComp.fn_Reset()
  },
  /*********************************************************
  * @function fn_SrchCallback
  * @name     콜백함수
  * @notice   컨설턴트 콜백함수
  *********************************************************/
  fn_SrchCallback(pData) {
    console.log('콜백 파라미터>>>>', pData)
    this.vEplyNo = pData.eplyNo // 컨설턴트ID
    this.vGssEplyNm = pData.gssEplyNm // 컨설턴트명

    console.log("변경한 컨설턴트 gssEplyInfo :::::::::::" + JSON.stringify(this.getStore('cmStore').getters.getState.gssEplyInfo))

    this.searchFlag = false
    this.fn_Reset()            
    this.fn_ServiceData('S')
  },

  // ============================================================================
  // [선심사 및 영업시스템 고도화 PJT] 추가 함수
  // ============================================================================
  /******************************************************************************
   * Function명 : fn_SeachState
   * 설명 : 선심사 관련 New-AEUS 버튼 체크 로직
   ******************************************************************************/
  fn_SeachState (pData) {
    window.vue.getStore('progress').dispatch('FULL')
    let inputData = {
      entplId: pData.entplId
    }
    piCommonUtil.addPropertyToObject(inputData, 'searchCriteria', 'reqSystm', 'NEXT_LOVE_ON')
    // 역슬래시 제거
    let reqVal = JSON.stringify(inputData)
    let pParams = {
      cmndSrvcNm: 'selButtonVisable',
      sdrvDtVal: reqVal
    }
    piCommonUtil.invoke2('URLPQL00154', pParams, this.fn_ResSeachState, null, null, this, this)
  },
  fn_ResSeachState (resultData) {
    window.vue.getStore('progress').dispatch('HIDE')
    if (resultData !== null && !_.isEmpty(resultData)) {
      let outData = JSON.parse(resultData.sdrvDtVal)
      console.log('URLPQL00154 resultData ===> ', outData)
      // 선심사 필수건
      if (outData.nundwVisibleYn === 'F') {
        this.getStore('confirm').dispatch('ADD', '선심사필수 건은 입력할 수 없습니다.')
        return false
      } else if (outData.nundwPrgStatCd !== '70' && outData.nundwYn === 'Y') { // 선심사 입력건
        this.getStore('confirm').dispatch('ADD', '선심사 대상 건은 입력할 수 없습니다.')
        return false
      } else if (outData.aeusVisibleYn !== 'Y') {
        this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC028']) // 'AEUS 비대상 상품-AEUS입력불가'
        return false
      }
      this.fn_NewAEUSClick(this.slctItamData)
      // 선심사 관련 New-AEUS 버튼 체크 로직 수행 필요
    }
  },
  /******************************************************************************
   * Function명 : fn_ZaNundwPrgStatNm
   * 설명 : 선심사진행상태명 선택 시 팝업 노출 실행하는 함수
   ******************************************************************************/
  fn_ZaNundwPrgStatNm(item) {
    // [TO-DO] 선심사 진행상태에 따라 신청화면 or 결과화면으로 분기
    // 작성중(10):신청화면, 중지(70):리턴, 그 외:결과화면
    let zaNundwPrgStatCd = item.zaNundwPrgStatCd
    if (zaNundwPrgStatCd === '10') {
      // 신청화면 호출
      this.fn_OpenMSPPI800P(item)
    } else if (zaNundwPrgStatCd === '70'){
      return
    } else {
      // 결과화면 호출
      this.fn_OpenMSPPI820P(item)
    }
  },
  /******************************************************************************
  * Function명 : fn_OpenMSPPI800P
  * 설명       : 선심사 신청 팝업 오픈 함수
  ******************************************************************************/
  fn_OpenMSPPI800P (item) {
    // let tmodelObj = Gaf.getObj('tmodel')
    // let rltnrInfo_21 = tmodelObj.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
    // console.log('rltnrInfo_21 ====> ', rltnrInfo_21)
    this.popupMSPPI800P = this.$bottomModal.open(MSPPI800P, {
      properties: {
        pPage: this.name,
        pParams: {planId: item.entplId,
                  custId: '',
                  chnlCustId: item.chnlCustId,
                  dsclsId: '',
                  nundwJdgPrgStatCd: item.zaNundwPrgStatCd,
                  // pmodel: Gaf.getObj('pmodel'),
                  // tmodel: Gaf.getObj('tmodel'),
                  // abdapolicy: Gaf.getGafObjByRefId('POLICY')
        },
        // pIsBackKeyListen: true,
      },
      listeners: {
        closePopup: (result) => {
          // 중단건(70)인 경우 목록 재조회
          if(result === '70') {
            this.fn_Reset()
            this.fn_ServiceData('S')
          }
          this.$bottomModal.close(this.popupMSPPI800P)
        },
        resultPopup: (result) => {
          this.$bottomModal.close(this.popupMSPPI800P).then(() => {
            this.fn_OpenMSPPI820P(item)
          })
        }
      }
    },
    {
      properties: {
        noHeader: true
      }
    })
  },
  /******************************************************************************
  * Function명 : fn_OpenMSPPI820P
  * 설명       : 선심사 완료 팝업 오픈 함수
  ******************************************************************************/
  fn_OpenMSPPI820P (item) {
    // zaPlanId
    this.popupMSPPI820P = this.$bottomModal.open(MSPPI820P, {
      properties: {
        pPage: this.name,
        pParams: {zaPlanId: item.entplId},
        // pIsBackKeyListen: true,
      },
      listeners: {
        closePopup: (result) => {
          // result 비교
          if (!_.isEmpty(result)) {
            // 선심사번호 or 선심사결과코드 상이할 경우 목록 재조회
            if ( (item.entplId !== result.planId) || (item.zaNundwPrgStatCd !== result.nundwPrgStatCd) ) {
              this.fn_Reset()
              this.fn_ServiceData('S')
            }
          }
          this.$bottomModal.close(this.popupMSPPI820P)
        },
        resultPopup: (result) => {
          this.$bottomModal.close(this.popupMSPPI820P).then(() => {
            this.fn_OpenMSPPI800P(item)
          })
        }
      }
    },
    {
      properties: {
        noHeader: true
      }
    })
  },
  /******************************************************************************
   * Function명  : fn_NewAEUSClick
   * 설명        : New AEUS 팝업 호출
   ******************************************************************************/
  fn_NewAEUSClick(item) {
    this.popupMSPPI840P = this.$bottomModal.open(MSPPI840P, {
      properties: {
        pPage: this.name,
        pParams: {
          planId: item.entplId
        }
      },
      listeners: {
        closePopup: () => {
          this.$bottomModal.close(this.popupMSPPI840P)
        },
        resultPopup: () => {
          this.$bottomModal.close(this.popupMSPPI840P).then(() => {
          })
        }
      }
    },
    {
      properties: {
        noHeader: true
      }
    })
  },

  /*-----------------------------------------------------------------------------------------------*/  
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>