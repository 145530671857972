/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM931M
 * 화면 설명: 보험계약기본사항
 * 접근권한: FC, 지점장
 * 작 성 일: 2023.02.09
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container ref="page" title="보험계약기본사항" @on-header-left-click="fn_BackToPrevious" @on-scroll-bottom="fn_LoadingData"
                     class="msp" type="subpage" :show-title="false" action-type="search"  :topButton="true" >
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar on">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

        <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow> 
              <div slot="nav" class="icon-wrapper" @click="fn_BackToPrevious">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '보험계약기본사항' : '보험계약기본사항'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon-size="30px" type="picto" @click="fn_OpenMoreMenu()">more</mo-icon>
                <mo-icon icon="msp-hamburger" class="fs26rem" @click="fn_openMenu()"/>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="text_hidden">hidden</div>
              <ur-box-container alignV="start" v-if="isShow" componentid="ur_box_container_001" direction="row" class="ns-input-box">
                <div class="fexTy3">
                  <p class="fs19rem mt16 fwb">
                    <!-- 상품명  -->
                    {{ contBasMatrVO.prdtNm }}
                    <!-- 계약번호 -->
                    ({{ contBasMatrVO.contNo }})
                    <span class="ns-tool-tip ml5 msp_vertical--tb">
                      <mo-icon id="location_MSPPM931M" icon="msp-tool-tip"></mo-icon>
                      <mo-tooltip target-id="location_MSPPM931M" class="tool-tip-box">
                        <p>
                          ※ 연체일수는 실효일자-부활예정일자 전일까지의 경과일수이며, 회차별 누적연체일수는 미납입 각 회차별 연체일수를 누적, 합산한 일수입니다. 
                        </p>
                        <p>
                          ※ 부활예정일자가 미래일자인 경우, 금리연동형보험의 연체이자는 조회일 현재의 이율을 기준으로 계산되며, 갱신특약이 부가된 경우 부활예정일자가 갱신일자 이후인 경우에는 해당 특약의 갱신보험료가 산출되기 이전에는 갱신전 보험료를 기준으로 계산됩니다.<br>
                        </p>
                        <p>
                          ※ 특별계정 상품(연금저축/변액 등)의 경우, 부활예정일자가 계약해당일 전이면 연체보험료에 당월분 보험료는 제외되어 있습니다.
                        </p>
                      </mo-tooltip>                  
                    </span>
                  </p>
                  <!-- 계약상태 -->
                  <mo-badge :class="contDtlStatCdNm != 'N' ? 'lightblue' : 'lightred'" class="badge-sample-badge sm ml10 min40 align-self-start mt20 " text="계약상태" shape="status">{{ contDtlStatCdNmTxt }}</mo-badge>
                  <!-- <mo-badge v-if="!$bizUtil.isEmpty(lv_RetContVO.ukepStatCdClassNm)" class="badge-sample-badge sm ml10 min40 align-self-start mt20" :class="lv_RetContVO.ukepStatCdClassNm" text="정상" shape="status">{{ fn_GetUkepStatCdNm(lv_RetContVO) }}</mo-badge> -->

                </div>
              </ur-box-container>
          </template>  

          <!-- stickey 영역 -->
          <template #sticky>
            <div class="sd-b-Ty1">
              <!-- 메인 카테고리 출력 -->
              <mo-tab-box :default-idx="defMenuIdx" ref="mainTab" class="ns-move-tab-box">
                <!-- tab id loop -->
                <template v-for="item in mainMenuList"> 
                  <mo-tab-label :idx="item.idx.toString()" :key="item.menuId" @click="fn_ClickMainMenu(item.viewId)">{{ item.menuNm }}</mo-tab-label>
                </template>
                <!-- tab id loop //-->
              </mo-tab-box>
              <!-- 메인 카테고리 출력 //-->
              <!-- 서브카테고리 출력 -->
              <template v-if="subMenuList.length > 1">
                <ur-box-container alignV="start" componentid="ur_box_container_111" direction="column">
                  <ur-tabs-container :tabId="lv_TabIdValue" ref="tabsContainer" @tab-changed="fn_ClickSubMenu" class="ns-tabs-box2 no-tab" componentid="ur_tabs_container_110">
                    <template v-for="item in subMenuList"> 
                      <ur-tab-item-container  :componentid="item.menuId" :title="item.menuNm" :tabId="item.viewId" :key="item.menuId"></ur-tab-item-container>
                    </template>
                  </ur-tabs-container>         
                </ur-box-container>
              </template>
              <!-- 서브카테고리 출력 //-->
            </div>
          </template>

          <!-- scroll 영역 -->
          <template v-if="!isNoData" #scroll>
            <!-- 계약정보 -->  
            <MSPPM932D v-show="lv_ViewId === 'MSPPM932D' || lv_ViewId === 'MSPPM934D' || lv_ViewId === 'MSPPM935D'" ref="MSPPM932D"  :pChnlCustId="lv_ChnlCustId" :pCnsltNo="lv_CnsltNo"
                        @setChildData="fn_SetMSPPM932DData" @setRetContVO="fn_SetRetCont"/> <!-- 계약정보 -->  
            <MSPPM934D v-show="lv_ViewId === 'MSPPM932D' || lv_ViewId === 'MSPPM934D' || lv_ViewId === 'MSPPM935D'" ref="MSPPM934D" />                                                                                        <!-- 상품상세정보 -->
            <MSPPM935D v-show="lv_ViewId === 'MSPPM932D' || lv_ViewId === 'MSPPM934D' || lv_ViewId === 'MSPPM935D'" ref="MSPPM935D" :pMSPPM932DData="contInfoVO"/>                                                            <!-- 유지/유의정보 -->
             <!-- 보장내용 -->
            <MSPPM905D v-show="lv_ViewId === 'MSPPM905D'" ref="MSPPM905D" :pMSPPM932DData="contInfoVO"/>
            <!-- 변액정보 -->
            <MSPPM907D v-show="lv_ViewId === 'MSPPM907D'" ref="MSPPM907D" :pMSPPM932DData="contInfoVO"/>                                                            <!-- 변액정보 -->
            <MSPPM941D v-show="lv_ViewId === 'MSPPM941D'" ref="MSPPM941D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 적립액변동내역 -->
            <MSPPM942D v-show="lv_ViewId === 'MSPPM942D'" ref="MSPPM942D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 기간별펀드수익률 -->
            <MSPPM943D v-show="lv_ViewId === 'MSPPM943D'" ref="MSPPM943D" />                                                                                        <!-- 추천포트폴리오 -->
            <!-- 미래예시 -->
            <MSPPM951D v-show="lv_ViewId === 'MSPPM951D'" ref="MSPPM951D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 해약환급금 -->
            <MSPPM953D v-show="lv_ViewId === 'MSPPM953D'" ref="MSPPM953D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 미래연금 -->
            <MSPPM968D v-show="lv_ViewId === 'MSPPM968D'" ref="MSPPM968D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 갱신보험료 -->
            <!-- 지급확인 -->
            <MSPPM908M v-show="lv_ViewId === 'MSPPM908M'" ref="MSPPM908M" />                                                                                        <!-- 해약환급금 -->
            <MSPPM961D v-show="lv_ViewId === 'MSPPM961D'" ref="MSPPM961D" @scrollDown="fn_scrollDown"/>                                                                                        <!-- 분할보험금 -->
            <MSPPM962D v-show="lv_ViewId === 'MSPPM962D'" ref="MSPPM962D" @scrollDown="fn_scrollDown"/>                                                                                         <!-- 중도인출금 -->
            <!-- 처리이력 -->
            <MSPPM963D v-show="lv_ViewId === 'MSPPM963D'" ref="MSPPM963D" :pMSPPM932DData="contInfoVO"/>                                                            <!-- 입금내역 -->     
            <MSPPM964D v-show="lv_ViewId === 'MSPPM964D'" ref="MSPPM964D"/>                                                                                         <!-- 월대체내역 -->
            <MSPPM965D v-show="lv_ViewId === 'MSPPM965D'" ref="MSPPM965D"/>                                                                                         <!-- 지급내역 -->
            <MSPPM966D v-show="lv_ViewId === 'MSPPM966D'" ref="MSPPM966D"/>                                                                                         <!-- 변경내역 -->
            <MSPPM967D v-show="lv_ViewId === 'MSPPM967D'" ref="MSPPM967D"/>                                                                                         <!-- 보험계약대출 -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- 더보기메뉴 -->
    <mo-bottom-sheet ref="refMoreList" class="ns-bottom-sheet" :close-btn="true">
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
          <mo-list :list-data="moreMenuList">
            <template #list-item="{ item }">
              <mo-list-item>
                <mo-button @click="fn_CloseMoreMenu(item.viewId)" class="ns-btn-check">
                  <template v-if="!item.asMenuNm">{{ item.menuNm }}</template>
                  <template else>{{ item.asMenuNm }}</template>
                  <mo-icon icon="msp-check-bold"/>
                </mo-button>              
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>

    <div class="chevron_area" v-show="isShowChevron">
      <div class="chevron" @click="fn_ClickChevron()">
        <a href="#">
          <!-- <mo-icon icon="more_view" /> -->
          <i></i>
          <span>더보기</span>
        </a>
      </div>
    </div> 
  </ur-page-container>
</template>

<script>

import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate' // error
import Msg         from '@/systems/webkit/msg/msg'
import pmConstants from '@/config/constants/pmConstants'

import MSPPM932D   from '@/ui/pm/MSPPM932D.vue' // 계약정보 > 계약정보 
import MSPPM934D   from '@/ui/pm/MSPPM934D.vue' // 계약정보 > 상품상세정보  
import MSPPM935D   from '@/ui/pm/MSPPM935D.vue' // 계약정보 > 유지/유의정보 
import MSPPM905D   from '@/ui/pm/MSPPM905D.vue' // 보장내용 : 1차반영
import MSPPM907D   from '@/ui/pm/MSPPM907D.vue' // 변액정보 > 변액정보

import MSPPM941D   from '@/ui/pm/MSPPM941D.vue' // 변액정보 > 적립액변동내역
import MSPPM942D   from '@/ui/pm/MSPPM942D.vue' // 변액정보 > 기간별펀드수익률
import MSPPM943D   from '@/ui/pm/MSPPM943D.vue' // 변액정보 > 추천포트폴리오
import MSPPM951D   from '@/ui/pm/MSPPM951D.vue' // 미래예시 >  해약환급금
import MSPPM953D   from '@/ui/pm/MSPPM953D.vue' // 미래예시 >  미래연금
import MSPPM968D   from '@/ui/pm/MSPPM968D.vue' // 미래예시 >  갱신보험료

import MSPPM908M   from '@/ui/pm/MSPPM908M.vue' // 지급확인 >  해약환급금 : 1차반영 

import MSPPM961D   from '@/ui/pm/MSPPM961D.vue' // 지급확인 >  분할보험금
import MSPPM962D   from '@/ui/pm/MSPPM962D.vue' // 지급확인 >  중도인출금

import MSPPM963D   from '@/ui/pm/MSPPM963D.vue' // 처리이력 >  입금내역 : 1차반영     
import MSPPM964D   from '@/ui/pm/MSPPM964D.vue' // 처리이력 >  월대체내역 : 1차반영   
import MSPPM965D   from '@/ui/pm/MSPPM965D.vue' // 처리이력 >  지급내역 : 1차반영     
import MSPPM966D   from '@/ui/pm/MSPPM966D.vue' // 처리이력 >  변경내역 : 1차반영      
import MSPPM967D   from '@/ui/pm/MSPPM967D.vue' // 처리이력 >  보험계약대출 : 1차반영  


export default {

  name: 'MSPPM931M', 
  screenId: 'MSPPM931M', 
  components: {    
    MSPPM932D, MSPPM934D, MSPPM935D,
    MSPPM905D,
    MSPPM907D, MSPPM941D, MSPPM942D, MSPPM943D,
    MSPPM951D, MSPPM953D, MSPPM968D,
    MSPPM908M, MSPPM961D, MSPPM962D,
    MSPPM963D, MSPPM964D, MSPPM965D, MSPPM966D, MSPPM967D,
  },//componets,
  props:{
    viewId: '',
    callerId: '',
    chnlCustId: '',
    cnsltNo: '',
    contNo:'',
  },//props

  data () {
    return {
      title: '보험계약기본사항',
      
      /**
       * router param 변수 
       */
      lv_CallerId: '',                // 송신화면 ID
      lv_ViewId: '',                  // 수신디비전 ID
      lv_ChnlCustId: '',              // 채널고객 ID
      lv_CnsltNo: '',                 // 컨설턴트번호
      lv_ContNo:'',                   // 계약번호 

      lv_InputParam: {},              // 서비스호출 파라미터 

      lv_HeaderviewScrollCompID: '',  // 스크롤 대상 컴포넌트 ID : error 

      
      /**
       * 화면 변수
       */
      isNoData: false,
      isExistedTab: false,            // 변액정보 탭 노출 여부
      isCreated: true,                // 최초조회 여부 
      isShow: false,                  // 계약기본정보조회 서비스 종료여부
      isShowChevron:false,            // 하단 더보기 버튼 노출여부
      lv_UserInfo: {},                // 사용자 정보

      /**
       * 탭변수
       */
      defMenuIdx: '1',                // 메뉴 기본 idx 값 

      mainMenuList: [],               // 메인메뉴 전체 list 데이터 
      vainsInfoTab: [],               // 변액정보 탭 Object 데이터 
      selectedMain: {},               // 선택된 메인메뉴 Object
      subMenuList: [],                // 서브메뉴 전체 list 데이터 
      selectedSub: {},                // 선택된 서브메뉴 Object

      lv_TabIdValue: '',

      /**
       * 조회 변수
       */ 
      contBasMatrVO: {},              // 보험계약기본사항 데이터                                           
      contDtlStatCdNm: '',            // 계약상태 code 
      contDtlStatCdNmTxt: '',         // 계약상태 text
      contInfoVO: {},                 // 계약정보 > 계약정보 데이터 (MSPPM932D)
      conctScCd: this.$MenuManager.fn_GetInternalNetwork() ? '1' : '2', // 내부망 여부 

      lv_RetContVO: {},               // 보유계약 데이터
      /**
       * 더보기메뉴 변수
       */
      moreMenuList: [],

    }
  },//data

  created () {
    
    window.vue.getStore('progress').dispatch('FULL')    // post 호출 전 Progress 타입 설정

    this.$BottomManager.fn_SetBottomVisible(false)      // GNB 숨기기 

    this.mainMenuList  = JSON.parse(JSON.stringify(pmConstants.PM_CONT_MENU_LIST_))     // 메인메뉴 전체 list 데이터 
    this.vainsInfoTab  = JSON.parse(JSON.stringify(pmConstants.PM_CONT_MENU_LIST_[2]))  // 변액정보 탭 Object 데이터 
        
    this.lv_ChnlCustId = this.$route.params.chnlCustId  
    this.lv_CallerId = (this.$route.params.callerId) ? this.$route.params.callerId : ''
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    
   /**
    * @notice nameUI로 다른화면 이동 가능한데,
    *         다시 MSPPM931M로 이동 후 reload하면 계약번호 및 컨설턴트번호 없어서 서비스 진행 불가하다.
    *         => 계약번호 및 컨설턴트번호 undefined 이면 pmStore에서 해당 값 가져와서 세팅해야한다.
    */
    this.lv_CnsltNo    = (this.$route.params.cnsltNo) ?
                          this.$route.params.cnsltNo : this.getStore('pmStore').getters.getState.contInfo.cnsltNo   
    this.lv_ContNo     = (this.$route.params.contNo) ? 
                          this.$route.params.contNo : this.getStore('pmStore').getters.getState.contInfo.contNo     

    console.log(this.lv_ViewId, this.lv_CallerId, this.lv_ChnlCustId, this.lv_CnsltNo, this.lv_ContNo);
    
    
    /**
     * @notice 다른탭에서 해당 화면(MSPPM932D) 데이터를 필요로하기 때문에
     *         일단 무조건 계약정보 > 계약정보(MSPPM932D) 화면으로 세팅하여 서비스를 조회해준다.
     */
    this.lv_ViewId = this.mainMenuList[0].subList[0].viewId
    
    /**
     * @notice '변액정보' 데이터가 존재할 경우만, '변액정보' 탭 화면에 노출히기 때문에
     *          최초 진입 할 때, '변액정보' 탭 제거 하고 시작한다. 
     */
    if(this.mainMenuList[2].idx === 3) this.mainMenuList.splice(2,1)  


    // 기본계약사항 서비스 
    this.fn_Submit()
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackToPrevious) // backKey Event 등록
    
    // 스크롤 애니메이션 instance 선언 : error
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.scrollEvent)
    this.$refs.page.setScrollElementId(this.lv_HeaderviewScrollCompID)
    this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.lv_HeaderviewScrollCompID), 'y')

  },//mounted

  beforeDestroy () {
    
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackToPrevious) //backKey Event 해제

    this.$BottomManager.fn_SetBottomVisible(true)   // GNB 보이기 

  },//beforeDestroy

  watch: {},//watch

  computed: {},//computed

  methods: {

   /**
    * @description 디비전 화면의 뒤로가기 함수를 호출한다.
    */
    fn_BackToPrevious(){
      let rParam = this.$route.params
      if (!_.isEmpty(rParam)) {
        if (rParam.callerId === 'MSPCM270M' && rParam.callerId === 'MSPCM285D' && rParam.auth === 'D') {
          this.$router.go(-3)
          return
        }
      }
      switch(this.lv_ViewId){
        case 'MSPPM951D': // 미래예시 > 해약환급금
          this.$refs.MSPPM951D.fn_BackToPrevious()
          break
        case 'MSPPM953D': // 미래예시 > 미래연금 
          this.$refs.MSPPM953D.fn_BackToPrevious()
          break
        default:
          this.$router.go(-1)
          break
      }
    },

    /**
     * @description 디비전 화면의 인피니트 로딩 함수를 호출한다.
     */
    fn_LoadingData(){
      switch(this.lv_ViewId){
        case 'MSPPM951D': // 미래예시 > 해약환급금
            this.$refs.MSPPM951D.fn_LoadingData()    
          break
        default:
          break
      }
    },

   /**
    * @description 메인메뉴(탭) 이벤트 핸들러이다.
    * @param       {String} viewId 선택된 메인화면ID
    */
    fn_ClickMainMenu(viewId){

      this.lv_ViewId = viewId

      //계약정보 화면일 경우 affordance btn 노출 (2024-01-11 선심사 및 영업시스템 고도화 PJT)
      if(viewId === "MSPPM932D") {
        this.isShowChevron = true
      } else {
        this.isShowChevron = false
      }

      // 메뉴 설정 
      this.fn_SetMenu(viewId)

      // subMenu 없으면 바로 해당 탭에 대한 서비스 호출을 한다.
      if(this.selectedMain.subList.length === 0)
        this.fn_SubmitMenu(this.lv_ViewId)

    },
    
   /**
    * @description 서브메뉴(탭) 이벤트 핸들러이다.
    * @param       {String} viewId 선택된 서브화면ID
    */
    fn_ClickSubMenu(viewId){
      this.lv_ViewId = viewId

      // 메뉴 설정 
      this.fn_SetMenu(viewId)

      // 해당 메뉴 서비스 조회
      this.fn_SubmitMenu(viewId)

    },

   /**
    * @description 메인메뉴(탭) 및 서브메뉴(탭) 구성을 설정한다.
    * @param       {String}  viewId  선택된 화면ID 
    */
    fn_SetMenu (viewId) {
      
      let lv_Vm = this
      
      this.lv_ViewId = viewId // 선택된 메뉴(탭) ID 저장 

      // 메뉴(탭) 세팅 
      this.mainMenuList.forEach((mainMenu) => {

        // 선택된 메뉴(탭)의 sublist가 없을 경우 
        if(mainMenu.subList.length === 0 && mainMenu.viewId === lv_Vm.lv_ViewId) {
          lv_Vm.selectedMain = mainMenu
          lv_Vm.selectedSub  = mainMenu.subList
          lv_Vm.subMenuList  = mainMenu.subList
        }
        // 선택된 메뉴(탭)의 subList가 있을 경우 
        else {
          mainMenu.subList.forEach((subMenu) => {
            if (subMenu.viewId === lv_Vm.lv_ViewId) {
              lv_Vm.selectedMain = mainMenu
              lv_Vm.selectedSub  = subMenu
              lv_Vm.subMenuList  = mainMenu.subList
            }
          })
        }

      })

      // 메인메뉴(탭) 트리거 
      for (let k = 0; k < this.mainMenuList.length; k++) {
        if (this.mainMenuList[k].menuId === this.selectedMain.menuId) {
          this.defMenuIdx = this.mainMenuList[k].idx.toString()
          break
        }
      }

      this.lv_TabIdValue = lv_Vm.selectedSub.viewId
      // 소분류 트리거는 카테고리 코드로 el에 세팅
      setTimeout(() => {
        // UI_DEV 수정되면 타임아웃 삭제
        if (lv_Vm.subMenuList.length > 1) {
          lv_Vm.$refs.tabsContainer.toggleTab(lv_Vm.selectedSub.viewId)
        }
        this.$refs.mainTab.setIdx(this.defMenuIdx)
        
      });

    },

   /**
    * @description 더보기메뉴가 열린다.
    */
    fn_OpenMoreMenu () {
      this.$refs.refMoreList.open()
    },

   /**
    * @description 메인/서브메뉴 설정 후, 더보기메뉴가 닫힌다.
    */
    fn_CloseMoreMenu (viewId) {
      
      this.lv_ViewId = viewId

      // 메뉴(탭) 설정 
      this.fn_SetMenu(this.lv_ViewId)

      // subMenu 없으면 바로 해당 탭에 대한 서비스 호출
      if(this.selectedMain.subList.length === 0)
        this.fn_SubmitMenu(this.lv_ViewId)


      this.$refs.refMoreList.close()

    },

   /**
    * @description 선택된 메뉴(탭)의 화면의 서비스 함수를 호출한다.
    * @param       {String} viewId 화면ID
    */
   fn_SubmitMenu(viewId){

     if(this.isNoData) return 
    

    //계약정보 내 소분류 선택 시, 스크롤 이동(2024-01-11 선심사 및 영업시스템 고도화 PJT)
    let offsetTop = 0

    switch(viewId){
      case 'MSPPM932D': // 계약정보 > 계약정보
        offsetTop = 0
        break
      case 'MSPPM934D': // 계약정보 > 상품상세정보 
        offsetTop = this.$refs.MSPPM932D.$el.scrollHeight + 40
        break
      case 'MSPPM935D': // 계약정보 > 유지/유의정보 
        offsetTop = this.$refs.MSPPM932D.$el.scrollHeight + this.$refs.MSPPM934D.$el.scrollHeight + 40 
        break
      case 'MSPPM905D': // 보장내용
        this.$refs.MSPPM905D.fn_GetContractInfo()
        break
      case 'MSPPM907D': // 변액정보 > 변액정보 
        this.$refs.MSPPM907D.fn_Submit()
        break
      case 'MSPPM941D': // 변액정보 > 적립액변동내역 
        this.$refs.MSPPM941D.fn_Submit()
        break
      case 'MSPPM942D': // 변액정보 > 기간별펀드수익률 
        this.$refs.MSPPM942D.fn_Submit()
        break
      case 'MSPPM943D': // 변액정보 > 추천포트폴리오 
        this.$refs.MSPPM943D.fn_Submit()
        break
      case 'MSPPM951D': // 미래예시 > 해약환급금 
        this.$refs.MSPPM951D.fn_Submit(true)
        this.$refs.MSPPM951D.fn_ShowChervon()
        break
      case 'MSPPM953D': // 미래예시 > 미래연금 
        this.$refs.MSPPM953D.fn_Submit(false)
        break
      case 'MSPPM968D': // 미래예시 > 갱신보험료 
        this.$refs.MSPPM968D.fn_SetEntrTypData(' ')
        this.$refs.MSPPM968D.fn_ShowChervon()
        break
      case 'MSPPM908M': // 지급확인 > 해약환급금
        this.$refs.MSPPM908M.fn_GetSrdvlDtlData()
        break
      case 'MSPPM961D': // 지급확인 > 분할보험금 
        this.$refs.MSPPM961D.fn_Submit()
        break
      case 'MSPPM962D': // 지급확인 > 중도인출금 
        this.$refs.MSPPM962D.fn_Submit(null, true)
        this.$refs.MSPPM962D.fn_ShowChervon()
        break
      case 'MSPPM963D': // 처리이력 > 입금내역
        this.$refs.MSPPM963D.fn_RctnData()
        break
      case 'MSPPM964D': // 처리이력 > 월대체내역 
        this.$refs.MSPPM964D.fn_MrpData()
        break
      case 'MSPPM965D': // 처리이력 > 지급내역
        this.$refs.MSPPM965D.fn_PymData()
        break
      case 'MSPPM966D': // 처리이력 > 변경내역
        this.$refs.MSPPM966D.fn_ChgData()
        break
      default:
        break
     } 
      this.scrollAnimateY.animate(offsetTop, 700, 0, 'easeOut')
     

   },
    
   /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     *              => 보험기본계약사항 데이터 조회
     */
    fn_Submit(){

      // 조회조건 세팅 
      this.lv_InputParam.contNo    = this.lv_ContNo   // 계약번호
      this.lv_InputParam.conctScCd = this.conctScCd   // 내부망 여부 

      // 서비스 호출 
      this.fn_GetService()

    },

    /**
     * @description 보험기본계약사항 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {
      const auth = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: '',
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: 'ICSelRetContCtlImpl',
        reqMthdNm: 'selListRetContDtl',
        targetSystemCode: 'sli.ic',
        resVONm: 'sli.ch.vo.ic.mngretcont.ICRetContDtlCmpxVO',
        reqVONm: 'sli.ch.vo.ic.mngretcont.ICRetContDtlCmpxVO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
      
    },

   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {
        
        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        // 데이터 없음
        this.isNoData = true 

        return
      }else{ this.isNoData = false }


      let responseVO = response.body.icretContDtlVO

      // 데이터 가공
      responseVO.markUpContNo = '*' + responseVO.contNo.substr(responseVO.contNo.length - 4, 4)  
      responseVO.cnsltNo      = this.lv_CnsltNo

      // 데이터 저장
      this.contBasMatrVO = responseVO
      this.isShow = true 
      
      // pmStore에 데이터 저장 
      window.vue.getStore('pmStore').dispatch('ADD', responseVO)

      //메인화면 로딩 시, 계약정보 텝 서비스 조회(2024-01-11 선심사 및 영업시스템 고도화 PJT)
      if(this.lv_ViewId === 'MSPPM932D' || this.lv_ViewId === 'MSPPM934D' || this.lv_ViewId === 'MSPPM935D') {
        this.$refs.MSPPM932D.fn_Submit() // 계약정보 > 계약정보
        this.$refs.MSPPM934D.fn_Submit() // 계약정보 > 상품상세정보
        this.$refs.MSPPM935D.fn_Submit() // 계약정보 > 유지/유의정보
      }
      
      // 고객카드 -> 터치이력 -> 계약사항변경에서 호출한 경우 바로 처리이력 -> 변경내역 탭으로 이동
      if (this.lv_CallerId === 'MSPCM285D') {
        this.isCreated = false
        this.isExistedTab = true
        this.fn_ClickSubMenu('MSPPM966D')
      } else {
        // 메뉴(탭) 설정 
        this.fn_SetMenu(this.lv_ViewId)
      }

    },

   /**
    * @description MSPPM932D 데이터를 세팅한다.
    * @param       {Object} childData 자식 데이터 
    */
    fn_SetMSPPM932DData(childData){

      console.log('TAB before',this.isExistedTab, this.mainMenuList);
      console.log(this.subMenuList);
      
      // 미등록 계약일 경우, 화면접근이 불가하다.
      if(childData.isNoData) {
        this.isNoData = childData.isNoData
        return
      }

      // 최초 조회일 경우, router viewId 설정한다.
      if(this.isCreated) this.fn_SetViewId()

      // 이미 이전에 탭 세팅을 완료했으면, pass
      if(this.isExistedTab) return

      // 변액정보 여부 'Y'이면 메뉴(탭)에 노출한다.
      if(childData.vainsPrdtInfoIcluYn === 'Y') {
        this.mainMenuList.splice(2,0,this.vainsInfoTab) 
        // 메뉴(탭) 설정 
        if(this.lv_ViewId!== this.mainMenuList[0].subList[0].viewId)
          this.fn_SetMenu(this.lv_ViewId)
      }

      // 더보기 메뉴 세팅
      this.fn_SetMoreMenu()

      this.isExistedTab = true

      // 계약상태 세팅 
      if(this.$bizUtil.isEmpty(childData.contDtlStatCdNm)){
        this.contDtlStatCdNm = 'Y'
      } else if(['정상', '완납', '면제'].includes(childData.contDtlStatCdNm)){
        this.contDtlStatCdNm = 'Y'
      }else{
        this.contDtlStatCdNm = 'N'
      }
      this.contDtlStatCdNmTxt = childData.contDtlStatCdNm

      // 계약정보(MSPPM932D) 데이터 저장 
      this.contInfoVO = childData.contInfoVO
      
      this.isShowChevron = true


      console.log('TAB after',this.isExistedTab, this.mainMenuList);
      console.log(this.subMenuList);

    },

    /**
     * @description 보험계약기본사항 서비스 조회 후, MSPPM932D 서비스를 필수적으로 조회해야한다.
     *              => 위의 서비스 모두 조회한 다음, router에서 받은 수신디비전ID를 설정해줘야한다.
     */
    fn_SetViewId(){

      // router로 전달받은 수신디비전이 없는경우, 계약정보 > 계약정보(MSPPM932D)를 기본값으로 설정한다.
      let temp = this.$route.params.viewId
      if(temp === undefined || temp === null || temp === '')  {
        this.lv_ViewId = this.mainMenuList[0].subList[0].viewId
      }
      else{
        this.lv_ViewId = temp 

        if(temp !== this.mainMenuList[0].subList[0].viewId){
          // 수신디비전이 변액정보 탭일 경우, 메뉴(탭) 에 탭이 추가가 안되어 있어서 일단 패스한다.
          if(childData.vainsPrdtInfoIcluYn !== 'Y')
            this.fn_SetMenu(this.lv_ViewId)
        }
      }

      this.isCreated = false

    },

    /**
     * @description 더보기 메뉴를 세팅한다.
     */
    fn_SetMoreMenu(){

      this.mainMenuList.forEach((main) => {

        if(main.subList.length === 0){
          this.moreMenuList.push(main)
        }else{
          main.subList.forEach((sub) => {
            // '해약환급금' 동일한 명칭이라서 구분 필요 
            if(sub.viewId === 'MSPPM951D') sub.asMenuNm = '해약환급금 예시'

            this.moreMenuList.push(sub)
          })
        }

      })

    },
    fn_SetRetCont (retContVO) {
      this.lv_RetContVO = retContVO

      this.fn_GetUkepStatCdNm(retContVO)
    },
    /**
     * Function명 : fn_GetUkepStatCdNm
     * 설명       : 상태코드명 getter
     * @param {Object} item 보유계약 데이터 
     * @return {String} - 상태코드명
     */
    fn_GetUkepStatCdNm (item) {
      let t_UkepStatCdNm = ''
      if ( item.chnlUkepStatCd === '00' ) {
         t_UkepStatCdNm = '정상'
         this.lv_RetContVO.ukepStatCdClassNm = 'lightblue'
      } else if ( item.chnlUkepStatCd === '03' ) {
         t_UkepStatCdNm = '면제'
         this.lv_RetContVO.ukepStatCdClassNm = 'lightyellow3'
      } else if ( item.chnlUkepStatCd === '04' ) {
        t_UkepStatCdNm = '완납'
        this.lv_RetContVO.ukepStatCdClassNm = 'lightgreen'
      } else if ( item.chnlUkepStatCd === '01' ) {
        t_UkepStatCdNm = '연체'
        this.lv_RetContVO.ukepStatCdClassNm = 'lightred'
      } else if ( item.chnlUkepStatCd === '02' ) {
        t_UkepStatCdNm = '실효'
        this.lv_RetContVO.ukepStatCdClassNm = 'lightred2'
      } else {
        t_UkepStatCdNm = item.ukepStatCd
        this.lv_RetContVO.ukepStatCdClassNm = 'lightblue'
      }
      return t_UkepStatCdNm
    },

    /**
     * Function명 : fn_ClickChevron
     * @description       : affordance btn 클릭 시, 스크롤 이동 및 버튼 제거
     * (2024-01-11 선심사 및 영업시스템 고도화 PJT)
     */
    fn_ClickChevron () {
      this.isShowChevron = false
      let offsetTop = 0
      offsetTop = this.$refs.MSPPM932D.$el.scrollHeight + 40
      this.scrollAnimateY.animate(offsetTop, 700, 0, 'easeOut')
    },
    /**
     * Function명 : scrollEvent
     * @description       : 화면 진입 후, scrollEvent 발생 시, 버튼 제거
     * (2024-01-11 선심사 및 영업시스템 고도화 PJT)
     */
    scrollEvent(e) {
      let target = e.target.scrollTop
      // console.log("target >> ", target, e.target, e)
      // console.log("clientHeight >>",this.$refs.headerview.$el.clientHeight)
      // console.log("scrolltHeight >>",this.$refs.headerview.$el.scrollHeight)
      if(this.isShowChevron === true) {
        if( target > 100 ) {
          this.isShowChevron = false 
        }
      }

      let viewId = ''
      // console.log(this.$refs.tabsContainer)
      if(this.selectedMain.viewId === 'MSPPM932D') {
        if(this.$refs.tabsContainer.selectedTabId === 'MSPPM934D' && (target < this.$refs.MSPPM932D.$el.offsetHeight - 300)){
          viewId = 'MSPPM932D'
        }
        else if((this.$refs.tabsContainer.selectedTabId === 'MSPPM932D' || this.$refs.tabsContainer.selectedTabId === 'MSPPM935D') 
            && this.$refs.MSPPM932D.$el.offsetHeight < target && this.$refs.MSPPM932D.$el.offsetHeight + this.$refs.MSPPM934D.$el.offsetHeight > target){
          viewId = 'MSPPM934D'
        }
        else if(this.$refs.tabsContainer.selectedTabId !== 'MSPPM935D' && this.$refs.MSPPM932D.$el.offsetHeight + this.$refs.MSPPM934D.$el.offsetHeight < target){
          viewId = 'MSPPM935D'
        }
      }

      // 디비전 affordancce 버튼 제어
      if(!this.$bizUtil.isEmpty(this.$refs.tabsContainer)) {
        let pageNm = this.$refs.tabsContainer.selectedTabId
        if(pageNm === 'MSPPM951D' || pageNm === 'MSPPM968D' || pageNm === 'MSPPM941D'|| pageNm === 'MSPPM942D' || pageNm === 'MSPPM961D' || pageNm === 'MSPPM962D'
        || pageNm === 'MSPPM953D') {
          if(target > 10) {
            this.$refs[pageNm].fn_ctrAffordance()
          }
        }
      }

      if(viewId !== ''){
        this.$refs.tabsContainer.selectedTabId = viewId
        // this.$refs.tabsContainer.toggleTab(viewId)
      }
    },
    
    /**
     * Function명 : fn_openMenu
     * @description       : 디비전 내, 표 모드 DOWN 어포던스버튼 클릭 시 Y축 스크롤 이동
     */
    fn_scrollDown() {
      this.scrollAnimateY.animate(300, 700, 0, 'easeOut')
    },
    /**
     * Function명 : fn_openMenu
     * @description       : 전체메뉴 오픈
     */
    fn_openMenu(){
      this.$BottomManager.fn_SetBottomMenuDrawerOpen()
    }
 },// methods

}//export default


</script>
<style scoped>
</style>