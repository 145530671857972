/*
 * 업무구분: 고객
 * 화 면 명: MSPCM133M
 * 화면설명: 비대면 고객동의
 * 접근권한: 일반사용자
 * 작 성 일: 2022.06.30
 * 작 성 자: 송웅주
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="비대면 고객동의" type="subpage" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">
        <mo-validate-watcher ref="vWatcher">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="input-box mb20">
            <mo-text-field ref="custRef" :rules="validateRule" v-model="fn_CustNm" underline placeholder="고객명 입력 후 조회하기를 선택해 주세요" class="form-input-name" :disabled="custInfoDisabled"
              @keyup.enter="fn_SearchCustomerInfo"/>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-round-btn">
              <mo-button class="ns-btn-round" @click="fn_SearchCustomerInfo" :disabled="custInfoDisabled">조회하기</mo-button>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check" v-if="showResult">
              <mo-checkbox class="is-checked" readonly>{{ isExistCustYN === 'Y' ? '기존고객입니다.' : '신규고객입니다.' }}</mo-checkbox>
            </ur-box-container>
            <ur-box-container v-show="isExistCustYN === 'Y'" direction="column" alignV="start" componentid="ur_box_container_001" class="ns-radio-list2 customer-list-ty">
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>필수컨설팅동의<b v-if="infoPrcusMcnstYn === 'Y'" class="crTy-blue2 pl10">{{ infoPrcusMcnstYn }}</b><b v-else class="crTy-orange2 pl10">{{ infoPrcusMcnstYn }}</b>
                  </span>
                  <em>|</em>
                  <span>마케팅동의<b v-if="infoPrcusMktYn === 'Y'" class="crTy-blue2 pl10">{{ infoPrcusMktYn }}</b><b v-else class="crTy-orange2 pl10">{{ infoPrcusMktYn }}</b>
                  </span>
                </div>
              </div>
            </ur-box-container>
          </ur-box-container>
        </mo-validate-watcher>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box">
          <ur-box-container v-if="showResult" alignV="start" componentid="" direction="column" class="info-box">
            <div class="info-title-wrap mb10">
              <span class="info-title must">연락처</span>
            </div>
            <div class="verify-phone">
              <!-- 휴대폰 앞자리-->
              <msp-bottom-select :items="phoneItems" v-model="fn_CelnoFrno" underline class="mr0 w100px" placeholder="010" bottom-title="" closeBtn />
              <!-- 휴대폰 뒷자리-->
              <mo-decimal-field numeric ref="celnoRef" :rules="validateRuleCelno" v-model="fn_CelnoBkno" mask="########" maxlength="8" underline placeholder="00000000" class="mb0 txt-center" />
            </div>
          </ur-box-container>
          <p class="untact-txt">
            비대면 고객동의가 궁금하시다면
            <span class="ns-tool-tip">
              <mo-icon-button id="location0" icon-type="line" class="tool-tip-btn">tool-tip</mo-icon-button>
              <mo-tooltip target-id="location0" class="tool-tip-box">
                <div class="untact-box">
                  <p class="title">비대면 동의란</p>
                  <img src="/images/img-untact-agree.svg">
                  <span>동의요청을 보내면 고객에게 동의 웹페이지 링크가 발송되며, 고객이 직접 동의처리를 진행합니다.<br><br>
                  고객이 동의 절차를 마치면 자동으로 동의 완료됩니다.</span>
                </div>
              </mo-tooltip>
            </span>
          </p>
          <p v-if="showResult" class="untact-txt pt30">
            ※ 휴대폰번호 오입력시 고객 VOC 발생의 소지가 있으며 이로 인한 귀책사유는 전송 당사자에게 있으니, 휴대폰번호를 반드시 재확인 해주시기 바랍니다.
          </p>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative" v-if="!show_sendBtn" >
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="disabled_sendBtn" @click="fn_Send">비대면 고객동의 전송</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택
import MSPCM134P from '@/ui/cm/MSPCM134P' // 알림톡 발송 안내 팝업
import MSPCM136P from '@/ui/cm/MSPCM136P' // 비대면동의 요청사항 팝업
import cmConstants from '@/config/constants/cmConstants'

export default {
  // 현재 화면명
  name: 'MSPCM133M',
  // 현재 화면 ID
  screenId: 'MSPCM133M',
  components: {
    MSPCM005P,
    MSPCM134P,
    MSPCM136P,
    MspBottomSelect
  },
  created () {
    var vm = this

    let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
    window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

    let pObj = this.$route.params
    if (!this.$bizUtil.isEmpty(pObj)) {
      this.custNm = pObj.custNm

      if(!this.$bizUtil.isEmpty(pObj.celno)) {
        this.chnlCustId = pObj.chnlCustId
        this.celno = pObj.celno.replace(/-/gi, '')
        this.showResult = true
        this.show_sendBtn = false // 버튼 활성화
        this.custInfoDisabled = true // 이름, 버튼 비활성화
      }
    }

    if (this.$bizUtil.isEmpty(this.custNm)) {
      this.$nextTick(_ => {
        vm.$refs['custRef'].focus()
      })
    }

    // 휴대전화 앞자리 정보 세팅
    let defaultValu = {value: '0', text: '선택'}
    let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
    this.phoneItems = [defaultValu, ...lv_CelphCD]

    // 휴대폰번호 앞자리 초기값은 '010'
    this.fn_CelnoFrno = '010'
  },
  // 컴포넌트 선언
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      stndKeyList: [],
      custInfoDisabled: false,
      custNm: '',
      realCustNm: '',
      chnlCustId: '',
      celno: '',
      celnoFrno: '',
      celnoBkno: '',
      vuchId: '',
      isExistCustYN: '',
      infoPrcusMcnstYn: '',
      infoPrcusMktYn: '',
      popup005: null,
      popup134: null,
      popup136: null,
      infoPrcusCnsntTypCd: '18',
      ltrqMthod: '',
      cnsntReqYmd: '',
      showResult: false,
      show_sendBtn: true,
      disabled_sendBtn: true,
      phoneItems: [],   //휴대폰 앞자리
      chkCelnoResult: true, // 핸드폰번호 유효성
      celnoErrorMsg: '', // 핸드폰번호 에러 메시지,
      validateRule: [
          v => !!v || '고객명은 필수 입력항목입니다'
      ],
      validateRuleCelno: [
        v => !!v || '필수 입력항목입니다',
        v => v.length >= 7 || '휴대폰번호를 입력하세요.',
        v => this.chkCelnoResult || this.celnoErrorMsg
      ],
    }
  },
  computed: {
    fn_CustNm: { // 고객명
      get: function () {
        return this.custNm
      },
      set: function (data) {
        this.custNm = data ? data.trim().toUpperCase() : ''
        
        this.$refs.vWatcher.clearValidation()
      }
    },
    fn_CelnoFrno: { // 핸드폰번호 앞자리 변경시 호출 - 전체핸드폰번호변수(celno)에 변경 값 처리
      get: function () {
        return this.celnoFrno
      },
      set: function (data) {
        this.celnoFrno = data
        this.celno = data + this.celnoBkno

        this.fn_CheckCelno()
      }
    },
    fn_CelnoBkno: { // 핸드폰번호 뒷자리 변경시 호출 - 전체핸드폰번호변수(celno)에 변경 값 처리
      get: function () {
        return this.celnoBkno
      },
      set: function (data) {
        this.celnoBkno = data
        this.celno = this.celnoFrno + data

        this.fn_CheckCelno()
      }
    },
  },
  watch: {
    chkCelnoResult(val) {
      this.disabled_sendBtn = !val
    }
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_CheckCelno
    * 설명       : 핸드폰번호 유효성 체크
    ******************************************************************************/
    fn_CheckCelno () {
      this.chkCelnoResult = true
      this.celnoErrorMsg = ''
      let lv_Celno = this.celno.trim()

      if (this.celnoFrno === '0') { // 선택일 경우 무조건 에러 처리
        this.chkCelnoResult = false
        this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
      } else if (this.celnoFrno === '010' && lv_Celno.length < 11) { // 010 번호인 경우 전체 자리수는 무조건 11자리
        this.chkCelnoResult = false
        this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
      } else if (lv_Celno.length < 10) {  // 010 외에는 뒷자리가 7자리(xxx-xxxx)로 올수도 있음. 전체 자리수를 10자리를 max로 해서 체크
        this.chkCelnoResult = false
        this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
      }
    },
    /******************************************************************************
     * Function명 : fn_SearchCustomerInfo
     * 설명       : 기존고객 조회 (정보활용 동의 정보 조회 서비스 호출)
     ******************************************************************************/
    fn_SearchCustomerInfo () {
      if (!this.$refs.vWatcher.isValid()) {
        return
      }
      let lv_Vm = this
      lv_Vm.show_sendBtn = true
      lv_Vm.showResult = false

      const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
      let lv_FilterSearchCustNm = lv_Vm.custNm
      if (validOnlyEnglish(lv_FilterSearchCustNm)) {
        lv_Vm.custNm = lv_FilterSearchCustNm.toUpperCase()
      }

      let pParams =
                  {
                    cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
                    custNm: lv_Vm.custNm.trim() // 고객명
                  }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
      }
      let trnstId = 'txTSSCM10S4'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 필수 컨설팅 리스트
          if (response.body !== null) {
            if (response.body.cMInfoPrcusCnsntListVO !== null) {
              lv_Vm.items = response.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.items.length === 0) {
                // lv_Vm.getStore('confirm').dispatch('ADD', '조회하고자 하는 고객이 없습니다.')
                // lv_Vm.fn_ShowAlert()
              lv_Vm.fn_SetNewCust()
              } else if (lv_Vm.items.length > 0) {
                // 고객 조회 팝업창 띄우기
                lv_Vm.popup005 = lv_Vm.$bottomModal.open(MSPCM005P, {
                  properties: {
                    pCustNm: lv_Vm.custNm, // 고객명 파라미터
                  },
                  listeners: {
                    confirmPopup: (pData) => {
                      lv_Vm.$bottomModal.close(lv_Vm.popup005)

                      // 파라미터 세팅
                      lv_Vm.fn_ConfirmMSPCM005P(pData)                      
                    }
                  }
                })
              }
            } else {
              // lv_Vm.getStore('confirm').dispatch('ADD', '조회하고자 하는 고객이 없습니다.')
              // lv_Vm.fn_ShowAlert()
              lv_Vm.fn_SetNewCust()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_ConfirmMSPCM005P (param) {
      if (param != null) {
        if (param.isExistCustYN === 'Y') {
          this.isExistCustYN = param.isExistCustYN
          this.custNm = param.custNm
          this.realCustNm = param.custNm
          this.chnlCustId = param.chnlCustId
          this.infoPrcusMcnstYn = param.custCardVO.infoPrcusMcnstYn
          this.infoPrcusMktYn = param.custCardVO.infoPrcusMktYn
          if (param.celno && param.celno.trim()) {
            const [frno, bkno1, bkno2] = param.celno.split('-')
            this.fn_CelnoFrno = frno
            this.fn_CelnoBkno = `${bkno1}${bkno2}`
          }

          this.showResult = true
          this.show_sendBtn = false // 버튼 활성화
        } else {
          this.fn_SetNewCust()
        }
      }
    },
    fn_SetNewCust() {
      this.isExistCustYN = 'N'
      this.realCustNm = this.custNm
      this.chnlCustId = ''
      this.fn_CelnoFrno = '010'
      this.fn_CelnoBkno = ''
      this.infoPrcusMcnstYn = ''
      this.infoPrcusMktYn = ''
      this.showResult = true
      this.show_sendBtn = false // 버튼 활성화
    },
    fn_Send() {
      if (!this.fn_validateCelno()) return
      
      // 당일 동일FC가 동일고객 2회 이상 발송할 경우 안내팝업으로 중복건 안내
      this.fn_selThdayDspchYn()
    },
    fn_validateCelno(){
      if (this.celno === '01000000000' || this.celno === '0100000000' || this.celno === '01010000000' || this.celno === '01020000000' || this.celno === '01030000000') {
        this.chkCelnoResult = false
        this.celnoErrorMsg = '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
      }
      if (!this.chkCelnoResult || this.celnoErrorMsg) {
        this.$refs.celnoRef.focus()
        this.$refs.celnoRef.blur()
        return false
      }
      return true
    },
    fn_OpenPopup136 () {
      // 비대면동의 요청사항 팝업창 띄우기
      this.popup136 = this.$bottomModal.open(MSPCM136P, {
        listeners: {
          confirmPopup: async (pData) => {
            this.ltrqMthod = pData.ltrqMthod
            this.cnsntReqYmd = pData.cnsntReqYmd

            await this.$bottomModal.close(this.popup136)
            this.fn_OpenPopup134()
          },
          cancelPopup: () => {
            this.$bottomModal.close(this.popup136)
          }
        }
      })
    },
    fn_OpenPopup134 () {
      // 알림톡 안내 팝업창 띄우기
      this.popup134 = this.$bottomModal.open(MSPCM134P, {
        properties: {
          custNm: this.custNm,
          celno: this.celno
        },
        listeners: {
          confirmPopup: async (pData) => {
            this.infoPrcusCnsntTypCd = pData.infoPrcusCnsntTypCd
            await this.$bottomModal.close(this.popup134)
            
            this.fn_SendSMS()
          },
          cancelPopup: () => {
            this.$bottomModal.close(this.popup134)
          }
        }
      })
    },
    fn_OpenConfirmAlert() {
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'B',
          content: '※ 해당 비대면동의 전송 요청건은 재발송 건이니, 확인후 전송 하시기 바랍니다.',
          title_pos_btn: "확인",
          single: true,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
            setTimeout(() => {
              this.fn_OpenPopup136()
            }, 500)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_selThdayDspchYn
     * 설명       : 당일 발송 여부 조회
     ******************************************************************************/
    fn_selThdayDspchYn() {
      const lv_Vm = this // (현) Vue객체모델 인스턴스
      const trnstId = 'txTSSCM90S4'
      const auth = 'S'
      let pParams = {}

      pParams.custNm = this.custNm
      pParams.celNo = this.celno

      //매니저가 컨설턴트를 변경했을경우  
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo},
                              {stndKeyId: 'cnsltNm', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.gssEplyNm},
                              {stndKeyId: 'gssOrgNm', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.gssOrgNm}
                              )
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            if (response.body.wkRslt === '0') { // 당일 발송 이력 있는 경우
              lv_Vm.fn_OpenConfirmAlert()
            } else { // 당일 발송 이력 없는 경우
              lv_Vm.fn_OpenPopup136()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_SendSMS () {
      const lv_Vm = this // (현) Vue객체모델 인스턴스
      const trnstId = 'txTSSCM90S1'
      const auth = 'S'
      let pParams = {}

      pParams.chnlCustId = this.chnlCustId // 가눈약
      pParams.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd
      pParams.custNm = this.custNm
      pParams.celNo = this.celno
      pParams.ltrqMthod = this.ltrqMthod
      pParams.cnsntReqYmd = this.cnsntReqYmd 

      //매니저가 컨설턴트를 변경했을경우  
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo},
                              {stndKeyId: 'cnsltNm', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.gssEplyNm},
                              {stndKeyId: 'gssOrgNm', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.gssOrgNm}
                              )
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseResult = response.body
            lv_Vm.vuchId = responseResult.vuchId
            lv_Vm.$addSnackbar('비대면 고객동의 전송 완료했습니다.')
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_Back () {
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
      this.$router.go(-1)
    }
  }
}
</script>
