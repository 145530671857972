/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI822D
 * 화면설명: 선심사 특약별 결과
 * 작 성 일: 2024.02.21
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false"> 
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <!-- 총건수 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <!-- <mo-checkbox v-model="lv_ChkAll" size="small" /> -->
          <div class="list-item__contents">
            <div class="list-item__contents__title mb0">
              <span v-if="lv_selected === 0" class="sum mt0">총 <strong class="crTy-blue3">{{viewData.length}}</strong> 건</span>
              <span v-else class="sum mt0"><strong class="crTy-blue3">{{lv_selected}}</strong> 건 선택</span>
              <div class="fexTy5">
                <span class="fwn fs14rem crTy-bk7">불가</span>
                <mo-switch v-model="lv_isDalw" small class="ns-switch ml10"/>
              </div>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- // 총건수 -->
      <ur-box-container direction="column" alignV="start" class="ns-list-area"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024 mb140">
          <mo-list :list-data="viewData" ref="viewData">
            <template #list-item="{item, index}">
              <mo-list-item :class="{'on' : item.isChecked === true, 'bgcolor-ightred' : isAfterVal === true || item.error || item.calcError}">
                <mo-checkbox v-model="item.isChecked"
                             :disabled="!lv_isEdit ? true : item.disabled ? false : item.disabled"
                             @input="onInputChecked(item, item.disabled)"/>
                             <!-- :disabled="item.disabled ? false : item.disabled" -->
                <div class="list-item__contents">
                  <div class="list-item__contents__info fexTy3-1 mt4 pal0">
                    <span class="fs14rem crTy-bk1 lh20px" :class="{'crTy-orange2' : item.zaAnmsEntLmtCntnt === '불가'}">{{item.zaExptJdgRsltCntnt}}</span>
                    <mo-badge class="badge-sample-badge sm" text="" shape="status" :class="fn_SetClass(item.zaAnmsEntLmtCntnt)" v-if="fn_SetClass(item.zaAnmsEntLmtCntnt) !== ''">{{item.zaAnmsEntLmtCntnt}}</mo-badge>
                    <!-- <mo-badge class="badge-sample-badge sm" text="" shape="status" :class="{'lightgreen1' : item.zaAnmsEntLmtCntnt === '정상', 'lightred2' : item.zaAnmsEntLmtCntnt === '불가', 'lightyellow2': item.zaAnmsEntLmtCntnt === '심사필요'}">{{item.zaAnmsEntLmtCntnt}}</mo-badge> -->
                  </div>
                  <div class="list-item__contents__title mt8">
                    <span class="name flex-1 fs19rem pr10">{{item.name}}</span>
                  </div>
                  <div class="list-item__contents__info fexTy4 align-item-center pal0" v-if="item.isChecked">
                    <span v-if="item.covInsAmt !== ''">
                      <span class="crTy-bk1 fwb ml6" v-if="item.currencyId !== 'KRW'">{{ item.unitNm }}</span>
                      <span class="fwb crTy-bk1">{{item.covInsAmt === '' ? '' : $bizUtil.numberWithCommasCurr(item.covInsAmt, item.currencyId,'Y','N', 3)}}</span>
                      <span class="crTy-bk1 fwb ml6" v-if="item.currencyId === 'KRW'">{{ item.unitNm }}</span>
                    </span>
                    <template v-if="lv_isEdit && item.isEnabled_covInsAmt == true && item.isChecked && fn_chkPer(item) == true">
                      <!-- <div style="width: 30px" v-if="item.zaAnmsEntLmtCntnt === ''"><span> </span></div>
                      <mo-button class="link-only-icon ml10" v-else><mo-icon icon="msp-edit" @click="clickRow_popupCall(item, index)"/></mo-button> -->
                      <mo-button class="link-only-icon ml10"><mo-icon icon="msp-edit" @click="clickRow_popupCall(item, index)"/></mo-button>
                      <!-- <mo-button class="link-only-icon ml10" v-else><mo-icon icon="msp-edit" @click="fn_OpenChgPrcBs(idx, item)"/></mo-button> -->
                    </template>
                    <template v-else-if="!lv_isEdit || !(item.isEnabled_covInsAmt == true && item.isChecked) || item.zaAnmsEntLmtCntnt === '불가'">
                      <div style="width: 30px"><span> </span></div>
                    </template>
                  </div>
                  <!-- 개별보험료 -->
                  <div class="list-item__contents__info fexTy4 align-item-center mt4 pal0">
                    <span class="crTy-bk1 fs15rem">{{item.covPrm === '' ? '' : $bizUtil.numberWithCommasCurr(item.covPrm, item.currencyId,'Y','Y', 3)}}</span><span class="ml4 mr30 crTy-bk1 fs15rem"></span>
                    <!-- <span class="crTy-bk1 fs15rem">{{item.covPrm === '' ? '' : $bizUtil.numberWithCommasCurr(item.covPrm, item.currencyId,'Y','Y', 3)}}</span> -->
                  </div>
                  <div class="list-item__contents__info fexTy4 align-item-center mt4 pal0" v-if="+item.zaEntLmtExcdAmt > 0">
                    <mo-icon icon="msp-arrow-decrease3"/>
                    <span class="fwb crTy-orange2">{{item.zaEntLmtExcdAmt === '' ? '' : $bizUtil.numberWithCommasCurr(item.zaEntLmtExcdAmt, item.currencyId,'Y','N', 3)}}</span>
                    <span class="ml4 fwb crTy-orange2">{{ item.unitNm }} 초과</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <!-- Error Guide -->
        <ur-box-container alignV="start" componentid="" direction="column"  v-show="isGuideBottomShow">
          <div class="bottom-sheet-ty--1 ty--2 bgcolor-ightred pt20 pr24 pl24">
            <div class="content-area mhAuto pb0 bgcolor-ightred">  
              <msp-expand btn-area-first expand-only-btn expanded class="mo-list-expand" btn-area-class="firstdiv-full pb4"  > 
                <template #btn>
                  <div class="full fexTy3-1 crTy-orange2">            
                    <div class="fexTy1">        
                      <mo-icon class="mr5">warning</mo-icon><b class="txtSkip fs19rem">설계가이드({{isErrorIdx+1}}/{{errorListCnt}})</b>
                    </div>
                    <mo-button class="link-arrow up"></mo-button>
                  </div>          
                </template>
                <template #content>
                  <div class="content-area">
                    <p class="ment fs17rem">
                      {{ErrorMsg}}
                    </p>
                  </div>              
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs bgcolor-ightred">
                    <div v-if="errorListCnt > 1 && isErrorIdx === 0" class="relative-div">
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_ErrNext">다음</mo-button>
                    </div>
                    <div v-if="errorListCnt > 1 && isErrorIdx > 0 && isErrorIdx < (errorListCnt-1)" class="relative-div">
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_ErrBefore">이전</mo-button>
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_ErrNext">다음</mo-button>
                    </div>
                    <div v-if="errorListCnt > 1  && isErrorIdx > 0 && isErrorIdx >= (errorListCnt-1)" class="relative-div">
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_ErrBefore">이전</mo-button>
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_premCalc2">보험료계산</mo-button>              
                      <!-- <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_premCalc2">보험료계산</mo-button>               -->
                    </div> 
                    <div v-if="errorListCnt === 1" class="relative-div">
                      <!-- <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_premCalc2">보험료계산</mo-button> -->
                      <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_premCalc2">보험료계산</mo-button>
                    </div>  
                  </ur-box-container>
                </template>
              </msp-expand>
            </div>
          </div>
        </ur-box-container>
      </ur-box-container>

      <!-- Popup: 가입금액변경 -->
      <mo-bottom-sheet ref="refChgPrc" preventTouchClose class="ns-bottom-sheet closebtn bs-thin-title dim-hidden">
        <template v-slot:title>
        </template>
        <div class="ns-btn-close" @click="fn_CloseChgPrcBs"></div> 

        <!-- content 영역 -->
        <div class="content-area pb10" v-if="lv_CalcData !== null">
          <div class="fex-clumn leading-tight">
            <template v-if="lv_CalcData.zaAnmsEntLmtCntnt !== ''">
              <span class="mb4"><mo-badge class="badge-sample-badge sm" :class="fn_SetClass(lv_CalcData.zaAnmsEntLmtCntnt)" text="" shape="status">{{lv_CalcData.zaAnmsEntLmtCntnt}}</mo-badge></span>                
            </template>
            <span class="fwb fs15rem">{{lv_CalcData.name}}</span>
            <span class="mt10 fs14rem">{{lv_CalcData.padCovRuleText}}</span>
          </div>
          <div class="ns-certify-sed-wrap gap--00 fexTy-half20 mt20">
            <div class="ns-certify-sed pal0">
              <span class="label-title">가입금액<span class="crTy-bk7">(변경 전)</span></span>
              <div class="row-text full mt8">
                <span class="fs17rem">{{lv_CalcData.prevCovInsAmt === '' ? '0' : $bizUtil.numberWithCommasCurr(lv_CalcData.prevCovInsAmt, lv_CalcData.currencyId,'Y','N', 3)}}</span>
                <!-- <span class="fs17rem">{{lv_CalcData.prevCovInsAmt}}</span> -->
                <span class="right-text mtm8">{{lv_CalcData.unitNm}}</span>
              </div> 
              <div class="full fexTy6 mt10 fs14rem" v-if="lv_CalcData.zaEntLmtExcdAmt !== '' && lv_CalcData.zaEntLmtExcdAmt !== '0'">
                <mo-icon icon="msp-arrow-decrease3"/>
                <span class="fwb crTy-orange2">{{$bizUtil.numberWithCommasCurr(lv_CalcData.zaEntLmtExcdAmt, lv_CalcData.currencyId,'Y','N', 3)}}</span>
                <span class="ml4 fwb crTy-orange2">{{lv_CalcData.unitNm}} 초과</span>
              </div> 
            </div>
            <div class="ns-certify-sed pal0" :class="lv_isError ? 'error' : ''">
              <span class="label-title must">가입금액<span class="crTy-bk7">(변경 후)</span></span>
              <div class="row-text full">
                <!-- <mo-decimal-field numeric class="form-input-name full" v-model="LocalcovInsAmt" underline :clearable="chkZzentPTCLVl_TF" maxlength=12 placeholder="금액을 입력해주세요."  @click="fn_LocalcovInsAmt_scroll()" readonly :selected="isSelectLocalcovInsAmt"/>  -->
              
                <mo-decimal-field numeric class="form-input-name full clear-with-rTxt" maxlength="12" :clearable="chkZzentPTCLVl_TF" v-model="lv_CalcData.covInsAmt" underline placeholder="금액입력" />
                <!-- <mo-text-field class="form-input-name full clear-with-rTxt" v-model="lv_CalcData.covInsAmt" underline clearable placeholder="금액입력" /> -->
                <span class="right-text">{{lv_CalcData.unitNm}}</span>
              </div>
              <!-- // 에러시 노출 -->
              <div class="ns-certify-sed-txt" v-if="lv_isError">
                <div class="txt-error">변경 후 금액을 입력하세요</div>
              </div>
              <!-- 개별보험료 -->
              <span class="crTy-blue3 fs13rem" v-if="lv_CalcData.covPrm !== ''">개별보험료 {{lv_CalcData.covPrm === '' ? '' : $bizUtil.numberWithCommasCurr(lv_CalcData.covPrm, lv_CalcData.currencyId,'Y','Y', 3)}}</span>
            </div>
          </div>
          
          <ur-box-container alignV="start" componentid="" direction="column" class="keypad-number-area pl0 pr0 pb0">
            <div class="relative-div">
              <mo-button class="ns-btn-round fs14" @click="fn_addNum(idx)" v-for="(item,idx) in addList" :key="idx">{{item.tit}}</mo-button>
            </div>          
            <div class="btn-keypad-area">
              <mo-button class="ns-btn-none" @click="fn_addStr(n)" v-for="n in 9" :key=n>{{n}}</mo-button>
              <mo-button class="ns-btn-none" @click="fn_addStr('0')">0</mo-button>
              <mo-button class="ns-btn-none" @click="fn_addStr('00')">00</mo-button>
              <mo-button class="ns-btn-none btn-arrow-line" @click="fn_delStr"><mo-icon icon="msp-arrow-line" /></mo-button>
            </div>  
          </ur-box-container>    
        </div>
        <template v-slot:action>
          <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button color="normal" shape="border" size="medium" @click="fn_Save()" class="ns-btn-round blue">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- // Popup: 가입금액변경 -->
    </ur-box-container>

    <!-- 컨텐츠영역  //-->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
// import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
// import Msg from '@/systems/webkit/msg/msg'
import Gaf from '@/ui/pi/common/Gaf.js'
import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import moment from 'moment'
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI822D', 
  screenId: 'MSPPI822D', 
  components: {    
  },//componets

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    // slctTabIdx : {type:Number, default:1}, // 부모 선택한 현재 탭 인덱스
    planId: String, // 설계ID
    dsclsId: String, // 고지ID
    nundwAplInfo: Object,
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      name: 'MSPPI822D',
      lv_planId: '',
      lv_dsclsId: '',
      // 키패드 바텀시트 관련
      addList: [
        {'tit': '+100', 'val': 100},
        {'tit': '+500', 'val': 500},
        {'tit': '+1,000', 'val': 1000},
        {'tit': '+5,000', 'val': 5000}
      ],
      smtotPrmTxt: '합계 보험료',
      currencyKrwTxt: '', // 통화코드 KRW
      currencyUsdTxt: '', // 통화코드 USD
      // 특약관련 ================================
      unique_productId: [],
      prdtPosSellList: [],
      rrnMaskingFlag: true,
      applnrTt: '',
      viewData: [], // 특약 리스트
      viewData2: [], // 불가건 해제를 위해 복사한 특약 리스트
      selected: [],
      selected2: [], // 불가건 해제를 위해 복사한 특약 리스트
      isError: false,
      clauseInfo: null,
      tabItems: [],
      items: {
        type: Array
      },
      covAutoChecked: false,
      covFiltered: false,
      errorList: [], // 설계가이드 메시지 목록
      // [NEXT 사랑On] 전산심사 한도체크 가이드
      isLimitOver: false,
      isAfterVal: false,    // 보험료계산후, 전산심사후
      isErrorIdx: 0,
      errorListCnt: 0,
      ErrorMsg: '',    // 오류메시지 내용

      // 특약 그룹핑
      tabItemsSelected: [], // 그룹핑 선택

      isGuideBottomShow: false,

      zaExptRsltColor: '', // 심사결과 컬러
      lv_isChgPlanFlag: true, // 설계변경가능 여부
      isCalcVal: false,    // 보험료계산후
      lv_isDalw: false, // 불가 switch

      //=======================================
      // 보험료계산 관련
      lv_CalcData: null, // 보험료입력 바텀시트에서 사용할 데이터
      lv_OriginData: null,
      chkZzentPTCLVl_TF: true,
      //=======================================
      // 합계보험료 관련
      isOpen: false,
      is1001_29: false,
      zaBrthPntmCnvlPrfmAmt: '', // 출생예정일기준 환산성적(TP)
      prevZaBrthPntmCnvlPrfmAmt: '', // 이전 출생예정일기준 환산성적(TP)
      zzcnvlPrfmAm: '', // 월납환산보험료
      prevZzcnvlPrfmAm: '', // 이전 월납환산보험료
      smtotPrm: '', // 합계보험료(=세후보험료)
      prevSmtotPrm: '', // 이전 합계보험료
      smtotPrmKrw: '', // 원화환산 보험료
      prevSmtotPrmKrw: '', // 이전 원화환산 보험료
      rlpadPrm: '', // 실납입보험료
      prevRlpadPrm: '', // 이전 실납입보험료
      smtotPrmTxt: '합계 보험료',
      rlpadPrmTxt: '실납입 보험료',
      smtotPrmTxtKrw: '원화환산 보험료',
      todayExchangeRate: '금일 환율',
      currencyId: '', // 통화코드
      isUsd: false, // 통화코드 USD 여부
      currencyKrwTxt: '', // 통화코드 KRW
      currencyUsdTxt: '', // 통화코드 USD
      exrtRate: '', // Gaf 변동 환율
      tpNm: '환산성적(TP)',
      tpNm2: '출생예정일기준TP',
      smtotPrmData: {}, // 선심사결과 메인 바텀에 노출할 데이터
      // ===================================================
      lv_nundwAplInfo: {},
      lv_isEdit: true,
    }
  },//data
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    Gaf.addPanelList(this)
    // MSPPI080D에서 추가한 정보
    // IFBC 속성 선택정보 추가
    //IFBC 속성 선택정보 추가
    // Gaf.addAttrNames(["ZZPRCD_D_ID","ZZPTCLR_PRD_T_ID","ZZJDG_OBJ_P_T_ID","ZZENPRS_SC_ID"], "POLPR") //POLPR RD
    Gaf.addCharNames(['CLCRL_ID', 'COVCPTYPE_CD', 'PREM_CD', 'ZZCONTV_INSRD_CD', 'ZZINSUV_INSRD_CD', 'ZZRENW_VCD_CD', 'INCREASEVAR_CD', 'INFRD_CD', 'INFRCT_CD', 'PAYFRQ_CD']) // Characteristics
    Gaf.addAttrNames(['MAIN_INSURANCE', 'ZZPDINS_R_C_C_ID', 'ZZDCA_APL_P_S_ID', 'ZZRENW_CYL_ID', 'ZZENT_ANTPYMT_CD', 'ZZPDINS_R_C_C_ID'], 'COV') // COV RD
    // Gaf.setCompareEnable(true) //설계 비교모드 true
  },//created

  mounted () {
  },//mounted

  beforeDestroy () {},//beforeDestroy

  destroyed () {
    Gaf.deletePanel(this.name)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
  },//destroyed
  
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    planId: { // 설계ID
      deep: true,
      immediate: true,
      handler (item) {
        console.log('watch planId ===> ', item)
        this.lv_planId = item
      }
    },
    dsclsId: { // 고지ID
      deep: true,
      immediate: true,
      handler (item) {
        console.log('watch dsclsId ===> ', item)
        this.lv_dsclsId = item
      }
    },
    'nundwAplInfo': {
      deep: true,
      immediate: true,
      handler(item) {
        if (!_.isEmpty(item)) {
          this.lv_nundwAplInfo = item
        }
      }
    },
    lv_isDalw (item) {
      console.log('lv_isDalw ===> ', item)
      let dalwArr = this.viewData.filter(_item => _item.zaAnmsEntLmtCntnt === '불가')
      if (item) {
        if (!piCommonUtil.isEmpty2(dalwArr) && dalwArr?.length > 0) {
          // this.viewData2 = this.$bizUtil.cloneDeep(this.viewData)
          this.viewData2 = this.viewData
          this.viewData = dalwArr
          this.selected2 = dalwArr
        } else {
          this.lv_isDalw = false
        }
        console.log('isDalw : ', item, ' / viewData ===> ', this.viewData)
      } else {
        if (!piCommonUtil.isEmpty2(dalwArr) && dalwArr?.length > 0) {
          this.viewData = this.viewData2
          this.selected = this.viewData.filter(_item => _item.isChecked === true)
        }
        console.log('isDalw : ', item, ' / viewData ===> ', this.viewData)
      }
    },
  },//watch
  
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    // lv_isEdit () {
    //   // if (!_.isEmpty(this.lv_nundwAplInfo) && Object.keys(this.lv_nundwAplInfo).length > 0) {
    //   //   if (this.lv_nundwAplInfo.nundwExpdEndYn === 'Y' || this.lv_nundwAplInfo.ofrfmPblYn === 'Y' ||
    //   //       ['10', '70', '20', '40'].includes(this.lv_nundwAplInfo.nundwPrgStatCd) ||
    //   //       (this.lv_nundwAplInfo.cundwYmd !== piCommonUtil.getCurrentDate() && ['30','50','90'].includes(this.lv_nundwAplInfo.nundwPrgStatCd))) {
    //   //     return false
    //   //   }
    //   // }
    //   if (Gaf.getPanel('MSPPI820P').lv_nundwExpdEndYn === 'Y' || Gaf.getPanel('MSPPI820P').lv_OfrfmPblYn === 'Y' ||
    //       ['10', '70', '20', '40'].includes(Gaf.getPanel('MSPPI820P').lv_nundwPrgStatCd) ||
    //       (Gaf.getPanel('MSPPI820P').lv_nundwAplInfo.cundwYmd !== piCommonUtil.getCurrentDate() && ['30','50','90'].includes(Gaf.getPanel('MSPPI820P').lv_nundwAplInfo.nundwPrgStatCd))) {
    //     return false
    //   }
    //   return true
    // },
    lv_selected () {
      return this.lv_isDalw ? this.selected2.filter(item => item.isChecked === true)?.length : this.selected.length
      // return this.viewData.filter(item => item.isChecked)?.length
    },
    lv_isError () {
      return !piCommonUtil.isEmpty2(this.lv_CalcData.covInsAmt) && +this.lv_CalcData.covInsAmt > 0 ? false : true
    }
  },//computed
  
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_HistoryBack
    * 설명        : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack() {
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 데이터 초기화 함수
    ******************************************************************************/
    fn_Init () {
      this.isCalcVal = false
      this.lv_isDalw = false
      this.lv_CalcData = null
    },
    /******************************************************************************
    * Function명 : fn_SetClass
    * 설명       : 클래스 리턴해주는 함수
    ******************************************************************************/
    fn_SetClass (item) {
      switch (item) {
        case '정상':
          return 'lightgreen1'
        case '불가':
          return 'lightred2'
        case '심사필요':
          return 'lightyellow2'
        default:
          return ''
      }
    },
    /******************************************************************************
    * Function명 : fn_SelProductList
    * 설명       : 판매가능상품목록검색 (ASR200901016 / [개선과제] 채널별 상품카테고리 출력-사랑On; URLPQL08166 >> URLPQL00414)
    ******************************************************************************/
    fn_SelProductList () {
      // 선심사 재조회 시 판매가능상품 목록은 존재하여 조회 스킵하고 설계조회로 넘긴다.
      if (this.prdtPosSellList.length > 0) {
        window.vue.getStore('progress').dispatch('SHOW')
        this.fn_SelPlanInfo()
        return
      }
      window.vue.getStore('progress').dispatch('SHOW')
      let inputJson = {
          reprPrdtPgScCd: '01',
          stndYmd: '',
          tbtPlanYn: 'Y',
          orgNo01: this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo,
          orgNo02: this.getStore('userInfo').getters.getUserInfo.onpstDofNo,
          orgNo03: this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
      }
      // ASR200901016 / [개선과제] 채널별 상품카테고리 출력-사랑On; URLPQL08166 >> URLPQL00414
      piCommonUtil.invoke('URLPQL00414', inputJson, this.URLPQL00414_LocalCallBack, null, this.URLPQL00414_LocalCallBack, this, this)
    },
    /******************************************************************************
    * Function명 : URLPQL00414_LocalCallBack (ASR200901016 / [개선과제] 채널별 상품카테고리 출력-사랑On; URLPQL08166 >> URLPQL00414)
    * 설명       : URLPQL00414_LocalCallBack
    ******************************************************************************/
    URLPQL00414_LocalCallBack (result) { // ASR200901016 / [개선과제] 채널별 상품카테고리 출력-사랑On; URLPQL08166 >> URLPQL00414
 
      if (result === null || result === undefined || !piCommonUtil.isObj(result.pisalePrdtInfoVO)) {
        // this.getStore('confirm').dispatch('ADD', '판매상품목록 조회결과가 없습니다.')
        // piCommonUtil.getFDPConfirm('s', '판매가능상품목록 조회결과가 없습니다.')
        //window.vue.getStore('progress').dispatch('UPDATE', false)
        console.log('URLPQL00414-판매가능상품목록 조회실패 !!!!!!!!')
        return false
      } else {
        console.log('판매가능상품목록 조회성공 !!!!!')
        this.prdtPosSellList = result.pisalePrdtInfoVO
        //console.log("URLPQL00414 prdtPosSellList: ", this.prdtPosSellList)

        // 판매가능상품목록 앞에 카테고리명 정보를 추가해주기 위해, 카테고리, 판매가능상품목록 리스트를 비교하여 해당 정보를 완성해준다.
        // this.prdtPosSellList.forEach((prdt, index) => {
        //   this.prdtCtgryList.forEach((category, index) => {
        //     if ( category.data === prdt.entplPrdtClsfCd) {
        //       prdt.categoryName = category.label
        //     }
        //   })
        // })
        this.unique_productId = []
        this.prdtPosSellList.forEach((el, index) => {
          el.zaExptRslt = '가입가능'
          el.zaExptRsltSort = 1  //가입가능 토글ON 이면 최상단 노출
          let inArr = this.unique_productId.some(function (el_u) {
            return el_u.prdtNm === el.prdtNm
          })

          if (!inArr) {
            this.unique_productId.push(el)
          }
        })
        console.log('판매가능상품목록 조회건수 : ' , this.prdtPosSellList.length)
 
        // Gaf에 판매가능상품목록을 저장해준다.
        Gaf.setProductList(this.prdtPosSellList)
        if (this.prdtPosSellList.length > 0) {
          this.fn_SelPlanInfo ()
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_SelPlanInfo
     * 설명       : 표준설계조회
     * [원본] MSPPI002D
     ******************************************************************************/
    fn_SelPlanInfo () {
      console.log('fn_SelPlanInfo START ====================')
      let inputJson = { 'pmodel': {'abdapolicy': [{'gafProcessNo': Gaf.getProcessNo()}]},
        'tmodel': {
          'searchCriteria': {
            zaPlanId: this.lv_planId,
            applnrTt: this.applnrTt,
            rrnMaskingFlag: this.rrnMaskingFlag ? 'Y' : 'N'
          }
        }
      }
      // 22.03 ASR220300038 / 건강증진형프리미엄(2204) 최소가입금액 한도체크 / young21.kwon
      // ASR220300426 / 건강증진프리미엄건강보험 최소가입금액 체크 반영 20220405 youngki78.lee
      let dataUrlId = 'URLPQL00073'
      // if (this.fn_GetZPIRealMinIsamProdCondList()) {
        let tmp = this.fn_GetZPIRealMinIsamProdCondList(dataUrlId)
        if (tmp !== null) {
          inputJson.tmodel.searchCriteria['zPIRealMinIsamProdCondList'] = tmp['zPIRealMinIsamProdCondList']
        }
      // }
      console.log('URLPQL00073 inputJson ===> ', inputJson)
      Gaf.execute('MSPPI820P', dataUrlId, inputJson, true, null, this)
      // Gaf.execute('MSPPI822D', dataUrlId, inputJson, true, null, this)
    },
    /******************************************************************************
    * Function명 : URLPQL00073_LocalCallBack
    * 설명       : 요청한 설계 정보 처리
    *              [원본] MSPPI002D, MSPPI080D
    ******************************************************************************/
    URLPQL00073_LocalCallBack (result) {
      console.log('URLPQL00073_LocalCallBack ====> ', result)
      // TEST ==========
      // 선심사결과 조회
      // this.$emit('selResult')
      // =======================================================
      // MSPPI002D의 URLPQL00073_LocalCallBack
      // =======================================================
      // if (piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto'))) {
      let tmp = result.pmodel
      let abdapolicy = tmp.abdapolicy[0]
      let abdapolpr = abdapolicy.abdapolpr[0]
      // ABDAPOLPR 정보
      Gaf.d('설계번호 :: ' + abdapolicy.zaPlanId)
      // 설계번호 셋팅
      console.log(abdapolpr.ztpqlCboextPrcsAbdapolpr, ': abdapolpr.ztpqlCboextPrcsAbdapolpr')
      
      // [선심사 및 영업시스템 고도화PJT] Gaf에 pmodel abdapolicy 복제하기 보험료 전/후 및 초기화를 위해서
      let copyAbdapolicy = GafMainFunc.copyJson(abdapolicy)
      Gaf.setPModel('abdapolicy', [copyAbdapolicy, copyAbdapolicy])
      this.lv_OriginData = this.$bizUtil.cloneDeep(copyAbdapolicy)
      // console.log('abdapolicy 후 pmodel', Gaf.getObj('pmodel'))
      // ==============================================

      // 상품명 셋팅 - 20160530 yherp.kwon : 상품명 셋팅 부분 toVmodel로 이동.
      // this.l00105_sec20_input20.setValue(Gaf.getTObj("prodInfo").prodStructList[0].productId);
      /** 20170215 yherp.kwon : 설계조회 tmodel 객체복사-동일 객체 참조되어 기본계약사항 그린후, result.tmodel 결과도 바뀌므로 복사해줌 */
      let copyTmodel = GafMainFunc.copyJson(result.tmodel)

      /** 20160524 yherp.kwon : 설계조회후 기본계약사항 그리기 */
      // [TO-DO] 데이터 확인 후 구현 여부 결정하기..
      // this.URLPQL08312_LocalCallBack(result)

      /** 20170215 yherp.kwon : 기본계약사항 그려지면서 tmodel.baseContDto 내용이 재구성 되므로, 설계조회 tmodel을 다시 setting해줌 */
      Gaf.setObj(copyTmodel)

      /* 20160608 yherp.kwon : 순서변경에 의해 UI 셋팅 부분 추가 */
      // [선심사PJT] 선심사에서는 고객선택, 상품선택정보 필요하지 않아서 주석처리
      // this.toVmodel()

      /** 20170711 실손의료비보장보험 설계조회 블러킹 */
      let prodInfo = Gaf.getTObj('prodInfo')
      if (piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) && Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('18') > -1) {
        prodInfo.coverageList = []
        return false
      }

      /** 20180125 어린이종신전환 설계조회 블러킹 */
      if (piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) && Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('19') > -1) {
        prodInfo.coverageList = []
        return false
      }

      /** 20180319 실손(어린이종신전환) 설계조회 블러킹 */
      if (piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) && Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('21') > -1) {
        prodInfo.coverageList = []
        return false
      }
      Gaf.d('MSPPI822D 패널 URLPQL00073_LocalCallBack End !!!!')
      console.log("MSPPI822D 페이지 URLPQL00073_LocalCallBack getPlanSearchFlag :", Gaf.getPlanSearchFlag())
      console.log('MSPPI822D 페이지 URLPQL00073_LocalCallBack End!!!')

      // =======================================================
      // MSPPI080D의 URLPQL00073_LocalCallBack
      // =======================================================
      if (piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto'))) {
        Gaf.setVModel('ZRldmContCnvrDto', {})
        // delete Gaf.getVObj('ZRldmContCnvrDto')
        let vmodel = Gaf.getObj('vmodel')
        delete vmodel.ZRldmContCnvrDto
      }
      // 보험 그리드 관련 초기화 function
      this.prcsProductInfoCallback(this)
      this.$nextTick(() => {
        // 보험 조회 결과 셋팅
        this.toVmodel(this, 'URLPQL00073')
        // 합계보험료
        this.toVmodel2()
        // 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
        this.fn_exceptionCheck()

        if (!_.isEmpty(Gaf.getPanel('MSPPI820P').lv_NextFn)) {
          Gaf.getPanel('MSPPI820P').fn_OpenMSPPI250P()
        } else {
          Gaf.getPanel('MSPPI820P').fn_SetMessege()
          // Gaf.getPanel('MSPPI820P').fn_DataLoadComplete()
        }
        // console.log('URLPQL00073_LocalCallBack items ====> ', JSON.stringify(this.items))
        // console.log('URLPQL00073_LocalCallBack viewData ====> ', JSON.stringify(this.viewData))
      })
      //   // this.prcsProductInfoCallback(this)
      //   // this.$nextTick(() => {
      //   //   // // [NEXT 사랑On] 원샷보험료 조회
      //   //   // 보험 조회 결과 셋팅
      //   //   this.toVmodel(this, 'URLPQL00073')

      //   //   // 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
      //   //   this.fn_exceptionCheck()
      //   // })
      return true
    },
    /******************************************************************************
    * Function명 : URLPQL00084_LocalCallBack
    * 설명       : 보험료계산 콜백
    *              [원본] MSPPI080D
    ******************************************************************************/
    URLPQL00084_LocalCallBack (result) {
      Gaf.d('MSPPI822D URLPQL00084_LocalCallBack Start !!!!')
      // 키패드 닫기 - 결함ID : DEF_833 보험료계산후 키패드 원복요청 by 김민지
      this.isPadShow = false
      // 보험료계산시 전산심사결과가 비정상이면 리턴
      if (!this.getCundwRslt()) {
        this.isCalcVal = false
        return
      }

      this.isCalcVal = true

      // [선심사 및 영업시스템 고도화PJT] 
      let tmp = result.pmodel
      let abdapolicy = tmp.abdapolicy[0]
      let abdapolpr = abdapolicy.abdapolpr[0]

      let abdapolpr1 = this.lv_OriginData.abdapolpr[0]
      let aldapolpr1 = abdapolpr1.aldapolpr
      if (!piCommonUtil.isEmpty2(aldapolpr1.zzentplHlthsCd)) {
        if (abdapolpr.aldapolpr.zzentplHlthsCd !== aldapolpr1.zzentplHlthsCd)
        aldapolpr1.zzentplHlthsCd = abdapolpr.aldapolpr.zzentplHlthsCd
      }
      // [선심사 및 영업시스템 고도화PJT] Gaf에 pmodel abdapolicy 복제하기 보험료 전/후 및 초기화를 위해서
      let copyAbdapolicy = GafMainFunc.copyJson(abdapolicy)
      Gaf.setPModel('abdapolicy', [copyAbdapolicy, this.lv_OriginData])
      console.log('보험료계산 결과 abdapolicy ===> ', Gaf.getObj('pmodel'))
      // this.lv_OriginData = this.$bizUtil.cloneDeep(copyAbdapolicy)

      if (this.readOnlyMode === true) {
        // this.toVmodelForReadOnly(this, 'URLPQL00084')

        // 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
        this.fn_exceptionCheck()        
      } else {
        // 보험 그리드 관련 초기화 function
        this.prcsProductInfoCallback(this)
        this.$nextTick(() => {
          // 보험료계산 결과 셋팅
          this.toVmodel(this, 'URLPQL00084')
          // 합계보험료
          this.toVmodel2()
          // 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
          this.fn_exceptionCheck()
        })
      }

      Gaf.d('MSPPI822D URLPQL00084_LocalCallBack End   !!!!')
    },
    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화 함수
    *              보험료계산, 저장 전 초기화 버튼을 통해 변경 후 보험료 초기화를 위한 로직
    ******************************************************************************/
    fn_Reset () {
      let tmp = Gaf.getObj('pmodel')
      let abdapolicy = tmp.abdapolicy[1]
      let abdapolpr = abdapolicy.abdapolpr[0]
      // ABDAPOLPR 정보
      Gaf.d('설계번호 :: ' + abdapolicy.zaPlanId)
      // 설계번호 셋팅
      console.log(abdapolpr.ztpqlCboextPrcsAbdapolpr, ': abdapolpr.ztpqlCboextPrcsAbdapolpr')
      // console.log(abdapolpr.abdapolpr.zzentplHlthsCd, ': abdapolpr.zzentplHlthsCd')
      
      // [선심사 및 영업시스템 고도화PJT] Gaf에 pmodel abdapolicy 복제하기 보험료 전/후 및 초기화를 위해서
      let copyAbdapolicy = GafMainFunc.copyJson(abdapolicy)
      Gaf.setPModel('abdapolicy', [copyAbdapolicy, copyAbdapolicy])
      console.log('abdapolicy 후 pmodel', Gaf.getObj('pmodel'))
      
      // 보험료 계산 상태 초기화
      this.isCalcVal = false
      // 보험 그리드 관련 초기화 function
      this.prcsProductInfoCallback(this)
      this.$nextTick(() => {
        // 보험 조회 결과 셋팅
        this.toVmodel(this, 'URLPQL00073')
        // 합계보험료
        this.toVmodel2()
        // 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
        this.fn_exceptionCheck()

        console.log('fn_Reset items ====> ', JSON.stringify(this.items))
        console.log('fn_Reset viewData ====> ', JSON.stringify(this.viewData))
      })
    },
    /******************************************************************************
    * Function명 : getCundwRslt
    * 설명       : 전산심사결과 정상여부 체크
    *             [원본] MSPPI080D
    ******************************************************************************/
    getCundwRslt () {
      let cundwRsult = Gaf.getTObj('systmUndwRslt')

      // 전산심사결과가 없거나, zaLstNrmYn 값이 Y 일때 true
      if (!cundwRsult || cundwRsult.zaLstNrmYn === 'Y') {
        return true
      }
      return false
    },
    /******************************************************************************
    * Function명 : fnDispErrList
    * 설명       : 보험료계산후 전산심사결과가 비정상 일때, errorList 표시
    * [NEXT 사랑On] 전산심사 한도체크 가이드 표시 로직 추가 isLimitOver = true 일때.
    *             [원본] MSPPI080D
    ******************************************************************************/
    fnDispErrList (isLimitOver) {
      Gaf.d('MSPPI822D fnDispErrList Start !!!!')
      console.log('[MSPPI822D] fnDispErrList Start !!!!')

      // errorList Clear
      this.clearErrList(true)
      if (isLimitOver) {
        // 전산심사 한도체크 가이드 정보 세팅


        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // // 동일한 오류메시지 건에 특약건이 2개 이상인 경우 각각 오류건으로 분리하는 로직 _ 시작  ===> 현업요청으로 미사용(20230511)
        // let tmpErrorList = []
        // tmpErrorList = Gaf.getTObj('zCundwRsltEntplGuide').entplPblDalwList

        // for (let i = 0; i < tmpErrorList.length; i++){
        //   if (tmpErrorList[i].inscdList.length > 1){
        //     // 가이드 오류 List코드가 2건 이상인 경우 개별건으로 다시 생성한다.
        //     for (let j = 0; j < tmpErrorList[i].inscdList.length; j++){
        //       let tmpinscdList = []
        //       tmpinscdList.push(tmpErrorList[i].inscdList[j])
        //       this.errorList.push({'inscdList': tmpinscdList, 'pblDalwRsn': tmpErrorList[i].pblDalwRsn})
        //     }
        //   } else {
        //     this.errorList.push(tmpErrorList[i])
        //   }
        // }
        // // 동일한 오류메시지 건에 특약건이 2개 이상인 경우 각각 오류건으로 분리하는 로직 _ 종료  ===> 현업요청으로 미사용(20230511)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        this.errorList = Gaf.getTObj('zCundwRsltEntplGuide').entplPblDalwList
        this.isLimitOver = isLimitOver
      } else {
        let cundwRsult = Gaf.getTObj('systmUndwRslt')
        if (piCommonUtil.isObj(cundwRsult.systmUndwRsltDtl) && cundwRsult.systmUndwRsltDtl.length > 0) {
          this.errorList = cundwRsult.systmUndwRsltDtl.filter(function (item) { return item.zaNrmYn !== 'Y' })
        }
      }
      this.isError = true
      this.isOpen = true
      this.$nextTick(() => {

        console.log("[MSPPI822D] fn_isError 호출...")
        // 오류메시지 발생시 호출
        this.fn_isError()         
      })

      Gaf.d('MSPPI822D fnDispErrList End   !!!!')

    },
    /******************************************************************************
    * Function명 : fn_isError
    * 설명       : 오류메시지 발생시
    ******************************************************************************/
    fn_isError(){
      // 오류메시지 발생시만 호출
      if(this.isError != true) {
        this.isGuideBottomShow = false
        return
      }
      this.isGuideBottomShow = true
      Gaf.getPanel('MSPPI820P').lv_isError = this.isError
      // this.$emit('isGuideBottomShow', this.isGuideBottomShow)
      // this.$emit('isLimitOver', this.isLimitOver)
      
      // this.$emit('errorList', this.errorList)
      this.fn_Error()
      console.log("[fn_isError] fn_isError 호출 성공...")

    },
    /******************************************************************************
     * Function명 : fn_GetZPIRealMinIsamProdCondList
     * 설명       :
     * 주피-주보험에 대해서만 추가 가능.
     * 주보험이 아닌경우 관계규칙( F1 가입배수 D1 보기동일 D2 납기동일 관계규칙등에 오류 발생 가능, calMin 값 계산등이 잘못될 수 있슴.
     * 22.03 ASR220300038 / 건강증진형프리미엄(2204) 최소가입금액 한도체크 / young21.kwon
     * ASR220300426 / 건강증진프리미엄건강보험 최소가입금액 체크 반영 20220405 youngki78.lee
     * [원본] MSPPI001M
     ******************************************************************************/
    fn_GetZPIRealMinIsamProdCondList (dataUrlId) {
      let zPIRealMinIsamProdCondList = []
      zPIRealMinIsamProdCondList.push({'zaPrcd': 'LP0660XXX', 'zaInscd': 'LA0660XXX', 'zzcontvInsrdCd': '21'}) // 건강증진형프리미엄

      if (dataUrlId === 'URLPQL00073') {
        // 불러오기시에는 대상 상품,보험 리스트만 전달 => 202 서비스에서 보기납기 구성후 실최소가입금액조회 서비스 호출
        return {'zPIRealMinIsamProdCondList': zPIRealMinIsamProdCondList}
      }

      // Check 1
      let _zaPrcd = GafMainFunc.getIfbcValue('ZZPRCD_D_ID')
      zPIRealMinIsamProdCondList = zPIRealMinIsamProdCondList.filter(function ( item ) { return item.zaPrcd.substring(0, 6) === _zaPrcd.substring(0, 6) }) // 실최소가입금액 체크 대상 상품인지 추출
      
      // 현재 화면의 상품이 실최소가입금액 체크 대상이 아닌경우 null return
      if (!piCommonUtil.isObj(zPIRealMinIsamProdCondList) || zPIRealMinIsamProdCondList.length === 0) {
        return null
      }

      // Check 2
      // minRule 해석 대상 COVLIST 구성
      let gafProdInfo = Gaf.getTObj('prodInfo')
      let zzentplLineCd = Gaf.getTObj('baseContDto').zzentplLineCd
      
      let _coverageList = []
      // 선심사 추가 조건 아래 데이터 없는 경우 유효성 체크 오류나기 때문에 추가
      if (_.isEmpty(gafProdInfo) || _.isEmpty(zzentplLineCd)) {
        console.log('gafProdInfo/zzentplLineCd null ===> ', gafProdInfo, zzentplLineCd)
        return null
      }
      zPIRealMinIsamProdCondList.forEach((el, i) => {
        gafProdInfo.coverageList.forEach((cov, j) => {
          // minRule이 존재하는지
          if (!piCommonUtil.isEmpty2(cov.minRule)) {
            // 보험종류, 관계자유형, 보험코드가 실최소가입금액 체크 대상과 같은 특약만 추출
            if (cov['type'] === zzentplLineCd &&
            cov['insured'] === el.zzcontvInsrdCd &&
            cov['zzcoltrCd'].substring(0, 6) === el.zaInscd.substring(0, 6)) {
              _coverageList.push(cov)
            }
          }
        })
      })

      // 실최소가입금액 체크 대상 특약이 없는 경우 null 리턴
      if (_coverageList.length === 0) {
        return null
      }
      // 실최소가입금액 체크 대상 상품,보험 리스트와 minRule 해석 대상 특약 리스트 리턴
      let result = {'zPIRealMinIsamProdCondList': zPIRealMinIsamProdCondList, 'coverageList': _coverageList}
      return result
    },
    /******************************************************************************
    * Function명 : prcsProductInfoCallback
    * 설명       : 상품조회 후 패널 초기화
    *              [원본] MSPPI080D
    ******************************************************************************/
    prcsProductInfoCallback (panel) {
      console.log('prcsProductInfoCallback START ==============')
      // 2019.09김민정프로요청 (종피건강상태 변경으로 인한 사항일때는 화면 그대로 넣어줌)
      let gridBindingObjchgInsrdYn = Gaf.getVObj('PNLPQL00109_CoverageInfo')

      this.isError = false
      // errorList clear
      this.clearErrList(true)
      // 23.02 min.ko ASR230100644 가입설계화면 특약 그룹핑 개발 요청_ASR230100645_가입설계화면 특약 그룹핑 개발요청
      // 주피, 종피, 자녀 1/2/3 탭 삭제
      let lv_Vm = this
      this.tabItems.splice(0)


      // 변경청약시 가입된 특약만 처리하기 위한 ABDACOV 모으기
      let abdacovpac = Gaf.getGafObjByRefId('COVPAC')
      let abdacov = []

      abdacovpac.forEach((el, i) => {
        for (let k in el.abdacov) {
          abdacov.push(el.abdacov[k])
        }
      })

      // V모델 생성
      let tmpCovList = []
      let cnt = 0
      let elementprodtpCd = null

      let zzptclrPrdTId = ''
      // if (Gaf.getPanel('MSPPI002D')) {
        zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
      // }
      // console.log('[prcsProductInfoCallback] zzptclrPrdTId ===> ', zzptclrPrdTId)

      let tmodel = Gaf.getObj('tmodel')

      // 202005 외화보험 통화키
      let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
      let currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

      // ===================================================================
      // 선심사 ----------- 선택된 특약만 노출하기
      // lstNundwDignUndtkStnd
      // zaInscd	보험코드 : LI1211001
      // zaContAmt	계약금액 : "1000"
      // zaLmtAmt	한도금액 : 0
      // zaEntLmtExcdAmt	가입한도초과금액
      // zaUndtkDalwScCd	인수불가구분코드
      // zaUndtkCntnt	인수내용 - 
      // zaAnmsEntLmtCntnt	병력가입한도내용 : "심사필요" - 심사결과
      // zaExptJdgRsltCntnt	예상심사결과내용 : "심사필요" - 심사결과 상세
      let lstNundwDignUndtkStnd = Gaf.getPanel('MSPPI820P').lv_lstNundwDignUndtkStnd
      
      // console.log('setCoverageList ===> ', setCoverageList)
      // ===================================================================
      // Gaf.getTObj('prodInfo').coverageList
      // setCoverageList.forEach((el, i) => {
      Gaf.getTObj('prodInfo').coverageList.forEach((el, i) => {
        
        if (lv_Vm.DEBUG) console.log(">>> prodInfo el:" +  JSON.stringify(el))

        // Start =====================================================
        // [선심사 및 영업시스템 고도화PJT] 가입된 특약만 필터링하기
        // lstNundwDignUndtkStnd
        let tempAbdacov = null
        for (let k in abdacov) {
          if (el.insCode === abdacov[k].zzcoltrCd && el.uiCode === abdacov[k].aldacov.zzentplRepCCd) {
            tempAbdacov = abdacov[k]
            console.log(i, '. tempAbdacov =========> ', tempAbdacov)
            break
          }
        }
        if (tempAbdacov === null) return true
        // End =====================================================
 
        /** 20160512 yherp.kwon 보험종목에 해당하는 보험목록만 화면 표시 */
        if (Gaf.getTObj('baseContDto') && !piCommonUtil.isEmpty2(Gaf.getTObj('baseContDto').zzentplLineCd)) {
          console.log(">>> baseContDto.zzentplLineCd:" +  Gaf.getTObj('baseContDto').zzentplLineCd + " >>>> prodInfo.coverageList.type :"  + el.type)
          if (!(el.type === Gaf.getTObj('baseContDto').zzentplLineCd || piCommonUtil.isEmpty2(el.type))) {
            return true
          }
        }

        /** 20170711 전환실손 상품은 LineCd 와 의료실손상세속성코드+의료실손보상비율코드 비교해서 필터링 */
        // if (Gaf.getPanel('MSPPI002D')) {
          if (piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto')) && piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto').covLineCds)) {
            let _covLineCds = null
            _covLineCds = Gaf.getVObj('ZRldmContCnvrDto').covLineCds
              .filter(function (item) { return piCommonUtil.isEmpty2(el.lineCd) || item === el.lineCd })

            if (!(piCommonUtil.isObj(_covLineCds) && _covLineCds.length > 0)) {
              return true
            }
          }
        // }

        /** 20180125 어린이종신전환 상품은 전환해지건수가 없거나, 전환해지계약번호가 1건을 초과하면 설계불가하도록, 특약 전부 안보이게. */
        // if (Gaf.getPanel('MSPPI002D')) {
          if (piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto')) && Gaf.getVObj('ZRldmContCnvrDto').zzcnvrPrdScCd === '06') {
            if (!(piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto').zaTotCnt) && Number(Gaf.getVObj('ZRldmContCnvrDto').zaTotCnt) > 0)) {
              return true
            } else if (Gaf.getVObj('ZRldmContCnvrDto')._zaContNos.length > 1) {
              return true
            }
          }
        // }

        /** 적립계약 여부가 존재하며, 적립계약 '미적용'일 경우에는 보험코드 구분코드가 47, 48인 특약은 조회하지 않음. 20160817 박세현**/
        //                  let rdZZPDINS_R_C_C_ID = GafMainFunc.getIfbcValue("ZZPDINS_R_C_C_ID", el.templateId);
        let rdZZPDINS_R_C_C_ID = el.zzinscdScCd
        let _baseInfo00033 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00033' })

        // if (Gaf.getPanel('MSPPI001M') &&
        // [선심사PJT] 아래의 필터링에서 체크
        // if (Gaf.getPanel('MSPPI002D') &&
        if (piCommonUtil.isObj(_baseInfo00033) && _baseInfo00033.length > 0 &&
            Gaf.getTObj('baseContDto').zaAcumContPlanYn === 'N') {
          console.log('baseContDto 1 ============')
          if (rdZZPDINS_R_C_C_ID === '47' || rdZZPDINS_R_C_C_ID === '48' ) {
            return true
          }
        }

        // 105패널이 없는경우 적립계약관련 필터링
        // if (!Gaf.getPanel('MSPPI001M')) {
        if (!Gaf.getPanel('MSPPI002D')) {
          console.log('baseContDto 2 ============')
          let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
          let ztpqlCboextAddmAbdapolpr = abdapolpr.ztpqlCboextAddmAbdapolpr

          if (ztpqlCboextAddmAbdapolpr &&
              ztpqlCboextAddmAbdapolpr.zaAcumContPlanYn &&
              ztpqlCboextAddmAbdapolpr.zaAcumContPlanYn !== '' &&
              ztpqlCboextAddmAbdapolpr.zaAcumContPlanYn !== 'Y'
          ) {
            if (rdZZPDINS_R_C_C_ID === '47' || rdZZPDINS_R_C_C_ID === '48' ) {
              return true
            }
          }
        }

        let tmpCovObj = {}
        /** 20160704 yherp.kwon : 체크여부저장용필드 추가 */
        tmpCovObj.isChecked = false
        tmpCovObj.disabled = false

        // 보험코드구분코드추가 20161017 박세현
        tmpCovObj.zzinscdScCd = el.zzinscdScCd

        tmpCovObj.seq = cnt++
        tmpCovObj.GafProductInfo = el // 상품정보원본
        tmpCovObj.relTyp = GafMainFunc.makeRltnrText(el.insured, el.childNo) // 관계-임시
        tmpCovObj.relTypCd = el.insured // 관계코드-임시
        tmpCovObj.name = el.name // 보험명
        tmpCovObj.covInsAmt = '' // 보험가입금액

        // 보기납기 데이터가 있는 경우에만 보험기간 콤보 아이템을 세팅함 20160929
        if ( piCommonUtil.isObj(Gaf.getTObj('prodInfo').inprdPmprdList) && Gaf.getTObj('prodInfo').inprdPmprdList.length > 0) {
          tmpCovObj.inPrdTypItems = this.makeInPrdTypItems(el.uiCode, el.insured, el.childNo) // 보험기간콤보아이템함수 호출
        }
        tmpCovObj.pmprdTypSelected = {key: '', label: ''} // 납입기간
        tmpCovObj.pmPrdTypItems = [] // 납입기간콤보아이템:보험기간선택시 동적으로 아이템이 생성됨
        tmpCovObj.covPrm = '' // 보험료
        tmpCovObj.unit = el.unit // 통화단위

        // 202005 외화보험 통화단위명  (상품구조에 외화보험도 통화단위 원으로 되어 강제설정, 추후 확인필요)
        tmpCovObj.currencyId = currencyId
        tmpCovObj.unitNm = el.unitNm

        // 변경청약 정보 관련
        tmpCovObj.prevCovInsAmt = '' // 변경전 가입금액
        tmpCovObj.prevCovInsAmtUnit = '만원' // 변경전 가입금액 단위
        tmpCovObj.prevCovPrm = '' // 변경전 보험료
        tmpCovObj.surCovPrm = '' // 할증보험료
        tmpCovObj.remSurCovPrm = '' // 보험료차액(할증)
        tmpCovObj.remCovPrm = '' // 보험료차액

        // 아래는 COV 관련 상품속성들
        tmpCovObj.templateId = el.templateId
        tmpCovObj.insCode = el.insCode
        tmpCovObj.insured = el.insured
        tmpCovObj.childNo = el.childNo
        tmpCovObj.subInsured = el.subInsured
        tmpCovObj.uiCode = el.uiCode
        /** 20160519 yherp.kwon : ZWPQL0201 6차 변경 반영 - 항목 추가 */
        tmpCovObj.type = el.type
        tmpCovObj.lineCd = el.lineCd
        tmpCovObj.maxAge = el.maxAge
        tmpCovObj.insCode = el.insCode
        tmpCovObj.typCd = el.typCd

        // vmodel에 구성해주지 않고 있었음. 박세현 추가 20170630
        tmpCovObj.preferable = el.preferable

        // 23.02 min.ko ASR230100644 가입설계화면 특약 그룹핑 개발 요청_ASR230100645_가입설계화면 특약 그룹핑 개발요청
        tmpCovObj.grouping = el.grouping
        if (!piCommonUtil.isEmpty2(tmpCovObj.grouping)) {
          if (lv_Vm.tabItems.length < 1) {
            lv_Vm.tabItems.push({'tabTitle': '전체', 'key': '00', 'label': '전체', 'isChecked': true})
            lv_Vm.tabItems.push({'tabTitle': tmpCovObj.grouping, 'key': '01', 'label': tmpCovObj.grouping, 'isChecked': false})
          } else {
            let hasTab = false
            lv_Vm.tabItems.forEach((elTab, i) => {
              if (elTab['tabTitle'] === tmpCovObj.grouping) {
                hasTab = true
              }
            })
            if (!hasTab) {
              let tmpKey = lv_Vm.tabItems.length + ''
              if (tmpKey.length < 2) {
                tmpKey = '0' + tmpKey
              }

              let tmpisChecked = false
              if (tmpCovObj.grouping === '전체'){
                tmpisChecked = true
              } 
              lv_Vm.tabItems.push({'tabTitle': tmpCovObj.grouping, 'key': tmpKey, 'label': tmpCovObj.grouping, 'isChecked': tmpisChecked})
            }
          }
        }   

        // 20160120 yherp.kwon : pnlpql00112 패널에서 변경청약종류 체크박스 클릭시 가입금액 컬럼 enable/disible 처리를 위한 컬럼 추가
        tmpCovObj.isEnabled_covInsAmt = true

        /** 20160712 yherp.kwon : 전환설계화면의 주보험 보기,납기 편집불가 처리위한 컬럼추가 */
        tmpCovObj.isEnabled_inprdTypSelected = true
        tmpCovObj.isEnabled_pmprdTypSelected = true

        // TOBE ONLY
        tmpCovObj.inprdTypSelected = {key: '', label: ''}
        tmpCovObj.pmprdTypSelected = {key: '', label: ''}

        // COV DTO 원본
        tmpCovObj.abdacov = null

        // [NEXT 사랑On] 역산 처리를 위한 역산보험료 필드 추가
        tmpCovObj.ICalcCovPrm = ''
        
        // [NEXT 사랑On] 심사결과 필드 추가
        tmpCovObj.zaExptRslt = ''

        // 행복종신 주보험 이전 가입금액 항목 추가
        tmpCovObj.prevCovInsAmtForLI0933 = ''

        // 금액미입력 여부 체크 필드 추가
        tmpCovObj.calcError = false

        /** 20161214 yherp.kwon : 박영현 대리님 요청으로 주보험 기준 변경 - 상품보험관계구성코드(ZZPDINS_R_C_C_ID)의 값이 01인 경우를 주보험으로 처리 */
        elementprodtpCd = el.zzinscdScCd // GafMainFunc.getIfbcValue('ZZPDINS_R_C_C_ID', el.templateId)
        Gaf.d('elementprodtpCd : ' + elementprodtpCd)
        if (piCommonUtil.isObj(elementprodtpCd) && (elementprodtpCd === '01')) {
          tmpCovObj.elementprodtpCd = '10' // 주보험
          tmpCovObj.disabled = true // 체크박스 컨트롤용 주보험 해제 못하게.
          this.clauseInfo = tmpCovObj // 숫자키패드 처리를 위해 (처음 그려질때 키패드는 숨겨져 있지만, 주보험 금액 미입력 상태에서 다른 보험 선택시 금액 관계 규칙 체크시키기 위해)
        } else {
          tmpCovObj.elementprodtpCd = '0'
        }

        /** 20170711 전환실손 상품은 편집불가 및 자동선택
            20180126 yherp.kwon : 어린이종신전환 상품 주보험 편집 불가
            20210720 ASR210600870_[상품판매준비] 4세대 전환실손 가입설계화면 개발
        */
        // if (Gaf.getPanel('MSPPI001M')) {
        // if (Gaf.getPanel('MSPPI002D')) {
          if (piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto'))) {
            if ((piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto').zRldmContCnvrListDto) &&
                Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('18') > -1 &&
                !(tmpCovObj.lineCd === 'AE04' && !Gaf.getVObj('ZRldmContCnvrDto').thNslryObadFg)) ||
                (tmpCovObj.elementprodtpCd === '10' && Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('19') > -1)) {
              tmpCovObj.isChecked = true
              tmpCovObj.disabled = true
              tmpCovObj.isEnabled_covInsAmt = false
              tmpCovObj.isEnabled_inprdTypSelected = false
              tmpCovObj.isEnabled_pmprdTypSelected = false
            }
          }
        // }

        /** 특이상품 유형코드가 29이고 파라미터개인가족구분코드가 06인경우 비활성화
         *  201906 박희윤. 201907 적립특약의 경우 비활성화 추가 */
        // if (Gaf.getPanel('MSPPI001M')) {
        // if (Gaf.getPanel('MSPPI002D')) {
          let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
          let zzprmtrPFSCd = el.zzprmtrPFSCd
          let zzinscdScCd = el.zzinscdScCd
          if (zzptclrPrdTId === '29') {
            if (zzprmtrPFSCd === '06' || zzinscdScCd === '60') {
              tmpCovObj.isEnabled_covInsAmt = false
              tmpCovObj.isEnabled_inprdTypSelected = false
              tmpCovObj.isEnabled_pmprdTypSelected = false
            }
          }
        // }

        // 2019.09김민정프로요청
        // let tmodel = Gaf.getObj('tmodel')
        // if (piCommonUtil.isObj(tmodel.chgInsrdYn) && !piCommonUtil.isEmpty2(tmodel.chgInsrdYn) && tmodel.chgInsrdYn === 'Y') {
        //   for (let l in gridBindingObjchgInsrdYn) {
        //     if (gridBindingObjchgInsrdYn[l].uiCode === el.uiCode && gridBindingObjchgInsrdYn[l].covInsAmt !== '') {
        //       tmpCovObj.isChecked = true
        //       tmpCovObj.covInsAmt = gridBindingObjchgInsrdYn[l].covInsAmt
        //       break
        //     }
        //   }
        // }
        // 23.05 min.ko DIY 보험 예외 처리(주종피 건강체, 납입주기 변경 시에는 기존 설계 내역 유지하도록 예외처리 되어 있으나 DIY보험 납입지원특약은 가입금액 초기화되도록 예외처리)
        // ASR230500741_[상품판매준비] 소액질병납입지원특약 화면예외처리_사랑On(추가요건)_김영석_youngki78.lee
        let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
        if (piCommonUtil.isObj(tmodel.chgInsrdYn) && !piCommonUtil.isEmpty2(tmodel.chgInsrdYn) && tmodel.chgInsrdYn === 'Y') {
          for (let i in gridBindingObjchgInsrdYn) {
            if (gridBindingObjchgInsrdYn[i].isChecked && gridBindingObjchgInsrdYn[i].uiCode === el.uiCode) {
              if (gridBindingObjchgInsrdYn[i].zzinscdScCd === '64' && piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('37') > -1) {
                tmpCovObj.isChecked = true
                tmpCovObj.covInsAmt = ''
              } else {
                if (gridBindingObjchgInsrdYn[i].covInsAmt !== '') {
                  tmpCovObj.isChecked = true
                  tmpCovObj.covInsAmt = gridBindingObjchgInsrdYn[i].covInsAmt
                }
              }
              break
            }
          }
        }

        // 선심사 추가
        // zaInscd	보험코드 : LI1211001
        // zaContAmt	계약금액 : "1000"
        // zaLmtAmt	한도금액 : 0
        // zaEntLmtExcdAmt	가입한도초과금액
        // zaUndtkDalwScCd	인수불가구분코드
        // zaUndtkCntnt	인수내용 - 
        // zaAnmsEntLmtCntnt	병력가입한도내용 : "심사필요" - 심사결과
        // zaExptJdgRsltCntnt	예상심사결과내용 : "심사필요" - 심사결과 상세
        if (!piCommonUtil.isEmpty2(lstNundwDignUndtkStnd) && lstNundwDignUndtkStnd.length > 0) {
          // : key값 추가 됨
          let item = lstNundwDignUndtkStnd.find(_item => {
            let inscdKey = el.insCode + ':' + el.uiCode
            // console.log('inscdKey : ', inscdKey)
            return inscdKey === _item.zaInscd
          })
          // let item = lstNundwDignUndtkStnd.find(_item => el.insCode === _item.zaInscd)
          if (!piCommonUtil.isEmpty2(item)) {
            tmpCovObj.zaAnmsEntLmtCntnt = item.zaAnmsEntLmtCntnt // 병력가입한도내용 : "심사필요" - 심사결과
            tmpCovObj.zaExptJdgRsltCntnt = item.zaExptJdgRsltCntnt // 예상심사결과내용 : "심사필요" - 심사결과 상세
            tmpCovObj.zaEntLmtExcdAmt = item.zaEntLmtExcdAmt // 가입한도초과금액
          }
        } else {
          tmpCovObj.zaAnmsEntLmtCntnt = '' // 병력가입한도내용 : "심사필요" - 심사결과
          tmpCovObj.zaExptJdgRsltCntnt = '' // 예상심사결과내용 : "심사필요" - 심사결과 상세
          tmpCovObj.zaEntLmtExcdAmt = '' // 가입한도초과금액
        }
        // tmpCovObj.zaEntLmtExcdAmt	= '' // 가입한도초과금액
        // tmpCovObj.zaUndtkDalwScCd = '' // 인수불가구분코드
        // tmpCovObj.prevCovInsAmtUnit = '만원' // 변경전 가입금액 단위
        // tmpCovObj.prevCovPrm = '' // 변경전 보험료
        // tmpCovObj.surCovPrm = '' // 할증보험료
        // tmpCovObj.remSurCovPrm = '' // 보험료차액(할증)
        // tmpCovObj.remCovPrm = '' // 보험료차액
        tmpCovList.push(tmpCovObj)
      })

      // 특약 Group 정보 전달
      // this.$emit('tabItems', lv_Vm.tabItems)        

      // 특약 목록 세팅 ===============
      this.items = tmpCovList
      this.viewData = null
      // [선심사 PJT]
      // as-is
      this.viewData = this.items
      // to-be
      // this.viewData = tmpCovList.filter(setItem => setItem.isChecked)
      // console.log('setChecked ===> ', this.viewData)

      // 2019.09 김민정프로요청
      // let tmodel = Gaf.getObj('tmodel')
      if (piCommonUtil.isObj(tmodel.chgInsrdYn) && !piCommonUtil.isEmpty2(tmodel.chgInsrdYn) && (tmodel.chgInsrdYn === 'Y' || tmodel.chgInsrdYn === 'N')) {
        Gaf.setTModel('chgInsrdYn', '') // 변경여부항목추가 초기화

        let entSrnEpId = '' // 가설화면예외코드(ex: "A1,A4")
        let prodInfo = Gaf.getTObj('prodInfo')
        if ((prodInfo.baseInfoDto.zzentSrnEpId) !== 'undefined') {
          entSrnEpId = prodInfo.baseInfoDto.zzentSrnEpId
          if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('07') > -1) {
            Gaf.setTModel('chgInsrdYn', 'Y') // 변경여부항목추가
          }
        }
      }

      /** 20160627 yherp.kwon : 주피의 주보험 자동 체크 - 부가특약도 자동체크 되어야 하므로 covAutoChecked false 유지 */
      let mainCovObj = this.items.filter(function (item) { return item.elementprodtpCd === '10' && item.relTypCd === '21' })
      let zzentPTCLVl = piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) ? Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl : '' // 가설예시특이유형코드
      if (piCommonUtil.isObj(mainCovObj) && mainCovObj.length > 0) {
        /** 20161019 yherp.kwon : 주보험 자동체크에 의한 부가특약 체크 및 가입금액한도표시등 이벤트 처리 되어야 하므로 covFiltered = true; 막음 */
        this.items[mainCovObj[0].seq].isChecked = true

        /** 20160712 yherp.kwon : 전환설계 화면에서는 주보험 편집 불가 */
        /** 20161013 yherp.kwon : 가설종목코드값이 (50)연금저축거치형, (51)연금저축즉시확정, (52)연금저축즉시종신 이면 주보험 편집 불가 */
        /** 20161014 yherp.kwon : (54)개인연금저축거치형, (55)개인연금저축즉시확정, (56)개인연금저축즉시종신 추가 */
        if (Gaf.getProcessNo() === '1002' || ['50', '51', '52', '54', '55', '56'].indexOf(Gaf.getTObj('baseContDto').zzentplLineCd) > -1) {
          // 가입금액
          this.items[mainCovObj[0].seq].isEnabled_covInsAmt = false
          // 보기
          this.items[mainCovObj[0].seq].isEnabled_inprdTypSelected = false
          // 납기
          this.items[mainCovObj[0].seq].isEnabled_pmprdTypSelected = false
        }

        // 주보험 행 체크시 ( 최초 조회시 )
        // 보험코드 구분코드가 18, 47, 48인 것은 행체크 하고 시작.  조형철 과장님 요청 , 20160907 - 박세현
        // 보험코드 구분코드 18은 제외
        for (let i = 1; i < this.items.length - 1; i++) {
          let zzinscdScCd = this.items[i].zzinscdScCd
          if ( zzinscdScCd === '47' || zzinscdScCd === '48' ) {
            this.items[i].isChecked = true
            this.items[i].disabled = true
          }
          // ASR240500456 [상품판매준비] 내인생플러스 가입설계 화면 개발_사랑온 - 보험구분코드(65)
          if (zzentPTCLVl.search('RN') > -1 && zzinscdScCd === '65') {
              this.items[i].isChecked = true
              this.items[i].disabled = true
          }
        }
      }

      // v-model 디렉티브들 바인딩
      Gaf.setVModel('PNLPQL00109_CoverageInfo', this.items)
      // console.log('MSPPI822D Set PNLPQL00109_CoverageInfo ===> ', JSON.stringify(Gaf.getVObj('PNLPQL00109_CoverageInfo')))

      this.selected.length = 0
      this.items.forEach((el, i) => {
        if (el.isChecked) {
          if (!this.selected.includes(el)) {
            this.selected.push(el)
          }

          this.l00109_sec30_grid10_rowSelectionChangeMain(el)
          console.log('after l00109_sec30_grid10_rowSelectionChangeMain ===> ', this.items[i])

          /** 특이상품 유형코드가 29이고 파라미터개인가족구분코드가 06인경우 
          *  가입금액 컬럼 사용여부 비활성화 처리 추가 (2023-02-28) */
          let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
          let zzprmtrPFSCd = el.GafProductInfo.zzprmtrPFSCd
          if (zzptclrPrdTId === '29') {
            if (zzprmtrPFSCd === '06') {          
              el.isEnabled_covInsAmt = false
            }
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : toVmodel
    * 설명       : 설계정보로 화면 Draw
    *             [원본] MSPPI080D
    ******************************************************************************/
    toVmodel (panel, urlId) {
      // 그리드 다시 그려질 경우에는 관계자유형"전체"로 셋팅
      // this.choiceBox = false // 선택한 특약 보기 라디오 체크해제
      // this.changeTabIdx(0) // '전체'
      
      // 가입금액
      let tempCovInsAmt = 0
      // 보험료
      let tempCovPrm = 0

      /** 20160616 yherp.kwon : COVPAC 적용 */
      // ABDACOV 객체
      let abdacovpac = Gaf.getGafObjByRefId('COVPAC')
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]

      /*
      심사비과세
      비과세정보전송 할지 안할지 (zExtinZsfpctb001Dto)의 값이 있는 경우
      pm에서 비과세정보를 전송한다.
      *
      */
      let zExtinZsfpctb001Tmp = Gaf.getPObj('zExtinZsfpctb001Dto')
      if (Gaf.getProcessNo() === '3001' && zExtinZsfpctb001Tmp) {
        Gaf.setPModel('zExtinZsfpctb001Dto', {})
      }
      /*
        * 심사비과세
      */
      abdacovpac.forEach((covpacel, covpaci) => {
        Gaf.d('abdacovpac => covpaci : ', covpaci)

        // 변경전 계약 정보
        // ABDAPOLPR 객체
        let prevAbdapolpr = null
        // ABDACOV 객체

        let prevAbdacov = null

        let abdacov = covpacel.abdacov

        if (Gaf.getPObj('abdapolicy') && Gaf.getPObj('abdapolicy').length > 1) {
          prevAbdapolpr = Gaf.getPObj('abdapolicy')[1].abdapolpr[0]
          prevAbdacov = prevAbdapolpr.abdacovpac[covpaci].abdacov
        }

        Gaf.d('abdapolpr => clcrlId : ', Number(Gaf.getValue(abdapolpr, 'clcrlId')))

        let gridBindingObj = this.items

        // ABDACOV 루프 처리
        abdacov.forEach((el, i) => {
          Gaf.d('보험 (' + i + ')  처리시작!!! : ', el)

          // 보험코드
          let zzcoltrCd = Gaf.getValue(el, 'zzcoltrCd')
          let zzentplRepCCd = Gaf.getValue(el, 'zzentplRepCCd')
          Gaf.d('zzcoltrCd : ' + zzcoltrCd)
          // 대응되는 Grid Row Get
          let tmpIndex = -1
          let tmpGridRowObj = null
          for (let j in gridBindingObj) {
            if (gridBindingObj[j].insCode === zzcoltrCd && gridBindingObj[j].uiCode === zzentplRepCCd) {
              tmpIndex = Number(j)
              tmpGridRowObj = gridBindingObj[j]
              break
            }
          }

          Gaf.d('tmpIndex : ' + tmpIndex)
          // ABDACOV 정보에 해당되는 그리드 Row 정보가 있는경우
          if (tmpGridRowObj) {
            // abdacov 원본 셋팅
            tmpGridRowObj.abdacov = el

            // 체크박스 체크처리
            Gaf.d('setSelectedIndex => ' + tmpIndex)

            if (!tmpGridRowObj.isChecked) {
              this.covAutoChecked = true
              this.covFiltered = true
              tmpGridRowObj.isChecked = true
              this.covAutoChecked = false
              this.covFiltered = false
            }

            // 가입금액 / 보험료 셋팅
            /** 20160628 yherp.kwon : 가입금액 cbo로 변경 */
            tempCovInsAmt = Gaf.getValue(el.ztpqlCboextAddmAbdacov, 'zaEntAmt') // 가입금액

            tempCovPrm = Gaf.getValue(el.covAbdaprem[0], 'premaftertaxAm')

            /** 20160628 yherp.kwon : 변경 */
            tmpGridRowObj.covInsAmt = GafMainFunc.setUnitAmt(tempCovInsAmt, tmpGridRowObj.unit)
            tmpGridRowObj.covPrm = GafMainFunc.getZeroFixedNum(tempCovPrm)
            tmpGridRowObj.prevCovInsAmtForLI0933 = tmpGridRowObj.covInsAmt

            // 선심사 추가
            tmpGridRowObj.prevCovInsAmt = GafMainFunc.setUnitAmt(tempCovInsAmt, tmpGridRowObj.unit)

            // 보험기간
            // let tempZzinprdTypCd = Gaf.getValue(el, 'zzinprdTypCd')
            // let tempZzinprdTypVl = Gaf.getValue(el, 'zzinprdTypVl')
            // let tempZzinprdTypkey = tempZzinprdTypCd + ',' + tempZzinprdTypVl
            // tmpGridRowObj.inprdTypSelected = tmpGridRowObj.inPrdTypItems.filter(function (item) { return item.key === tempZzinprdTypkey })[0]
            // 납면,보장(보험코드구분코드 18,48,17일때의 처리) 추가
            // 20170324 박세현
            // this.fnZzinscdScCd184847( tmpGridRowObj )

            // 보험계약대상자 및 자녀일련번호 추가 - 20160708 박세현 추가
            // let insured = tmpGridRowObj.insured
            // let childNo = tmpGridRowObj.childNo

            // 보험기간 기준으로 납입기간 콤보 생성
            // let items = this.makePmPrdTypItems(tmpGridRowObj.uiCode, tempZzinprdTypCd, tempZzinprdTypVl, insured, childNo, tmpGridRowObj.templateId )
            // tmpGridRowObj.pmPrdTypItems = items.slice()

            // 납입기간 셋팅
            // let tempZzpmprdTypCd = Gaf.getValue(el.covAbdaprem[0], 'zzpmprdTypCd')
            // let tempZzpmprdTypVl = Gaf.getValue(el.covAbdaprem[0], 'zzpmprdTypVl')
            // debugger
            // let tempZzpmprdTypkey = tempZzpmprdTypCd + ',' + tempZzpmprdTypVl
            // tmpGridRowObj.pmprdTypSelected = tmpGridRowObj.pmPrdTypItems.filter(function (item) { return item.key === tempZzpmprdTypkey })[0]

            // 납면,보장(보험코드구분코드 18,48,17일때의 처리) 추가
            // 20170324 박세현
            // 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
            // 보험기간
            let tempZzinprdTypCd = Gaf.getValue(el, 'zzinprdTypCd')
            let tempZzinprdTypVl = Gaf.getValue(el, 'zzinprdTypVl')
            let tempZzinprdTypkey = tempZzinprdTypCd + ',' + tempZzinprdTypVl
            tmpGridRowObj.inprdTypSelected = tmpGridRowObj.inPrdTypItems.filter(function (item) { return item.key === tempZzinprdTypkey })[0]

            if (!this.fnZzinscdScCd184847( tmpGridRowObj )) {
              // 보험계약대상자 및 자녀일련번호 추가 - 20160708 박세현 추가
              let insured = tmpGridRowObj.insured
              let childNo = tmpGridRowObj.childNo

              // 보험기간 기준으로 납입기간 콤보 생성
              let items = this.makePmPrdTypItems(tmpGridRowObj.uiCode, tempZzinprdTypCd, tempZzinprdTypVl, insured, childNo, tmpGridRowObj.templateId )
              tmpGridRowObj.pmPrdTypItems = items.slice()

              // 납입기간 셋팅
              let tempZzpmprdTypCd = Gaf.getValue(el.covAbdaprem[0], 'zzpmprdTypCd')
              let tempZzpmprdTypVl = Gaf.getValue(el.covAbdaprem[0], 'zzpmprdTypVl')
              // debugger
              let tempZzpmprdTypkey = tempZzpmprdTypCd + ',' + tempZzpmprdTypVl
              tmpGridRowObj.pmprdTypSelected = tmpGridRowObj.pmPrdTypItems.filter(function (item) { return item.key === tempZzpmprdTypkey })[0]
            }

            // 변경청약 정보 관련
            let tempOrginalCov = null
            if (prevAbdacov) {
              for (let i in prevAbdacov) {
                if (prevAbdacov[i].zzcoltrCd === zzcoltrCd &&
                                    prevAbdacov[i].aldacov.zzentplRepCCd === zzentplRepCCd
                ) {
                  tempOrginalCov = prevAbdacov[i]
                  break
                }
                /*
                * 심사프로세스번호 '3001' 신계약심사에서 비과세 전송처리 여부를 체크 하기위해
                * 비과세DTO정보가 있고, 가입금액 변경시에만 비과세정보를 적재한다.
                */
                if (Gaf.getProcessNo() === '3001') {
                  if (zExtinZsfpctb001Tmp && prevAbdacov[i].zaEntAmt !== tempCovInsAmt) {
                    Gaf.setPModel('zExtinZsfpctb001Dto', zExtinZsfpctb001Tmp)
                  }
                }
              }
            }

            // 변경전 보험정보가 있으면 변경전 정보 생성 (변경청약용)
            // 심사에서는 보험료계산이 없더라도 역산상품의 보험료정보를 셋팅하도록 처리 한다.
            if (tempOrginalCov ) {
              tmpGridRowObj.prevCovPrm = Gaf.getValue(tempOrginalCov.covAbdaprem[0], 'premaftertaxAm') // 변경전 보험료
              tmpGridRowObj.prevCovInsAmt = Gaf.getValue(tempOrginalCov.ztpqlCboextAddmAbdacov, 'zaEntAmt')

              // 변경전 가입금액
              tmpGridRowObj.prevCovInsAmt = GafMainFunc.setUnitAmt(tmpGridRowObj.prevCovInsAmt, tmpGridRowObj.unit)
              // 변경전 가입금액 단위
              tmpGridRowObj.prevCovInsAmtUnit = tmpGridRowObj.unitNm
              // 변경전보험료
              tmpGridRowObj.prevCovPrm = GafMainFunc.getZeroFixedNum(tmpGridRowObj.prevCovPrm)

              // 역산일경우 가입금액란에 보험료로 대체
              if ( el.aldacov.zzinscdScCd === '01' && Number(abdapolpr.aldapolpr.clcrlId) === 2 ) {
                tmpGridRowObj.covInsAmt = GafMainFunc.setUnitAmt(tempCovPrm, tmpGridRowObj.unit) // 변경후 가입금액
                tmpGridRowObj.prevCovInsAmt = GafMainFunc.setUnitAmt(tmpGridRowObj.prevCovPrm, tmpGridRowObj.unit) // 변경전 가입금액
              }

              /*
              - 변경 전/후 할증금액 구하기
              971 사망할증
              972 CI 할증
              973 LTC할증
              974 종합실손할증
              975 질병실손할증
              976 심근경색할증
              977 뇌출혈할증
              978 고도장해할증
              979 입원할증
              980 사망할증 예외건
              981 CI 할증 예외건
              982 사망&CI 할증
              */
              //
              let befExtraAmt = this.getExtraPrm(tempOrginalCov) // 변경전 할증금액
              let aftExtraAmt = this.getExtraPrm(el) // 변경후 할증금액

              let befNetPrm = GafMainFunc.getZeroFixedNum(Number(tmpGridRowObj.prevCovPrm) - Number(befExtraAmt)) // 변경전 순수보험료
              let aftNetPrm = GafMainFunc.getZeroFixedNum(Number(tmpGridRowObj.covPrm) - Number(aftExtraAmt)) // 변경후 순수보험료
              let remNetPrm = aftNetPrm - befNetPrm // 이게 감액분

              // 보험료차액(할증) = 변경전후 할증금액 차이
              tmpGridRowObj.remSurCovPrm = GafMainFunc.getZeroFixedNum(Number(aftExtraAmt) - Number(befExtraAmt))

              // 보험료차액(할증+감액) = 변경후보험료 - 변경전보험료의 차이
              tmpGridRowObj.remCovPrm = GafMainFunc.getZeroFixedNum(Number(tmpGridRowObj.covPrm) - Number(tmpGridRowObj.prevCovPrm))

              // 보험료(감액) = 변경후 보험료 - 변경후 할증금액
              tmpGridRowObj.reduCovPrm = GafMainFunc.getZeroFixedNum(Number(tmpGridRowObj.covPrm) - Number(aftExtraAmt))

              // 보험료(할증) = SUPDC 의 할증코드금액의 합
              tmpGridRowObj.surCovPrm = GafMainFunc.getZeroFixedNum(Number(tmpGridRowObj.covPrm)) - remNetPrm
            }

            // 월납환산 및 수당코드정보
            let covAldaprem = el.covAbdaprem[0].covAldaprem
            if (covAldaprem && covAldaprem.zzmpadCnvlPAm) {
              // 월납환산보험료
              tmpGridRowObj.mpadCnvlPrm = GafMainFunc.getZeroFixedNum(covAldaprem.zzmpadCnvlPAm)
            }
            if (covAldaprem && covAldaprem.zzalwcCd) {
              // 수당코드
              tmpGridRowObj.alwcCd = covAldaprem.zzalwcCd
            }
          } else {
            console.log('[ERROR]보험코드:' + zzcoltrCd + ' 가 그리드에 존재하지 않습니다!!!!')
          }

          Gaf.d('보험코드 : ' + zzcoltrCd + '  처리종료')
        })
      }) // covpac loop end

      // 22.03 ASR220300038 / 건강증진형프리미엄(2204) 최소가입금액 한도체크 / young21.kwon
      // ASR220300426 / 건강증진프리미엄건강보험 최소가입금액 체크 반영 20220405 youngki78.lee
      this.fn_SetRealMinIsam(false, false)

      // v-model 디렉티브들 바인딩
      this.selected.length = 0
      this.items.forEach((el, i) => {
        if (el.isChecked) {
          this.selected.push(el)
        }
      })
    },
    /******************************************************************************
    * Function명 : toVmodel2
    * 설명       : 설계정보로 화면 Draw
    *              합계보험료 세팅
    *              [원본] MSPPI084D
    ******************************************************************************/
    toVmodel2 () {
      // POLPR_ABDAPREM
      let polprAbdaprem = Gaf.getGafObjByRefId('POLPR_PREM')[0]
      console.log('polprAbdaprem ===> ', polprAbdaprem)
      let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
      let abdapolpr = abdapolicy.abdapolpr[0]
      // 선심사 추가 - 이전 금액 세팅을 위한 내용
      let abdapolicy1 = Gaf.getGafObjByRefId('POLICY')[1]
      let abdapolpr1 = abdapolicy1.abdapolpr[0]
      let polprAbdaprem1 = abdapolpr1.polprAbdaprem[0]
      // let polprAbdaprem1 = Gaf.getGafObjByRefId('POLPR_PREM')[1]

      // 출생예정일기준 환산성적(TP)
      if (piCommonUtil.isObj(abdapolpr.polprAbdabase) && abdapolpr.polprAbdabase.length > 0) {
        let polprAbdabase = abdapolpr.polprAbdabase
        for (let i in polprAbdabase) {
          if (polprAbdabase[i].pmId === '3590TB5L0000' && !piCommonUtil.isEmpty2(polprAbdabase[i].zzentPRDTCd) && polprAbdabase[i].zzentPRDTCd === '04' && !piCommonUtil.isEmpty2(polprAbdabase[i].zaBrthPntmCnvlPrfmAmt)) {
            this.zaBrthPntmCnvlPrfmAmt = polprAbdabase[i].zaBrthPntmCnvlPrfmAmt
            break
          }
        }
      }
      // 선심사 추가 - 이전 출생예정일기준 환산성적(TP)
      if (piCommonUtil.isObj(abdapolpr1.polprAbdabase) && abdapolpr1.polprAbdabase.length > 0) {
        let polprAbdabase = abdapolpr1.polprAbdabase
        for (let i in polprAbdabase) {
          if (polprAbdabase[i].pmId === '3590TB5L0000' && !piCommonUtil.isEmpty2(polprAbdabase[i].zzentPRDTCd) && polprAbdabase[i].zzentPRDTCd === '04' && !piCommonUtil.isEmpty2(polprAbdabase[i].zaBrthPntmCnvlPrfmAmt)) {
            this.prevZaBrthPntmCnvlPrfmAmt = polprAbdabase[i].zaBrthPntmCnvlPrfmAmt
            break
          }
        }
      }
      /** 20170308 yherp.kwon : 환산성적 표시 추가 -박영현 책임 요청 */
      // 월납환산보험료
      this.zzcnvlPrfmAm = polprAbdaprem.polprAldaprem.zzcnvlPrfmAm ? polprAbdaprem.polprAldaprem.zzcnvlPrfmAm : '0'
      // 선심사 추가 - 월납환산보험료
      this.prevZzcnvlPrfmAm = polprAbdaprem1.polprAldaprem.zzcnvlPrfmAm ? polprAbdaprem1.polprAldaprem.zzcnvlPrfmAm : '0'

      // 합계보험료(=세후보험료)
      this.smtotPrm = polprAbdaprem.premaftertaxAm ? polprAbdaprem.premaftertaxAm : '0'
      // 선심사 추가 - 이전 합계보험료
      this.prevSmtotPrm = polprAbdaprem1.premaftertaxAm ? polprAbdaprem1.premaftertaxAm : '0'

      // 실납입보험료
      this.rlpadPrm = polprAbdaprem.polprAldaprem.zzrlpadPrmAm ? polprAbdaprem.polprAldaprem.zzrlpadPrmAm : '0'
      if (abdapolpr.aldapolpr && abdapolpr.aldapolpr.zzfrtRpdPrmAm) { // 초회실납입보험료 있으면 이 값으로 셋팅
        this.rlpadPrm = abdapolpr.aldapolpr.zzfrtRpdPrmAm
      }
      // 선심사 추가 - 실납입보험료
      this.prevRlpadPrm = polprAbdaprem1.polprAldaprem.zzrlpadPrmAm ? polprAbdaprem1.polprAldaprem.zzrlpadPrmAm : '0'
      if (abdapolpr.aldapolpr && abdapolpr.aldapolpr.zzfrtRpdPrmAm) { // 초회실납입보험료 있으면 이 값으로 셋팅
        this.prevRlpadPrm = abdapolpr.aldapolpr.zzfrtRpdPrmAm
      }

      // 202005 외화보험 통화단위
      this.currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId


      console.log(">>>>>>>>>>>>>>>>>> currencyId :", this.currencyId)

      // 202005 원화환산보험료
      if (this.currencyId === 'USD') {
        this.isUsd = true
        this.currencyKrwTxt = ''
        this.currencyUsdTxt = '$  '
        // if ( !this.$bizUtil.isEmpty( Gaf.getTObj('zCurrConvDto').exrtRate)) {
        if ( !this.$bizUtil.isEmpty( Gaf.getTObj('zCurrConvDto')) && !this.$bizUtil.isEmpty( Gaf.getTObj('zCurrConvDto').exrtRate)) {
          this.smtotPrmKrw = this.smtotPrm * Gaf.getTObj('zCurrConvDto').exrtRate
          // 선심사 추가 - 원화환산 보험료
          this.prevSmtotPrmKrw = this.prevSmtotPrm * Gaf.getTObj('zCurrConvDto').exrtRate
          this.exrtRate = Gaf.getTObj('zCurrConvDto').exrtRate

          // 월납환산보험료 환율계산 (소숫점 내림)
          this.zzcnvlPrfmAm = Math.floor(this.zzcnvlPrfmAm * this.exrtRate)
        }
      } else {
        this.isUsd = false
        this.currencyKrwTxt = '원'
        this.currencyUsdTxt = ''
        this.smtotPrmKrw = this.smtotPrm
        // 선심사 추가 - 원화환산 보험료
        this.prevSmtotPrmKrw = this.prevSmtotPrm
        this.exrtRate = ''
      }

      // 201907 실납입보험료 -> 출생이후 합계보험료로 변경(김민정프로요청)
      let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
      let processNo = Gaf.getProcessNo()
      this.is1001_29 = false
      if (zzptclrPrdTId === '29' && processNo === '1001') {
        this.is1001_29 = true
        this.rlpadPrm = polprAbdaprem.polprAldaprem.zzth02AfSPAm ? polprAbdaprem.polprAldaprem.zzth02AfSPAm : '0'
        // 선심사 추가 - 이전 실납입보험료
        this.prevRlpadPrm = polprAbdaprem1.polprAldaprem.zzth02AfSPAm ? polprAbdaprem1.polprAldaprem.zzth02AfSPAm : '0'
        this.tpNm = '계약일기준TP'
        this.smtotPrmTxt = '출생예정일 이전 합계 보험료'
        this.rlpadPrmTxt = '출생예정일 이후 합계 보험료'
      } else {
        this.tpNm = '환산성적(TP)'
        this.smtotPrmTxt = '합계 보험료'
        this.rlpadPrmTxt = '실납입 보험료'
      }

      // 2020.12 김수은프로요청 (ASR201200733 / 올바른성장보험(태아형)의 가설화면의 TP관련 텍스트 변경 요청)_youngki78.lee
      // >> ASR210200103 '태아가입보험의 사랑온 가설화면의 TP 구분 표기' 로 결합
      // let productId = abdapolicy.productCd // 상품ID
      // let processNo = Gaf.getProcessNo()
      // if (processNo === '1001') {
      //   if (productId === 'LQ_U5PAL55L0000_G02_1' || productId === 'LQ_U5PAL55L0000_G02_2' ) {
      //     this.tpNm = '계약일기준TP'
      //   } else {
      //     this.tpNm = '환산성적(TP)'
      //   }
      // }

      // TP 숨기기
      this.isOpen = false

      // [선심사 및 영업시스템 고도화PJT] 메인화면 바텀시트에서 보여질 합계보험료
      // MSPPI003P 가입설계 진행현황에 보낼 정보들
      this.smtotPrmData = {}
      let _smtotPrmData = {isUsd: this.isUsd,                         // 통화코드 USD 여부
                          todayExchangeRate: this.todayExchangeRate,  // 금일 환율
                          exrtRate: this.exrtRate,                    // Gaf 변동 환율
                          smtotPrmTxtKrw: this.smtotPrmTxtKrw,        // 원화환산 보험료 명칭
                          smtotPrmKrw: this.smtotPrmKrw,              // 원화환산 보험료
                          prevSmtotPrmKrw: this.prevSmtotPrmKrw,      // 선심사 추가- 이전 원화환산 보험료
                          smtotPrmTxt: this.smtotPrmTxt,              // 합계 보험료 명칭
                          currencyUsdTxt: this.currencyUsdTxt,        // 통화코드 USD
                          smtotPrm: this.smtotPrm,                    // 합계보험료(=세후보험료)
                          prevSmtotPrm: this.prevSmtotPrm,            // 선심사 추가- 이전 합계보험료(=세후보험료)
                          currencyId: this.currencyId,                // 통화코드
                          currencyKrwTxt: this.currencyKrwTxt,        // 통화코드 KRW
                          rlpadPrmTxt: this.rlpadPrmTxt,              // 실납입 보험료 명칭
                          rlpadPrm: this.rlpadPrm,                    // 실납입보험료
                          prevRlpadPrm: this.prevRlpadPrm,            // 선심사 추가- 이전 실납입보험료
                          diffSmtotPrm: (+this.smtotPrm) - (+this.prevSmtotPrm) // 선심사 추가 - 보험료차액
                          }
                          // isOneShotPrm: this.isOneShotPrm }           // 원샷보험료 여부
      this.smtotPrmData = _smtotPrmData
      this.$emit('setSmtotPrmData', this.smtotPrmData)
    },
    /******************************************************************************
    * Function명 : makeInPrdTypItems
    * 설명       : 보험기간 콤보 아이템 생성 함수
    *              [원본] MSPPI080D
    ******************************************************************************/
    makeInPrdTypItems (prcdId, insured, childNo) {
      let tmp = []
      let inPrdTypItems = []

      // 관계에 해당하는 고객정보가 없다면, 선택불가항목임 / 영현대리님 요청
      // 관계가 자녀1인데, 자녀1 고객선택이 되어있지않다면 선택불가항목 - 20160711 박세현
      let chkRltnrInfo = false
      for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
        if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === insured) {
          if (insured === '24') {
            if (String(childNo) === String(Gaf.getTObj('rltnrInfo')[i].zzchldSnoVl)) {
              chkRltnrInfo = true
              break
            }
          } else {
            chkRltnrInfo = true
            break
          }``
        }
      }
      if (!chkRltnrInfo) {
        inPrdTypItems.push({label: '불가', key: '9'})
        return inPrdTypItems
      }

      // let slctEntObjrScCd = insured + childNo + ''

      // prcdId와 inprdPmprdList.zzcoltrId 비교하여 같은건만 리스팅 하여 반환
      tmp = Gaf.getTObj('prodInfo').inprdPmprdList.filter(function (item) {
        return item.uiCode === prcdId
      })

      /* tmp가 있다면 */
      /** 20160830 yherp.kwon : 보험료계산결과가 오류일때 tmp배열내 errType 속성이 아예 없기에 오류 발생하여 수정 */

      // tmp 길이가 0이거나, errType 유효값이 존재하면 불가세팅
      if ( tmp.length === 0 || ( piCommonUtil.isObj(tmp[0].errType) && tmp[0].errType.length > 0)) {
      // '불가' 추가
        inPrdTypItems.push({label: '불가', key: '9'})
        return inPrdTypItems
      }

      // 보험기간 콤보세트 만들기
      let _key = null
      let _text = null
      let tmp2 = []

      tmp.forEach((el, i) => {
        _key = el.zzinprdTypCd + ',' + el.zzinprdTypVl
        _text = GafMainFunc.makePrdText(el.zzinprdTypCd, el.zzinprdTypVl)

        // 콤보아이템이 중복되지 않도록
        if (!tmp2.includes(_key)) {
          tmp2.push(_key)
          inPrdTypItems.push({label: _text, key: _key})
        }
      })

      return inPrdTypItems
    },
    /******************************************************************************
    * Function명 : makePmPrdTypItems
    * 설명       : 납입기간 콤보 아이템 생성 함수
    * [원본] MSPPI080D
    ******************************************************************************/
    makePmPrdTypItems (prcdId, zzinprdTypCd, zzinprdTypVl, insured, childNo, templateId) {
      let tmp = []
      let pmPrdTypItems = []

      // '선택' 항목을 아예 빼는 것으로 영현대리님과 협의 20170126 박세현 수정
      // '선택' 추가(주보험이 아닐 때만 20170120 박세현 추가)
      /* if(  isObj(Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo) && Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo[0].insCode != prcdId){
            pmPrdTypItems.push({label:"선택", key:""});
        } */

      // let slctEntObjrScCd = insured + childNo + ''

      // zzinprdTypCd ,zzinprdTypVl 비교하여 같은건만 리스팅 하여 반환
      tmp = Gaf.getTObj('prodInfo').inprdPmprdList.filter(function (el, i) {
        // let chk = false

        // 설계조회할때도 Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo Object가 존재함. 20170307
        // Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo Object가 없다면, 설계조회할때임. 이때는 이미 보기납기 서비스 내에서
        // 주보험 보기 혹은 납기에 대한 처리를 끝내고 내려오므로 아래 스크립트 체크가 필요없음. 20161025 박세현 수정
        //                    if( el.zzcoltrId === prcdId && el.zzinprdTypCd === zzinprdTypCd && el.zzinprdTypVl === zzinprdTypVl && (null == el.zzinsuvInsrdCd || (el.zzinsuvInsrdCd!="" && el.zzinsuvInsrdCd==slctEntObjrScCd)||el.zzinsuvInsrdCd=="") ){
        return el.uiCode === prcdId && el.zzinprdTypCd === zzinprdTypCd && el.zzinprdTypVl === String(zzinprdTypVl)
        //                          && isObj(Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo) ){
        // chk = true
        // }

        // return chk
      })
      // 납입기간 콤보세트 만들기
      let _key = null
      let _text = null
      // 2018.01.12[전환용]어린이종신 예외처리 요청 (박영현책임)
      // let prodTemplateId = Gaf.getPObj('abdapolicy')[0].productBaseId
      let tmp2 = []
      let _zzentPTCLVl = Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl
      tmp.forEach((el, i) => {
        _key = el.zzpmprdTypCd + ',' + el.zzpmprdTypVl
        _text = GafMainFunc.makePrdText(el.zzpmprdTypCd, el.zzpmprdTypVl)

        // 보험기간보다 긴 납입기간 제거 - 20160524 박세현 추가
        // 실보험기간
        let inprdKey = zzinprdTypCd + ',' + zzinprdTypVl
        let tmpInprd = this.fnTrtyInprd(inprdKey, insured, childNo)
        // 실납입기간
        let tmpPmprd = this.fnTrtyPmprd(_key, insured, childNo)

        // 주보험 실납입기간
        let mnPmprd = this.fnTrtyPmprd( Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected.key,
          Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].insured,
          Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].childNo)

        if ( Number(tmpInprd) >= Number(tmpPmprd)) {
          // 갱신형이 아닐 경우에만
          let zzrenwCylId = GafMainFunc.getIfbcValue('ZZRENW_CYL_ID', templateId)

          // 주보험 변경에 대한 이벤트일경우 && 주보험이 아닐때  갱신형 관련 로직 체크
          //                      if("2" === Gaf.gafObject.tmodel.prodInfo.searchCriteria.inprdPmprdInqrCond.rlInfoReqScCd
          //                      && Gaf.gafObject.vmodel.PNLPQL00109_CoverageInfo[0].templateId != templateId){

          // 주보험이 아닐때는 갱신형 관련로직 체크 20170403 박세현 수정
          if (Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].templateId !== templateId) {
            if (zzrenwCylId == null || zzrenwCylId === '' || String(zzrenwCylId) === '0') {
              // 2018.01.12[전환용]어린이종신 예외처리 요청 (박영현책임)
              // if(isObj(prodTemplateId) && prodTemplateId != "E2E5L95L0000"){
              if (!(piCommonUtil.isObj(_zzentPTCLVl) && _zzentPTCLVl.search('19') > -1)) {
                if (Number(mnPmprd) >= Number(tmpPmprd)) {
                  // 콤보아이템이 중복되지 않도록
                  if (!tmp2.includes(_key)) {
                    tmp2.push(_key)
                    pmPrdTypItems.push({label: _text, key: _key})
                  }
                }
              } else {
                // 콤보아이템이 중복되지 않도록
                if (!tmp2.includes(_key)) {
                  tmp2.push(_key)
                  pmPrdTypItems.push({label: _text, key: _key})
                }
              }
            } else {
              // 콤보아이템이 중복되지 않도록
              if (!tmp2.includes(_key)) {
                tmp2.push(_key)
                pmPrdTypItems.push({label: _text, key: _key})
              }
            }
          } else {
            // 콤보아이템이 중복되지 않도록
            if (!tmp2.includes(_key)) {
              tmp2.push(_key)
              pmPrdTypItems.push({label: _text, key: _key})
            }
          }
        }
      })

      return pmPrdTypItems
    },
    /******************************************************************************
    * Function명 : fnTrtyInprd
    * 설명       : 실보험기간
    *             [원본] MSPPI080D
    ******************************************************************************/
    fnTrtyInprd (key, insured, childNo ) {
      let result = ''

      let inprdTypCd = key.split(',')[0] // 보험기간유형코드
      let inprd = key.split(',')[1] // 보험기간

      // 보험기간유형코드='02' - 세형
      if (inprdTypCd === '02') {
        for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
          if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === insured) {
            if (insured === '24') {
              if (String(childNo) === String(Gaf.getTObj('rltnrInfo')[i].zzchldSnoVl)) {
                result = Number(inprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
              }
            } else {
              result = Number(inprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
            }
          }
        }
      } else if ( inprdTypCd === '30') { // 보험기간유형코드='30' - 종피세형
        for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
          if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === '23') {
            result = Number(inprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
          }
        }
      } else {
        result = inprd
      }

      return result
    },

    /******************************************************************************
    * Function명 : fnTrtyPmprd
    * 설명       : 실납입기간
    *             [원본] MSPPI080D
    ******************************************************************************/
    fnTrtyPmprd (key, insured, childNo ) {
      let result = ''

      let pmprdTypCd = key.split(',')[0] // 납입기간유형코드
      let pmprd = key.split(',')[1] // 납입기간

      // 납입기간유형코드='02' - 세형
      if (pmprdTypCd === '02' ) {
        for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
          if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === insured) {
            if (insured === '24') {
              if (String(childNo) === String(Gaf.getTObj('rltnrInfo')[i].zzchldSnoVl)) {
                result = Number(pmprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
              }
            } else {
              result = Number(pmprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
            }
          }
        }
      } else if ( pmprdTypCd === '30') { // 납입기간유형코드='30' - 종피세형
        for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
          if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === '23') {
            result = Number(pmprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
          }
        }
      } else if ( pmprdTypCd === '40') { // 납입기간유형코드='40' - 자녀세형
        for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
          if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === '24') {
            result = Number(pmprd) - Number(Gaf.getTObj('rltnrInfo')[i].ageatentryVl)
          }
        }
      } else {
        result = pmprd
      }

      return result
    },
    /******************************************************************************
    * Function명 : l00109_sec30_grid10_rowSelectionChangeMain
    * 설명       : rowSelectionChange 메인 function
                  fdpInfinite에서 clickRow 이벤트는 selected에 push 되기 전에 emit 된다.
                  [원본] MSPPI080D
    ******************************************************************************/
    l00109_sec30_grid10_rowSelectionChangeMain (_rowData, dontClearCalcResult) {
      console.log('l00109_sec30_grid10_rowSelectionChangeMain ====>', JSON.stringify(_rowData))
      console.log('셀렉션체인지', this.selected.length, _rowData.isChecked, _rowData.seq)

      if (Gaf.getProcessNo() === '1001') {
      // 23.05 min.ko DIY 보험 예외 처리
      // ASR230500741_[상품판매준비] 소액질병납입지원특약 화면예외처리_사랑On(추가요건)_김영석_youngki78.lee
        let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
        if (_rowData.zzinscdScCd === '64' && piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('37') > -1 && _rowData.isChecked && !piCommonUtil.isEmpty2(_rowData.covInsAmt)) {
          return
        }
      }      

      // 행복종신 주보험 이전 가입금액 (체증형 특약 설정을 위한 기준_ASR230100438_행복종신 체증형보장특약 가입금액 자동설계 개발요청
      if (_rowData.prevCovInsAmtForLI0933 !== undefined) {
        _rowData.prevCovInsAmtForLI0933 = _rowData.covInsAmt
      }

      /** 20160928 yherp.kwon : 특약 필터링에 의한 rowSelectionChange 이벤트 스킵 */
      if (this.covFiltered) {
        return false
      }

      let idx = _rowData.seq

      let isCheckedAct = _rowData.isChecked

      /** 20161021 yherp.kwon : 주보험 행체크 해제 불가 */
      if (!isCheckedAct) {
        // 주보험체크해제 불가 처리
        if (_rowData.elementprodtpCd === '10' && !this.covAutoChecked && !this.covFiltered) {
          console.log('주보험 해제 불가', this.selected.length)
          _rowData.isChecked = true
          _rowData.disabled = true
          this.covFiltered = true
          this.selected.push(_rowData)
          this.covFiltered = false
          return false
        }

        if (piCommonUtil.isObj(Gaf.getTObj('prodInfo').rltnRlDtDtoList)) {
          // 주보험과 "모두동일"로 엮여있는 특약은 체크해제 불가 20170418 박세현 추가
          let _rulesA1 = Gaf.getTObj('prodInfo').rltnRlDtDtoList.filter(function (item) {
            return !piCommonUtil.isEmpty(item.zaRltnRlVal) &&
                  'A1'.search(item.zaRltnRlVal) > -1 && item.ruleYN !== 'N'
          })

          for (let i = 0; i < _rulesA1.length; i++) {
            let elj = _rulesA1[i]

            if (elj.zaObjInsrListNm.search(_rowData.uiCode) > -1 &&
                elj.zaObjInsrListNm.search(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].uiCode) > -1 ) {
              _rowData.isChecked = true
              _rowData.disabled = true
              this.covFiltered = true
              this.selected.push(_rowData)
              this.covFiltered = false
              return false
            }
          }

          // 주보험를 대상보험으로 하는 "모두선행"에 엮여있는 특약도 체크해제 불가 20170822
          let _rulesB1 = Gaf.getTObj('prodInfo').rltnRlDtDtoList.filter(function (item) {
            return !piCommonUtil.isEmpty(item.zaRltnRlVal) &&
                  'B1'.search(item.zaRltnRlVal) > -1 && item.ruleYN !== 'N'
          })

          for (let i = 0; i < _rulesB1.length; i++) {
            let elj = _rulesB1[i]
            if (elj.zaStndInsrListNm.search(_rowData.uiCode) > -1 &&
                elj.zaObjInsrListNm.search(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].uiCode) > -1 ) {
              _rowData.isChecked = true
              _rowData.disabled = true
              this.covFiltered = true
              this.selected.push(_rowData)
              this.covFiltered = false
              return false
            }
          }
        }

        // 적립(47), 납면(48) 인 경우에는 행체크 해제가 불가함. 20170725 박세현 수정
        if (_rowData.zzinscdScCd === '47' || _rowData.zzinscdScCd === '48' ) {
          _rowData.isChecked = true
          _rowData.disabled = true
          this.covFiltered = true
          this.selected.push(_rowData)
          this.covFiltered = false
          return false
        }

        // 20170711 전환실손상품 특약은 행체크 해제 불가(단, 의료실손상세속성코드 T3, T4, T5는 제외(비급여 도수치료, 비급여 주사료, 비급여 MRI/MRA) ==> lineCd가 없는것들 ):가상고객제외
        // 2021.07.20_ASR210600870_[상품판매준비] 4세대 전환실손 가입설계화면 개발
        if (piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) && Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('18') > -1) {
          let _rltnrInfo_21 = Gaf.getTObj('rltnrInfo').filter(function ( item ) { return item.zzcontvInsrdCd === '21' })[0] // 주피
          if (_rltnrInfo_21.zaVcustYn !== 'Y') {
            // if (!piCommonUtil.isEmpty2(_rowData.lineCd)) {
            if (!(_rowData.lineCd === 'AE04' && !Gaf.getVObj('ZRldmContCnvrDto').thNslryObadFg)) {
              console.log('전환실손상품 특약 해제 불가')
              _rowData.isChecked = true
              _rowData.disabled = true
              this.covFiltered = true
              this.selected.push(_rowData)
              this.covFiltered = false
              return false
            }
          }
        }
      }
      
      // 23.05 min.ko DIY 관련 예외 처리
      // ASR230500741_[상품판매준비] 소액질병납입지원특약 화면예외처리_사랑On(추가요건)_김영석_youngki78.lee
      // DIY보험에서 납입지원특약 외 행 체크/해제가 있는 경우
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      if (_rowData.zzinscdScCd !== '64') {
        if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('37') > -1) {
          let gridBindingObj = Gaf.getVObj('PNLPQL00109_CoverageInfo')
          if (piCommonUtil.isObj(gridBindingObj) && gridBindingObj.length > 0) {
            gridBindingObj.forEach((el, i) => {
              if (el.zzinscdScCd === '64') {
                el.covInsAmt = '' // 환산성적
              }
            })
          }
        }
      }

      // [TO-DO] 선심사결과에서 아래의 조건이 필요한지 확인 필요함
      // fn_OnChangeBaseContDto : MSPPI001M에 존재함
      if ((Gaf.getMainPage()['fn_OnChangeBaseContDto'] && !dontClearCalcResult) || ( isCheckedAct && _rowData.GafProductInfo.zzinscdScCd === '62') || ( isCheckedAct && _rowData.GafProductInfo.zzinscdScCd === '64')) {
        // 22.06 min.ko ASR220600578 비갱신암 환급특약 화면 예외처리_EP : 비갱신 암보험. 주보험&환급특약 이외 특약 가입금액 수정 시 가입금액 초기화
        // >> 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
        // if (_rowData.GafProductInfo.zzinscdScCd !== '64') {
        let gridBindingObj = Gaf.getVObj('PNLPQL00109_CoverageInfo')
        if (piCommonUtil.isObj(gridBindingObj) && gridBindingObj.length > 0) {
          gridBindingObj.forEach((el, i) => {
            if (el.GafProductInfo.zzinscdScCd === '64') {
              el.covInsAmt = ''
            }
          })
        }
        // }
        // [선심사 및 영업시스템 고도화PJT] 기본계약사항 변경 화면 X
        // Gaf.getMainPage()['fn_OnChangeBaseContDto']()
      }

      /** 20180130 yherp.kwon : 어린이종신전환 보험종류 3년실속형일때, 가입 가능 특약 체크 */
      let _rltnrInfo_21 = Gaf.getTObj('rltnrInfo').filter(function ( item ) { return item.zzcontvInsrdCd === '21' })[0] // 주피
      if (isCheckedAct && !this.covAutoChecked && _rltnrInfo_21.zaVcustYn !== 'Y' &&
          piCommonUtil.isObj(Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl) &&
          Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl.search('19') > -1 &&
          Gaf.getTObj('baseContDto').zzentplLineCd === 'T5' &&
          piCommonUtil.isObj(_rowData.type) && _rowData.type === 'T5' &&
          (piCommonUtil.isEmpty2(_rowData.elementprodtpCd) || _rowData.elementprodtpCd !== '10')) {
        let oldInsCds = ['I19202', 'I19207', 'I19212', 'I19217', 'I19222', 'I19227']
        let mappedCds = ['AC01', 'AC02', 'AC03', 'AC04', 'AC05', 'AC06']
        let korNames = ['종합입원', '종합통원', '질병입원', '질병통원', '상해입원', '상해통원']

        let mappedCdIdx = mappedCds.indexOf(_rowData.lineCd)

        if (mappedCdIdx < 0) {
          let message = `3년실속형 특약 파라메터 담보종목코드 오류(${_rowData.lineCd})`
          piCommonUtil.getFDPConfirm('s', message)
          return false
        }

        let threeYearIns = []
        Gaf.getVObj('ZRldmContCnvrDto').zRldmContCnvrListDto.forEach((el, i) => {
          if (!threeYearIns.includes(el.zzrmvlInscdCD)) {
            threeYearIns.push(el.zzrmvlInscdCD)
          }
        })

        // 3년실손 선택특약이 이전보험코드중에 없다면...
        // 종합입원 가입했던 사람은 종합입원, 질병입원, 상해입원 가능
        // 종합통원 가입했던 사람은 종합통원, 질병통원, 생해통원 가능
        if (threeYearIns.indexOf(oldInsCds[mappedCdIdx]) < 0) {
          if (!(((_rowData.lineCd === 'AC03' || _rowData.lineCd === 'AC05') && threeYearIns.indexOf('I19202') > -1) ||
                  ((_rowData.lineCd === 'AC04' || _rowData.lineCd === 'AC06') && threeYearIns.indexOf('I19207') > -1))) {
            let message = '해당 계약은'
            threeYearIns.forEach((el, i) => {
              message = message + korNames[oldInsCds.indexOf(el)] + '형, '
            })

            message = message.substring(0, message.lastIndexOf(','))

            message = message + '만 가입이 가능합니다.'

            _rowData.isChecked = false
            this.fnRowSelectionChange()
            piCommonUtil.getFDPConfirm('s', message)
            return false
          }
        }
      }

      // 행 체크시 보기납기 세팅처리함수 20160929 박세현
      if ( isCheckedAct && piCommonUtil.isObj(Gaf.getTObj('prodInfo').inprdPmprdList) && Gaf.getTObj('prodInfo').inprdPmprdList.length > 0) {
        let _result = this.fnCheckedRowSetInprdPmprd(idx)
        if (_result === false) {
          return
        }
      }

      // 행체크 이벤트일때,
      // 선택가능한 보험기간이 "9" 불가 일때는 행 체크해주지 않고, alert 메시지 보녀주고 끝냄. 김민지 책임님 요청. 20170828 박세현 추가
      if ( isCheckedAct &&
      piCommonUtil.isObj(_rowData.inPrdTypItems) && _rowData.inPrdTypItems[_rowData.inPrdTypItems.length - 1].key === '9' &&
      Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected.key !== '') {
        _rowData.isChecked = false
        this.fnRowSelectionChange()

        let message = `[${_rowData.relTyp}:${_rowData.name}] 가입불가한 설계조건(성별,나이,보기,납기 등)입니다. 해당 상품예규를 확인하시기 바랍니다.`
        piCommonUtil.getFDPConfirm('s', message)
        // [TO-DO] 선심사 결과에서는 금액입력 팝업 변경으로 변경 필요
        // this.$nextTick(() => {
        //   this.isShowMSPPI082P = false
        // })        

        return false
      }

      /** 20160519 yherp.kwon : 체크해제시 특약정보 초기화 */
      if (!isCheckedAct) {
        console.log('행체크 해제')

        _rowData.covInsAmt = '' // 보험가입금액

        // 보기와 납기가 불가처리된 경우의 행 체크해제처리는 보기납기를 초기화하지 않는다.
        if ( _rowData.inprdTypSelected.key !== '9' || _rowData.pmprdTypSelected.key !== '9' ) {
          _rowData.inprdTypSelected = {key: '', label: ''} // 보험기간
          _rowData.pmprdTypSelected = {key: '', label: ''} // 납입기간
          _rowData.pmPrdTypItems.length = 0 // 납입기간콤보아이템:보험기간선택시 동적으로 아이템이 생성됨
        }

        _rowData.covPrm = '' // 보험료

        // 가입금액 및 보기납기 enable 처리
        // _rowData.isEnabled_covInsAmt = true
        // _rowData.isEnabled_inprdTypSelected = true
        // _rowData.isEnabled_pmprdTypSelected = true

        // 2019.07 HY 태아보험의 적립형 특약 및 파라미터개인가족구분코드가 06인경우 체크해제시에도 가입금액 보기납기 비활성화한다(최은비선임요청)
        let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
        let checkchildValidate = false
        if (zzptclrPrdTId === '29') {
          if (_rowData.zzinscdScCd === '60' || _rowData.GafProductInfo.zzprmtrPFSCd === '06') {
            checkchildValidate = true
          }
        }
        // 가입금액 및 보기납기 enable 처리
        if (checkchildValidate) {
          _rowData.isEnabled_covInsAmt = false
          _rowData.isEnabled_inprdTypSelectedKey = false
          _rowData.isEnabled_pmprdTypSelectedKey = false
        } else {
          _rowData.isEnabled_covInsAmt = true
          _rowData.isEnabled_inprdTypSelectedKey = true
          _rowData.isEnabled_pmprdTypSelectedKey = true
        }

        // 사용자가 직접 체크한 행에 대해서만 관계규칙 체크
        if (this.covAutoChecked) return true

        // 2019.09 원효영프로요청
        // let _zaRltnRlVals = 'A1;1B'
        // TODO: TEST위하여 추가
        let _zaRltnRlVals = 'A1;1A;B1;1B'
        // [TO-DO] 선심사PJT 로직추가 필요한가 확인필요함
        // fnCheckRltnRlDt ==> 관계규칙체크 로직!!!
        // 관계규칙 위배 메시지창
        // this.fnCheckRltnRlDt(_rowData, _zaRltnRlVals)

        return true
      }

      /** 20160627 yherp.kwon : 체크시, 특약안내영역 메세지 표시 */
      // 가입가능금액 범위에 가입배수 체크로직 추가. 박영현 요청 - 20170220 박세현 수정

      let tmpStndAmt = 0 // 기준금액
      let zaAplcCalcTypVal = null // 적용계산유형

      let _rules = null

      if (piCommonUtil.isObj(Gaf.getTObj('prodInfo')) && piCommonUtil.isObj(Gaf.getTObj('prodInfo').rltnRlDtDtoList)) {
        // 체크대상 관계규칙 추출
        _rules = Gaf.getTObj('prodInfo').rltnRlDtDtoList.filter(function (item) {
          return !piCommonUtil.isEmpty(item.zaRltnRlVal) &&
                              'F1'.search(item.zaRltnRlVal) > -1 && item.ruleYN !== 'N'
        })

        if (piCommonUtil.isObj(_rules) && _rules.length > 0) {
          // 관계규칙 체크
          let _uiCode = _rowData.uiCode
          let tmpArr = null
          let tmpArr2 = null
          let elkAmt = null
          let elkAmtExceptNum = 0

          _rules.forEach((elj, j) => {
            // 선택한 보험이 대상보험중에 있을때, 대상보험이 하나인 경우에만 체크
            if ( elj.zaObjInsrListNm.search(_uiCode) > -1 && elj.zaObjInsrListNm.search('[|][|]') < 0 ) {
              // 기준금액 계산
              tmpArr = elj.zaStndInsrListNm.split('||') // 기준보험
              tmpArr2 = elj.zaStndInsrCalcVal.split('||') // 기준보험가입배수
              elkAmt = [] // 기준금액배수
              elkAmtExceptNum = 0

              tmpArr.forEach((elk, k) => {
                /** 기준보험 List에 숫자가 들어있을때, elkAmt List에 숫자값도 추가해줄 수 있도록 수정. 박영현대리님 요청 - 20170103 박세현 추가**/
                if (piCommonUtil.isNumber(elk)) {
                  elkAmt.push(piCommonUtil.NumCal( elk, tmpArr2[k], '*') )
                } else { // uiCode명 이면
                  Gaf.getVObj('PNLPQL00109_CoverageInfo').forEach((ell, l) => {
                    if (elk === ell.uiCode && ell.isChecked) { // 확인필요. 체크안되어 있는것도 계산대상으로 넣어야 하는지?
                      /** 20160628 yherp.kwon : 가입금액계산규칙 적용 */
                      elkAmt.push(piCommonUtil.NumCal(this.fnCalcRLCovInsAmt(ell), tmpArr2[k], '*'))
                      elkAmtExceptNum = elkAmtExceptNum + this.fnCalcRLCovInsAmt(ell)
                    }
                  })
                }
              })

              // 숫자를 제외한, 특약의 가입금액이 유효하지 않을때는 자동세팅해주지 않음
              if (elkAmtExceptNum > 0) {
                // 기준금액 계산유형에 따라 최종 대상금액 계산
                tmpStndAmt = this.fnCalcTypVal(elkAmt, elj.zaStndListCalcTypVal)
                // 기준금액에 적용숫자 배수 적용
                tmpStndAmt = piCommonUtil.NumCal(tmpStndAmt, elj.zaMulVal, '*')

                zaAplcCalcTypVal = elj.zaAplcCalcTypVal
              }
            }
          })
        }

        if (zaAplcCalcTypVal != null && tmpStndAmt > 0 ) {
          if (zaAplcCalcTypVal === '1') { // 동일
            // 실제 금액이 넘어오므로, 가입금액 표시 단위에 맞게 처리해줌- 20170525 박세현 수정
            if (_rowData.unitNm === '만원') {
              _rowData.covInsAmt = tmpStndAmt / 10000
            } else if (_rowData.unitNm === '천원') {
              _rowData.covInsAmt = tmpStndAmt / 1000
            } else if (_rowData.unitNm === '원') {
              _rowData.covInsAmt = tmpStndAmt
            }
          }
        }
      }

      // 위치 수정 20170819 박세현 수정
      //           oPanel.l00109_sec30_textview10.setText(GafMainFunc.makeCovInfo(_rowData,tmpStndAmt,zaAplcCalcTypVal));
      // 메시지 조합은 직접 체크한 행에 대해서만 수행하고, calmin, calmax 는 수행한다. 20170824 수정
      if (piCommonUtil.isObj(_rowData.GafProductInfo)) {
        GafMainFunc.calMinCovInsAmt(_rowData.GafProductInfo, tmpStndAmt, zaAplcCalcTypVal )
        GafMainFunc.calMaxCovInsAmt(_rowData.GafProductInfo, tmpStndAmt, zaAplcCalcTypVal )
      } else {
        GafMainFunc.calMinCovInsAmt(_rowData, tmpStndAmt, zaAplcCalcTypVal )
        GafMainFunc.calMaxCovInsAmt(_rowData, tmpStndAmt, zaAplcCalcTypVal )
      }

      // MIN=MAX인 경우 가입금액을 클릭하면, 해당금액을 가입단위로 나누어서 표시함 보험가입금액에 바로 표시
      // calMin 이나 calMax가 존재하면, calMin, calMax 기준으로 계산 - 20170317 박세현
      if (_rowData.GafProductInfo.calMin !== null) {
        if (_rowData.GafProductInfo.calMin === _rowData.GafProductInfo.calMax) {
          // calMin과 calMax는 "만원"단위임. "천원"단위거나 "원" 단위라면 세팅금액에 각각 10과 10000을 곱해줌. 20170525 박세현 수정
          if (_rowData.unitNm === '천원') {
            _rowData.covInsAmt = _rowData.GafProductInfo.calMin * 10
          } else if (_rowData.unitNm === '원') {
            _rowData.covInsAmt = _rowData.GafProductInfo.calMin * 10000
          } else {
            _rowData.covInsAmt = _rowData.GafProductInfo.calMin
          }

          // 가입배수관련 체크 위배사항이 없을때만 자동세팅 해준다.
          let _zaRltnRlVals = 'F1;1F'
          // [TO-DO] 로직추가 필요한가 확인필요함
          // if ( !this.fnCheckRltnRlDt(_rowData, _zaRltnRlVals) ) {
          //   _rowData.covInsAmt = ''
          // }
        }
      } else {
        if (!piCommonUtil.isEmpty2(_rowData.GafProductInfo.min)) {
          if (Number(_rowData.GafProductInfo.min) === Number(_rowData.GafProductInfo.max)) {
            _rowData.covInsAmt = GafMainFunc.setUnitAmt(_rowData.GafProductInfo.min, _rowData.unit)

            // 가입배수관련 체크 위배사항이 없을때만 자동세팅 해준다.
            let _zaRltnRlVals = 'F1;1F'
            // [TO-DO] 로직추가 필요한가 확인필요함
            // if ( !this.fnCheckRltnRlDt(_rowData, _zaRltnRlVals) ) {
            //   _rowData.covInsAmt = ''
            // }
          }
        }
      }

      // 그리드 행체크시 체크대상 관계규칙 추출
      // 사용자가 직접 체크한 행에 대해서만 관계규칙 체크
      if (this.covAutoChecked) return true

      // 사용자가 직접 체크한 행에 대해서 상단 text 세팅
      _rowData.padCovRuleText = GafMainFunc.makeCovInfo(_rowData, tmpStndAmt, zaAplcCalcTypVal)
      console.log('l00109_sec30 padCovRuleText ===> ', _rowData.padCovRuleText)

      // [NEXT 사랑On] 원샷보험료 계산 처리
      // this.fn_calcAllPrem()

      // 행체크했을때에만  관계규칙 체크
      if (!isCheckedAct) return true

      // 체크대상룰 추출 : A1모두동행/B1모두선행/C1모두배타/C2일부배타
      let _zaRltnRlVals = 'A1;B1;C1;C2'

      // [TO-DO] 로직추가 필요한가 확인필요함
      // 20160428 yherp.kwon : 관계규칙체크적용
      // if (!this.fnCheckRltnRlDt(_rowData, _zaRltnRlVals)) {
      //   return false
      // }
    }, /// /특약 그리드의 행 선택 변경 이벤트처리 End
    /******************************************************************************
    * Function명 : fnCheckedRowSetInprdPmprd
    * 설명       : 행 체크시 보기납기 데이터 세팅
    *              [원본] MSPPI080D
    ******************************************************************************/
    fnCheckedRowSetInprdPmprd (idx) {
      let _rowData = this.items[idx]
      /* 해당 보험대상자를 선택하지 않고, 체크표시 or 가입금액 or 보기 or 납기 or 보험료 or 보장 中 하나라도 클릭시 고객정보체크
         이상윤 선임님 요청 - 20160719 박세현 */
      if (_rowData.isChecked) {
        // 고객정보 체크
        let chkInsrdCd = false
        Gaf.getTObj('rltnrInfo').forEach((el, i) => {
          if ( el.zzcontvInsrdCd === _rowData.insured ) {
            if ( _rowData.insured === '24') {
              if (String(el.zzchldSnoVl) === String(_rowData.childNo)) {
                chkInsrdCd = true
              }
            } else {
              chkInsrdCd = true
            }
          }
        })

        if (!chkInsrdCd) {
          let t_processUnChecked = false
          if (_rowData.insured === '23') {
            piCommonUtil.getFDPConfirm('s', '선택된 [종피보험자]정보가 존재하지 않습니다.')
            t_processUnChecked = true
          } else if (_rowData.insured === '24') {
            if (String(_rowData.childNo) === '1') {
              piCommonUtil.getFDPConfirm('s', '선택된 [자녀1]정보가 존재하지 않습니다.')
              t_processUnChecked = true
            } else if (String(_rowData.childNo) === '2') {
              piCommonUtil.getFDPConfirm('s', '선택된 [자녀2]정보가 존재하지 않습니다.')
              t_processUnChecked = true
            } else if (String(_rowData.childNo) === '3' ) {
              piCommonUtil.getFDPConfirm('s', '선택된 [자녀3]정보가 존재하지 않습니다.')
              t_processUnChecked = true
            }
          }

          if (t_processUnChecked) {
            this.items[idx].isChecked = false
            for (let i = 0; i < this.selected.length; i++) {
              if (this.selected[i].seq === this.items[idx].seq) {
                this.selected.splice(i, 1) // 선택 해제
                break
              }
            }
          }
          return false
        }
        // subInsured값이 존재할 시 해당 값에 대한 고객정보가 존재하는지 체크. 이상윤 선임님 요청 - 20160719 박세현
        let chkSubInsrdCd = false
        if ( _rowData.subInsured !== null && _rowData.subInsured !== '' && _rowData.subInsured.length > 0 ) {
          for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
            if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === _rowData.subInsured ) {
              chkSubInsrdCd = true
            }
          }

          if (!chkSubInsrdCd) {
            if (_rowData.subInsured === '23') {
              if (!this.showCheckSubinsr) {
                this.checkMessageSubinsr = '선택된 [종피보험자]정보가 존재하지 않습니다.'
                this.showCheckSubinsr = true
              }
              this.items[idx].isChecked = false
              for (let i = 0; i < this.selected.length; i++) {
                if (this.selected[i].seq === this.items[idx].seq) {
                  this.selected.splice(i, 1) // 선택 해제
                  break
                }
              }
            }
            return false
          }
        }
      }

      // 행 체크시 선택가능한 보기납기 최하위 데이터를 기본으로 세팅 - 20160711 박세현
      if ( this.items[idx].isChecked) {
        if (_rowData.inPrdTypItems.length >= 1 && (_rowData.inprdTypSelected.key === '' || _rowData.inprdTypSelected.key === '9')) {
          _rowData.inprdTypSelected = _rowData.inPrdTypItems[_rowData.inPrdTypItems.length - 1]
          /* 보험코드구분코드가 18, 48인 경우에는 주보험의납기 = 해당특약 보기 = 해당특약 납기
             보험코드구분코드가 47인 경우에는 해당특약 보기=주보험보기, 납기=주보험납기  이상윤대리님 요청 - 20160901 박세현 */
          // 태아보험의 경우 상품코드가 주보험과 동일한 특약이 존재하므로 동일하더라도 태아구분코드 가지고있으면 처리가능하도록 추가함 (박희윤)
          var checkSet = 'N'
          if (_rowData.zzinscdScCd === '59') {
            let vmodel = Gaf.getObj('vmodel')
            if (vmodel.PNLPQL00109_CoverageInfo[0].insCode === _rowData.insCode) {
              checkSet = 'Y'
            }
          }
          if (( !this.fnZzinscdScCd184847( _rowData ) && Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].insCode !== _rowData.insCode ) || (checkSet === 'Y')) {
            if ( _rowData.inprdTypSelected.key === '9' || _rowData.inprdTypSelected.key === '' ) {
              // 보험기간이 불가 세팅되었다면 납입기간도 불가 세팅
              let tmpItems = []
              tmpItems.push( {'key': '9', 'label': '불가'})
              _rowData.pmPrdTypItems = tmpItems.slice()
              _rowData.pmprdTypSelected = {'key': '9', 'label': '불가'}
            } else {
              let pmprdSet = this.makePmPrdTypItems(_rowData.uiCode, _rowData.inprdTypSelected.key.split(',')[0], _rowData.inprdTypSelected.key.split(',')[1], _rowData.insured, _rowData.childNo, _rowData.templateId)
              if (pmprdSet.length >= 1 ) {
                // 납기동일 관련 특약은 기존에 선택된 납기동일 데이터가 있을 경우 그 데이터를 세팅해줌 - 20170214 박영현 대리님 요청
                let _zaRltnRlVals = 'D2' // 납기동일
                let _rules = null
                // 체크대상 관계규칙 추출
                if ( piCommonUtil.isObj( Gaf.getTObj('prodInfo').rltnRlDtDtoList)) {
                  _rules = Gaf.getTObj('prodInfo').rltnRlDtDtoList.filter( function (item) { return !piCommonUtil.isEmpty(item.zaRltnRlVal) && _zaRltnRlVals.search(item.zaRltnRlVal) > -1 && item.ruleYN !== 'N' })
                }
                // 체크대상룰 리스트가 있으면 체크
                if (piCommonUtil.isObj(_rules) && _rules.length > 0) {
                  let _uiCode = _rowData.uiCode
                  // for ( let j in _rules) {
                  for ( let j = 0; j < _rules.length; j++) {
                    if (_rules[j].zaObjInsrListNm.search(_uiCode) > -1) { // 선택한 보험이 대상보험중에 있으면,
                      let _pmprdTypSelected = '-1'
                      for ( let k in Gaf.getVObj('PNLPQL00109_CoverageInfo')) {
                        // 대상보험 중 체크되어있고, 유효한 납입기간이 세팅되어 있는 값을 찾음
                        if (Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].uiCode !== _uiCode &&
                            (_rules[j].zaObjInsrListNm.search(Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].uiCode) > -1) &&
                            Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].isChecked &&
                            Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].pmprdTypSelected.key !== '' &&
                            Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].pmprdTypSelected.key !== '9') {
                          _pmprdTypSelected = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[k].pmprdTypSelected)
                          break
                        }
                      }
                      // -1 이면 납기동일 특약중 유효한 값이 세팅된 데이터가 없다는 말이므로, 가장 하위 데이터를 세팅해줌
                      if (_pmprdTypSelected === '-1') {
                        _rowData.pmprdTypSelected = this.getMaxPmprdKey( pmprdSet, _rowData.insured, _rowData.childNo )
                        _rowData.pmPrdTypItems = pmprdSet.slice()
                      } else {
                        let chkPmprd = false
                        for (let i in pmprdSet) {
                          if (pmprdSet[i].key === _pmprdTypSelected.key) {
                            chkPmprd = true
                          }
                        }
                        // 납기동일의 값이 선택가능하면 세팅, 선택불가하면 "" 세팅
                        if (chkPmprd) {
                          _rowData.pmprdTypSelected = _pmprdTypSelected
                          _rowData.pmPrdTypItems = pmprdSet.slice()
                        } else {
                          _rowData.pmprdTypSelected = {key: '', label: ''} // TODO 라벨명
                          _rowData.pmPrdTypItems = pmprdSet.slice()
                        }
                      }
                      // 납기동일 관련 체크가 1회 끝났다면 return;
                      return true
                    } else { // 납기동일과 관련이 없다면 맨 하위데이터 세팅.
                      // _rowData.pmprdTypSelected.key = pmprdSet[pmprdSet.length-1].key;
                      _rowData.pmprdTypSelected = this.getMaxPmprdKey( pmprdSet, _rowData.insured, _rowData.childNo )
                      _rowData.pmPrdTypItems = pmprdSet.slice()
                    }
                  }
                } else { // 납기동일 룰 자체가 없다면 맨 하위데이터 세팅
                  // _rowData.pmprdTypSelected.key = pmprdSet[pmprdSet.length-1].key;
                  _rowData.pmprdTypSelected = this.getMaxPmprdKey( pmprdSet, _rowData.insured, _rowData.childNo )
                  _rowData.pmPrdTypItems = pmprdSet.slice()
                }
              }
            }
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : getMaxPmprdKey
    * 설명       : itemSet에서 가장 큰 실납입기간을 찾아서 return 20170816 박세현 추가
    * [원본] MSPPI080D
    ******************************************************************************/
    getMaxPmprdKey ( itemSet, insured, childNo ) {
      let maxPmprdKey = ''
      let maxTrTyPmprd = 0

      for ( let i = 0; i < itemSet.length; i++ ) {
        let tmpPmprdKey = itemSet[i]
        let tmpTrtyPmprd = this.fnTrtyPmprd(tmpPmprdKey.key, insured, childNo)

        // 일시납일때
        if (tmpPmprdKey.key === '00,0') {
          maxPmprdKey = tmpPmprdKey
          break
        } else { // 일시납이 아닐때
          if (Number(tmpTrtyPmprd) >= Number(maxTrTyPmprd)) {
            maxTrTyPmprd = tmpTrtyPmprd
            maxPmprdKey = tmpPmprdKey
          }
        }
      }

      return maxPmprdKey
    },
    /******************************************************************************
    * Function명 : fnCalcIncinfrdendYmd
    * 설명       : 최대조정기간
    ******************************************************************************/
    fnCalcIncinfrdendYmd (maxAge, inprdVl, stndYmd) {
      let result = ''
      let maxPrd = Math.min(maxAge, inprdVl)
      // 8자리 날짜로 변환
      let date8 = GafMainFunc.convertDateToLength8(stndYmd)

      let tempStndYear = date8.substr(0, 4)
      let tempStndDate = date8.substr(date8.length - 4, 4)

      let tempCalcYear = Number(tempStndYear) + Number(maxPrd)

      if (('' + tempCalcYear + tempStndDate) > '29980322') {
        result = '29980322' // SAP max 일자로 셋팅
      } else {
        if (tempStndDate === '0229') {
          if (!((tempCalcYear % 4 === 0) && (tempCalcYear % 100 !== 0) || (tempCalcYear % 400 === 0))) {
            tempStndDate = '0228'
          }
        }
        result = '' + tempCalcYear + tempStndDate
      }

      return result
    },
    /******************************************************************************
    * Function명 : fnRowSelectionChange
    * 설명       : 그리드 행 체크,해제 처리
    *              [원본] MSPPI080D
    ******************************************************************************/
    fnRowSelectionChange () {
      this.covAutoChecked = true
      this.items.forEach((el, i) => {
        let t_checked = this.selected.filter(function (item) { return item.seq === el.seq })
        if (el.isChecked) {
          // 행체크
          if (!(t_checked && t_checked.length > 0)) {
            this.selected.push(el)
            this.l00109_sec30_grid10_rowSelectionChangeMain(el)
            console.log('fnRowSelectionChange l00109_sec30 1 ==> ', i)
          }
        } else {
          // 행 체크해제시에도 autoChecked 체크 기능을 추가 (묶음특약 기능관련에서 필요) 20170418 박세현 추가
          if (t_checked && t_checked.length > 0) {
            let t_idx = this.selected.indexOf(t_checked[0])

            console.log('[MSPPI080D] fnRowSelectionChange ------------->')
            
            if(el.calcError){

              el.calcError = false
            }
            
            this.selected.splice(t_idx, 1)            

            this.l00109_sec30_grid10_rowSelectionChangeMain(el)
          }
        }
      })
      this.covAutoChecked = false

      /** 201907 태아보험 적립형 체크해제 체크시 가입금액 입력되지않도록 변경 */
      /** let gridBindingObj = Gaf.getVObj('PNLPQL00109_CoverageInfo')
      gridBindingObj.forEach((el, i) => {
        if (Gaf.getPanel('MSPPI001M')) {
          let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
          let zzprmtrPFSCd = el.zzprmtrPFSCd
          let zzinscdScCd = el.zzinscdScCd
          if (zzptclrPrdTId === '29') {
            if (zzprmtrPFSCd === '06' || zzinscdScCd === '60') {
              // tmpCovObj.isChecked = true
              // tmpCovObj.isEnabled_covInsAmt = false
              // tmpCovObj.isEnabled_inprdTypSelectedKey = false
              // tmpCovObj.isEnabled_pmprdTypSelectedKey = false
            }
          }
        }
      }) */

      // [NEXT 사랑On] 원샷보험료 계산 처리
      // this.fn_calcAllPrem()
    },
    /******************************************************************************
    * Function명 : fnZzinscdScCd184847
    * 설명       : 보험코드구분코드가 18, 48인 경우에는 주보험의납기 = 해당특약 보기 = 해당특약 납기
                     -> 보험코드구분코드가 47인 경우에는
                        해당특약 보기=주보험보기, 납기=주보험납기 이상윤대리님 요청 - 20160901 박세현
                   >> 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
                     -> 보험코드구분코드가 64인 경우
                        해당 특약 보기 = max(65세, 주보험 납기) : 주보험의 납기가 해당 특약 보기 콤보에 있으면 주보험의 납기, 없으면 보기유형코드가 '세형'인 값 세팅
                        해당 특약 납기 = 주보험 납기
                  [원본] MSPPI080D
    ******************************************************************************/
    fnZzinscdScCd184847 ( _rowData ) {
      let result = false
      let zzinscdScCd = _rowData.zzinscdScCd

      // GI plus 종신 관련 로직
      let zzentPTCLVl = Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl // 가설예시특이유형코드

      // ASR240500456 [상품판매준비] 내인생플러스 가입설계 화면 개발_사랑온 - 보험구분코드(65)
      if (piCommonUtil.isObj(zzentPTCLVl) && zzentPTCLVl.search('RN') > -1 && zzinscdScCd === '65') {
    	  _rowData.isEnabled_covInsAmt = false
        	return false
      }

      // let inprdTyp = _rowData.inprdTypSelectedKey
      let GI_PLUS = false
      // 22.03 갤럭시 환급특약 관련 개발계 선반영 요청에 따른 가설예시특이유형코드 수정 : 변경전 29, 변경후 A1(GI환급특약예외처리)/ young21.kwon
      // 2022.4.5 / ASR220300794 / 건강증진보험 6대환급특약 화면 예외처리(보기납기발행화면) / youngki78.lee: '29' > 'A1'
      // if (piCommonUtil.isObj(zzentPTCLVl) && zzentPTCLVl.search('29') > -1) {
      if (piCommonUtil.isObj(zzentPTCLVl) && zzentPTCLVl.search('A1') > -1) {
        GI_PLUS = true
      }

      // 보험코드구분코드 확인
      // 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
      if (zzinscdScCd === '18' || zzinscdScCd === '48' || zzinscdScCd === '62' || zzinscdScCd === '64') {
        result = true

        // 보장적립일 경우만 disable 처리
        if (zzinscdScCd === '48') {
          // 가입금액 및 보기납기 disable 처리
          _rowData.isEnabled_covInsAmt = false
          _rowData.isEnabled_inprdTypSelected = false
          _rowData.isEnabled_pmprdTypSelected = false
        }

        if (GI_PLUS || zzinscdScCd === '64') {
          let inprdSet = this.makeInPrdTypItems(_rowData.uiCode, _rowData.insured, _rowData.childNo)

          let chk = false
          // 주보험의 납입기간이 선택보험의 보험기간 선택목록에 있을 경우에만 세팅
          for (let i = 0; i < inprdSet.length; i++) {
            let PNLPQL00109_CoverageInfo0 = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0]
            if (inprdSet[i].key === PNLPQL00109_CoverageInfo0.pmprdTypSelected.key) {
              chk = true
            }
          }

          // itemSet도 이것만 남김
          let tmpItems = []

          if (chk) {
            _rowData.inprdTypSelected = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected)
            // 주보험의 납입기간 item
            let mnPmPrdTypItems = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmPrdTypItems)

            // pmprdTypeText 구하기 위해 주보험 납입기간 찾아보기
            mnPmPrdTypItems.forEach((el, i) => {
            // for (let i = 0; i < mnPmPrdTypItems.length; i++) {
              let PNLPQL00109_CoverageInfo0 = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0]
              if (mnPmPrdTypItems[i].key === PNLPQL00109_CoverageInfo0.pmprdTypSelected.key) {
                tmpItems.push({key: mnPmPrdTypItems[i].key, label: mnPmPrdTypItems[i].label} )
              }
            })

            _rowData.inPrdTypItems = []
            _rowData.inPrdTypItems = tmpItems.slice()
          } else {
            // 24.01 min.ko
            // 2025.01 ASR241200354 / [상품판매준비] 더블보장보험 가입설계 화면 개발_사랑On (LP0778 ,LP0780)
            if (['LI1147', 'LI1148', 'LI1596', 'LI1597', 'LI1598', 'LI1599'].indexOf(_rowData.insCode.substring(0,6)) < 0) { // ASR240300980 [상품판매준비] 생애보장보험 가입설계 화면 로직 보완 _사랑온  / 박재우
              for (let i = 0; i < inprdSet.length; i++) {
                let el = inprdSet[i]              
                if (el.key && el.key.search('02') > -1) {
                  tmpItems.push({key: el.key, label: el.label} )
                  _rowData.inprdTypSelectedKey = el.key
                  _rowData.inprdTypSelected = {key: el.key, label: el.label}
                  break
                }
              }
              if (tmpItems.length === 0) {
                tmpItems.push({key: '9', label: '불가'} )
                _rowData.inPrdTypItems = tmpItems.slice()
                _rowData.inprdTypSelectedKey = '9'
                _rowData.inprdTypSelected = {key: '9', label: '불가'}
              }            
              _rowData.inPrdTypItems = []
              _rowData.inPrdTypItems = tmpItems.slice()
            }
          }
        } else {
          let inprdSet = this.makeInPrdTypItems(_rowData.uiCode, _rowData.insured, _rowData.childNo)

          let chk = false
          // 주보험의 납입기간이 선택보험의 보험기간 선택목록에 있을 경우에만 세팅
          for (let i = 0; i < inprdSet.length; i++) {
            let PNLPQL00109_CoverageInfo0 = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0]
            if (inprdSet[i].key === PNLPQL00109_CoverageInfo0.pmprdTypSelected.key) {
              chk = true
            }
          }

          // itemSet도 이것만 남김
          let tmpItems = []

          if (chk) {
            _rowData.inprdTypSelected = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected)
            // 주보험의 납입기간 item
            let mnPmPrdTypItems = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmPrdTypItems)

            // pmprdTypeText 구하기 위해 주보험 납입기간 찾아보기
            for (let i = 0; i < mnPmPrdTypItems.length; i++) {
              let PNLPQL00109_CoverageInfo0 = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0]
              if (mnPmPrdTypItems[i].key === PNLPQL00109_CoverageInfo0.pmprdTypSelected.key) {
                tmpItems.push({key: mnPmPrdTypItems[i].key, label: mnPmPrdTypItems[i].label} )
              }
            }

            _rowData.inPrdTypItems = []
            _rowData.inPrdTypItems = tmpItems.slice()
          } else {
            tmpItems.push({key: '9', label: '불가'} )

            _rowData.inPrdTypItems = tmpItems.slice()
            _rowData.inprdTypSelected = {key: '9', label: '불가'}
          }
        }

        if (zzinscdScCd === '62' || zzinscdScCd === '64') {
          if (piCommonUtil.isObj(_rowData.covInsAmt) && _rowData.covInsAmt.toString.length > 0) {
            _rowData.isEnabled_covInsAmt = true
          } else {
            _rowData.isEnabled_covInsAmt = false
          }
        }
      } else if ( zzinscdScCd === '47' ) {
        result = true

        // 가입금액 및 보기납기 disable 처리
        _rowData.isEnabled_covInsAmt = false
        _rowData.isEnabled_inprdTypSelected = false
        _rowData.isEnabled_pmprdTypSelected = false

        let inprdSet = this.makeInPrdTypItems(_rowData.uiCode, _rowData.insured, _rowData.childNo)

        let chk = false
        // 주보험의 보험기간이 선택보험의 보험기간 선택목록에 있을 경우에만 세팅
        for (let i = 0; i < inprdSet.length; i++) {
          if (inprdSet[i].key === Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].inprdTypSelected.key) {
            chk = true
          }
        }

        // itemSet도 이것만 남김
        let tmpItems = []

        if (chk) {
          _rowData.inprdTypSelected = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].inprdTypSelected)
          // 주보험의 보험기간 item
          let mnInPrdTypItems = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].inPrdTypItems)

          // inprdTypeText 구하기 위해 주보험 보험기간 찾아보기
          for (let i = 0; i < mnInPrdTypItems.length; i++) {
            if (mnInPrdTypItems[i].key === Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].inprdTypSelected.key) {
              tmpItems.push({key: mnInPrdTypItems[i].key, label: mnInPrdTypItems[i].label} )
            }
          }

          _rowData.inPrdTypItems = []
          _rowData.inPrdTypItems = tmpItems.slice()
        } else {
          tmpItems.push({key: '9', label: '불가'} )

          _rowData.inPrdTypItems = tmpItems.slice()
          _rowData.inprdTypSelected = {key: '9', label: '불가'}
        }
      // 암생활비지급서비스(61) 가입금액 체크예외 추가(2020-07-10)
      } else if (zzinscdScCd === '61') {
        _rowData.isEnabled_covInsAmt = false
        result = true
      }
      // 주보험의 납입기간을 선택보험 납입기간에 세팅
      if (result) {
        if (_rowData.inprdTypSelected.key === '9' || _rowData.inprdTypSelected.key === '') {
          // 보험기간이 불가 세팅되었다면 납입기간도 불가 세팅
          let tmpItems = []
          tmpItems.push({key: '9', label: '불가'} )
          _rowData.pmPrdTypItems = tmpItems.slice()
          _rowData.pmprdTypSelected = {key: '9', label: '불가'}
        } else {
          let pmprdSet = this.makePmPrdTypItems(_rowData.uiCode, _rowData.inprdTypSelected.key.split(',')[0], _rowData.inprdTypSelected.key.split(',')[1], _rowData.insured, _rowData.childNo, _rowData.templateId)

          let chk = false
          // 주보험의 납입기간이 선택보험의 납입기간 선택목록에 있을 경우에만 세팅
          for (let i = 0; i < pmprdSet.length; i++) {
            let PNLPQL00109_CoverageInfo0 = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0]
            if (pmprdSet[i].key === PNLPQL00109_CoverageInfo0.pmprdTypSelected.key) {
              chk = true
            }
          }

          // itemSet도 이것만 남김
          let tmpItems = []

          if (chk) {
            _rowData.pmprdTypSelected = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected)
            // 주보험의 납입기간 item
            let mnPmPrdTypItems = GafMainFunc.copyJson(Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmPrdTypItems)

            // pmprdTypeText 구하기 위해 주보험 납입기간 찾아보기
            for (let i = 0; i < mnPmPrdTypItems.length; i++) {
              if (mnPmPrdTypItems[i].key === Gaf.getVObj('PNLPQL00109_CoverageInfo')[0].pmprdTypSelected.key) {
                tmpItems.push({key: mnPmPrdTypItems[i].key, label: mnPmPrdTypItems[i].label} )
              }
            }

            _rowData.pmPrdTypItems.length = 0
            _rowData.pmPrdTypItems = tmpItems.slice()
          } else { // 주보험의 납입기간이 선택보험의 납입기간 선택목록에 없다면
            tmpItems.push({key: '9', label: '불가'} )
            _rowData.pmPrdTypItems = tmpItems.slice()
            _rowData.pmprdTypSelected = {key: '9', label: '불가'}
          }
        }
      }

      return result
    },
     /******************************************************************************
     * Function명 : fn_SetRealMinIsam
     * 설명       : 외부 호출로 탭 "전체"로 초기화 처리 - 150D에서 호출됨.
     * 22.03 ASR220300038 / 건강증진형프리미엄(2204) 최소가입금액 한도체크 / young21.kwon
     * ASR220300426 / 건강증진프리미엄건강보험 최소가입금액 체크 반영 20220405 youngki78.lee
     * [원본] MSPPI080D
    ******************************************************************************/
    fn_SetRealMinIsam (setEntplAmt, checkEntplAmtLimit) {
      Gaf.d('<!--[R,PANEL_KEY]--> fnProcRealMinIsam Start!!!')
      // let oPanel = u5.panel.getPanel('<!--[R,PANEL_KEY]-->')

      let gridBindingObj = Gaf.getVObj('PNLPQL00109_CoverageInfo')
      let mainCovObj = gridBindingObj.filter(function (item) { return item.elementprodtpCd === '10' && item.relTypCd === '21' })
      if (piCommonUtil.isObj(mainCovObj) && mainCovObj.length > 0) {
        mainCovObj = mainCovObj[0]
      }
      if (!piCommonUtil.isObj(mainCovObj.GafProductInfo)) return true

      // 1. 최소가입금액 한도체크 대상 결과 목록이 존재 하는지 체크
      let prodInfo = Gaf.getTObj('prodInfo')
      let _zPIRealMinIsamProdList = prodInfo['zPIRealMinIsamProdList']
      if (!piCommonUtil.isObj(_zPIRealMinIsamProdList) || _zPIRealMinIsamProdList.length === 0) {
        return true
      }

      // 2. 동일 특약에서 보기,납기 선택값 비교하여 min값 변경
      let tempInprdArray = mainCovObj.inprdTypSelected.key.split(',') // [0]
      if (!tempInprdArray || String(tempInprdArray) === '' || String(tempInprdArray) === '9') {
        return true
      }

      let tempPmprdArray = mainCovObj.pmprdTypSelected.key.split(',') // [0]
      if (!tempPmprdArray || String(tempPmprdArray) === '' || String(tempPmprdArray) === '9') {
        return true
      }

      let tmpStndAmt = 0
      let zaAplcCalcTypVal = null

      _zPIRealMinIsamProdList.forEach((item, i) => {
        if (item.zaInscd.substring(0, 6) === mainCovObj.GafProductInfo.insCode.substring(0, 6) && item.uiCode === mainCovObj.GafProductInfo.uiCode) {
          if (tempInprdArray[0] === item.zzinprdTypCd && tempInprdArray[1] === item.zzinprdTypVl &&
          tempPmprdArray[0] === item.zzpmprdTypCd && tempPmprdArray[1] === item.zzpmprdTypVl) {
            if (!mainCovObj['Oldmin']) {
              mainCovObj.Oldmin = mainCovObj.GafProductInfo.min // 오리지날 min 값 백업
            }

            if (mainCovObj.GafProductInfo.min !== item.min) {
              mainCovObj.GafProductInfo.min = item.min
              // calMin값 재계산-기준금액 0원, 계산유형 null
              // 주의 : 주보험은 F1 가입배수 관계규칙에 대상보험이 되는 케이스가 없슴. 상품IT 성미라프로 확인 내용.
              GafMainFunc.calMinCovInsAmt(mainCovObj.GafProductInfo, tmpStndAmt, zaAplcCalcTypVal )
              GafMainFunc.calMaxCovInsAmt(mainCovObj.GafProductInfo, tmpStndAmt, zaAplcCalcTypVal )

              // ==================================
              // MIN=MAX인 경우 가입금액 set ※ 주보험 보기납기 변경에 의한 보기납기 재조회시는 제외.
              if (setEntplAmt && (isNaN(mainCovObj['covInsAmt']) || Number(mainCovObj['covInsAmt']) === 0)) {
                let _zaRltnRlVals
                if (mainCovObj.GafProductInfo.calMin !== null) {
                  if (mainCovObj.GafProductInfo.calMin === mainCovObj.GafProductInfo.calMax) {
                    if (mainCovObj.unitNm === '천원') {
                      mainCovObj.covInsAmt = mainCovObj.GafProductInfo.calMin * 10
                    } else if (mainCovObj.unitNm === '원') {
                      mainCovObj.covInsAmt = mainCovObj.GafProductInfo.calMin * 10000
                    } else {
                      mainCovObj.covInsAmt = mainCovObj.GafProductInfo.calMin
                    }

                    // 가입배수관련 체크 위배사항이 없을때만 자동세팅 해준다.
                    _zaRltnRlVals = 'F1;1F'
                    // [TO-DO] 로직추가 필요한가 확인필요함
                    // if ( !this.fnCheckRltnRlDt('mnInprdPmprd', '', _zaRltnRlVals, 0) ) {
                    //   mainCovObj.covInsAmt = ''
                    // }
                  }
                } else {
                  if (!piCommonUtil.isEmpty2(mainCovObj.GafProductInfo.min)) {
                    if (Number(mainCovObj.GafProductInfo.min) === Number(mainCovObj.GafProductInfo.max)) {
                      mainCovObj.covInsAmt = GafMainFunc.setUnitAmt(mainCovObj.GafProductInfo.min, mainCovObj.unit)

                      // 가입배수관련 체크 위배사항이 없을때만 자동세팅 해준다.
                      _zaRltnRlVals = 'F1;1F'
                      // [TO-DO] 로직추가 필요한가 확인필요함
                      // if ( !this.fnCheckRltnRlDt('mnInprdPmprd', '', _zaRltnRlVals, 0) ) {
                      //   mainCovObj.covInsAmt = ''
                      // }
                    }
                  }
                }
              }
              // 상단 가입한도 text 세팅
              mainCovObj.padCovRuleText = GafMainFunc.makeCovInfo(mainCovObj, tmpStndAmt, zaAplcCalcTypVal)

              // 주보험 보기납기 변경후, 가입금액 한도 다시 체크-주보험의 보기납기를 사용자가 직접 변경시에만 다시 체크
              // if (checkEntplAmtLimit && !isNaN(mainCovObj['covInsAmt']) && Number(mainCovObj['covInsAmt']) > 0) {
              //   oPanel.l00109_sec30_grid10.getRows()[0].getCells()[2].fireChange()
              // }
              if (checkEntplAmtLimit && !isNaN(mainCovObj['covInsAmt']) && Number(mainCovObj['covInsAmt']) > 0) {
                this.changeCovInsAmt(this.clauseInfo)
              }
            }
          }
        }
      })

      Gaf.d('<!--[R,PANEL_KEY]--> fnProcRealMinIsam End!!!')
    },
    /******************************************************************************
    * Function명 : fnCalcRLCovInsAmt
    * 설명       : 관계규칙 - 가입금액계산규칙 적용후 가입금액값
    *             [원본] MSPPI080D
    ******************************************************************************/
    fnCalcRLCovInsAmt (covElem) {
      let _covInsAmt = null
      switch (covElem.GafProductInfo.amtCalcRl) {
        case '00' : // 입력한 금액이 가입금액으로 계산됨
          _covInsAmt = covElem.covInsAmt
          break
        case '01' : // 일시납인 경우 입력한 금액이 가입금액으로 계산됨,일시납이 아닌 경우 입력한금액*12*min(10,납입기간)
          let _pmprdTyp = covElem.pmprdTypSelected.key.split(',')
          if (_pmprdTyp[0] === '00') {
            _covInsAmt = covElem.covInsAmt
          } else {
            // 실납입기간구하기-실납입기간으로 체크 하는게 맞는지 확인필요
            // 보험계약대상자 및 자녀일련번호 추가 - 20160708 박세현 추가
            let insured = covElem.insured
            let childNo = covElem.childNo
            let _realpmprd = this.fnTrtyPmprd(covElem.pmprdTypSelected.key, insured, childNo)
            _covInsAmt = piCommonUtil.NumCal(covElem.covInsAmt, 12, '*')
            _covInsAmt = piCommonUtil.NumCal(_covInsAmt, Math.min(10, _realpmprd), '*')
          }
          break
        case '02' : // 입력한 금액의 20배
          _covInsAmt = piCommonUtil.NumCal(covElem.covInsAmt, 20, '*')
          break
        default :
          _covInsAmt = covElem.covInsAmt
      }
      return GafMainFunc.getUnitAmt(_covInsAmt, covElem.unit)
    },
    /******************************************************************************
    * Function명 : fnCalcTypVal
    * 설명       : 계산유형에 따른 금액 계산
    *              [원본] MSPPI080D
    ******************************************************************************/
    fnCalcTypVal (amtArr, calcTypVal) {
      let result = 0
      // 최소값 계산의 경우 최초값이 0이면, 유효값과 최소값 비교시 무조건 0이 들어가므로, 0이 아닌 값으로 세팅. 20170103 박세현 수정
      let minVal = 999999999999
      amtArr.forEach((el, i) => {
        if (calcTypVal === 'SUM') {
          result = piCommonUtil.NumCal(result, el, '+')
        } else if (calcTypVal === 'MIN') {
          minVal = Math.min(minVal, el)
          result = minVal
        } else if (calcTypVal === 'MAX') {
          result = Math.max(result, el)
        }
      })
      return result
    },
    /******************************************************************************
    * Function명 : clearErrList
    * 설명       : errorList Clear
    *              [원본] MSPPI080D
    ******************************************************************************/
    clearErrList (isErrorListReset) {
      if (isErrorListReset) {
        this.errorList.splice(0)
        // [NEXT 사랑On] 가입설계발행불가 한도초과 특약 가이드 구분자 초기화
        this.isLimitOver = false        
      }
      if (this.viewData && this.viewData.length > 0) {
        // error 표시된 특약 clear
        this.viewData.forEach((el, i) => {
          if (el.error) {
            this.$set(this.viewData[i], 'error', false)
          }
        })
      }
    },
    /******************************************************************************
    * Function명 : 소액질병납입지원특약 금액입력 화면 예외처리 및 체크
    * 설명       : fn_exceptionCheck
    *              [원본] MSPPI080D
    ******************************************************************************/
    fn_exceptionCheck () {
      if (Gaf.getProcessNo() === '1001' && this.viewData.length > 0) {

        for(let n = 0; n < this.viewData.length; n++){
          // 23.05 min.ko DIY 보험 예외 처리
          // ASR230500741_[상품판매준비] 소액질병납입지원특약 화면예외처리_사랑On(추가요건)_김영석_youngki78.lee
          let _rowData = this.items[n]
          let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
          let polprAbdaprem = abdapolpr.polprAbdaprem[0] // POLPR_ABDAPREM
          let smtotPrm = Gaf.getValue(polprAbdaprem, 'premaftertaxAm') // 합계보험료
          if (_rowData.zzinscdScCd === '64' && piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('37') > -1 && !piCommonUtil.isEmpty2(smtotPrm)) {
            this.$set(this.viewData[n], 'isEnabled_covInsAmt', false)              
          }
        }      
      }
    },
    /******************************************************************************
    * Function명 : onInputChecked
    * 설명       : 체크박스 클릭시 disibled 여부 체크하여 true인 경우 체크상태 유지되도록 처리
    *              [원본] MSPPI080D
    ******************************************************************************/
    onInputChecked(v, disabled) {
      if (v.isChecked == false && v.disabled == true) {
        for (let n in this.viewData) {
          if (this.viewData[n].insCode === v.insCode && this.viewData[n].lineCd === v.lineCd) {
            
            if (this.viewData[n].isChecked == false) {
              this.$set(this.viewData[n], 'isChecked', true)
              break
            }
          }
        }  
      } else {
        if (v.isChecked == false){
          this.selected.splice(this.selected.indexOf(v), 1)

          // [선심사PJT]
          console.log('onInputChecked ===> ', v)
          if (this.lv_isEdit) {
            Gaf.getPanel('MSPPI820P').lv_isChgFlag = true
            if (Gaf.getPanel('MSPPI820P').lv_isChgFlag && Gaf.getPanel('MSPPI820P').lv_isCalc) {
              Gaf.getPanel('MSPPI820P').lv_isCalc = false
            }
          }
        }
        
      } 

      if(disabled) {
        this.clickRow_New(v, false)
      }
      else {
        // as-is
        // v.isEnabled_covInsAmt = true
        // to-be
        if (v.isEnabled_covInsAmt) {
          v.isEnabled_covInsAmt = true
        }
        this.clickRow_New(v, true)
      }
        
      // 선택 건수 조회
      // this.fn_selectedCount()   

    },
    /******************************************************************************
    * Function명 : clickRow_New
    * 설명       : row 클릭 시 error 지우기, props 용 객체 설정
    *              [원본] MSPPI080D
    ******************************************************************************/
    clickRow_New (v, onOff) {
  
      /* clickRow content 영역 클릭시 */
      console.log("clickRow ========= " + JSON.stringify(v))  
      console.log("clickRow isChecked :" + JSON.stringify(v.isChecked))

      if (v.error) v.error = false

      if (v.calcError) v.calcError = false
 
      // 합계 보험료 영역 숨기기
      // ASR200700885 암생활비지급서비스특약인 경우, 특약 수정이 불가하기때문에 합계보험료 초기화 제외
      if (v.GafProductInfo.zzinscdScCd !== '61' && !(v.GafProductInfo.zzinscdScCd === '62' && v.covInsAmt === '') && !(v.GafProductInfo.zzinscdScCd === '64' && v.covInsAmt === '')) {
        // [TO-DO] 합계 보험료 영역 수정 필요 =======================
        // this.$emit('reset-bottom')
      }

      let checkRelease = false
 

      if (v.isChecked == false && v.disabled !== true) { // checkbox가 해제되는 이벤트 발생 시(해제불가 행 클릭시는 제외)
        checkRelease = true
        this.isPadShow = false // 우측 특약 키패드는 out
        this.clauseInfo = null

        console.log("clickRow_New checkRelease :" + checkRelease )
      }
 
      let acumPass = false
      let tmpcovInsAmt = v.covInsAmt
      let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
      let zzprmtrPFSCd = v.GafProductInfo.zzprmtrPFSCd
      if (zzptclrPrdTId === '29') {
        if (zzprmtrPFSCd === '06') {
          this.isPadShow = false
          v.isEnabled_covInsAmt = false

          if (v.isChecked == true) {
            acumPass = true
           } else if (v.isChecked == false) {
            if (!checkRelease) {
              if (v.isChecked) {
                return
              } else {
                acumPass = true
              }
            }
          }
        }
      }


      let doesAdded = false
      if (!checkRelease && v.isEnabled_covInsAmt || acumPass) {
      // if ((!checkRelease && v.isEnabled_covInsAmt) || acumPass) {
        doesAdded = !this.selected.includes(v)
        this.pushClause(v) // select Item 에 넣을지 판단
      }

      //행체크 해제 되면
      if (!v.isChecked !== !checkRelease) {
        v.isChecked = !checkRelease
 
        console.log("v.isChecked !== !checkRelease : isChecked    :" + v.isChecked )

        if (checkRelease) { // 행체크해제 되면
          this.l00109_sec30_grid10_rowSelectionChangeMain(v)
        }
      }

      // 키패드 처리
      // if ((!checkRelease && v.isEnabled_covInsAmt) || acumPass) { // 행체크 해제가 아니면,(즉, 행체크 되거나, 기 체크된 행 클릭시)
      if (!checkRelease && v.isEnabled_covInsAmt || acumPass) { // 행체크 해제가 아니면,(즉, 행체크 되거나, 기 체크된 행 클릭시)
        this.clauseInfo = v
        this.covColorClear() // 다른 특약 선택시, 이전 dom 객체 배경색 없애기.
        //this.targetDom = event.target // 키패드에 표시될 행에 해당하는 dom 객체(tr or td)
        // ASWAS covInsAmt focusIn에서 처리하던 로직
        this.l00109_sec30_grid10_rowSelectionChangeMain(v, !doesAdded) // 기 체크된 행 클릭 여부 (_added) 파라메터 추가하여 보험료 계산 결과 클리어 처리
      }

      if (v.disabled === true && v.isEnabled_covInsAmt) {
        // 선심사 추가 - 특약입력 불가한건 최초 선택 시 this.clauseInfo에 값이 없어서 오류 발생
        if (_.isEmpty(this.clauseInfo)) {
          this.clauseInfo = v
        } // End
        this.clauseInfo.prevCovInsAmtForLI0933 = this.clauseInfo.covInsAmt  
        this.isPadShow = true
      } else {
        if (!checkRelease) {
          if (v.isEnabled_covInsAmt) {
            if (acumPass) {
              this.isPadShow = false
            } else {
              this.clauseInfo.prevCovInsAmtForLI0933 = this.clauseInfo.covInsAmt
              this.isPadShow = true
            }
          } else {
            this.isPadShow = false
          }
        } else {
          this.isPadShow = !checkRelease
        }
      }

      // [선심사PJT] 선택 건수 필요X
      // 선택 건수 조회
      // this.fn_selectedCount()

      if (acumPass) {
        if (tmpcovInsAmt === '') {
          v.covInsAmt = ''
        }
        this.resetCalcResult(v)
      }

      // v.focused = this.isPadShow
      // // 불러오기시, 저장했을 당시의 보기 납기 리스트가 없어져서, 보기.납기 선택 표시가 안되어 있는 경우
      // // 키패드를 누르면, EP처럼 콤보박스 change 이벤트 효과를 내기 위해, 아래 로직 추가함.
      // if (this.isPadShow) {
      //   if (!piCommonUtil.isEmpty(this.clauseInfo.inprdTypSelected.key) && this.clauseInfo.pmPrdTypItems.length === 0) {
      //     this.changeInprd(this.clauseInfo)
      //   }
      // }
 
    },
    /******************************************************************************
    * Function명 : resetCalcResult
    * 설명       : 보장팝업 연결이 키패드로 옮겨감에 따라, 키패드에서 보기/납기/가입금액 변경시에 보험료계산결과 clear
    *              [원본] MSPPI080D
    ******************************************************************************/
    resetCalcResult (v) {
      for (let n in this.items) {
        if (this.items[n].insCode === v.insCode && this.items[n].lineCd === v.lineCd) {
          
          this.items[n].covInsAmt = v.covInsAmt

          this.items[n].covPrm = ''
          break;
      
        }
      } 

      if (Gaf.getProcessNo() === '1001') {
        // 23.05 min.ko DIY 보험 예외 처리
        // ASR230500741_[상품판매준비] 소액질병납입지원특약 화면예외처리_사랑On(추가요건)_김영석_youngki78.lee
        let _rowData = v
        let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
        let polprAbdaprem = abdapolpr.polprAbdaprem[0] // POLPR_ABDAPREM
        let smtotPrm = Gaf.getValue(polprAbdaprem, 'premaftertaxAm') // 합계보험료
        if (_rowData.zzinscdScCd === '64' && piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('37') > -1 && !piCommonUtil.isEmpty2(smtotPrm)) {
          _rowData.covInsAmt = _rowData.prevCovInsAmtForLI0933
          piCommonUtil.getFDPConfirm('s', '소액질병진단보험금납입지원특약은\n보험료 계산 이후에\n특약 가입금액을 변경하실 수 없습니다.')
          return
        }
      }


      // 주보험으로 계산결과 유무 체크해서, 계산결과가 있을때만 클리어 하도록
      // [NEXT 사랑On] 원샷보험료 계산 처리되도록 리턴로직 막음
      // if (piCommonUtil.isEmpty(this.items[0].covPrm)) {
      //   // return
      // }

      // ASR201200640_(화면개선) 암생VUL 암진단보험료환급특약 관련 설계 화면개선_20210203 youngki78.lee
      if (Gaf.getProcessNo() === '1001') {
        let _rowData = v
        if (_rowData.seq === 0 && _rowData.elementprodtpCd === '10') { // 주보험이면,
          this.selected.forEach((el, i) => {
            if (el.GafProductInfo.zzinscdScCd === '62' || el.GafProductInfo.zzinscdScCd === '64') {
              el.covInsAmt = '' // 암생VUL 암진단보험료환급특약 가입금액 초기화
            }
          })
        }
        // 22.06 min.ko ASR220600578 비갱신암 환급특약 화면 예외처리_EP : 비갱신 암보험. 주보험&환급특약 이외 특약 가입금액 수정 시 가입금액 초기화
        // >> 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
        if (_rowData.GafProductInfo.zzinscdScCd !== '64') {
          let gridBindingObj = Gaf.getVObj('PNLPQL00109_CoverageInfo')
          if (piCommonUtil.isObj(gridBindingObj) && gridBindingObj.length > 0) {
            gridBindingObj.forEach((el, i) => {
              if (el.GafProductInfo.zzinscdScCd === '64') {
                el.covInsAmt = ''
              }
              // [TO-DO] 요기서 개별보험료 모두 날려버림!!!
              // el.covPrm = ''
            })
            // u5.panel.getPanel("PNLPQL00109").l00109_sec30_grid10.getModel().refresh()
          }
        }
      }
      // [선심사 및 영업시스템 고도화PJT] 기본계약사항 변경 화면 X
      // if (Gaf.getMainPage()['fn_OnChangeBaseContDto']) {
      //   Gaf.getMainPage()['fn_OnChangeBaseContDto']()
      // }

      // [NEXT 사랑On] 원샷보험료 계산 처리
      // this.fn_calcAllPrem()      

    },
    /******************************************************************************
    * Function명 : getExtraPrm
    * 설명       : 할증보험료 구하기
    *              [원본] MSPPI080D
    ******************************************************************************/
    getExtraPrm (abdacov) {
      let extraPrm = '0'

      if (abdacov.covAbdasupdc && abdacov.covAbdasupdc.length > 0) {
        abdacov.covAbdasupdc.forEach((supdcElem, i) => {
          if ('971,972,973,974,975,976,977,978,979,980,981,982'.indexOf(supdcElem.supdctpCd) >= 0) {
            Gaf.d('supdcElem.supdctpCd : ' + supdcElem.supdctpCd + '  supdcElem.amountAm : ' + supdcElem.amountAm)
            extraPrm = Number(extraPrm) + Number(supdcElem.amountAm)
          }
        })
      }

      Gaf.d('extraPrm => ', extraPrm)
      return extraPrm
    },
    /******************************************************************************
    * Function명 : pushClause
    * 설명       : 선택된 특약 목록 갱신
    *              [원본] MSPPI080D
    ******************************************************************************/
    pushClause (v) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].seq === v.seq) { // 클릭한 걸 또 클릭하면 push 하지 않음
          console.log("[MSPPI080D] pushClause :: 선택특약 실패 상품명 --> " + v.name)
          return
        }
      }
      this.selected.push(v)
      console.log("[MSPPI080D] pushClause :: 선택특약 성공 상품명 --> " + v.name)
    },
    /******************************************************************************
    * Function명 : covColorClear
    * 설명       : 특약행의 배경색 clear
    * [원본] MSPPI080D
    ******************************************************************************/
    covColorClear () {
      let focusedRow = this.items.filter(function (item) { return item.focused })
      if (focusedRow && focusedRow.length > 0) {
        focusedRow.forEach((el, i) => {
          // el.focused = false
          this.$set(el, 'focused', false)
        })
      }
    },
    /******************************************************************************
    * Function명 : fn_chkPer
    * 설명       : 보험기간과 납입기간 체크
    *              [원본] MSPPI080D
    ******************************************************************************/
    fn_chkPer (v) {
      
      let isValidate = true

      if( piCommonUtil.isEmpty2(v)){
        isValidate = false
        return isValidate
      }
      
      // console.log('[MSPPI080D] fn_chkPer --> ' + v.seq + v.name + v.pmPrdTypItems.length)

      let tempInprdArray = v.inprdTypSelected.key.split(',')[0]
      if (!tempInprdArray || String(tempInprdArray) === '9') {
        isValidate = false
      }

      let tempPmprdArray = v.pmprdTypSelected.key.split(',')[0]
      if (!tempPmprdArray || String(tempPmprdArray) === '9') {
        isValidate = false
      }
      return isValidate
    },
    /******************************************************************************
    * Function명 : clickRow_popupCall
    * 설명       : row 클릭 시 팝업창 호출
    ******************************************************************************/
    clickRow_popupCall (v, index, opt) {      
      // if(this.isPadOpend) {
      //   return
      // }
      // ModalEventBus.$once('openBottomModal', () => {
      //   this.isPadOpend = true
      // })
      // ModalEventBus.$once('afterCloseBottomModal', () => {
      //   this.isPadOpend = false
      // })

      console.log('[MSPPI080D] clickRow_popupCall --> index : ' + index)

      // this.isShowMSPPI082P = true

      // 가입금액 편집이 불가능한 경우...
      if(!v.isEnabled_covInsAmt) {
      // if(v.isEnabled_covInsAmt != true) {
        this.getStore('toast').dispatch('ADD', '상세 입력 불가한 특약입니다.')
        return
      }
      // 체크가 안 된 건인 경우...
      if(v.isChecked != true) {
        // v.isChecked = true
        this.$set(v, 'isChecked', true)
        this.clickRow_New(v, true)
      }else{
        // this.clickRow_New(v, true)
      }

      // 체크가 되었지만 보험기간과 납입기간 내용이 미입력인 경우...
      if(v.isChecked == true && (this.fn_chkPer(v) == false)) {
        return
      }

      this.$nextTick(() => {

        // 특가 가입정보 입력 화면 호출 안함
        // if (this.isShowMSPPI082P == false)
        // {
        //   return
        // }        

        // 가입금액 편집이 불가능한 경우...
        if(v.isEnabled_covInsAmt != true)
        {
          return
        }

        this.clauseInfo = this.$bizUtil.cloneDeep(v)   // 선택한 건 정보 저장
        console.log('바텀시트 금액 데이터', this.clauseInfo)
        // this.clauseInfo = v   // 선택한 건 정보 저장
        // this.fn_OpenChgPrcBs(index, this.clauseInfo)
        // this.lv_CalcData = this.$bizUtil.cloneDeep(v)   // 선택한 건 정보 저장
        this.fn_OpenChgPrcBs(index, this.clauseInfo)
        // this.$refs.refChgPrc.open()
        // this.fn_OpenMSPPI082P()
      })

    },
    // ---------------------------------------------------------------------------
    // [가입금액 변경 바텀시트 관련 함수] -------------------------------------------
    // ---------------------------------------------------------------------------
    /******************************************************************************
    * Function명 : fn_OpenChgPrcBs
    * 설명       : 가입금액 입력 바텀시트 오픈
    ******************************************************************************/
    fn_OpenChgPrcBs (idx, item) {
      console.log('fn_OpenChgPrcBs ===>', idx, item)
      this.l00109_sec30_grid10_rowSelectionChangeMain(item, true) // 기 체크된 행 클릭 여부 (_added) 파라메터 추가하여 보험료 계산 결과 클리어 처리

      // clauseInfo.name - 보험명
      // clauseInfo.padCovRuleText - 입력가능 금액
      // this.clauseInfo.prevCovInsAmt - 이전 가입금액
      // this.clauseInfo.covInsAmt - 가입금액
      // 초과금액
      // zaAnmsEntLmtCntnt
      this.lv_CalcData = item
      this.$refs.refChgPrc.open()
      this.fn_MoveScrollTop(idx)
    },
    /******************************************************************************
    * Function명 : fn_OpenChgPrcBs
    * 설명       : 가입금액 입력 바텀시트 닫기
    ******************************************************************************/
    fn_CloseChgPrcBs () {
      this.$emit('chgBottom', true)
      this.$refs.refChgPrc.close()
    },
    /******************************************************************************
    * Function명 : fn_addNum
    * 설명       : 키패드 단위별 버튼 선택 시
    *              [원본] MSPPI082P - addNum
    ******************************************************************************/
    fn_addNum (n) {
      // [TO-DO] 확인 후 적용하기
      // this.fn_isSelectLocalcovInsAmt(true)
      // 2024.02 ASR240200218 / [상품판매준비] 환급특약류 설계관련 화면개선_사랑온(김민지프로요청)
      if (this.fn_chkZzentPTCLVl(true)) return
      // this.lv_CalcData
      let aftAmt = Number(this.lv_CalcData.covInsAmt) + this.addList[n].val
      if (aftAmt < 10000000000) { // 더한 결과값은 9999999999 까지만 허용 -> 최대10자리
        this.lv_CalcData.covInsAmt = (Number(this.lv_CalcData.covInsAmt) + this.addList[n].val).toString()

        this.lv_CalcData.covPrm = ''

        // 입력된 금액의 한도 여부를 체크
        this.fnCheckCovInsAmt_keypad(this.lv_CalcData)

        // 행복종신보험 체증형보장특약 가입금액 세팅로직 함수 
        // 2023.08 행복종신 체증형보장특약(LI0933001)자동 입력삭제
        // this.fn_resetCalcResult(this.clauseInfo)

        // this.LocalcovInsAmt = this.clauseInfo.covInsAmt
        this.$set(this.lv_CalcData, 'covInsAmt', this.lv_CalcData.covInsAmt)
        this.fn_ResetCalcResult({ rtnData:this.lv_CalcData })
        // this.$emit('onPopupResetCalcResult', { rtnData: this.clauseInfo })

      }

    },
    /******************************************************************************
    * Function명 : fn_addStr
    * 설명       : 키패드 숫자 버튼 선택 시
    *              [원본] MSPPI082P - addStr
    ******************************************************************************/
    fn_addStr (n) {
      // [TO-DO] 확인 후 적용하기
      // this.fn_isSelectLocalcovInsAmt(true)
      // 2024.02 ASR240200218 / [상품판매준비] 환급특약류 설계관련 화면개선_사랑온(김민지프로요청)
      if (this.fn_chkZzentPTCLVl(true)) return
      let _keyIn = String(n)
      if ((_keyIn === '0' || _keyIn === '00') && Number(this.lv_CalcData.covInsAmt) === 0) {
        this.fn_isSelectLocalcovInsAmt(false)
        return
      }
      // 10자리까지만 입력 가능.
      for (let i = 0; i < _keyIn.length; i++) {
        if (String(this.lv_CalcData.covInsAmt).length < 10) {
          // this.clauseInfo.covInsAmt = this.clauseInfo.covInsAmt.replace(/,/g, '')
          this.lv_CalcData.covInsAmt += _keyIn.charAt(i)

          this.lv_CalcData.covPrm = ''

          // 입력된 금액의 한도 여부를 체크
          this.fnCheckCovInsAmt_keypad(this.lv_CalcData)

          // 행복종신보험 체증형보장특약 가입금액 세팅로직 함수
          // 2023.08 행복종신 체증형보장특약(LI0933001)자동 입력삭제
          // this.fn_resetCalcResult(this.clauseInfo)              

          // this.LocalcovInsAmt = this.lv_CalcData.covInsAmt
          this.$set(this.lv_CalcData, 'covInsAmt', this.lv_CalcData.covInsAmt)
          this.fn_ResetCalcResult({ rtnData:this.lv_CalcData })

          // this.$emit('onPopupResetCalcResult', { rtnData: this.clauseInfo })
  
        } else {
          break
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ResetCalcResult
    * 설명       : 키패드 금액 변경 시
    *              [원본] MSPPI080D - onPopupResetCalcResult
    ******************************************************************************/
    fn_ResetCalcResult (item) {
      this.isCalcVal = false
      let tmpval = item.rtnData
      this.resetCalcResult(tmpval)   //--> 수정전 금액 셋팅 함수 호출
    },
    /******************************************************************************
    * Function명 : fn_delStr
    * 설명       : 키패드 삭제 버튼 선택 시
    *              [원본] MSPPI082P - delStr
    ******************************************************************************/
    fn_delStr () {
      // [TO-DO] 확인 후 적용하기
      // this.fn_isSelectLocalcovInsAmt(true)
      // 2024.02 ASR240200218 / [상품판매준비] 환급특약류 설계관련 화면개선_사랑온(김민지프로요청)
      if (this.fn_chkZzentPTCLVl(true)) return
      this.lv_CalcData.covInsAmt = String(this.lv_CalcData.covInsAmt).substring(0, String(this.lv_CalcData.covInsAmt).length - 1)

      this.lv_CalcData.covPrm = ''

      // 행복종신보험 체증형보장특약 가입금액 세팅로직 함수 
      // 2023.08 행복종신 체증형보장특약(LI0933001)자동 입력삭제
      // this.fn_resetCalcResult(this.clauseInfo)

      // [TO-DO] 에러 라인 추후 적용하기
      // this.LocalcovInsAmt = this.lv_CalcData.covInsAmt
      // if (piCommonUtil.isEmpty2(this.LocalcovInsAmt) == true) {
      //   // 가입금액 입력 항목 오류인 경우 빨간줄색으로 변경
      //   this.fn_isSelectLocalcovInsAmt(false)            
      // }
      this.fn_ResetCalcResult({ rtnData:this.lv_CalcData })
      // this.$emit('onPopupResetCalcResult', { rtnData: this.clauseInfo })
    
    },
    /******************************************************************************
    * Function명 : fn_Save
    * 설명       : 특약 가입정보 입력 내용 전달
    *             [원본] MSPPI082P - fn_save
    ******************************************************************************/
    fn_Save (flag) {
      // 2024.02 ASR240200218 / [상품판매준비] 환급특약류 설계관련 화면개선_사랑온(김민지프로요청)
      if (this.fn_chkZzentPTCLVl(false)) {
        this.$refs.refChgPrc.close()
        return
      }
      if(flag === 'X'){
        // 화면에서 뒤로 가기 버튼을 클릭한 경우 금액을 초기화 한다.
        this.lv_CalcData.covInsAmt = ''
        this.lv_CalcData.covPrm = ''

      } else {

        // [확인] 버튼 클릭시 필수 입력 항목 중 입력되지 않은 경우 동작을 종료한다.
        if (piCommonUtil.isEmpty2(this.lv_CalcData.covInsAmt)) {
          this.showCheck = true
          // 가입금액 입력 항목 오류인 경우 빨간줄색으로 변경 
          this.isSelectLocalcovInsAmt = false
          return
        }
        // if (this.fn_isCheck() == false){
        //   return
        // }

        if (piCommonUtil.isEmpty2(this.lv_CalcData.covInsAmt) == true) {
          this.fn_LocalcovInsAmtKeyUp()
        }

        // 가입 금액을 체크한다.
        if (!this.fn_CheckCovInsAmt()){
          // 가입 금액 체크 후 오류인 경우....

          // [선심사PJT] 한화면에서 처리되기에 호출 함수 변경
          // this.$emit('onPopupResetCalcResult', { rtnData: this.clauseInfo })            
          this.fn_ResetCalcResult({ rtnData:this.lv_CalcData })
          // 화면 원샷보험료 계산 금액 조회
          // this.fn_covPrm()

          // 가입금액 입력 항목 오류인 경우 빨간줄색으로 변경 
          this.isSelectLocalcovInsAmt = false

          return

        } else {
          // 가입 금액 체크 후 정상인 경우....
          // [선심사PJT] 추가
          this.$set(this.viewData[this.lv_CalcData.seq], 'covInsAmt', this.lv_CalcData.covInsAmt)
          this.$refs.refChgPrc.close()
        }
      }
      this.$emit('chgBottom', true)
      this.fn_ResetCalcResult({ rtnData:this.lv_CalcData })
      // this.$emit('onPopupResetCalcResult', { rtnData: this.clauseInfo })

      // this.$emit('onPopupClose', {})
    },
    /******************************************************************************
    * Function명 : fn_LocalcovInsAmtKeyUp
    * 설명       : 화면상의 가입금액을 보험상품 정보에 등록하는 함수
    *              [원본] MSPPI082P
    ******************************************************************************/
    fn_LocalcovInsAmtKeyUp(){
      let nLocalcovInsAmt = this.lv_CalcData.covInsAmt
      
    //  if (this.isICalcMode) {
    //     let aftAmt = nLocalcovInsAmt
    //     if (aftAmt < 10000000000) { // 더한 결과값은 9999999999 까지만 허용 -> 최대10자리
    //       this.clauseInfo.covPrm = (nLocalcovInsAmt).toString()
    //       this.clauseInfo.covInsAmt = Gaf.getOneShotObj().prcsIcalcPrem(this.clauseInfo)

    //     }
    //   } else {
        let aftAmt = nLocalcovInsAmt
        if (aftAmt < 10000000000) { // 더한 결과값은 9999999999 까지만 허용 -> 최대10자리
          this.lv_CalcData.covInsAmt = (nLocalcovInsAmt).toString()

          this.lv_CalcData.covPrm = ''

          // 행복종신보험 체증형보장특약 가입금액 세팅로직 함수 
          // 2023.08 행복종신 체증형보장특약(LI0933001)자동 입력삭제
          // this.fn_resetCalcResult(this.clauseInfo)

        }
      // }
      
      
      this.fn_covInsAmt()
    },
    /******************************************************************************
    * Function명 : fn_covInsAmt
    * 설명       : [NEXT 사랑On] 보험료 가입금액에 [,] 단위 표시 처리
    ******************************************************************************/
    fn_covInsAmt(){

      if (piCommonUtil.isEmpty2(this.lv_CalcData.covInsAmt) == false) {
        // 보험료 가입금액에 [,] 단위 표시 처리
        // this.LocalcovInsAmt = this.$commonUtil.numberWithCommas(this.lv_CalcData.covInsAmt)
      } else {
        this.lv_CalcData.covInsAmt = ''
        this.lv_CalcData.covPrm = ''
      }

      // 행복종신보험 체증형보장특약 가입금액 세팅로직 함수 
      // 2023.08 행복종신 체증형보장특약(LI0933001)자동 입력삭제
      // this.fn_resetCalcResult(this.clauseInfo)

      // 다음 특약 입력 버튼 비활성화 여부 체크
      // this.fn_isNextBtnDisabled()
    },
    /******************************************************************************
    * Function명 : fnCheckCovInsAmt
    * 설명       : 가입금액체크/가입단위체크
    *             [원본] MSPPI082P
    ******************************************************************************/
    fnCheckCovInsAmt (that) {
      let _rowData = that // row를 받아야 할듯...
      let message = null
      let _min = _rowData.GafProductInfo.min
      // min 금액이 없으면 1
      if (piCommonUtil.isEmpty2(_min)) {
        _min = '1'
      }

      let _max = _rowData.GafProductInfo.max
      // max 금액이 없으면 999999999999
      if (piCommonUtil.isEmpty2(_max)) {
        _max = '999999999999'
      }

      let _unit = _rowData.GafProductInfo.unit
      // unit이 없으면 1
      if (piCommonUtil.isEmpty2(_unit)) {
        _unit = '1'
      }

      // 202005 외화보험 통화키
      let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
      let currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

      /*
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMin)) {
        _min = GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMin, 10000 ) // 만단위로 나누었으니 만원곱해서 복구해줌
      }
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMax)) {
        _max = GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMax, 10000 )// 만단위로 나누었으니 만원곱해서 복구해줌
      }
      */
      // KRW일 경우 최소,최대 금액은 만단위로 나누었으니 만원곱해서 복구해줌
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMin)) {
        _min = currencyId === 'KRW' ? GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMin, 10000 ) : _rowData.GafProductInfo.calMin
      }
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMax)) {
        _max = currencyId === 'KRW' ? GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMax, 10000 ) : _rowData.GafProductInfo.calMax
      }

      if (Number(_min) > Number(_max)) {
        message = `[${_rowData.name}] 가입불가한 특약입니다.`
        this.checkMessage = message
        this.callBackParams = {_rowData}
        this.fnClearCovInsAmt_New()
        this.showCheck = true
        return false
      }

      // UI에 입력된 가입금액에 가입단위곱하기
      // 202005 외환보험 체크
      let _amt = null
      let unitName1 = null
      let unitName2 = null
      if (currencyId === 'KRW') {
        _amt = GafMainFunc.getUnitAmt(_rowData.covInsAmt, _unit)
        unitName1 = ''
        unitName2 = '원'
      } else {
        _amt = _rowData.covInsAmt
        unitName1 = '$'
        unitName2 = ''
      }

      if ( Number(_amt) < Number(_min) ) { // 최소금액 미만인 경우
        // {0}특약의 가입금액은{1}원 이상으로 가입하셔야 합니다.
        // message = `${_rowData.name}의 가입금액은${piCommonUtil.numberFormat( '#,###.', _min)}원 이상으로 가입하셔야 합니다.`
        message = `${_rowData.name}의 가입금액은 ${unitName1}${piCommonUtil.numberFormat( '#,###.', _min)}${unitName2} 이상으로 가입하셔야 합니다.`
        this.checkMessage = message
        this.callBackParams = {_rowData}
        this.fnClearCovInsAmt_New()
        this.showCheck = true
        return false
      } else if (Number(_amt) > Number(_max) ) { // 최대금액 초과인 경우
        // {0}특약의 가입금액은 {1}원 이하로 가입하셔야 합니다.
        message = `${_rowData.name}의 가입금액은 ${unitName1}${piCommonUtil.numberFormat( '#,###.', _max)}${unitName2} 이하로 가입하셔야 합니다.`
        this.checkMessage = message
        this.callBackParams = {_rowData}
        this.fnClearCovInsAmt_New()
        this.showCheck = true
        return false
      } else if ( (Number(_amt) % _unit ) !== 0 ) { // 가입금액이 가입단위로 나누어 떨어지지 않는 경우
        // {0}특약은 {1}원 단위로 가입하셔야 합니다.
        message = `${_rowData.name}은 ${piCommonUtil.numberFormat( '#,###.', _unit)} 단위로 가입하셔야 합니다.`
        this.checkMessage = message
        this.callBackParams = {_rowData}
        this.fnClearCovInsAmt_New()
        this.showCheck = true
        return false
      }

      return true
    },
    /******************************************************************************
    * Function명 : fnClearCovInsAmt
    * 설명       : 관계규칙 불통 가입금액 클리어 처리
    ******************************************************************************/
    fnClearCovInsAmt_New () {
      this.callBackParams._rowData.covInsAmt = ''
      this.callBackParams._rowData.covPrm = ''
      this.LocalcovInsAmt = ''        
    },
    /******************************************************************************
    * Function명 : fn_padCovRuleTextCheck
    * 설명       : 특약 설계 가이드 내용 체크 후 내용이 없으면 등록하는 함수
    ******************************************************************************/
    fn_padCovRuleTextCheck () {
      if (piCommonUtil.isEmpty2(this.lv_CalcData.padCovRuleText)){
        let tmpStndAmt = piCommonUtil.isEmpty2(this.lv_CalcData.covInsAmt) ? 0 :  this.lv_CalcData.covInsAmt  // 기준금액
        let zaAplcCalcTypVal = null // 적용계산유형        
        this.lv_CalcData.padCovRuleText = GafMainFunc.makeCovInfo(this.lv_CalcData, tmpStndAmt, zaAplcCalcTypVal) // 상단 가입한도 text 세팅
      }
    },
    /******************************************************************************
    * Function명 : fn_CheckCovInsAmt
    * 설명       : 가입금액 체크
    *              [원본] MSPPI082P - fn_CheckCovInsAmt
    ******************************************************************************/
    fn_CheckCovInsAmt () {

      let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')

      // 키패드 입력하고 다른 특약 클릭(선택/해제 구분 없이) 하면 가입금액을 체크
      if (piCommonUtil.isObj(this.lv_CalcData) && (this.lv_CalcData.GafProductInfo.zzprmtrPFSCd !== '06' || zzptclrPrdTId !== '29')) {
        if (!this.changeCovInsAmt(this.lv_CalcData)) {

          // 화면 내용 갱신 처리
          this.$forceUpdate()

          // 해당 건 금액 초기화
          return false
        }
      }

      let tmpV = this.lv_CalcData
      this.l00109_sec30_grid10_rowSelectionChangeMain(tmpV, true) // 기 체크된 행 클릭 여부 (_added) 파라메터 추가하여 보험료 계산 결과 클리어 처리


      this.showCheck = false  

      return true
      
    },
    /******************************************************************************
      * Function명 : changeCovInsAmt
      * 설명       : 가입금액 변경시
      *             [원본] MSPPI082P
      ******************************************************************************/
      changeCovInsAmt (v) {
        /** 20160627 yherp.kwon : 가입금액/가입단위체크 */
        // DEF_828 - 김민지 책임 : 가입금액 미입력시 한도체크 PASS되도록 수정요청드립니다
        if (!piCommonUtil.isEmpty(v.covInsAmt) && !this.fnCheckCovInsAmt(v)) {
          return false
        }

        // 20160428 yherp.kwon : 관계규칙체크적용
        // 체크대상룰 추출 : F1가입배수F2가입금액
        let _zaRltnRlVals = 'F1;F2'
        // [TO-DO] 선심사에서는 관계규칙 빼기로하여 주석처리함
        // if (!this.fnCheckRltnRlDt(v, _zaRltnRlVals)) {    // 오류 발생히 false로 리턴

        //   // 화면 내용 갱신 처리
        //   this.$forceUpdate();

        //   return false
        // }

        return true
      },
    /******************************************************************************
    * Function명 : fnCheckCovInsAmt_keypad
    * 설명       : 입력된 금액의 한도 여부를 체크(숫자키패드 또는 금액 버튼)
    *              최소금액은 숫자나 금액 버튼 클릭시 무조건 최소금액을 체크하여 셋팅하기 때문에 미사용하기로 결정함.
    ******************************************************************************/
    fnCheckCovInsAmt_keypad (that) {
      let _rowData = that // row를 받아야 할듯...
      let message = null
      let _min = _rowData.GafProductInfo.min
      // min 금액이 없으면 1
      if (piCommonUtil.isEmpty2(_min)) {
        _min = '1'
      }

      let _max = _rowData.GafProductInfo.max
      // max 금액이 없으면 999999999999
      if (piCommonUtil.isEmpty2(_max)) {
        _max = '999999999999'
      }

      let _unit = _rowData.GafProductInfo.unit
      // unit이 없으면 1
      if (piCommonUtil.isEmpty2(_unit)) {
        _unit = '1'
      }

      // 202005 외화보험 통화키
      let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
      let currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

      // KRW일 경우 최소,최대 금액은 만단위로 나누었으니 만원곱해서 복구해줌
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMin)) {
        _min = currencyId === 'KRW' ? GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMin, 10000 ) : _rowData.GafProductInfo.calMin
      }
      if (piCommonUtil.isObj( _rowData.GafProductInfo.calMax)) {
        _max = currencyId === 'KRW' ? GafMainFunc.getUnitAmt(_rowData.GafProductInfo.calMax, 10000 ) : _rowData.GafProductInfo.calMax
      }

      // UI에 입력된 가입금액에 가입단위곱하기
      // 202005 외환보험 체크
      let _amt = null
      let unitName1 = null
      let unitName2 = null        
      let tmpCovInsAmt = _rowData.covInsAmt

      if (currencyId === 'KRW') {
        _amt = GafMainFunc.getUnitAmt(_rowData.covInsAmt, _unit)
        unitName1 = ''
        unitName2 = '원'          
      } else {
        _amt = _rowData.covInsAmt
        unitName1 = '$'
        unitName2 = ''          
      }


      if (Number(_amt) > Number(_max) ) { // 최대금액 초과인 경우
        message = `${_rowData.name}의 가입금액은 ${unitName1}${piCommonUtil.numberFormat( '#,###.', _max)}${unitName2} 이하로 가입하셔야 합니다.`
        this.checkMessage = message          
        tmpCovInsAmt = GafMainFunc.setUnitAmt(_max, _unit)

        // // 가입금액 입력 항목 오류인 경우 빨간줄색으로 변경 
        // this.fn_isSelectLocalcovInsAmt(false)

        // 메시지 항목 표시 여부
        this.showCheck = true
      } else {
        // this.checkMessage = ''

        // // 가입금액 입력 항목 오류인 경우 빨간줄색으로 변경 해제
        // this.fn_isSelectLocalcovInsAmt(true)

        // // 메시지 항목 표시 여부
        // this.showCheck = false
      }

      this.lv_CalcData.covInsAmt = tmpCovInsAmt

    },
    /******************************************************************************
    * Function명 : toPmodel
    * 설명       : 화면 -> P Model 로 Move
    *             [원본] MSPPI080D
    ******************************************************************************/
    toPmodel (urlId) {
      let lv_Vm = this
      let result = null
      // errorList Clear
      this.clearErrList(true)
      this.isError = false
      // 20201118 younghee01.kwon 선택된 특약 리스트를 화면 표시 순서대로 정렬한다.
      let _orderBy = require('lodash/orderBy')
      this.selected = _orderBy(this.selected, 'seq', 'asc')

      // [선심사PJT] 선심사 특약재설계인 경우 toPmodelOld 적용
      return lv_Vm.toPmodelOld(urlId)
      // let serviceType = ''
      // if (Gaf.getProcessNo() === '2001' || Gaf.getProcessNo() === '2003' || Gaf.getProcessNo() === '3001') { // 접수화면은 모델서비스 기본적용 처리
      //   serviceType = '1'
      // }
      // if (serviceType && serviceType === '1') {
      //   Gaf.d('toPmodel 모델서비스 적용 버전으로 실행!!!!!!')
      //   return lv_Vm.toPmodelNew(urlId)
      // } else {
      //   Gaf.d('toPmodel 기존 버전으로 실행!!!!!!')
      //   result = lv_Vm.toPmodelOld(urlId)
      //   return result
      // }
    },
    /******************************************************************************
    * Function명 : toPmodelOld
    * 설명       : 화면 -> P Model 로 Move
    ******************************************************************************/
    toPmodelOld (urlId) {
      /**
      자녀위험등급처리 관련로직 추가    -  20170501 박세현 추가
            1. 특이상품 유형코드가 28, 29인 경우 (어린이CI3종(출생아)주피의 위험등급을 비위험(0)으로 설정함. )
            2. 특이상품 유형코드가 20인 경우 (우리아이변액교육보험 종피의 위험등급을 비위험(0)으로 설정함.)
            3. 자녀1, 2, 3은 비위험 설정.
      **/
      let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
      let zzprmtrPrdTCd = Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzprmtrPrdTCd // 파라미터상품유형코드
      let _rltnrInfo = Gaf.getTObj('rltnrInfo')
      if (!piCommonUtil.isObj(_rltnrInfo)) {
        return false
      }

      // let tmp_rltnrInfo_11 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '11' })[0]// min.ko 17.01.10
      let tmp_rltnrInfo_21 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '21' })[0]
      let tmp_rltnrInfo_23 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '23' })[0]
      let tmp_rltnrInfo_241 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '24' && String(item.zzchldSnoVl) === '1' })[0]
      let tmp_rltnrInfo_242 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '24' && String(item.zzchldSnoVl) === '2' })[0]
      let tmp_rltnrInfo_243 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '24' && String(item.zzchldSnoVl) === '3' })[0]

      if ( zzptclrPrdTId === '29' || zzprmtrPrdTCd === 'L25') {
        tmp_rltnrInfo_21.zzcarKndScCd = '00' // 차량종류코드
        tmp_rltnrInfo_21.zzlstRskGrdCd = '0' // 최종위험등급코드
        tmp_rltnrInfo_21.zzinjryRskGCd = '0' // 상해위험등급코드
        tmp_rltnrInfo_21.zzhspzRskGrCd = '0' // 입원위험등급코드
        if (tmp_rltnrInfo_21.zaVcustYn !== 'Y') {
          // [선심사PJT] MSPPI010D 화면연계 없으므로 주석 처리 함
          // 차종 등록 호출 이벤트
          // EventBus.$emit('MSPPI010D', {functionName: 'fn_insrCarKndChange', data: {key: '', insrdCd: '21', custType: 'cust'}})
    
        } else {
          // [선심사PJT] MSPPI010D 화면연계 없으므로 주석 처리 함
          // 차종 등록 호출 이벤트
          // EventBus.$emit('MSPPI010D', {functionName: 'fn_insrCarKndChange', data: {key: '', insrdCd: '21', custType: 'vcust'}})

       
        }
      } else if (zzptclrPrdTId === '20') {
        tmp_rltnrInfo_23.zzcarKndScCd = '00' // 차량종류코드
        tmp_rltnrInfo_23.zzlstRskGrdCd = '0' // 최종위험등급코드
        tmp_rltnrInfo_23.zzinjryRskGCd = '0' // 상해위험등급코드
        tmp_rltnrInfo_23.zzhspzRskGrCd = '0' // 입원위험등급코드
        if (tmp_rltnrInfo_23.zaVcustYn !== 'Y') {
          // [선심사PJT] MSPPI010D 화면연계 없으므로 주석 처리 함
          // 차종 등록 호출 이벤트
          // EventBus.$emit('MSPPI010D', {functionName: 'fn_insrCarKndChange', data: {key: '', insrdCd: '23', custType: 'cust'}})

        } else {
          // [선심사PJT] MSPPI010D 화면연계 없으므로 주석 처리 함
          // 차종 등록 호출 이벤트
          // EventBus.$emit('MSPPI010D', {functionName: 'fn_insrCarKndChange', data: {key: '', insrdCd: '23', custType: 'vcust'}})          
        }
      }
      if (piCommonUtil.isObj(tmp_rltnrInfo_241)) {
        tmp_rltnrInfo_241.zzcarKndScCd = '00' // 차량종류코드
        tmp_rltnrInfo_241.zzlstRskGrdCd = '0' // 최종위험등급코드
        tmp_rltnrInfo_241.zzinjryRskGCd = '0' // 상해위험등급코드
        tmp_rltnrInfo_241.zzhspzRskGrCd = '0' // 입원위험등급코드
      }
      if (piCommonUtil.isObj(tmp_rltnrInfo_242)) {
        tmp_rltnrInfo_242.zzcarKndScCd = '00' // 차량종류코드
        tmp_rltnrInfo_242.zzlstRskGrdCd = '0' // 최종위험등급코드
        tmp_rltnrInfo_242.zzinjryRskGCd = '0' // 상해위험등급코드
        tmp_rltnrInfo_242.zzhspzRskGrCd = '0' // 입원위험등급코드
      }
      if (piCommonUtil.isObj(tmp_rltnrInfo_243)) {
        tmp_rltnrInfo_243.zzcarKndScCd = '00' // 차량종류코드
        tmp_rltnrInfo_243.zzlstRskGrdCd = '0' // 최종위험등급코드
        tmp_rltnrInfo_243.zzinjryRskGCd = '0' // 상해위험등급코드
        tmp_rltnrInfo_243.zzhspzRskGrCd = '0' // 입원위험등급코드
      }

      /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // covSelectionList 초기화
      let covSelectionList = []
      Gaf.setTModel('covSelection', covSelectionList)

      let errors = []

      // ABDAPOLPR 정보
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      // ABDACOV 정보
      let abdacovList = []
      // 관계자 중복제거 정보
      let rltnrInfo = Gaf.getTObj('rltnrInfo')

      // 상품기본계약정보
      let baseContDto = Gaf.getTObj('baseContDto')

      // MAX END DATE
      let maxEndDate = ''

      let bnfrPanel = null

      // 상품정보 --------------------------------------------------------
      // let rdClcrlId = Number(GafMainFunc.getIfbcValue('CLCRL_ID')) // RD:보험료계산방법
      //              let rdZZENT_ANTPYMT_CD = GafMainFunc.getIfbcValueArray("ZZENT_ANTPYMT_CD"); //가입시점연금지급유형코드RD
      //              let anutPrdtYn = rdZZENT_ANTPYMT_CD && rdZZENT_ANTPYMT_CD.length > 0 ? true : false; //연금상품여부
      let anutPrdtYn = piCommonUtil.isObj(baseContDto.zzanutPymCd) // 연금상품여부 체크수정 - 박영현 대리님 협의. 20170518 박세현
      let _zzentPTCLVl = Gaf.getGafObjByRefId('POLPR')[0].aldapolpr.zzentPTCLVl // 가설예시특이유형코드

      // 그리드 선택된 행 추출
      let rowIndices = this.selected
      if (rowIndices.length === 0) {
        piCommonUtil.getFDPConfirm('s', '선택된 행이 없습니다.')
        return false
      }

      /** 20160713 yherp.kwon : 전환설계_전환정보입력 조회 시에는 주보험 1개만 구성 */
      if (urlId === 'URLPQL00094') {
        let tmpMainCovIdx = []

        for (let el of rowIndices) {
          if (el.elementprodtpCd === '10') {
            tmpMainCovIdx.push(el)
            break
          }
        }

        if (tmpMainCovIdx.length === 0) {
          piCommonUtil.getFDPConfirm('s', '선택된 전환 대상 주보험이 없습니다.')
          return false
        }
        rowIndices = tmpMainCovIdx
      }

      let sendList = null

      /* ===============================================KYH========================================================== */
      /** 20160615 yherp.kwon : COVPAC P 모델 추가 */
      // COVPAC P 모델 생성
      let errorFlag = false

      let mainInsAbdacov = null

      let covPacTemplateIds = GafMainFunc.getProdStructTemplateId('COVPAC')
      // covpac 정보 유무에 따른 p모델 처리
      if (!piCommonUtil.isObj(covPacTemplateIds) || covPacTemplateIds.length === 0) {
        covPacTemplateIds = [{isNotExistsCovPac: true}]
      } else {
        let _tmp_covPacTemplateIds = GafMainFunc.copyJson(covPacTemplateIds)
        covPacTemplateIds = []
        _tmp_covPacTemplateIds.forEach((covPacEl, covPacI) => {
          // 계약관점피보험자유형코드
          covPacEl._insrdCd = GafMainFunc.getIfbcValue('ZZCONTV_INSRD_CD', covPacEl.templateId)
          covPacEl._childNo = '0'
          if (covPacEl._insrdCd === '24') { // 자녀팩은 자녀순번 1,2,3까지 만들어줌.
            covPacEl._childNo = '1'
            covPacTemplateIds.push(GafMainFunc.copyJson(covPacEl))
            covPacEl._childNo = '2'
            covPacTemplateIds.push(GafMainFunc.copyJson(covPacEl))
            covPacEl._childNo = '3'
            covPacTemplateIds.push(GafMainFunc.copyJson(covPacEl))
          } else {
            covPacTemplateIds.push(covPacEl)
          }
        })

        /** 20160810 yherp.kwon : 코드순으로 sort - mainInsAbdacov 관련 오류 방지 */
        covPacTemplateIds.sort(
          function (a, b) {
            let a_insrdCd = a._insrdCd.concat(a._childNo)
            let b_insrdCd = b._insrdCd.concat(b._childNo)

            if (a_insrdCd === b_insrdCd) {
              return 0
            }
            return a_insrdCd > b_insrdCd ? 1 : -1
          }
        )
      }

      let covPac_insured = null
      let covPacIdx = -1 // COVPAC 배열 인덱스
      /** 20161013 yherp.kwon : covPac 초기화 추가 */
      abdapolpr.abdacovpac = null

      covPacTemplateIds.forEach((covPacEl, covPacI) => {
        // ABDACOV 정보
        abdacovList = []
        sendList = []

        if (piCommonUtil.isObj(covPacEl.isNotExistsCovPac)) { // COVPAC 미적용 상품이면,
          covPacIdx++
          rowIndices.forEach((el, i) => {
            sendList.push(el)
          })
        } else { // 통합보험,어린이 보험류의 COVPAC 적용 상품 이라면,
          // 계약관점피보험자유형코드
          covPac_insured = null

          for (let insuredElem of Gaf.getTObj('rltnrInfo')) {
            if (insuredElem.zzcontvInsrdCd === covPacEl._insrdCd) {
              if (insuredElem.zzcontvInsrdCd === '24') { // 자녀는 자녀 순번까지 체크.
                if (String(insuredElem.zzchldSnoVl) === String(covPacEl._childNo)) {
                  covPac_insured = insuredElem
                  break
                }
              } else {
                covPac_insured = insuredElem
                break
              }
            }
          }
          /** 20160802 yherp.kwon : COVPAC 생성 조건 및 순서 변경 */
          rowIndices.forEach((el, i) => {
            if (el.insured === covPacEl._insrdCd) {
              if (el.insured === '24') { // 자녀는 자녀 순번까지 체크.
                if (String(el.childNo) === String(covPacEl._childNo)) {
                  sendList.push(el)
                }
              } else {
                sendList.push(el)
              }
            }
          })

          // 선택한 특약리스트중 해당하는 COVPAC과 일치하는 리스트가 없으면 COVPAC 생성하지 않음.
          if (sendList.length === 0) {
            return true
          }

          // 계약관계자정보에 존재하는 COVPAC 만 생성해야함.
          if (piCommonUtil.isObj(covPac_insured)) {
            covPacIdx++
          } else {
            return true
          }
        }

        // COV P 모델 생성 ====================================================================
        for (let el of sendList) {
          let subjctTemplateId = null
          let subSubjctTemplateId = null
          let premTemplateId = null
          let bnfTemplateId = null
          let bnfcryTemplateId = null
          let infrdTemplateId = null// Adjustment 추가

          let tempSubjct = null
          let tempPrem = null
          let tempBnf = null

          // let rd_insured; 20160819 yherp.kwon 안써서 막음
          // let rd_childNo = null
          // let rd_subInsured; 20160819 yherp.kwon 안써서 막음
          // let rd_zzinsrVcdId = GafMainFunc.getIfbcValue("ZZINSR_VC_ID", el.templateId);
          let rd_covcptypeCdArray = GafMainFunc.getIfbcValueArray('COVCPTYPE_CD', el.templateId)

          // 사용되지 않아서 주석처리
          // let rd_zzinsrTypCd = GafMainFunc.getIfbcValue("ZZINSR_TYP_CD", el.templateId);

          /** 20160601 yherp.kwon : 자유입출금형태별분류코드의 RD값 추가 */
          // let rd_zzfreioClsfCd = GafMainFunc.getIfbcValue("ZZFREIO_S_C_C_ID", el.templateId);

          /** 20160811 yherp.kwon : 표준미달체인수가능방법코드 RD값 추가 */
          // let rd_zzimprdUPMId = GafMainFunc.getIfbcValue("ZZIMPRD_U_P_M_ID", el.templateId);

          /** 20160714 yherp.kwon : 전환설계_전환정보입력 조회 시에는 보험 금액을 0으로../ 납입기간은 PNLPQL01000패널의 납입기간 선택값으로.. */
          if (urlId === 'URLPQL00094') {
            el.covInsAmt = '0'
            el.pmprdTypSelected = {key: baseContDto._zaPadCylCd, label: ''} // TODO 명찾아줘야함
          }

          // 피보험자 진단지수정보
          let dignIndxn = null

          // COV Object 생성
          let newAbdacov = null

          if (el.abdacov) { // 기존 abdacov 정보 있으면 기존정보로...
            newAbdacov = el.abdacov
            Gaf.d('COV 를 기존정보로 처리함... => ', newAbdacov)
          } else {
            newAbdacov = {
              aldacov: {},
              ztpqlCboextAddmAbdacov: {},
              //, covAbdasubjct : [{covAbdasubjct1:{},covAbdasubjct2:{},ztpqlCboextAddmAbdasubjct:{}}]
              // 2016.10.13 속성삭제(covAbdasubjct2삭제)
              // 2017.07.03 속성삭제(covAbdasubjct1삭제)
              //, covAbdasubjct : [{covAbdasubjct1:{},ztpqlCboextAddmAbdasubjct:{}}]
              covAbdasubjct: [{ztpqlCboextAddmAbdasubjct: {}}],
              covAbdaprem: [{covAldaprem: {}}],
              covAbdabnf: [{covAldabnf: {}}],
              //                             ,covAldassfund //펀드
              //                             ,covAldasshead //자산운용옵션
              covAbdabnfcry: [], // 수익자
              covAbdainfrd: [] // Adjustment
            }
          }

          // COV Child 상품구조 Load
          let childTemplateList = GafMainFunc.getChildProdStruct(el.templateId)
          Gaf.d('[' + el.templateId + '] childTemplateList : ', childTemplateList)

          // COV Child Object 생성
          childTemplateList.forEach((subElem, i) => {
            if (subElem.refId === 'COV_SUBJCT') {
              // RD_보험관점피보험자코드
              let insuvInsrdCd = GafMainFunc.getIfbcValue('ZZINSUV_INSRD_CD', subElem.templateId)

              Gaf.d('insuvInsrdCd : ' + insuvInsrdCd)

              // 보험주피이면 주피로
              if (insuvInsrdCd === '21') {
                subjctTemplateId = subElem.templateId
                // rd_insured = GafMainFunc.getIfbcValue("ZZCONTV_INSRD_CD",subElem.templateId);
                // Gaf.d("rd_insured : "+rd_insured);
              } else { // 그외에는 보험종피로...
                // subSubjctTemplateId = subElem.templateId;
                // rd_subInsured = GafMainFunc.getIfbcValue("ZZCONTV_INSRD_CD",subElem.templateId);
                /** 20160711 yherp.kwon : insured,insured 와 같은 값만 필터링 - 특약별로 상품구조의 COV_SUBJCT가 다르기에. */
                if (!piCommonUtil.isEmpty2(el.subInsured) && el.subInsured === insuvInsrdCd) {
                  subSubjctTemplateId = subElem.templateId
                  // rd_subInsured = GafMainFunc.getIfbcValue("ZZCONTV_INSRD_CD",subElem.templateId);
                }
              }
            } else if (subElem.refId === 'COV_PREM') { // TO_DO 일시납/비일시납에 따라 PREM template Id 구하도록 수정 필요
              // let rd_payfrqCd = GafMainFunc.getIfbcValue("PAYFRQ_CD", subElem.templateId);
              // if(rd_payfrqCd == "12"){ //일단 월납으로만 체크
              //  premTemplateId = subElem.templateId;
              // }
              /** 20160613 yherp.kwon : 일시납  PREM template Id추가 */
              let abdapolicyList = Gaf.getGafObjByRefId('POLICY') // Policy 정보 Array
              let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
              let abdapolpr = abdapolicy.abdapolpr[0]
              let aldapolpr = abdapolpr.aldapolpr
              let rd_premCd = GafMainFunc.getIfbcValue('PREM_CD', subElem.templateId)
              // let prodTemplateId = abdapolicy.productBaseId

              if (aldapolpr.zzprmtrPadCCd === '02' || (piCommonUtil.isObj(baseContDto.zaPadCylCd) && baseContDto.zaPadCylCd === '00')) { // 일시납
                if (rd_premCd === '002') {
                  premTemplateId = subElem.templateId
                }
                // 2017.01.12[전환용]어린이종신예외처리 (박영현책임)
                // }else if(prodTemplateId == "E2E5L95L0000"){
              } else if (piCommonUtil.isObj(_zzentPTCLVl) && _zzentPTCLVl.search('19') > -1) {
                premTemplateId = subElem.templateId
              } else { // 월납,기타
                if (rd_premCd === '001') { // 001 비일시납
                  premTemplateId = subElem.templateId
                }
              }
            } else if (subElem.refId === 'COV_BNF') {
              bnfTemplateId = subElem.templateId
            } else if (subElem.refId === 'COV_BNFCRY') {
              bnfcryTemplateId = subElem.templateId
            } else if (subElem.refId === 'COV_INFRD') {
              infrdTemplateId = subElem.templateId
            }
          })

          // SUBJCT - 보험주피 구성 -----------------------------------------------------
          let insuredOriginal = null
          if (subjctTemplateId) {
            insuredOriginal = null
            Gaf.getTObj('rltnrInfo').forEach((insuredElem, i) => {
              if (insuredElem.zzcontvInsrdCd !== el.insured) {
                return true
              }
              if (el.insured === '24') { // 자녀는 자녀 순번까지 체크.
                if (String(insuredElem.zzchldSnoVl) !== String(el.childNo)) {
                  return true
                }
              }

              insuredOriginal = insuredElem
            })

            if (!insuredOriginal) {
              let errorStr = '선택된 피보험자 정보가 없습니다!!! (피보험자코드:' + el.insured + ')'
              Gaf.d(errorStr)
              errors.push(errorStr)
              errorFlag = true
              break
            }

            tempSubjct = newAbdacov.covAbdasubjct[0]
            tempSubjct.currencyId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 20200917 외화보험 - COV_ABDASUBJCT.CURRENCY_ID 셋팅
            tempSubjct.deleteFlag = '0'
            tempSubjct.productBaseId = subjctTemplateId // template id
            tempSubjct.pmId = subjctTemplateId
            tempSubjct.objcatCd = '5'
            tempSubjct.objtypCd = '50001'
            tempSubjct.updaterequireFg = '0'
            tempSubjct.partnerId = insuredOriginal.partnerId
            // tempSubjct.zzhlthStatCd = "0";  //건강상태코드 (TO_DO 기본계약사항 선택값 취해야함)
            /** 20160901 yherp.kwon : 기본적으로 건강상태코드 및 파라미터주피보험자 건강상태코드를 '0'으로 SET */
            tempSubjct.zzhlthStatCd = '0'
            /** 20160826 yherp.kwon : 기본계약사항에서 주피,종피건강체를 우량체로 선택한 경우 건강상태코드 = 1 입력 */
            /** 기본계약사항에서 주피,종피건강체를 고지우량체로 선택한 경우 건강상태코드 = 4 입력 */
            if (el.insured === '21') {
              //                              tempSubjct.zzhlthStatCd = isObj(baseContDto.zzentplHlthsCd) ? baseContDto.zzentplHlthsCd : "0"; 20170630 박세현 뺌
              if (el.preferable === 'Y' && baseContDto.zzentplHlthsCd === '1' ) {
                tempSubjct.zzhlthStatCd = '1'
              }
              if (el.preferable === 'Y' && baseContDto.zzentplHlthsCd === '4' ) {
                tempSubjct.zzhlthStatCd = '4'
              }
            } else if (el.insured === '23') {
              //                              tempSubjct.zzhlthStatCd = isObj(baseContDto.zzentplAisHlthsCd) ? baseContDto.zzentplAisHlthsCd : "0"; 20170630 박세현 뺌
              if (el.preferable === 'Y' && baseContDto.zzentplAisHlthsCd === '1' ) {
                tempSubjct.zzhlthStatCd = '1'
              }
              if (el.preferable === 'Y' && baseContDto.zzentplAisHlthsCd === '4' ) {
                tempSubjct.zzhlthStatCd = '4'
              }
            }

            /** 20160826 yherp.kwon : 할증지수가 세팅된 경우 해당 보험 및 InsuredObject의 건강상태코드는 할증체로 반영 */
            if (el.insured === '21' || el.insured === '23') {
              // 피보험자 진단지수정보
              dignIndxn = rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === el.insured })[0].dignIndxn
            }
            /** 20160519 yherp.kwon : GafCoveragesDto값으로 setting */
            // tempSubjct.zzcontvInsrdCd = rd_insured; //계약관점피보험자코드
            tempSubjct.zzcontvInsrdCd = el.insured // 계약관점피보험자유형코드
            // tempSubjct.zzchldSnoVl = insuredOriginal.zzchldSnoVl?insuredOriginal.zzchldSnoVl:"0"; //자녀일련번호
            tempSubjct.zzchldSnoVl = el.childNo // 자녀일련번호

            tempSubjct.ageatentryVl = insuredOriginal.ageatentryVl // 가입연령
            tempSubjct.zzleglAgeVl = insuredOriginal.zzleglAgeVl // 법정연령 (만연령)
            // Subjct 의 Birth_DT 는  Cov 의 COVBEG_DT(기준일자로 셋팅됨)
            // 모두 기준일자로 셋팅하였으나 변경 (생년월일로 셋팅하고 태아의 경우 기준일자로 셋팅: 정상묵선임 요청)
            tempSubjct.birthDt = piCommonUtil.isObj(insuredOriginal.zzcustRrnId) ? GafMainFunc.calcBirthDate(insuredOriginal.zzcustRrnId) : '00010101' // 생일(yyyy-MM-dd)
            if (tempSubjct.birthDt === '00010101' ||
                          (piCommonUtil.isObj(insuredOriginal.zzcustRrnId) && (insuredOriginal.zzcustRrnId.substring(0, 7)) === '0000003')) {
              tempSubjct.birthDt = Gaf.getStndYmd()
            }
            tempSubjct.zzlstRskGrdCd = insuredOriginal.zzlstRskGrdCd // 최종위험등급코드
            tempSubjct.zzinjryRskGCd = insuredOriginal.zzinjryRskGCd // 상해위험등급코드
            tempSubjct.zzhspzRskGrCd = insuredOriginal.zzhspzRskGrCd // 입원위험등급코드
            tempSubjct.zzinsuvInsrdCd = '21' // 보험관점 피보험자코드
            tempSubjct.genderCd = insuredOriginal.genderCd // 성별
            // tempSubjct.genderCd = GafMainFunc.calcGender(insuredOriginal.zzcustRrnId); //성별
            tempSubjct.zzcarKndScCd = insuredOriginal.zzcarKndScCd // 차량종류코드
            /* 20160624 yherp.kwon : 추가 */
            tempSubjct.zzinsrEnJobCd = insuredOriginal.zzinsrJobCd // 보험가입자직업코드
            /** 20160811 yherp.kwon : 표준미달체인수가능방법코드별 할증지수 setting */
            tempSubjct.zzindxnTypCd = '' // 지수유형코드
            tempSubjct.zzmnisdIndxnVl = '' // 주피보험자지수
            tempSubjct.zzsndInxTypCd = '' // 두번째지수유형코드
            tempSubjct.zzmnisd2indxVl = '' // 주피보험자두번째지수

            /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관
                      ZZMD_RC_AU_PE_FG(의료수급권자여부) / ZZANU_P_B_C_A_VL(연금지급개시민법연령) / ZZDTHR_DTH_YM_DT(사망자사망일자) / ZZDTHR_LEGL_A_VL(사망자법적연령) */

            // min.ko
            if (el.insured === '21') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFg
            } else if (el.insured === '23') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgAisd
            } else if (el.insured === '24' && String(el.childNo) === '1') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh1
            } else if (el.insured === '24' && String(el.childNo) === '2') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh2
            } else if (el.insured === '24' && String(el.childNo) === '3') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh3
            }

            /** 20160901 yherp.kwon : 지수유형코드/피보험자지수 set 조건 추가 */
            let _todo = false
            let _todo2 = false
            let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
            if (el.insured === '21' || el.insured === '23') {
              // 상품의 가설예시유형코드(ZZENT_P_T_C_L_VL) 가 05인 경우
              if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('05') > -1) {
                _todo = true
              } else if (el.zzinscdScCd === '01') { // 기본계약에서 건강상태를 할증으로 선택한 case의 경우 보험코드구분코드(ZZINSCD_SC_CD)가 01
                let rltnrInfo_21 = Gaf.getTObj('rltnrInfo').filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
                if (piCommonUtil.isObj(rltnrInfo_21) && rltnrInfo_21.zzhlthStatCd === '3') {
                  _todo = true
                }
                // 기본계약에서 할증체 선택한 경우에도 todo true. 20170630 박세현 추가
                if ( piCommonUtil.isObj(baseContDto.zzentplHlthsCd) && baseContDto.zzentplHlthsCd === '3') {
                  _todo = true
                }
              }
            }

            if (el.insured === '21') {
              if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentplHlthsCd) && abdapolpr.aldapolpr.zzentplHlthsCd === '3') {
                _todo2 = true
              }
            }

            if (_todo) {
              // 피보험자 진단지수정보
              // let dignIndxn = rltnrInfo.filter(function(item){return item.zzcontvInsrdCd == el.insured})[0].dignIndxn;
              /** 20160816 yherp.kwon : Coverage level 계약정보 적용 */
              // switch(rd_zzimprdUPMId){
              switch (el.GafProductInfo.zzmnisdImpdaCd) {
                case '0' : // 인수불가
                  break
                case '1' : // 삭감법
                  break
                case '2' : // 연증법
                  break
                case '3' : // 할증법(사망)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '971'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn
                  }
                  break
                case '4' : // 할증법(사망+발생)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '971'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn
                  }
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzsndInxTypCd = '972'
                    tempSubjct.zzmnisd2indxVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn
                  }
                  break
                case '5' : // 할증법(Table)
                  break
                case '6' : // 할증법(LTC)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '05' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '973'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '05' })[0].zaDundwRsltIndxn
                  }
                  break
                case '7' : // 재보할증인수불가
                  break
                case '8' : // 할증법(종합실손)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '07' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '974'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '07' })[0].zaDundwRsltIndxn
                  }
                  break
                case '9' : // 할증법(질병실손)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '06' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '975'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '06' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'A' : // 할증법(CI)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '972'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'B' : // 할증법(심근경색)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '36' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '976'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '36' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'C' : // 할증법(뇌출혈)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '977'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'D' : // 할증법(고도장해)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '34' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '978'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '34' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'E' : // 할증법(입원)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '13' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '979'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '13' })[0].zaDundwRsltIndxn
                  }
                  break
                case 'F' : // 할증법(사망+뇌심근)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '971'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn
                  }
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzsndInxTypCd = '977'
                    tempSubjct.zzmnisd2indxVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn
                  }
                  break
                // 2022.4.5 / ASR220300495 / [사랑온]건강증진 보험 가입설계 표준미달체인수 신규 허용값 반영 / youngki78.lee
                case 'G' : // 할증법(G.뇌출혈+CI)
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzindxnTypCd = '977'
                    tempSubjct.zzmnisdIndxnVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn
                  }
                  if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn > 100) {
                    tempSubjct.zzsndInxTypCd = '972'
                    tempSubjct.zzmnisd2indxVl = dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn
                  }
                  break

                default :
              }

              // 진단지수존재 + 지수가 100보다 클때 -> 할증체(3) 세팅. 20170630 박세현 추가
              if ( (piCommonUtil.isObj(tempSubjct.zzmnisdIndxnVl) && tempSubjct.zzmnisdIndxnVl > 100) ||
                      (piCommonUtil.isObj(tempSubjct.zzmnisd2indxVl) && tempSubjct.zzmnisd2indxVl > 100) ) {
                tempSubjct.zzhlthStatCd = '3'
              }
            }

            if (_todo2) {
              // 주보험일 경우에만 체크하도록 요청 (2017.02.03 김민수사원요청 )
              if (el.elementprodtpCd === '10') {
                switch (el.GafProductInfo.zzmnisdImpdaCd) {
                  case '3' : // 할증법(사망)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(사망)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  case '4' : // 할증법(사망+발생)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(사망)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn + ', 할증(CI)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  case 'E' : // 할증법(입원)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '13' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(입원)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '13' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  case 'F' : // 할증법(사망+뇌출혈)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(사망)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '02' })[0].zaDundwRsltIndxn + ', 할증(뇌출혈)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  // 2022.4.5 / ASR220300495 / [사랑온]건강증진 보험 가입설계 표준미달체인수 신규 허용값 반영 / youngki78.lee
                  case 'G' : // 할증법(G.뇌출혈+CI)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(뇌출혈)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '35' })[0].zaDundwRsltIndxn + ', 할증(CI)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '04' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  case '8' : // 할증법(종합실손)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '07' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(종합입원)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '07' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break

                  case '9' : // 할증법(질병실손)
                    if (dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '06' })[0].zaDundwRsltIndxn < 150) {
                      errors.push('주피보험자의 주보험 할증(질병입원)지수' + dignIndxn.filter(function (item) { return item.zaDundwRsltTypCd === '06' })[0].zaDundwRsltIndxn + '를 확인하세요.')
                      errorFlag = true
                    }
                    break
                }
              }
            }

            /** 20161004 yherp.kwon : 승계전계약정보 추가 */
            if (!piCommonUtil.isEmpty2(baseContDto.zaDthrDob)) {
              // 사망자법적연령=사망자사망일자-사망자 생년월일
              // 2016.10.13 속성삭제 ABDASUBJCT -> ABDASUBJCT1 이동
              // tempSubjct.zzdthrLeglAVl = GafMainFunc.getFullAge(baseContDto.zaDthrDob,baseContDto.zaDthrDthYmd);
              // tempSubjct.covAbdasubjct1.zzdthrLeglAVl = GafMainFunc.getFullAge(baseContDto.zaDthrDob,baseContDto.zaDthrDthYmd);
              /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관 */
              tempSubjct.zzdthrLeglAVl = GafMainFunc.getFullAge(baseContDto.zaDthrDob, baseContDto.zaDthrDthYmd)
            }
            if (!piCommonUtil.isEmpty2(baseContDto.zaDthrDthYmd)) {
              // 2016.10.13 속성삭제 ABDASUBJCT -> ABDASUBJCT1 이동
              // tempSubjct.zzdthrDthYmDt =  baseContDto.zaDthrDthYmd;
              // tempSubjct.covAbdasubjct1.zzdthrDthYmDt =  baseContDto.zaDthrDthYmd;
              /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관 */
              tempSubjct.zzdthrDthYmDt = baseContDto.zaDthrDthYmd
            }

            // 건강나이 2021.06.08 ASR210401013
            // if( isObj(u5.panel.getPanel("PNLPQL00105"))
            //     && isObj(u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00062"])
            //     && !isEmpty2(u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00062"].getValue())){
            //   tempSubjct.zzhageDfgrVl = u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00062"].getValue();
            // }
            // let _baseInfo00062 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00062' })
            // if (Gaf.getPanel('MSPPI001M') && piCommonUtil.isObj(_baseInfo00062) && _baseInfo00062.length > 0) {
            //   let panlData120D = Gaf.getPanel('MSPPI040D')
            //   tempSubjct.zzhageDfgrVl = panlData120D.comp['00062'] // Gaf.getPanel('MSPPI040D').comp['00062']
            // }
            // ASR210701004_[상품판매준비]사랑On 설계화면 건강나이 관련 개발
            let _baseInfo00062 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00062' })
            // if (Gaf.getPanel('MSPPI001M') && piCommonUtil.isObj(_baseInfo00062) && _baseInfo00062.length > 0) {
            
            // ==============================================================================
            // [선심사PJT] MSPPI002D, MSPPI040D 화면 연계가 없으므로 조건 변경
            // ==============================================================================
            // 기존 특약 화면 로직
            // if (Gaf.getPanel('MSPPI002D') && piCommonUtil.isObj(_baseInfo00062) && _baseInfo00062.length > 0) {
            //   let panlData120D = Gaf.getPanel('MSPPI040D')
            //   if (!piCommonUtil.isEmpty2(panlData120D.comp['00062'])) {
            //     tempSubjct.zzhageDfgrVl = panlData120D.comp['00062']
            //   } else {
            //     if (piCommonUtil.isObj(tmp_rltnrInfo_21) && tmp_rltnrInfo_21.zzhlthStatCd === '3') {
            //       tempSubjct.zzhageDfgrVl = '98'
            //     } else {
            //       tempSubjct.zzhageDfgrVl = '99'
            //     }
            //   }
            // }
            if (piCommonUtil.isObj(_baseInfo00062) && _baseInfo00062.length > 0) {
              if (piCommonUtil.isObj(tmp_rltnrInfo_21) && tmp_rltnrInfo_21.zzhlthStatCd === '3') {
                tempSubjct.zzhageDfgrVl = '98'
              } else {
                tempSubjct.zzhageDfgrVl = '99'
              }
            }

            /** 20170703 COV_ABDASUBJCT1 사용하지않음
                  tempSubjct.covAbdasubjct1.deleteFlag = "0";
                  tempSubjct.covAbdasubjct1.productBaseId = subjctTemplateId; //template id
                  tempSubjct.covAbdasubjct1.pmId = subjctTemplateId;
                  /** 20170703 COV_ABDASUBJCT1의 직업코드 컬럼은 사용하지않음
                  tempSubjct.covAbdasubjct1.zzinsrJobCd = insuredOriginal.zzinsrJobCd; //직업코드

                  /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관
                  tempSubjct.covAbdasubjct1.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFg; //의료수급권자여부

                  //2017.04.25 : 의료수급권자여부 관련 임시 세팅
                  if( isObj(u5.panel.getPanel("PNLPQL00105")) && isObj(u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00054"]) ){
                      let _rltnrInfo = el.childNo == "0"? Gaf.gafObject.tmodel.rltnrInfo.filter(function(item){return item.zzcontvInsrdCd == el.insured})
                              : Gaf.gafObject.tmodel.rltnrInfo.filter(function(item){return item.zzcontvInsrdCd == el.insured && item.zzchldSnoVl == el.childNo});
                      tempSubjct.covAbdasubjct1.zzmdRcAuPeFg = _rltnrInfo[0].zzmdRcAuPeFg;
                  } */

            tempSubjct.ztpqlCboextAddmAbdasubjct.deleteFlag = '0'
            tempSubjct.ztpqlCboextAddmAbdasubjct.productBaseId = subjctTemplateId // template id
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaInsrdNm = insuredOriginal.zaInsrdNm // 피보험자명
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaJobRskGrdCd = insuredOriginal.zaJobRskGrdCd // 직업위험등급코드

            // 가입설계주석 tempSubjct.ztpqlCboextAddmAbdasubjct.zaMdcrRldmTrtyEntYn = "N";
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaAgtFileId = insuredOriginal.zaAgtFileId // Agent File Id
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaInsrJobNm = insuredOriginal.zaInsrJobNm // 직업명
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaDstrRskGrdCd = insuredOriginal.zaDstrRskGrdCd // 재해위험등급코드

            Gaf.d('tempSubjct 생성 => ', tempSubjct)
          } else {
            let errorStr = 'Template : [' + el.templateId + '] insCode : [' + el.insCode + ']' + el.name + ' 의 피보험자 Template 이 존재하지 않습니다.'
            Gaf.d(errorStr)
            errors.push(errorStr)
            errorFlag = true
          }

          /** 20160614 yherp.kwon : 연생 보험 종피 subject 생성 추가 */
          // SUBJCT - 연생 보험 종피 구성 -----------------------------------------------------
          if (subSubjctTemplateId) {
            insuredOriginal = null
            for (let insuredElem of Gaf.getTObj('rltnrInfo')) {
              if (insuredElem.zzcontvInsrdCd === el.subInsured) { // el.subInsured == "23" 고정값임.
                insuredOriginal = insuredElem
                break
              }
            }

            if (!insuredOriginal) {
              let errorStr = '선택된 피보험자 정보가 없습니다!!! (피보험자코드:' + el.subInsured + ')'
              Gaf.d(errorStr)
              errors.push(errorStr)
              errorFlag = true
              break
            }

            // 계약종피 객체 추가
            if (newAbdacov.covAbdasubjct.length < 2) {
              // newAbdacov.covAbdasubjct.push({covAbdasubjct1:{},covAbdasubjct2:{},ztpqlCboextAddmAbdasubjct:{}});
              // 2016.10.13 속성삭제(covAbdasubjct2삭제)
              // 2017.07.03 속성삭제(covAbdasubjct1삭제)
              // newAbdacov.covAbdasubjct.push({covAbdasubjct1:{},ztpqlCboextAddmAbdasubjct:{}});
              newAbdacov.covAbdasubjct.push({ztpqlCboextAddmAbdasubjct: {}})
            }

            tempSubjct = newAbdacov.covAbdasubjct[1]
            tempSubjct.deleteFlag = '0'
            tempSubjct.productBaseId = subSubjctTemplateId // template id
            tempSubjct.pmId = subSubjctTemplateId
            tempSubjct.objcatCd = '5'
            tempSubjct.objtypCd = '50001'
            tempSubjct.updaterequireFg = '0'
            tempSubjct.partnerId = insuredOriginal.partnerId
            // tempSubjct.zzhlthStatCd = "0";  //건강상태코드 (TO_DO 기본계약사항 선택값 취해야함)
            /** 20160624 yherp.kwon : 추가 */
            if (el.insured === '21') {
              tempSubjct.zzhlthStatCd = piCommonUtil.isObj(baseContDto.zzentplHlthsCd) ? baseContDto.zzentplHlthsCd : '0'
            } else {
              tempSubjct.zzhlthStatCd = piCommonUtil.isObj(baseContDto.zzentplAisHlthsCd) ? baseContDto.zzentplAisHlthsCd : '0'
            }

            tempSubjct.zzcontvInsrdCd = el.subInsured // 계약관점피보험자유형코드
            // tempSubjct.zzchldSnoVl = insuredOriginal.zzchldSnoVl?insuredOriginal.zzchldSnoVl:"0"; //자녀일련번호
            tempSubjct.zzchldSnoVl = el.childNo // 자녀일련번호
            tempSubjct.ageatentryVl = insuredOriginal.ageatentryVl // 가입연령
            tempSubjct.zzleglAgeVl = insuredOriginal.zzleglAgeVl // 법정연령 (만연령)
            tempSubjct.birthDt = piCommonUtil.isObj(insuredOriginal.zzcustRrnId) ? GafMainFunc.calcBirthDate(insuredOriginal.zzcustRrnId) : '00010101' // 생일(yyyy-MM-dd)
            tempSubjct.zzlstRskGrdCd = insuredOriginal.zzlstRskGrdCd // 최종위험등급코드
            tempSubjct.zzinjryRskGCd = insuredOriginal.zzinjryRskGCd // 상해위험등급코드
            tempSubjct.zzhspzRskGrCd = insuredOriginal.zzhspzRskGrCd // 입원위험등급코드
            tempSubjct.zzinsuvInsrdCd = '23' // 보험관점 피보험자코드
            tempSubjct.genderCd = insuredOriginal.genderCd // 성별
            tempSubjct.zzcarKndScCd = insuredOriginal.zzcarKndScCd // 차량종류코드
            /* 20160624 yherp.kwon : 추가 */
            tempSubjct.zzinsrEnJobCd = insuredOriginal.zzinsrJobCd // 보험가입자직업코드
            /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관 */
            // min.ko
            if (el.subInsured === '21') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFg
            } else if (el.subInsured === '23') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgAisd
            } else if (el.subInsured === '24' && String(el.childNo) === '1') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh1
            } else if (el.subInsured === '24' && String(el.childNo) === '2') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh2
            } else if (el.subInsured === '24' && String(el.childNo) === '3') {
              tempSubjct.zzmdRcAuPeFg = baseContDto.zzmdRcAuPeFgCh3
            }

            /** 20170703 COV_ABDASUBJCT1 사용하지않음
                  tempSubjct.covAbdasubjct1.deleteFlag = "0";
                  tempSubjct.covAbdasubjct1.productBaseId = subSubjctTemplateId; //template id
                  tempSubjct.covAbdasubjct1.pmId = subSubjctTemplateId;
                  /** 20170703 COV_ABDASUBJCT1의 직업코드 컬럼은 사용하지않음
                  tempSubjct.covAbdasubjct1.zzinsrJobCd = insuredOriginal.zzinsrJobCd; //직업코드
                  /** 20170703 COV_ABDASUBJCT1의 기존 컬럼은 COV_ABDASUBJCT로 이관
                  tempSubjct.covAbdasubjct1.zzmdRcAuPeFg = insuredOriginal.zzmdRcAuPeFg; //의료수급권자여부

                  //2017.04.25 : 의료수급권자여부 관련 임시 세팅
                  if( isEmpty2(insuredOriginal.zzmdRcAuPeFg) ){
                      tempSubjct.covAbdasubjct1.zzmdRcAuPeFg = Gaf.gafObject.tmodel.rltnrInfo[1].zzmdRcAuPeFg;
                  } */

            tempSubjct.ztpqlCboextAddmAbdasubjct.deleteFlag = '0'
            tempSubjct.ztpqlCboextAddmAbdasubjct.productBaseId = subSubjctTemplateId // template id
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaInsrdNm = insuredOriginal.zaInsrdNm // 피보험자명
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaJobRskGrdCd = insuredOriginal.zaJobRskGrdCd // 직업위험등급코드
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaMdcrRldmTrtyEntYn = 'N'
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaAgtFileId = insuredOriginal.zaAgtFileId // Agent File Id
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaInsrJobNm = insuredOriginal.zaInsrJobNm // 직업명
            tempSubjct.ztpqlCboextAddmAbdasubjct.zaDstrRskGrdCd = insuredOriginal.zaDstrRskGrdCd // 재해위험등급코드

            // 20170628 : 태아종피보험자ID 제거(105번에서 태아종피를 셋팅해주는데, 여기서도 종피를 넣으면 관계자가 중복 될수 있으므로) */
            if (piCommonUtil.isObj(abdapolpr.ztpqlCboextAddmAbdapolpr) && !piCommonUtil.isEmpty2(abdapolpr.ztpqlCboextAddmAbdapolpr.zaUnchAisdId)) {
              abdapolpr.ztpqlCboextAddmAbdapolpr.zaUnchAisdId = null
              delete abdapolpr.ztpqlCboextAddmAbdapolpr['zaUnchAisdId']
            }

            Gaf.d('tempSubjct 연생 종피 생성 => ', tempSubjct)
          }

          // COV 구성 -----------------------------------------------------
          Gaf.d('COV 구성 Start')
          // ABDA
          newAbdacov.deleteFlag = '0'
          newAbdacov.productBaseId = el.templateId
          newAbdacov.pmId = el.templateId
          newAbdacov.covbegDt = Gaf.getStndYmd()
          newAbdacov.generationDt = newAbdacov.covbegDt
          newAbdacov.generatioDetDt = newAbdacov.covbegDt

          // newAbdacov.zzcoltrCd = el.insCode;
          /** 20160519 yherp.kwon : ZWPQL0201 6차변경 반영 - ABDACOV 항목 추가 */
          // newAbdacov.zzcoltrCd = el.insCode;

          /** 20160816 yherp.kwon : Coverage level 계약정보 적용 */
          // 담보코드
          newAbdacov.zzcoltrCd = el.GafProductInfo.zzcoltrCd

          newAbdacov.gafRefId = 'COV'

          // ALDA
          newAbdacov.aldacov.currencyId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 20200917 외화보험 - ALDACOV.CURRENCY_ID 셋팅
          newAbdacov.aldacov.deleteFlag = '0'
          newAbdacov.aldacov.productBaseId = el.templateId
          newAbdacov.aldacov.pmId = el.templateId
          // 가입설계주석 newAbdacov.aldacov.pensionId = "1";
          newAbdacov.aldacov.begoptionDt = '2998-03-22' // TO_DO 최대일자 하드코딩
          newAbdacov.aldacov.firstratingDt = '1800-01-01' // TO_DO 최소일자 하드코딩
          newAbdacov.aldacov.endoptionDt = '2998-03-22' // TO_DO 최대일자 하드코딩
          /*
              if(el.seq === 0){
                  newAbdacov.aldacov.elementprodtpCd = "10"; //주보험 : "10"
                  mainInsAbdacov = newAbdacov; //주보험 변수에 저장
              }else{
                  newAbdacov.aldacov.elementprodtpCd = "0"; //그외에는 "0"
              }
              */
          /*
              if(el.templateId == mainCovTemplateId){
                  newAbdacov.aldacov.elementprodtpCd = "10"; //주보험 : "10"
                  mainInsAbdacov = newAbdacov; //주보험 변수에 저장
              }else{
                  newAbdacov.aldacov.elementprodtpCd = "0"; //그외에는 "0"
              }
              */
          newAbdacov.aldacov.elementprodtpCd = el.elementprodtpCd
          if (el.elementprodtpCd === '10') { // 주보험이면,
            mainInsAbdacov = newAbdacov // 주보험 변수에 저장
          }

          // newAbdacov.aldacov.zzantPymPrdVl = 999; //TO_DO 연금지급유형에따라...
          /** 20160930 yherp.kwon : baseContDto 값으로 수정 */
          newAbdacov.aldacov.zzantPymPrdVl = baseContDto.zzantPymPrdVl
          // 보험료 계산전 필수값 체크에서 수행하는것으로 수정
          // 20170810 박세현 수정
          let tempInprdArray = el.inprdTypSelected.key.split(',')
          newAbdacov.aldacov.zzinprdTypCd = tempInprdArray[0]
          newAbdacov.aldacov.zzinprdTypVl = tempInprdArray[1]

          /** 20160722 yherp.kwon : zzinprtVl 계산시 가입연령은 보험주피의 가입연령으로 계산토록 */
          let _ageatentryVl = newAbdacov.covAbdasubjct.filter(function (item) { return item.zzinsuvInsrdCd === '21' })[0].ageatentryVl

          newAbdacov.aldacov.zzinprdVl = GafMainFunc.calcPrd(newAbdacov.aldacov.zzinprdTypCd
            , newAbdacov.aldacov.zzinprdTypVl
            , _ageatentryVl)

          // 가입설계주석 newAbdacov.aldacov.zzcnvrScCd = "1"; //전환구분코드 기본 "1" TO_DO 전환계약 처리화면에서는 변경필요
          // if((isObj(el.GafProductInfo.cnvCd))&&(!isEmpty2(el.GafProductInfo.cnvCd))){
          //    newAbdacov.aldacov.zzprmtrCnvSCd = el.GafProductInfo.cnvCd; //파라메터:전환구분코드추가
          // }else{
          //    newAbdacov.aldacov.zzprmtrCnvSCd = "1"; //파라메터:전환구분코드 기본 "1" TO_DO 전환계약 처리화면에서는 변경필요
          // }
          newAbdacov.aldacov.zzprmtrCnvSCd = el.GafProductInfo.zzprmtrCnvSCd// 통합파라메터셋팅변경
          /** 20160519 yherp.kwon : ZWPQL0201 6차 변경- IFBC -> Coverage_List 조회값으로 변경 */
          newAbdacov.aldacov.zzinsrTypCd = el.typCd
          // 파라미터보험구분코드
          newAbdacov.aldacov.zzprmtrInsSCd = el.GafProductInfo.zzprmtrInsSCd // 통합파라메터셋팅추가
          newAbdacov.aldacov.zzprmtrInsTCd = el.GafProductInfo.zzprmtrInsTCd // 통합파라메터셋팅변경
          /** 20160519 yherp.kwon : ZWPQL0201 6차변경 반영 - ALDACOV 항목 추가 */
          newAbdacov.aldacov.zzentplRepCCd = el.uiCode
          newAbdacov.aldacov.zzprmtrCITCd = el.GafProductInfo.zzprmtrCITCd// 통합파라메터셋팅변경
          newAbdacov.aldacov.zzprmtrChlSCd = el.GafProductInfo.zzprmtrChlSCd// 통합파라메터셋팅변경
          newAbdacov.aldacov.zzcoltrLineCd = el.lineCd
          newAbdacov.aldacov.zzprmtrColLCd = el.GafProductInfo.zzprmtrColLCd// 통합파라메터셋팅변경
          /** 20161123 ALDACOV 항목 추가(출력명 및 출력순서값 추가) */
          newAbdacov.aldacov.zzentplPINTt = el.GafProductInfo.insName
          newAbdacov.aldacov.zzentplIPSVl = el.GafProductInfo.order
          // 2016.10.13 속성삭제
          let abdapolicyList = Gaf.getGafObjByRefId('POLICY') // Policy 정보 Array
          let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
          abdapolpr = abdapolicy.abdapolpr[0]
          // let aldapolpr = abdapolpr.aldapolpr
          if (newAbdacov.aldacov.elementprodtpCd === '10') { // 주보험인경우
            newAbdacov.aldacov.zzprmtrLgaSCd = el.GafProductInfo.zzprmtrLgaSCd// 통합파라메터셋팅변경
          }

          newAbdacov.aldacov.zzprmtrPadCCd = el.GafProductInfo.zzprmtrPadCCd// 통합파라메터셋팅변경

          // newAbdacov.aldacov.zzprmtrSCSFg = "1"; //TO_DO 통합파라메터 임시 보험료계산위해(ZZPRMTR_S_C_S_FG 파라미터표준해약공제여부) by goju
          newAbdacov.aldacov.zzprmtrSCSFg = el.GafProductInfo.zzprmtrSCSFg// 통합파라메터셋팅변경

          /** 20160601 yherp.kwon : 의무납입개월 수 및 자유입출금형태별분류코드추가 */
          // newAbdacov.aldacov.zzoblgPadMsVl = aldapolpr.zzprmtrPadCCd == "02" ? "0" : "24"; //일시납이면 0 그외 24
          // newAbdacov.aldacov.zzfreioClsfCd = rd_zzfreioClsfCd;

          // 보험코드구분코드
          // newAbdacov.aldacov.zzinscdScCd = GafMainFunc.getIfbcValue("ZZPDINS_R_C_C_ID", el.templateId);
          // 즉시연금거치기간
          newAbdacov.aldacov.zzianutDfrPVl = baseContDto.zzianutDfrPVl

          // 갱신특약구분코드 : 갱신이면01 갱신 아니면 00 갱신주기 값 여부로 판단
          if (GafMainFunc.getIfbcValue('ZZRENW_CYL_ID', el.templateId) === '0') {
            newAbdacov.aldacov.zzrenwCovScCd = '00'
          } else {
            newAbdacov.aldacov.zzrenwCovScCd = '01'
          }
          // 갱신주기
          newAbdacov.aldacov.zzrenwCylVl = GafMainFunc.getIfbcValue('ZZRENW_CYL_ID', el.templateId)
          // 갱신버전코드
          newAbdacov.aldacov.zzrenwVcdCd = GafMainFunc.getIfbcValue('ZZRENW_VCD_CD', el.templateId)
          // 고액계약보험료적립여부(baseContDto.zzlgatAcumFg :고액적립 / baseContDto.zzlgatDiscFg : 고액할인)
          // 0 : 고액할인 / 1 : 고액적립  --> msg요청
          // 고액할인여부 디폴트요청 : 20170829 이병현선임. 주보험일때만 셋팅하도록 함
          if (newAbdacov.aldacov.elementprodtpCd === '10') {
            newAbdacov.aldacov.zzlacpAcumFg = '0' // 고액할인여부값 디폴트요청
            if (baseContDto.zzlgatAcumFg === 'Y') { // 고액적립
              newAbdacov.aldacov.zzlacpAcumFg = '1'
            } else if (baseContDto.zzlgatDiscFg === 'Y') { // 고액할인
              newAbdacov.aldacov.zzlacpAcumFg = '0'
            }
          }
          // 파라미터개인가족구분코드
          // newAbdacov.aldacov.zzprmtrPFSCd = aldapolpr.zzprmtrPFSCd;
          /** 20161115 yherp.kwon : 변경 */
          newAbdacov.aldacov.zzprmtrPFSCd = el.GafProductInfo.zzprmtrPFSCd// 통합파라메터셋팅변경

          // 파라미터보험기간코드-통합파라메터셋팅변경
          newAbdacov.aldacov.zzprmtrInprdCd = el.GafProductInfo.zzprmtrInprdCd
          // 파라미터납입기간코드-통합파라메터셋팅변경
          // newAbdacov.aldacov.zzprmtrPmprdCd = aldapolpr.zzprmtrPrdLCd;
          // 파라미터제2보험기간개시연령코드-통합파라메터셋팅변경
          // newAbdacov.aldacov.zzprmtr2BACd = aldapolpr.zzprmtr2BACd;
          // 퇴직연령,생활자금나이 동일항목 사용
          newAbdacov.aldacov.zzrtmAgeVl = baseContDto.zzrtmAgeVl
          // 연금지급유형코드LIST - 확인필요.우선 하드코딩
          // newAbdacov.aldacov.zzanuPTCLVl = "01,02,03,07,08";
          // 가입시점연금지급유형코드LIST - 확인필요
          newAbdacov.aldacov.zzeAPTCLVl = Gaf.getTObj('prodInfo').baseInfoDto.zzeAPTCLVl
          // 파라미터채널구분코드
          newAbdacov.aldacov.zzprmtrChnSCd = el.GafProductInfo.zzprmtrChnSCd// 통합파라메터셋팅변경
          // 파라미터주피보험자종피보험자성별구분코드-통합파라메터셋팅변경
          // newAbdacov.aldacov.zzpMASSCCd = aldapolpr.zzpMASSCCd;
          // 파라미터연금보증지급년수코드-통합파라메터셋팅변경
          // newAbdacov.aldacov.zzprmAGPYCd = aldapolpr.zzprmAGPYCd;
          /** 20160616 yherp.kwon : ALDACOV 필드 추가 Start */

          // 연금상품일 경우에만 세팅. 박영현 대리님 요청 - 20170518 박세현 수정
          if (anutPrdtYn) {
            // 연금지급보증년수
            newAbdacov.aldacov.zzantguapymysVl = baseContDto.zzantPymPrdVl

            /** 20170529 yherp.kwon : 연금보증년수 -> 연금유형이 확정기간의 경우 N으로 셋팅 상속형인경우 0으로 셋팅 */
            /* if(baseContDto.zzanutPymCd == "02"){
                      newAbdacov.aldacov.zzantguapymysVl = "N";
                  }else  */
            // 확정기간의 경우 알파벳 N이 아니고, N년으로 세팅임. 20170601 박세현 수정
            if (baseContDto.zzanutPymCd === '03') {
              newAbdacov.aldacov.zzantguapymysVl = '0'
            }

            // 가입시점연금지급유형코드
            newAbdacov.aldacov.zzentAntpymtCd = baseContDto.zzanutPymCd
            // 즉시연금상속형지급기간
            newAbdacov.aldacov.zzianuInPPVl = baseContDto.zzianuInPPVl
            // 연금조기집중배수
            newAbdacov.aldacov.zzantearlCnmVl = baseContDto.zzearlCnctMVl
          }

          /** 20160616 yherp.kwon : ALDACOV 필드 추가 End */

          /** 20160621 yherp.kwon : ALDACOV 필드 추가 Start */
          if (Gaf.getProcessNo() === '1001') { // 개인가입설계 Process No = "1001"
            // 보험요율할인유형코드
            newAbdacov.aldacov.zzinsrPrDTCd = '00'
            // 파라미터단체개별전환구분코드(개인가설에서는 해당값 셋팅하지않음)
            newAbdacov.aldacov.zzprmGICSCd = el.GafProductInfo.zzprmGICSCd// 통합파라메터셋팅변경
          }

          // 파라미터주피보험자건강상태코드
          /** 20160901 yherp.kwon : 기본적으로 건강상태코드 및 파라미터주피보험자 건강상태코드를 '0'으로 SET */
          // 통합파라메터세팅변경
          newAbdacov.aldacov.zzprmtrMniHCd = piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y' ? '0' : ''

          newAbdacov.aldacov.zzhlthStatCd = '0'

          /** 20160826 yherp.kwon : 기본계약사항에서 주피,종피건강체를 우량체로 선택한 경우 건강상태코드,파라미터주피보험자건강상태코드 = 1 입력 */
          /** 기본계약사항에서 주피,종피건강체를 고지우량체로 선택한 경우 건강상태코드,파라미터주피보험자건강상태코드 = 4 입력 */
          // 통합파라메터세팅변경
          if (el.insured === '21') {
            if (piCommonUtil.isObj(baseContDto.zzentplHlthsCd)) {
              newAbdacov.aldacov.zzprmtrMniHCd = piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y' ? baseContDto.zzentplHlthsCd : ''
            }
            if (el.preferable === 'Y' && baseContDto.zzentplHlthsCd === '1' ) {
              if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                newAbdacov.aldacov.zzprmtrMniHCd = '1'
              }
              newAbdacov.aldacov.zzhlthStatCd = '1'
            }
            // 고지우량체
            if (el.preferable === 'Y' && baseContDto.zzentplHlthsCd === '4' ) {
              if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                newAbdacov.aldacov.zzprmtrMniHCd = '4'
              }
              newAbdacov.aldacov.zzhlthStatCd = '4'
            }
            if (piCommonUtil.isObj(newAbdacov.covAbdasubjct)) {
              for (let k in newAbdacov.covAbdasubjct) {
                if ((el.insured === newAbdacov.covAbdasubjct[k].zzcontvInsrdCd) &&
                                              newAbdacov.covAbdasubjct[k].zzhlthStatCd === '3') {
                  newAbdacov.aldacov.zzhlthStatCd = '3'
                  // 통합파라메터세팅변경
                  if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                    newAbdacov.aldacov.zzprmtrMniHCd = '0'
                  }
                }
              }
            }
          } else if (el.insured === '23') {
            if (piCommonUtil.isObj(baseContDto.zzentplAisHlthsCd)) {
              newAbdacov.aldacov.zzprmtrMniHCd = piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y' ? baseContDto.zzentplAisHlthsCd : ''
            }
            if (el.preferable === 'Y' && baseContDto.zzentplAisHlthsCd === '1' ) {
              if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                newAbdacov.aldacov.zzprmtrMniHCd = '1'
              }
              newAbdacov.aldacov.zzhlthStatCd = '1'
            }
            if (el.preferable === 'Y' && baseContDto.zzentplAisHlthsCd === '4' ) {
              if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                newAbdacov.aldacov.zzprmtrMniHCd = '4'
              }
              newAbdacov.aldacov.zzhlthStatCd = '4'
            }
            if (piCommonUtil.isObj(newAbdacov.covAbdasubjct)) {
              for (let k in newAbdacov.covAbdasubjct) {
                if ((el.insured === newAbdacov.covAbdasubjct[k].zzcontvInsrdCd) &&
                                                    newAbdacov.covAbdasubjct[k].zzhlthStatCd === '3') {
                  newAbdacov.aldacov.zzhlthStatCd = '3'
                  // 통합파라메터세팅변경
                  if (piCommonUtil.isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd === 'Y') {
                    newAbdacov.aldacov.zzprmtrMniHCd = '0'
                  }
                }
              }
            }
          }
          // 0 표준체 1 우량체 2 비흡연체 3할증체 : 할증체 선택시는 파라미터주피보험자건강상태코드 0으로.
          if (piCommonUtil.isObj(newAbdacov.aldacov.zzprmtrMniHCd) && newAbdacov.aldacov.zzprmtrMniHCd === '3') {
            newAbdacov.aldacov.zzprmtrMniHCd = '0'
          }

          /** 20160826 yherp.kwon : 할증지수가 세팅된 경우 해당 보험 및 InsuredObject의 건강상태코드는 할증체로 반영
              if(el.insured == "21" || el.insured == "23"){

                  $.each(dignIndxn, function(i, el2){
                      if(Number(el2.zaDundwRsltIndxn) > 100){
                          newAbdacov.aldacov.zzhlthStatCd = "3";
                          //통합파라메터세팅변경
                          if(isObj(el.GafProductInfo.zzprmtrMniHCd) && el.GafProductInfo.zzprmtrMniHCd == "Y"){
                              newAbdacov.aldacov.zzprmtrMniHCd = "0";
                          }
                          return false;
                      }
                  });
              } */

          // 책임개시일자 : Gaf.getStandYmd(150D에서 서버 날짜로 셋됨)
          newAbdacov.aldacov.zzrspnBgnDt = moment(Gaf.getStndYmd()).format('YYYYMMDD')
          /** 20160714 : ALDACOV 필드추가(박영현대리요청) */
          // 의료실손상세속성코드
          // newAbdacov.aldacov.zzclnicITACd = GafMainFunc.getIfbcValue("ZZCLNIC_I_T_A_ID", el.templateId);
          // 표준미달체인수가능방법코드
          // newAbdacov.aldacov.zzmnisdImpdaCd = GafMainFunc.getIfbcValue("ZZIMPRD_U_P_M_ID", el.templateId);
          /** 20160621 yherp.kwon : ALDACOV 필드 추가 End */

          /** 20160816 yherp.kwon : Coverage level 계약정보 적용 */
          // 연금지급유형코드LIST
          newAbdacov.aldacov.zzanuPTCLVl = el.GafProductInfo.zzanuPTCLVl
          // 의무납입개월수(보험료계산 205서비스에서 채우도록 변경함)
          // newAbdacov.aldacov.zzoblgPadMsVl = el.GafProductInfo.zzoblgPadMsVl;
          // 자유입출금형태별분류코드
          newAbdacov.aldacov.zzfreioClsfCd = el.GafProductInfo.zzfreioClsfCd
          // 보험코드구분코드
          newAbdacov.aldacov.zzinscdScCd = el.GafProductInfo.zzinscdScCd
          // 보험버전코드
          newAbdacov.aldacov.zzinsrVcdId = el.GafProductInfo.zzinsrVcdId
          // 의료실손상세속성코드
          if (piCommonUtil.isObj(el.GafProductInfo.zzclnicITACd) && el.GafProductInfo.zzclnicITACd !== '00') {
            newAbdacov.aldacov.zzclnicITACd = el.GafProductInfo.zzclnicITACd
          }
          // 주피보험자표준미달체적용코드
          newAbdacov.aldacov.zzmnisdImpdaCd = el.GafProductInfo.zzmnisdImpdaCd

          /** 20160913 yherp.kwon : 추가 ZZGRIC_GR_R_L_VL 체증체감율LIST / ZZGRPRD_LI_VL 체증체감기간LIST /ZZJLI_A_P_R_L_VL 연생연금지급율LIST */
          // 체증체감율LIST
          newAbdacov.aldacov.zzgricGrRLVl = el.GafProductInfo.zzgricGrRLVl
          // newAbdacov.aldacov.zzgricGrRLVl = "0";
          // 체증체감기간LIST
          newAbdacov.aldacov.zzgrprdLiVl = el.GafProductInfo.zzgrprdLiVl
          // 연생연금지급율LIST
          newAbdacov.aldacov.zzjliAPRLVl = el.GafProductInfo.zzjliAPRLVl

          // 연생구분코드 세팅 추가. 20170630 박세현 추가
          newAbdacov.aldacov.zzjliveScCd = el.GafProductInfo.zzjliveScCd

          /** 20161004 yherp.kwon : 승계전계약정보 추가 */
          // 승계전계약일자
          newAbdacov.aldacov.zzscsBfconYDt = baseContDto.zaScsBfconYmd
          /** 20161005 yherp.kwon : 계약이체정보 추가 */
          // 세법적용가입일자
          newAbdacov.aldacov.zztaxLAEYDt = baseContDto.zaTaxLgslAplcYmd

          // 21.07 min.ko ASR210600656 [상품판매준비] 4세대 전환실손 가입설계화면 개발. 실손의료비무사고할인대상여부 세팅
          if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('18') > -1 && piCommonUtil.isObj(Gaf.getVObj('ZRldmContCnvrDto'))) {
            let zRldmContCnvrDto = Gaf.getVObj('ZRldmContCnvrDto')
            newAbdacov.aldacov.zzvpiNcdObjFg = zRldmContCnvrDto.vpiNcdObjFg
          } else {
            newAbdacov.aldacov.zzvpiNcdObjFg = '0'
          }

          newAbdacov.aldacov.gafRefId = 'COV'
          /** 20160617 yherp.kwon : 참고사항 - newAbdacov.covendDt 값이 형식에 맞지 않거나 값이 없거나 하면, 보험료 계산하다가 오류남 DateUtis.class */
          // Coverage End Date 계산
          newAbdacov.covendDt = GafMainFunc.calcExpYmd(newAbdacov.aldacov.zzinprdVl, newAbdacov.covbegDt) // 만기일자 함수 호출
          newAbdacov.techendDt = newAbdacov.covendDt

          if (maxEndDate === '' || maxEndDate < newAbdacov.covendDt) {
            maxEndDate = newAbdacov.covendDt
          }

          // CBO EXT
          newAbdacov.ztpqlCboextAddmAbdacov.deleteFlag = '0'
          newAbdacov.ztpqlCboextAddmAbdacov.productBaseId = el.templateId
          newAbdacov.ztpqlCboextAddmAbdacov.zaInsrNm = el.name
          newAbdacov.ztpqlCboextAddmAbdacov.zaTrtSno = el.seq
          /** 20160628 yherp.kwon : 가입금액 추가 */
          newAbdacov.ztpqlCboextAddmAbdacov.zaEntAmt = GafMainFunc.getUnitAmt(el.covInsAmt, el.unit)

          newAbdacov.ztpqlCboextAddmAbdacov.gafRefId = 'COV'

          // PREM 구성 -----------------------------------------------------
          if (premTemplateId) {
            tempPrem = newAbdacov.covAbdaprem[0]

            // 보험료 계산전 필수값 체크에서 수행하는것으로 수정
            // 20170810 박세현 수정
            let tempPmprdArray = el.pmprdTypSelected.key.split(',')
            //                             if(!tempPmprdArray || tempPmprdArray == "" || tempPmprdArray == "9"){
            //                                 //errors.push("Template : ["+el.templateId+"] insCode : ["+el.insCode+"]"+el.name+" 의 납입기간을 선택해야 합니다.");
            //                                 /** 20170119 yherp.kwon : 메세지 문구 수정 */
            //                                 errors.push("["+el.relTyp+"]"+el.name+"보험의 납입기간이 입력되지 않았습니다.");
            //                                 errorFlag = true;
            //                             }

            /** 20160722 yherp.kwon : zzpmprdVl 계산시 가입연령은 보험주피의 가입연령으로 계산토록 */
            let _ageatentryVl = newAbdacov.covAbdasubjct.filter(function (item) { return item.zzinsuvInsrdCd === '21' })[0].ageatentryVl

            let _zzpmprdVl = GafMainFunc.calcPrd(tempPmprdArray[0], tempPmprdArray[1], _ageatentryVl)

            // ABDA
            tempPrem.deleteFlag = '0'
            tempPrem.productBaseId = premTemplateId
            tempPrem.pmId = premTemplateId
            tempPrem.prembegbDt = newAbdacov.covbegDt
            /** 20160824 yherp.kwon : 보험료납입시작(PREMBEGB_DT)일자에 납입기간을 더해서 계산하도록 변경 */
            tempPrem.premendbDt = piCommonUtil.dateCalc(tempPrem.prembegbDt, parseInt(_zzpmprdVl, 10), 'YY')
            tempPrem.premaftertaxAm = el.covPrm
            tempPrem.mainmaturityDt = GafMainFunc.getDateString_DDMM(newAbdacov.covendDt)

            /** 20160601 yherp.kwon : 납입주기코드 추가 */
            tempPrem.payfrqCd = baseContDto.zaPadCylCd // baseContDto.zaPadCylCd은 105패널에서 셋팅됨.

            /** 20160628 yherp.kwon : 세후보험료 추가 */
            // URLPQL00160 일때, 보험료 설계 팝업을 통해 왔을때는 기존값 유지 - 20170315 박세현 추가
            if (urlId === 'URLPQL00160') {
              tempPrem.premaftertaxAm = Gaf.getPObj('abdapolicy')[0].abdapolpr[0].polprAbdaprem[0].premaftertaxAm
            } else {
              tempPrem.premaftertaxAm = GafMainFunc.getUnitAmt(el.covInsAmt, el.unit)
            }
            tempPrem.gafRefId = 'COV_PREM'

            // 202005 외화보험 통화키
            // tempPrem.currencyId = 'KRW'
            tempPrem.currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

            // ALDA
            tempPrem.covAldaprem.currId = tempPrem.currencyId // 20200917 외화보험 - COV_ALDAPREM.CURR_ID 셋팅
            tempPrem.covAldaprem.deleteFlag = '0'
            tempPrem.covAldaprem.productBaseId = premTemplateId
            tempPrem.covAldaprem.pmId = premTemplateId
            // 가입설계주석 tempPrem.covAldaprem.investmentCd = "1";
            // 가입설계주석 tempPrem.covAldaprem.zzprmPadMthCd = "1"; //TO_DO 정확히 어떤건지...?

            tempPrem.covAldaprem.zzpmprdTypCd = tempPmprdArray[0]
            tempPrem.covAldaprem.zzpmprdTypVl = tempPmprdArray[1]

            tempPrem.covAldaprem.zzpmprdVl = _zzpmprdVl

            tempPrem.covAldaprem.zzpadMmsVl = String(piCommonUtil.NumCal(tempPrem.covAldaprem.zzpmprdVl, 12, '*'))

            /** 20160922 yherp.kwon : 할인/적립대상여부 추가
                  고액계약할인. 적립. 동시가입할인의 경우에는 COV의 주보험에만 적용 기업복지는 모든 특약 적용 */

            if (newAbdacov.aldacov.elementprodtpCd === '10') { // 주보험인경우
              // 고액계약할인대상여부
              tempPrem.covAldaprem.zzlgatCntDcFg = '1'
              if (baseContDto.zzlgatAcumFg === 'Y') { // 고액적립
                tempPrem.covAldaprem.zzlgatCntDcFg = '0'
              }

              // 고액계약적립대상여부
              tempPrem.covAldaprem.zzlgatCntAcFg = '0'
              if (baseContDto.zzlgatAcumFg === 'Y') { // 고액적립
                tempPrem.covAldaprem.zzlgatCntAcFg = '1'
              }

              // 동시가입할인대상여부
              tempPrem.covAldaprem.zzstmusDiscFg = '0'
              if (piCommonUtil.isObj(abdapolpr.ztpqlCboextAddmAbdapolpr) && abdapolpr.ztpqlCboextAddmAbdapolpr.zaStmusEntDiscYn === 'Y') {
                tempPrem.covAldaprem.zzstmusDiscFg = '1'
              }
            }
            // 기업복지할인대상여부-전coverage 셋
            // 급여이체할인여부추가
            tempPrem.covAldaprem.zzentpWlfDcFg = '0'
            tempPrem.covAldaprem.zzsarytrsDscFg = '0'
            // 2018.10 역산할인대상여부(장효상책임요청)
            tempPrem.covAldaprem.zzicalcDiscFg = '0'
            if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentplLineCd) && ['85', '86', '87'].indexOf(abdapolpr.aldapolpr.zzentplLineCd) > -1) {
              tempPrem.covAldaprem.zzentpWlfDcFg = '1'
              tempPrem.covAldaprem.zzsarytrsDscFg = '1'
              // 2018.10 역산할인대상여부(장효상책임요청)
              tempPrem.covAldaprem.zzicalcDiscFg = '1'
            }

            tempPrem.covAldaprem.gafRefId = 'COV_PREM'
          } else {
            let errorStr = 'Template : [' + el.templateId + '] insCode : [' + el.insCode + ']' + el.name + ' 의 PREM Template 이 존재하지 않습니다.'
            Gaf.d(errorStr)
            errors.push(errorStr)
            errorFlag = true
          }

          // BNF 구성 -----------------------------------------------------
          if (bnfTemplateId) {
            tempBnf = newAbdacov.covAbdabnf[0]

            // ABDA
            tempBnf.deleteFlag = '0'
            tempBnf.productBaseId = bnfTemplateId
            tempBnf.pmId = bnfTemplateId
            tempBnf.gafRefId = 'COV_BNF'
            tempBnf.endofbnfDt = newAbdacov.covbegDt

            // ALDA
            tempBnf.covAldabnf.currencyId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 20200917 외화보험 - COV_ALDABNF.CURRENCY_ID 셋팅
            tempBnf.covAldabnf.deleteFlag = '0'
            tempBnf.covAldabnf.productBaseId = bnfTemplateId
            tempBnf.covAldabnf.pmId = bnfTemplateId

            // 보험코드구분코드가 18,47,48인경우에는 가입금액 필수값 체크하지 않음. 조형철 과장님 요청 - 20160907 박세현 수정
            // 보험코드구분코드가 47,48인경우에는 가입금액 필수값 체크하지 않음. 조형철 과장님 요청
            //                          let zzinscdScCd = GafMainFunc.getIfbcValue("ZZPDINS_R_C_C_ID", el.templateId);
            let zzinscdScCd = el.zzinscdScCd
            // 암생활비지급서비스(61) 가입금액 체크예외 추가(2020-07-10)
            // 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
            // ASR240500456 [상품판매준비] 내인생플러스 가입설계 화면 개발_사랑온 - 보험구분코드(65)
            if (zzinscdScCd !== '47' && zzinscdScCd !== '48' && zzinscdScCd !== '61' && !(zzinscdScCd === '62' && el.covInsAmt === '') && !(zzinscdScCd === '64' && el.covInsAmt === '') && zzinscdScCd !== '65') {
              // 보험료 계산전 필수값 체크에서 수행하는것으로 수정
              // 20170810 박세현 수정
              //                                 if(!el.covInsAmt || el.covInsAmt == ""){
              //                                     //errors.push("Template : ["+el.templateId+"] insCode : ["+el.insCode+"]"+el.name+" 의 가입금액을 입력해야 합니다.");
              //                                     /** 20170119 yherp.kwon : 메세지 문구 수정 */
              //                                     errors.push("["+el.relTyp+"]"+el.name+"보험의 가입금액이 입력되지 않았습니다.");
              //                                     errorFlag = true;
              //                                 }
              /** 20160628 yherp.kwon : 변경 */
              // tempBnf.covAldabnf.insamountabsAm = GafMainFunc.get10000Amt(el.covInsAmt); // 절대보험금액
              tempBnf.covAldabnf.insamountabsAm = GafMainFunc.getUnitAmt(el.covInsAmt, el.unit)
            }
            // 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
            // ASR240500456 [상품판매준비] 내인생플러스 가입설계 화면 개발_사랑온 - 보험구분코드(65)
            if ((zzinscdScCd === '62' && el.covInsAmt === '') || (zzinscdScCd === '64' && el.covInsAmt === '') || zzinscdScCd === '65') {
              tempBnf.covAldabnf.insamountabsAm = ''
            }
            // 가입설계주석 tempBnf.covAldabnf.insamountcdAm = "2"; // 보험금액id "2":절대보험금액
            // 가입설계주석 tempBnf.covAldabnf.zzgricgrdcScCd = "01";
            // 가입설계주석 tempBnf.covAldabnf.zzprtPymMthCd = "01";

            // ALDA - 연금관련 TO_DO 연금상품인경우 처리하도록 해야함.... 관련 상세내용도 수정 필요

            tempBnf.covAldabnf.zz1yrAntFrsAm = tempBnf.covAldabnf.insamountabsAm // 1차년도 연금재원금액
            tempBnf.covAldabnf.annuitybegDt = '2016-10-30' // 연금시작
            /** 20160602 yherp.kwon : 기본계약사항과 연계 */
            // tempBnf.covAldabnf.payfrqCd = "12"; // 납입주기 (12 => 월납, 1 => 년납) TO_DO 기본계약사항과 연계필요
            // tempBnf.covAldabnf.payfrqCd = baseContDto.zaPadCylCd; //baseContDto.zaPadCylCd은 105패널에서 셋팅됨.
            tempBnf.covAldabnf.payfrqCd = GafMainFunc.getIfbcValue('PAYFRQ_CD', bnfTemplateId) // baseContDto.zaPadCylCd은 105패널에서 셋팅됨.

            // 가입설계주석 tempBnf.covAldabnf.endmatfactVl = "100"; // 양로급부합계비율 100 하드코딩 TO_DO 맞는지 확인
            tempBnf.covAldabnf.pensionAm = '2000000' // 납입주기에 따른 연금
            // 가입설계주석 tempBnf.covAldabnf.annuityendDt = "2016-10-30";
            // 2018.07 생활자금지급기간 추가(장효상책임요청)
            tempBnf.covAldabnf.zzlvngFPYVl = baseContDto.lvngFdPymPrd

            if (anutPrdtYn) { // 연금인 경우 (가입시점연금지급유형코드가 유효할 경우)
              if (newAbdacov.aldacov.elementprodtpCd === '10') { // 주보험인경우
                //                                    tempBnf.covAldabnf.zzanutBMnAVl = Number(tempSubjct.ageatentryVl) + Number(newAbdacov.aldacov.zzinprdVl); // 연금개시주피보험자연령 TO_DO 기본계약사항과 연계필요
                // 연금개시주피보험자연령에 연금개시연령으로 세팅. 박영현 책임님 요청. 20170615 박세현 수정
                tempBnf.covAldabnf.zzanutBMnAVl = baseContDto.zaAnutBgnAge
              }

              /**
                      ** 연금지급유형코드, 연금보증지급년수, 연금지급기간, 연생연금지급율, 조기집중배수, 조기집중년수 는 연금상품일 경우에만 세팅
                      ** 박영현 대리님 요청 - 20170518 박세현 수정
                      **/
              tempBnf.covAldabnf.zzantPymPrdVl = '999' // 연금지급기간
              /** 20170517 yherp.kwon : 연금지금기간 변경 연금유형을 확정기간 OR 상속형으로 선택한 경우 */
              if (baseContDto.zzanutPymCd === '02') {
                //                                  tempBnf.covAldabnf.zzantPymPrdVl = "N"; // 연금유형이 확정기간형인 경우 연금지급기간 = N
                tempBnf.covAldabnf.zzantPymPrdVl = baseContDto.zzantPymPrdVl
              } else if (baseContDto.zzanutPymCd === '03') { // 연금유형이 상속형인 경우
                if (baseContDto.zzantPymPrdVl === '999') { // 종신
                  tempBnf.covAldabnf.zzantPymPrdVl = '999'
                } else {
                  //                                      tempBnf.covAldabnf.zzantPymPrdVl = "N";
                  tempBnf.covAldabnf.zzantPymPrdVl = baseContDto.zzantPymPrdVl
                }
              } else if (baseContDto.zzanutPymCd === '10') { // 연금유형이 실적배당종신연금일때
                tempBnf.covAldabnf.zzantPymPrdVl = '0'
              }

              // 연금지급유형코드 세팅방식 변경 20161214 박세현 수정
              if (baseContDto.zzanutPymCd === '02' || baseContDto.zzanutPymCd === '03') {
                tempBnf.covAldabnf.zzanutPymCd = baseContDto.zzanutPymCd
                // 20170529 yherp.kwon : 종신브릿지 형의 경우 연금지급유형코드를 04로 셋팅
              } else if (baseContDto.zzanutPymCd === '60') {
                tempBnf.covAldabnf.zzanutPymCd = '04'
                // 20170610 실적배당종신연금의 경우 연금지급유형코드를 10으로 세팅
              } else if (baseContDto.zzanutPymCd === '10') {
                tempBnf.covAldabnf.zzanutPymCd = '10'
              } else {
                tempBnf.covAldabnf.zzanutPymCd = '01'
              }

              /** 20160616 yherp.kwon : ALDABNF 필드 추가 */
              // 연금지급보증년수
              tempBnf.covAldabnf.zzantguapymysVl = baseContDto.zzantPymPrdVl

              /** 20170529 yherp.kwon : 연금보증년수 -> 연금유형이 확정기간의 경우 N으로 셋팅 상속형인경우 0으로 셋팅 */
              /* if(baseContDto.zzanutPymCd == "02"){
                          tempBnf.covAldabnf.zzantguapymysVl = "N";
                      }else  */

              // 확정기간의 경우 알파벳 N이 아니고, N년으로 세팅임. 20170601 박세현 수정
              // 실적배당 종신연금도 연금보증지급년수 0으로 세팅. 20170610 박세현 수정
              if (baseContDto.zzanutPymCd === '03' || baseContDto.zzanutPymCd === '10') {
                tempBnf.covAldabnf.zzantguapymysVl = '0'
              }

              // 연생연금지급율
              /**
                20220418 ASR220301059 / 연금정보 반영기준 추가 요청 / young21.kwon
                □ 요청사항 : 연금유형에 따라 연금정보 세팅하고 있는 프로그램에 '연금연생구분코드' 세팅로직 추가
                1) 연금유형 53, 54, 55, 56, 57, 58의 경우
                    'ALDABNF > 연금연생구분코드 ' 에 '02'(연생) 값을 UPDATE
                2) 그 外 연금유형 01 ,02 ,03 ,10 , 60의 경우
                    'ALDABNF > 연금연생구분코드 ' 에 '01'(단생) 값을 UPDATE
                ※ 연금연생구분코드 : zzanutJlivSCd[COV_ALDABNF.ZZANUT_JLIV_S_CD]
              */
              switch (baseContDto.zzanutPymCd) {
                case '53' : // 종신연생70%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '0.7'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break
                case '54' : // 종신연생100%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '1.0'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break
                case '55' : // 종신연생20%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '0.2'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break
                case '56' : // 종신연생50%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '0.5'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break
                case '57' : // 종신연생150%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '1.5'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break
                case '58' : // 종신연생200%
                  tempBnf.covAldabnf.zzjlvantPymrVl = '2.0'
                  tempBnf.covAldabnf.zzanutJlivSCd = '02'
                  break

                  // 연금연생지급율 default 값은 0으로 세팅되어야 함. 20170610 박세현 수정
                default :
                  tempBnf.covAldabnf.zzjlvantPymrVl = '0'
                  tempBnf.covAldabnf.zzanutJlivSCd = '01'
                  break
              }
              tempBnf.covAldabnf.zzearlCnctYVl = baseContDto.zzearlCnctYVl // 조기집중년수
              tempBnf.covAldabnf.zzearlCnctMVl = baseContDto.zzearlCnctMVl // 조기집중배수
            }

            if (!piCommonUtil.isObj(baseContDto) || baseContDto.length === 0) {
              Gaf.d('연금개시연령 없음')
            } else {
              Gaf.d('연금개시연령 : ' + baseContDto.zaAnutBgnAge)
              tempBnf.covAldabnf.zzanutBMnAVl = baseContDto.zaAnutBgnAge
            }

            // 가입설계주석 tempBnf.covAldabnf.zzantpymobjscCd = "01";

            tempBnf.covAldabnf.gafRefId = 'COV_BNF'
          } else {
            let errorStr = 'Template : [' + el.templateId + '] insCode : [' + el.insCode + ']' + el.name + ' 의 BNF Template 이 존재하지 않습니다.'
            Gaf.d(errorStr)
            errors.push(errorStr)
            errorFlag = true
          }

          // BNFCRY 구성 (수익자)
          if (!rd_covcptypeCdArray || rd_covcptypeCdArray.length === 0) { // 만약 이 값이 없으면 임시로 셋팅 00001 => 주보험에 아직 급부코드가 없어서... PM issue 할때 수익자 없다고 에러남. 그래서 강제 생성
            rd_covcptypeCdArray = ['00001']
          }
          if (bnfcryTemplateId && rd_covcptypeCdArray && rd_covcptypeCdArray.length > 0 && bnfrPanel) {
            Gaf.d('수익자 bnfcryTemplateId : ' + bnfcryTemplateId)

            let bnfrGridData = Gaf.getVObj('PNLPQL00104_grid')
            let prevBnfcryArray = null
            if (newAbdacov.covAbdabnfcry) {
              prevBnfcryArray = GafMainFunc.copyJson(newAbdacov.covAbdabnfcry)
            }

            // ABDACOV 의 수익자 초기화
            newAbdacov.covAbdabnfcry = []

            for (let k in rd_covcptypeCdArray) {
              let rd_covcptypeCd = rd_covcptypeCdArray[k]
              Gaf.d('수익자 rd_covcptypeCd : ' + rd_covcptypeCd)

              // 해당 수익자 찾기 (from 104 수익자패널)
              let bnfrTyp = bnfrPanel.toGridBnfTyp(rd_covcptypeCd)
              Gaf.d('수익자 bnfrTyp : ' + bnfrTyp)

              // 해당 수익자 찾기
              let prcYn = null
              bnfrGridData.forEach((bnfrElem, k) => {
                // 처리여부
                prcYn = true
                // 수익자 이름, 주민번호, 지급율 없으면 continue;
                if (!bnfrElem.bnfrName || bnfrElem.bnfrName === '' ||
                          !bnfrElem.bnfrRrn || bnfrElem.bnfrRrn === '' ||
                          !bnfrElem.rto || bnfrElem.rto === ''
                ) {
                  prcYn = false
                }

                Gaf.d('insrdTyp  ' + bnfrElem.insrdTypCd + ' : ' + tempSubjct.zzcontvInsrdCd)
                Gaf.d('insrdChld  ' + bnfrElem.insrdChldSno + ' : ' + tempSubjct.zzchldSnoVl)
                Gaf.d('bnfrCdVal  ' + bnfrElem.bnfrCdVal + ' : ' + bnfrTyp)

                if (prcYn &&
                          bnfrElem.insrdTypCd === tempSubjct.zzcontvInsrdCd &&
                          String(bnfrElem.insrdChldSno) === String(tempSubjct.zzchldSnoVl) &&
                          bnfrElem.bnfrCdVal === bnfrTyp
                ) {
                  let tempBnfcry = null

                  // 기존 설계데이터에 같은 급부(rd_covcptypeCd)의 같은 수익자 정보(bnfrElem.partnerId) 있는지 체크
                  if (prevBnfcryArray) {
                    for (let e in prevBnfcryArray) {
                      if (bnfrElem.partnerId === prevBnfcryArray[e].partnerId &&
                                      rd_covcptypeCd === prevBnfcryArray[e].covcptypeCd) {
                        tempBnfcry = prevBnfcryArray[e]
                        break
                      }
                    }
                  }
                  Gaf.d('tempBnfcry => ', tempBnfcry)

                  // 기존 객체 없으면 생성
                  if (!tempBnfcry) {
                    tempBnfcry = {}
                    Gaf.d('new Bnfcry !!!!')
                  } else {
                    Gaf.d('prev Bnfcry !!!!')
                  }

                  // ABDACOV 에 수익자 추가
                  newAbdacov.covAbdabnfcry.push(tempBnfcry)

                  // 수익자 내용 구성
                  tempBnfcry.deleteFlag = '0'
                  tempBnfcry.productBaseId = bnfcryTemplateId
                  tempBnfcry.pmId = bnfcryTemplateId
                  tempBnfcry.gafRefId = 'COV_BNFCRY'
                  tempBnfcry.definedatDt = newAbdacov.covbegDt

                  // 가입설계주석tempBnfcry.rankvlCd = "01";
                  // 가입설계주석tempBnfcry.refusalCd = "001";
                  tempBnfcry.currencyId = abdapolpr.currencyId
                  tempBnfcry.covcptypeCd = rd_covcptypeCd
                  tempBnfcry.zzbpRltnId = tempSubjct.partnerId // 피보험자 BP_ID
                  tempBnfcry.zzcontvInsrdCd = bnfrElem.insrdTypCd // 계약관점피보험자유형코드
                  tempBnfcry.partnerId = bnfrElem.partnerId // 수익자 BP_ID

                  tempBnfcry.shareVl = bnfrElem.rto // 수익자 지급율

                  if (bnfrElem.bnfrRrn === '0000000000000') {
                    tempBnfcry.bnftpCd = '006' // 상속인
                  } else {
                    tempBnfcry.bnftpCd = '001' // 이름별
                  }
                  Gaf.getTObj('rltnrInfo').forEach((insuredElem, e) => {
                    if (insuredElem.partnerId === tempBnfcry.partnerId) {
                      if (insuredElem.zzcontvInsrdCd === '11') {
                        tempBnfcry.bnftpCd = '002' // 보험계약자
                      } else {
                        tempBnfcry.bnftpCd = '003' // 피보험자
                      }
                    }
                  })

                  // CBO EXT
                  if (!tempBnfcry.ztpqlCboextAddmAbdabnfcry) {
                    tempBnfcry.ztpqlCboextAddmAbdabnfcry = {}
                  }
                  tempBnfcry.ztpqlCboextAddmAbdabnfcry.zaAgtFileId = '' // Agent File Id
                  tempBnfcry.ztpqlCboextAddmAbdabnfcry.zaBnfrSno = bnfrElem.bnfrSno // 수익자일련번호
                  tempBnfcry.ztpqlCboextAddmAbdabnfcry.zzbnfrRolCd = bnfrElem.bnfrCdVal // 수익자역할코드
                  tempBnfcry.ztpqlCboextAddmAbdabnfcry.zzchldSnoVl = tempSubjct.zzchldSnoVl // 자녀일련번호
                }
              })
            }
          }

          if (infrdTemplateId) {
            let tempInfrd = newAbdacov.covAbdainfrd[0]

            if (!tempInfrd) {
              tempInfrd = {}
              newAbdacov.covAbdainfrd.push(tempInfrd)
            }

            tempInfrd.currencyId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 20200917 외화보험 - COV_ABDAINFRD.CURRENCY_ID 셋팅
            tempInfrd.deleteFlag = '0'
            tempInfrd.productBaseId = infrdTemplateId
            tempInfrd.pmId = infrdTemplateId
            tempInfrd.gafRefId = 'COV_INFRD'

            tempInfrd.incinfrdbegDt = newAbdacov.covendDt // 다음갱신일 = COV END Date
            // tempInfrd.incinfrdendDt = GafMainFunc.calcExpYmd(newAbdacov.aldacov.zzinprdVl, newAbdacov.covendDt); //다음 만기일자 함수 호출

            // tempInfrd.lstmodDt = newAbdacov.covbegDt;
            // tempInfrd.pervlCd = GafMainFunc.getIfbcValue("ZZRENW_CYL_ID", newAbdacov.productBaseId); //갱신주기
            tempInfrd.increaseletCd = GafMainFunc.getIfbcValue('INCREASEVAR_CD', infrdTemplateId) // Adjustment ID
            tempInfrd.infrdCd = GafMainFunc.getIfbcValue('INFRD_CD', infrdTemplateId) // Adjustment 유형
            tempInfrd.infrctCd = GafMainFunc.getIfbcValue('INFRCT_CD', infrdTemplateId) // Adjustment 인상기준
            let _tmpIfbc = el.GafProductInfo.maxadjustmentageCd // 갱신최대연령
            // tempInfrd.maxadjustmentageCd = isEmpty2(_tmpIfbc) ? "100" :_tmpIfbc; //최대 조정 기간

            /** 20160726 yherp.kwon : 갱신최대연령 변경 */

            /** 주보험보험기간 정의
                      - 주보험 보험기간유형값이 세형인 경우 : [주보험보험기간유형값] - [주보험보험주피의 보험연령]
                        ex) 65세만기, 보험주피30세 -> 보험기간 : 35
                      - 주보험 보험기간유형값이 연형인 경우 : [주보험보험기간유형값]
                        ex) 30년만기, 보험주피30세 -> 보험기간 : 30
                  */
            // 주보험의주피
            // let mainCovAbdasubjct = mainInsAbdacov.covAbdasubjct.filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
            // 주보험보험기간
            let mainCovInprd = null
            if (mainInsAbdacov.aldacov.zzinprdTypCd === '02' || mainInsAbdacov.aldacov.zzinprdTypCd === '30') {
              mainCovInprd = piCommonUtil.NumCal(mainInsAbdacov.aldacov.zzinprdTypVl, insuredOriginal.ageatentryVl, '-')
            } else {
              mainCovInprd = mainInsAbdacov.aldacov.zzinprdTypVl
            }

            if (piCommonUtil.isEmpty2(_tmpIfbc)) {
              tempInfrd.maxadjustmentageCd = '100'
            } else if (_tmpIfbc === 'R1') {
              // [보험주피의 보험나이] + [주보험보험기간]
              tempInfrd.maxadjustmentageCd = piCommonUtil.NumCal(insuredOriginal.ageatentryVl, mainCovInprd, '+')
            } else if (_tmpIfbc === 'R2') {
              // Min (100세,  Max(80세, [보험주피의 보험나이] + [주보험보험기간]) )
              tempInfrd.maxadjustmentageCd =
                          Math.min(100, Math.max(80, piCommonUtil.NumCal(insuredOriginal.ageatentryVl, mainCovInprd, '+')))
            } else if (_tmpIfbc === 'R3') {
              tempInfrd.maxadjustmentageCd = '80'
            } else if (_tmpIfbc === 'R4') {
              // Min (100세, [보험주피의 보험나이] + [주보험보험기간])
              tempInfrd.maxadjustmentageCd =
                          Math.min(100, piCommonUtil.NumCal(insuredOriginal.ageatentryVl, mainCovInprd, '+'))
            } else if (_tmpIfbc === 'R5') {
              // Min (80세, [보험주피의 보험나이] + [주보험보험기간])
              tempInfrd.maxadjustmentageCd =
                          Math.min(80, piCommonUtil.NumCal(insuredOriginal.ageatentryVl, mainCovInprd, '+'))
            } else if (piCommonUtil.isNumber(_tmpIfbc)) { // 위의 케이스가 아니고 숫자로 오는 경우
              tempInfrd.maxadjustmentageCd = _tmpIfbc
            }

            _tmpIfbc = el.GafProductInfo.maxadjustmentperiodVl // 갱신최대기간
            tempInfrd.maxadjustmentperiodVl = !piCommonUtil.isObj(_tmpIfbc) ? '999' : _tmpIfbc // 최대 조정 기간2

            let maxadjustmentage = piCommonUtil.NumCal(tempInfrd.maxadjustmentageCd, insuredOriginal.ageatentryVl, '-')
            tempInfrd.incinfrdendDt = this.fnCalcIncinfrdendYmd(maxadjustmentage, tempInfrd.maxadjustmentperiodVl, Gaf.getStndYmd()) // 최대조정기간
            tempInfrd.infrdcovext = newAbdacov.covendDt// 다음 조정일
            tempInfrd.zzrenwAgeVl = insuredOriginal.ageatentryVl// 가입연령(보험연령)

            /** 20160816 yherp.kwon : Coverage level 계약정보 적용 */
            tempInfrd.pervlCd = el.GafProductInfo.pervlCd // 갱신주기(조정 빈도)
            tempInfrd.adjstatusCd = '0' // default  0 : 조정가능 활용(미사용속성)
            tempInfrd.zzrEntCylVl = el.GafProductInfo.zzrEntCylVl // 재가입주기
            // tempInfrd.maxadjustmentageCd = el.GafProductInfo.maxadjustmentageCd; //갱신최대연령
            // tempInfrd.maxadjustmentperiodVl = el.GafProductInfo.maxadjustmentperiodVl; //갱신최대기간
          }

          Gaf.d('New Abdacov 생성 => ', newAbdacov)
          abdacovList.push(newAbdacov)
        }

        /** 20160614 yherp.kwon : cov 생성중 오류가 있다면 하단 로직 흐르지 않도록 - cov 오류 있을시 하단 로직에서 null 오류 날수 있기에 */
        // if(errorFlag === true){
        //  Gaf.d("PNLPQL00109 toPmodel New Abdacov errors : ",errors);
        //  return errors;
        // }

        if (!abdapolpr.abdacovpac) {
          abdapolpr.abdacovpac = [{}]
        }

        if (abdapolpr.abdacovpac.length < (covPacIdx + 1)) {
          abdapolpr.abdacovpac.push({})
        }

        abdapolpr.abdacovpac[covPacIdx].abdacov = abdacovList
        abdapolpr.abdacovpac[covPacIdx].deleteFlag = '0'
        abdapolpr.abdacovpac[covPacIdx].gafRefId = 'COVPAC'

        if (!piCommonUtil.isObj(covPacEl.isNotExistsCovPac)) { // COVPAC 적용 상품이면,
          abdapolpr.abdacovpac[covPacIdx].productBaseId = covPacEl.templateId // template id
          abdapolpr.abdacovpac[covPacIdx].pmId = covPacEl.templateId
          abdapolpr.abdacovpac[covPacIdx].zzcontvInsrdCd = covPacEl._insrdCd
          if (covPacEl._insrdCd === '24') { // 자녀는 자녀일련번호 setting
            abdapolpr.abdacovpac[covPacIdx].zzchldSnoVl = covPacEl._childNo
          }
        }

      // COV P 모델 생성 끝 !!!! ====================================================================
      }) // COVPAC LOOP END

      if (!mainInsAbdacov) {
        let errorStr = '선택된 주보험이 없습니다!!!'
        Gaf.d(errorStr)
        errors.push(errorStr)
        errorFlag = true
      }

      if (errorFlag === true) {
        Gaf.d('PNLPQL00109 toPmodel New COVPAC errors : ', errors)
        return errors
      }

      /* ===============================================KYH========================================================== */

      // End Date 생성  ========================================================================
      let t_abdapolicy = Gaf.getPObj('abdapolicy')
      t_abdapolicy[0].polendDt = maxEndDate
      abdapolpr.polprodendDt = t_abdapolicy[0].polendDt
      // 2017.09.04 재설계시 기준일자 재 셋팅  ========================================================================
      t_abdapolicy[0].polbegDt = Gaf.getStndYmd()
      t_abdapolicy[0].generatioDetDt = t_abdapolicy[0].polbegDt
      t_abdapolicy[0].generationDt = t_abdapolicy[0].polbegDt

      Gaf.setPModel('abdapolicy', t_abdapolicy)
      abdapolpr.polprodbegDt = Gaf.getStndYmd()
      abdapolpr.date1Dt = Gaf.getStndYmd()
      abdapolpr.techstartDt = abdapolpr.polprodbegDt
      abdapolpr.generationDt = abdapolpr.polprodbegDt
      abdapolpr.generatioDetDt = abdapolpr.polprodbegDt
      abdapolpr.ztpqlCboextPrcsAbdapolpr.zaRspnBgnYmd = t_abdapolicy[0].polbegDt.replace('-', '').replace('-', '')
      abdapolpr.ztpqlCboextPrcsAbdapolpr.zaEntplStoreYmd = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaRspnBgnYmd

      // POLPR_PREM 생성  ========================================================================

      let tempPolprPrem = abdapolpr.polprAbdaprem[0]

      if (!tempPolprPrem) {
        tempPolprPrem = {
          polprAldaprem: {}
        }
      }

      // POLPR_PREM 상품 Struct 구하기
      let childStructOfPolpr = GafMainFunc.getChildProdStruct('', 'POLPR')
      let polprPremTemplateId = null

      for (let subElem of childStructOfPolpr) {
        if (subElem.refId === 'POLPR_PREM') {
          let rd_premCd = GafMainFunc.getIfbcValue('PREM_CD', subElem.templateId)

          if (abdapolpr.aldapolpr.zzprmtrPadCCd === '02' || (piCommonUtil.isObj(baseContDto.zaPadCylCd) && baseContDto.zaPadCylCd === '00')) { // 일시납
            if (rd_premCd === '002') {
              polprPremTemplateId = subElem.templateId
            }
          } else { // 월납,기타
            if (rd_premCd === '001') { // 001 비일시납
              polprPremTemplateId = subElem.templateId
            }
          }

          if (polprPremTemplateId && polprPremTemplateId !== '') {
            break
          }
        }
      }

      if (polprPremTemplateId) {
        tempPolprPrem.deleteFlag = '0'
        tempPolprPrem.productBaseId = polprPremTemplateId
        tempPolprPrem.pmId = polprPremTemplateId
        tempPolprPrem.prembegbDt = mainInsAbdacov.covbegDt
        /** 20160824 yherp.kwon : 보험료납입시작(PREMBEGB_DT)일자에 납입기간을 더해서 계산하도록 변경 ==> 주보험의 납입기간 사용 ==> 결국 주보험 정보와 동일 */
        // tempPolprPrem.premendbDt = maxEndDate;
        tempPolprPrem.premendbDt = mainInsAbdacov.covAbdaprem[0].premendbDt

        tempPolprPrem.premaftertaxAm = mainInsAbdacov.covAbdaprem[0].premaftertaxAm
        tempPolprPrem.mainmaturityDt = mainInsAbdacov.covAbdaprem[0].mainmaturityDt
        /** 20160601 yherp.kwon : 납입주기코드 추가 */
        if (baseContDto.zaPadCylCd != null && baseContDto.zaPadCylCd !== '') {
          tempPolprPrem.payfrqCd = baseContDto.zaPadCylCd // baseContDto.zaPadCylCd은 105패널에서 셋팅됨.
        } else {
          // 이 시점에서 aldapolpr 이 없음 20160930 박세현 수정
          //                      tempPolprPrem.payfrqCd  = aldapolpr.zzprmtrPadCCd == "02" ? "00" : "12"; //일시납이면 00 월납 12
          tempPolprPrem.payfrqCd = t_abdapolicy[0].abdapolpr[0].aldapolpr.zzprmtrPadCCd === '02' ? '00' : '12' // 일시납이면 00 월납 12
        }

        tempPolprPrem.gafRefId = 'POLPR_PREM'

        // ALDA
        tempPolprPrem.polprAldaprem.currId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 20200917 외화보험 - POLPR_ALDAPREM.CURR_ID 셋팅
        tempPolprPrem.polprAldaprem.deleteFlag = '0'
        tempPolprPrem.polprAldaprem.productBaseId = polprPremTemplateId
        tempPolprPrem.polprAldaprem.pmId = polprPremTemplateId
        tempPolprPrem.polprAldaprem.zzprmPadMthCd = mainInsAbdacov.covAbdaprem[0].covAldaprem.zzprmPadMthCd
        tempPolprPrem.polprAldaprem.investmentCd = mainInsAbdacov.covAbdaprem[0].covAldaprem.investmentCd
        tempPolprPrem.polprAldaprem.zzpmprdTypCd = mainInsAbdacov.covAbdaprem[0].covAldaprem.zzpmprdTypCd
        tempPolprPrem.polprAldaprem.zzpadMmsVl = mainInsAbdacov.covAbdaprem[0].covAldaprem.zzpadMmsVl
        tempPolprPrem.polprAldaprem.zzpmprdTypVl = mainInsAbdacov.covAbdaprem[0].covAldaprem.zzpmprdTypVl
        tempPolprPrem.polprAldaprem.zzpmprdVl = mainInsAbdacov.covAbdaprem[0].covAldaprem.zzpmprdVl
        //                  tempPolprPrem.polprAldaprem.zzrlpadPrmAm = "500000";
        /** 20160628 yherp.kwon : 추가 */
        tempPolprPrem.polprAldaprem.zzrlpadPrmAm = mainInsAbdacov.covAbdabnf[0].covAldabnf.insamountabsAm

        /** 20160922 yherp.kwon : 할인/적립대상여부 추가
          기본계약사항 해당 항목 유무에 관계없이 디폴트값 셋팅-20160922박영현 대리님 확인 */
        // 고액계약할인대상여부
        tempPolprPrem.polprAldaprem.zzlgatCntDcFg = '1'
        if (baseContDto.zzlgatAcumFg === 'Y') { // 고액적립
          tempPolprPrem.polprAldaprem.zzlgatCntDcFg = '0'
        }

        // 고액계약적립대상여부
        tempPolprPrem.polprAldaprem.zzlgatCntAcFg = '0'
        if (baseContDto.zzlgatAcumFg === 'Y') { // 고액적립
          tempPolprPrem.polprAldaprem.zzlgatCntAcFg = '1'
        }

        // 동시가입할인대상여부
        tempPolprPrem.polprAldaprem.zzstmusDiscFg = '0'
        if (piCommonUtil.isObj(abdapolpr.ztpqlCboextAddmAbdapolpr) && abdapolpr.ztpqlCboextAddmAbdapolpr.zaStmusEntDiscYn === 'Y') {
          tempPolprPrem.polprAldaprem.zzstmusDiscFg = '1'
        }

        // 기업복지할인대상여부
        // 급여이체할인여부추가
        tempPolprPrem.polprAldaprem.zzentpWlfDcFg = '0'
        tempPolprPrem.polprAldaprem.zzsarytrsDscFg = '0'
        // 2018.10 역산할인대상여부(장효상책임요청)
        tempPolprPrem.polprAldaprem.zzicalcDiscFg = '0'
        if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentplLineCd) && ['85', '86', '87'].indexOf(abdapolpr.aldapolpr.zzentplLineCd) > -1) {
          tempPolprPrem.polprAldaprem.zzentpWlfDcFg = '1'
          tempPolprPrem.polprAldaprem.zzsarytrsDscFg = '1'
          // 2018.10 역산할인대상여부(장효상책임요청)
          tempPolprPrem.polprAldaprem.zzicalcDiscFg = '1'
        }

        tempPolprPrem.polprAldaprem.gafRefId = 'POLPR_PREM'

        abdapolpr.polprAbdaprem = [tempPolprPrem]
        Gaf.d('New polprAbdaprem 생성 => ', tempPolprPrem)
      } else {
        let errorStr = 'POLPR_PREM 의 Template 이 존재하지 않습니다.'
        Gaf.d(errorStr)
        errors.push(errorStr)
        errorFlag = true
      }

      // 펀드(FUND)/자산운용옵션(HEAD) 생성  ========================================================================
      let fundDto = Gaf.getTObj('fndKndInfoDto')
      Gaf.d('fundDto => ', fundDto)
      if (fundDto && fundDto.zFndKndInfoPrtDto && fundDto.zFndKndInfoPrtDto.length > 0) {
        // 펀드정보
        let tempTargetPrem = mainInsAbdacov.covAbdaprem[0]
        let tempCovAldaasfund = []
        if (!tempTargetPrem.covPremAldaasfund || !tempTargetPrem.covPremAldaasfund[0]) {
          tempTargetPrem.covPremAldaasfund = []
        }

        Gaf.d('fundDto.zFndKndInfoPrtDto loop start')
        fundDto.zFndKndInfoPrtDto.forEach((fundElem, i) => {
          // 편입비율이 존재하는 데이터만 처리
          if (fundElem.percentassVl && fundElem.percentassVl !== '') {
            // 기 존재하는 객체 get
            let tempFundItemList = tempTargetPrem.covPremAldaasfund.filter(function (subElem, i) {
              return subElem.intnameTt === fundElem.zaFundCode // 펀드코드 일치하는것 GET
            })

            let tempFundItem = tempFundItemList ? tempFundItemList[0] : undefined

            Gaf.d('tempFundItem grep => ', tempFundItem)
            if (!tempFundItem) {
              tempFundItem = {}
              Gaf.d('tempFundItem new Object')
            }

            // 데이터 셋팅
            tempFundItem.deleteFlag = '0'
            /** 20160803 템플릿 아이디 : 추가 */
            // tempFundItem.productBaseId = "EH60000L0000";

            // tempFundItem.pmId = ?;
            // 가입설계주석 tempFundItem.distprimCd = "3";  //3:비율
            tempFundItem.currencyId = abdapolpr.currencyId
            tempFundItem.fundeffectiveDt = mainInsAbdacov.covbegDt

            tempFundItem.intnameTt = fundElem.zaFundCode // 펀드코드
            tempFundItem.percentassVl = fundElem.percentassVl// 편입비율
            // 20160720 김민지과장 요청으로 해당 항목 추가
            tempFundItem.percentresVl = fundElem.percentassVl// 편입비율
            tempCovAldaasfund.push(tempFundItem)
            Gaf.d('tempFundItem push')
          }
        })
        Gaf.d('fundDto.zFndKndInfoPrtDto loop end')
        tempTargetPrem.covPremAldaasfund = tempCovAldaasfund

        // 자산운용옵션
        let tempCovAldasshead = null
        if (!mainInsAbdacov.covAldasshead || !mainInsAbdacov.covAldasshead[0]) {
          mainInsAbdacov.covAldasshead = [{}]
        }

        tempCovAldasshead = mainInsAbdacov.covAldasshead[0]

        // 데이터셋팅
        /** 펀드데이터 재매핑요청
          tempCovAldasshead.deleteFlag = "0";
          tempCovAldasshead.firstdueDt = mainInsAbdacov.covbegDt;
          tempCovAldasshead.currencyId = abdapolpr.currencyId;
          tempCovAldasshead.executionDt = tempCovAldasshead.firstdueDt; //??? 안넣으면 보험료계산 후 Date String 으로 변환시 에러남
          tempCovAldasshead.zzvainOpKndCd = isEmpty2(fundDto.zaDcaAplYn)?"":"02";
          tempCovAldasshead.zzfndThrCylCd = fundDto.zzfndThrCylCd;
          tempCovAldasshead.zzfndDueDdCd = fundDto.zzFndDueDdCd;

          let rdZZDCA_APL_P_S_ID = GafMainFunc.getIfbcValue("ZZDCA_APL_P_S_ID",mainInsAbdacov.productBaseId);
          tempCovAldasshead.zzdcaAprmScCd = rdZZDCA_APL_P_S_ID;

          tempCovAldasshead.zzaplInfoFg = isEmpty2(fundDto.zaDcaAplYn)?"1":"2";
          //20160701 yherp.kwon : 자산운용옵션 매핑 변경
          tempCovAldasshead.zzdcaAprmScCd = fundDto.zaDcaAplYn;
          tempCovAldasshead.zzdcaThrNtsVl = fundDto.zaDcaThrwNts;
          tempCovAldasshead.zzfndDueDdCd = fundDto.zzFndDueDdCd;  */

        /** 20170810 펀드데이터 재매핑 */
        tempCovAldasshead.deleteFlag = '0'
        tempCovAldasshead.firstdueDt = mainInsAbdacov.covbegDt
        // tempCovAldasshead.currencyId = abdapolpr.currencyId
        tempCovAldasshead.currencyId = !this.$bizUtil.isEmpty(Gaf.getGafObjByRefId('POLICY')[0].currencyId) ? Gaf.getGafObjByRefId('POLICY')[0].currencyId : 'KRW' // 외화보험 - COV_ALDABNF.CURRENCY_ID 셋팅
        tempCovAldasshead.executionDt = tempCovAldasshead.firstdueDt // ??? 안넣으면 보험료계산 후 Date String 으로 변환시 에러남
        tempCovAldasshead.zzvainOpKndCd = piCommonUtil.isEmpty2(fundDto.zaDcaAplYn) ? '' : '2'

        if (piCommonUtil.isObj(tempCovAldasshead.zzvainOpKndCd) && tempCovAldasshead.zzvainOpKndCd === '2') {
          tempCovAldasshead.swtypId = '1'
          tempCovAldasshead.zzoptEndYmdDt = '99991231'
          tempCovAldasshead.zzdcaAprmScCd = '10'
          tempCovAldasshead.zzfndThrCylCd = fundDto.zaDcaThrwNts // 펀드투입주기코드
          tempCovAldasshead.zzfndDueDdCd = fundDto.zzFndDueDdCd // 펀드투입응당일코드
          tempCovAldasshead.zzaplInfoFg = '1'
        } else { // DCA선택하지 않은 경우 초기화
          tempCovAldasshead.swtypId = ''
          tempCovAldasshead.zzoptEndYmdDt = ''
          tempCovAldasshead.zzdcaAprmScCd = ''
          tempCovAldasshead.zzfndThrCylCd = '' // 펀드투입주기코드
          tempCovAldasshead.zzfndDueDdCd = '' // 펀드투입응당일코드
          tempCovAldasshead.zzaplInfoFg = ''
        }
        // tempCovAldasshead.zzdcaAprmScCd = fundDto.zaDcaAplYn;
        // tempCovAldasshead.zzfndDueDdCd = fundDto.zzFndDueDdCd;                     kadP
        // tempCovAldasshead.zzaplInfoFg = isEmpty2(fundDto.zaDcaAplYn)?"1":"2"; 삭제요청
        // tempCovAldasshead.zzdcaThrNtsVl = fundDto.zaDcaThrwNts; 삭제요청
        // let rdZZDCA_APL_P_S_ID = GafMainFunc.getIfbcValue("ZZDCA_APL_P_S_ID",mainInsAbdacov.productBaseId);
        // tempCovAldasshead.zzdcaAprmScCd = rdZZDCA_APL_P_S_ID; //삭제요청 DBA 옵션 선택시 무조건 10으로 셋팅
        Gaf.d('Fund 정보 생성 => ', mainInsAbdacov)
      }
      // 에러체크 후 리턴
      Gaf.d('PNLPQL00109 toPmodel errors : ', errors)
      if (errorFlag === true) {
        return errors
      } else {
        // 에러없으면 그냥 리턴
        console.log(Gaf.getGafObjByRefId('COV')[0])
        return false
      }
    },
    /******************************************************************************
    * Function명 : fn_ErrBefore
    * 설명       : 오류메시지 이전
    ******************************************************************************/
    fn_ErrBefore(){

      if (this.isErrorIdx === 0){
        return
      }

      this.isErrorIdx -= 1
      this.fn_Error()
    },    

    /******************************************************************************
    * Function명 : fn_ErrNext
    * 설명       : 오류메시지 다음
    ******************************************************************************/
    fn_ErrNext(){

      if (this.isErrorIdx >= (this.errorList.length-1)){
        return
      }

      this.isErrorIdx += 1
      this.fn_Error()

    },
    /******************************************************************************
    * Function명 : fn_Error
    * 설명       : 오류메시지 발생시
    ******************************************************************************/
    fn_Error(){
      console.log("[MSPPI822D] fn_Error 호출 성공...")

      let _errorList = this.errorList
      let _isErrorIdx = this.isErrorIdx

      if(piCommonUtil.isEmpty(_errorList)){
        
        this.isGuideBottomShow = false

        this.ErrorMsg = ''
        this.errorListCnt = 0
      } else {

        this.isGuideBottomShowYN = true
        this.errorListCnt = _errorList.length

        if (this.isLimitOver == false){

          this.ErrorMsg = '[' + _errorList[_isErrorIdx].zaUndwRsltMsgCd + '] ' + _errorList[_isErrorIdx].zaUndwRsltMsgCntnt

          // error에 딸린 uiCode가 있는지 여부를 체크한다.
          let _error = []
          _error.zaUndwRsltMsgCd = _errorList[_isErrorIdx].zaUndwRsltMsgCd
          _error.zaUndwRsltMsgCntnt = _errorList[_isErrorIdx].zaUndwRsltMsgCntnt
          _error.zaNrmYn = _errorList[_isErrorIdx].zaNrmYn

          if (this.hasErrorUiCodes(_error)){
            // ErrorUiCode를 MSPPI080D 화면으로 전달한다.

            // EventBus.$emit('MSPPI080D', {functionName: 'errorRow_New', data: _error.zaUndwRsltMsgCd}) // 오류 특약건 스크롤 이동요청 함수 호출
            // Gaf.getPanel('MSPPI080D').errorRow_New(_error.zaUndwRsltMsgCd)
            this.errorRow_New(_error.zaUndwRsltMsgCd)
          }
        } else {

          // [NEXT 사랑ON] 가입설계발행불가특약가이드목록 표시
          this.ErrorMsg = _errorList[_isErrorIdx].pblDalwRsn

          // error에 딸린 uiCode가 있는지 여부를 체크한다.
          let _error = []
          _error.inscdList = _errorList[_isErrorIdx].inscdList
          _error.pblDalwRsn = _errorList[_isErrorIdx].pblDalwRsn

          // if (this.hasErrorUiCodes(_error)){
            // ErrorUiCode를 MSPPI080D 화면으로 전달한다.

            // EventBus.$emit('MSPPI080D', {functionName: 'limitOverRow_New', data: _error}) // 오류 특약건 스크롤 이동요청 함수 호출
            // Gaf.getPanel('MSPPI080D').limitOverRow_New(_error)
            this.limitOverRow_New(_error)

          // }          
        }

      }

    },
    /******************************************************************************
    * Function명 : errorRow_New
    * 설명       : error 정보 표시
    ******************************************************************************/
    errorRow_New (val) {
      // error 표시된 특약 clear
      this.clearErrList(false)
      
      let lv_Vm = this
      let errCd = val

      if (errCd.substring(0, 1) === 'R') { // 관계규칙 위배시

        console.log('[MSPPI822D] [errorRow_New] 1. errCd : ' + errCd)

        // error 표시될 uiCode List 추출
        let _errors = Gaf.getTObj('zPrmCalcMstkRsltDto')
        if (!_errors) {
          return
        }

        console.log('[MSPPI822D] [errorRow_New] 2. errCd : ' + errCd)

        _errors = _errors.filter(function ( item ) { return item.zaUndwRsltMsgCd === errCd })

        
        // error 표시
        for (let el of _errors) {
          let _uiCodes = el.zzentplRepCCdAry.split('||')
          this.viewData.forEach((el3, i3) => {
            if (_uiCodes.indexOf(el3.uiCode) > -1) {
              this.$set(this.viewData[i3], 'error', true)
            }
          })
          // [TO-DO] 추가여부 확인 필요
          // _.forEach(this.viewData, function (el4, i4) {
          //   if (_uiCodes.indexOf(el4.uiCode) > -1) {
          //     // 첫번째 오류특약으로 스크롤 이동
          //     lv_Vm.fn_ScrollToAccordion(i4)

          //     return false
          //   }
          // })
        }
        return
      }

      if (errCd.substring(0, 1) === 'L') { // 가입금액 위배시
        this.viewData.forEach((el, i) => {
          if (el.insCode === errCd) {
            this.$set(this.viewData[i], 'error', true)
          }
        })
      }
    },  

    /******************************************************************************
    * Function명 : limitOverRow_New
    * 설명       : [NEXT 사랑On] 가입설계발행불가 한도초과 특약 가이드
    ******************************************************************************/
    limitOverRow_New (limitOverGuide) {
      // error 표시된 특약 clear
      this.clearErrList(false)

      let lv_Vm = this
      let _uiCodes = []
      // error 표시

      limitOverGuide.inscdList.forEach((el) => {
        _uiCodes.push(el.uiCode)
      })

      // let _uiCodes = limitOverGuide.inscdList

      this.viewData.forEach((el3, i3) => {
        if (_uiCodes.indexOf(el3.uiCode) > -1) {
          this.$set(this.viewData[i3], 'error', true)
        }
      })

      // [TO-DO] 추가여부 확인 필요
      // _.forEach(this.viewData, function (el4, i4) {
      //   if (_uiCodes.indexOf(el4.uiCode) > -1) {
      //     // 첫번째 오류특약으로 스크롤 이동
      //     lv_Vm.fn_ScrollToAccordion(i4)
      //     return false
      //   }
      // })
    },
    /******************************************************************************
    * Function명 : clearErrList
    * 설명       : errorList Clear
    ******************************************************************************/
    clearErrList (isErrorListReset) {
      if (isErrorListReset) {
        this.errorList.splice(0)
        // [NEXT 사랑On] 가입설계발행불가 한도초과 특약 가이드 구분자 초기화
        this.isLimitOver = false        
      }
      if (this.viewData && this.viewData.length > 0) {
        // error 표시된 특약 clear
        this.viewData.forEach((el, i) => {
          if (el.error) {
            this.$set(this.viewData[i], 'error', false)
          }
        })
      }      
    },
    /******************************************************************************
    * Function명 : hasErrorUiCodes
    * 설명       : errorList '확인' 버튼 표시 여부 결정을 위해, error에 딸린 uiCode가 있는지 여부를 체크한다.
    ******************************************************************************/
    hasErrorUiCodes (error) {
      // 정상여부가 N(비정상)이 아니거나, 에러코드가 없으면 '확인' 버튼 안보이도록 false 리턴.
      if (error.zaNrmYn !== 'N' || piCommonUtil.isEmpty(error.zaUndwRsltMsgCd)) {
        return false
      }

      // 관계규칙 위배시
      if (error.zaUndwRsltMsgCd.substring(0, 1) === 'R') {
        // error 표시될 uiCode List 추출
        let _errors = Gaf.getTObj('zPrmCalcMstkRsltDto')
        if (!_errors) {
          return false
        }
        _errors = _errors.filter(function ( item ) { return item.zaUndwRsltMsgCd === error.zaUndwRsltMsgCd })
        if (_errors) {
          return true
        }
      }

      // 가입금액 위배시-20190219 박진현 선임 요청 LY~는 확인 표시하지 않기로 함
      // if (error.zaUndwRsltMsgCd.substring(0, 1) === 'L') {
      //   let _errors = this.selected.filter(function ( item ) { return item.insCode === error.zaUndwRsltMsgCd })
      //   if (_errors) {
      //     return true
      //   }
      // }

      return false
    },
    /******************************************************************************
    * Function명 : fn_premCalc2
    * 설명       : 보험료계산(설계가이드)
    ******************************************************************************/
    fn_premCalc2(){

      console.log('[MSPPI822D] fn_premCalc2 호출 성공....')
      this.isErrorIdx = 0
      
      this.isGuideBottomShow = false
      Gaf.getPanel('MSPPI820P').lv_isError = false
      this.$emit('premCalc')
      // this.premCalc()
      
      // this.$nextTick(() => {
      //   this.isGuideBottomShow = false
      // })    
      
    },
    /******************************************************************************
    * Function명 : fn_MoveScrollTop
    * 설명       : 스크롤이동
    ******************************************************************************/
    fn_MoveScrollTop (index) {
      // tab : 54 - 171
      // console.log('[MSPPI822D] fn_ScrollTo ---> scrollTop :: ' + scrollTop)
      // 태블릿인 경우만 좌표 위치를 조정한다. (그룹 항목 버튼 존재시  - 72 추가)
      let posY = this.$refs.viewData.$refs.moList.childNodes[0].childNodes[index].offsetTop

      if(this.$refs.refChgPrc.status === 'open'){
        posY += 225
      }
      console.log('posY ===> ', posY)
      this.$emit('moveScroll', posY)
    },
    /******************************************************************************
    * Function명 : fn_chkZzentPTCLVl
    * 설명       : 특약 가입금액을 변경 가능여부 판단하여 알림 메시지 출력 및 블로킹
    ******************************************************************************/
    fn_chkZzentPTCLVl(openConfirm) {
      // 2024.02 ASR240200218 / [상품판매준비] 환급특약류 설계관련 화면개선_사랑온(김민지프로요청)
      let _rowData = this.lv_CalcData
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      // let polprAbdaprem = abdapolpr.polprAbdaprem[0] // POLPR_ABDAPREM
      // let smtotPrm = Gaf.getValue(polprAbdaprem, 'premaftertaxAm') // 합계보험료
      if (_rowData.zzinscdScCd === '62' || _rowData.zzinscdScCd === '64') {
        if (piCommonUtil.isObj(abdapolpr.aldapolpr.zzentPTCLVl) && abdapolpr.aldapolpr.zzentPTCLVl.search('RN') > -1 && !piCommonUtil.isEmpty2(_rowData.covPrm)) {
          _rowData.covInsAmt = _rowData.prevCovInsAmtForLI0933
          if (openConfirm) {
            let insName = ''
            if (_rowData.name.indexOf('특약') > -1) {
              insName = _rowData.name.substring(0, _rowData.name.search('특약') + 2)
            }
            piCommonUtil.getFDPConfirm('s', insName + '은\n보험료 계산 이후에 특약 가입금액을 변경하실 수 없습니다.')
          }
          this.chkZzentPTCLVl_TF = false
          return true
        }
      }
      return false
    },
    //--------------------------------------------------------------------------------
 },//methods

}//export default
</script>