/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI821D
 * 화면설명: 선심사결과
 * 접근권한: 
 * 작 성 일: 2024.02.13
 * 작 성 자: 
 */
<template>
  <!-- <ur-page-container class="msp" :show-title="false" :topButton="false">  -->
    <!-- 컨텐츠 영역 --> 
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap"> 
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 mb90">     
        <mo-list-item style="padding-top:0px"> 
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 mb20"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fwb">선심사 결과</span>
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20">
                <div class="list-item__contents">
                  <ul class="expand_grid_wrap">
                    <li class="item tit"><span>연금</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['12']}}</span></li>
                    <li class="item tit"><span>사망</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['02']}}</span></li>
                    <li class="item tit"><span>CI</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['04']}}</span></li>
                    <li class="item tit"><span>종합실손</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['07']}}</span></li>
                    <li class="item tit"><span>질병실손</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['06']}}</span></li>
                    <li class="item tit"><span>상해실손</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['14']}}</span></li>
                    <li class="item tit"><span>상해</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['31']}}</span></li>
                    <li class="item tit"><span>고도장해</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['34']}}</span></li>
                    <li class="item tit"><span>LTC</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['05']}}</span></li>
                    <li class="item tit"><span>암</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['03']}}</span></li>
                    <li class="item tit"><span>납입면제</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['11']}}</span></li>
                    <li class="item tit"><span>입원</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['13']}}</span></li>
                    <li class="item tit"><span>특정입원</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['01']}}</span></li>
                    <li class="item tit"><span>특정수술</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['08']}}</span></li>
                    <li class="item tit"><span>뇌출혈</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['35']}}</span></li>
                    <li class="item tit"><span>심근경색</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['36']}}</span></li>
                    <li class="item tit"><span>어린이</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['10']}}</span></li>
                    <!-- <li class="item tit"><span>수술(구)</span></li>
                    <li class="item txt"><span class="mr10">{{lv_lstNundwDignBasOpin['30']}}</span></li> -->
                  </ul>
                </div>
              </div>
            </template>
          </msp-expand>
         </mo-list-item>
         <!-- 진단결과 안내 START -->
         <mo-list-item style="padding-top:0px" v-if="lv_lstNcsDocuMsgCntnt.length > 0"> 
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 mb20"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fwb">진단결과 안내</span>
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div v-for="(msg, index) in lv_lstNcsDocuMsgCntnt" :key="index">
                <div class="list-item__contents margin--full pa12024 bgcolor-3" v-if="msg !== ''">
                  <p class="mla0 fs16rem">{{msg}}</p>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
        <!-- 진단결과 안내 END -->
        <!-- 처리불가 세부내용 START -->
        <mo-list-item style="padding-top:0px" v-if="lv_lstNundwCundwAbnrm.length > 0"> 
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 mb20"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fwb">처리불가 세부내용</span>
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="list-item__contents margin--full pa12024 bgcolor-3" v-for="(msg, index) in lv_lstNundwCundwAbnrm" :key="index">
                <p class="mla0 fs16rem" :class="{'mt20': index !== 0}">{{`[${msg.zaUndwRsltMsgCd}] ${msg.zaUndwRsltMsgCntnt}`}}</p>
                <!-- <p class="mla0 fs16rem">[진단] 당뇨: 진단고객(무서류심사) 50세이상 사망급부만 서류없이 고지만으로 심사가능</p> -->
                <!-- <p class="mla0 mt20 fs16rem">[진단] 당뇨: 진단고객(무서류심사)</p> -->
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
        <!-- 처리불가 세부내용 END -->
        <!-- 비정상 세부내용 START -->
        <mo-list-item style="padding-top:0px" v-if="lv_lstNundwCundwRslt !== null && lv_lstNundwCundwRslt.length > 0"> 
        <!-- <mo-list-item style="padding-top:0px" v-if="lstNundwCundwRslt !== null && lstNundwCundwRslt.length > 0">  -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 mb20"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title bdT">
                  <span class="name txtSkip fwb">비정상 세부내용</span>
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <mo-list :list-data="lv_lstNundwCundwRslt" class="margin--full mokup-listTy1">
              <!-- <mo-list :list-data="lstNundwCundwRslt" class="margin--full mokup-listTy1"> -->
                <template #list-item="{item}">
                  <div class="list-item__contents pa12024 bgcolor-3">
                    <div class="list-item__contents__title"> 
                      <span class="mr10"><mo-badge v-if="item.zzcontvInsrdCd !== '11'" class="badge-sample-badge lightblue" :class="{'lightblue' : item.zzcontvInsrdCd === '21', 'lightblue2' : item.zzcontvInsrdCd === '23', 'lightgreen' : item.zzcontvInsrdCd === '11'}" text="" shape="status">{{item.zzcontvInsrdNm}}</mo-badge></span>
                      <span class="fwn flex-1">{{item.zaErrCntnt}}</span>
                    </div>
                    
                    <div class="list-item-detail bgcolor-2 bd-radius-10 pal1524 mt10" v-if="(+item.zaAddupEntAmt !== 0 && +item.zaLimtAmt !== 0 && +item.zaOverAmt !== 0)">
                      <div class="contents-row fex-rw fexTy3 pb5">
                        <span class="crTy-bk7">가입금액</span><span class="ml10 fs16rem">{{item.zaAddupEntAmt}} 만원</span>
                      </div>
                      <div class="contents-row fex-rw fexTy3 pb5">
                        <span class="crTy-bk7">한도금액</span><span class="ml10 fs16rem">{{item.zaLimtAmt}} 만원</span>
                      </div>
                      <div class="contents-row fex-rw fexTy3">
                        <span class="crTy-bk7">초과금액</span><span class="ml10 fs16rem crTy-red2">{{item.zaOverAmt}} 만원</span>
                      </div>
                    </div>
                  </div>
                </template>
              </mo-list>
            </template>
          </msp-expand>
        </mo-list-item>
        <!-- 비정상 세부내용 END -->
      </ur-box-container>  
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
 <!-- </ur-page-container> -->
</template>

<script>
import piCommonUtil from './common/piCommonUtil'
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
export default {
  name: 'MSPPI821D',
  screenId: 'MSPPI821D',
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1}, // 부모 선택한 현재 탭 인덱스
    lstNundwDignBasOpin: { // 진단기본의견목록 - 선심사결과
      type: Array,
      default: () => []
    },
    lstNundwCundwRslt: { // 전산심사결과목록 - 비정상 세부내용
      type: Array,
      default: () => []
    },
    lstNcsDocuMsgCntnt: { // 필요서류메시지내용목록
      type: Array,
      default: () => []
    },
    lstNundwCundwAbnrm: { // 선심사전산심사비정상목록 - 처리불가상세
      type: Array,
      default: () => []
    },
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      lv_lstNundwDignBasOpin: {
        '02': '', // 사망
        '12': '', // 연금
        '04': '', // CI
        '07': '', // 종합실손
        '06': '', // 질병실손
        '14': '', // 상해실손
        '31': '', // 상해
        '34': '', // 고도장해
        '05': '', // LTC
        '03': '', // 암
        '11': '', // 납입면제
        '13': '', // 입원
        '01': '', // 특정입원
        '08': '', // 특정수술
        '35': '', // 뇌출혈
        '36': '', // 심근경색
        '10': '', // 어린이
        // '30': '', // 수술(구)
      },
      lv_lstNundwCundwAbnrm: [], // 처리불가 세부내용
      lv_lstNrmYn: '', // 최종정상여부
      lv_lstNundwCundwRslt: [],
      lv_lstNcsDocuMsgCntnt: []
    }
  },

  created () {
  },//created

  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    slctTabIdx(){
      console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
    lstNundwDignBasOpin (item) {
      console.log('lstNundwDignBasOpin ===> ', item)
      if (!piCommonUtil.isEmpty2(item)) {
        for (let setItem in this.lv_lstNundwDignBasOpin) {
          if (item.length > 0) {
            let findItem = item.find(getFind => getFind.zaDundwRsltTypCd === setItem)
            if (piCommonUtil.isObj(findItem)) {
              this.lv_lstNundwDignBasOpin[setItem] = findItem.zaDundwCllgOpin
            } else {
              this.lv_lstNundwDignBasOpin[setItem] = '-'
            }
          } else {
            this.lv_lstNundwDignBasOpin[setItem] = '-'
          }
        }
        console.log('watch Setting ===> ', this.lv_lstNundwDignBasOpin)
      } else {
        for (let setItem in this.lv_lstNundwDignBasOpin) {
          this.lv_lstNundwDignBasOpin[setItem] = '-'
        }
      }
    },
    lstNundwCundwAbnrm (item) {
      console.log('[MSPPI821D watch lv_lstNundwCundwAbnrm] ', item)
      if (!piCommonUtil.isEmpty2(item)) {
        let isError = item.find(findItm => findItm.zanrmYn === 'N')
        if (!piCommonUtil.isEmpty2(isError)) {
          this.lv_lstNrmYn = 'N' // 처리불가여부
        }
        // TEST ====
        // this.lv_lstNrmYn = 'N' // 처리불가여부
        // this.lv_lstNundwCundwAbnrm = item
        // TEST ====
        this.lv_lstNundwCundwAbnrm = item.filter(setItem => setItem.zanrmYn === 'N')
      } else {
        this.lv_lstNundwCundwAbnrm = []
      }
    },
    lstNundwCundwRslt (item) {
      if (!piCommonUtil.isEmpty2(item)) {
        this.lv_lstNundwCundwRslt = item
        for (let i=0; i < this.lv_lstNundwCundwRslt.length; i++) {
          this.lv_lstNundwCundwRslt[i].id = i + 1
          this.lv_lstNundwCundwRslt[i].N = i + 1
          this.lv_lstNundwCundwRslt[i].zaAddupEntAmt = piCommonUtil.addMoneyCommas(this.lv_lstNundwCundwRslt[i].zaAddupEntAmt) // 합산가입금액
          this.lv_lstNundwCundwRslt[i].zaLimtAmt = piCommonUtil.addMoneyCommas(this.lv_lstNundwCundwRslt[i].zaLimtAmt) // 한도금액
          this.lv_lstNundwCundwRslt[i].zaOverAmt = piCommonUtil.addMoneyCommas(this.lv_lstNundwCundwRslt[i].zaOverAmt) // 초과금액
        }
      } else {
        this.lv_lstNundwCundwRslt = []
      }
    },
    lstNcsDocuMsgCntnt (item) {
      if (!piCommonUtil.isEmpty2(item)) {
        this.lv_lstNcsDocuMsgCntnt = item
      } else {
        this.lv_lstNcsDocuMsgCntnt = []
      }
    }
  },//watch
  computed: {},//computed
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SetClass
    * 설명       : 클래스 처리 함수
    ******************************************************************************/
    fn_SetClass (typCd) {

    },
  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>