/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI820P
 * 화면설명: 선심사 완료
 * 접근권한: 
 * 작 성 일: 2024.02.13
 * 작 성 자: 
 */
<template>
  <ur-page-container ref="page" class="msp" title="선심사 완료" :show-title="false" :topButton="slctTabIdx === 1" type="popup">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack('close')">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '선심사 완료' : '선심사 완료'}}</div>
              <div slot="action">
                <span><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_OpenMSPPI840P">질병판정결과</mo-button></span>
                <span>
                  <mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_HistoryBack('close')">close</mo-icon>
                </span>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>
          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" ref="tabbox"  class="ns-move-tab-box">
              <mo-tab-content :idx="1" label="선심사결과" ></mo-tab-content>
              <mo-tab-content :idx="2" label="특약별결과" ></mo-tab-content>      
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="pal2430 bd-b-Ty1" v-if="lv_nundwAplInfo !== null && lv_nundwAplInfo !== undefined && Object.keys(lv_nundwAplInfo).length > 0">
              <mo-list :list-data="[lv_nundwAplInfo]">
                <template #list-item="{item}">
                  <div class="list-item__contents">
                    <div class="list-item-detail">
                      <div class="contents-row mb10">
                        <!-- 확정아님 변경 예정 -->
                        <mo-badge class="badge-sample-badge sm" text="" shape="status" :class="fn_SetClass(item.nundwPrgStatCd)">{{fn_SetClass(item.nundwPrgStatCd, '1')}}</mo-badge>
                      </div>
                      <div class="contents-row mb4">
                        <span class="dsInline crTy-bk7 min65 mr20">선심사번호</span><span class="fs16rem">{{item.dsclsId}}</span>
                      </div>
                      <div class="contents-row mb4">
                        <!-- item.hasOwnProperty('avlStrYmd') ? $bizUtil.dateDashFormat(item.avlStrYmd.replace(/-/g, '').substr(0,8)) : '' -->
                        <span class="dsInline crTy-bk7 min65 mr20">선심사일</span><span class="fs16rem">{{nundwExpYmd}}</span>
                        <!-- <span class="dsInline crTy-bk7 min65 mr20">선심사일</span><span class="fs16rem">{{item.hasOwnProperty('nundwYmd') ? $bizUtil.dateDashFormat(item.nundwYmd.replace(/-/g, '').substr(0,8)) : ''}}</span> -->
                      </div>
                      <div class="contents-row" v-if="lv_lstNundwInspRslt.length > 0">
                      <!-- <div class="contents-row" v-if="item.nundwPrgStatCd === '50'"> -->
                        <span class="dsInline crTy-bk7 min65 mr20">미결보완</span>
                        <span class="fs16rem fwb" v-if="lv_pendCpmtYn !== ''" :class="{'crTy-blue2' : lv_pendCpmtYn === 'Y','crTy-orange2' : lv_pendCpmtYn === 'N'}">{{lv_pendCpmtYn}}</span>
                        <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 minWauto hauto pal0" :class="{'ml16': lv_pendCpmtYn !== '' }" @click="fn_OpenMSPPI830P">심사의견조회</mo-button> 
                      </div> 
                    </div>
                  </div>
                </template>
              </mo-list>
            </ur-box-container>
            <MSPPI821D ref="refMSPPI821D"
                       v-show="slctTabIdx === 1"
                       :slctTabIdx="slctTabIdx"
                       :lstNundwDignBasOpin="lv_lstNundwDignBasOpin"
                       :lstNundwCundwRslt="lv_lstNundwCundwRslt"
                       :lstNcsDocuMsgCntnt="lv_lstNcsDocuMsgCntnt"
                       :lstNundwCundwAbnrm="lv_lstNundwCundwAbnrm"
                       />
            <MSPPI822D ref="refMSPPI822D" 
                       v-show="slctTabIdx === 2"
                       :slctTabIdx="slctTabIdx"
                       :planId="lv_planId"
                       :dsclsId="lv_dsclsId"
                       @chgBottom="fn_ChangeBottomSheet"
                       @setSmtotPrmData="fn_SetSmtotPrm"
                       @selResult="fn_SelNundwRslt"
                       @premCalc="fn_PreChkPremCalc"
                       @moveScroll="fn_ScrollTo"/>
          </template>
        </mo-collapsing-header-view>
      </div>

      <!-- 심사결과 표기 및 하단 버튼 영역 -->
      <ur-box-container direction="column" alignV="start" class="fixed bottom0">
        <!--더보기 영역-->
        <ur-box-container alignV="start" componentid="" direction="column">
          <div class="bottom-sheet-ty--1 pt10 pr24 pl24 bs-con-expand" :class="{'open': lv_isBsOpen === true}" ref="moreExpandBody"> <!-- 2024.02.29 : open class - toggle -->
            <div class="content-area margin--full bdnone pb0 mt40 mhAuto">              
              <msp-expand ref="moreExpand" class="detail-bar-acodian more-accordion mt0 open" btn-area-class="detail-bar-btn"> <!--2024.02.29 open class 상시추가 -->
                <template #btn>
                  <mo-button class="btn-close-bs" @click="fn_OpenBottomExpand"><mo-icon icon="msp-close-bold"/></mo-button><!-- 2024.02.29 : openExpand: 퍼블 화면테스트용 -->
                </template>  
                <template #content>
                  <div class="detail-con">
                    <ur-box-container alignV="start" componentid="" direction="row" class="gray-box line-middle mb20">
                      <!-- 일반 -->
                      <ur-box-container class="pal1624">
                        <div class="full fexTy3 fs16rem crTy-bk1">
                          <span class="min130 mr15">합계보험료(변경 전)</span>
                          <span class=""><span class="fs18rem mr4">{{lv_smtotPrm.hasOwnProperty('prevSmtotPrm') ? $bizUtil.numberWithCommasCurr(lv_smtotPrm.prevSmtotPrm, lv_smtotPrm.currencyId, 'Y', 'N') : '0'}}</span>원</span>
                        </div>
                        <div class="full fexTy3 mt4 fs16rem crTy-bk1">
                          <span class="min130 mr15">합계보험료(변경 후)</span>
                          <template v-if="lv_isChgFlag && lv_isCalc">
                          <!-- <template v-if="lv_isCalc && lv_isChgCalc"> -->
                            <span class=""><span class="fwb fs18rem mr4">{{lv_smtotPrm.hasOwnProperty('smtotPrm') ? $bizUtil.numberWithCommasCurr(lv_smtotPrm.smtotPrm, lv_smtotPrm.currencyId, 'Y', 'N') : '0'}}</span>원</span>
                          </template>
                          <template v-else>
                            <span class=""><span class="fwb fs18rem mr4">{{'-'}}</span>원</span>
                          </template>
                        </div>
                        <template v-if="lv_isChgFlag && lv_isCalc">
                        <!-- <template v-if="lv_isCalc && lv_isChgCalc"> -->
                          <div class="full fexTy4 align-item-center mt4 fs16rem">
                            <mo-icon icon="msp-arrow-decrease3" v-show="lv_isArrow"/>
                            <span class="fwb crTy-orange2">{{lv_smtotPrm.hasOwnProperty('diffSmtotPrm') ? $bizUtil.numberWithCommasCurr(lv_smtotPrm.diffSmtotPrm, lv_smtotPrm.currencyId, 'Y', 'N') : '0'}}</span>
                            <span class="ml4 fwb crTy-orange2">원</span>
                          </div>
                        </template>
                      </ur-box-container>
                    </ur-box-container>
                    <!-- 미결 START -->
                    <ur-box-container v-if="lv_nundwPrgStatCd === '50'" alignV="start" componentid="ur_box_container_006" direction="row" class="pt10">
                    <!-- <ur-box-container v-if="lv_nundwPrgStatCd === '50'" alignV="start" componentid="ur_box_container_006" direction="row" class=""> -->
                      <ur-box-container alignV="start" componentid="" direction="row" class="con-area">
                        <span class="fwb fs19rem">미결보완제출</span>
                      </ur-box-container>
                      <ur-box-container alignV="start" componentid="" direction="row" class="con-area fexTy2 mt10">
                        <span class="mt3 mr4"><mo-icon icon="warning" class="crTy-orange2" /></span>
                        <span class="flex-1 fs17rem">사전/질병정보보완 또는 서류보완 완료 후 <span class="crTy-orange2">미결보완제출 버튼을 눌러 미결보완에 대한 메시지를 작성</span>해주세요.</span>
                      </ur-box-container>  
                      <ur-box-container alignV="start" componentid="" direction="row" class="insert-area ty--2 pt10 pb0">
                        <mo-button class="ns-btn-round black" @click="fn_MovePage" :disabled="lv_OfrfmPblYn === 'Y' || lv_nundwExpdEndYn === 'Y'">사전/질병정보보완</mo-button>
                        <mo-button class="ns-btn-round black" @click="fn_OpenMSPPI825P" :disabled="lv_OfrfmPblYn === 'Y' || lv_nundwExpdEndYn === 'Y'">서류보완(지류)</mo-button>
                      </ur-box-container>
                      <ur-box-container alignV="start" componentid="" direction="row" class="insert-area pt10 pb20">
                        <mo-button class="ns-btn-round mla0" @click="fn_PrcsPendIndctCpmt('S')" :disabled="lv_OfrfmPblYn === 'Y' || lv_nundwExpdEndYn === 'Y'">미결보완제출</mo-button>
                        <!-- <mo-button class="ns-btn-round mla0" @click="fn_PreCheckNundwState('pendCpmtSbmt')">미결보완제출</mo-button> -->
                      </ur-box-container>
                    </ur-box-container>
                    <!-- 미결 END -->
                  </div>
                </template>
              </msp-expand>
            </div>
          </div>
        </ur-box-container>
        <!--//더보기 영역-->
        <!-- 하단 버튼 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow bs-bar-more" :class="{'shadow-blue' : lv_isBsOpen === false}" >
          <div class="relative-div">
            <div class="bs-bar-info gray-box" @click="fn_OpenBottomExpand">
              <p class="txt-exp">합계보험료<span class="crTy-blue3">{{lv_btnState ? '(변경 전)' : '(변경 후)'}}</span></p> <!--텍스트컬러 case: crTy-blue3 -->
              <p class="txt-total"><b class="txtSkip crTy-blue3" ref="skiptext">{{disSmtotPrm}}</b> 원</p>
              <mo-icon icon="msp-blue-arrow" class="bs-bar-arrow" :class="{'play': !lv_isBsOpen}" />
              <!-- <mo-icon icon="msp-blue-arrow" class="bs-bar-arrow"/> -->
              <div v-if="bubble" class="chat-bubble chat-bubble-top">
                <span class="ani-txt-show">{{disSmtotPrm}}원</span>
              </div>
            </div>
            <!-- 상품판매 종료 같은 오류가 발생하였을 때 버튼 제어를 위한 조건 추가 -->
            <div class="bs-bar-btn" v-if="Object.keys(lv_smtotPrm).length > 0">
              <!-- 특약 변경전, 기본 값 -->
              <!-- 1. 유효기간 초과 시 : 모든 버튼 숨김. 버튼영역에 유효기간종료 안내 메시지
                   2. 청약서 발행 후 : 3번 케이스 아닌 경우는 발행 버튼만 노출
                   3. 심사실행버튼 : 당일 전산심사 X, 진행상태코드 30,50,90 일때만 노출. 전산심사 완료 시 숨김
                   4. 인심사요청 버튼 미노출 : 심사완료-표준체, 심사필요-진단필요, 재설계필요
                   5. 심사실행 미노출 : 거절, 재설계필요, 유효기간초과
                   6. 인심사 노출 조건일때 인심사, 발행 -> 금액 변경 시 보험료계산 버튼노출, 초기화버튼 노출 -> 보험료계산 후 설계저장, 초기화버튼 노출 -> 설계저장 시 결과 재조회 -> 상태에 따라 버트 노출 
               -->
              <!-- 선심사 유효기간 초과 시 -->
              <template v-if="lv_nundwExpdEndYn === 'Y'">
                <div class="txt-box org fexTy1">
                  <span>선심사<br> 유효기간 경과</span>
                </div>
              </template>
              <!-- [TO-DO] 가설번호 삭제 시 -->
              <!-- <template>
                <div class="txt-box org fexTy1 pl10 pr10">
                  <span>가설설계번호 삭제</span>
                </div>
              </template> -->
              <template v-else-if="lv_isError">
              </template>
              <!-- 청약서 발행 -->
              <template v-else-if="lv_OfrfmPblYn === 'Y'">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white keep-all" @click="fn_preChkPbl">발행</mo-button>
              </template>
              <template v-else-if="!lv_isChgFlag">
                <!-- 특약 또는 체크박스 변경 전 -->
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white keep-all" v-if="lv_inspPsbFlag" @click="fn_PreNundwInspApl">인심사</mo-button>
                <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white keep-all" v-if="lv_inspPsbFlag" @click="fn_PreCheckNundwState('nundwInspApl')">인심사</mo-button> -->
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white keep-all" v-show="lv_nundwAplInfo.nundwExpdEndYn !== 'Y'" @click="fn_preChkPbl">발행</mo-button>
              </template>
              <template v-else-if="lv_isChgPlanFlag && lv_isChgFlag">
                <!-- 특약 또는 체크박스 변경 후 -->
                <mo-button componentid="" color="primary" shape="border" size="medium" class="link-only-icon circle" @click="fn_CommAlert('btnRest')"><mo-icon icon="msp-refresh2"/></mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white keep-all" v-show="lv_isChgFlag && !lv_isCalc" @click.stop="fn_PreChkPremCalc">보험료계산</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue keep-all" v-show="lv_isChgFlag && lv_isCalc" @click="fn_CommAlert('savePlan')">설계저장</mo-button>
              </template>
            </div>
          </div>
        </ur-box-container>
        <!-- //하단 버튼 -->
      </ur-box-container>
      <!-- // 심사결과 표기 및 하단 버튼 영역 -->
    </ur-box-container>

    <!-- Popup: 심사요청의견 -->
    <mo-bottom-sheet preventTouchClose ref="refBsJdgReqOpinSbmt" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        심사요청의견제출
      </template>
      <div class="ns-btn-close" @click="$refs.refBsJdgReqOpinSbmt.close()"></div>
      
      <div class="content-area pb6">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb0">
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-text-area class="hauto pa15 crTy-bk1 lh23" rows="8" :maxlength="400" placeholder="인수 심사 요청드립니다." v-model="lv_jdgMemoCntnt"/>
              <div class="fex-rw fexJsCtTy3 pt10 fs12rem">
                <div class="crTy-bk7"><span class="fwb pr2 crTy-blue2">{{lv_jdgMemoCntnt.length}}</span><span class="pl2 pr2">/</span><span class="pl2 pr2">400</span>자</div>
              </div>
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_PreCheckNundwState('nundwInspApl')" class="ns-btn-round blue">인심사신청</mo-button>
            <!-- <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_PrcsNundwInspApl" class="ns-btn-round blue">인심사신청</mo-button> -->
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup: 심사요청의견 -->

    <!-- Popup: 인심사결과 안내 -->
    <mo-bottom-sheet preventTouchClose ref="refBsRsltInfo" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        선심사결과 안내
      </template>
      <div class="ns-btn-close" @click="$refs.refBsRsltInfo.close()"></div>
      
      <div class="content-area pb6">
        <ur-box-container class="customer-info-txt pal0">
          <p class="ment">심사의견조회를 통해 상세내용을 확인할 수 있습니다.</p>
        </ur-box-container>
        <div class="round-box bgcolor-1 mt20">
          <mo-list :list-data="lv_lstNundwInspRslt" v-if="lv_lstNundwInspRslt.length > 0">
            <template #list-item="{item}">
              <div class="list-item__contents" v-if="item.rsltStatCd === '00'">
                <div class="list-item-detail">
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">인심사결과</span><span class="fs16rem" :class="{'crTy-red2' : item.rsltStatCd === '00'}">{{item.rsltStatCdNm}}</span>
                  </div>
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">인심사일자</span><span class="fs16rem">{{item.jdgYmd !== '' ? $bizUtil.dateDashFormat(item.jdgYmd.replace(/-/g, '').substr(0,8)) : ''}}</span>
                  </div>
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">미결코드 1</span><span class="fs16rem">{{item.jdgCdNm01}}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">미결코드 2</span><span class="fs16rem">{{item.jdgCdNm02}}</span>
                  </div> 
                </div>
              </div>
            </template>
          </mo-list> 
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CloseBs('refBsRsltInfo')" class="ns-btn-round blue">확인</mo-button>
            <!-- <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="$refs.refBsRsltInfo.close()" class="ns-btn-round blue">확인</mo-button> -->
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup: 선심사결과 안내 -->

    <!-- Popup: 미결보완제출 -->
    <mo-bottom-sheet preventTouchClose ref="refPendCpmtInpt" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        미결보완사항 입력
      </template>
      <div class="ns-btn-close" @click="$refs.refPendCpmtInpt.close()"></div>
      
      <div class="content-area pb6">
        <div class="round-box bgcolor-1">
          <mo-list :list-data="[lv_nundwPendIndctDto]">
            <template #list-item="{item}">
              <div class="list-item__contents">
                <div class="list-item-detail">
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">미결코드1</span><span class="fs16rem">{{item.pendIndctCdNm01}}</span>
                  </div>
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">미결코드2</span><span class="fs16rem">{{item.pendIndctCdNm02}}</span>
                  </div>
                  <div class="contents-row mb4">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">인심사일자</span><span class="fs16rem">{{item.pendIndctYmd}}</span>
                    <!-- <span class="dsInline fs14rem crTy-bk7 min65 mr20">인심사일자</span><span class="fs16rem">{{item.pendIndctYmd !== '' ? $bizUtil.dateDashFormat(item.pendIndctYmd.replace(/-/g, '').substr(0,8)):''}}</span> -->
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem crTy-bk7 min65 mr20">보완기한</span><span class="fs16rem">{{item.cpmtParngYmd}}</span>
                    <!-- <span class="dsInline fs14rem crTy-bk7 min65 mr20">보완기한</span><span class="fs16rem">{{item.cpmtParngYmd !== '' ? $bizUtil.dateDashFormat(item.cpmtParngYmd.replace(/-/g, '').substr(0,8)) : ''}}</span> -->
                  </div> 
                </div>
              </div>
            </template>
          </mo-list> 
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area mt20">
          <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb0">
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-text-area class="hauto pa15 crTy-bk1 lh23" rows="8" :maxlength="400" placeholder="미결보완 내용을 작성해주세요." v-model="lv_pendTrtCntnt"/>
              <div class="fex-rw fexJsCtTy3 pt10 fs12rem">
                <div class="crTy-bk7"><span class="fwb pr2 crTy-blue2">{{lv_pendTrtCntnt.length}}</span><span class="pl2 pr2">/</span><span class="pl2 pr2">400</span>자</div>
              </div>
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_PrePrcsPendIndctCpmt" class="ns-btn-round blue">미결보완사항 제출</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- // Popup: 미결보완제출 -->

    <!-- // Popup : 공통 안내 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refCommAlert" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{lv_AlertMsg}}</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.refCommAlert.close()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- // Popup : 오류 알럿 -->
    <mo-bottom-sheet preventTouchClose ref="refErrAlert" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{lv_ErrAlertMsg}}</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseErrAlert">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- confirmAlert팝업 Bottom Sheet : MSPPI083D 보험료 계산 시 사용하는 알럿 팝업 -->
    <!-- <mo-bottom-sheet ref="confirmAlert" slot-scope="" v-model="isShowConfirm" class="ns-bottom-sheet msp-bottomSt-alert" > -->
    <mo-bottom-sheet ref="confirmAlert" slot-scope="" class="ns-bottom-sheet msp-bottomSt-alert" >
      <div class="customer-info-txt">
        <p class="ment" v-html="confirmMsg"/>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="onNegative">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="onPositive">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- MultiAlert팝업 Bottom Sheet : MSPPI083D 보험료 계산 시 사용하는 알럿 팝업 -->
    <mo-bottom-sheet ref="multiAlertConfirm" class="ns-bottom-sheet msp-bottomSt-alert" >
    <!-- <mo-bottom-sheet ref="multiAlertConfirm" v-model="showMultiConfirm" class="ns-bottom-sheet msp-bottomSt-alert" > -->
      <div class="customer-info-txt">
        <p class="ment" v-html="confirmMsg"/>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="multiAlert">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import Gaf from '@/ui/pi/common/Gaf.js'
import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import GafInfoMsg from '@/ui/pi/common/GafInfoMsg.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import MSPPI821D from '@/ui/pi/MSPPI821D'
import MSPPI822D from '@/ui/pi/MSPPI822D'
import MSPPI825P from '@/ui/pi/MSPPI825P'
import MSPPI830P from '@/ui/pi/MSPPI830P'
import MSPPI840P from '@/ui/pi/MSPPI840P'
import MSPPI250P from '@/ui/pi/MSPPI250P'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

const PM_ID_FKO4TB5L0000 = 'FKO4TB5L0000' // ZLI_ExtCat_Quo_Info 자동감액, 적립전환, 지정인출 In
const PM_ID_9590TB5L0000 = '9590TB5L0000' // ZLI_Ext_Quo_PadPln_Prod 자유납,추가납,중도인출 In
const PM_ID_QUU3TB5L0000 = 'QUU3TB5L0000' // ZLI_Ext_Quo_SmtotPrmByPsgPrd 제안/최대보험료 Out
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPI820P",
  screenId: "MSPPI820P",
  isBackKeyHandle: true,
  components: {
    MSPPI821D, // 선심사 결과
    MSPPI822D, // 특약별 결과
    MSPPI825P, // 서류보완 팝업
    MSPPI830P, // 미결상세조회
    MSPPI250P, // 발행팝업
    MSPPI840P, // New-AEUS
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    pPage: {
      type: String,
      default: () => ''
    },
    pParams: {
      type: Object,
      // dsclsId 고지ID
      // planId 설계ID
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
    Gaf.initStore('MSPPI001M')
    GafMainFunc.setStore('MSPPI001M')
  },
  destroyed () {
    Gaf.deletePanel(this.name)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
  },
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.headerVO = this.getStore('userInfo').getters.getUserInfo
    
    // Gaf 세팅
    Gaf.initStore(this.name)
    GafMainFunc.setStore(this.name)
    Gaf.setProcessNo('1001') // SET_PROCESS_NO = '1001':L
    Gaf.setIfbcSelectionYn('Y')
    Gaf.addPanelList(this)
  },
  mounted() {
    console.log('MSPPI820 mounted ===')
    // this.fn_TabChangeHandler(this.defaultIdxs)
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()

    this.$refs.page.setScrollElementId(this.lv_HeaderviewScrollCompID)
    this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.lv_HeaderviewScrollCompID), 'y')

    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    window.vue.getStore('progress').dispatch('FULL')
    this.fn_Init()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      name: 'MSPPI820P',
      lv_HeaderviewScrollCompID: '',
      // =======================
      slctTabIdx : 1, 
      defaultIdxs : 1,
      bubble: false,
      //==============================
      lv_planId: '',
      lv_dsclsId: '',
      
      lv_isBsOpen: false,
      
      // 선심사결과 관련 =========================
      lv_AlertMsg: '',
      lv_ErrAlertMsg: '',
      lv_nundwPrgStatCd: '', // 선심사진행상태코드
      lv_OfrfmPblYn: '', // 청약서발행여부
      lv_nundwExpdExpYmd: '', // 선심사유효기간만기일자
      lv_nundwExpdEndYn: '', // 선심사유효기간종료여부
      lv_currCundwYn: '', // 당일 전산심사여부
      lv_inspPsbYn: '', // 인심사가능여부
      lv_nundwAplInfo: {}, // 선심사신청정보
      lv_lstNundwInspRslt: [], // 선심사인심사결과
      lv_lstNundwDignBasOpin: [], // 진단기본의견목록 - 선심사결과(MSPPI821D)
      lv_lstNundwDignUndtkStnd: [], // 진단결과인수기준목록 - MSPPI822D 사용
      lv_lstNundwCundwRslt: [], // 전산심사결과목록 - 비정상 세부내용(MSPPI821D)
      lv_lstNcsDocuMsgCntnt: [], // 필요서류메시지내용목록 - MSPPI821D 사용
      lv_lstNundwCundwAbnrm: [], // 선심사전산심사비정상목록 - 처리불가상세(MSPPI821D) - zanrmYn:N 인것만 보여준다..
      lv_isCalc: false, // 보험료계산여부
      lv_isChgPlanFlag: true, // 설계변경가능 여부
      lv_isChgFlag: false, // 특약 체크 또는 금액 변경 시 true 처리 -> 버튼 제어 목적
      // 합계보험료 관련 =======================
      lv_smtotPrm: {},
      // 미결/인심사 관련 ==============
      lv_inspPsbFlag: false, // 인심사요청 버튼
      pollingCnt: 0,
      lv_DocuList: [], // NAS에 있는 서류 이미지 목록
      lv_nundwPendIndctDto: {
        pendTrtScCd: '', // 미결처리구분코드
        dsclsId: '', // 고지ID
        planId: '', // 설계ID
        undwPendScCd: '', // 계약심사미결구분코드
        untyMngNoScCd: '', // 통합관리번호구분코드
        untyMngNo: '', // 통합관리번호
        trtSno: '', // 처리일련번호
        pendPrgStatCd: '', // 미결진행상태코드
        pendIndctCd01: '', // 미결지시코드01
        pendIndctCd02: '', // 미결지시코드02
        pendIndctCdNm01: '', // 미결지시코드명01
        pendIndctCdNm02: '', // 미결지시코드명02
        pendIndctYmd: '', // 미결지시일자
        pendCmpltYmd: '', // 미결완료일자
        cpmtParngYmd: '', // 보완예정일자
        undwrEno: '', // 계약심사자사번
        pendCntnt: '', // 미결내용
        pendTrtrEno: '', // 미결처리자사번
        pendTrtCntnt: '', // 미결처리내용
      },
      lv_jdgMemoCntnt: '', // 인심사요청-심사메모내용
      lv_pendTrtCntnt: '', // 미결처리내용
      lv_zNundwAplInfoDto: {}, // 선심사신청정보DTO
      // 보험료 계산 관련 =======
      scrollAnimateY: null,
      lv_isError: false,
      isShowConfirm: false,
      confirmMsg: '',
      showMultiConfirm: false,
      multiAlertObj: {},
      callback: '',
      lv_isChgCalc: false,
      lv_isGaMng: false, // GA설계매니저여부
      lv_GaCnsltNo: '', // GA컨설턴트번호(GA설계매니저가 선택한 컨설턴트)
      lv_GaFofOrgNo: '', // GA컨설턴트번호(GA설계매니저가 선택한 컨설턴트의 소속지점조직번호)
      headerVO: {},
      zzentplHlthsCd: '',
      // 설계저장
      zaPlanNm: '',
      policynrTt: '',
      lv_NextFn: '',
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    pParams: {
      deep: true,
      immediate: true,
      handler (item) {
        if (item !== null && Object.keys(item).length > 0) {
          for (let item1 in item) {
            // console.log(`item ====> ${item} : ${this.pParams[item]}`)
            if (item1 === 'zaPlanId' || item1 === 'planId') {
              this.lv_planId = item[item1]
            } else if (item1 === 'dsclsId') {
              this.lv_dsclsId = item[item1]
            }
          }
        }
      }
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    nundwExpYmd () {
      let expdate = ''
      if (!_.isEmpty(this.lv_nundwAplInfo)) {
        expdate = `${this.$bizUtil.dateDashFormat(this.lv_nundwAplInfo.nundwYmd.replace(/-/g, '').substr(0,8))} (유효기간 : ${this.$bizUtil.dateDashFormat(this.lv_nundwAplInfo.nundwExpdExpYmd.replace(/-/g, '').substr(0,8))})`
      }
      return expdate
    },
    disSmtotPrm () {
      let smtotPrm = ''
      if (Object.keys(this.lv_smtotPrm).length > 0) {
        if (!this.lv_isCalc) {
          // 변경 전 합계 보험료 세팅
          smtotPrm = this.$bizUtil.numberWithCommasCurr(this.lv_smtotPrm.prevSmtotPrm, this.lv_smtotPrm.currencyId, 'Y', 'N')
        } else {
          // 변경 후 합계 보험료 세팅
          smtotPrm = this.$bizUtil.numberWithCommasCurr(this.lv_smtotPrm.smtotPrm, this.lv_smtotPrm.currencyId, 'Y', 'N')
        }
      }
      return smtotPrm !== '' ? smtotPrm : '0'
    },
    lv_isArrow () {
      if (Object.keys(this.lv_smtotPrm).length > 0) {
        if (this.lv_isCalc && +this.lv_smtotPrm.diffSmtotPrm > 0) {
          return true
        }
      }
      return false
    },
    lv_pendCpmtYn () {
      if (!piCommonUtil.isEmpty2(this.lv_lstNundwInspRslt) && this.lv_lstNundwInspRslt.length > 0) {
        // let resultArr = this.lv_lstNundwInspRslt.filter(item => item.rsltStatCdNm === '미결')
        // return resultArr?.length > 0 ? resultArr[0].pendCpmtYn : ''
        return this.lv_lstNundwInspRslt[0].pendCpmtYn
      }
      return ''
    },
    lv_btnState () {
      // return !this.lv_isChgFlag
      return !this.lv_isChgFlag || (this.lv_isChgPlanFlag && this.lv_isChgFlag && !this.lv_isCalc)
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack (type) {
      if (type === 'close') {
        this.$emit('closePopup', {nundwPrgStatCd: this.lv_nundwPrgStatCd, planId: this.lv_planId})
      } else {
        // 백버튼 닫기 방지용
        if (this.$refs.refBsJdgReqOpinSbmt.status === 'open') {
          return
        }
        if (this.$refs.refBsRsltInfo.status === 'open') {
          return
        }
        if (this.$refs.refPendCpmtInpt.status === 'open') {
          return
        }
        // 공통 알럿 메시지
        if (this.$refs.refCommAlert.status === 'open') {
          this.$refs.refCommAlert.close()
          return
        }
        // 공통 에러 메시지
        if (this.$refs.refErrAlert.status === 'open') {
          return
        }
        this.$emit('closePopup', {nundwPrgStatCd: this.lv_nundwPrgStatCd, planId: this.lv_planId})
      }
    },
    fn_TabChangeHandler(idx){
      this.slctTabIdx = idx
      console.log("this.slctTabIdx = "+ this.slctTabIdx)
    },
    /******************************************************************************
     * Function명 : fn_ScrollTo
     * 설명       : scroll 화면의 scrollTop 위치로 이동
     * @param {Number} scrollTop - 이동하고자 하는 세로 위치(0: 최상단)
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ScrollTo (scrollTop) {
      // this.pageScrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
      console.log('fn_ScrollTo:', scrollTop)
      this.scrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
    },
    /******************************************************************************
     * Function명 : showBubble
     * 설명       : 합계보험료 말줄임시 말풍선 노출 함수
     ******************************************************************************/
    showBubble() {
      const skipText = this.$refs.skiptext
      const clone = skipText.cloneNode(true)

      Object.assign(clone.style, {
        position: 'absolute',
        top: '-0',
        zIndex: '-10', 
        visibility: 'hidden',
        width: 'auto',
        whiteSpace: 'nowrap',
        fontSize: '16px'
      })

      document.querySelector('.txt-total').appendChild(clone) //2024.03.05 수정: clone 위치 변경
      const realWidth = clone.offsetWidth
      document.querySelector('.txt-total').removeChild(clone) //2024.03.05 수정: clone 위치 변경

      if (realWidth > skipText.clientWidth) this.bubble = true
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 생성 시 초기화 함수
     ******************************************************************************/
    fn_Init () {
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        this.lv_isGaMng = true
        this.lv_GaCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        this.lv_GaFofOrgNo = this.getStore('cmStore').getters.getState.gssEplyInfo.fofOrgNo
        console.log('ga data ===> ', this.getStore('cmStore').getters.getState.gssEplyInfo)
      } else {
        this.lv_isGaMng = false
        this.lv_GaCnsltNo = ''
        this.lv_GaFofOrgNo = ''
      }
      // 선심사결과 조회
      this.fn_SelNundwRslt()
      // 표준설계 TEST ====
      // 상품정보 조회
      // this.$nextTick(() => {
      //   this.showBubble()
      //   this.$refs.refMSPPI822D.fn_SelProductList()
      // })
      // this.fn_SelProductList()
      // .then(() => {
      //   this.fn_SelPlanInfo()
      // })
    },
    /******************************************************************************
    * Function명 : fn_SetClass
    * 설명       : 심사결과 클래스 처리 함수
    ******************************************************************************/
    fn_SetClass (statCd, type) {
      // 심사필요	30
      // 심사중	40
      // 미결	50
      // 미결(임시저장)	51
      // 거절	60
      // 중지	70
      // 재설계필요	80
      // 심사완료	90
      
      switch (statCd) {
        case '90': // 심사완료
          return !_.isEmpty(type) ? '심사완료' : 'deepblue'
        case '10': // 작성중
          return !_.isEmpty(type) ? '작성중' : 'blue'
        case '40': // 심사중
          return !_.isEmpty(type) ? '심사중' : 'green'
        case '30': // 심사필요
          return !_.isEmpty(type) ? '심사필요' : 'orange'
        case '50': // 미결
          return !_.isEmpty(type) ? '미결' : 'red3'
        case '60': // 거절
          return !_.isEmpty(type) ? '거절' : 'darkgray'
        case '80': // 재설계필요
          return !_.isEmpty(type) ? '재설계필요' : 'black'
        default:
          break
      }
    },
    /******************************************************************************
     * Function명 : fn_CommAlert
     * 설명       : 안내 메시지 처리
     ******************************************************************************/
    fn_CommAlert (type, msg) {
      let alertMsg = ''
      let btnSingle = true
      let preventTouchClose = true
      let title_neg_btn = ''
      let title_pos_btn = ''
      let showCloseBtn = true
      switch (type) {
        case 'inspReq': // 인심사신청 완료
        case 'pendComplte': // 미결보완사항 완료 시
          alertMsg = '제출이 완료되었습니다.</br>인심사 진행현황은 \'최근가입설계\' 화면에서 확인하실 수 있습니다.'
          btnSingle = true
          title_pos_btn = '확인'
          break
        case 'pendRetry':
          alertMsg = '전송에 실패하였습니다. 다시 시도해주세요.'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '재전송'
          break
        case 'chkfnAbdaBase':
          alertMsg = '보험료계산 시 기 설계된 자유납입, 적립전환, 연금선지급 정보는 모두 초기화 됩니다.'
          btnSingle = true
          title_pos_btn = '확인'
          break
        case 'savePlan':
          alertMsg = '변경된 금액으로 가입설계를 저장하시겠습니까? 저장 시 설계번호는 변경되고, 전산심사를 재진행합니다.'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'btnRest':
          alertMsg = '변경한 가입금액이 초기화 됩니다. 가입금액을 초기화 하시겠습니까?'
          btnSingle = false
          title_neg_btn = '취소'
          title_pos_btn = '확인'
          break
        case 'chkCundw':
          alertMsg = '당일 전산심사 건이 없습니다. 청약서 발행은 전산심사 후 진행해주세요.'
          btnSingle = false
          title_neg_btn = '아니오'
          title_pos_btn = '예'
          break
        case 'zzentplHlthsCd3': // 표준체 -> 할증체
          alertMsg = '심사결과 할증체로 변경하겠습니다.</br>보험료계산 및 저장 시 최종 반영됩니다.'
          btnSingle = true
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        case 'zzentplHlthsCd0': // 할증체 -> 표준체
          alertMsg = '심사결과 표준체로 변경하겠습니다.</br>보험료계산 및 저장 시 최종 반영됩니다.'
          btnSingle = true
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        case 'zzentplHlthsCd': // 할증체 -> 할증
          alertMsg = '선심사결과 할증지수가 변경되었습니다.</br>보험료계산 및 저장 시 보험료에 반영됩니다.'
          btnSingle = true
          title_pos_btn = '확인'
          showCloseBtn = false
          break
        default:
          if (!_.isEmpty(msg)) {
            alertMsg = msg
            btnSingle = true
            title_pos_btn = '확인'
            showCloseBtn = false
          } else return
          break
      }
      let lv_vm = this
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'A',
          // type: 'B',
          single: btnSingle,
          content: alertMsg,
          title_neg_btn: title_neg_btn,
          title_pos_btn: title_pos_btn,
          showCloseBtn: showCloseBtn,
          title: '',
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj).then(() => {
              if (type === 'inspReq' || type === 'pendComplte') { // 인심사신청 완료, 미결보완사항 제출 완료
                if (type === 'inspReq') {
                  lv_vm.$refs.refBsJdgReqOpinSbmt.close()
                }
                // 선심사결과 재조회
                lv_vm.fn_SelNundwRslt()
              } else if (type === 'pendRetry') {
                // iSCAN 전송 함수 재호출
                lv_vm.fn_SendIScan()
              } else if (type === 'chkfnAbdaBase') {
                // 보험료계산 함수 호출!
                lv_vm.premCalc()
              } else if (type === 'savePlan') {
                // 저장 함수 호출
                lv_vm.fn_Save()
              } else if (type === 'btnRest') {
                lv_vm.fn_CalcReset()
              } else if (type === 'chkCundw') {
                // 전산심사
                lv_vm.fn_SendJudgeInfo()
              } else if (type === 'zzentplHlthsCd3') {
                let abdapolpr = Gaf.getGafObjByRefId("POLPR")[0]
                let abdapolicy1 = Gaf.getPObj('abdapolicy')[1]
                let abdapolpr1 = abdapolicy1.abdapolpr[0]
                let aldapolpr = abdapolpr.aldapolpr
                let aldapolpr1 = abdapolpr1.aldapolpr
                aldapolpr.zzentplHlthsCd = '3' // 할증체
                aldapolpr1.zzentplHlthsCd = '3' // 할증체
                Gaf.setPModel('abdapolicy', [Gaf.getPObj('abdapolicy')[0], abdapolicy1])
                this.fn_ChangeBottomSheet(true)
                console.log('zzentplHlthsCd3 : ', abdapolpr)
              } else if (type === 'zzentplHlthsCd0') {
                let abdapolpr = Gaf.getGafObjByRefId("POLPR")[0]
                let abdapolicy1 = Gaf.getPObj('abdapolicy')[1]
                let abdapolpr1 = abdapolicy1.abdapolpr[0]
                let aldapolpr = abdapolpr.aldapolpr
                let aldapolpr1 = abdapolpr1.aldapolpr
                aldapolpr.zzentplHlthsCd = '0' // 표준체
                aldapolpr1.zzentplHlthsCd = '0' // 표준체
                Gaf.setPModel('abdapolicy', [Gaf.getPObj('abdapolicy')[0], abdapolicy1])
                this.fn_ChangeBottomSheet(true)
                console.log('zzentplHlthsCd0 : ', abdapolpr)
              } else if (type === 'zzentplHlthsCd') {
                this.fn_ChangeBottomSheet(true)
              }
            })
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj).then(() => {
              if (type === 'chkCundw') {
                lv_vm.fn_OpenMSPPI250P()
              }
            })
          },
          onPopupCancel: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      },
      {
        properties: {preventTouchClose : preventTouchClose}
      })
    },
    /******************************************************************************
    * Function명 : fn_OepnConfirm
    * 설명       : 알럿메시지 공통 바텀시트 노출
    * @param : msg
    ******************************************************************************/
    fn_OepnConfirm(msg) {
      if (_.isEmpty(msg)) return
      this.lv_AlertMsg = msg
      this.$refs.refCommAlert.open()
    },
    /******************************************************************************
    * Function명 : fn_CloseErrAlert
    * 설명       : 오류메시지 확인 버튼 선택 시 팝업 종료
    ******************************************************************************/
    fn_CloseErrAlert () {
      this.$emit('closePopup')
    },
    /******************************************************************************
    * Function명 : fn_OpenBottomExpand
    * 설명       : 바텀 버튼 선택 시 함수
    ******************************************************************************/
    fn_OpenBottomExpand () {
      this.lv_isBsOpen = !this.lv_isBsOpen
    },
    /******************************************************************************
    * Function명 : fn_ChangeBottomSheet
    * 설명       : 바텀 버튼 변경 함수
    ******************************************************************************/
    fn_ChangeBottomSheet (item) {
      // 심사중 상태일때는 바텀 데이터 변경없어야함
      if (this.lv_nundwPrgStatCd === '40') return
      this.lv_isChgFlag = item
      if (!piCommonUtil.isEmpty2(Gaf.getPanel('MSPPI822D').lv_OriginData)) {
        this.lv_isChgCalc = true
      }
      this.lv_isCalc = false
    },
    fn_InitData () {
      this.lv_lstNundwInspRslt = [] // 선심사인심사결과
      this.lv_lstNundwDignBasOpin = [] // 진단기본의견목록
      this.lv_lstNundwDignUndtkStnd = [] // 진단결과인수기준목록
      this.lv_lstNundwCundwRslt = [] // 전산심사결과목록
      this.lv_lstNcsDocuMsgCntnt = [] // 필요서류메시지내용목록
      this.lv_lstNundwCundwAbnrm = [] // 선심사전산심사비정상목록
    },
    /******************************************************************************
     * Function명 : fn_SelNundwRslt
     * 설명       : 선심사결과 조회
     ******************************************************************************/
    fn_SelNundwRslt () {
      if (_.isEmpty(this.lv_planId)) {
        return
      }
      let lv_vm = this
      window.vue.getStore('progress').dispatch('SHOW')
      let inputJson = {
        dsclsId: this.lv_dsclsId, // 고지ID
        planId: this.lv_planId, // 설계ID
      }
      piCommonUtil.invoke2('UF1PQL0486', inputJson, this.fn_ResSelNundwRslt, null, this.fn_ResErrorHandler, this, this)
      console.log(`선심사결과 params ===> `, inputJson)
    },
    /******************************************************************************
     * Function명 : fn_ResSelNundwRslt
     * 설명       : 선심사결과 조회 후 처리
     ******************************************************************************/
    fn_ResSelNundwRslt (returnData) {
      console.log('fn_ResSelNundwRslt ===> ', returnData)
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        // lv_OfrfmPblYn: '', // 청약서발행여부
        // lv_nundwExpdExpYmd: '', // 선심사유효기간만기일자
        // lv_nundwExpdEndYn: '', // 선심사유효기간종료여부
        // lv_nundwAplInfo: {}, // 선심사신청정보
        // lv_lstNundwInspRslt: [], // 선심사인심사결과
        // lv_lstNundwDignBasOpin: [], // 진단기본의견목록
        // lv_lstNundwDignUndtkStnd: [], // 진단결과인수기준목록
        // lv_lstNundwCundwRslt: [], // 전산심사결과목록
        // lv_lstNcsDocuMsgCntnt: [], // 필요서류메시지내용목록
        // lv_lstNundwCundwAbnrm: [], // 선심사전산심사비정상목록
        
        if (!_.isEmpty(returnData.znundwRsltDto)) {
          this.fn_InitData() // list 초기화
          let znundwRsltDto = returnData.znundwRsltDto
          // 선심사신청정보
          if (znundwRsltDto.hasOwnProperty('nundwAplInfo')) {
            this.lv_nundwAplInfo = znundwRsltDto.nundwAplInfo
            this.lv_nundwExpdExpYmd = znundwRsltDto.nundwAplInfo.nundwExpdExpYmd // 선심사유효기간만기일자
            this.lv_nundwExpdEndYn = znundwRsltDto.nundwAplInfo.nundwExpdEndYn // 선심사유효기간종료여부
            this.lv_OfrfmPblYn = znundwRsltDto.nundwAplInfo.ofrfmPblYn // 청약서발행여부
            this.lv_dsclsId = znundwRsltDto.nundwAplInfo.dsclsId // 고지ID
            this.lv_nundwPrgStatCd = znundwRsltDto.nundwAplInfo.nundwPrgStatCd // 선심사진행상태코드
            this.lv_currCundwYn = '' // 당일 전산심사 여부
            this.lv_inspPsbYn = znundwRsltDto.nundwAplInfo.inspPsbYn // 인심사가능여부

            // 당일 전산심사 여부
            if (znundwRsltDto.nundwAplInfo.cundwYmd === piCommonUtil.getCurrentDate()) {
              this.lv_currCundwYn === 'Y'
            } else {
              this.lv_currCundwYn === 'N'
            }
          }
          // 선심사인심사결과
          if (znundwRsltDto.hasOwnProperty('lstNundwInspRslt')) {
            // this.lv_lstNundwInspRslt = znundwRsltDto.lstNundwInspRslt
            this.lv_lstNundwInspRslt = []
            if (!_.isEmpty(znundwRsltDto.lstNundwInspRslt) && znundwRsltDto.lstNundwInspRslt.length > 0) {
              znundwRsltDto.lstNundwInspRslt.forEach(chgItm => {
                chgItm.jdgCntntOpin = piCommonUtil.fn_ChangeTxtVal(chgItm.jdgCntntOpin)
                console.log('chgItm.jdgCntntOpin ====> ', chgItm.jdgCntntOpin)
                this.lv_lstNundwInspRslt.push(chgItm)
                chgItm.jdgCntntOpin = piCommonUtil.fn_ChangeTxtVal(chgItm.jdgCntntOpin)
              })
            }
          }
          // 진단기본의견목록
          if (znundwRsltDto.hasOwnProperty('lstNundwDignBasOpin')) {
            this.lv_lstNundwDignBasOpin = znundwRsltDto.lstNundwDignBasOpin
          }
          // 진단결과인수기준목록
          if (znundwRsltDto.hasOwnProperty('lstNundwDignUndtkStnd')) {
            this.lv_lstNundwDignUndtkStnd = znundwRsltDto.lstNundwDignUndtkStnd
          }
          // 전산심사결과목록
          if (znundwRsltDto.hasOwnProperty('lstNundwCundwRslt')) {
            this.lv_lstNundwCundwRslt = znundwRsltDto.lstNundwCundwRslt
          }
          // 필요서류메시지내용목록
          if (znundwRsltDto.hasOwnProperty('lstNcsDocuMsgCntnt')) {
            let lstNcsDocuMsgCntnt = znundwRsltDto.lstNcsDocuMsgCntnt
            console.log('lstNcsDocuMsgCntnt ===> ', lstNcsDocuMsgCntnt)
            if (typeof lstNcsDocuMsgCntnt === 'string') {
              this.lv_lstNcsDocuMsgCntnt = [znundwRsltDto.lstNcsDocuMsgCntnt]
            } else {
              this.lv_lstNcsDocuMsgCntnt = znundwRsltDto.lstNcsDocuMsgCntnt
            }
          }
          // 선심사전산심사비정상목록
          if (znundwRsltDto.hasOwnProperty('lstNundwCundwAbnrm')) {
            this.lv_lstNundwCundwAbnrm = znundwRsltDto.lstNundwCundwAbnrm.filter(findItem => findItem.zanrmYn === 'N')
          }
        }
        // 상품정보 조회
        this.$refs.refMSPPI822D.fn_SelProductList()

        this.$nextTick(() => {
          // if (this.lv_nundwExpdEndYn === 'Y') {
          //   // 하단 버튼 노출 제어
          //   this.lv_isChgPlanFlag = false // 설계변경가능 여부
          //   this.fn_OepnConfirm('선심사 유효기간이 만료되었습니다.\n새로운 가입설계로 선심사 신청을 진행해주세요.')
          // }
          // if (this.lv_OfrfmPblYn === 'Y') {
          //   this.lv_isChgPlanFlag = false // 설계변경가능 여부
          //   this.fn_OepnConfirm('청약서가 발행되어 선심사 진행이 종료되었습니다.')
          // } else {
          //   // 표준체, 할증체, 할증지수 변경 시 메시지 체크
          //   // 미결 상태일 경우
          //   // if (this.lv_nundwPrgStatCd === '50' && this.lv_lstNundwInspRslt.length > 0) {
          //   //   this.$refs.refBsRsltInfo.open()
          //   // }
          //   // // 재설계필요 또는 전산심사결과 발행불가인 경우
          //   // if (this.lv_nundwPrgStatCd === '80' || this.lv_lstNundwCundwAbnrm.length > 0) {
          //   //   this.fn_OepnConfirm('처리불가세부내용을 확인 후 재설계를 진행해주세요.')
          //   // }
          //   // 심사중 상태인 경우 설계변경 불가
          //   if (this.lv_nundwPrgStatCd === '40') {
          //     this.lv_isChgPlanFlag = false // 설계변경가능 여부
          //   }
          // }

          // 인심사 버튼 활성화
          let inspPsbYn = this.lv_inspPsbYn === 'Y' ? 'Y' : 'N'
          // 선심사유효기간이 종료된 경우 [인심사 요청] 비활성화 처리
          if (this.lv_nundwExpdEndYn === 'Y') {
            inspPsbYn = 'N'
          }
          // 선심사 진행상태가 40:심사중, 80:재설계필요 또는 청약서가 발행된 경우 [인심사 요청] 비활성화 처리
          if (['10', '70', '20', '40', '80'].indexOf(this.lv_nundwPrgStatCd) >= 0 || this.lv_OfrfmPblYn === 'Y') {
            inspPsbYn = 'N'
          }
          if (inspPsbYn === 'Y') {
            this.lv_inspPsbFlag = true
          } else {
            this.lv_inspPsbFlag = false
          }

          // 특약재설계화면 입력제어 세팅
          // let chkFlag = (this.lv_nundwAplInfo.cundwYmd !== piCommonUtil.getCurrentDate() && ['30','50','90'].includes(this.lv_nundwAplInfo.nundwPrgStatCd))
          // if (this.lv_nundwExpdEndYn === 'Y' || this.lv_OfrfmPblYn === 'Y' ||
          //     ['10', '70', '20', '40'].includes(this.lv_nundwPrgStatCd) || chkFlag
          // ) {
          if (this.lv_nundwExpdEndYn === 'Y' || this.lv_OfrfmPblYn === 'Y' ||
              ['10', '70', '20', '40'].includes(this.lv_nundwPrgStatCd)
          ) {
            this.$refs.refMSPPI822D.lv_isEdit = false
          } else {
            this.$refs.refMSPPI822D.lv_isEdit = true
          }
        })
      } else { // 결과 없는 경우
        window.vue.getStore('progress').dispatch('HIDE')
      }
    },
    /******************************************************************************
    * Function명 : fn_SetMessege
    * 설명       : 메시지 처리 함수
    ******************************************************************************/
    fn_SetMessege () {
      if (this.lv_nundwExpdEndYn === 'Y') {
        // 하단 버튼 노출 제어
        this.lv_isChgPlanFlag = false // 설계변경가능 여부
        this.fn_OepnConfirm('선심사 유효기간이 만료되었습니다.\n새로운 가입설계로 선심사 신청을 진행해주세요.')
        return
      }
      if (this.lv_OfrfmPblYn === 'Y') {
        this.lv_isChgPlanFlag = false // 설계변경가능 여부
        this.fn_OepnConfirm('청약서가 발행되어 선심사 진행이 종료되었습니다.')
      } else {
        // 표준체, 할증체, 할증지수 변경 시 메시지 체크
        if (!this.fn_DataLoadComplete()) {
          // 미결 상태일 경우
          if (this.lv_nundwPrgStatCd === '50' && this.lv_lstNundwInspRslt.length > 0) {
            this.$refs.refBsRsltInfo.open()
          }
          // 재설계필요 또는 전산심사결과 발행불가인 경우
          if (this.lv_nundwPrgStatCd === '80' || this.lv_lstNundwCundwAbnrm.length > 0) {
            this.fn_OepnConfirm('처리불가세부내용을 확인 후 재설계를 진행해주세요.')
          }
        }
        // 심사중 상태인 경우 설계변경 불가
        if (this.lv_nundwPrgStatCd === '40') {
          this.lv_isChgPlanFlag = false // 설계변경가능 여부
        }
      }
      // // 표준체, 할증체, 할증지수 변경 시 메시지 체크
      // if (!this.fn_DataLoadComplete()) {
      //   // 미결 상태일 경우
      //   if (this.lv_nundwPrgStatCd === '50' && this.lv_lstNundwInspRslt.length > 0) {
      //     this.$refs.refBsRsltInfo.open()
      //   }
      //   // 재설계필요 또는 전산심사결과 발행불가인 경우
      //   if (this.lv_nundwPrgStatCd === '80' || this.lv_lstNundwCundwAbnrm.length > 0) {
      //     this.fn_OepnConfirm('처리불가세부내용을 확인 후 재설계를 진행해주세요.')
      //   }
      // }
    },
    /******************************************************************************
    * Function명 : fn_ResErrorHandler
    * 설명       : 선심사결과 조회 및 저장 서비스 호출 결과 오류 처리 함수
    ******************************************************************************/
    fn_ResErrorHandler (resultData) {
      window.vue.getStore('progress').dispatch('HIDE')
      console.log('fn_ResErrorHandler ===> ', resultData)
      if (!_.isEmpty(resultData.msgComm.msgDesc)) {
        let msgArr = resultData.msgComm.msgDesc.split(', ')
        let alertMsg = null
        if (msgArr.length > 0) {
          alertMsg = msgArr.find(item => item.indexOf('userMsg=') > -1)
        }
        if (!_.isEmpty(alertMsg) && Object.keys(alertMsg).length > 0) {
          alertMsg = alertMsg.replace(/userMsg=|\[|\]/g, '')
        } else {
          // alertMsg = resultData.msgComm.msgDesc
        }
        if (!_.isEmpty(alertMsg)) {
          this.lv_ErrAlertMsg = alertMsg
          // this.$refs.refErrAlert.open()
          this.fn_OepnConfirm(this.lv_ErrAlertMsg)
        } else {
          this.fn_OepnConfirm(resultData.msgComm.msgDesc)
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_CalcReset
     * 설명        : 초기화 버튼 선택 시 처리 함수
     ************************************************************************************************/
    fn_CalcReset () {
      this.lv_isChgFlag = false // 특약 체크 또는 금액 변경 시 true 처리 -> 버튼 제어 목적
      this.lv_isCalc = false // 보험료계산여부
      console.log('fn_CalcReset 에서 MSPPI822D.fn_Reset 호출 ===================')
      this.$refs.refMSPPI822D.fn_Reset()
    },
    /************************************************************************************************
     * Function명  : fn_PreChkPremCalc
     * 설명        : 보험료 계산 함수 호출 전 자유납입, 적립전환, 연금선지급 데이터 체크
     ************************************************************************************************/
    fn_PreChkPremCalc () {
      if (this.fn_ChkFndelAbdaBase()) {
        // 안내메시지 노출
        this.fn_CommAlert('chkfnAbdaBase')
      } else {
        this.premCalc()
      }
    },
    /************************************************************************************************
     * Function명  : fn_premCalc
     * 설명        : 보험료 계산 함수 1
     *              [원본] MSPPI083D
     ************************************************************************************************/
    premCalc () {
      let lv_Vm = this
      if (lv_Vm.DEBUG) console.log(">>> 1. premCalc START ========")
      window.vue.getStore('progress').dispatch('INIT')
      this.$commonUtil.getServerTime().then(function (response) {
        // 서버 현재 일자 적용
        Gaf.setStndYmd(moment(response).format('YYYY-MM-DD'))
        lv_Vm.fn_PremCalc()
      })
    },
    /************************************************************************************************
     * Function명  : fn_PremCalc
     * 설명        : 보험료 계산 함수 2
     *              [원본] MSPPI083D
     ************************************************************************************************/
    async fn_PremCalc () {
      let lv_Vm = this
      if (lv_Vm.DEBUG) console.log(">>> 2. fn_PremCalc START ========")

      // 20160905 yherp.kwon : 메인페이지 패널별 필수입력항목 체크
      let isValidate = true
      // yhmob.kwon 연금유형 선택값
      console.log(Gaf.getTObj('baseContDto').zzantPymPrdVl)

      window.vue.getStore('progress').dispatch('INIT')

      let itemCheckResult = Gaf.processCommonPanelFunction('fn_checkItemValidate')
      for (let name in itemCheckResult) {
        if (!itemCheckResult[name]) {
          isValidate = false
          return false
        }
      }
      if (!isValidate) {
        return false
      }

      let zzcClctTypCd = Gaf.getPObj('abdapolicy')[0].zzcClctTypCd
      /* 방카계약의 역산상품의 경우 가입금액 주계약과 의무부가 특약이 가입금액 배수체크시 zz field로 관리하여
         실제 가입금액이 아니어서 화면에서 체크할때 가입금액 상이 오류 메시지를 생성하여 오류처리를 하지만
         심사에서는 접수이후 보험관련정보는 변경을 하지 않기에 체크를 하지 않도록 수정(조성문차장 요청 :2017-11-27)
      */
      if ( (Gaf.getProcessNo() === '3001' || Gaf.getProcessNo() === '3999') && zzcClctTypCd === '19' ) {
        console.log('방카의 경우 특약관련 관계규칙 체크 pass')
      } else {
        // [선심사PJT] ProcessNo === 1001 외엔 없고, 관계 규칙 체크 X, 특약 탭 X 주석처리
        // 20160502 yherp.kwon : 관계규칙체크적용 (보험료계산 오류(관계규칙) 서비스 처리로 삭제처리 2019-01-15)
        // if ( Gaf.getProcessNo() !== '1001') {
        //   if (!Gaf.getPanel('MSPPI080D').fnCheckRltnRlDt( null, 'ALL')) {
        //     return false
        //   }
        // } else {
        //   // EventBus.$emit('MSPPI080D', {functionName: 'fn_initTab'}) // 특약탭 "전체"로.
        //   Gaf.getPanel('MSPPI080D').fn_initTab()   // 특약탭 "전체"로.
        // }
        
        // 20160729 hj0121.park : 적립전환 입력값 초기화
        this.fndelAbdaBase(PM_ID_FKO4TB5L0000)
        
        // 미선택 특약 관계자 초기화 처리는 필수값 체크 및 관계규칙 체크가 끝난 이후에 실행
        // 미선택 특약 관계자 초기화 처리
        let _rltnrInfo = Gaf.getTObj('rltnrInfo').filter(function ( item ) { return item.zzcontvInsrdCd === '21' }) // 주피

        if (piCommonUtil.isObj(_rltnrInfo) && _rltnrInfo.length > 0 && _rltnrInfo[0].zaVcustYn !== 'Y') { // 가상고객이 아닐때,
        // let oPanel = u5.panel.getPanel('PNLPQL00105')

          let _newRltnrInfo = []
          let unborn = false

          if (_rltnrInfo[0].zaInsrdNm === '태아' && piCommonUtil.isObj(_rltnrInfo[0].zzcustRrnId) && _rltnrInfo[0].zzcustRrnId.substring(0, 7) === '0000003') {
            unborn = true
          }

          Gaf.getTObj('rltnrInfo').forEach((el, i) => {
            if (el.zzcontvInsrdCd === '21' || el.zzcontvInsrdCd === '11') {
              _newRltnrInfo.push(el)
              return true
            }
            _rltnrInfo = Gaf.getVObj('PNLPQL00109_CoverageInfo')
              .filter(function (item) {
                return item.isChecked &&
                  (item.subInsured === el.zzcontvInsrdCd || (item.insured === el.zzcontvInsrdCd &&
                                (el.zzcontvInsrdCd === '23' || (el.zzcontvInsrdCd === '24' &&
                                (el.zzchldSnoVl === undefined ||
                                  piCommonUtil.isEmpty2(Number(el.zzchldSnoVl).toString()) ||
                                  (!piCommonUtil.isEmpty2(Number(el.zzchldSnoVl).toString()) && item.childNo === Number(el.zzchldSnoVl).toString())
                                )))))
              })

            if (piCommonUtil.isObj(_rltnrInfo) && _rltnrInfo.length > 0) {
              _newRltnrInfo.push(el)
              return true
            } else {
              // 계약주피가 태아의 경우, 계약종피는 삭제처리 하지 않음
              if (el.zzcontvInsrdCd === '23' && unborn) {
                _newRltnrInfo.push(el)
                return true
              }
            }
            // [TO-DO] 세대정보 관련 처리할 거 확인 후 적용 여부 판단하기
            // if ( Gaf.getProcessNo() !== '3001' && Gaf.getProcessNo() !== '3999' ) {
            //   // 화면 컴포넌트 선택 해제
            //   if (el.zzcontvInsrdCd === '23') {
            //     // oPanel.l00105_sec20_combobox40.setValue('선택')
            //     // EventBus.$emit('MSPPI010D', {functionName: 'fn_init_subInfo'})
            //     this.$emit('fnChangeCustSummary', {zzcontvInsrdCd: '23'})
            //   } else if (el.zzcontvInsrdCd === '24') {
            //     // oPanel.l00105_sec20_combobox50.setValue('선택')
            //     EventBus.$emit('MSPPI010D', {functionName: 'fn_init_childInfo', data: Number(el.zzchldSnoVl).toString()})
            //     this.$emit('fnChangeCustSummary', {zzcontvInsrdCd: '24', zzchldSnoVl: Number(el.zzchldSnoVl).toString()})
            //   }
            // }
          })

          Gaf.setTModel('rltnrInfo', _newRltnrInfo)
        }

        // toPmodel ===================================================
        let urlId = 'URLPQL00084'
        Gaf.clearResultBox()
        let result = Gaf.processCommonPanelFunction('toPmodel', urlId)
        // console.log('refMSPPI822D.toPmodel result ===> ', result)
        let error = []
        for (let name in result) {
          if (result[name]) {
            error.push(result[name])
          }
        }
        let alertMsg = ''
        if (error && error.length > 0) {
          let errorMsgTitle = '다음의 사유로 보험료계산 처리가 불가합니다.\n\n'
          let errorMsg = ''
          let errorYn = false
          for (let i in error) {
            for (let k in error[i]) {
              if (error[i][k].indexOf('[alert]') < 0) {
                errorYn = true
                errorMsg = errorMsg + error[i][k] + '\n'
              } else {
                error[i][k] = error[i][k].split('[alert]')[1]
                alertMsg = alertMsg + error[i][k] + '<br>'
              }
            }
            errorMsg = errorMsg + '\n'
            alertMsg = alertMsg + '<br>'
          }
          if (errorYn) {
            piCommonUtil.getFDPConfirm('m', errorMsgTitle + errorMsg)
            return false
          }
        }
        let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
        _rltnrInfo = Gaf.getTObj('rltnrInfo')

        if (!piCommonUtil.isObj(_rltnrInfo)) {
          return false
        }
        let _rltnrInfo_11 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '11' })[0]// min.ko 17.01.10
        let _rltnrInfo_21 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '21' })[0]
        let _rltnrInfo_23 = _rltnrInfo.filter(function ( item ) { return item.zzcontvInsrdCd === '23' })[0]

        let zzenprsScId = this.fnCheckMessage()
        Gaf.d(zzenprsScId)

        let zzjdgObjPTId = GafMainFunc.getIfbcValue('ZZJDG_OBJ_P_T_ID') // 심사대상상품유형코드RD
        let zaAgtFileId_11
        let zaAgtFileId_21
        let zzcarKndScCd_21
        let zaInsrJobNm_21
        let zaInsrJobNm_23
        let zzcarKndScCd_23
        let rltnrInfo_23_age
        if (piCommonUtil.isObj(_rltnrInfo_11)) {
          zaAgtFileId_11 = _rltnrInfo_11.zaAgtFileId // 계약자 채널고객ID
        }
        if (piCommonUtil.isObj(_rltnrInfo_21)) {
          zaAgtFileId_21 = _rltnrInfo_21.zaAgtFileId // 주피 채널고객ID
          zzcarKndScCd_21 = _rltnrInfo_21.zzcarKndScCd // 주피 차량선택코드
          zaInsrJobNm_21 = _rltnrInfo_21.zaInsrJobNm
        }
        if (piCommonUtil.isObj(_rltnrInfo_23)) {
          zzcarKndScCd_23 = _rltnrInfo_23.zzcarKndScCd // 종피 차량선택코드
          rltnrInfo_23_age = _rltnrInfo_23.ageatentryVl // 종피나이
          zaInsrJobNm_23 = _rltnrInfo_23.zaInsrJobNm
        }

        // 의료실손상세속성코드RD  대체값 (ZZCLNIC_I_T_A_ID -> ZZCLNIC_I_T_A_CD) : 실손특약 체크여부 확인
        let abdapolicy = Gaf.getPObj('abdapolicy')[0]
        let abdapolpr = abdapolicy.abdapolpr[0]
        let abdacovpac = abdapolpr.abdacovpac
        let zzprmtrPrdTCd = abdapolpr.aldapolpr.zzprmtrPrdTCd // 파라미터상품유형코드
        // min.ko 170426
        if (Gaf.getProcessNo() === '1001' && piCommonUtil.isObj(abdacovpac) && abdacovpac !== undefined && abdacovpac.length > 0) {
          for (let i = 0; i < abdacovpac.length; i++) {
            let abdacov = abdacovpac[i].abdacov

            if (piCommonUtil.isObj(abdacov) && abdacov !== undefined && abdacov.length > 0) {
              for (let n = 0; n < abdacov.length; n++) {
                // 납면특약 계피상이여부 체크
                if (abdacov[n].aldacov.zzinscdScCd === '18') {
                  if (zaAgtFileId_11 !== zaAgtFileId_21) {
                    piCommonUtil.getFDPConfirm('s', '납입면제특약 가입 시 계약자와 주피보험자가\n동일해야 합니다.')
                    return false
                  }
                }

                // 실손 특약인 경우
                if (!piCommonUtil.isEmpty2(abdacov[n].aldacov.zzclnicITACd) && abdacov[n].aldacov.zzclnicITACd !== '00') {
                  // 주종피 실손 특약 가입 시 직업/차량 체크
                  if (zzptclrPrdTId !== '20' && zzptclrPrdTId !== '28' && zzptclrPrdTId !== '29' && zzprmtrPrdTCd !== 'L25') {
                    if (abdacov[n].covAbdasubjct[0].zzcontvInsrdCd === '21') { // 주피.종피체크 변경    2017.06.23
                      // [선심사PJT] 특약설계 화면에서 이미 체크하고 온 로직이고, MSPPI040D 화면 연계가 되어 있지 않기 때문에 화면 호출은 주석처리 함
                      if ((zzcarKndScCd_21 === undefined || piCommonUtil.isEmpty2(zzcarKndScCd_21)) && !piCommonUtil.isEmpty2(zaInsrJobNm_21) ) {
                        // 차종선택 화면을 호출
                        // Gaf.getPanel('MSPPI040D').fn_OpenMSPPI086P()
                        piCommonUtil.getFDPConfirm('s', '차종 입력하세요.')
                        return false
                      }
                      else if (zzcarKndScCd_21 === undefined || piCommonUtil.isEmpty2(zzcarKndScCd_21) || piCommonUtil.isEmpty2(zaInsrJobNm_21) || zaInsrJobNm_21 === undefined ) {
                        piCommonUtil.getFDPConfirm('s', '주피 직업 및 차량을 입력하세요.')
                        return false
                      }
                    } else if (abdacov[n].covAbdasubjct[0].zzcontvInsrdCd === '23') { // 주피.종피체크 변경    2017.06.23
                      if (String(rltnrInfo_23_age) === '0') { // 종피의 나이가 0세가 아닌 경우에만 체크

                      } else if (zzcarKndScCd_23 === undefined || piCommonUtil.isEmpty2(zzcarKndScCd_23) || piCommonUtil.isEmpty2(zaInsrJobNm_23) || zaInsrJobNm_23 === undefined ) {
                        piCommonUtil.getFDPConfirm('s', '종피 직업 및 차량을 입력하세요.')
                        return false
                      }
                    }
                  }

                  // 통합보험 피보험자별 의료수급권자 선택 여부 체크
                  let _baseInfo00054 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00054' })

                  // if (oPanel105 && piCommonUtil.isObj(oPanel105['l00105_sec20_component80_00054'])) {
                  
                  // [선심사PJT] 특약설계 화면에서 이미 체크하고 온 로직이고, 기본계약사항 입력 화면이 없기 때문에 주석처리 한다
                  //            Gaf.getPanel('MSPPI002D') 가 없기 때문에 로직에서 삭제
                  // if (Gaf.getPanel('MSPPI002D') &&
                  if (piCommonUtil.isObj(_baseInfo00054) && _baseInfo00054.length > 0) {
                    let rltnrType = abdacov[n].aldacov.zzprmtrCITCd
                    let chldSno = abdacov[n].aldacov.zzprmtrChlSCd
                    let rltnrTypeNm = GafMainFunc.makeRltnrText(rltnrType, chldSno)
                    if (piCommonUtil.isEmpty2(abdacov[n].covAbdasubjct[0].zzmdRcAuPeFg)) {
                      piCommonUtil.getFDPConfirm('s', '[' + rltnrTypeNm + '] 의 의료수급권자여부를 선택하시기 바랍니다')
                      // oPanel105.l00105_sec20_component80_00054.setText('선택')
                      // EventBus.$emit('MSPPI040D', {functionName: 'fn_initBaseCont', data: {code: '00054'}})
                      // Gaf.getPanel('MSPPI040D').fn_initBaseCont({code: '00054'})
                      return false
                    }
                  }

                  let _baseInfo00032 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00032' })
                  // if (oPanel105 && isObj(oPanel105['l00105_sec20_component80_00032'])) {
                  if (Gaf.getPanel('MSPPI002D') &&
                        piCommonUtil.isObj(_baseInfo00032) && _baseInfo00032.length > 0) {
                    if (piCommonUtil.isEmpty2(abdacov[n].covAbdasubjct[0].zzmdRcAuPeFg) || abdacov[n].covAbdasubjct[0].zzmdRcAuPeFg === '') {
                      piCommonUtil.getFDPConfirm('s', '의료수급권자여부를 선택하시기 바랍니다')
                      return false
                    }
                  }
                }// End 실손 특약인 경우
              }
            }
          }
        }
        /* 개인가설 보장적립유형코드, 가설대표분류코드 체크 로직변경
          1. 심사대상상품유형코드(ZZJDG_OBJ_P_T_ID) 50(신상해)이고
          1.1 주피의 차량종류코드가 선택되어 있지 않으면
                메세지발생 : "차량이 선택되지 않았습니다."
                다음단계는 차량 선택해야 넘어감.
          1.2 차량코드선택 되어 있으면 (2016.12.19 박영현선임 요청)
                메세지발생 : "설계하신 상품은 상해상품입니다.\n직업상해요율 상이시 반송될 수 있습니다. 주의요망합니다."

          ※ 가설대표분류코드에 있는 상품은 판매중지상품이므로 미반영
        */
        if (zzjdgObjPTId === '50' && Gaf.getProcessNo() === '1001') {
          if ((zzcarKndScCd_21 === undefined || piCommonUtil.isEmpty2(zzcarKndScCd_21)) && !piCommonUtil.isEmpty2(zaInsrJobNm_21) ) {
            // 차종선택 화면을 호출
            // [선심사PJT] 특약설계 화면에서 이미 체크하고 온 로직이고, MSPPI040D 화면 연계가 되어 있지 않기 때문에 화면 호출은 주석처리 함
            // Gaf.getPanel('MSPPI040D').fn_OpenMSPPI086P()
            piCommonUtil.getFDPConfirm('s', '차종 입력하세요.')
            return false
          }
          else if (piCommonUtil.isEmpty2(zzcarKndScCd_21) || zzcarKndScCd_21 === undefined || piCommonUtil.isEmpty2(zaInsrJobNm_21) || zaInsrJobNm_21 === undefined ) {
            piCommonUtil.getFDPConfirm('s', '직업 및 차량을 입력하세요.')
            return false
          }
        }

        // 가입설계 : 할증지수코드가 100이상으로 입력되어있는데 기본계약의 주피 / 종피 건강체가 표준체로 입력된 경우 블러킹
        if ( Gaf.getProcessNo() === '1001') {
          // 주보험
          let _gaf = Gaf
          let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
          let aldapolpr = abdapolpr.aldapolpr
          let mainAbdacov = Gaf.getGafObjByRefId('COV')[0]
          // 주보험의 주피
          let mainCovAbdasubjct = mainAbdacov.covAbdasubjct.filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]

          // 주피 기본계약 건강체 선택 체크
          if (piCommonUtil.isObj(_rltnrInfo_21)) {
            // 피보험자 진단지수정보
            let rltnrNm = _rltnrInfo_21.zaInsrdNm
            let rsltIndxn = ''
            let rsltIndxn2 = ''

            if (piCommonUtil.isObj(mainCovAbdasubjct)) {
              // 첫번째 지수나 두번째 지수가 100초과이나 기본계약 건강체를 표준체로 선택한 경우 블러킹
              if ((!piCommonUtil.isEmpty2( Number(mainCovAbdasubjct.zzmnisdIndxnVl).toString()) && Number(mainCovAbdasubjct.zzmnisdIndxnVl) > 100) ||
                                                        (!piCommonUtil.isEmpty2( Number(mainCovAbdasubjct.zzmnisd2indxVl).toString()) && Number(mainCovAbdasubjct.zzmnisd2indxVl) > 100)) {
                if (aldapolpr.zzentplHlthsCd !== '3') {
                  rsltIndxn = mainCovAbdasubjct.zzmnisdIndxnVl
                  if (!piCommonUtil.isEmpty2(mainCovAbdasubjct.zzmnisd2indxVl)) {
                    rsltIndxn2 = ',' + mainCovAbdasubjct.zzmnisd2indxVl
                  }
                  // u5.alert('Alert', getFormatMsg('<!--[M, PQLALT00506]-->', [rltnrNm, rsltIndxn, rsltIndxn2]))
                  let msg = `${rltnrNm}고객님은 주보험의 할증지수${rsltIndxn}${rsltIndxn2}존재합니다.
                              건강상태를 할증체로 가입하시기 바랍니다.`
                  piCommonUtil.getFDPConfirm('s', msg)
                  return false
                }
                // 첫번째 지수나 두번째 지수가 모두 100이하 OR 빈값이나 기본계약 건강체를 할증체로 선택 한 경우 블러킹
              } else if ((piCommonUtil.isEmpty2( Number(mainCovAbdasubjct.zzmnisdIndxnVl).toString()) || Number(mainCovAbdasubjct.zzmnisdIndxnVl) <= 100) &&
                                                    (piCommonUtil.isEmpty2( Number(mainCovAbdasubjct.zzmnisd2indxVl).toString()) || Number(mainCovAbdasubjct.zzmnisd2indxVl) <= 100)) {
                if (aldapolpr.zzentplHlthsCd === '3') {
                  rsltIndxn = mainCovAbdasubjct.zzmnisdIndxnVl
                  if (!piCommonUtil.isEmpty2(mainCovAbdasubjct.zzmnisd2indxVl)) {
                    rsltIndxn2 = ',' + mainCovAbdasubjct.zzmnisd2indxVl
                  }

                  piCommonUtil.getFDPConfirm('s', '주보험 할증지수가 없습니다. 할증체로 설계가 불가능합니다.')
                  return false
                }
              }
            }
          }

          // 2017.01.26 변액적합성체크
          _rltnrInfo = Gaf.getTObj('rltnrInfo')[0]
          let abdapolhldr = abdapolicy.abdapolhldr[0]
          let ztpqlCboextAddmAbdapolhldr = abdapolhldr.ztpqlCboextAddmAbdapolhldr

          // 적합성 - 단체계약자 && (변액상품 또는 외화보험 ) && 주피자 가망고객일때 법인대표자 정보가 없다면 블러킹.
          /** yherp.kwon : 법인 판단 로직 수정 */
          // if(!isEmpty2(ztpqlCboextAddmAbdapolhldr.zaGNm) && abdapolicy.abdapolpr[0].aldapolpr.zzilsITCLVl === '04' && _rltnrInfo.zaVcustYn === "N"){
          let rltnrInfo_B = Gaf.getTObj('rltnrInfo').filter(function (item) { return item.zzcontvInsrdCd === '11' && item.zzcusDtlTypCd === '21' })
          let rltnrInfo_21 = Gaf.getTObj('rltnrInfo').filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
          if (piCommonUtil.isObj(rltnrInfo_B) && rltnrInfo_B.length > 0) {
            if ( (abdapolicy.abdapolpr[0].aldapolpr.zzilsITCLVl === '04' || this.isBtnShow.isShowBtn160F ) && rltnrInfo_21.zaVcustYn === 'N') { // 달러보험인경우 체크 추가 isShowBtn160F true (ASR220400623_외화 적합성 관련 사랑온)
              if (piCommonUtil.isEmpty2(ztpqlCboextAddmAbdapolhldr.zaResntNm) || piCommonUtil.isEmpty2(ztpqlCboextAddmAbdapolhldr.zaResntRrnId) || piCommonUtil.isEmpty2(ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId) ||
                                            ztpqlCboextAddmAbdapolhldr.zaResntNm === '' || ztpqlCboextAddmAbdapolhldr.zaResntRrnId === '' || ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId === '') {
                // u5.error('법인대표자의 적합성진단 결과를 입력해주시기 바랍니다.')
                piCommonUtil.getFDPConfirm('s', '법인대표자의 적합성진단 결과를 입력해주시기 바랍니다.')
                return false
              }
            }
          }
        }
        // ============================================================================================================================================
        // 가입설계일때만, 정보성 메세지 표시
        // if (Gaf.getProcessNo() === '1001' && piCommonUtil.isObj(GafInfoMsg)) {
        // [선심사PJT] Gaf.getPanel('MSPPI002D') 조건 삭제
        // if (Gaf.getProcessNo() === '1001' && Gaf.getPanel('MSPPI002D')) {
        if (Gaf.getProcessNo() === '1001') {
          let lv_Vm = this
          let infoMsgList = GafInfoMsg.chkInfoMsgs(lv_Vm)
          let msgArr = []
          if (piCommonUtil.isObj(infoMsgList) && infoMsgList.length > 0) {
            infoMsgList.forEach((el, i) => {
              //msgArr.push(el)
              msgArr.push(el.replace(/\n/gi, '<br>'))
            })
            // 정보성 메세지 alert 처리후, 마지막 alert창이 닫힐때 보험료계산 처리

            this.multiAlertObj = {msgArr: msgArr, lastCallBackFunc: this.fnCalcPrm}
            // Gaf.multiAlert(msgArr, () => { this.fnCalcPrm() })
            this.multiAlert()
            // window.vue.getStore('progress').dispatch('UPDATE', false)   // Progress Bar 해제 추가
            window.vue.getStore('progress').dispatch('INIT')
            return false
          }
        }
        // =================================================================================================================================
        // 보험료계산 호출
        if (alertMsg !== '') {
          // u5.alert({message: '[알림 메시지]\n\n\n' + alertMsg, clickFunc: function () { u5.panel.getPanel('PNLPQL00110').fnCalcPrm(evt) } })
          this.confirmMsg = '[알림 메시지]<br><br><br>' + alertMsg
          this.callback = 'fnCalcPrm'
          this.$refs.confirmAlert.open()
        } else {
          this.fnCalcPrm()
        }
      }
    },
    /************************************************************************************************
     * Function명  : fnCalcPrm
     * 설명        : 보험료 계산 함수 3
     *               [원본] MSPPI083D
     ************************************************************************************************/
    fnCalcPrm (evt) {
      let urlId = 'URLPQL00084'
      // 전산심사결과 초기화
      Gaf.setTModel('systmUndwRslt', {})
      // 자유입출금 정보 초기화
      this.fndelAbdaBase(PM_ID_9590TB5L0000)
      // 2016.08.31자 hj0121.park : 보장적립 팝업 추가
      let _baseInfo00033 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00033' })
      // [선심사PJT] Gaf.getPanel('MSPPI002D') 및 보장적립 팝업 연계되지 않아 주석처리한다.
      if ( Gaf.getProcessNo() === '1001' && // 개인가입설계 Process No = "1001"
          // Gaf.getPanel('MSPPI002D') &&
          piCommonUtil.isObj(_baseInfo00033) && _baseInfo00033.length > 0 &&
          Gaf.getTObj('baseContDto').zaAcumContPlanYn === 'Y') { // 포함
        // this.popUpParam = {}
        // this.showMSPPI083P = true
        // this.fn_OpenMSPPI083P()
      } else {
        // INPUT 셋팅
        let inputJson = {
          pmodel: Gaf.getObj('pmodel'),
          tmodel: Gaf.getObj('tmodel')
        }
        // console.log('[MSPPI820 보험료계산] tmodel : ', JSON.stringify(inputJson.tmodel))
        // [TO-DO] 1번째 데이터 백업하기!!
        let _nextServiceFn = function () {

          // 상세결과가 중 에러가 있는경우만 띄움
          let cundwRsult = Gaf.getTObj('systmUndwRslt')
          if (cundwRsult && cundwRsult.systmUndwRsltDtl && cundwRsult.systmUndwRsltDtl.length > 0 && cundwRsult.zaLstNrmYn === 'N') {
            // [선심사PJT] fn_OnChangeBaseContDto 제외
            // if (Gaf.getMainPage()['fn_OnChangeBaseContDto']) {
            //     Gaf.getMainPage()['fn_OnChangeBaseContDto']()
            // }
            // EventBus.$emit('MSPPI080D', {functionName: 'fnDispErrList', data: false}) // 보험료계산 오류 내역 표시
            Gaf.getPanel('MSPPI822D').fnDispErrList(false)  // 보험료계산 오류 내역 표시
            // 보험료 계산 상태 변경
            Gaf.getPanel('MSPPI820P').lv_isCalc = false
          } else {
            // 보험료 계산 상태 변경
            Gaf.getPanel('MSPPI820P').lv_isCalc = true
          }
        }
        // TEST====================
        console.log('MSPPI820P 보험료계산 input Data ===> ', JSON.stringify(inputJson))
        // TEST====================
        Gaf.execute('MSPPI820P', urlId, inputJson, true, _nextServiceFn, this)
      }
    },
    
    /******************************************************************************
    * Function명 : fn_checkItemValidate
    * 설명       : 필수값 체크함수 추가 20170810 박세현 추가
    *              가입금액, 보험기간, 납입기간 체크
    *              [원본] MSPPI080D
    ******************************************************************************/
    // fn_checkItemValidate () {
    //   Gaf.d('MSPPI080D fnCheckItemValidate Start !!!!')

    //   let errors = []
    //   let errors2 = []
    //   let isValidate = true
    //   let tmp = Gaf.getVObj('PNLPQL00109_CoverageInfo')
    //   let sendList = []

    //   tmp.forEach((el, i) => {
    //     if (el.isChecked === true) {
    //       sendList.push(el)
    //     }
    //   })

    //   let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID')
    //   sendList.forEach((el, i) => {
    //     let zzinscdScCd = el.zzinscdScCd
    //     let zzprmtrPFSCd = el.GafProductInfo.zzprmtrPFSCd
    //     let result = true

    //     // 보험코드구분코드 확인
    //     if (zzptclrPrdTId === '29' && zzprmtrPFSCd === '06') {
    //       result = false
    //     }
    //     // 암생활비지급서비스(61) 가입금액 체크예외 추가(2020-07-10)
    //     // 22.06 youngki78.lee ASR220600579_비갱신암 환급특약 화면 예외처리_사랑On
    //     if (zzinscdScCd !== '47' && zzinscdScCd !== '48' && zzinscdScCd !== '61' && zzinscdScCd !== '62' && zzinscdScCd !== '64' && (result)) {
    //       if (!el.covInsAmt || el.covInsAmt === '') {
    //         if ( el.covInsAmt !== '0' ) { // 변경청약신청서발행에서는 0 원이 들어감. 정상적인 처리 _ 강석제 20170831
    //           errors.push('[' + el.relTyp + ']' + el.name + '보험의 가입금액이 입력되지 않았습니다.')
    //           errors2.push(el.uiCode)
    //           isValidate = false
    //         }
    //       }
    //     }

    //     let tempInprdArray = el.inprdTypSelected.key.split(',')[0]
    //     if (!tempInprdArray || String(tempInprdArray) === '' || String(tempInprdArray) === '9') {
    //       errors.push('[' + el.relTyp + ']' + el.name + '보험의 보험기간이 입력되지 않았습니다.')
    //       errors2.push(el.uiCode)
    //       isValidate = false
    //     }

    //     let tempPmprdArray = el.pmprdTypSelected.key.split(',')[0]
    //     if (!tempPmprdArray || String(tempPmprdArray) === '' || String(tempPmprdArray) === '9') {
    //       errors.push('[' + el.relTyp + ']' + el.name + '보험의 납입기간이 입력되지 않았습니다.')
    //       errors2.push(el.uiCode)
    //       isValidate = false
    //     }

    //     /** subInsured값이 존재할 시 해당 값에 대한 고객정보가 존재하는지 체크. 20170925 박세현**/
    //     let chkSubInsrdCd = false
    //     if (el.subInsured != null && el.subInsured !== '' && el.subInsured.length > 0 ) {
    //       for (let i = 0; i < Gaf.getTObj('rltnrInfo').length; i++) {
    //         if (Gaf.getTObj('rltnrInfo')[i].zzcontvInsrdCd === el.subInsured ) {
    //           chkSubInsrdCd = true
    //         }
    //       }

    //       if (!chkSubInsrdCd) {
    //         if (el.subInsured === '23') {
    //           errors.push('선택된 [종피보험자]정보가 존재하지 않습니다.')
    //         }
    //         errors2.push(el.uiCode)
    //         isValidate = false
    //       }
    //     }
    //   })

    //   // 보험료 계산시 오류 리스트 초기화
    //   this.clearErrList_calc()

    //   if (errors && errors.length > 0) {
    //     let errorMsgTitle = '다음의 사유로 보험료계산 처리가 불가합니다.<br><br>'
    //     let errorMsg = ''
    //     let errorYn = false
    //     for (let i in errors) {
    //       if (errors[i].indexOf('[alert]') < 0) {
    //         errorYn = true
    //         errorMsg = errorMsg + errors[i] + '<br>'
    //       } else {
    //         errors[i] = errors[i].split('[alert]')[1]
    //       }
    //     }
    //     if (errorYn) {

    //       this.items.forEach((el3, i3) => {
    //         if (errors2.indexOf(el3.uiCode) > -1) {
    //           this.$set(this.items[i3], 'calcError', true)
    //         }
    //       })

    //       this.message_html = errorMsgTitle + errorMsg
    //       this.$refs.confirmAlert.open()

    //       Gaf.setResultBox({'MSPPI822D': false})
    //       return false
    //     }
    //   }

    //   Gaf.d('MSPPI820P fnCheckItemValidate End !!!!')
    //   Gaf.setResultBox({'MSPPI822D': isValidate})
    //   return isValidate
    // },
    /******************************************************************************
     * Function명 : fnCheckMessage
     * 설명       : fn_PremCalc 함수에서 호출하는 함수
     *              [원본] MSPPI083D
    ******************************************************************************/
    fnCheckMessage () {
      let selected = Gaf.getVObj('PNLPQL00109_CoverageInfo').filter(function (item) { return item.isChecked === true })
      if (selected.length === 0) {
        piCommonUtil.getFDPConfirm('s', '선택된 행이 없습니다.')
        return false
      }

      // 선택한 특약의 가입대상자구분코드 체크
      let insuredCheck = []

      selected.forEach((el, i) => {
        let tmpInsCode = el.insCode
        let tmpUniCode = el.uiCode
        let tmpInSured = el.insured

        Gaf.getTObj('prodInfo').coverageList.forEach((el2, i2) => {
          if (tmpInsCode === el2.insCode && tmpUniCode === el2.uiCode && tmpInSured === el2.insured) {
            if (el2.insured === '21' || el2.insured === '23' || el2.insured === '24') {
              insuredCheck.push(el2.insured)
            }
          }
        })
      })
      return insuredCheck
    },
    /******************************************************************************
     * Function명 : fn_OnChangeBaseContDto
     * 설명       : 화면에서 기본계약사항 변경시 하단 버튼 영역 재처리
     *              [원본] MSPPI001M
    ******************************************************************************/
    fn_OnChangeBaseContDto () {
      let _ZPPrdtEntInfoDto = Gaf.getVObj('ZPPrdtEntInfoDto')
      // [TO-DO] 보험료계산, 자유입출금, 적립전환, 연금선지급 처리 로직 확인 후 적용하기
      // 보험료계산
      // this.isShow.isShowBtn60 = true
      // this.isEnabled.isEnabledBtn60 = true

      // 보험료 계산 상태 변경
      this.lv_isCalc = true
    },
    /************************************************************************************************
     * Function명  : multiAlert
     * 설명        : 보험료 계산 시 안내 메시지 다건 처리 함수
     ************************************************************************************************/
    multiAlert () {
      window.vue.getStore('progress').dispatch('INIT')
      if (this.multiAlertObj.msgArr.length > 0) {
        console.log(this.$refs.multiAlertConfirm)
        // window.vue.getStore('progress').dispatch('UPDATE', false)     // Progress Bar 해제 추가
        // window.vue.getStore('progress').dispatch('INIT')
        this.$refs.multiAlertConfirm.$el.scrollTop = 0
        this.purpleScrollVal = 0
        this.confirmMsg = this.multiAlertObj.msgArr[0]
        // this.showMultiConfirm = true
        this.multiAlertObj.msgArr.splice(0, 1)
        this.$refs.multiAlertConfirm.open()
      } else {
        this.purpleScrollVal = 0
        // this.showMultiConfirm = false
        this.multiAlertObj.lastCallBackFunc.call()
        this.confirmMsg = ''
        this.multiAlertObj = {}
        
        this.$refs.multiAlertConfirm.close()
      }
    },
    /************************************************************************************************
     * Function명  : onPositive
     * 설명        : confirmAlert 에서 확인 버튼 선택 시
     *              [원본] MSPPI083D
     ************************************************************************************************/
    onPositive () {
      console.log('onPositive =====')
      this[this.callback](this.callBackParams)
      this.callBackParams = null
      
      this.$nextTick(() => {
        this.$refs.confirmAlert.close()
      })

    },
    /************************************************************************************************
     * Function명  : onNegative
     * 설명        : confirmAlert 에서 취소 버튼 선택 시
     *              [원본] MSPPI083D
     ************************************************************************************************/
    onNegative () {
      console.log('onNegative =====')
      // let zaPlanNm = this.callBackParams.zaPlanNm   // 설계명
      // [TO-DO] 플랜 저장 팝업 호출해야하는지...
      // this.fn_OpenMSPPI100P(zaPlanNm)

      this.confirmMsg = ''
      this.$refs.confirmAlert.close()
    },
    /************************************************************************************************
     * Function명  : fn_ChkFndelAbdaBase
     * 설명        : 자유납입, 적립전환, 연금선지급 데이터 있는지 체크하여 리턴해주는 함수
     ************************************************************************************************/
    fn_ChkFndelAbdaBase () {
      let chkPmId1 = PM_ID_9590TB5L0000 // 자유입출금
      let chkPmId2 = PM_ID_FKO4TB5L0000 // 적립전환, 연금선지급
      let returnFlag = false
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      if (!piCommonUtil.isEmpty(abdapolpr.polprAbdabase)) {
        let polprAbdabase = abdapolpr.polprAbdabase

        for (let i=0; i<polprAbdabase.length; i++) {
          if ((polprAbdabase[i].pmId === chkPmId1 && polprAbdabase[i].productBaseId === chkPmId1) ||
              (polprAbdabase[i].pmId === chkPmId2 && polprAbdabase[i].productBaseId === chkPmId2)) {
            returnFlag = true
            break
          }
        }
      }
      return returnFlag
    },
    /************************************************************************************************
     * Function명  : fndelAbdaBase
     * 설명        : 기존 설계 삭제
     ************************************************************************************************/
    fndelAbdaBase (pmId) {
      let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      if (!piCommonUtil.isEmpty(abdapolpr.polprAbdabase)) {
        let polprAbdabase = abdapolpr.polprAbdabase

        // 기존 설계 삭제
        let seq = 0
        let aNum = polprAbdabase.length
        for (let i = 0; i < aNum; i++) {
          if (polprAbdabase[seq].pmId === pmId && polprAbdabase[seq].productBaseId === pmId) {
            polprAbdabase.splice(seq, 1)
            seq--
          }
          seq++
        }
      }
      Gaf.getPObj('abdapolicy')[0].abdapolpr[0].aldapolpr.zzadpadInptCd = ''
    },
    /************************************************************************************************
     * Function명  : fn_Save
     * 설명        : 
     ************************************************************************************************/
    fn_Save () {
      // 설계번호 셋팅
      // param.zaPlanId = abdapolicy.zaPlanId
      // // 설계명 셋팅
      // param.zaPlanNm = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPlanNm

      // // 상품명 셋팅
      // param.zaPrdtNm = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPrdtNm

      // // 주피자 고객ID 셋팅
      // Gaf.getTObj('rltnrInfo').forEach((el, i) => {
      //   if (el.zzcontvInsrdCd === '21') {
      //     param.zaAgtFileId = el.zaAgtFileId
      //   }
      // })
      // let abdapolpr = Gaf.getGafObjByRefId('POLPR')[0]
      // abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPlanNm = result.planNm
      // abdapolpr.ztpqlCboextPrcsAbdapolpr.zaOfrStatCd = '50'
      // let abdapolicyList = Gaf.getGafObjByRefId('POLICY') // Policy 정보 Array
      // abdapolicyList[0].zaPlanId = '' //  설계ID 초기화
      // let urlId = "URLPQL00136";
      /** 20161130 yherp.kwon : 설계저장(보험료계산없음) URL 변경 URLPQL00136 -> URLPQL00157 */
      let urlId = 'URLPQL00157'
      let tmodel = Gaf.getObj('tmodel')
      let pmodel = Gaf.getObj('pmodel')
      pmodel.abdapolicy.splice(1,1)
      tmodel.searchCriteria.pageId = 'NAEUS'
      let inputJson = {
        pmodel: Gaf.getObj('pmodel'),
        tmodel: tmodel
      }
      // console.log('fn_Save input Data ===> ', JSON.stringify(inputJson))
      // TEST 위해 임시 주석 처리함
      Gaf.execute('MSPPI820P', urlId, inputJson, true, null, this)
      // =======================================
    },
    /******************************************************************************
     * Function명 : URLPQL00157_LocalCallBack
     * 설명       : 설계저장(보험료계산없음) 콜백
    ******************************************************************************/
    URLPQL00157_LocalCallBack (result) {
      //GA매니저 가입설계 대행시
      if(this.$cmUtil.fn_GachangeTF()){
        console.log('설계저장 MSPPI820P result',result)
        console.log('GA TGAM00010 설계저장')
        this.fn_insGaEntplSptReq(result)
      }

      Gaf.d('MSPPI820P 패널  URLPQL00157_LocalCallBack Start !!!!')
      let tmp = result.pmodel

      let abdapolicy = tmp.abdapolicy[0]

      // ABDAPOLPR 정보
      let abdapolpr = abdapolicy.abdapolpr[0]

      Gaf.d('설계번호 :: ' + abdapolicy.zaPlanId)
      console.log(`이전 : ${this.lv_planId} / 변경 zaPlanId : ${abdapolicy.zaPlanId}`)

      // 설계번호 셋팅
      this.lv_planId = abdapolicy.zaPlanId

      // 계약번호 셋팅
      this.policynrTt = abdapolpr.applnrTt

      // 설계명 셋팅
      this.zaPlanNm = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPlanNm
      Gaf.d('MSPPI083D 패널  URLPQL00157_LocalCallBack End !!!!')
 
      // let message = '설계가 저장되었습니다.'
      // this.$refs.snackbarShow.show({text: message})

      // [선심사PJT] 선심사결과 조회부터 다시 조회
      // 버튼 상태 초기화
      this.lv_isChgFlag = false
      this.lv_isCalc = false
      Gaf.getPanel('MSPPI822D').fn_Init()

      this.fn_SelNundwRslt()
      // 가입설계 진행현황 특약선택 처리완료로 변경(STEP:3 - 특약선택)
      // this.$emit('isStepComplete', {STEP:'3' , STATE:true})
 
    },
    /******************************************************************************
     * Function명 : URLPQL00157_FailLocalCallBack
     * 설명       : 
     ******************************************************************************/
    URLPQL00157_FailLocalCallBack(res, param) {
      console.log('URLPQL00157_FailLocalCallBack ===> ', res)
    },
    /******************************************************************************
     * Function명 : fn_SendJudgeInfo
     * 설명       : 심사 서비스 호출
     ******************************************************************************/
    fn_SendJudgeInfo () {
      // 전산심사 처리
      let tmodel = Gaf.getObj('tmodel')
      let pmodel = Gaf.getObj('pmodel')
      tmodel.searchCriteria.pageId = 'NAEUS'
      tmodel.bnfrInfo = [
          {'zzcontvInsrdCd':'',
          'zzchldSnoVl':'',
          'zzcustRrnId':'',
          'zaInsrdNm':'',
          'zzbnfrRolCd':'',
          'zaBnfrSno':'',
          'knb':'',
          'custNm':'',
          'zzcusDtlTypCd':'',
          'shareVl':''}
      ]
      tmodel.systmUndwRslt = {} // 전산심사결과 초기화
      // 성능관련 불필요항목 제거
      if (tmodel !== undefined && tmodel.prodInfo !== undefined ) {
        if (tmodel.prodInfo.ifbcAttrList !== undefined) {
          tmodel.prodInfo.ifbcAttrList = null
        }
        if (tmodel.prodInfo.prodStructList !== undefined) {
          tmodel.prodInfo.prodStructList = null
        }
      }
      // INPUT 셋팅
      let inputJson = {
        'pmodel': pmodel,
        'tmodel': tmodel
      }
      
      // test =====
      // console.log('심사 실행 시 input Data ===> ', JSON.stringify(inputJson))
      // test end =====

      window.vue.getStore('progress').dispatch('SHOW')
      Gaf.execute('MSPPI820P', 'URLPQL08510', inputJson, false, null, this)
      // Gaf.execute('MSPPI200P', 'URLPQL08510', inputJson, false, null, this)
    },
    /******************************************************************************
    * Function명 : URLPQL08510_LocalCallBack
    * 설명       : URLPQL08510_LocalCallBack
    ******************************************************************************/
    URLPQL08510_LocalCallBack (returnData) {
      console.log('전산심사 콜백함수 ====> ', returnData)
      // 선심사결과 재조회 후 발행팝업 호출
      this.fn_SelNundwRslt()
      this.lv_NextFn = 'fn_OpenMSPPI250P'
      // this.fn_OpenMSPPI250P()
      // window.vue.getStore('progress').dispatch('HIDE')
    },
    /******************************************************************************
     * Function명 : fn_insGaEntplSptReq
     * 설명       : 
     ******************************************************************************/
    fn_insGaEntplSptReq(result){
      let userId = this.getStore('userInfo').getters.getUserInfo.userId
      let fpEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      let reqYmd = this.$bizUtil.getDateToString(new Date(), '') // 오늘
      let reqTm = moment(new Date().toISOString()).format('YYYYMMDDHHMMSSSSSSSS').toString().substring(8,14)

      let entAmt = 0
      let mainCov = Gaf.getVObj('PNLPQL00109_CoverageInfo').filter((el) => { return String(el.seq) === '0' && el.elementprodtpCd === '10' })[0]
      // console.log('mainCov :: ', mainCov)
      
      let unitAmt = 1
      switch ( mainCov.unitNm ) {
        case '원': unitAmt = 1
          break
        case '천원': unitAmt = 1000
          break
        case '만원': unitAmt = 10000
          break
        case '$': unitAmt = 1
          break
      }
      entAmt = mainCov.covInsAmt * unitAmt // 보험가입금액(원단위)

      let reqDtlCntnt = '고 객 명 : ' + result.tmodel.rltnrInfo[0].zaInsrdNm + '/'
                        + '생년월일 : ' + result.tmodel.rltnrInfo[0].zzcustRrnId.substring(0,6) + '/'
                        + '상품종류 : ' + result.pmodel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPrdtNm + '/'
                        + '요청보험료 : ' + this.$bizUtil.numberWithCommasByNumber(entAmt) + '/'
                        + '요청방법 : ' + '모바일'

      let pParams = {fnScCd:'10', dma_input: {}, dma_GAM00010DVO: {}}
      pParams.dma_input = {mngerId: userId, fpEno: fpEno, reqYmd: reqYmd}
      pParams.dma_GAM00010DVO = {mngerId: userId, fpEno: fpEno, reqYmd: reqYmd, reqDtlCntnt: reqDtlCntnt, 
                                 entplNo: result.pmodel.abdapolicy[0].zaPlanId, 
                                 insrdChnlCustId: result.tmodel.rltnrInfo[0].zaAgtFileId,
                                 custNm: result.tmodel.rltnrInfo[0].zaInsrdNm, 
                                 prdtId: result.pmodel.abdapolicy[0].productCd, 
                                 prdtNm: result.pmodel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPrdtNm,
                                 prm: this.lv_smtotPrm.smtotPrm,
                                 aenam: userId, pmprdTypCd: '', pmprdTypVl: '', inprdTypCd: '', inprdTypVl: '',
                                 crncyCd: mainCov.currencyId, mobpsYn: '', recmEplyScCd: '30'}
      // console.log('GA pParams : ', pParams)

      let trnstId = 'txTSSPI08I2'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          console.log("설계번호 저장 완료 :: " + response.body.dma_GAM00010DVO.entplNo)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
      
    },
    /************************************************************************************************
     * Function명  : fn_PreCheckNundwState
     * 설명        : 선심사 신청 정보 조회 호출 함수
     *               인심사신청, 발행 팝업 연결 등 선심사상태 조회 후 처리를 위해 type을 받아 처리
     ************************************************************************************************/
    fn_PreCheckNundwState (type) {
      // 1. 청약서 발행여부 체크
      //    1)발행 상태 : 청약서 발행 상태로 전환 후 안내메시지('청약서가 발행되어 선심사 진행이 종료되었습니다.') 노출
      //    2)미발행 상태 : 인심사 신청 가능 여부 체크
      // 2. 동일 피보험자의 인심사 진행중 건 존재 시 => '동일 피보험자의 심사 진행중인 건이 존재하여 인심사 신청이 불가능합니다.' 안내 메시지 노출
      //    => 이건은 PQM 쪽과 얘기하여 오류로 내려줄건지 확인 필요함
      // 3. 인심사 진행 2회 => '인심사 요청 가능 횟수(최대 2회)를 초과하였습니다.' 안내 메시지 노출
      // 4. 인심사 진행 2회 미만 => 심사요청의견 제출 바텀시트 노출
      if (type === 'nundwInspApl') {
        if (this.lv_jdgMemoCntnt.trim().length < 1) {
          this.fn_OepnConfirm('심사요청의견을 작성해 주세요.')
          return
        }
      }
      this.fn_SelAplNundwInfo(type)
    },
    /************************************************************************************************
     * Function명  : fn_PreNundwInspApl
     * 설명        : 선심사 인심사 신청전 유효성 체크
     ************************************************************************************************/
    fn_PreNundwInspApl () {
      if (this.lv_inspPsbYn === 'Y') {
        if (this.lv_nundwAplInfo.inspReqNts >= 2) {
          this.fn_OepnConfirm('인심사 요청 가능 횟수(최대 2회)를 초과하였습니다.')
          return
        } else {
          this.lv_jdgMemoCntnt = ''
          // 심사요청의견 제출 바텀시트 오픈
          this.$refs.refBsJdgReqOpinSbmt.open()
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_PrcsNundwInspApl
     * 설명        : 선심사 인심사 신청
     ************************************************************************************************/
    fn_PrcsNundwInspApl () {
      
      // UF1PQL0488
      if (_.isEmpty(this.lv_planId)) {
        return
      }
      let lv_vm = this
      window.vue.getStore('progress').dispatch('SHOW')
      let inputJson = {
        zNundwInspAplDto: {
          dsclsId:	this.lv_dsclsId, // 고지ID
          planId: this.lv_planId, // 설계ID
          jdgMemoCntnt: this.lv_jdgMemoCntnt // 심사메모내용
        }
      }
      piCommonUtil.invoke2('UF1PQL0488', inputJson, this.fn_ResPrcsNundwInspAplRslt, null, this.fn_ResErrorHandler, this, this)
      console.log(`선심사 인심사 신청 input params ===> `, inputJson)
    },
    /************************************************************************************************
     * Function명  : fn_ResPrcsNundwInspAplRslt
     * 설명        : 선심사 인심사 신청 결과
     ************************************************************************************************/
    fn_ResPrcsNundwInspAplRslt (returnData) {
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        // 선심사인심사요청DTO
        if (!_.isEmpty(returnData.znundwInspAplDto)) {
          let jdgrEno = returnData.znundwInspAplDto.jdgrEno // 심사자사번
          if (!_.isEmpty(jdgrEno)) {
            this.fn_CommAlert('inspReq')
          }
        }
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /************************************************************************************************
     * Function명  : fn_PrePrcsPendIndctCpmt
     * 설명        : 선심사 미결 지시 보완처리 전 NAS에 서류여부에 따른 분기 처리 함수
     ************************************************************************************************/
    fn_PrePrcsPendIndctCpmt () {
      if (this.lv_pendTrtCntnt.length < 11) {
        this.fn_OepnConfirm('미결보완내용은 10자 이상 작성해야 합니다.')
        return
      }
      this.fn_PreCheckNundwState('pendCpmtSbmt')
    },
    /************************************************************************************************
     * Function명  : fn_selImgList
     * 설명        : 저장된 서류이미지 목록 조회(nas-fix.dat)
     ************************************************************************************************/
    fn_selImgList() {
      let lv_vm = this
      let pParams = {
        preDocuID: this.lv_dsclsId
      }
      let trnstId = 'txTSSPI80S1'
      let auth = 'S'
      this.lv_DocuList = []
      this.post(lv_vm, pParams, trnstId, auth).then(function (response) {
        if ( response.body && response.body.photoList) { // 조회성 & 목록이 있는경우
          let list = response.body.photoList
          if (list.length > 0) {
            lv_vm.lv_DocuList = list
            console.log('lv_DocuList ===> ', lv_vm.lv_DocuList)
            lv_vm.fn_SendIScan()
          } else {
            lv_vm.fn_PrcsPendIndctCpmt('U')
          }
        } else {
          lv_vm.fn_PrcsPendIndctCpmt('U')
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /************************************************************************************************
     * Function명  : fn_SendIScan
     * 설명        : NAS에 저장된 이미지 iSCAN에 전송
     ************************************************************************************************/
    fn_SendIScan () {
      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'DCNundwImgMngCtlImpl',
        reqMthdNm: 'imgScan',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.nundw.DCNundwDocuMngSVO',
        resVONm: 'sli.mo.vo.dc.nundw.DCNundwDocuMngSVO'
      }
      let params = {
        rctNo: this.lv_dsclsId, // 선심사 ID
        userId: this.lv_isGaMng ? this.lv_GaCnsltNo : this.headerVO.userEno,
        userDeptCd: this.lv_isGaMng ? this.lv_GaFofOrgNo : this.headerVO.userDeptCd,
        contrCustId: this.lv_nundwAplInfo.contrCustId, // 계약자 BPID 
        contrNm: this.lv_nundwAplInfo.contrNm, // 계약자 성명
        isdBpid: this.lv_nundwAplInfo.insrdCustId, // 피보험자 BPID
        isdNm: this.lv_nundwAplInfo.insrdNm, // 피보험자 성명
      }
      window.vue.getStore('progress').dispatch('SHOW') // progress
      piCommonUtil.invokeDirect(null, params, this.fn_SendIScanResult, null, this.fn_SendIScanResult, this, commHeader)
    },
    /************************************************************************************************
     * Function명  : fn_PrcsPendIndctCpmt
     * 설명        : ISCAN 전송 서비스 결과 처리 함수
     ************************************************************************************************/
    fn_SendIScanResult (result) {
      console.log('sendIScanResult ===> ', result)
      if (Number(result.wkRslt) === 0) { // 작업결과 (0인경우 성공)
        console.log('ISCAN 전송 성공')
        this.fn_PrcsPendIndctCpmt('U')
      } else {
        // polling 필요없다고 하셔서 바로 오류 메시지 띄우기
        this.fn_CommAlert('pendRetry')
        console.log('sendIScanResult ===> ', result.rtnMsg)
        window.vue.getStore('progress').dispatch('HIDE')
      }
    },
    /************************************************************************************************
     * Function명  : fn_PrcsPendIndctCpmt
     * 설명        : 선심사 미결 지시 보완처리
     ************************************************************************************************/
    fn_PrcsPendIndctCpmt (type, inData) {
      // UF1PQL0487
      console.log('fn_PrcsPendIndctCpmt type : ', type, ' / inData : ', inData)
      let lv_vm = this
      window.vue.getStore('progress').dispatch('SHOW')
      let nundwPendIndctDto = {}
      let inputJson = {}
      if (type === 'S') { // 조회
        let srchItem = this.lv_lstNundwInspRslt.find(findItm => findItm.pendCpmtYn !== 'Y')
        if (!piCommonUtil.isEmpty2(srchItem)) {
          nundwPendIndctDto = {
            pendTrtScCd: type, // 미결처리구분코드(S:조회, U:미결보완)
            dsclsId:	this.lv_dsclsId, // 고지ID
            planId: this.lv_planId, // 설계ID
            pendPrgStatCd: '00', // 미결진행상태코드(00:미결, 30:미결보완)
            undwPendScCd: srchItem.undwPendScCd, // 계약심사미결구분코드
            untyMngNo: srchItem.untyMngNo, // 통합관리번호구분코드
            untyMngNoScCd: srchItem.untyMngNoScCd, // 통합관리번호
            trtSno: srchItem.trtSno
          }
        }
      } else {
        if (Object.keys(this.lv_nundwPendIndctDto).length > 0) {
          nundwPendIndctDto = {
            pendTrtScCd: 'U', // 미결처리구분코드(S:조회, U:미결보완)
            dsclsId:	this.lv_dsclsId, // 고지ID
            planId: this.lv_planId, // 설계ID
            pendPrgStatCd: this.lv_nundwPendIndctDto.pendPrgStatCd, // 미결진행상태코드(00:미결, 30:미결보완)
            pendTrtCntnt: this.lv_pendTrtCntnt, // 미결처리내용
            pendTrtrEno: this.lv_isGaMng?  this.lv_GaCnsltNo : this.headerVO.userEno, // 미결처리자사번
            pendIndctCd01: this.lv_nundwPendIndctDto.pendIndctCd01,
            pendIndctCd02: this.lv_nundwPendIndctDto.pendIndctCd02,
            undwPendScCd: this.lv_nundwPendIndctDto.undwPendScCd, // 계약심사미결구분코드
            untyMngNo: this.lv_nundwPendIndctDto.untyMngNo, // 통합관리번호구분코드
            untyMngNoScCd: this.lv_nundwPendIndctDto.untyMngNoScCd, // 통합관리번호
            trtSno: this.lv_nundwPendIndctDto.trtSno, // 처리일련번호
            undwrEno: this.lv_nundwPendIndctDto.undwrEno,
          }
        }
      }
      if (piCommonUtil.isEmpty2(nundwPendIndctDto)) return
      inputJson = {'nundwPendIndctDto': nundwPendIndctDto}
      console.log(`선심사 미결 지시 보완처리 input params ===> `, inputJson)
      // TEST 주석처리
      // if (type !== 'S') {
      //   window.vue.getStore('progress').dispatch('HIDE')
      //   return
      // }
      piCommonUtil.invoke2('UF1PQL0487', inputJson, this.fn_ResPrcsPendIndctCpmtRslt, type, this.fn_ResErrorHandler, this, this)
      // TEST 주석처리
    },
    /************************************************************************************************
     * Function명  : fn_ResPrcsPendIndctCpmtRslt
     * 설명        : 선심사 미결 지시 보완처리 결과
     ************************************************************************************************/
    fn_ResPrcsPendIndctCpmtRslt (returnData, type) {
      // 미결처리내용 초기화
      this.lv_pendTrtCntnt = ''
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        // [TO-DO] 선심사 미결 지시 보완처리 추가
        // 성공 시
        if (!_.isEmpty(returnData.nundwPendIndctDto)) {
          if (type === 'S') {
            // 미결보완 조회
            this.lv_nundwPendIndctDto = returnData.nundwPendIndctDto
            this.lv_nundwPendIndctDto.pendIndctYmd = this.$bizUtil.dateDashFormat(returnData.nundwPendIndctDto.pendIndctYmd.replace(/-/g, '').substr(0,8))
            this.lv_nundwPendIndctDto.cpmtParngYmd = this.$bizUtil.dateDashFormat(returnData.nundwPendIndctDto.cpmtParngYmd.replace(/-/g, '').substr(0,8))
            this.lv_pendTrtCntnt = ''
            this.$refs.refPendCpmtInpt.open()
          } else {
            // 미결보완 제출 성공 시
            this.$refs.refPendCpmtInpt.close()
            this.fn_CommAlert('pendComplte')
          }
        }
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /************************************************************************************************
     * Function명  : fn_SetSmtotPrm
     * 설명        : 합계보험료 세팅
     ************************************************************************************************/
    fn_SetSmtotPrm (data) {
      if (_.isEmpty(data)) return

      console.log('fn_SetSmtotPrm ===> ', data)
      this.lv_smtotPrm = data
    },
    /************************************************************************************************
     * Function명  : fn_CloseBs
     * 설명        : 바텀시트 종료 시 함수
     ************************************************************************************************/
    fn_CloseBs (type) {
      if (_.isEmpty(type)) return
      if (type === 'refBsRsltInfo') {
        this.$refs.refBsRsltInfo.close()
        this.fn_OpenBottomExpand()
      }
    },
    /************************************************************************************************
     * Function명  : fn_MovePage
     * 설명        : 미결보완-사전/질병정보보완
     ************************************************************************************************/
    fn_MovePage () {
      this.$emit('resultPopup', {nundwPrgStatCd: '50', planId: this.lv_planId})
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI825P
     * 설명        : 미결서류보완 팝업 오픈 함수
     ************************************************************************************************/
    fn_OpenMSPPI825P () {
      let lv_vm = this
      lv_vm.popupMSPPI825P = this.$bottomModal.open(MSPPI825P, {
        properties: {
          params: {
            preDocuID: lv_vm.lv_dsclsId
          }
        },
        listeners: {
          closePopup: () => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI825P)
          },
          confirmPopup: (item) => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI825P)
            console.log('MSPPI825P confirmPopup', item)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI830P
     * 설명        : 미결상세조회 팝업 오픈 함수
     ************************************************************************************************/
    fn_OpenMSPPI830P () {
      let lv_vm = this
      lv_vm.popupMSPPI830P = this.$bottomModal.open(MSPPI830P, {
        properties: {
          nundwInspRsltList: lv_vm.lv_lstNundwInspRslt
        },
        listeners: {
          closePopup: () => {
            lv_vm.$bottomModal.close(lv_vm.popupMSPPI830P)
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_preChkPbl
     * 설명        : 발행 팝업 오픈 전 함수
     ************************************************************************************************/
    fn_preChkPbl () {
      // 진행상태코드[30:심사필요, 50:미결, 90:심사완료]
      if (this.lv_nundwAplInfo.cundwYmd !== piCommonUtil.getCurrentDate() && ['30','50','90'].includes(this.lv_nundwAplInfo.nundwPrgStatCd)) {
        this.fn_CommAlert('chkCundw')
      } else {
        this.fn_OpenMSPPI250P()
      }
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI250P
     * 설명        : 발행 팝업 오픈 함수
     ************************************************************************************************/
    fn_OpenMSPPI250P () {
      let lv_vm = this
      this.lv_NextFn = '' // 초기화함
      // console.log('fn_OpenMSPPI250P : ', JSON.stringify({pmodel: Gaf.getObj('pmodel'), tmodel:Gaf.getObj('tmodel'), abdapolicy: Gaf.getGafObjByRefId('POLICY')}))
      this.popupPI250P = this.$bottomModal.open(MSPPI250P, {
        properties: {
          pParams: {zaPlanId: this.lv_planId,
                    pmodel: Gaf.getObj('pmodel'),
                    tmodel: Gaf.getObj('tmodel'),
                    abdapolicy: Gaf.getGafObjByRefId('POLICY'),
                    pageId: this.$options.name},
        },
        listeners: {
          onConfirmed: (result) => {
            this.$bottomModal.close(this.popupPI250P).then(() => {
              lv_vm.fn_SelNundwRslt()
            })
          },
          cancelPopup: () => {
            this.$bottomModal.close(this.popupPI250P).then(() => {
              lv_vm.fn_SelNundwRslt()
            })
          },
          onClose: () => {
            this.$bottomModal.close(this.popupPI250P).then(() => {
              lv_vm.fn_SelNundwRslt()
            })
          }
        }
      },
      {properties: {preventTouchClose : true}})
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI840P
     * 설명        : New-AEUS 팝업 오픈 함수
     ************************************************************************************************/
    fn_OpenMSPPI840P () {
      this.popupMSPPI840P = this.$bottomModal.open(MSPPI840P, {
        properties: {
          pPage: this.$options.name,
          pParams: {planId: this.lv_planId},
          // pIsBackKeyListen: true,
        },
        listeners: {
          closePopup: (result) => {
            this.$bottomModal.close(this.popupMSPPI840P).then(() => {
            })
          },
          resultPopup: (result) => {
            this.$bottomModal.close(this.popupMSPPI840P).then(() => {
            })
          },
        }
      },
      {
        properties: {
          noHeader: true,
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_SelAplNundwInfo
     * 설명        : 선심사신청정보 서비스 호출
     ************************************************************************************************/
    fn_SelAplNundwInfo (type) {
      window.vue.getStore('progress').dispatch('SHOW')
      let params = {
        zNundwAplInfoReqDto: {
          dsclsId: '', //this.lv_dsclsId, // 고지ID
          planId: this.lv_planId, // 설계ID
        }
      }
      piCommonUtil.invoke2('UF1PQL0483', params, this.fn_ResAplNundwInfo, type, null, this, this)
      // piCommonUtil.invoke2('UF1PQL0483', params, this.fn_ResAplNundwInfo, null, this.fn_ResErrorHandler, this, this)
    },
    /************************************************************************************************
     * Function명  : fn_ResAplNundwInfo
     * 설명        : 선심사신청정보 서비스 호출 결과 처리
     ************************************************************************************************/
    fn_ResAplNundwInfo (returnData, type) {
      console.log('선심사신청정보 서비스 호출 결과 ====> ', returnData)
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        this.lv_zNundwAplInfoDto = returnData.znundwAplInfoDto
        // 청약서발행여부 체크
        let ofrfmPblYn = this.lv_zNundwAplInfoDto.ofrfmPblYn
        console.log('선심사 청약서발행 여부 ====> ', ofrfmPblYn)
        if (ofrfmPblYn === 'Y') {
          this.lv_OfrfmPblYn = ofrfmPblYn
          this.fn_OepnConfirm('청약서가 발행되어 선심사 진행이 종료되었습니다.')
        } else {
          if (!_.isEmpty(type)) {
            // 인심사 요청
            if (type === 'nundwInspApl') {
              this.fn_PrcsNundwInspApl()
              // if (this.lv_inspPsbYn === 'Y') {
              //   if (this.lv_nundwAplInfo.inspReqNts >= 2) {
              //     this.fn_OepnConfirm('인심사 요청 가능 횟수(최대 2회)를 초과하였습니다.')
              //   } else {
              //     this.lv_jdgMemoCntnt = ''
              //     // 심사요청의견 제출 바텀시트 오픈
              //     this.$refs.refBsJdgReqOpinSbmt.open()
              //   }
              // }
            } else if (type === 'pendCpmtSbmt') {
              // 선심사미결지시보완처리 서비스 조회
              // this.fn_PrcsPendIndctCpmt('S')
              this.fn_selImgList()
            }
          }
        }
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /************************************************************************************************
     * Function명  : fn_DataLoadComplete
     * 설명        : 설계조회 후 주피 건강체 관련 체크 함수
     *               EP DataLoadComplete
     ************************************************************************************************/
    fn_DataLoadComplete () {
      console.log('START fn_DataLoadComplete ===============')
      let mnisdExtra = '' // 주피 할증지수로 합계보험료 상이
      let after_smtotPrm = 0
      if (piCommonUtil.isObj(this.lv_nundwAplInfo)) {
        // console.log('this.lv_lstNundwCundwAbnrm : ', this.lv_lstNundwCundwAbnrm)
        if (this.lv_nundwAplInfo.cundwRsltCd === 'N' && this.lv_lstNundwCundwAbnrm?.length > 0) {
          this.lv_lstNundwCundwAbnrm.forEach((el, idx) => {
            if (el.zanrmYn === 'N' && el.zaUndwRsltMsgCd === 'R00113E0009') {
              mnisdExtra = el.zaUndwRsltMsgCd
              // console.log ('zaUndwRsltMsgCntnt ===> ', el.zaUndwRsltMsgCntnt)
              try {
                let msgCntnt = el.zaUndwRsltMsgCntnt.replace('합계보험료(', '')
                let indx = msgCntnt.indexOf('원')
                if (indx > 0) {
                  msgCntnt = msgCntnt.substring(0, indx).trim()
                  msgCntnt = msgCntnt.split(',').join('')
                  after_smtotPrm = parseInt(msgCntnt, 0)
                }
              } catch (err) {
                after_smtotPrm = 0
              }
            }
          })
        }
      }
      let hlthsList = [] // 건강체목록
      let gafBaseInfoListDtos = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos
      // console.log('gafBaseInfoListDtos ==> ', gafBaseInfoListDtos)
      gafBaseInfoListDtos.forEach(baseInfo => {
        // console.log('baseInfo ===> ', baseInfo)
        // 주피건강체
        if (baseInfo.code === '00003') {
          baseInfo.list.split('|').forEach(_el => {
            let hlthsCdz = _el.split('_')
            hlthsList.push(hlthsCdz[0])
          })
        }
      })

      let isShow = this.$bizUtil.cloneDeep(this.$refs.refMSPPI822D.lv_isEdit)
      let abdapolicyList = Gaf.getGafObjByRefId("POLICY") //Policy 정보 Array
      let befor_abdapolicy = abdapolicyList[0]
      if (abdapolicyList.length > 1) {
        befor_abdapolicy = abdapolicyList[1]
      }
      let befor_abdapolpr = befor_abdapolicy.abdapolpr[0]
      let befor_polprAbdaprem = befor_abdapolpr.polprAbdaprem[0]
      // 합계보험료(=세후보험료)
      let befor_smtotPrm = parseInt(befor_polprAbdaprem.premaftertaxAm?befor_polprAbdaprem.premaftertaxAm:'0', 10)
      // 건강상태가 표준체인데 할증지수가 존재하는 경우 할증체로 변경
      if (isShow && mnisdExtra === 'R00113E0009') {
        let zzmnisdIndxn = this.fn_GetZzmnisdIndxnVl()
        let abdapolpr = Gaf.getGafObjByRefId("POLPR")[0]
        let aldapolpr = abdapolpr.aldapolpr
        console.log(`건강체 : ${aldapolpr.zzentplHlthsCd} / 할증지수 : `, zzmnisdIndxn, ' / 변경가능 건강체코드 : ', hlthsList)
        console.log('보험료(전) : ', befor_smtotPrm , ' / 보험료(후) : ', after_smtotPrm)

        // 할증지수 100 이상
        if (after_smtotPrm > befor_smtotPrm && zzmnisdIndxn.zzmnisdIndxnVl > 100 || zzmnisdIndxn.zzmnisd2indxVl > 100) {
          // 표준체에서 할증된 경우
          if (aldapolpr.zzentplHlthsCd === '0' && hlthsList.indexOf('3') >= 0) {
            // 할증체가 가능한지 확인
            if (this.fn_IsAvlExtra()) {
              this.fn_CommAlert('zzentplHlthsCd3')
              return true
            } else {
              this.fn_CommAlert('zzentplHlthsCd')
              return true
            }
          } else {
            // 할증체에서 할증된 경우
            if (aldapolpr.zzentplHlthsCd === '3') {
              this.fn_CommAlert('zzentplHlthsCd')
              return true
            }
          }
        } else {
          // 할증체에서 표준체가된 경우
          if (aldapolpr.zzentplHlthsCd === '3' && hlthsList.indexOf('0') >= 0) {
            this.fn_CommAlert('zzentplHlthsCd0')
            return true
          }
        }
      }
      return false
    },
    /************************************************************************************************
     * Function명  : fn_GetZzmnisdIndxnVl
     * 설명        : 할증지수 세팅 함수
     *               EP getzzmnisdIndxnVl
     ************************************************************************************************/
    fn_GetZzmnisdIndxnVl () {
      let zzmnisdIndxn = {
        zzmnisdIndxnVl : 0,
			  zzmnisd2indxVl : 0
      }
      let tmodel = Gaf.getObj('tmodel')
      if (!piCommonUtil.isObj(tmodel)) return zzmnisdIndxn
      let prodInfo = tmodel.prodInfo
      if (!piCommonUtil.isObj(prodInfo)) return zzmnisdIndxn
      let coverageList = prodInfo.coverageList
      if (!piCommonUtil.isObj(coverageList)) return zzmnisdIndxn

      let rltnrInfo = Gaf.getTObj('rltnrInfo').filter(item => item.zzcontvInsrdCd === '21') // 주피정보
      
      coverageList.forEach(item => {
        let dignIndxn = rltnrInfo.filter(_item => _item.zzcontvInsrdCd === item.insured)[0].dignIndxn
        let zaDundwRsltIndxn, zaDundwRsltIndxn1
        switch (item.zzmnisdImpdaCd) {
          case '3': // 할증법(사망)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn
            if(zaDundwRsltIndxn > 100) {
            	zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case '4': // 할증법(사망+발생)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn
            zaDundwRsltIndxn1 = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            if (zaDundwRsltIndxn1 > 100) {
              zzmnisdIndxn.zzmnisd2indxVl = zaDundwRsltIndxn1
            }
            break
          case '6': // 할증법(LTC)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '05')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case '8': // 할증법(종합실손)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '07')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case '9': // 할증법(질병실손)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '06')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'A': // 할증법(CI)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'B': // 할증법(심근경색)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '36')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'C': // 할증법(뇌출혈)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'D': // 할증법(고도장해)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '34')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'E': // 할증법(입원)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '13')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            break
          case 'F': // 할증법(사망+뇌심근)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn
            zaDundwRsltIndxn1 = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            if (zaDundwRsltIndxn1 > 100) {
              zzmnisdIndxn.zzmnisd2indxVl = zaDundwRsltIndxn1
            }
            break
          case 'G': // 할증법(뇌출혈+CI)
            zaDundwRsltIndxn = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn
            zaDundwRsltIndxn1 = dignIndxn.filter(finditem => finditem.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn
            if (zaDundwRsltIndxn > 100) {
              zzmnisdIndxn.zzmnisdIndxnVl = zaDundwRsltIndxn
            }
            if (zaDundwRsltIndxn1 > 100) {
              zzmnisdIndxn.zzmnisd2indxVl = zaDundwRsltIndxn1
            }
            break
          default:
            break
        }
      })
      return zzmnisdIndxn
    },
    /************************************************************************************************
     * Function명  : fn_IsAvlExtra
     * 설명        : 할증가능여부
     *               EP isAvlExtra
     ************************************************************************************************/
    fn_IsAvlExtra() {
      let avlExtra = true
      let coverageList = Gaf.getVObj('PNLPQL00109_CoverageInfo')
      if (!piCommonUtil.isObj(coverageList)) return avlExtra
      let rltnrInfo = Gaf.getTObj('rltnrInfo').filter(item => item.zzcontvInsrdCd === '21') //주피
      coverageList.forEach((el, idx) => {
        let dignIndxn = rltnrInfo.filter(item => item.zzcontvInsrdCd == el.insured)[0].dignIndxn
        //주보험일 경우에만 체크하도록 요청 (2017.02.03 김민수사원요청 )
        if(el.elementprodtpCd === '10') {
          switch(el.GafProductInfo.zzmnisdImpdaCd){
            case '3' : //할증법(사망)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn < 150){
                Gaf.d('3.주피보험자의 주보험 할증(사망)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn +'를 확인하세요.')
                avlExtra = false
              }
              break

            case '4' : //할증법(사망+발생)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(item => item.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn < 150){
                Gaf.d('4.주피보험자의 주보험 할증(사망)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn +', 할증(CI)지수' + dignIndxn.filter(item => item.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn+ '를 확인하세요.')
                avlExtra = false
              }
              break

            case 'E' : //할증법(입원)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '13')[0].zaDundwRsltIndxn < 150){
                Gaf.d('E.주피보험자의 주보험 할증(입원)지수' +dignIndxn.filter(item => item.zaDundwRsltTypCd === '13')[0].zaDundwRsltIndxn+ '를 확인하세요.')
                avlExtra = false
              }
              break

            case 'F' : //할증법(사망+뇌출혈)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(item => item.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn < 150){
                Gaf.d('F.주피보험자의 주보험 할증(사망)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '02')[0].zaDundwRsltIndxn +', 할증(뇌출혈)지수' + dignIndxn.filter(item => item.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn+ '를 확인하세요.')
                avlExtra = false
              }
              break

            case 'G' : //할증법(뇌출혈+CI)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn < 150 && dignIndxn.filter(item => item.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn < 150){
                Gaf.d('G.주피보험자의 주보험 할증(뇌출혈)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '35')[0].zaDundwRsltIndxn +', 할증(CI)지수' + dignIndxn.filter(item => item.zaDundwRsltTypCd === '04')[0].zaDundwRsltIndxn+ '를 확인하세요.')
                avlExtra = false
              }
              break

            case '8' : //할증법(종합실손)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '07')[0].zaDundwRsltIndxn < 150){
                Gaf.d('8.주피보험자의 주보험 할증(종합입원)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '07')[0].zaDundwRsltIndxn +'를 확인하세요.')
                avlExtra = false
              }
              break

            case '9' : //할증법(질병실손)
              if(dignIndxn.filter(item => item.zaDundwRsltTypCd === '06')[0].zaDundwRsltIndxn < 150){
                Gaf.d('9.주피보험자의 주보험 할증(질병입원)지수'+ dignIndxn.filter(item => item.zaDundwRsltTypCd === '06')[0].zaDundwRsltIndxn +'를 확인하세요.')
                avlExtra = false
              }
              break
            default :
              break
          }
        }
      })
      return avlExtra
    },
  }
}
</script>
<style scoped>
</style>